import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import Buttons from "../../../../../components/buttons";
import * as Yup from "yup";
import Inputs from "../../../../../components/form/inputs";
import EditorToolbar, {
  modules,
  formats,
} from "../../../../../components/richTextEditor/EditorToolbar";
import { useDispatch, useSelector } from "react-redux";
import { saveDataFAQ, saveEditDataFAQ } from "stores/actions/faq";
import { useFormik } from "formik";
import { convertBase64 } from "utils/helpers/convertBase64";

const AddFAQ = ({ setOpenPopupAddFAQ, editData }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.faq);

  const saveData = async () => {
    const payload = {
      pertanyaan: formikValidation.values.pertanyaan,
      jawaban: convertBase64("ENCRYP", formikValidation.values.jawaban),
    };
    if (editData?.id_faq) {
      dispatch(
        saveDataFAQ({
          ...payload,
          id_faq: editData.id_faq,
        })
      );
      setOpenPopupAddFAQ(false);
    } else {
      dispatch(saveDataFAQ(payload));
      setOpenPopupAddFAQ(false);
    }
  };

  const formikValidation = useFormik({
    initialValues: {
      pertanyaan: "",
      jawaban: "",
    },
    validationSchema: Yup.object().shape({
      pertanyaan: Yup.string().required("Pertanyaan Wajib diisi"),
      jawaban: Yup.string().required("Jawaban Wajib Diisi"),
    }),
    onSubmit: saveData,
  });

  useEffect(() => {
    if (editData) {
      formikValidation.setFieldValue("pertanyaan", editData?.pertanyaan);
      formikValidation.setFieldValue(
        "jawaban",
        convertBase64("DECRYP", editData?.jawaban)
      );
    }
  }, [editData]);

  return (
    <div>
      <div className="border-[1px] border-[#4048521a] rounded-[12px]">
        <div className="p-4">
          <Inputs
            label="Pertanyaan"
            placeholder="Masukkan pertanyaan"
            id="pertanyaan"
            name="pertanyaan"
            value={formikValidation.values.pertanyaan}
            error={formikValidation.errors.pertanyaan}
            onChange={formikValidation.handleChange}
          />
          <div className="text-[#4F4F4F] font-semibold mt-4 mb-2">Jawaban</div>
          <div className="text-editor">
            <EditorToolbar />
            <ReactQuill
              theme="snow"
              id="jawaban"
              name="jawaban"
              value={formikValidation.values.jawaban}
              onChange={(value) => {
                if (value === "<p><br></p>") {
                  formikValidation.setFieldValue("jawaban", "");
                } else {
                  formikValidation.setFieldValue("jawaban", value);
                }
              }}
              error={formikValidation.errors.jawaban}
              modules={modules}
              formats={formats}
              className="bg-[#EDF2F4] w-full h-[300px]"
            />
          </div>
        </div>
        <div className="divider"></div>
        <div className="flex flex-row justify-end p-4">
          <Buttons
            text="Tambah"
            customClass="btn-primary btn-medium !w-fit"
            onClick={formikValidation.handleSubmit}
            loading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(AddFAQ);
