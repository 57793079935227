import axios from "axios";
import { BASE_URL_ENDPOINT } from "constant/constanta";

const AdminArtikelAPI = {
  getListPage: (payload) =>
    axios.post(`${BASE_URL_ENDPOINT}/admin/artikel`, payload),
  postData: (payload) =>
    axios.post(`${BASE_URL_ENDPOINT}/admin/save-artikel`, payload),
  deleteData: (id) => axios.delete(`${BASE_URL_ENDPOINT}/admin/artikel/${id}`),
};

const AdminKategoriArtikelAPI = {
  getListCategory: (payload) =>
    axios.post(`${BASE_URL_ENDPOINT}/admin/get-kategori-artikel`, payload),
  postAddCategory: (payload) =>
    axios.post(`${BASE_URL_ENDPOINT}/admin/save-kategori-artikel`, payload),
  deleteCategory: (id) =>
    axios.delete(`${BASE_URL_ENDPOINT}/admin/kategori-artikel/${id}`),
};

export { AdminArtikelAPI };
export { AdminKategoriArtikelAPI };
