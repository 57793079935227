import { DocumentCopy, Edit, Eye, Trash } from "iconsax-react";
import { getMasterId } from "stores/actions/datamaster";
import { getLocalStorage } from "utils/helpers";

const userLogin =
  getLocalStorage("userLogin") && JSON.parse(getLocalStorage("userLogin"));
const permissions = userLogin?.data?.account?.permissions;

export const rupiah = (number) => {
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  }).format(number);
};

export const columns = (dispatch) => [
  {
    title: "Nama Additional",
    dataIndex: "name",
    render: (name) => <div className="text-sm">{name}</div>,
  },
  {
    title: "Harga Default",
    dataIndex: "default_price",
    render: (default_price) => (
      <div className="text-sm">{rupiah(default_price)}</div>
    ),
  },
  {
    title: "Deskripsi",
    dataIndex: "description",

    render: (description, data) => (
      <div className="w-52">
        <div className={`flex gap-x-4 items-center`}>{description}</div>
        <div className="action absolute !w-full top-2 flex !flex-row !justify-end">
          <div className="flex flex-row justify-end pr-[16px] gap-x-16">
            {permissions?.includes("delete_master-data") && (
              <Trash
                className="cursor-pointer"
                onClick={() =>
                  dispatch(getMasterId({ modal: true, data: data }))
                }
                size="16"
                color="#141414"
              />
            )}
            {permissions?.includes("create_master-data") && (
              <DocumentCopy
                className="cursor-pointer"
                onClick={() =>
                  dispatch(getMasterId({ popup: true, data: data }))
                }
                size="16"
                color="#141414"
              />
            )}
            {permissions?.includes("update_master-data") && (
              <Edit
                className="cursor-pointer"
                onClick={() =>
                  dispatch(getMasterId({ modalEdit: true, data: data }))
                }
                size="16"
                color="#141414"
              />
            )}
          </div>
        </div>
      </div>
    ),
  },
];
