import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { handleError, handleSuccess } from "stores/actions/errorGeneral";

import { PriceUpdateAPI } from "utils/api/calculator/price-update";

import Buttons from "components/buttons";
import AlertPopup from "components/popup/alert";

import IconTrash from "assets/icons/trash-maroon.svg";

import Inputs from "components/form/inputs";
import InputsCurrency from "components/form/inputsCurrency";
import AkomodasiToursPriceUpdate from "./akomodasiTour";

const CountryTourUpdatePrice = () => {

    const dispatch = useDispatch()
    const masterDataStore = useSelector(({ datamaster }) => datamaster);

    const [actionIndex, setActionIndex] = useState(null)
    const [removeConfirm, setRemoveConfirm] = useState({ show: false, index: null })

    const [isGetAllLoading, setGetAllLoading] = useState(false)
    const [isSaveLoading, setIsSaveLoading] = useState(false)
    const [isUpdateLoading, setIsUpdateLoading] = useState(false)
    const [isRemoveLoading, setIsRemoveLoading] = useState(false)
    const [isGetAkomodasiToursLoading, setIsGetAkomodasiToursLoading] = useState(false)

    const [countryTours, setCountryTours] = useState([])
    const [akomodasiTours, setAkomoasiTours] = useState([])

    useEffect(() => {
        onHandleCountryTours()
    }, [])

    const onHandleCountryTours = async () => {
        try {

            setGetAllLoading(true)
            
            const res = await PriceUpdateAPI.countryToursGet()
            if(res.status = 200) {
                handleFetchCityTours(res.data.data)
                onHandleAkomodasiCountryTours()
            } else {
                dispatch(handleError(res))
            }

            setGetAllLoading(false)

        } catch(error) {
            setGetAllLoading(false)
            dispatch(handleError(error))
        }
    }
    const onHandleSave = async (index) => {
        setActionIndex(index)

        try {
            setIsSaveLoading(true)

            let countryToursTemp = [...countryTours]
            const countryTour = countryToursTemp[index];

            const res = await PriceUpdateAPI.countryToursSave(countryTour.data)

            if(res.status === 200) {
                dispatch(handleSuccess(res))
                countryToursTemp[index].props.active = true
                countryToursTemp[index].props.updated = false

                countryToursTemp[index].data.id = res.data.data.id

                setCountryTours(countryToursTemp)
                onHandleAkomodasiCountryTours()
            } else {
                dispatch(handleError(res))
            }

            setIsSaveLoading(false)
        } catch(error) {
            setIsSaveLoading(false)
            dispatch(handleError(error))
        }
        
    };
    const onHandleUpdate = async (index) => {
        setActionIndex(index)
        setIsUpdateLoading(index)

        try {
            setIsUpdateLoading(true)

            let countryToursTemp = [...countryTours]
            const countryTour = countryToursTemp[index];

            const res = await PriceUpdateAPI.countryToursUpdate(countryTour.data)

            if(res.status === 200) {
                dispatch(handleSuccess(res))
                countryToursTemp[index].props.active = true
                countryToursTemp[index].props.updated = false

                setCountryTours(countryToursTemp)
            } else {
                dispatch(handleError(res))
            }

            setIsUpdateLoading(false)
        } catch(error) {
            setIsUpdateLoading(false)
            dispatch(handleError(error))
        }
        
    };
    const onHandleRemove = async (index) => {
        try {

            setIsRemoveLoading(true)

            const countryToursTemp = [...countryTours]
            const countryTour = countryToursTemp[index];

            const res = await PriceUpdateAPI.countryToursRemove(countryTour.data.id)

            if(res.status === 200) {
                countryToursTemp.splice(index, 1)
                
                dispatch(handleSuccess(res))
                setCountryTours(countryToursTemp)
            } else {
                dispatch(handleError(res))
            }

            setIsRemoveLoading(false)
            setRemoveConfirm({ show: false, index: 0 })
        } catch(error) {
            setIsRemoveLoading(false)
            dispatch(handleError(error))
        }
    }
    const onHandleAkomodasiCountryTours = async () => {
        try {

            setIsGetAkomodasiToursLoading(true)
            
            const res = await PriceUpdateAPI.countryToursAkomodasiGetAll()
            if(res.status = 200) {
                handleFetchAkomodasiTours(res.data.data)
            } else {
                dispatch(handleError(res))
            }

            setIsGetAkomodasiToursLoading(false)

        } catch(error) {
            setIsGetAkomodasiToursLoading(false)
            dispatch(handleError(error))
        }
    }

    const handleFetchCityTours = (countryToursData) => {
        let countryToursTemp = []

        countryToursData.map((item) => {
            countryToursTemp.push({
                props: {
                    active: true,
                    updated: false,
                },
                data: item,
            });
        })

        setCountryTours(countryToursTemp)
    }

    const handleAddCityTours = () => {
        const newMaskapai = {
          props: {
            active: false,
            updated: false,
          },
          data: {
            id: null,
            name: "",
            price: 0,
          },
        }
    
        let countryToursTemp = [...countryTours]
        countryToursTemp.push(newMaskapai)
    
        setCountryTours(countryToursTemp)
    }
    const handleCancelAddCityTour = (index) => {
        let countryToursTemp = [...countryTours];
        
        countryToursTemp.splice(index, 1);
    
        setCountryTours(countryToursTemp)
    }

    const handleFetchAkomodasiTours = (akomodasiToursData) => {
        let akomodasiToursTemp = []

        akomodasiToursData.map((item) => {
            akomodasiToursTemp.push({
                props: {
                    active: true,
                    show: false,
                    updated: false,
                },
                data: item,
            });
        })

        setAkomoasiTours(akomodasiToursTemp)
    }

    const handleUpdateAkomodasi = (data, countryTourID) => {
        let akomodasiToursTemp = [...akomodasiTours]

        const akomodasiToursTempMore = akomodasiToursTemp.filter((item) => item.data.paket_kalkulator_tour_id !== countryTourID)
        akomodasiToursTemp = akomodasiToursTempMore.concat(data)

        setAkomoasiTours(akomodasiToursTemp)
    }

    const handleGetNameCountryTours = (countryToursID) => {
        const countryTour = countryTours.find(item => item.data.id === countryToursID);
        return countryTour ? countryTour.data.name : null;
    }

    const countryToursActive = countryTours.filter(function (countryTour) {
        return countryTour.props.active === true;
    });
    
    const countryToursDeactive = countryTours.filter(function (countryTour) {
        return countryTour.props.active === false;
    });

    return (
        <>

            <AlertPopup
                open={removeConfirm.show}
                handleClose={() => setRemoveConfirm({ show: false, index: 0 })}
                handleContinue={() => onHandleRemove(removeConfirm.index)}
                loading={isRemoveLoading}
                subtitle="Apakah anda yakin menghapus negara tour ini?"
            />

            <div className="flex flex-row">
                
                <div className="w-[25%]">
                <div className="text-sm font-semibold">Negara Tour</div>
                </div>
                <div className="w-[75%]">
                
                <div className="flex flex-col gap-4">
                    {countryTours.map((countryTour, index) => {
                        return (
                            <div
                                className="border border-[#E0E0E0] p-4 rounded-2xl"
                                key={index}
                                >
                                    {/* <!----------- HEADER -----------> */}
                                    <div className="flex justify-between items-center">
                                        <div className="font-bold mb-4">
                                            {!countryTour.props.active && "Tambah "}Negara Tour{" "}
                                            {index + 1}
                                        </div>
                                        <div className="flex justify-end gap-4 items-center">
                                            {!countryTour.props.active ? (
                                                <>
                                                    <Buttons
                                                        text="Batal"
                                                        onClick={() => handleCancelAddCityTour(index)}
                                                        customClass="btn-primary btn-outline btn-small"
                                                        />
                                                    <Buttons
                                                        text="Simpan"
                                                        loading={isSaveLoading}
                                                        onClick={() => onHandleSave(index)}
                                                        customClass="btn-primary btn-small"
                                                    />
                                                </>
                                            ): null}
                                            {countryTour.props.active ? (
                                                <>
                                                    {countryToursActive.length > 1 && (
                                                        <i
                                                            className="!cursor-pointer"
                                                            onClick={() =>
                                                                setRemoveConfirm({ show: true, index })
                                                            }
                                                            >
                                                            <img
                                                                src={IconTrash}
                                                                className={`w-6 h-6`}
                                                                />
                                                        </i>
                                                    )}
                                                    <Buttons
                                                        text="Simpan"
                                                        loading={isUpdateLoading ? actionIndex === index : false}
                                                        onClick={() => {
                                                            onHandleUpdate(index);
                                                        }}
                                                        disabled={!countryTour.props.updated}
                                                        customClass="btn-primary btn-small"
                                                    />
                                                </>
                                            ) : null}
                                        </div>
                                    </div>

                                    {/* <!----------- CONTENT ----------------> */}
                                    <div className="flex gap-4 items-center mb-4">
                                        <div className="w-[83%]">
                                            <Inputs
                                                label="Kota Tour"
                                                placeholder="Masukan nama Kota Tour"
                                                value={countryTour.data.name}
                                                onChange={(val) => {
                                                    const countryToursTemp = [...countryTours];

                                                    countryToursTemp[index].props.updated = true;
                                                    countryToursTemp[index].data.name = val.target.value;

                                                    setCountryTours(countryToursTemp)
                                                }}
                                            />
                                        </div>
                                        <div className="w-[83%]">
                                            <InputsCurrency
                                                label="Harga"
                                                value={countryTour.data.price}
                                                placeholder="Masukan harga City Tour"
                                                onChange={(val) => {
                                                    const countryToursTemp = [...countryTours];

                                                    countryToursTemp[index].props.updated = true;
                                                    countryToursTemp[index].data.price = val;

                                                    setCountryTours(countryToursTemp)
                                                }}
                                            />
                                        </div>

                                    </div>
                            </div>
                        )
                    })}
                </div>
                
                <Buttons
                    text="+ Tambah Negara Tour"
                    customClass="btn-outline btn-medium mt-4 !py-4"
                    disabled={countryToursDeactive.length}
                    onClick={() => handleAddCityTours()}
                />

                </div>

            </div>

            <div className="divider my-6"></div>
            
            <div className="flex flex-row">
                
                <div className="w-[25%]">
                    <div className="text-sm font-semibold">Hotel Negara Tour</div>
                </div>
                <div className="w-[75%]">
                    {countryTours.map((countryTour, index) => {
                        return (
                            <>
                                <div className={`text-sm font-bold mb-4 ${index ? 'mt-4' : ''}`}>{countryTour.data.name}</div>
                                <AkomodasiToursPriceUpdate 
                                    countryTour={countryTour} 
                                    akomodasiToursData={akomodasiTours?.filter((item) => item.data.paket_kalkulator_tour_id === countryTour.data.id)} 
                                    handleUpdateAkomodasi={(data) => handleUpdateAkomodasi(data, countryTour.data.id)}
                                    />
                            </>
                        )
                    })}
                </div>
            </div>
        </>
    )

}

export default CountryTourUpdatePrice