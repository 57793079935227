import React, { useState } from "react";

import DateRangePicker from "components/datepicker/DateRangePicker";
import MoslemeTable from "components/Table";

import IconBack from "assets/icons/arrow-left-dark-icon.svg";
import IconEye from "assets/icons/eye.svg";
import Badge from "components/badge";

const PendapatanCabangHistory = ({ setHistory }) => {
  const [filterStatus, setFilterStatus] = useState("all");
  // Loading
  const [isLoading, setIsLoading] = useState(false);
  // Selection & type state
  const [selectionType, setSelectionType] = useState("checkbox");
  const [withSelection, setWithSelection] = useState(true);

  // Pagination state
  const [currentpage, setCurrentpage] = useState(1);
  const [size, setSize] = useState(10);
  const total = 100;

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: total,
    current: currentpage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20"],
  };

  const columns = [
    {
      title: "Nama Paket",
      dataIndex: "name",
      render: (name) => (
        <div className="flex flex-row items-center gap-2">
          <div>{name}</div>
          <img src={IconEye} alt="eye" />
        </div>
      ),
    },
    {
      title: "Tanggal Pendapatan",
      dataIndex: "date",
    },
    {
      title: "Jumlah Pax",
      dataIndex: "total_pax",
    },
    {
      title: "Nominal Pendapatan",
      dataIndex: "income",
      render: (income) => (
        <>
          <div className="font-semibold">Rp {income}</div>
          {/* <div className="action">
            <Eye onClick={() => alert(`show id:`)} size="15" color="#141414" />
            <Trash
              onClick={() => alert(`delete id:`)}
              size="15"
              color="#141414"
            />
            <DocumentCopy
              onClick={() => alert(`copy id:`)}
              size="15"
              color="#141414"
            />
            <Edit onClick={() => alert(`edit id:`)} size="15" color="#141414" />
          </div> */}
        </>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      name: "Umroh Bersama Ustadz Muhammad Abduh Tuasikal 12 September 2022",
      date: "21/08/2022 11:27:29",
      total_pax: "24",
      income: "10.500.000",
    },
    {
      key: "2",
      name: "Umroh Bersama Ustadz Muhammad Abduh Tuasikal 12 September 2022",
      date: "21/08/2022 11:27:29",
      total_pax: "24",
      income: "10.500.000",
    },
  ];

  // func to set size pagination
  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setSize(pageSize);
  };
  // manipulated data with action
  const sourceData = data;

  const onDatePickerChange = (val) => {
    console.log(val);
  };

  return (
    <>
      <div className="flex flex-row justify-between items-center py-3">
        <div className="flex flex-row gap-4">
          <img
            className="w-[24px] h-[24px] cursor-pointer"
            src={IconBack}
            alt="back"
            onClick={() => setHistory(false)}
          />
          <div className="text-[16px] font-semibold">
            History Pendapatan Cabang
          </div>
          <Badge label="Yogyakarta" />
        </div>
        <div className="flex flex-row gap-4">
          <div className="flex flex-row gap-1 border border-[#E0E0E0] rounded-[20px] px-3 py-1">
            <div
              className={`border border-[#E0E0E0] px-2 py-1 rounded-xl cursor-pointer ${
                filterStatus === "all" && "border-[#8a1212]"
              }`}
              onClick={() => setFilterStatus("all")}
            >
              <div className={`${filterStatus === "all" && "txt-maroon"}`}>
                All
              </div>
            </div>
            <div
              className={`border border-[#E0E0E0] px-2 py-1 rounded-xl cursor-pointer ${
                filterStatus === "success" && "border-[#8a1212]"
              }`}
              onClick={() => setFilterStatus("success")}
            >
              <div className={`${filterStatus === "success" && "txt-maroon"}`}>
                Berhasil
              </div>
            </div>
            <div
              className={`border border-[#E0E0E0] px-2 py-1 rounded-xl cursor-pointer ${
                filterStatus === "pending" && "border-[#8a1212]"
              }`}
              onClick={() => setFilterStatus("pending")}
            >
              <div className={`${filterStatus === "pending" && "txt-maroon"}`}>
                Pending
              </div>
            </div>
            <div
              className={`border border-[#E0E0E0] px-2 py-1 rounded-xl cursor-pointer ${
                filterStatus === "failed" && "border-[#8a1212]"
              }`}
              onClick={() => setFilterStatus("failed")}
            >
              <div className={`${filterStatus === "failed" && "txt-maroon"}`}>
                Gagal
              </div>
            </div>
          </div>
          <DateRangePicker
            customClass="!px-4 !py-2"
            placeholder="Periode"
            onCalendarChange={onDatePickerChange}
          />
        </div>
      </div>
      <div className="mt-4">
        <MoslemeTable
          loading={isLoading}
          columns={columns}
          data={sourceData}
          withSelection={withSelection}
          selectionType={selectionType}
          pagination={pagination}
          onPageChange={(page) => setCurrentpage(page)}
          onSizeChange={(currentpage, pageSize) =>
            onSizeChange(currentpage, pageSize)
          }
        />
      </div>
    </>
  );
};

export default React.memo(PendapatanCabangHistory);
