import React from "react";
import PropTypes from "prop-types";
import Popup from "../../../popup";
import Inputs from "../../../form/inputs";
import Buttons from "../../../buttons";
import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles({
  card_popup_profile: {
    width: "100%",
    height: "280px",
    padding: "20px",
    borderWidth: "1px 1px 1px 1px",
    borderStyle: "solid",
    borderColor: "rgba(64, 72, 82, 0.1)",
    borderRadius: "8px 8px 0px 0px",
    "& .container_button": {
      marginTop: "28px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    "& .container_button_outline": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      marginTop: "10px",
    },
  },
});

const GantiKataSandi = ({
  openProfileSandi,
  handleClosePopupProfileSandi,
  dataProfileSandi,
}) => {
  const classes = useStyles();
  return (
    <Popup
      type={"secondary"}
      width="1000px"
      height="416px"
      open={openProfileSandi}
      handleClose={handleClosePopupProfileSandi}
      title="Ganti Kata Sandi"
    >
      <div className={classes.card_popup_profile}>
        <Inputs
          id="sandi_new"
          placeholder="Masukan sandi baru"
          customClass="mt-[5px]"
          type="password"
        />
        <Inputs
          id="confirm_sandi"
          placeholder="Konfirm kata sandi"
          customClass="mt-[5px]"
          type="password"
        />
        <div className="container_button_outline">
          <Buttons text="Perbaharui" customClass="btn-outline btn-medium" />
        </div>

        <div className="container_button">
          <Buttons text="Simpan" customClass="btn-primary btn-medium" />
        </div>
      </div>
    </Popup>
  );
};

GantiKataSandi.propTypes = {
  dataProfileSandi: PropTypes.object.isRequired,
  openProfileSandi: PropTypes.bool.isRequired,
  handleClosePopupProfileSandi: PropTypes.func.isRequired,
};
GantiKataSandi.defaultProps = {
  dataProfileSandi: {},
  openProfileSandi: false,
  handleClosePopupProfileSandi: () => {},
};

export default React.memo(GantiKataSandi);
