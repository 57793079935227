import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { handleError, handleSuccess } from "stores/actions/errorGeneral";

import { PriceUpdateAPI } from "utils/api/calculator/price-update";

import {
    getAllDataMaskapai,
  } from "stores/actions/datamaster";

import Buttons from "components/buttons";
import AlertPopup from "components/popup/alert";

import IconTrash from "assets/icons/trash-maroon.svg";
import IconArrowSquareDown from "assets/icons/arrow-square-down-2.svg";

import Inputs from "components/form/inputs";
import AntdSelect from "components/form/antdSelect";
import InputsCurrency from "components/form/inputsCurrency";
import moment from "moment/moment";

const MaskapaiPriceUpdate = () => {

    const dispatch = useDispatch()
    const masterDataStore = useSelector(({ datamaster }) => datamaster);

    const [actionIndex, setActionIndex] = useState(null)
    const [removeConfirm, setRemoveConfirm] = useState({ show: false, index: null })

    const [isGetAllLoading, setGetAllLoading] = useState(false)
    const [isSaveLoading, setIsSaveLoading] = useState(false)
    const [isRemoveLoading, setIsRemoveLoading] = useState(false)
    const [isGetPriceLoading, setIsGetPriceLoading] = useState(false)

    const [months, setMonths] = useState([])
    const [years, setYears] = useState([])

    const [masterMaskapais, setMasterMaskapais] = useState([])

    const [maskapaiPrices, setMaskapaiPrices] = useState([])

    useEffect(() => {
        onHandleGetAllMaskapaiPrice()

        // set months
        setMonths([
            { value: "01", label: "Januari" },
            { value: "02", label: "Februari" },
            { value: "03", label: "Maret" },
            { value: "04", label: "April" },
            { value: "05", label: "Mei" },
            { value: "06", label: "Juni" },
            { value: "07", label: "Juli" },
            { value: "08", label: "Agustus" },
            { value: "09", label: "September" },
            { value: "10", label: "Oktober" },
            { value: "11", label: "November" },
            { value: "12", label: "Desember" },
        ]);

        setYears(handleSetYears())

    }, [])

    useEffect(() => {
        if (masterDataStore?.dataMaskapai?.data?.length > 0) {
          let masterMaskapaisTemp = [];
          masterDataStore.dataMaskapai.data.map((val) => {
            masterMaskapaisTemp.push({
              id: val.id_maskapai,
              desc: val.code,
              img: val.logo,
              value: val.id_maskapai,
              label: val.name,
            });
          });
    
          // let listCalcMaskapai = [...listMaskapaiState]
          const allMaskapaiIDSelected = maskapaiPrices.map(item => item.data.maskapai_id)
          masterMaskapaisTemp = masterMaskapaisTemp.filter(item => !allMaskapaiIDSelected.includes(item.id))
    
          setMasterMaskapais(masterMaskapaisTemp);
        }
    
      }, [
        masterDataStore.dataMaskapai,
        masterDataStore.dataMaskapai.length,
    ]);

    const onHandleGetAllMaskapaiPrice = async () => {
        try {

            setGetAllLoading(true)
            
            const res = await PriceUpdateAPI.maskapaiGetList()
            if(res.status = 200) {
                handleFetchAllMaskapaiPrice(res.data.data)

                dispatch(
                    getAllDataMaskapai({
                        per_page: 100,
                        page_number: 1,
                        sort_column: "name",
                        sort_order: "",
                        keyword: "",
                    })
                );
            } else {
                dispatch(handleError(res))
            }

            setGetAllLoading(false)

        } catch(error) {
            setGetAllLoading(false)
            dispatch(handleError(error))
        }
    }
    const onHandleSaveMaskapai = async (index) => {
        setActionIndex(index)

        try {
            setIsSaveLoading(true)

            let maskapaiPricesTemp = [...maskapaiPrices]
            const maskapai = maskapaiPricesTemp[index];

            const res = await PriceUpdateAPI.maskapaiSave(maskapai.data)

            if(res.status === 200) {
                dispatch(handleSuccess(res))
                maskapaiPricesTemp[index].props.active = true
                maskapaiPricesTemp[index].props.updated = false

                // REMOVE MASKAPAI FROM LIST SELECT
                let listMasterMaskapai = [...masterMaskapais]
                listMasterMaskapai = listMasterMaskapai.filter(item => item.id !== maskapaiPricesTemp[index].data.maskapai_id)

                setMasterMaskapais(listMasterMaskapai)
                setMaskapaiPrices(maskapaiPricesTemp)
            } else {
                dispatch(handleError(res))
            }

            setIsSaveLoading(false)

        } catch(error) {
            setIsSaveLoading(false)
            dispatch(handleError(error))
        }
        
    };
    const onHandleRemove = async (index) => {
        try {

            setIsRemoveLoading(true)

            const maskapaiPricesTemp = [...maskapaiPrices]
            const maskapai = maskapaiPricesTemp[index];

            const res = await PriceUpdateAPI.maskapaiRemove(maskapai.data.maskapai_id)

            if(res.status === 200) {
                maskapaiPricesTemp.splice(index, 1)
                
                dispatch(
                    getAllDataMaskapai({
                        per_page: 100,
                        page_number: 1,
                        sort_column: "name",
                        sort_order: "",
                        keyword: "",
                    })
                );
                
                dispatch(handleSuccess(res))
                setMaskapaiPrices(maskapaiPricesTemp)
            } else {
                dispatch(handleError(res))
            }

            setIsRemoveLoading(false)
            setRemoveConfirm({ show: false, index: 0 })
        } catch(error) {
            setIsRemoveLoading(false)
            dispatch(handleError(error))
        }
    }
    const onHandleGetPrice = async (index) => {
        setActionIndex(index)

        try {
            setIsGetPriceLoading(true)

            let maskapaiPricesTemp = [...maskapaiPrices]
            const maskapai = maskapaiPricesTemp[index];

            const res = await PriceUpdateAPI.maskapaiGetPrice(maskapai.data)

            if(res.status === 200) {
                const price = res.data.data

                maskapaiPricesTemp[index].data.price = price
                setMaskapaiPrices(maskapaiPricesTemp)
            } else {
                dispatch(handleError(res))
            }

            setIsGetPriceLoading(false)
        } catch (error) {
            setIsGetPriceLoading(false)
            dispatch(handleError(error))
        }
    }

    const handleSetYears = () => {
        const yearNow = new Date().getFullYear();

        // Membuat daftar tahun
        const yearList = [];
        
        for (let i = 0; i <= 20; i++) {
            const yearSelected = yearNow + i;
            yearList.push({ value: String(yearSelected), label: yearSelected });
        }

        yearList.sort((a, b) => a - b);

        return yearList;
    };

    const handleFetchAllMaskapaiPrice = (maskapaiPricesData) => {
        let maskapaiPricesTemp = []

        maskapaiPricesData.map((item) => {
            let dataItem = {...item, maskapai_id: item.id}
            delete dataItem.id

            maskapaiPricesTemp.push({
                props: {
                active: true,
                showPrice: false,
                updated: false,
                },
                data: dataItem,
            });
        })

        setMaskapaiPrices(maskapaiPricesTemp)
    }
    const handleShowPrice = (index) => {
        let maskapaiPricesTemp = [...maskapaiPrices]

        maskapaiPricesTemp[index].props.showPrice = !maskapaiPricesTemp[index].props.showPrice;

        setMaskapaiPrices(maskapaiPricesTemp);
    }
    const handleAddMaskapai = () => {
        const newMaskapai = {
          props: {
            active: false,
            showPrice: false,
            updated: false,
          },
          data: {
            maskapai_id: null,
            maskapai_name: "",
            month: moment().format("MM"),
            price: 0,
            year: moment().format("YYYY")
          },
        }
    
        let maskapaiPricesTemp = [...maskapaiPrices]
        maskapaiPricesTemp.push(newMaskapai)
    
        setMaskapaiPrices(maskapaiPricesTemp)
    }
    const handleCancelAddMaskapai = (index) => {
        let maskapaiPricesTemp = [...maskapaiPrices];
        
        maskapaiPricesTemp.splice(index, 1);
    
        setMaskapaiPrices(maskapaiPricesTemp)
    }

    const maskapaiPricesActive = maskapaiPrices.filter(function (maskapai) {
        return maskapai.props.active === true;
    });
    
    const maskapaiPricesDeactive = maskapaiPrices.filter(function (maskapai) {
        return maskapai.props.active === false;
    });

    return (
        <>

            <AlertPopup
                open={removeConfirm.show}
                handleClose={() => setRemoveConfirm({ show: false, index: 0 })}
                handleContinue={() => onHandleRemove(removeConfirm.index)}
                loading={isRemoveLoading}
                subtitle="Apakah anda yakin menghapus maskapai ini?"
            />

            <div className="flex flex-row">
                
                <div className="w-[25%]">
                <div className="text-sm font-semibold">Maskapai</div>
                </div>
                <div className="w-[75%]">
                
                <div className="flex flex-col gap-4">
                    {maskapaiPrices.map((maskapai, index) => {
                        return (
                            <div
                                className="border border-[#E0E0E0] p-4 rounded-2xl"
                                key={index}
                                >
                                    {/* <!----------- HEADER -----------> */}
                                    <div className="flex justify-between items-center">
                                        <div className="font-bold mb-4">
                                            {!maskapai.props.active && "Tambah "}Maskapai{" "}
                                            {index + 1}
                                        </div>
                                        <div className="flex justify-end gap-4 items-center">
                                            {!maskapai.props.active ? (
                                                <>
                                                    <Buttons
                                                        text="Batal"
                                                        onClick={() => handleCancelAddMaskapai(index)}
                                                        customClass="btn-primary btn-outline btn-small"
                                                        />
                                                    <Buttons
                                                        text="Simpan"
                                                        loading={isSaveLoading}
                                                        onClick={() => onHandleSaveMaskapai(index)}
                                                        customClass="btn-primary btn-small"
                                                    />
                                                </>
                                            ): null}
                                            {maskapai.props.active ? (
                                                <>
                                                    {maskapaiPricesActive.length > 1 && (
                                                        <i
                                                            className="!cursor-pointer"
                                                            onClick={() =>
                                                                setRemoveConfirm({ show: true, index })
                                                            }
                                                            >
                                                            <img
                                                                src={IconTrash}
                                                                className={`w-6 h-6`}
                                                                />
                                                        </i>
                                                    )}
                                                    <Buttons
                                                        text="Simpan"
                                                        loading={isSaveLoading ? actionIndex === index : false}
                                                        onClick={() => {
                                                            onHandleSaveMaskapai(index);
                                                        }}
                                                        disabled={!maskapai.props.updated}
                                                        customClass="btn-primary btn-small"
                                                    />
                                                </>
                                            ) : null}
                                        </div>
                                    </div>

                                    {/* <!----------- CONTENT ----------------> */}
                                    <div className="flex gap-4 items-center mb-4">
                                        <div className="w-[83%]">
                                            {maskapai.props.active ? (
                                                <>
                                                    <Inputs
                                                        label="Maskapai"
                                                        readonly
                                                        value={maskapai.data.maskapai_name}
                                                        disabled={isGetPriceLoading ? actionIndex === index : false}
                                                    />
                                                </>
                                            ): null}
                                            {!maskapai.props.active ? (
                                                <AntdSelect
                                                    label="Maskapai"
                                                    placeholder="Pilih maskapai"
                                                    type="secondary"
                                                    options={masterMaskapais}
                                                    onChange={(item) => {
                                                        let maskapaiPricesTemp = [...maskapaiPrices]
                    
                                                        maskapaiPricesTemp[index].data.maskapai_id = item.value
                                                        maskapaiPricesTemp[index].data.maskapai_name = item.label.props.option.label
                        
                                                        setMaskapaiPrices(maskapaiPricesTemp)
                                                    }}
                                                />
                                            ) : null}
                                        </div>
                                        <div className="w-[83%]">
                                            <AntdSelect
                                                label="Bulan"
                                                placeholder="Pilih bulan"
                                                options={months}
                                                defaultValue={maskapai.data.month}
                                                onChange={(item) => {
                                                    let maskapaiPricesTemp = [...maskapaiPrices]

                                                    maskapaiPricesTemp[index].data.month = item.value

                                                    setMaskapaiPrices(maskapaiPricesTemp)

                                                    onHandleGetPrice(index)

                                                }}
                                                disabled={isGetPriceLoading ? actionIndex === index : false}
                                            />
                                        </div>
                                        <div className="w-[83%]">
                                            <AntdSelect
                                                label="Tahun"
                                                placeholder="Pilih tahun"
                                                options={years}
                                                defaultValue={maskapai.data.year}
                                                onChange={(item) => {
                                                    let maskapaiPricesTemp = [...maskapaiPrices]

                                                    maskapaiPricesTemp[index].data.year = item.value

                                                    setMaskapaiPrices(maskapaiPricesTemp)

                                                    onHandleGetPrice(index)

                                                }}
                                                disabled={isGetPriceLoading ? actionIndex === index : false}
                                                />
                                        </div>
                                        <div className="w-[10%] relative">
                                            <div className="cursor-pointer absolute bottom--4">
                                            <img
                                                onClick={() => {
                                                    handleShowPrice(index);
                                                }}
                                                src={IconArrowSquareDown}
                                                className={`w-8 h-8 ${
                                                    maskapai.props.showPrice && `rotate-180`
                                                }`}
                                            />
                                            </div>
                                        </div>

                                    </div>
                                    <div
                                        className={`transition-all duration-500 ${
                                            maskapai.props.showPrice
                                            ? `block`
                                            : `hidden`
                                        }`}
                                        >

                                        <InputsCurrency
                                            label="Harga"
                                            value={maskapai.data.price}
                                            placeholder="0"
                                            // disabled={updateHargaStore.isGetPriceLoading ? maskapaiActionIndex === index : false}
                                            onChange={(val) => {
                                                const maskapaiPricesTemp = [...maskapaiPrices];

                                                maskapaiPricesTemp[index].props.updated = true;
                                                maskapaiPricesTemp[index].data.price = val;

                                                setMaskapaiPrices(maskapaiPricesTemp)
                                            }}
                                        />
                                    </div>
                            </div>
                        )
                    })}
                </div>
                
                <Buttons
                    text="+ Tambah Maskapai"
                    customClass="btn-outline btn-medium mt-4 !py-4"
                    disabled={maskapaiPricesDeactive.length}
                    onClick={() => handleAddMaskapai()}
                />

                </div>

            </div>
        </>
    )

}

export default MaskapaiPriceUpdate