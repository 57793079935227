import { useEffect, useLayoutEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Select } from "antd";

import ImageField from "../../../../../../components/image_field";
import Inputs from "../../../../../../components/form/inputs";
import Textareas from "components/form/textarea/index";
import Selects from "../../../../../../components/form/selects";
import DatePicker from "../../../../../../components/datepicker/DatePicker";

import moment from "moment";
import axios from "axios";
import { BASE_URL_ENDPOINT } from "constant/constanta";
import { useDispatch, useSelector } from "react-redux";
import { getMasterMusim } from "stores/actions/datamaster/musim";

const useStyle = makeStyles({
  step1: {
    "& .input-label, .select-label": {
      fontSize: "14px",
      fontWeight: 600,
      color: "#4f4f4f",
      marginBottom: "4px",
    },
    "& .ant-picker": {
      width: "100%",
    },
    "& .ant-select-selector": {
      borderColor: "transparent",
      boxShadow: "none !important",
      padding: "10px 16px",
      minHeight: "54px",
      backgroundColor: "#f2f2f2 !important",
      border: "0 !important",
      borderRadius: "12px",
      width: "100%",
    },
    "& .ant-select-selection-item": {
      background: "#fff",
      borderRadius: "30px !important",
      height: "fit-content",
      padding: "4px 10px !important",
    },
    "& .select-label": {
      fontSize: "16px",
      fontWeight: "600",
      color: "rgb(79, 79, 79)",
      marginBottom: "8px",
    },
    "& .ant-select-selection-placeholder": {
      color: "#9CA3AF",
      left: "20px",
      fontSize: "14px",
    },
  },
  img_field_custom: {
    cursor: "pointer",
    height: "fit-content",
    padding: "2rem",
    width: "100%",
  },
  custom_dropdown: {
    "& .rc-virtual-list-holder-inner": {
      flexDirection: "row !important",
      flexWrap: "wrap",
      gap: "6px !important",
    },
    "& .ant-select-item-option": {
      border: "1px solid #ccc",
      borderRadius: "50px !important",
    },
    "& .ant-select-item-option-content": {
      marginRight: "6px !important",
    },
  },
});

const optionsMusim = [
  {
    value: "1",
    label: "Semi",
  },
  {
    value: "2",
    label: "Panas",
  },
  {
    value: "3",
    label: "Dingin",
  },
];

const optionsTags = [
  {
    value: "umroh",
    label: "Umroh",
  },
  {
    value: "paket_umroh",
    label: "PaketUmroh",
  },
  {
    value: "paket_murah",
    label: "PaketMurah",
  },
  {
    value: "travel_murah",
    label: "TravelMurah",
  },
];

const Step1 = ({ payload, setPayload }) => {
  const classes = useStyle();
  const [maskapaiOptions, setMaskapaiOptions] = useState([]);
  const { all_musim, isLoading } = useSelector((state) => state.musim);
  const [optionMusim, setOptionMusim] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getMasterMusim({
        keyword: "",
        sort_column: "",
        sort_order: "DESC",
      })
    );
  }, []);

  useEffect(() => {
    if (Object.keys(all_musim).length > 0) {
      const result_map = all_musim?.data?.map((item) => {
        return {
          label: `${item.thn_hijriyah} H / ${item.thn_masehi} M`,
          value: item.id_musim,
        };
      });

      setOptionMusim(result_map);
    }
  }, [all_musim]);

  useLayoutEffect(() => {
    const fetch = async () => {
      const response = await axios.post(
        `${BASE_URL_ENDPOINT}/admin/get-maskapai`,
        {
          sort_column: "name",
          sort_order: "",
          keyword: "",
        }
      );
      const _temp = [];
      response.data.data.forEach((item) => {
        _temp.push({
          label: item.name,
          value: item.id_maskapai,
        });
      });
      setMaskapaiOptions(_temp);
    };
    fetch();
  }, []);

  return (
    <div className={classes.step1}>
      <div className="flex gap-4">
        <div className="w-full">
          <ImageField
            error={false}
            customContainer="!rounded-2xl"
            width="100%"
            height="16rem"
            label="Thumbnail"
            isMulti={false}
            setFile={(file) =>
              setPayload({
                ...payload,
                image_thumbnail: file?.path,
              })
            }
          />
        </div>
        <div className="w-full">
          <Inputs
            label="Judul Paket"
            placeholder="Masukan judul paket"
            containerClass="w-full mb-2"
            value={payload.judul_paket}
            onChange={(e) => {
              setPayload({
                ...payload,
                judul_paket: e.target.value,
              });
            }}
          />
          <Selects
            label="Maskapai"
            placeholder="Pilih Maskapai"
            options={maskapaiOptions}
            value={maskapaiOptions.filter(
              (item) => item.value === payload.maskapai_id
            )}
            onChange={(e) =>
              setPayload({ ...payload, maskapai_id: e[0].value })
            }
            containerClass="w-full mb-2"
          />
          <div>
            <div className="input-label">Jadwal Keberangkatan</div>
            <DatePicker
              placeholder={payload?.jadwal_keberangkatan ?? "Pilih Tanggal"}
              onChange={(val) => {
                setPayload({
                  ...payload,
                  jadwal_keberangkatan: moment(val, "DD-MM-YYYY").format(
                    "YYYY-MM-DD"
                  ),
                });
              }}
              containerClass="mt-2"
            />
          </div>
        </div>
      </div>
      <div className="flex gap-5 mt-3">
        <Selects
          label="Pilih Musim"
          placeholder="Pilih Musim"
          value={optionMusim.filter((item) => item.value === payload.musim_id)}
          options={optionMusim}
          onChange={(e) => setPayload({ ...payload, musim_id: e[0].value })}
        />
        <Inputs
          label="Jumlah Hari"
          placeholder="Masukan jumlah hari"
          containerClass="w-full mb-2"
          value={payload.jumlah_hari}
          onChange={(e) => {
            setPayload({
              ...payload,
              jumlah_hari: e.target.value,
            });
          }}
        />

        <Inputs
          label="Kategori"
          placeholder="Masukan masukan kategori"
          containerClass="w-full mb-2"
          value={payload.kategori}
          onChange={(e) => {
            setPayload({
              ...payload,
              kategori: e.target.value,
            });
          }}
        />
      </div>
      <div className="mt-3">
        <div className="select-label">Tag</div>
        <Select
          mode="tags"
          style={{
            width: "100%",
          }}
          placeholder="Pilih Tags"
          options={optionsTags}
          dropdownStyle={{ zIndex: 2000 }}
          popupClassName={classes.custom_dropdown}
          value={
            payload?.tag?.split(",").length > 0 &&
            payload?.tag?.split(",")?.filter((item) => item != "")
          }
          onChange={(val) => {
            setPayload({ ...payload, tag: val.toString() });
          }}
        />
      </div>
      <div className="mt-3">
        <Textareas
          rows={13}
          placeholder="Masukan Deskripsi "
          label="Deskripsi"
          id="deskripsi"
          name="deskripsi"
          value={payload.deskripsi}
          onChange={(e) => {
            setPayload({
              ...payload,
              deskripsi: e.target.value,
            });
          }}
        />
      </div>
    </div>
  );
};

export default Step1;
