import { DocumentCopy, Edit, Eye, Trash } from "iconsax-react";
import Rates from "../../../../../components/form/rate";
import {
  editDataMaster,
  getDetailDataAkomodasi,
  getDetailDataTransport,
} from "stores/actions/datamaster";
import uuid from "react-uuid";
import { getLocalStorage } from "utils/helpers";

const userLogin =
  getLocalStorage("userLogin") && JSON.parse(getLocalStorage("userLogin"));
const permissions = userLogin?.data?.account?.permissions;

export const columns = (
  handleOpen,
  setActionHandle,
  setIsModal,
  openModal,
  dispatch,
  formikValue,
  setCheckName
) => [
  {
    title: "Logo",
    dataIndex: "logo",
    render: (img) => (
      <img
        className="rounded-full w-10 h-10 object-cover"
        alt="logo maskapai"
        src={img}
      />
    ),
  },
  {
    title: "Nama",
    dataIndex: "name",
  },

  {
    title: "Kategori",
    dataIndex: "kategori",
  },
  {
    title: "Kelas Bintang",
    dataIndex: "bintang",
    render: (s) => (
      <div>
        <Rates value={s} disabled={true} />
      </div>
    ),
  },
  {
    title: "Map",
    dataIndex: "alamat_lengkap",
  },
  {
    title: "Deskripsi",
    dataIndex: "deskripsi",
    className: "whitespace-nowrap relative",

    render: (s, record) => (
      <div className="w-full relative flex flex-row justify-between">
        {s !== null && s?.length > 150 ? `${s?.substr(0, 150)}...` : s}
        <div className="action absolute !w-full top-2 flex !flex-row !justify-end">
          <div className="flex flex-row justify-end pr-[16px] gap-x-16">
            {permissions?.includes("delete_master-data") && (
              <Trash
                onClick={() => {
                  handleOpen();
                  setActionHandle(record.id_akomodasi);
                }}
                size="16"
                color="#141414"
                className="cursor-pointer"
              />
            )}
            {permissions?.includes("create_master-data") && (
              <DocumentCopy
                onClick={() => {
                  formikValue("id", record.id_akomodasi);
                  setIsModal(true);
                }}
                size="16"
                color="#141414"
                className="cursor-pointer"
              />
            )}
            {permissions?.includes("update_master-data") && (
              <Edit
                className="cursor-pointer"
                onClick={() => {
                  openModal(true);
                  dispatch(editDataMaster("edit akomodasi"));
                  dispatch(getDetailDataAkomodasi(record.id_akomodasi, uuid()));
                }}
                size="16"
                color="#141414"
              />
            )}
          </div>
        </div>
      </div>
    ),
  },
];
