import React from "react";
import PropTypes from "prop-types";

import ImageField from "components/image_field";

const AddGaleriUpload = ({ setFieldValue, value, errors }) => {
  return (
    <>
      <ImageField
        id="image"
        name="image"
        label="Upload Gambar"
        error={errors.image}
        errorMesssage={errors.image}
        setFile={(file) => setFieldValue("image", file?.path)}
      />
    </>
  );
};

AddGaleriUpload.propTypes = {
  setFieldValue: PropTypes.func,
  value: PropTypes.object,
  errors: PropTypes.object,
};

export default React.memo(AddGaleriUpload);
