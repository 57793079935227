import {
  ERROR_FETCHING_STAFF,
  SET_ADD_OR_EDIT_STAFF,
  SET_DELETE_STAFF,
  SET_PAGE_STAFF,
  SET_SIZE_STAFF,
  SET_TOTAL_STAFF,
  START_FETCHING_STAFF,
  SUCCESS_FETCHING_STAFF,
} from "stores/actions/apps/staff/actionTypes";

const statuslist = {
  idle: "idle",
  process: "process",
  success: "success",
  error: "error",
};

const initialState = {
  data: [],
  total: 100,
  per_page: 10,
  page_number: 1,
  sort_column: "",
  sort_order: "Desc",
  keyword: "",
  status: statuslist.idle,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case START_FETCHING_STAFF:
      return { ...state, status: statuslist.process };

    case ERROR_FETCHING_STAFF:
      return { ...state, status: statuslist.error };

    case SUCCESS_FETCHING_STAFF:
      return {
        ...state,
        data: action.payload,
        status: statuslist.success,
      };
    case SET_PAGE_STAFF:
      return { ...state, page_number: action.page };

    case SET_SIZE_STAFF:
      return { ...state, per_page: action.size };

    case SET_TOTAL_STAFF:
      return { ...state, total: action.total };
    case SET_ADD_OR_EDIT_STAFF:
      if (action.payload && action.payload.data && !action.edit) {
        state["data"].push(action.payload.data);
      } else {
        state["data"].map((val, idx) => {
          if (val.id === action.payload.data.id) {
            state["data"][idx] = action.payload.data;
          }
        });
      }
      return state;
    case SET_DELETE_STAFF:
      state["data"] = state["data"].filter((list) => list.id !== action.id);
      return state;
    default:
      return state;
  }
}
