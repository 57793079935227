import React from "react";
import PropTypes from "prop-types";
import { Drawer } from "antd";

import DrawersStyle from "./Drawer.Style";

const Drawers = ({
  containerClass,
  customClass,
  placement,
  open,
  key,
  children,
  onClose,
}) => {
  return (
    <DrawersStyle className={`${containerClass}`}>
      <Drawer
        className={`custom-drawer ${customClass}`}
        placement={placement}
        open={open}
        key={key}
        closable={false}
        onClose={onClose}
        getContainer={() => document.getElementById("area-drawer")}
      >
        {children}
      </Drawer>
      <div id="area-drawer"></div>
    </DrawersStyle>
  );
};

Drawers.propTypes = {
  containerClass: PropTypes.string,
  customClass: PropTypes.string,
  title: PropTypes.string,
  placement: PropTypes.string,
  open: PropTypes.bool,
  key: PropTypes.bool,
  onClose: PropTypes.func,
};

Drawers.defaultProps = {
  containerClass: "",
  customClass: "",
  title: "Drawer",
  placement: "right",
  open: false,
  key: "right",
  onClose: () => {},
};

export default React.memo(Drawers);
