import React from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
import ChartStyle from "./Chart.style";

const Chart = ({ containerClass, data, categories, type, colors }) => {
  const options = {
    chart: {
      height: 350,
      type: "area",
      toolbar: {
        show: false,
        autoSelected: "pan",
      },
      locales: [{
        "name": "en",
        "options": {
          "months": ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"]
        }
      }],
      defaultLocale: "en"
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      axisBorder: {
        show: true,
        color: "#4F4F4F",
        height: 1,
        width: "100%",
        offsetX: 0,
        offsetY: 0,
      },
    },
    yaxis: {
      axisBorder: {
        show: true,
        width: 1,
        color: "#4F4F4F",
        offsetX: 0,
        offsetY: 0,
      },
    },
    colors: colors,
    tooltip: {
      x: {
        position: 'top',
        formatter: function (value) {
          const date = new Date(value);
          const day = date.getDate();
          const month = new Intl.DateTimeFormat('id-ID', { month: 'long' }).format(date);
          const year = date.getFullYear();
          return `${day} ${month} ${year}`;
        }
      },
      y: {
        formatter: function (value, { series, seriesIndex, dataPointIndex }) {
          const chartName = data[seriesIndex].name;
          return value + ' ' + chartName.replace(chartName + ':', '');
        }
      }
    },
  };

  options.xaxis.categories = categories;

  return (
    <ChartStyle className={`custom-chart ${containerClass}`}>
      <ReactApexChart
        options={options}
        series={data}
        type={type}
        height={350}
      />
    </ChartStyle>
  );
};

Chart.propTypes = {
  containerClass: PropTypes.string,
  data: PropTypes.array,
  type: PropTypes.string,
  colors: PropTypes.array,
};

Chart.defaultProps = {
  containerClass: "",
  data: [],
  type: "area",
  colors: ["#DA4646", "#DA4646", "#DA4646"],
};

export default Chart;
