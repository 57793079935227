import { makeStyles } from "@material-ui/core";
import React, { useState, useCallback } from "react";
import Popup from ".";
import Buttons from "../buttons";
import Inputs from "../form/inputs";
const useStyles = makeStyles({
  button: {
    width: "150px",
    margin: "20px",
  },
});
const PopupDocs = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [opensecond, setOpensecond] = useState(false);
  const handleClosePopup = useCallback(() => {
    setOpen(false);
  }, []);
  const handleClosePopupSecond = useCallback(() => {
    setOpensecond(false);
  }, []);
  return (
    <div>
      <div className={classes.button}>
        <Buttons
          text="Open Popup"
          customClass="btn-primary btn-medium"
          onClick={() => setOpen(true)}
        />
      </div>
      <Popup
        open={open}
        handleClose={handleClosePopup}
        title="Duplicate Coupon"

      >
        <Inputs
          id="test"
          placeholder="Text Input"
          customClass="input-disabled"
        />
      </Popup>

      <div className={classes.button}>
        <Buttons
          text="Open Popup second"
          customClass="btn-primary btn-medium"
          onClick={() => setOpensecond(true)}
        />
      </div>

      <Popup
        type="secondary"
        open={opensecond}
        handleClose={handleClosePopupSecond}
        title="Edit Profile"
      >
        <Inputs
          id="test"
          placeholder="Text Input"
          customClass="input-disabled"
        />
      </Popup>
    </div>
  );
};

export default PopupDocs;
