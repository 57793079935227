/* eslint-disable react/jsx-no-duplicate-props */
import { Edit, Trash } from "iconsax-react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  IcArrowBottom,
  IcClose,
  IcDocCopy,
  IcTambah,
  IcTanggal,
} from "assets/icons/dashboard";
import { ImCardAceh } from "assets/images";
import Badge from "components/badge";
import Buttons from "components/buttons";
import Cards from "components/cards";
import { Tooltip } from "antd";
import { resetDataUpload } from "stores/actions/uploadFile";

const DetailItinenary = ({
  setIsDuplicated,
  setIsDrawer,
  setIsEditItenary,
  setIsDelete,
  setIsModalImage,
  detailItenary,
  setListDataGalery,
  setChoice,
  getListStartingItenaryV2,
  setItenary,
}) => {
  const dispatch = useDispatch();
  const title = (item) => (
    <div className="text-white items-center text-center justify-between p-3 text-sm flex-col">
      <div className="text-center font-semibold capitalize">
        {item?.airport_departure_city}
      </div>
      <div class="flex z-50 items-center">
        <div class="border-b border-white flex-grow"></div>
        <div class="px-2">
          <img alt="arrow" src={IcArrowBottom} />
        </div>
        <div class="border-b border-white flex-grow"></div>
      </div>
      <div className="text-center font-semibold capitalize">
        {item?.airport_arrival_city}
      </div>
    </div>
  );

  return (
    <div className="relative flex flex-col  justify-start">
      <Cards containerClass="flex flex-row justify-between !p-4 items-center">
        <div className="text-lg font-semibold flex flex-col">
          Daftar Itinerary
          <Tooltip placement="bottom" title={title(detailItenary)}>
            <Badge
              backgroundColor={detailItenary?.starting?.bg_color}
              color={detailItenary?.starting?.text_color}
              label={detailItenary?.starting?.name}
              customClass=" px-4 text-center py-1  rounded-lg text-xs"
            />
          </Tooltip>
        </div>
        <div>
          <img
            className="cursor-pointer h-6 w-6"
            src={IcClose}
            alt="close"
            onClick={() => {
              setIsDrawer(false);
            }}
          />
        </div>
      </Cards>
      {detailItenary?.itineraries?.length !== 0 && (
        <main className="overflow-auto scrollbar-thin scrollbar-track-transparent">
          <div className=" flex justify-end items-end my-5">
            <Buttons
              text="Duplicate"
              customClass="btn-outline btn-small"
              icon={IcDocCopy}
              onClick={() => {
                getListStartingItenaryV2();
              }}
            />
          </div>
          {detailItenary?.itineraries?.map((item, idx) => (
            <div className="flex w-full" key={`itenary-${idx}`}>
              <div className="mr-4 flex flex-col items-center @apply shadow-[0px_4px_80px_-4px_#0000000A]">
                <div>
                  <div className="flex h-10 w-10 bg-red-1 text-white border-2 border-white items-center justify-center rounded-full font-semibold">
                    {idx + 1}
                  </div>
                </div>
                <div className="h-full w-[0.1px] bg-[#DEDEDE] outline-dashed "></div>
              </div>
              <div className={"pt-1 pb-8 w-full"}>
                <div className="flex gap-x-4 items-start justify-start">
                  <main className="bg-white drop-shadow rounded-2xl px-4 pb-4 !w-full">
                    <section className="relative rounded-2xl py-4">
                      <img
                        alt="location"
                        src={item?.thumbnail ? item?.thumbnail : ImCardAceh}
                        className="h-36 w-full bg-cover object-cover rounded-2xl"
                      />
                      <span className="rounded-tl-2xl absolute top-4 left-0 rounded-br-2xl px-3 py-2 bg-[#EDBABA] text-[#FE0000]">
                        {item?.rute?.join(",")}
                      </span>
                      <span className="p-2 absolute rounded-bl-2xl top-4 right-0  bg-white cursor-pointer rounded-tr-2xl">
                        <div className="flex gap-x-2 items-center">
                          <Edit
                            size={16}
                            onClick={() => {
                              setItenary(item);
                              setIsEditItenary(true);
                            }}
                          />
                          <Trash
                            size={16}
                            onClick={() => {
                              setChoice(item);
                              setIsDelete(true);
                            }}
                          />
                        </div>
                      </span>
                    </section>
                    <section className="flex justify-between items-center">
                      <Badge
                        label={`Hari ke-${item?.day}`}
                        backgroundColor="#D8E1BE"
                        color="#3D3D3D"
                      />
                      <div className="flex items-center gap-x-2 text-xs">
                        <img alt="tanggal" src={IcTanggal} />
                        <span>{item?.date}</span>
                      </div>
                    </section>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `<ul><li>Makan</li><li>Minum</li></ul>`,
                      }}
                    />

                    <div
                      className="flex items-center gap-x-3 cursor-pointer"
                      onClick={() => {
                        setListDataGalery(item);
                        setIsModalImage(true);
                      }}
                    >
                      {item?.gallery?.map((item, idx) => {
                        return (
                          <div
                            key={`galery-${idx}`}
                            className={`${
                              idx > 3 &&
                              "contrast-50 rounded-lg flex justify-center items-center"
                            } relative cursor-pointer`}
                          >
                            <img
                              alt="gambar"
                              src={item ? item?.link : ImCardAceh}
                              className="w-16 h-16 rounded-lg object-cover"
                            />
                            {idx > 3 && (
                              <div className="text-xl absolute font-medium text-white">
                                +1
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </main>
                </div>
              </div>
            </div>
          ))}
        </main>
      )}
      <div className="mt-5 grow flex justify-end flex-col">
        <Buttons
          text="Rute Hari"
          customClass="btn-outline btn-medium"
          onClick={() => {
            setIsEditItenary(true);
            setItenary(null);
            dispatch(resetDataUpload());
          }}
          icon={IcTambah}
        />
      </div>
    </div>
  );
};

export default DetailItinenary;
