import React from "react";
import { Switch } from "antd";
import PropTypes from "prop-types";

import SwitchsStyle from "./Switchs.style";

const Switchs = ({
  containerClass,
  customClass,
  id,
  defaultChecked,
  label,
  onChange,
  checked,
}) => {
  return (
    <SwitchsStyle className={`flex flex-row ${containerClass}`}>
      {label && <label>{label}</label> }
      <Switch
        id={id}
        checkedChildren="ON"
        unCheckedChildren="OFF"
        className={`${customClass}`}
        defaultChecked={defaultChecked}
        checked={checked}
        onChange={onChange}
      ></Switch>
    </SwitchsStyle>
  );
};

Switchs.propTypes = {
  containerClass: PropTypes.string,
  customClass: PropTypes.string,
  id: PropTypes.string,
  defaultChecked: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

Switchs.defaultProps = {
  containerClass: "",
  customClass: "",
  id: "",
  defaultChecked: false,
  label: "",
  onChange: () => {},
};

export default React.memo(Switchs);
