import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";

import ButtonStyle from "./Button.style";
import Spinner from "../spinner";

const Buttons = ({
  customClass,
  containerClass,
  id,
  disabled,
  icon,
  iconPosition,
  loading,
  text,
  onClick,
  style,
}) => {
  return (
    <ButtonStyle className={`${containerClass}`}>
      <Button
        id={id}
        disabled={disabled}
        className={`flex items-center justify-center custom-btn ${loading && 'loading'} ${customClass}`}
        onClick={onClick}
        style={style}
      >
        {icon && iconPosition === "left" && (
          <img src={icon} alt="left" className="mr-2" />
        )}
        {loading && <Spinner customClass="mr-2" />}
        {text}
        {icon && iconPosition === "right" && (
          <img src={icon} alt="right" className="ml-2" />
        )}
      </Button>
    </ButtonStyle>
  );
};

Buttons.propTypes = {
  customClass: PropTypes.string,
  containerClass: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.any,
  iconPosition: PropTypes.string,
  loading: PropTypes.bool,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

Buttons.defaultProps = {
  customClass: "",
  containerClass: "",
  id: "",
  disabled: false,
  icon: "",
  iconPosition: "left",
  loading: false,
  text: "Submit",
  onClick: () => {},
};

export default React.memo(Buttons);
