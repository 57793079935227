const { default: ListMember } = require("./components/ListMember");

export const columns = [
  {
    title: "",
    dataIndex: "image_profile",
    render: (image_profile, data, idx) => (
      <ListMember
        img={image_profile}
        name={data?.name}
        role={data?.nama_role}
        cabang={data?.nama_cabang}
      />
    ),
  },
];
