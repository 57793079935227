import React, { useEffect, useState } from "react";
import Inputs from "../../../../../../components/form/inputs";
import ImageField from "../../../../../../components/image_field";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Buttons from "../../../../../../components/buttons";
import { editDataMaskapai } from "../../../../../../stores/actions/datamaster";
import Add from "../../../../../../assets/icons/add-circle.svg";
import ListUrl from "../../../listurl";
import TextAreas from "../../../../../../components/form/textarea";

function EditGallery({
  valueImage,
  valueVideo,
  formikValue,
  handleSave,
  setDataVideo,
  dataVideo,
  loading
}) {
  const [data, setData] = useState("");

  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item sm={12} md={6}>
        <ImageField
          label="Galeri Akomodasi"
          customContainer="!w-auto h-[20rem] !rounded-2xl"
          isMulti={true}
          name="gallery"
          setFile={() => formikValue("images", valueImage)}
        />
      </Grid>
      <Grid item sm={12} md={6}>
        <Inputs
          id="link"
          label="Link Video Galeri"
          placeholder="Masukan Link"
          iconPosition="right"
          icon={Add}
          onChange={(e) => setData(e.target.value)}
          onClick={(e) => {
            setDataVideo([...dataVideo, data]);
          }}
        />
        <div className="my-4 ">
          <ListUrl data={dataVideo} setDataVideo={setDataVideo} />
        </div>
      </Grid>

      <div className="w-full flex justify-end pt-4 mt-4 items-center border-t border-gray-300">
        <div className="w-64">
          <Buttons
            onClick={handleSave}
            text="Simpan"
            customClass="btn-primary btn-large"
            loading={loading}
            />
        </div>
      </div>
    </Grid>
  );
}

export default EditGallery;
