import { makeStyles } from "@material-ui/core";
import "./travel.scss";
import Router from "./router";

const useStyle = makeStyles({
  content: {
    height: "100%",
  },
});

function App() {
  const classes = useStyle();
  return (
    <div className={classes.content}>
      <Router />
    </div>
  );
}

export default App;
