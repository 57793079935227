import { useEffect, useLayoutEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { Select } from "antd";

import ImageField from "../../../../../../components/image_field";
import Inputs from "../../../../../../components/form/inputs";
import Selects from "../../../../../../components/form/selects";
import axios from "axios";
import { BASE_URL_ENDPOINT } from "constant/constanta";
import { useDispatch, useSelector } from "react-redux";
import { getMasterMusim } from "stores/actions/datamaster/musim";
import { getLocalStorage } from "utils/helpers";

const useStyle = makeStyles({
  step1: {
    "& .input-label, .select-label": {
      fontSize: "14px",
      fontWeight: 600,
      color: "#4f4f4f",
      marginBottom: "4px",
    },
    "& .ant-picker": {
      width: "100%",
    },
    "& .ant-select-selector": {
      borderColor: "transparent",
      boxShadow: "none !important",
      padding: "10px 16px",
      minHeight: "54px",
      backgroundColor: "#f2f2f2 !important",
      border: "0 !important",
      borderRadius: "12px",
      width: "100%",
    },
    "& .ant-select-selection-item": {
      background: "#fff",
      borderRadius: "30px !important",
      height: "fit-content",
      padding: "4px 10px !important",
    },
    "& .select-label": {
      fontSize: "16px",
      fontWeight: "600",
      color: "rgb(79, 79, 79)",
      marginBottom: "8px",
    },
    "& .ant-select-selection-placeholder": {
      color: "#9CA3AF",
      left: "20px",
      fontSize: "14px",
    },
  },
  img_field_custom: {
    cursor: "pointer",
    height: "fit-content",
    padding: "2rem",
    width: "100%",
  },
  custom_dropdown: {
    "& .rc-virtual-list-holder-inner": {
      flexDirection: "row !important",
      flexWrap: "wrap",
      gap: "6px !important",
    },
    "& .ant-select-item-option": {
      border: "1px solid #ccc",
      borderRadius: "50px !important",
    },
    "& .ant-select-item-option-content": {
      marginRight: "6px !important",
    },
  },
});

const dataCategoryPaket = [
  {
    id: "1",
    label: "VIP",
  },
  {
    id: "2",
    label: "Hemat",
  },
  {
    id: "3",
    label: "Super Hemat",
  },
  {
    id: "4",
    label: "Extra Hemat",
  },
];

const Step1 = ({ payload, setPayload }) => {
  const classes = useStyle();
  const [maskapaiOptions, setMaskapaiOptions] = useState([]);
  const { all_musim, isLoading } = useSelector((state) => state.musim);
  const [optionMusim, setOptionMusim] = useState([]);
  const [categoryPaket, setCategoryPaket] = useState(dataCategoryPaket);
  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    setMonths([
      { value: "01", label: "Januari" },
      { value: "02", label: "Februari" },
      { value: "03", label: "Maret" },
      { value: "04", label: "April" },
      { value: "05", label: "Mei" },
      { value: "06", label: "Juni" },
      { value: "07", label: "Juli" },
      { value: "08", label: "Agustus" },
      { value: "09", label: "September" },
      { value: "10", label: "Oktober" },
      { value: "11", label: "November" },
      { value: "12", label: "Desember" },
    ]);

    setYears(onHandleSetYears());
  }, []);

  const onHandleSetYears = () => {
    const yearNow = new Date().getFullYear();

    // Membuat daftar tahun
    const yearList = [];
    for (let i = 0; i <= 5; i++) {
      const yearSelected = yearNow + i;
      yearList.push({ value: String(yearSelected), label: yearSelected });
    }

    yearList.sort((a, b) => a - b);

    return yearList;
  };

  useEffect(() => {
    dispatch(
      getMasterMusim({
        keyword: "",
        sort_column: "",
        sort_order: "DESC",
      })
    );
    console.log(payload)
  }, []);

  useEffect(() => {
    if (Object.keys(categoryPaket).length > 0) {
      const result_map = categoryPaket?.map((item) => {
        return {
          label: `${item.label}`,
          value: item.id,
        };
      });

      setCategoryPaket(result_map);
    }
  }, [categoryPaket]);

  useEffect(() => {
    if (Object.keys(dataCategoryPaket).length > 0) {
      const result_map = dataCategoryPaket?.map((item) => {
        return {
          label: `${item.label}`,
          value: item.id,
        };
      });

      setOptionMusim(result_map);
    }
  }, [all_musim]);

  return (
    <div className={classes.step1}>
      <div className="flex gap-6">
        <Inputs
          label="Nama Paket"
          placeholder="Masukan nama paket"
          containerClass="w-full"
          value={payload.nama_paket}
          onChange={(e) => {
            setPayload({
              ...payload,
              nama_paket: e.target.value,
            });
          }}
        />
        <Selects
          label="Kategori Paket"
          placeholder="Kategori Paket"
          value={optionMusim.filter(
            (item) => payload?.kategori_paket === item.value
          )}
          options={optionMusim}
          onChange={(e) =>
            setPayload({ ...payload, kategori_paket: e[0].value })
          }
        />
      </div>
      <div className="mt-6">
        <div className="select-label mb-4">Jumlah Hari</div>
        <Inputs
          label="Total Hari"
          placeholder="Masukan jumlah hari total"
          containerClass="w-full"
          type="number"
          value={payload.jumlah_hari}
          onChange={(e) => {
            setPayload({
              ...payload,
              jumlah_hari: e.target.value,
            });
          }}
        />
      </div>
      <div className="mt-6">
        <div className="select-label mb-4">Waktu Keberangkatan</div>
        <div className="flex gap-6">
          <Selects
            label="Bulan"
            placeholder="Pilih bulan"
            options={months}
            value={months.filter(
              (item) => payload?.bulan === item.value
            )}
            onChange={(e) => setPayload({ ...payload, bulan: e[0].value })}
          />
          <Selects
            label="Tahun"
            placeholder="Pilih tahun"
            options={years}
            value={years.filter(
              (item) => payload?.tahun === item.value
            )}
            onChange={(e) => setPayload({ ...payload, tahun: e[0].value })}
          />
        </div>
      </div>
    </div>
  );
};

export default Step1;
