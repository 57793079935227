import React, { useEffect, useState } from "react";
import Buttons from "components/buttons";
import Inputs from "components/form/inputs";
import RichTextEditor from "components/richTextEditor";
import EditorToolbar, {
    modules,
    formats,
} from "components/richTextEditor/EditorToolbar";
import ReactQuill from "react-quill";
import Textarea from "components/form/textarea";
import Selects from "components/form/selects";
import Checkbox from "components/checkbox";
import Badge from "components/badge";
import { useDispatch, useSelector } from "react-redux";
import { toBase64 } from "utils/helpers";
import { addDoaAction, getDoaAction } from "stores/actions/layananIslami/doa";

const PopupAddTambahDoa = ({ handleClosePopup, getDoa, data }) => {
    const dispatch = useDispatch();
    const { doa_category } = useSelector((state) => state.doa);

    const [isSubmitting, setIsSubmitting] = useState("");
    const [checked, setChecked] = useState(false);
    const [indeterminate, setIndeterminate] = useState(false);

    const [judul, setJudul] = useState(data?.judul || "");
    const [doaArab, setDoaArab] = useState(data?.doa_arab || "");
    const [doaLatin, setDoaLatin] = useState(data?.doa_latin || "");
    const [doaTerjemahan, setDoaTerjemahan] = useState(
        data?.terjemahan_doa || ""
    );
    const [kategori, setKategori] = useState(data?.kategori_doa_id || "");

    const [options, setOptions] = useState([]);

    const payload = {
        judul: judul,
        doa_arab: doaArab,
        doa_latin: doaLatin,
        terjemahan_doa: doaTerjemahan,
        kategori_doa_id: kategori.value,
    };

    const addDoa = async () => {
        const formData = new FormData();
        setIsSubmitting(true);
        formData.append("id", data.id_doa);
        formData.append("judul", payload.judul);
        formData.append("doa_arab", payload.doa_arab);
        formData.append("doa_latin", payload.doa_latin);
        formData.append("terjemahan_doa", payload.terjemahan_doa);
        formData.append(
            "kategori_doa_id",
            payload.kategori_doa_id || data.kategori_doa_id
        );
        try {
            const status = await dispatch(addDoaAction(formData));
            if (status == 200) {
                handleClosePopup();
                getDoa();
                setIsSubmitting(false);
            }
        } catch (error) {
            setIsSubmitting(false);
        }
    };

    useEffect(() => {
        if (doa_category?.data?.length) {
            const cateogry_mapped = doa_category.data.map((item) => ({
                value: item.id_kategori_doa,
                label: item.nama_kategori_doa,
                color: "#6C757D",
                backgroundColor: "#CED4DA",
            }));
            setOptions(cateogry_mapped);
        }
    }, [doa_category]);

    return (
        <div className="border rounded-lg w-full !bg-white">
            <div className="flex flex-col gap-[16px] border rounded-lg !bg-white w-full p-[16px] overflow-auto">
                <div>
                    <Inputs
                        id="test"
                        label="Judul"
                        placeholder="Masukkan Judul"
                        onChange={(e) => setJudul(e.target.value)}
                        value={judul}
                    />
                </div>
                <div className="">
                    <div className="text-[#4F4F4F] font-semibold mt-4 mb-2">
                        Do'a Arab
                    </div>
                    <div className="text-editor">
                        <EditorToolbar />
                        <ReactQuill
                            theme="snow"
                            value={doaArab}
                            modules={modules}
                            formats={formats}
                            className="bg-[#EDF2F4] w-full h-[300px]"
                            onChange={(e) => setDoaArab(e)}
                        />
                    </div>
                </div>
                <div>
                    <Textarea
                        id="test"
                        placeholder="Masukkan Do'a Latin"
                        label="Do'a Latin"
                        customClass="!h-[222px]"
                        onChange={(e) => setDoaLatin(e.target.value)}
                        value={doaLatin}
                    />
                </div>
                <div>
                    <Textarea
                        id="test"
                        placeholder="Masukkan Terjemahan Do'a"
                        label="Terjemahan Do'a"
                        customClass="!h-[222px]"
                        onChange={(e) => setDoaTerjemahan(e.target.value)}
                        value={doaTerjemahan}
                    />
                </div>
                <div>
                    <Selects
                        label="Kategori"
                        placeholder="Kategori"
                        options={options}
                        searchable
                        customClass="!w-[880px]"
                        containerClass="!w-[880px]"
                        onChange={(e) => setKategori(e[0])}
                        itemRenderer={({ state, item, methods }) => {
                            return (
                                <>
                                    <div className="flex flex-col gap-[8px] p-[8px]">
                                        <div className="flex flex-row justify-start items-center gap-[8px] p-[4px] border rounded-lg w-full">
                                            <div>
                                                <Checkbox
                                                    indeterminate={
                                                        indeterminate
                                                    }
                                                    checked={checked}
                                                    onChange={(e) => {
                                                        setChecked(
                                                            e.target.checked
                                                        );
                                                        setIndeterminate(false);
                                                    }}
                                                />
                                            </div>
                                            <div
                                                className={`react-dropdown-select-item ${
                                                    state.values.length !== 0 &&
                                                    state.values[0].value ===
                                                        item.value &&
                                                    "react-dropdown-select-item-selected"
                                                } !w-full !border-0`}
                                                onClick={() =>
                                                    methods.addItem(item)
                                                }
                                            >
                                                <Badge
                                                    label={item.label}
                                                    color={item.color}
                                                    backgroundColor={
                                                        item.backgroundColor
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            );
                        }}
                    />
                </div>
            </div>
            <div className="flex flex-col justify-end border-t rounded-lg py-[8px] px-[20px]">
                <div className="flex flex-row justify-end gap-[8px]">
                    <Buttons
                        text="Kembali"
                        customClass="btn-outline btn-medium !w-[186px] float-right"
                        onClick={handleClosePopup}
                    />
                    <Buttons
                        text="Tambah"
                        customClass="btn-primary btn-medium !w-[186px] float-right"
                        onClick={addDoa}
                        loading={isSubmitting}
                    />
                </div>
            </div>
        </div>
    );
};

export default PopupAddTambahDoa;
