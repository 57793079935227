import React, { useState } from "react";
import ImageField from "../../../../../components/image_field";
import Inputs from "../../../../../components/form/inputs";
import Add from "../../../../../assets/icons/add-circle.svg";
import TextAreas from "../../../../../components/form/textarea";
import Grid from "@mui/material/Grid";
import ListUrl from "../../listurl";

function InfoPerlengkapan({
  formikOnChange,
  valueDescription,
  setVariant,
  variant,
  formikValue,
  valueName,
}) {
  const [data, setData] = useState("");

  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item sm={12}>
        <Inputs
          id="Nama Perlengkapan"
          name="name"
          label="Nama Perlengkapan"
          placeholder="Masukan nama perlengkapan"
          onChange={formikOnChange}
          value={valueName}
        />
      </Grid>
      <Grid item sm={12} md={6}>
        <ImageField
          label="Thumbnail Perlengkapan"
          name="image_logo"
          width="100%"
          height="16rem"
          customContainer="!rounded-2xl"
          setFile={(file) => {
            formikValue("image_logo", file?.path);
          }}
        />
      </Grid>
      <Grid item sm={12} md={6}>
        <Inputs
          id="variant"
          label="Tambahkan Varian"
          placeholder="Masukan varian"
          iconPosition="right"
          icon={Add}
          onChange={(e) => setData(e.target.value)}
          onClick={(e) => {
            setVariant([...variant, data]);
          }}
        />
        <div className="my-4 ">
          <ListUrl data={variant} setDataVideo={setVariant} />
        </div>
      </Grid>
    </Grid>
  );
}

export default InfoPerlengkapan;
