import React, { useState } from "react";

import EditorToolbar, {
  formats,
  modules,
} from "components/richTextEditor/EditorToolbar";
import ReactQuill from "react-quill";

const FollowUpTwo = () => {
  const [input, setInput] = useState();

  return (
    <>
      <div className="border border-[#E0E0E0] rounded-2xl">
        <div className="grid grid-cols-2 p-4">
          <div className="font-semibold text-[#4F4F4F]">Kode Pesan</div>
          <div className="font-semibold text-[#4F4F4F]">Keterangan</div>
        </div>

        <div className="grid grid-cols-2 p-4 bg-[#FBF3F2]">
          <div>[name]</div>
          <div>Nama yang ditagihkan</div>
        </div>

        <div className="grid grid-cols-2 p-4 bg-white">
          <div>[no_order]</div>
          <div>Nama Order</div>
        </div>

        <div className="grid grid-cols-2 p-4 bg-[#FBF3F2]">
          <div>[minimal_dp]</div>
          <div>Minimal DP</div>
        </div>

        <div className="grid grid-cols-2 p-4 bg-white">
          <div>[kekurangan]</div>
          <div>Kekurangan pembayaran</div>
        </div>

        <div className="grid grid-cols-2 p-4 bg-[#FBF3F2]">
          <div>[sudah_dibayarkan]</div>
          <div>Nominal sudah dibayarkan</div>
        </div>

        <div className="grid grid-cols-2 p-4 bg-white">
          <div>[nama_paket]</div>
          <div>Nama paket</div>
        </div>

        <div className="grid grid-cols-2 p-4 bg-[#FBF3F2]">
          <div>[tgl_order]</div>
          <div>Tanggal order</div>
        </div>

        <div className="grid grid-cols-2 p-4 bg-white">
          <div>[rincian_tagihan]</div>
          <div>Rincian tagihan</div>
        </div>
      </div>

      <div className="font-semibold min-h-[24px] mb-2 mt-4 text-[16px] text-[#4F4F4F]">
        Pesan Email
      </div>
      <div className="text-editor mt-4">
        <EditorToolbar />
        <ReactQuill
          id="content"
          name="content"
          theme="snow"
          value={input}
          onChange={(e) => setInput(e)}
          modules={modules}
          formats={formats}
          className="bg-[#EDF2F4] w-full h-[28vh]"
        />
      </div>

      <div className="font-semibold min-h-[24px] mb-2 mt-4 text-[16px] text-[#4F4F4F]">
        Pesan Whatsapp
      </div>
      <div className="text-editor mt-4">
        <EditorToolbar />
        <ReactQuill
          id="content"
          name="content"
          theme="snow"
          value={input}
          onChange={(e) => setInput(e)}
          modules={modules}
          formats={formats}
          className="bg-[#EDF2F4] w-full h-[28vh]"
        />
      </div>
    </>
  );
};

export default React.memo(FollowUpTwo);
