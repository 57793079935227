import React, { useCallback, useState } from "react";
import AlertPopup from ".";
import Buttons from "../../buttons";
const DocsAlertPopup = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);
  const handleContinue = useCallback(() => {
    console.log("handle continue action");
  }, []);
  return (
    <div>
      <AlertPopup
        open={open}
        handleClose={handleClose}
        handleContinue={handleContinue}
      />
      <div className="w-[200px]">
        <Buttons
          text="Success"
          customClass="btn-primary btn-medium"
          onClick={handleOpen}
        />
      </div>
    </div>
  );
};

export default DocsAlertPopup;
