import React from "react";
import Buttons from "components/buttons";
import ImageField from "components/image_field";

const DokumenUploadKK = () => {
  return (
    <>
      <div className="flex flex-col items-center gap-[16px] w-full h-fit">
        <div>
          <ImageField
            customContainer="!w-[550px] !h-[162px]"
            setFile={(file) => console.log("file1", file)}
            label="Upload KK"
            name="KK"
          />
        </div>
        <div className="">
          <Buttons
            text="Simpan"
            customClass="btn-primary btn-large !w-[579px] !h-[54]"
          />
        </div>
      </div>
    </>
  );
};

export default DokumenUploadKK;
