import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import IconPlane from "assets/icons/Penerbangan-aktif.svg";
import "./GeneralInfo.scss";
import PaketDetailGeneralInfoAction, {
  deleteManasikCabangPaket,
  getListManasikPaket,
  getManasikCabangPaket,
  handleGetBusById,
} from "stores/actions/paket/generalInfo";
import { useHistory } from "react-router-dom";

import Buttons from "components/buttons";
import Badge from "components/badge";
import Popup from "components/popup";
import AlertPopup from "components/popup/alert";

import PhotoKabah from "assets/images/fotoKabah.jpg";
import IconVip from "assets/icons/colored/ic-paket-vip.svg";
import CalendarIcon from "assets/icons/colored/calendar-icon.svg";
import SeatIcon from "assets/icons/colored/icon-seat-maroon.svg";
import BatasPendaftaranIcon from "assets/icons/colored/icon-batas-pendaftaran-maroon.svg";
import { Edit } from "iconsax-react";
import PopupContent from "../../pages/generalInfo/components/PopupContent";
import moment from "moment";
import { Select } from "antd";
import { detailById } from "stores/actions/general";
import Item from "../../pages/generalInfo/components/PopupMenu/Manasik/Item";
import IconPengumpulanberkas from "assets/icons/icon-pengumpulan-berkas-maroon.svg";
import IconBatasDP from "assets/icons/icon-batas-pelunasan-maroon.svg";
import IconBatasPelunasan from "assets/icons/batas_lunas.svg";
import IconMinimumDP from "assets/icons/dp_minimum.svg";
import { useParams } from "react-router-dom";
import { handleSuccess } from "stores/actions/errorGeneral";

const GeneralInfo = () => {
  const dispatch = useDispatch();
  const [listManasik, setListManasik] = useState([]);

  let { id } = useParams();
  const { byDetail } = useSelector((state) => state.general);
  const store = useSelector(({ generalInfo }) => generalInfo);
  const [detail, setDetail] = useState();
  const router = useHistory();
  const [open, setOpen] = useState(false);
  const handleClosePopup = useCallback(() => {
    setOpen(false);
    dispatch(detailById({}));
  }, [dispatch]);
  const [successEdit, setSuccessEdit] = useState(false);
  const [listPembimbing, setListPembimbing] = useState([]);
  const [listGuide, setListGuide] = useState([]);
  const [dataCabangManasik, setDataCabangManasik] = useState([]);
  const [choiceManasik, setChoiceManasik] = useState(null);
  const [isDeleteManasik, setIsDeleteManasik] = useState(false);
  const [isLoadingDelManasik, setIsLoadingDeleteManasik] = useState(false);
  const [choiceIndex, setChoiceIndex] = useState(null);

  useEffect(() => {
    let id = router.location.pathname.replace(/\D/g, "");
    if (id) {
      dispatch(PaketDetailGeneralInfoAction.fetchData(id));
      dispatch(handleGetBusById(id));
    }
    if (successEdit) {
      setSuccessEdit(false);
    }
  }, [dispatch, router.location.pathname, successEdit]);

  useEffect(() => {
    if (store?.data) {
      setDetail(store.data);
      store.data.pembimbing_paket.map((val, idx) => {
        if (val.type === "guide") {
          setListGuide((prevState) => [...prevState, val]);
        } else {
          setListPembimbing((prevState) => [...prevState, val]);
        }
      });
    }
  }, [store.data]);

  const [items, setItems] = useState([
    {
      koordinat: {
        lat: -7.7955798,
        lng: 110.3694896,
      },
      tanggal_pelaksanaan: "Sabtu, 2 Agustus 2023",
      waktu_pelaksanaan: "13.00-Selesai",
      pesan_undangan: "",
    },
  ]);

  useEffect(() => {
    dispatch(
      getListManasikPaket(id, (message) => {
        setListManasik(message.data);
      })
    );
  }, [id]);

  const addItem = () => {
    setItems([
      ...items,
      {
        koordinat: {
          lat: 0,
          lng: 0,
        },
        tanggal_pelaksanaan: "",
        waktu_pelaksanaan: "",
        pesan_undangan: "",
      },
    ]);
  };

  const removeItem = (item_index) => {
    setIsLoadingDeleteManasik(true);
    let data = dataCabangManasik[item_index - 1];
    dispatch(
      deleteManasikCabangPaket(data.id_manasik, (message) => {
        handleClickManasikCabang(data.cabang_id);
        setIsLoadingDeleteManasik(false);
        setIsDeleteManasik(false);
        dispatch(handleSuccess(message));
      })
    );
  };

  const handleClickManasikCabang = (id_cabang) => {
    const payload = {
      id: id,
      cabang_id: id_cabang,
    };

    dispatch(
      getManasikCabangPaket(payload, (message) => {
        if (message.data.length > 0) {
          let resultMap = message.data?.map((item) => {
            return {
              ...item,
              koordinat: {
                lat: parseFloat(item.lotitude),
                lng: parseFloat(item.langitude),
              },
              lokasi: item.lokasi,
              tanggal_pelaksanaan: item.date,
              waktu_pelaksanaan: `${item.start_at}-Selesai`,
              pesan_undangan: item.message,
            };
          });
          setDataCabangManasik(resultMap);
        } else {
          setDataCabangManasik([]);
        }
      })
    );
  };

  const handleClickEditManasikGeneralInfo = (data) => {
    dispatch(
      detailById({
        status: true,
        menu: "Manasik",
        type: 2,
      })
    );
  };

  const handleClosePopUpConfirmManasik = () => {
    setIsDeleteManasik(false);
  };

  return (
    <>
      {detail && (
        <div className="w-[100%]">
          <div className="flex flex-row justify-between p-[16px] gap-[12px] w-[100%]">
            <div className="w-[100%] h-[399px] bg-cover bg-center">
              <img
                src={detail.image_thumbnail}
                alt="foto kabah"
                className="w-[100%] h-[399px] rounded-2xl object-cover"
              />
            </div>
            <div className="flex flex-col w-[100%] items-start p-[16px] gap-[16px] border rounded-lg">
              <div className="flex flex-row justify-between w-[100%]">
                <div className="paket-vip-general-info">
                  <img src={IconVip} alt="VIP" />
                  <p>{detail?.kategori}</p>
                </div>
                <div>
                  <Buttons
                    text="Edit Info Dasar"
                    customClass="btn-outline"
                    onClick={() => {
                      setOpen(true);

                      detailById({
                        status: true,
                        menu: "Syarat",
                        type: 1,
                        showAll: true,
                      });
                    }}
                  />
                  <Popup
                    open={open || byDetail?.status}
                    handleClose={handleClosePopup}
                    title="Edit Popup"
                    width="1172px"
                    height="648px"
                  >
                    <PopupContent
                      data={detail}
                      bus={store.bus}
                      isDetail={true}
                      closePopup={setOpen}
                      setSuccessEdit={setSuccessEdit}
                      listPembimbing={listPembimbing}
                      listGuide={listGuide}
                    />
                  </Popup>
                </div>
              </div>
              <div className="">
                <h1 className="text-base text-black font-semibold">
                  {detail.judul_paket}
                </h1>
              </div>
              <div className="bg-[#E0E0E0] rounded-md text-[#141414] font-sans font-normal text-[12px] px-[8px] py-[8px]">
                <p className="text-sm font-normal">
                  {detail.thn_masehi} M/{detail.thn_hijriyah} H
                </p>
              </div>
              <div className="flex flex-row items-start gap-[8px]">
                {detail?.tag?.split(",")?.map((item) => (
                  <div className="hastag-generalInfo">
                    <p className="text-black font-semibold">#{item}</p>
                  </div>
                ))}
              </div>
              <div className="divider-generalInfo"></div>
              <div className="keterangan-generalInfo">
                <div className="flex flex-row items-center gap-[8px]">
                  <img
                    src={CalendarIcon}
                    alt="Calendar"
                    className="w-[24px] h-[24px]"
                  />
                  <p className="font-normal text-sm text-black">
                    {moment(detail.jadwal_keberangkatan).format("DD MMMM YYYY")}
                  </p>
                </div>
                <div className="divider-vertical-generalInfo"></div>
                <div className="flex flex-row items-center gap-[8px]">
                  <img
                    src={CalendarIcon}
                    alt="Calendar"
                    className="w-[24px] h-[24px]"
                  />
                  <p className="font-normal text-sm text-black">
                    {detail.jumlah_hari} Hari
                  </p>
                </div>
                <div className="divider-vertical-generalInfo"></div>
                <div className="flex flex-row items-center gap-[8px]">
                  <img
                    src={SeatIcon}
                    alt="Calendar"
                    className="w-[24px] h-[24px]"
                  />
                  <p className="font-normal text-sm text-black">
                    {detail.jumlah_jamaah} Seat
                  </p>
                </div>
              </div>
              <div className="divider-generalInfo"></div>
              <div>
                <p lassName="font-normal text-sm text-black">
                  {detail.deskripsi}
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-between p-[16px] gap-[12px] w-[100%]">
            <div className="flex flex-row justify-center items-center w-[100%]">
              <div className="mr-[8px]">
                <h1 className="font-semibold text-black text-base">Scan QR</h1>
                <p className="">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elitLorem
                  ipsum dolor sit amet, consectetur adipiscing elit
                </p>
              </div>
              <div>
                <Buttons
                  text="Scan QR"
                  customClass="btn-secondary btn-medium !w-[73px]"
                />
              </div>
            </div>
            <div className="flex flex-row items-center w-[100%]">
              <div className="mr-[8px]">
                <h1 className="font-semibold text-black text-base">
                  Print Paket
                </h1>
                <p className="">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elitLorem
                  ipsum dolor sit amet, consectetur adipiscing elit
                </p>
              </div>
              <div>
                <Buttons
                  text="Print"
                  customClass="btn-secondary btn-medium !w-[73px]"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row items-start w-[100%] justify-between">
            <div className="flex flex-col items-start p-[16px] gap-[16px] w-[100%]">
              <h1 className="font-semibold text-black text-base">
                Syarat & Ketentuan
              </h1>

              <div className="divider-red-generalInfo"></div>

              <div className="syarat-pendaftaran-generalInfo">
                <div className="title-generalInfo">
                  <h1>Keterangan Batas Waktu</h1>
                  <div className="w-[24px] h-[24px] bg-white rounded-lg">
                    <Edit
                      className="w-[16px] h-[16px] cursor-pointer mt-[4px] ml-[4px]"
                      onClick={() => {
                        dispatch(
                          detailById({
                            status: true,
                            menu: "Syarat",
                            type: 2,
                          })
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="list-generalInfo flex !flex-col">
                  <div className="flex flex-row justify-between w-full items-center mb-[24px]">
                    <div className="flex flex-row justify-start items-center">
                      <div className="mr-[16px]">
                        <img
                          src={BatasPendaftaranIcon}
                          alt="batas pendaftaran"
                        />
                      </div>
                      <div className="flex flex-col">
                        <div className="font-sans font-semibold text-[14px] text-[#141414] mb-[8px]">
                          Batas Pendaftaran
                        </div>
                        <div className="font-sans font-normal text-[14px] text-[#828282]">
                          {detail?.tgl_batas_pendaftaran ? (
                            `${detail?.tgl_batas_pendaftaran} hari sebelum keberangkatan`
                          ) : (
                            <p>-</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row justify-start items-center">
                      <div className="mr-[16px]">
                        <img
                          src={IconPengumpulanberkas}
                          alt="batas-pengumpulan"
                        />
                      </div>
                      <div className="flex flex-col !w-[233px]">
                        <div className="font-sans font-semibold text-[14px] text-[#141414] mb-[8px]">
                          Batas Pengumpulan Berkas
                        </div>
                        <div className="font-sans font-normal text-[14px] text-[#828282]">
                          {detail?.tgl_batas_pengumpulan_berkas ? (
                            `${detail?.tgl_batas_pengumpulan_berkas} hari sebelum keberangkatan`
                          ) : (
                            <p>-</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row justify-between w-full items-center mb-[24px]">
                    <div className="flex flex-row justify-start items-center">
                      <div className="mr-[16px]">
                        <img src={IconBatasDP} alt="batas_dp" />
                      </div>
                      <div className="flex flex-col">
                        <div className="font-sans font-semibold text-[14px] text-[#141414] mb-[8px]">
                          Batas PDP
                        </div>
                        <div className="font-sans font-normal text-[14px] text-[#828282]">
                          {detail?.tgl_batas_dp ? (
                            `${detail?.tgl_batas_dp}`
                          ) : (
                            <p>-</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row justify-start items-center">
                      <div className="mr-[16px]">
                        <img src={IconBatasPelunasan} alt="batas pendaftaran" />
                      </div>
                      <div className="flex flex-col !w-[233px]">
                        <div className="font-sans font-semibold text-[14px] text-[#141414] mb-[8px]">
                          Batas Pelunasan
                        </div>
                        <div className="font-sans font-normal text-[14px] text-[#828282]">
                          {detail?.tgl_batas_pelunasan ? (
                            `${detail?.tgl_batas_pelunasan} hari sebelum keberangkatan`
                          ) : (
                            <p>-</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row justify-between w-full items-center mb-[24px]">
                    <div className="flex flex-row justify-start items-center">
                      <div className="mr-[16px]">
                        <img src={IconMinimumDP} alt="minimum_dp" />
                      </div>
                      <div className="flex flex-col">
                        <div className="font-sans font-semibold text-[14px] text-[#141414] mb-[8px]">
                          Minimal DP
                        </div>
                        <div className="font-sans font-normal text-[14px] text-[#828282]">
                          {detail?.dp ? `Rp ${detail?.dp}` : <p>-</p>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-[16px] w-[100%]">
                <div className="syarat-pendaftaran-generalInfo">
                  <div className="title-generalInfo">
                    <h1>Syarat-syarat Pendaftaran</h1>
                    <div className="w-[24px] h-[24px] bg-white rounded-lg">
                      <Edit
                        className="w-[16px] h-[16px] cursor-pointer mt-[4px] ml-[4px]"
                        onClick={() => {
                          dispatch(
                            detailById({
                              status: true,
                              menu: "Syarat",
                              type: 1,
                            })
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="list-generalInfo flex !flex-col">
                    {detail?.syarat?.length !== 0 ? (
                      detail?.syarat
                        ?.filter((val) => val.type === 1)
                        ?.map((item, idx) => (
                          <div
                            className="flex flex-row justify-between items-center py-[16px] px-[16px] w-full border-b-[1px] border-[#E0E0E0]"
                            key={idx}
                          >
                            <div className="flex flex-row gap-2 flex-1">
                              <span className="font-semibold text-[14px] text-[#000]">
                                {idx + 1}
                              </span>

                              <div
                                placeholder="Masukan list"
                                className="flex-1 outline-none w-full"
                              >
                                {item.item}
                              </div>
                            </div>
                          </div>
                        ))
                    ) : (
                      <p>-</p>
                    )}
                  </div>
                </div>
                <div className="syarat-pendaftaran-generalInfo">
                  <div className="title-generalInfo">
                    <h1>Harga Sudah Termasuk</h1>
                    <div className="w-[24px] h-[24px] bg-white rounded-lg">
                      <Edit
                        className="w-[16px] h-[16px] cursor-pointer mt-[4px] ml-[4px]"
                        onClick={() => {
                          dispatch(
                            detailById({
                              status: true,
                              menu: "Syarat",
                              type: 2,
                            })
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="list-generalInfo flex !flex-col">
                    {detail?.syarat?.length !== 0 ? (
                      detail?.syarat
                        ?.filter((val) => val.type === 2)
                        ?.map((item, idx) => (
                          <div
                            className="flex flex-row justify-between items-center w-full py-[16px] px-[16px] border-b-[1px] border-[#E0E0E0]"
                            key={idx}
                          >
                            <div className="flex flex-row gap-2 flex-1">
                              <span className="font-semibold text-[14px] text-[#000]">
                                {idx + 1}
                              </span>
                              <div
                                placeholder="Masukan list"
                                className="flex-1 outline-none w-full"
                              >
                                {item.item}
                              </div>
                            </div>
                          </div>
                        ))
                    ) : (
                      <p>-</p>
                    )}
                  </div>
                </div>
                <div className="syarat-pendaftaran-generalInfo">
                  <div className="title-generalInfo">
                    <h1>Harga Belum Termasuk</h1>
                    <div className="w-[24px] h-[24px] bg-white rounded-lg">
                      <Edit
                        className="w-[16px] h-[16px] cursor-pointer mt-[4px] ml-[4px]"
                        onClick={() => {
                          dispatch(
                            detailById({
                              status: true,
                              menu: "Syarat",
                              type: 3,
                            })
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="list-generalInfo flex !flex-col">
                    {detail?.syarat?.length !== 0 ? (
                      detail?.syarat
                        ?.filter((val) => val.type === 3)
                        ?.map((item, idx) => (
                          <div
                            className="flex flex-row justify-between items-center w-full py-[16px] px-[16px] border-b-[1px] border-[#E0E0E0]"
                            key={idx}
                          >
                            <div className="flex flex-row gap-2 flex-1">
                              <span className="font-semibold text-[14px] text-[#000]">
                                {idx + 1}
                              </span>
                              <div
                                placeholder="Masukan list"
                                className="flex-1 outline-none w-full"
                              >
                                {item.item}
                              </div>
                            </div>
                          </div>
                        ))
                    ) : (
                      <p>-</p>
                    )}
                  </div>
                </div>
                <div className="syarat-pendaftaran-generalInfo">
                  <div className="title-generalInfo">
                    <h1>Ketentuan Pembatalan</h1>
                    <div className="w-[24px] h-[24px] bg-white rounded-lg">
                      <Edit
                        className="w-[16px] h-[16px] cursor-pointer mt-[4px] ml-[4px]"
                        onClick={() => {
                          dispatch(
                            detailById({
                              status: true,
                              menu: "Syarat",
                              type: 4,
                            })
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="list-generalInfo flex !flex-col">
                    {detail?.syarat?.length !== 0 ? (
                      detail?.syarat
                        ?.filter((val) => val.type === 4)
                        ?.map((item, idx) => (
                          <div
                            className="flex flex-row justify-between items-center w-full py-[16px] px-[16px] border-b-[1px] border-[#E0E0E0]"
                            key={idx}
                          >
                            <div className="flex flex-row gap-2 flex-1">
                              <span className="font-semibold text-[14px] text-[#000]">
                                {idx + 1}
                              </span>
                              <div
                                placeholder="Masukan list"
                                className="flex-1 outline-none w-full"
                              >
                                {item.item}
                              </div>
                            </div>
                          </div>
                        ))
                    ) : (
                      <p>-</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start p-[16px] gap-[16px] w-full">
              <div className="flex flex-col items-start gap-[16px] w-full ">
                <div>
                  <h1 className="font-semibold text-black text-base">
                    Pembimbing & Guide
                  </h1>
                  <div className="divider-red-generalInfo mt-[16px]"></div>
                </div>
                <div className="flex flex-row items-start gap-[16px] w-full justify-between">
                  <div className="w-[304px]">
                    <div className="syarat-pendaftaran-green-generalInfo">
                      <div className="title-generalInfo">
                        <h1>Pembimbing</h1>
                        <div className="w-[24px] h-[24px] bg-white rounded-lg">
                          <Edit
                            className="w-[16px] h-[16px] cursor-pointer mt-[4px] ml-[4px]"
                            onClick={() => {
                              dispatch(
                                detailById({
                                  status: true,
                                  menu: "PembimbingGuide",
                                  type: 1,
                                })
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="list-generalInfo flex !flex-col gap-3">
                        {detail?.pembimbing_paket.length > 0 ? (
                          detail.pembimbing_paket.map((val, idx) => {
                            return (
                              <>
                                <div className="flex flex-row gap-3">
                                  <span className="font-semibold">
                                    {idx + 1}
                                  </span>
                                  {val.pembimbing?.name}
                                </div>

                                <div className="divider"></div>
                              </>
                            );
                          })
                        ) : (
                          <div>-</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="w-[304px]">
                    <div className="syarat-pendaftaran-green-generalInfo">
                      <div className="title-generalInfo">
                        <h1>Guide</h1>
                        <div className="w-[24px] h-[24px] bg-white rounded-lg">
                          <Edit
                            className="w-[16px] h-[16px] cursor-pointer mt-[4px] ml-[4px]"
                            onClick={() => {
                              dispatch(
                                detailById({
                                  status: true,
                                  menu: "PembimbingGuide",
                                  type: 1,
                                })
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="list-generalInfo flex !flex-col gap-3">
                        {detail?.pembimbing_paket.length > 0 ? (
                          detail.pembimbing_paket.map((val, idx) => {
                            return (
                              <>
                                <div className="flex flex-row gap-3">
                                  <span className="font-semibold">
                                    {idx + 1}
                                  </span>
                                  {val.guide?.name}
                                </div>

                                <div className="divider"></div>
                              </>
                            );
                          })
                        ) : (
                          <div>-</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-start gap-[16px] py-[16px] px-0 mt-[16px] w-full">
                <div>
                  <h1 className="font-semibold text-black text-base">
                    Manasik
                  </h1>
                  <div className="divider-red-generalInfo mt-[16px]"></div>
                </div>
                <div className="flex flex-row items-center gap-[16px] flex-wrap">
                  {listManasik?.map((item, idx) => (
                    <div
                      className={`flex flex-row items-center gap-[12px] cursor-pointer hover:bg-[#fff] hover:shadow-md hover:p-[2px] ${
                        choiceManasik === idx ? "p-[2px] shadow-md [#fff]" : ""
                      }`}
                      onClick={() => {
                        handleClickManasikCabang(item.cabang_id);

                        setChoiceManasik(idx);
                      }}
                    >
                      <Badge
                        label={`${item.cabang_name}`}
                        color={`${item.text_color}`}
                        backgroundColor={`${item.bg_color}`}
                        customClass="!capitalize font-bold text-[12px]"
                      />
                      <div className="px-[8px] py-[2px] rounded-md text-[#141414] flex flex-row items-center justify-center !bg-[#D8E1BE] font-sans font-semibold">
                        {item?.manasik_count}
                      </div>
                    </div>
                  ))}
                </div>

                <div className="flex flex-col gap-3 w-full">
                  {dataCabangManasik.map((item, index) => {
                    const data = { ...item, id: index + 1 };
                    return (
                      <Item
                        removeItem={(data) => {
                          setIsDeleteManasik(true);
                          setChoiceIndex(data);
                        }}
                        data={data}
                        key={index}
                        generalInfo={true}
                        onClickEditGeneralInfo={
                          handleClickEditManasikGeneralInfo
                        }
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* AlertPopup */}
      <AlertPopup
        open={isDeleteManasik}
        handleClose={handleClosePopUpConfirmManasik}
        handleContinue={() => removeItem(choiceIndex)}
        title="Hapus Manasik?"
        subtitle={`Apakah kamu yakin ingin menghapus manasik ini ?`}
        loading={isLoadingDelManasik}
      />
    </>
  );
};

export default GeneralInfo;
