import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { menuStepSidebarList } from "../../configuration/stepMenuList";
import { useSelector, useDispatch } from "react-redux";

import BookingStyle from "./Booking.style";
import SidebarChild from "../../components/template_layout/Siderbar/SidebarChild";
import CardBookingTable from "./components/CardBookingTable";
import Popup from "../../components/popup";
import AddBooking from "./components/AddBooking";
import PopupConfirm from "../../components/popup/error-handling/index";

import BookingAction from 'stores/actions/booking/umroh'

import { clearSucces200 } from "../../stores/actions/errorGeneral";

const BookingPage = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [showContent, setShowContent] = useState("umroh");
  const [openPopupAddBooking, setPopupAddBooking] = useState(false);
  const storeBooking = useSelector(({ bookingUmroh }) => bookingUmroh);
  const {success} = useSelector(
    (state) => state.errorGeneral
  ) 

  useEffect(() => {
    storeBooking.isNewOrder && setPopupAddBooking(false)
  }, [storeBooking.isNewOrder])

  useEffect(() => {
    
    if(openPopupAddBooking) {
      dispatch(BookingAction.startNewOrder())
    }
    
  }, [openPopupAddBooking])

  const handleClosePopup = useCallback(() => {
    if (openPopupAddBooking) {
      setPopupAddBooking(false);
    }
  }, [openPopupAddBooking]);

  const memoizeClearSuccess = useCallback(() => {
    dispatch(clearSucces200());
  
    history.push('/booking/detail')
  }, []);

  return (
    <BookingStyle>
      <PopupConfirm
        open={success?.isSuccess}
        handleClose={memoizeClearSuccess}
        type="success"
        title="Berhasil"
        subtitle={success?.message}
      />

      <Popup
        open={openPopupAddBooking}
        handleClose={handleClosePopup}
        title="Tambah Booking"
        width="672px"
        containerClass=""
        children={<AddBooking />}
      />

        <SidebarChild
          menuList={menuStepSidebarList}
          handleClick={setShowContent}
          showContent={showContent}
        />
        <div style={{ marginLeft: '174px', paddingLeft: '16px' }}>
          <CardBookingTable setPopupAddBooking={setPopupAddBooking} />
          <div className="h-5"></div>
        </div>
    </BookingStyle>
  );

};

export default BookingPage;
