import IconPenarikan from "assets/icons/Penarikan-Fee.svg";
import IconPendapatan from "assets/icons/Pendapatan.svg";

export const feeMenuList = [
  {
    title: "Request Penarikan",
    key: "penarikan",
    icon: IconPenarikan,
    order: 0,
  },
  {
    title: "Pendapatan",
    key: "pendapatan",
    icon: IconPendapatan,
    order: 1,
  },
];
