import React, { useEffect, useState } from "react";
import Inputs from "../../../../../../components/form/inputs";
import ImageField from "../../../../../../components/image_field";
import Grid from "@mui/material/Grid";

import Buttons from "../../../../../../components/buttons";

import Add from "../../../../../../assets/icons/add-circle.svg";
import ListUrl from "../../../listurl";

function EditInfo({
  valueName,
  valueVariant,
  setVariant,
  formikOnChange,
  formikValue,
  handleSave,
  loading
}) {
  const [data, setData] = useState("");

  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item sm={12}>
        <Inputs
          id="name"
          name="name"
          defaultValue={valueName}
          label="Nama Perlengkapan"
          placeholder="Masukan Nama Perlengkapan"
          onChange={formikOnChange}
        />
      </Grid>
      <Grid item sm={12} md={6}>
        <ImageField
          name="logo"
          label="Logo Perlengkapan"
          customContainer="!rounded-2xl"
          width="100%"
          height="16rem"
          setFile={(file) => {
            formikValue("logo", file?.path);
          }}
        />
      </Grid>
      <Grid item sm={12} md={6}>
        <Inputs
          id="variant"
          label="Tambahkan Varian"
          placeholder="Masukan varian"
          iconPosition="right"
          icon={Add}
          onChange={(e) => setData(e.target.value)}
          onClick={(e) => {
            setVariant([...valueVariant, data]);
          }}
        />
        <div className="my-4 ">
          <ListUrl data={valueVariant} setDataVideo={setVariant} />
        </div>
      </Grid>

      <div className="w-full flex justify-end pt-8 mt-10 items-center border-t border-gray-300">
        <div className="w-64">
          <Buttons
            onClick={handleSave}
            text="Simpan"
            customClass="btn-primary btn-large"
            loading={loading}
            />
        </div>
      </div>
    </Grid>
  );
}

export default EditInfo;
