import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";

const ScrollCustom = ({
  height,
  children,
  style,
  scrollHorizontal,
  width,
  scroll,
}) => {
  const useStyles = makeStyles({
    scrollCustom: {
      height,
      ...(scrollHorizontal ? { width } : { width }),
      overflowY: "scroll",
      overflowX: scrollHorizontal ? "scroll" : "hidden",
      "&::-webkit-scrollbar": {
        width: "2px",
        height: scrollHorizontal ? "5px" : "100px",
      },
      "&::-webkit-scrollbar-track": {
        width: "2px",
        borderRight: "10px solid #fff",
        borderLeft: "10px solid #fff",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#F4CECE",
        height: "30px",
        width: "2px",
        borderRadius: "10px",
      },
      "&::-webkit-scrollbar-track-piece:start": {
        background: "#F4F7FB",
      },
      "&::-webkit-scrollbar-track-piece:end": {
        background: "#F4F7FB",
      },
    },
  });

  const classes = useStyles();

  if (!scroll) {
    return <div>{children}</div>;
  }

  return (
    <div className={classes.scrollCustom} style={style}>
      {children}
    </div>
  );
};

ScrollCustom.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
  scrollHorizontal: PropTypes.bool,
  scroll: PropTypes.bool,
};

ScrollCustom.defaultProps = {
  height: "auto",
  width: "auto",
  children: null,
  style: {},
  scrollHorizontal: false,
  scroll: true,
};

export default React.memo(ScrollCustom);
