import React, { useState } from "react";

import Buttons from "components/buttons";
import MoslemeTable from "components/Table";
import Badge from "components/badge";
import PendapatanAgenHistory from "./components/History";

const PendapatanAgen = () => {
  const [history, setHistory] = useState(false);
  // Loading
  const [isLoading, setIsLoading] = useState(false);
  // Selection & type state
  const [selectionType, setSelectionType] = useState("checkbox");
  const [withSelection, setWithSelection] = useState(true);

  // Pagination state
  const [currentpage, setCurrentpage] = useState(1);
  const [size, setSize] = useState(10);
  const total = 100;

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: total,
    current: currentpage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20"],
  };

  const columns = [
    {
      title: "Nama",
      dataIndex: "name",
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (role) => <Badge label="Agen Pusat" />,
    },
    {
      title: "Total Fee Pendapatan",
      dataIndex: "total_fee",
      render: (fee) => (
        <>
          <div className="font-semibold">Rp {fee}</div>
          {/* <div className="action">
            <Eye onClick={() => alert(`show id:`)} size="15" color="#141414" />
            <Trash
              onClick={() => alert(`delete id:`)}
              size="15"
              color="#141414"
            />
            <DocumentCopy
              onClick={() => alert(`copy id:`)}
              size="15"
              color="#141414"
            />
            <Edit onClick={() => alert(`edit id:`)} size="15" color="#141414" />
          </div> */}
        </>
      ),
    },
    {
      title: "",
      dataIndex: "history",
      render: (val) => (
        <Buttons
          text="History"
          containerClass="w-fit"
          customClass="btn-outline btn-small"
          onClick={() => setHistory(true)}
        />
      ),
    },
  ];

  const data = [
    {
      key: "1",
      name: "Dimas Andreyan Pradana Putra",
      role: "Role",
      total_fee: "10.500.000",
      history: "",
    },
    {
      key: "2",
      name: "Dimas Andreyan Pradana Putra",
      role: "Role",
      total_fee: "10.500.000",
      history: "",
    },
    {
      key: "2",
      name: "Dimas Andreyan Pradana Putra",
      role: "Role",
      total_fee: "10.500.000",
      history: "",
    },
  ];

  // func to set size pagination
  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setSize(pageSize);
  };
  // manipulated data with action
  const sourceData = data;

  return (
    <>
      {history ? (
        <PendapatanAgenHistory setHistory={setHistory} />
      ) : (
        <>
          <div className="flex flex-row justify-between items-center py-4">
            <div className="text-xl font-semibold">Daftar Pendapatan Agen</div>
          </div>
          <div className="mx-[-1rem] border-t border-[##E0E0E0]">
            <MoslemeTable
              loading={isLoading}
              columns={columns}
              data={sourceData}
              withSelection={withSelection}
              selectionType={selectionType}
              pagination={pagination}
              onPageChange={(page) => setCurrentpage(page)}
              onSizeChange={(currentpage, pageSize) =>
                onSizeChange(currentpage, pageSize)
              }
            />
          </div>
        </>
      )}
    </>
  );
};

export default React.memo(PendapatanAgen);
