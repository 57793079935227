import React, { useState } from "react";
import PropType from "prop-types";

import Buttons from "components/buttons";
import AntdSelect from "components/form/antdSelect";
import Inputs from "components/form/inputs";
import Badge from "components/badge";
import Dropdowns, { closeDropdown } from "components/dropdowns";
import InputPhone from "components/form/inputPhone";

import IconSearch from "assets/icons/search-normal.svg";
import DropdownArrow from "assets/icons/dropdown-arrow.svg";
import IconSingle from "assets/icons/Single.svg";
import IconDouble from "assets/icons/Double.svg";
import IconTripple from "assets/icons/Triple.svg";
import IconQuad from "assets/icons/Quad.svg";
import FlagIndonesia from "assets/icons/flag-indonesia.svg";

const AddJamaah = ({ setOpenPopupAddJamaah }) => {
  const [selectedStartingItem, setSelectedStartingItem] = useState("");
  const [selectedRoom, setSelectedRoom] = useState("");
  const [selectPhoneCode, setSelectPhoneCode] = useState(false);

  const title = [
    {
      id: 1,
      value: "tuan",
      label: "Tuan",
    },
    {
      id: 2,
      value: "nyonya",
      label: "Nyonya",
    },
  ];

  const starting = [
    {
      id: 1,
      berangkat: "Balikpapan",
      pulang: "Jakarta",
      code: "BPN_CGK",
    },
    {
      id: 2,
      berangkat: "Yogyakarta",
      pulang: "Jakarta",
      code: "YIA_CGK",
    },
  ];

  const rooms = [
    {
      id: 1,
      type: "Single",
      price: "58.000.000",
    },
    {
      id: 2,
      type: "Double",
      price: "58.000.000",
    },
    {
      id: 3,
      type: "Triple",
      price: "58.000.000",
    },
    {
      id: 4,
      type: "Quad",
      price: "58.000.000",
    },
  ];

  return (
    <>
      <div className="border border-[#E0E0E0] rounded-2xl">
        <div className="p-4">
          <div className="flex flex-row gap-4">
            <AntdSelect
              containerClass="!w-[30%]"
              placeholder="Pilih title"
              label="Title"
              options={title}
            >
              {title.map((val, idx) => {
                return (
                  <div value={val.value} data={val} label={val.label} key={idx}>
                    {val.label}
                  </div>
                );
              })}
            </AntdSelect>
            <Inputs
              placeholder="Masukkan nama"
              label="Nama"
              containerClass="!w-full"
              customClass="!py-4"
            />
          </div>
          <div className="flex flex-row gap-4 mt-4">
            <Inputs
              placeholder="Masukkan identitas"
              label="Identitas"
              containerClass="!w-[30%]"
              customClass="!py-4"
            />
            <Inputs
              placeholder="Masukkan No ID"
              label="No ID"
              containerClass="!w-full"
              customClass="!py-4"
            />
          </div>

          <Dropdowns
            containerClass="mt-4"
            dropdownHeader={
              <>
                <div className="font-semibold min-h-[24px] mb-2 text-[16px] text-[#4F4F4F]">
                  Starting
                </div>
                <div className="bg-[#F2F2F2] rounded-xl p-4 flex flex-row justify-between items-center">
                  <div
                    className={`text-[14px] ${
                      selectedStartingItem === "" && "text-[#828282]"
                    }`}
                  >
                    {selectedStartingItem !== ""
                      ? selectedStartingItem
                      : "Pilih starting"}
                  </div>
                  <img src={DropdownArrow} alt="arrow" className="w-6 h-6" />
                </div>
              </>
            }
          >
            <div className="bg-white hard-shadow rounded-3xl p-4">
              <Inputs
                id="test"
                placeholder="Search"
                containerClass="mb-4"
                iconPosition="left"
                icon={IconSearch}
              />
              <div className="flex flex-col gap-4">
                {starting.map((val, idx) => {
                  return (
                    <div
                      key={idx}
                      className={`flex flex-row justify-between border border-[ #E0E0E0] rounded-2xl p-4 cursor-pointer hover:bg-[#FFDCDC] ${
                        selectedStartingItem === val.code && "bg-[#FF9C9A]"
                      }`}
                      onClick={() => {
                        setSelectedStartingItem(val.code);
                        closeDropdown();
                      }}
                    >
                      <div className="flex flex-row gap-10">
                        <div className="flex flex-col gap-1">
                          <div>Berangkat</div>
                          <div className="font-semibold text-[16px]">
                            {val.berangkat}
                          </div>
                        </div>
                        <div className="flex flex-col gap-1">
                          <div>Pulang</div>
                          <div className="font-semibold text-[16px]">
                            {val.berangkat}
                          </div>
                        </div>
                      </div>
                      <Badge customClass="!h-fit" label={val.code} />
                    </div>
                  );
                })}
              </div>
              <Buttons
                containerClass="mt-4"
                text="Pilih"
                customClass="btn-outline btn-medium"
              />
            </div>
          </Dropdowns>

          <Dropdowns
            containerClass="mt-4"
            dropdownHeader={
              <>
                <div className="font-semibold min-h-[24px] mb-2 text-[16px] text-[#4F4F4F]">
                  Tipe Kamar
                </div>
                <div className="bg-[#F2F2F2] rounded-xl p-4 flex flex-row justify-between items-center">
                  <div
                    className={`text-[14px] ${
                      selectedRoom === "" && "text-[#828282]"
                    }`}
                  >
                    {selectedRoom !== "" ? selectedRoom : "Pilih tipe kamar"}
                  </div>
                  <img src={DropdownArrow} alt="arrow" className="w-6 h-6" />
                </div>
              </>
            }
          >
            <div className="bg-white hard-shadow rounded-2xl">
              <div className="flex flex-col gap-4">
                {rooms.map((val, idx) => {
                  return (
                    <div
                      key={idx}
                      className={`cursor-pointer hover:bg-[#FFDCDC] ${
                        selectedRoom === val.type && "bg-[#FF9C9A]"
                      }`}
                      onClick={() => {
                        setSelectedRoom(val.type);
                        closeDropdown();
                      }}
                    >
                      <div
                        className={`flex flex-col gap-2 p-4 ${
                          idx !== rooms.length - 1 &&
                          "border-b border-[#E0E0E0]"
                        }`}
                      >
                        <div className="flex flex-row gap-2">
                          <img src={IconSingle} alt="type" />
                          <div>{val.type}</div>
                        </div>
                        <div className="txt-green font-semibold text-[20px]">
                          Rp {val.price}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Dropdowns>

          <InputPhone
            id="test"
            name="telephone"
            label="Nomer Telepon"
            containerClass="mt-4"
            placeholder="Text Input"
            icon={FlagIndonesia}
            enableSearch
            onClickIcon={() => {
              setSelectPhoneCode(true);
            }}
          />

          <Inputs
            id="email"
            name="email"
            label="Email"
            placeholder="Isi email"
            containerClass="mt-4"
          />
        </div>
        <div className="divider"></div>
        <div className="flex flex-row gap-2 justify-end p-4">
          <Buttons
            text="Batal"
            customClass="btn-outline btn-large"
            onClick={() => setOpenPopupAddJamaah(false)}
          />
          <Buttons text="Simpan" customClass="btn-primary btn-large" />
        </div>
      </div>
    </>
  );
};

AddJamaah.propType = {
  setOpenPopupAddJamaah: PropType.func,
};

export default React.memo(AddJamaah);
