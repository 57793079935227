import React from "react";
import Buttons from "components/buttons";
import ImageField from "components/image_field";

const DokumenUploadPassport = () => {
  return (
    <>
      <div className="flex flex-col items-center gap-[16px] w-full h-fit">
        <div className="flex flex-row items-start gap-[16px] w-full">
          <div>
            <ImageField
              customContainer="!w-[277.5px] !h-[162px]"
              setFile={(file) => console.log("file1", file)}
              label="Document 1"
              name="Document1"
            />
          </div>
          <div>
            <ImageField
              customContainer="!w-[277.5px] !h-[162px]"
              setFile={(file) => console.log("file1", file)}
              label="Document 2"
              name="Document2"
            />
          </div>
        </div>
        <div className="">
          <Buttons
            text="Simpan"
            customClass="btn-primary btn-large !w-[579px] !h-[54]"
          />
        </div>
      </div>
    </>
  );
};

export default DokumenUploadPassport;
