// testimoni Actions
// --------------------------------------------------------

import TestimoniAPI from "utils/api/apps/testimoni";
import { handleError, handleErrorBE, handleSuccess } from "../../errorGeneral";

/* eslint-disable space-before-function-paren */
export const SET_LOADING = "testimoni/SET_LOADING";
export const CLEAR_ERROR = "testimoni/CLEAR_ERROR";
export const SET_ERROR = "testimoni/SET_ERROR";
export const INIT_DATA = "testimoni/INIT_DATA";
export const SET_DOUBLE_SUBMIT = "testimoni/SET_DOUBLE_SUBMIT";
export const SET_DATA_TESTIMONI = "testimoni/SET_DATA_TESTIMONI";

export const setLoading = (payload) => ({
    type: SET_LOADING,
    payload,
});

export const setDoubleSubmit = (payload) => ({
    type: SET_DOUBLE_SUBMIT,
    payload,
});

export const setDataTestimoni = (payload) => ({
    type: SET_DATA_TESTIMONI,
    payload,
});

export const getDataTestimoni = (payload) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        console.log(payload);
        const res = await TestimoniAPI.getData(payload);
        if (res.status == 200) {
            console.log("success");

            dispatch(setDataTestimoni(res.data.data));
            return res;
        } else {
            dispatch(handleErrorBE(res));
        }
    } catch (error) {
        dispatch(handleError(error));
    } finally {
        dispatch(setLoading(false));
    }
};

export const saveDataTestimoni = (payload) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        console.log(payload);
        const res = await TestimoniAPI.saveData(payload);
        if (res.status == 200) {
            console.log("success");
            dispatch(handleSuccess(res.data));
            return res;
        } else {
            dispatch(handleErrorBE(res));
        }
    } catch (error) {
        dispatch(handleError(error));
    } finally {
        dispatch(setLoading(false));
    }
};

export const deleteDataTestimoni = (payload) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        console.log(payload);
        const res = await TestimoniAPI.deleteData(payload);
        if (res.status == 200) {
            console.log("success");
            dispatch(handleSuccess(res.data));
            return res;
        } else {
            dispatch(handleErrorBE(res));
        }
    } catch (error) {
        dispatch(handleError(error));
    } finally {
        dispatch(setLoading(false));
    }
};
