import React, { useState, useEffect } from "react";
import Card from "../../../../../components/cards";
import Inputs from "../../../../../components/form/inputs";
import Textareas from "../../../../../components/form/textarea";
import Grid from "@mui/material/Grid";
import Buttons from "../../../../../components/buttons";
import Selects from "../../../../../components/form/selects";
import ImageField from "../../../../../components/image_field";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  addDataPembimbing,
  editDataPembimbing,
} from "stores/actions/datamaster";
import { useDispatch, useSelector } from "react-redux";
import RichTextEditor from "components/richTextEditor";
import { Spin } from "antd";

function PembimbingComponentModal({ setOpen }) {
  const { detailData, editData, isLoading } = useSelector(
    (state) => state.datamaster
  );
  console.log({ isLoading });
  const [idData, setIdData] = useState("");
  const [options, setOptions] = useState();
  const [time, setTime] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      type: "",
      image_photo: "",
      description: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Nama wajib diisi"),
      description: Yup.string().required("Deskripsi wajib diisi"),
    }),
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (editData !== "" && detailData) {
      formik.setFormikState({
        values: {
          name: detailData?.data?.name,
          type: detailData?.data?.type,
          image_photo: detailData?.data?.photo,
          description: detailData?.data?.description,
        },
      });
      setIdData(detailData?.data?.id_mentor);
      setOptions(detailData?.data?.type);
    }
    setTimeout(() => {
      setTime(true);
    }, 1000);
  }, [detailData]);

  const categories = [
    {
      value: "pembimbing",
      label: "Pembimbing",
    },
    {
      value: "guide",
      label: "Guide",
    },
  ];

  // submit form
  const handleSave = () => {
    if (editData === "") {
      dispatch(addDataPembimbing({ ...formik.values, type: options }));
    } else {
      dispatch(
        editDataPembimbing({
          ...formik.values,
          id: idData,
          type: options,
        })
      );
    }

    setOpen(false);
  };

  return (
    <>
      {!isLoading ? (
        <Card containerClass="!px-0 !pb-4 !pt-4 !rounded-2xl !shadow-none !bg-transparent !border !border-gray-200">
          <div className="w-full px-4 overflow-y-auto ">
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item sm={12} md={6}>
                <Inputs
                  id="name"
                  name="name"
                  label="Nama"
                  placeholder="Masukan pembimbing"
                  onChange={formik.handleChange}
                  onBlur={editData === "" ? formik.handleBlur : null}
                  value={formik.values.name}
                  error={
                    formik?.touched?.name &&
                    formik?.errors?.name &&
                    formik?.errors?.name
                  }
                />
              </Grid>
              <Grid item sm={12} md={6}>
                <Selects
                  placeholder="Pilih Tipe"
                  label="Tipe"
                  options={categories}
                  onChange={(values) =>
                    setOptions(values.map((x) => x.value)[0])
                  }
                />
              </Grid>
            </Grid>

            <div className="mt-4">
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item sm={6}>
                  <RichTextEditor
                    placeholder="Masukkan deskripsi akomodasi"
                    data={{ value: formik.values.description }}
                    onChange={(value) => {
                      formik.setFieldValue("description", value);
                    }}
                  />
                </Grid>
                <Grid item sm={12} md={6}>
                  <ImageField
                    name="image_photo"
                    label="Foto Pembimbing"
                    customContainer="!w-auto h-[20rem] !rounded-2xl"
                    setFile={(file) => {
                      formik.setFieldValue("image_photo", file?.path);
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
          <div className="w-full flex justify-end border-t border-gray-200 pt-4 mt-6">
            <div className="w-64 px-4 h-auto">
              <Buttons
                text="Selanjutnya"
                disabled={
                  !(
                    formik.isValid &&
                    formik.dirty &&
                    formik.values.description !== "<p><br></p>"
                  )
                }
                customClass="btn-primary  btn-large"
                loading={isLoading}
                onClick={handleSave}
              />
            </div>
          </div>
        </Card>
      ) : (
        <div className="w-full h-[534px] flex justify-center items-center">
          <Spin size="large" />
        </div>
      )}
    </>
  );
}

export default PembimbingComponentModal;
