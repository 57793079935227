import React from "react";
import PropTypes from "prop-types";

import Cards from "components/cards";
import Selects from "components/form/selects";
import InputPhone from "components/form/inputPhone";
import Inputs from "components/form/inputs";
import Buttons from "components/buttons";

import FlagIndonesia from "assets/icons/flag-indonesia.svg";
import IconSearch from "assets/icons/Search.svg";

const CardLogin = ({
  loginType,
  selectPhoneCode,
  phoneRegion,
  options,
  setSelectPhoneCode,
  setPhoneRegion,
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  touched,
  errors,
  setFieldValue,
  setLoginType,
  emailValidated,
  setForgotPage,
  isLoading,
  isSubmitting,
}) => {
  return (
    <Cards containerClass="w-full">
      <h3 className="card-login-title">Login Admin</h3>
      <h5 className="card-login-desc">
        Masukkan data dibawah untuk lanjut ke Admin
      </h5>
      {loginType === "telepon" ? (
        selectPhoneCode ? (
          <Selects
            open
            defaultValue={phoneRegion}
            label="Label"
            placeholder="Text Input"
            containerClass="mt-6"
            icon={IconSearch}
            iconPosition="left"
            options={options}
            onChange={(e) => {
              setSelectPhoneCode(false);
              setPhoneRegion(e);
            }}
          />
        ) : (
          <InputPhone
            id="test"
            name="telephone"
            value={values.telephone}
            phoneRegion={phoneRegion}
            placeholder="Text Input"
            containerClass="mt-6"
            onChange={(e) => {
              setFieldValue("telephone", e);
            }}
            error={
              touched.telephone && errors.telephone ? errors.telephone : ""
            }
            onBlur={handleBlur}
            icon={FlagIndonesia}
            enableSearch
            onClickIcon={() => {
              setSelectPhoneCode(true);
            }}
          />
        )
      ) : (
        <>
          <Inputs
            id="email"
            name="email"
            label="Email"
            value={values.email}
            onChange={(e) => {
              setFieldValue("email", e.target.value);
            }}
            onBlur={handleBlur}
            error={touched.email && errors.email ? errors.email : ""}
            placeholder="Login dengan email"
            containerClass="mt-6"
            customClass={errors.email && touched.email ? "input-error" : ""}
          />
          {emailValidated && (
            <Inputs
              id="password"
              name="password"
              type="password"
              label="Password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.password && errors.password ? errors.password : ""}
              placeholder="Password"
              containerClass="mt-6"
              customClass={
                errors.password && touched.password ? "input-error" : ""
              }
            />
          )}
        </>
      )}

      {loginType === "email" && (
        <span
          className="card-login-forgot-pass"
          onClick={() => {
            setForgotPage(true);
          }}
        >
          Lupa kata sandi?
        </span>
      )}

      <Buttons
        text="Lanjutkan"
        containerClass="mt-6"
        loading={isSubmitting}
        customClass={`btn-primary btn-large`}
        onClick={handleSubmit}
      />
      {loginType === "telepon" ? (
        <Buttons
          text="Login Dengan Email"
          containerClass="mt-6"
          customClass="btn-outline btn-large"
          onClick={() => setLoginType("email")}
        />
      ) : (
        <Buttons
          text="Login Dengan Nomor Telepon"
          containerClass="mt-6"
          customClass="btn-outline btn-large"
          onClick={() => setLoginType("telepon")}
        />
      )}
    </Cards>
  );
};

CardLogin.propTypes = {
  loginType: PropTypes.string,
  selectPhoneCode: PropTypes.bool,
  phoneRegion: PropTypes.string,
  options: PropTypes.array,
  setSelectPhoneCode: PropTypes.func,
  setPhoneRegion: PropTypes.func,
  values: PropTypes.object,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  handleSubmit: PropTypes.func,
  touched: PropTypes.object,
  errors: PropTypes.object,
  setFieldValue: PropTypes.func,
  setLoginType: PropTypes.func,
  emailValidated: PropTypes.bool,
  setForgotPage: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

export default React.memo(CardLogin);
