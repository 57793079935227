/* eslint-disable array-callback-return */
import { CheckOutlined, FilterOutlined } from "@ant-design/icons";
import { Collapse, Input } from "antd";
import CustomSelect from "components/antd/CustomSelect";
import Sliders from "components/slider";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid, makeStyles } from "@material-ui/core";
import moment from "moment";
import { rupiah } from "pages/master/components/subpage/additional/columns";
import { useHistory } from "react-router-dom";
import { getAllCabang } from "stores/actions/cabang";
import {
  getDataJamaah,
  getDataMemberOnline,
  getDataPaket,
  getDataPaketAktif,
  getStatistik,
  getdataKelengkapanJamaah,
  resetDataDashboard,
} from "stores/actions/dashboard";
import { detailById } from "stores/actions/general";
import { getDataRole } from "stores/actions/role";
import styled from "styled-components";
import {
  IcArrowRsM,
  IcBelumBerangkat,
  IcCeklisBlue,
  IcSearch,
  IcSedangBerangkat,
  IcSudahBerangkat,
  IcTotalPaket,
} from "../../assets/icons/dashboard";
import { ImAvatar } from "../../assets/images";
import MoslemeTable from "../../components/Table";
import Badge from "../../components/badge";
import Buttons from "../../components/buttons";
import Chart from "../../components/chart";
import Inputs from "../../components/form/inputs";
import Popup from "../../components/popup";
import { columns } from "./columns";
import CardJamaah from "./components/CardJamaah";
import CardProgress from "./components/CardProgress";
import ListJamaah from "./components/ListJamaah";
import PaketAktif from "./components/PaketAktif";
import UserDesc from "./components/UserDesc";

import Card from "../../components/cards";

const { Panel } = Collapse;

const CustomSlider = styled.div`
  .slick-slider {
    position: inherit;
  }
  .custom-slick-arrow-prev {
    position: absolute;
    top: 27px;
    left: 92% !important;
  }

  .slick-disabled {
    opacity: 1 !important;
  }

  .custom-slick-arrow-next {
    top: 27px;
    position: absolute;
    right: 8px !important;
  }
`;

const CustomDropdown = styled.div`
  .ant-collapse-content-active {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
  .ant-collapse-header {
    align-items: center !important;
  }
`;

const useStyle = makeStyles({
  contMemberOnline: {
    "&::-webkit-scrollbar": {
      width: "4px",
    },
    "&::-webkit-scrollbar-track": {
      width: "4px",
      borderRight: "10px solid #fff",
      borderLeft: "10px solid #fff",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#E0E0E0",
      height: "30px",
      width: "4px",
      borderRadius: "16px",
    },
    "&::-webkit-scrollbar-track-piece:start": {
      background: "#fff",
    },
    "&::-webkit-scrollbar-track-piece:end": {
      background: "#fff",
    },
    scrollBehavior: 'smooth'
  }
})

const Dashboard = () => {
  const classes = useStyle();
  const navigate = useHistory();
  const dispatch = useDispatch();
  const { byDetail, global } = useSelector((state) => state?.general);
  const { dataRole } = useSelector((state) => state.role);
  const { dataAllCabang } = useSelector((state) => state.cabang);
  const {
    totalJamaah,
    totalPaket,
    totalPaketAktif,
    totalMemberOnline,
    statistics,
    kelengkapanDataJamaah,
  } = useSelector((state) => state.dashboard);

  const [userOnline, setUserOnline] = useState({
    search: "",
    role: 1,
    cabang: 1,
  });

  const [searchModal, setSearchModal] = useState({
    dp: "",
    lunas: "",
    perlengkapan: "",
    kelengkapan: "",
  });

  const filterBelumDp = byDetail?.data?.data?.filter((item) =>
    item?.order_number?.toLowerCase()?.includes(searchModal?.dp?.toLowerCase())
  );

  const filterPerlengkapan = byDetail?.data?.data?.filter((item) =>
    item?.jamaah_name
      ?.toLowerCase()
      ?.includes(searchModal?.perlengkapan?.toLowerCase())
  );

  const dataJamaah = useCallback(() => {
    dispatch(getDataJamaah());
  }, [dispatch]);

  useEffect(() => {
    dataJamaah();
  }, [dataJamaah]);

  const dataPaket = useCallback(() => {
    dispatch(getDataPaket());
  }, [dispatch]);

  useEffect(() => {
    dataPaket();
  }, [dataPaket]);

  const dataPaketAktif = useCallback(
    (per_page, page_number) => {
      dispatch(
        getDataPaketAktif({
          per_page: per_page,
          page_number: page_number,
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    dataPaketAktif(10, 1);
  }, [dataPaketAktif]);

  const dataMemberOnline = useCallback(
    (currentpage) => {
      dispatch(getDataMemberOnline());
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(
      getDataMemberOnline({
        cabang_id: userOnline?.cabang,
        role_id: userOnline?.role,
        per_page: "5",
        keyword: userOnline?.search,
      })
    );
  }, [dispatch, userOnline?.cabang, userOnline?.role, userOnline?.search]);

  const allCabang = useCallback(() => {
    dispatch(
      getAllCabang({
        page_number: global?.currentpage,
        per_page: global?.size,
        sort_column: "",
        sort_order: "Desc",
        keyword: "",
      })
    );
  }, [dispatch, global?.currentpage, global?.size]);

  useEffect(() => {
    allCabang();
  }, [allCabang]);

  useEffect(() => {
    dispatch(getDataRole());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getStatistik());
  }, [dispatch]);

  const cabangs = dataAllCabang?.data?.map((item) => ({
    ...item,
    value: item?.id_cabang,
    label: item?.name,
  }));

  const roles = dataRole?.data?.map((item) => ({
    ...item,
    value: item?.key,
    label: item?.role?.tags,
  }));

  const categories = [];
  const dataSeries = [];

  statistics?.data?.map((item) => {
    categories.push(`${item?.year}-${item?.month}`);
    dataSeries.push(item.jumlah_jamaah);
  });

  const series = [
    {
      name: "Orang",
      data: dataSeries,
    },
  ];

  const onDetailModal = (data) => {
    // return console.log("mantap", data);
    dispatch(
      getdataKelengkapanJamaah({
        paket_id: data?.id_paket,
        order_kamar_id: data.id,
        keyword: "",
        per_page: 10,
        page_number: 1,
        sort_column: "ordered_at",
        sort_order: "ASC",
      })
    );

    if (byDetail?.status === "perlengkapan") {
      dispatch(
        detailById({
          detailPerlengkapan: true,
          data: byDetail?.data,
          detail: kelengkapanDataJamaah,
        })
      );
    } else {
      dispatch(
        detailById({
          detailKelengkapanData: true,
          data: byDetail?.data,
          detail: kelengkapanDataJamaah,
        })
      );
    }
  };

  if (
    kelengkapanDataJamaah?.total_data !== 0 &&
    kelengkapanDataJamaah !== null
  ) {
    byDetail.detail = kelengkapanDataJamaah;
  }

  const handleCloseJamaah = () => {
    if (byDetail?.detailPerlengkapan) {
      dispatch(detailById({ status: "perlengkapan", data: byDetail?.data }));
      dispatch(resetDataDashboard());
    } else if (byDetail?.detailKelengkapanData) {
      dispatch(detailById({ status: "kelengkapan", data: byDetail?.data }));
      dispatch(resetDataDashboard());
    } else {
      dispatch(detailById({}));
    }
  };

  console.log("by detail", searchModal.perlengkapan);

  return (
    <Card containerClass="!p-4">
      <Grid className="">
        <div className="text-[20px] font-semibold pt-[2px] pb-[18px]">
          Dashboard
        </div>
        <div className="text-[20px] font-semibold my-4">Total Jamaah</div>
        <section className="grid w-full grid-cols-3 items-center gap-6">
          <CardJamaah
            img={IcBelumBerangkat}
            title={"Belum Berangkat"}
            paket={totalJamaah?.data?.belum_berangkat?.total + " Jamaah"}
            data={totalJamaah?.data?.belum_berangkat}
          />
          <CardJamaah
            img={IcSedangBerangkat}
            title={"Sedang Berangkat"}
            paket={totalJamaah?.data?.sedang_berangkat?.total + " Jamaah"}
            data={totalJamaah?.data?.sedang_berangkat}
          />
          <CardJamaah
            img={IcSudahBerangkat}
            title={"Sudah Berangkat"}
            paket={totalJamaah?.data?.sudah_berangkat?.total + " Jamaah"}
            data={totalJamaah?.data?.sudah_berangkat}
          />
        </section>
        <section className="grid w-full grid-cols-5 gap-x-6">
          <div className="col-span-2">
            <div className="text-[20px] font-semibold my-4">Total Paket</div>
            <div className="grid grid-cols-2 items-center gap-2">
              <CardJamaah
                bg="bg-white"
                styleItem="grid-cols-3"
                styleTitlePaket="text-[#828282] !text-[16px]"
                styleTotalPaket="text-[#141414] font-semibold !text-[16px]"
                styleTitle="text-[#828282] !text-[14px]"
                styleTotal="text-[#141414] font-semibold !text-[14px]"
                img={IcTotalPaket}
                title="Total Paket"
                paket={`${totalPaket?.data?.total_paket} Paket`}
                data={totalPaket?.data?.detail_total_paket}
                customClass="shadow-soft"
                customStyle={{ border: "0.5px solid #F2F2F2" }}
              />
              <CardJamaah
                bg="bg-white"
                styleItem="grid-cols-3"
                styleTitlePaket="text-[#828282] !text-[16px]"
                styleTotalPaket="text-[#141414] font-semibold !text-[16px]"
                styleTitle="text-[#828282] !text-[14px]"
                styleTotal="text-[#141414] font-semibold !text-[14px]"
                img={IcBelumBerangkat}
                title="Belum Berangkat"
                paket={`${totalPaket?.data?.belum_berangkat} Paket`}
                data={totalPaket?.data?.detail_belum_berangkat}
                customClass="shadow-soft"
                customStyle={{ border: "0.5px solid #F2F2F2" }}
              />
              <CardJamaah
                bg="bg-white"
                styleItem="grid-cols-3"
                styleTitlePaket="text-[#828282] !text-[16px]"
                styleTotalPaket="text-[#141414] font-semibold !text-[16px]"
                styleTitle="text-[#828282] !text-[14px]"
                styleTotal="text-[#141414] font-semibold !text-[14px]"
                img={IcSedangBerangkat}
                title="Sedang Berangkat"
                paket={`${totalPaket?.data?.sedang_berangkat} Paket`}
                data={totalPaket?.data?.detail_sedang_berangkat}
                customClass="shadow-soft"
                customStyle={{ border: "0.5px solid #F2F2F2" }}
              />
              <CardJamaah
                bg="bg-white"
                styleItem="grid-cols-3"
                styleTitlePaket="text-[#828282] !text-[16px]"
                styleTotalPaket="text-[#141414] font-semibold !text-[16px]"
                styleTitle="text-[#828282] !text-[14px]"
                styleTotal="text-[#141414] font-semibold !text-[14px]"
                img={IcSudahBerangkat}
                title="Sudah Berangkat"
                paket={`${totalPaket?.data?.sudah_berangkat} Paket`}
                data={totalPaket?.data?.detail_sudah_berangkat}
                customClass="shadow-soft"
                customStyle={{ border: "0.5px solid #F2F2F2" }}
              />
            </div>
            <div className="flex items-center gap-x-2">
              <div className="text-[20px] font-semibold my-4">Member</div>
              <div className="flex items-center text-xs gap-x-1 font-semibold">
                <div className="bg-green-old w-2 h-2 rounded-full" />
                Online ({totalMemberOnline?.data?.length})
              </div>
            </div>
            <div className="rounded-2xl bg-white shadow-soft p-4 border border-[#e0e0e0]">
              <div className="grid grid-cols-10 items-start gap-x-2 mb-3">
                <div className="col-span-4">
                  <Input
                    size="large"
                    placeholder="Search"
                    prefix={<img src={IcSearch} alt="search" />}
                    onChange={(e) =>
                      setUserOnline({ ...userOnline, search: e.target.value })
                    }
                  />
                </div>
                <div className="col-span-3 ml-4">
                  <CustomSelect
                    prefixIconLeft={<FilterOutlined />}
                    defaultValue="Role"
                    className="w-full"
                    onChange={(value) =>
                      setUserOnline({ ...userOnline, role: value })
                    }
                    menuItemSelectedIcon={<CheckOutlined />}
                    size="large"
                    options={roles}
                  />
                </div>
                <div className="col-span-3">
                  <CustomSelect
                    prefixIconLeft={<FilterOutlined />}
                    defaultValue="Cabang"
                    className="w-full"
                    onChange={(value) =>
                      setUserOnline({ ...userOnline, cabang: value })
                    }
                    menuItemSelectedIcon={<CheckOutlined />}
                    size="large"
                    options={cabangs}
                  />
                </div>
              </div>
              <div className={ "mx-[-1rem] h-[375px] overflow-y-auto " + classes.contMemberOnline }>
                <MoslemeTable
                  // bordered
                  columns={columns}
                  data={totalMemberOnline?.data}
                  customClass="tbl-jamaah"
                  withSelection={false}
                  selectionType=""
                  withPagination={false}
                  customPaginationClass="pr-4"
                  onRowClick={(record, rowIndex) => ({
                    onClick: (e) => {
                      // console.log("RECORD", record);
                      // router.push("/tagihan/detail");
                    },
                  })}
                />
              </div>
            </div>
          </div>
          <div className="col-span-3 relative">
            <CustomSlider>
              <div className="text-[20px] font-semibold my-4 pl-2">
                Paket Aktif
              </div>
              <Sliders
                customClass="grid grid-cols-2 gap-x-2 !w-full slider-paket-aktif"
                slidesToShow={2}
              >
                {totalPaketAktif.data &&
                  totalPaketAktif.data.length > 0 &&
                  totalPaketAktif?.data?.map((item, idx) => (
                    <PaketAktif
                      key={idx}
                      judul_paket={item?.judul_paket}
                      image={item?.image}
                      tipe_paket={item?.tipe_paket}
                      tanggal_keberangkatan={item?.tanggal_keberangkatan}
                      total_kuota_jamaah={item?.total_kuota_jamaah}
                      seat_terisi={item?.seat_terisi}
                      sisa_seat={item?.sisa_seat}
                      total_seat={item?.total_seat}
                      data={item}
                    />
                  ))}
              </Sliders>
            </CustomSlider>
          </div>
        </section>
        <section className="mt-4 w-full">
          <div className="flex justify-between items-center">
            <div className="text-[20px] font-semibold mb-4">Statistik</div>
            <div className="drop-shadow">
              <CustomSelect
                prefixIconLeft={<FilterOutlined />}
                defaultValue="1 Tahun Terakhir"
                className="w-full"
                onChange={(value) => console.log(value)}
                menuItemSelectedIcon={<CheckOutlined />}
                size="large"
                options={[]}
              />
            </div>
          </div>
        </section>
        <section
          className="grid grid-cols-1 bg-white p-4 rounded-2xl mt-2 !max-w-full"
          style={{ border: "0.5px solid #E0E0E0" }}
        >
          <Chart data={series} categories={categories} />
        </section>
        <Popup
          containerClass="!z-[9999]"
          open={
            byDetail?.status === "belumDp" || byDetail?.status === "belumLunas"
          }
          handleClose={() => {
            dispatch(detailById({}));
            setSearchModal("");
          }}
          title={byDetail?.status === "belumDp" ? "Belum DP" : "Belum Lunas"}
        >
          <Inputs
            id="test"
            placeholder="Search"
            iconPosition="left"
            icon={IcSearch}
            customClass="!py-3"
            value={searchModal.dp}
            onChange={(e) =>
              setSearchModal({ ...searchModal, dp: e.target.value })
            }
          />

          <section className="bg-white drop-shadow p-4 rounded-2xl my-6 overflow-auto scrollbar-thin scrollbar-track-transparent">
            {byDetail?.data?.total_data !== 0 &&
              filterBelumDp?.map((item, idx) => {
                return (
                  <div key={idx}>
                    {idx === 0 && (
                      <div>
                        <div className="flex justify-between items-center mb-6">
                          <div className="font-semibold">
                            #{item?.order_number}
                          </div>
                          <div>
                            {moment(item?.ordered_at).format("DD MMMM YYYY")}
                          </div>
                        </div>
                        <UserDesc
                          name={item?.cabang_name}
                          linkImg={item?.image_paket}
                          date={moment(item?.ordered_at).format("DD MMMM YYYY")}
                          desc={`${byDetail?.data?.total_data} tagihan`}
                        />
                      </div>
                    )}
                    <CustomDropdown>
                      <Collapse
                        defaultActiveKey={[idx]}
                        onChange={(key) => console.log(key)}
                        className="border-none mt-2"
                        expandIconPosition="right"
                      >
                        <Panel
                          header={
                            <div>
                              <div className="font-semibold">
                                #{item?.order_number}
                              </div>
                              <div>{item?.jml_pax} Pax</div>
                            </div>
                          }
                          key={idx}
                          className="bg-white border-none !rounded-2xl drop-shadow mt-4"
                        >
                          <div>
                            {item?.list_jamah?.map((item, idx) => {
                              return (
                                <ListJamaah
                                  key={idx}
                                  id={idx + 1}
                                  name={item?.jamaah_name}
                                  kamar_name={item?.kamar_name}
                                  starting={item?.paket_kamar?.nama_starting}
                                />
                              );
                            })}
                            <div className="font-semibold border-t pt-4">
                              Rincian Tagihan
                            </div>
                            <div className="flex items-center justify-between text-sm mt-4">
                              <div>Batas Waktu DP</div>
                              <div className="font-semibold">
                                {moment(item?.ordered_at).format(
                                  "DD MMMM YYYY"
                                )}
                              </div>
                            </div>
                            <div className="flex items-center justify-between text-sm mt-4">
                              <div>Jumlah</div>
                              <div className="font-semibold">
                                {item?.user_jamaah_id}
                              </div>
                            </div>
                            <div className="flex items-center justify-between text-sm mt-4">
                              <div>Total DP</div>
                              <div className="font-semibold text-[#5C9D0A]">
                                {rupiah(item?.total_price)}
                              </div>
                            </div>
                            <section className="grid grid-cols-2 gap-x-4 mt-6 items-center">
                              <Buttons
                                text="Follow Up"
                                customClass="btn-outline w-full btn-small"
                              />
                              <Buttons
                                text="Bayar"
                                onClick={() => navigate.push("/tagihan/detail")}
                                customClass="btn-primary w-full btn-small"
                              />
                            </section>
                          </div>
                        </Panel>
                      </Collapse>
                    </CustomDropdown>
                  </div>
                );
              })}
          </section>
        </Popup>

        <Popup
          containerClass="!z-[9999]"
          open={
            byDetail?.status === "perlengkapan" ||
            byDetail?.detailPerlengkapan ||
            byDetail?.status === "kelengkapan" ||
            byDetail?.detailKelengkapanData
          }
          type={
            byDetail?.detailPerlengkapan || byDetail?.detailKelengkapanData
              ? "secondary"
              : "primary"
          }
          handleClose={handleCloseJamaah}
          title={
            byDetail?.status === "perlengkapan" || byDetail.detailPerlengkapan
              ? "Perlengkapan"
              : "Kelengkapan Data"
          }
        >
          {byDetail?.status === "perlengkapan" ||
          byDetail?.status === "kelengkapan" ? (
            <div>
              <Inputs
                id="test"
                placeholder="Search mantap"
                iconPosition="left"
                icon={IcSearch}
                customClass="!py-3"
                value={searchModal.perlengkapan}
                onChange={(e) =>
                  setSearchModal({
                    ...searchModal,
                    perlengkapan: e.target.value,
                  })
                }
              />
              <div className="h-[550px] overflow-auto scrollbar-thin scrollbar-track-transparent pb-4">
                {byDetail?.data?.total_data !== 0 &&
                  filterPerlengkapan?.map((data, idx) => {
                    return (
                      <div
                        key={idx}
                        className={`p-4 drop-shadow bg-white rounded-2xl mt-4 ${
                          byDetail?.status === "perlengkapan" && "pb-0"
                        }`}
                      >
                        <CardProgress
                          className={"drop-shadow-none"}
                          icon={ImAvatar}
                          title={`${data?.jamaah_title} ${data?.jamaah_name}`}
                          percent={
                            byDetail?.status === "perlengkapan"
                              ? data?.jml_perlengkapan_jamaah_sudah_lengkap_persen
                              : data?.kelengkapan_data_terisi_persen
                          }
                          info={
                            byDetail?.status === "perlengkapan"
                              ? data?.jml_perlengkapan_jamaah_sudah_lengkap
                              : data?.kelengkapan_data_terisi
                          }
                          kode={`#${data?.order_number}`}
                          onPress={() => onDetailModal(data)}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          ) : (
            <main className="mt-4">
              <section className="flex justify-between items-center mb-4">
                <div>
                  <div>#{byDetail?.detail?.data?.order_number}</div>
                  <div>
                    {moment(byDetail?.detail?.data?.order_date).format(
                      "DD MMMM YYYY"
                    )}
                  </div>
                </div>
                <Badge
                  label={byDetail?.detail?.data?.tipe_paket}
                  backgroundColor="#EAF7E6"
                  color="#01F91A"
                />
              </section>
              <Buttons
                text="Follow Up"
                customClass="btn-secondary w-full btn-medium"
                onClick={() =>
                  byDetail?.status === "perlengkapan"
                    ? dispatch(detailById({ detailPerlengkapan: true }))
                    : dispatch(detailById({ detailKelengkapanData: true }))
                }
              />
              <div className="text-xl font-semibold mt-4">
                {byDetail?.detail?.data?.name_jamaah}
              </div>
              <div className="h-[400px] overflow-auto scrollbar-thin scrollbar-track-transparent pb-4">
                {byDetail?.detailPerlengkapan
                  ? byDetail?.detail?.data?.perlengkapan.map((item, idx) => {
                      return (
                        <div
                          key={idx}
                          className="flex justify-between items-center bg-white drop-shadow rounded-2xl p-4 mt-4"
                        >
                          <div className="flex gap-x-4 items-center justify-start">
                            <img
                              src={item.thumbnail}
                              alt="search"
                              height={80}
                              width={80}
                              className="rounded-xl"
                            />
                            <div>
                              <div
                                className={`${
                                  item.status !== "0" && "text-green-400"
                                } flex items-center gap-x-2`}
                              >
                                {idx + 1}.
                                <span className="font-semibold">
                                  {item.name}
                                </span>
                                {item.status !== "0" && (
                                  <img src={IcCeklisBlue} alt="ceklis" />
                                )}
                              </div>
                              <div className="mt-2">
                                {/* {item.status !== "0" ? item.description : "-"} */}
                                {item.description ? item.description : "-"}
                              </div>
                            </div>
                          </div>
                          <div>
                            <img
                              alt="left arrow"
                              src={IcArrowRsM}
                              className="object-cover"
                              // onClick={onPress}
                            />
                          </div>
                        </div>
                      );
                    })
                  : byDetail?.detail?.data?.perlengkapan.map((item, idx) => {
                      return (
                        <div
                          className="flex items-center justify-between mt-4 bg-white drop-shadow rounded-2xl p-4"
                          key={idx}
                        >
                          <div
                            className={`${
                              item.status !== "0" && "text-green-400"
                            } flex items-center gap-x-2 font-semibold`}
                          >
                            {item.name}
                            {item.status !== "0" && (
                              <img src={IcCeklisBlue} alt="ceklis" />
                            )}
                          </div>
                          <div>
                            <img
                              alt="left arrow"
                              src={IcArrowRsM}
                              className="object-cover cursor-pointer"
                              onClick={() => navigate.push("/booking/detail")}
                            />
                          </div>
                        </div>
                      );
                    })}
              </div>
            </main>
          )}
        </Popup>
      </Grid>
    </Card>
  );
};

export default Dashboard;
