import React, { useState } from "react";
import { Grid } from "@material-ui/core";

import Dropdowns, { closeDropdown } from ".";
import Inputs from "components/form/inputs";
import Badge from "components/badge";
import Buttons from "components/buttons";
import DatePicker from "components/datepicker/DatePicker";
import Checkbox from "components/checkbox";

import IconSearch from "assets/icons/search-normal.svg";
import DropdownArrow from "assets/icons/dropdown-arrow.svg";
import Photo from "assets/images/Photo.png";
import IconChecklisted from "assets/icons/Checklisted.svg";
import Filter from "assets/icons/filter.svg";

const DropwndonsDocs = () => {
  const [selectedStartingItem, setSelectedStartingItem] = useState("");
  const [selectedJamaahItem, setSelectedJamaahItem] = useState("");
  const [selectedAdditionalItem, setSelectedAdditionalItem] = useState("");
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);

  const starting = [
    {
      id: 1,
      berangkat: "Balikpapan",
      pulang: "Jakarta",
      code: "BPN_CGK",
    },
    {
      id: 2,
      berangkat: "Yogyakarta",
      pulang: "Jakarta",
      code: "YIA_CGK",
    },
  ];

  const jamaahItems = [
    {
      name: "Upgrade Business Class",
      price: "Rp 2.000.000",
    },
    {
      name: "Naik Kereta Cepat",
      price: "Rp 2.000.000",
    },
    {
      name: "Kursi Roda",
      price: "Rp 2.000.000",
    },
    {
      name: "Layanan Pembuatan Passport",
      price: "Rp 2.000.000",
    },
    {
      name: "Layanan Suntik Meningitis",
      price: "Rp 2.000.000",
    },
    {
      name: "Skuter Untuk Thowaf",
      price: "Rp 2.000.000",
    },
  ];

  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <Dropdowns
            containerClass="mt-4"
            dropdownHeader={
              <>
                <div className="font-semibold min-h-[24px] mb-2 text-[16px] text-[#4F4F4F]">
                  Starting
                </div>
                <div className="bg-[#F2F2F2] rounded-xl p-4 flex flex-row justify-between items-center">
                  <div
                    className={`text-[14px] ${
                      selectedStartingItem === "" && "text-[#828282]"
                    }`}
                  >
                    {selectedStartingItem !== ""
                      ? selectedStartingItem
                      : "Pilih starting"}
                  </div>
                  <img src={DropdownArrow} alt="arrow" className="w-6 h-6" />
                </div>
              </>
            }
          >
            <div className="bg-white hard-shadow rounded-3xl p-4">
              <Inputs
                id="test"
                placeholder="Search"
                containerClass="mb-4"
                iconPosition="left"
                icon={IconSearch}
              />
              <div className="flex flex-col gap-4">
                {starting.map((val, idx) => {
                  return (
                    <div
                      key={idx}
                      className={`flex flex-row justify-between border border-[ #E0E0E0] rounded-2xl p-4 cursor-pointer hover:bg-[#FFDCDC] ${
                        selectedStartingItem === val.code && "bg-[#FF9C9A]"
                      }`}
                      onClick={() => {
                        setSelectedStartingItem(val.code);
                        closeDropdown();
                      }}
                    >
                      <div className="flex flex-row gap-10">
                        <div className="flex flex-col gap-1">
                          <div>Berangkat</div>
                          <div className="font-semibold text-[16px]">
                            {val.berangkat}
                          </div>
                        </div>
                        <div className="flex flex-col gap-1">
                          <div>Pulang</div>
                          <div className="font-semibold text-[16px]">
                            {val.berangkat}
                          </div>
                        </div>
                      </div>
                      <Badge customClass="!h-fit" label={val.code} />
                    </div>
                  );
                })}
              </div>
              <Buttons
                containerClass="mt-4"
                text="Pilih"
                customClass="btn-outline btn-medium"
              />
            </div>
          </Dropdowns>
        </Grid>

        <Grid item md={6}>
          <Dropdowns
            containerClass="mt-4"
            dropdownHeader={
              <>
                <div className="font-semibold min-h-[24px] mb-2 text-[16px] text-[#4F4F4F]">
                  Pilih Jamaah
                </div>
                <div className="bg-[#F2F2F2] rounded-xl p-4 flex flex-row justify-between items-center">
                  <div
                    className={`text-[14px] ${
                      selectedJamaahItem === "" && "text-[#828282]"
                    }`}
                  >
                    {selectedJamaahItem !== ""
                      ? selectedJamaahItem
                      : "Pilih jamaah"}
                  </div>
                  <img src={DropdownArrow} alt="arrow" className="w-6 h-6" />
                </div>
              </>
            }
          >
            <div className="orange-bg-shine !px-4 !py-3 !rounded-none !rounded-tl-2xl !rounded-tr-2xl">
              <div className="text-white font-semibold text-[16px]">
                Pilih Additional
              </div>
            </div>
            <div className="bg-white soft-shadow rounded-bl-2xl rounded-br-2xl">
              {jamaahItems.map((val, idx) => {
                return (
                  <div
                    key={idx}
                    className={`flex flex-row px-4 py-2 text-[#151515] cursor-pointer hover:bg-[#FFDCDC] ${
                      selectedJamaahItem === val.name && "bg-[#FFDCDC]"
                    }`}
                    onClick={() => {
                      setSelectedJamaahItem(val.name);
                      closeDropdown();
                    }}
                  >
                    <img
                      className="w-[40px] h-[40px]"
                      src={Photo}
                      alt="avatar"
                    />
                    <div className="ml-3">
                      <div className="font-semibold text-[16px]">
                        {val.name}
                      </div>
                      <div className="flex gap-2 mt-2">
                        <Badge label="Banjarmasin" />
                        <Badge label="Banjarmasin" />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Dropdowns>
        </Grid>

        <Grid item md={6}>
          <Dropdowns
            dropdownHeader={
              <>
                <div className="flex flex-row gap-2 items-center font-semibold text-[18px]">
                  <div>
                    {selectedAdditionalItem !== ""
                      ? selectedAdditionalItem
                      : "Tambahan"}
                  </div>
                  <img src={DropdownArrow} alt="arrow" className="w-6 h-6" />
                </div>
              </>
            }
          >
            <div className="orange-bg-shine !px-4 !py-3 !rounded-none !rounded-tl-2xl !rounded-tr-2xl">
              <div className="text-white font-semibold text-[16px]">
                Select Additional
              </div>
            </div>
            <div className="bg-white soft-shadow rounded-bl-2xl rounded-br-2xl">
              {jamaahItems.map((val, idx) => {
                return (
                  <div
                    key={idx}
                    className={`px-4 py-2 text-[#151515] cursor-pointer hover:bg-[#FFDCDC] ${
                      selectedAdditionalItem === val.name && "bg-[#FFDCDC]"
                    }`}
                    onClick={(e) => {
                      setSelectedAdditionalItem(val.name);
                      closeDropdown();
                    }}
                  >
                    <div className="flex flex-row">
                      <div className="text-[16px]">{val.name}</div>
                      {selectedAdditionalItem === val.name && (
                        <img
                          className="ml-2"
                          src={IconChecklisted}
                          alt="checklisted"
                        />
                      )}
                    </div>
                    <div className="txt-orange font-semibold">{val.price}</div>
                  </div>
                );
              })}
            </div>
          </Dropdowns>
        </Grid>

        <Grid item md={6}>
          <Dropdowns
            dropdownHeader={
              <>
                <div className="flex flex-row items-center justify-center my-1 py-2 px-3 gap-2 border rounded-lg cursor-pointer">
                  <img src={Filter} alt="filter" className="w-4 h-4" />
                  <div>Filter</div>
                  <img src={DropdownArrow} alt="arrow" className="w-6 h-6" />
                </div>
              </>
            }
          >
            <div className="bg-white soft-shadow rounded-xl p-3">
              <div className="!w-[154px] h-[30px]">
                <div className="">
                  <DatePicker
                    customClass="!w-[154px] h-[30px]"
                    containerClass="!w-[154px] h-[30px]"
                  />
                </div>
              </div>
              <div className="checkbox-container">
                <p className="title">Keberangkatan</p>
                <Checkbox
                  indeterminate={indeterminate}
                  checked={checked}
                  onChange={(e) => {
                    setChecked(e.target.checked);
                    setIndeterminate(false);
                  }}
                >
                  Sudah Berangkat
                </Checkbox>
                <Checkbox
                  checked={indeterminate}
                  onChange={(e) => setIndeterminate(e.target.checked)}
                >
                  Sedang Berangkat
                </Checkbox>
                <Checkbox
                  indeterminate={indeterminate}
                  checked={checked}
                  onChange={(e) => {
                    setChecked(e.target.checked);
                    setIndeterminate(false);
                  }}
                >
                  Belum Berangkat
                </Checkbox>
              </div>
              <div className="checkbox-container">
                <p className="title">Status</p>
                <Checkbox
                  indeterminate={indeterminate}
                  checked={checked}
                  onChange={(e) => {
                    setChecked(e.target.checked);
                    setIndeterminate(false);
                  }}
                >
                  Draft
                </Checkbox>
                <Checkbox
                  indeterminate={indeterminate}
                  checked={checked}
                  onChange={(e) => {
                    setChecked(e.target.checked);
                    setIndeterminate(false);
                  }}
                >
                  Public
                </Checkbox>
              </div>
              <div className="flex flex-row justify-center items-center">
                <Buttons
                  text="Terapkan"
                  customClass="btn-outline btn-small !w-[154px] h-[30px] !mt-[22px]"
                />
              </div>
            </div>
          </Dropdowns>
        </Grid>
      </Grid>
    </>
  );
};

export default DropwndonsDocs;
