import { makeStyles } from "@material-ui/core";
import { Fragment, useEffect, useState } from "react";

import Double from "assets/icons/Double.svg";
import Quad from "assets/icons/Quad.svg";
import Single from "assets/icons/Single.svg";
import Triple from "assets/icons/Triple.svg";
import Buttons from "components/buttons";
import Popup from "components/popup";
import JamaahDrawer from "./JamaahDrawer";
import RoomPriceCard from "./RoomPriceCard";
import StartingCard from "./StartingCard";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import {
  gerListStartingJamaahPaket,
  getAllTipeKamarDasarPaket,
  getAllTipeKamarPenambahan,
  setTipeKamarDasarPaket,
  setTipeKamarStartingPaket,
  setUpdatedTipeKamarDasarPaket,
  setUpdatedTipeKamarPenambahanPaket,
  tipeKamar,
} from "stores/actions/paket/tipeKamar";
import IconJamPasir from "assets/icons/Jam-Pasir.svg";
import Trash from "assets/icons/trash.svg";
import { getMasterStarting } from "stores/actions/datamaster/starting";
import { handleErrorBE, handleSuccess } from "stores/actions/errorGeneral";
import Badge from "components/badge";
import useDebounce from "utils/helpers/useDebounce";

const useStyle = makeStyles({
  modal_body: {
    display: "flex",
    gap: "20px",
    "& .scroll_area": {
      height: "422px",
      overflow: "auto",
      padding: "14px",
      width: "100%",
    },
    "& .action_area": {
      borderTop: "1px solid #e5e7eb",
      display: "flex",
      justifyContent: "flex-end",
      padding: "8px 14px",
    },
  },
});

const TipeKamar = () => {
  const classes = useStyle();
  const dispatch = useDispatch();

  const { tkAktif, allTipeKamar } = useSelector((state) => state.tipeKamar);
  let { id } = useParams();
  const [hargaDasarTipeKamar, setHargaDasarTipeKamar] = useState({});
  const [hargaPenambahanTipeKamar, setHargaPenambahanTipeKamar] = useState([]);
  const [masterStarting, setMasterStarting] = useState([]);
  const [choiceMasterStarting, setChoiceMasterStarting] = useState(null);
  const [isOpenStartingUtama, setOpenStartingUtama] = useState(false);
  const [isOpenStarting, setOpenStarting] = useState(false);
  const [loadingAction, setIsLoadingAction] = useState(false);
  const [isLoadingSimpan, setIsLoadingSimpan] = useState(false);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const { allStarting, isLoading } = useSelector(
    (state) => state.adminStarting
  );

  const [listDataJamaah, setListDataJamaah] = useState({});
  const [dataHeaderDrawer, setDataHeaderDrawer] = useState({});
  const [input, setInput] = useState("");
  const search = useDebounce(input, 1000);

  useEffect(() => {
    if (allTipeKamar) {
      let _resultMap = {
        ...allTipeKamar.data,
        quad_input: false,
        quad_input_business: false,
        tripple_input: false,
        tripple_input_business: false,
        double_input: false,
        double_input_business: false,
        single_input: false,
        single_input_business: false,
      };
      setHargaDasarTipeKamar(_resultMap);
    }
    if (allStarting || isOpenStartingUtama || allTipeKamar) {
      let _resultMap = allStarting?.data?.map((item) => {
        if (allTipeKamar?.data?.starting?.name === item?.name) {
          setChoiceMasterStarting(item);
        }

        return {
          ...item,
          checked: false,
        };
      });

      setMasterStarting(_resultMap);
    }

    if (tkAktif) {
      let _resultMap = tkAktif?.data?.map((item) => {
        return {
          ...item,
          quad_input: false,
          quad_input_business: false,
          tripple_input: false,
          tripple_input_business: false,
          double_input: false,
          double_input_business: false,
          single_input: false,
          single_input_business: false,
        };
      });

      setHargaPenambahanTipeKamar(_resultMap);
    }
    if (tkAktif || isOpenStarting || allStarting) {
      let _temp = [];
      allStarting?.data?.forEach((item, index) => {
        tkAktif?.data?.forEach((nill) => {
          if (item.name === nill.starting.name) {
            _temp.push(item.id_starting);
          }
        });
      });

      const resultMap = allStarting?.data?.map((item) => {
        return {
          ...item,
          checked: _temp.includes(item.id_starting),
        };
      });

      setMasterStarting(resultMap);
    }

    if (search) {
      const payload = {
        starting_id: dataHeaderDrawer?.starting?.starting_id,
        room_type: dataHeaderDrawer?.type ?? "quad",
        name: search,
      };

      dispatch(
        gerListStartingJamaahPaket(
          id,
          payload,
          (message) => {
            setListDataJamaah(message);
          },
          (error) => {
            dispatch(handleErrorBE(error));
          }
        )
      );
    }
  }, [
    allTipeKamar,
    allStarting,
    tkAktif,
    search,
    isOpenStartingUtama,
    isOpenStarting,
  ]);

  useEffect(() => {
    dispatch(getAllTipeKamarDasarPaket(id));
    dispatch(getAllTipeKamarPenambahan(id));
    dispatch(
      getMasterStarting({
        page_number: 1,
        per_page: 10,
        keyword: "",
        sort_column: "id_starting",
        sort_order: "DESC",
        // package_starting: id,
      })
    );
  }, [dispatch, id]);

  const handleSetTipekamarDasar = () => {
    setIsLoadingAction(true);
    const payload = {
      starting_id: choiceMasterStarting?.id_starting,
    };

    dispatch(
      setTipeKamarDasarPaket(
        id,
        payload,
        (message) => {
          setIsLoadingAction(false);
          setOpenStartingUtama(false);
          dispatch(getAllTipeKamarDasarPaket(id));
          dispatch(handleSuccess(message));
        },
        (error) => {
          setIsLoadingAction(false);
          setOpenStartingUtama(false);
          dispatch(handleErrorBE(error));
        }
      )
    );
  };

  const handleSetTipekamarStarting = () => {
    let _temp = [];
    setIsLoadingAction(true);
    masterStarting.forEach((item) => {
      if (item.checked) {
        _temp.push(item.id_starting);
      }
    });
    const payload = {
      starting_id: _temp,
    };

    dispatch(
      setTipeKamarStartingPaket(
        id,
        payload,
        (message) => {
          setIsLoadingAction(false);
          setOpenStarting(false);
          dispatch(getAllTipeKamarPenambahan(id));
          dispatch(handleSuccess(message));
        },
        (error) => {
          setIsLoadingAction(false);
          setOpenStarting(false);
          setOpenStartingUtama(false);
          dispatch(handleErrorBE(error));
        }
      )
    );
  };

  const handleChecked = (id_starting) => {
    let _temp = [...masterStarting];
    let index = _temp.findIndex((item) => item.id_starting === id_starting);
    _temp[index]["checked"] = !_temp[index]["checked"];

    setMasterStarting(_temp);
  };

  const handleSwitch = (data, type, penambahan = false) => {
    let _temp = { ...data };
    if (type === "single")
      _temp["single_active"] = _temp["single_active"] === 1 ? 0 : 1;
    if (type === "quad")
      _temp["quad_active"] = _temp["quad_active"] === 1 ? 0 : 1;
    if (type === "tripple")
      _temp["triple_active"] = _temp["triple_active"] === 1 ? 0 : 1;
    if (type === "double")
      _temp["double_active"] = _temp["double_active"] === 1 ? 0 : 1;

    const {
      quad_input,
      quad_input_business,
      tripple_input,
      tripple_input_business,
      double_input,
      double_input_business,
      single_input,
      single_input_business,
      ...object_new
    } = _temp;

    if (penambahan) {
      const { starting, ...params } = object_new;

      const payload = {
        ...params,
        starting_id: starting.starting_id,
      };
      dispatch(
        setUpdatedTipeKamarPenambahanPaket(
          id,
          payload,
          (message) => {
            setIsLoadingSimpan(false);
            dispatch(getAllTipeKamarPenambahan(id));
            dispatch(handleSuccess(message));
          },
          (error) => {
            setIsLoadingSimpan(false);
            dispatch(handleErrorBE(error));
          }
        )
      );
    } else {
      dispatch(
        setUpdatedTipeKamarDasarPaket(
          id,
          object_new,
          (message) => {
            setIsLoadingSimpan(false);
            dispatch(getAllTipeKamarDasarPaket(id));
            dispatch(handleSuccess(message));
          },
          (error) => {
            setIsLoadingSimpan(false);
            dispatch(handleErrorBE(error));
          }
        )
      );
    }
  };

  const handleEditTipeDasar = (data, type, penambahan = false, idx) => {
    let _temp = { ...data };
    if (type === "quad")
      _temp["quad_input"] = _temp["quad_input"] === true ? false : true;
    if (type === "tripple")
      _temp["tripple_input"] = _temp["tripple_input"] === true ? false : true;
    if (type === "double")
      _temp["double_input"] = _temp["double_input"] === true ? false : true;
    if (type === "single")
      _temp["single_input"] = _temp["single_input"] === true ? false : true;

    if (type === "quad_business")
      _temp["quad_input_business"] =
        _temp["quad_input_business"] === true ? false : true;
    if (type === "tripple_business")
      _temp["tripple_input_business"] =
        _temp["tripple_input_business"] === true ? false : true;
    if (type === "double_business")
      _temp["double_input_business"] =
        _temp["double_input_business"] === true ? false : true;
    if (type === "single_business")
      _temp["single_input_business"] =
        _temp["single_input_business"] === true ? false : true;

    if (penambahan) {
      let _tempPenambahan = [...hargaPenambahanTipeKamar];
      _tempPenambahan[idx] = _temp;

      setHargaPenambahanTipeKamar(_tempPenambahan);
    } else {
      setHargaDasarTipeKamar(_temp);
    }
  };

  const handleChangeTipeDasar = (
    data,
    type,
    value,
    penambahan = false,
    idx
  ) => {
    let _temp = { ...data };
    if (type === "quad") _temp["quad_price"] = value;
    if (type === "tripple") _temp["triple_price"] = value;
    if (type === "double") _temp["double_price"] = value;
    if (type === "single") _temp["single_price"] = value;
    if (type === "quad_business") _temp["quad_bussines_price"] = value;
    if (type === "tripple_business") _temp["triple_bussines_price"] = value;
    if (type === "double_business") _temp["double_bussines_price"] = value;
    if (type === "single_business") _temp["single_bussines_price"] = value;
    if (type === "quad_addition_price") _temp["quad_addition_price"] = value;
    if (type === "quad_bussines_addition_price")
      _temp["quad_bussines_addition_price"] = value;
    if (type === "single_addition_price")
      _temp["single_addition_price"] = value;
    if (type === "single_bussines_addition_price")
      _temp["single_bussines_addition_price"] = value;
    if (type === "double_bussines_addition_price")
      _temp["double_bussines_addition_price"] = value;
    if (type === "double_addition_price")
      _temp["double_addition_price"] = value;

    if (type === "triple_bussines_addition_price")
      _temp["triple_bussines_addition_price"] = value;
    if (type === "triple_addition_price")
      _temp["triple_addition_price"] = value;

    if (penambahan) {
      let _tempPenambahan = [...hargaPenambahanTipeKamar];
      _tempPenambahan[idx] = _temp;

      setHargaPenambahanTipeKamar(_tempPenambahan);
    } else {
      setHargaDasarTipeKamar(_temp);
    }
  };

  const handleActionSaveData = (data, penambahan = false) => {
    setIsLoadingSimpan(true);
    const {
      quad_input,
      quad_input_business,
      tripple_input,
      tripple_input_business,
      double_input,
      double_input_business,
      single_input,
      single_input_business,
      ...object_new
    } = data;

    if (penambahan) {
      const { starting, ...params } = object_new;

      const payload = {
        ...params,
        starting_id: starting.starting_id,
      };
      dispatch(
        setUpdatedTipeKamarPenambahanPaket(
          id,
          payload,
          (message) => {
            setIsLoadingSimpan(false);
            dispatch(getAllTipeKamarDasarPaket(id));
            dispatch(getAllTipeKamarPenambahan(id));

            dispatch(handleSuccess(message));
          },
          (error) => {
            setIsLoadingSimpan(false);
            dispatch(handleErrorBE(error));
          }
        )
      );
    } else {
      dispatch(
        setUpdatedTipeKamarDasarPaket(
          id,
          object_new,
          (message) => {
            setIsLoadingSimpan(false);
            dispatch(getAllTipeKamarPenambahan(id));
            dispatch(getAllTipeKamarDasarPaket(id));
            dispatch(handleSuccess(message));
          },
          (error) => {
            setIsLoadingSimpan(false);
            dispatch(handleErrorBE(error));
          }
        )
      );
    }
  };

  const handleViewData = (data, type = "quad") => {
    const payload = {
      starting_id: data?.starting?.starting_id,
      room_type: type,
      name: "",
    };

    dispatch(
      gerListStartingJamaahPaket(
        id,
        payload,
        (message) => {
          setListDataJamaah(message);
          setDataHeaderDrawer({ ...data, type });

          setIsOpenDrawer(true);
        },
        (error) => {
          dispatch(handleErrorBE(error));
        }
      )
    );
  };

  return (
    <Fragment>
      <div className="p-5 border-b">
        <div className="flex flex-row  justify-between items-center mb-[16px]">
          <h1 className="text-xl font-extrabold mb-4">
            Harga Dasar Tipe Kamar
          </h1>
          <Buttons
            text="Atur Starting"
            customClass="btn-outline !font-extrabold btn-small"
            onClick={() => {
              setOpenStartingUtama(true);
            }}
          />
        </div>
        {Object.keys(hargaDasarTipeKamar).length > 0 ? (
          <div className="p-[16px] border-[#E0E0E0] border-[1px] rounded-xl w-full">
            <div className="flex justify-between">
              <Badge
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                customClass={
                  "!py-[4px] !px-[16px] !text-[12px] !font-semibold rounded-lg"
                }
                label={hargaDasarTipeKamar?.starting?.name}
                color={hargaDasarTipeKamar?.starting?.color_text}
                backgroundColor={hargaDasarTipeKamar?.starting?.color_bg}
              />
            </div>
            <div className="flex gap-8 mt-3 mb-[8px]">
              <div>
                <div className="text-[#828282] text-[14px] font-normal">
                  Berangkat
                </div>
                <p className="font-bold">
                  {hargaDasarTipeKamar?.starting?.departure}
                </p>
              </div>
              <div>
                <div className="text-[#828282] text-[14px] font-normal">
                  Pulang
                </div>
                <p className="font-bold">
                  {hargaDasarTipeKamar?.starting?.return}
                </p>
              </div>
            </div>
            <div className="grid grid-cols-4 gap-5">
              <Fragment>
                <RoomPriceCard
                  onHandleView={(data) => handleViewData(data, "quad")}
                  data={hargaDasarTipeKamar}
                  title={"Quad"}
                  img={Quad}
                  isLoading={isLoadingSimpan}
                  price={hargaDasarTipeKamar?.quad_price}
                  priceBusiness={hargaDasarTipeKamar?.quad_bussines_price}
                  checked={hargaDasarTipeKamar?.quad_active}
                  handleSwitch={(data) => handleSwitch(data, "quad")}
                  input={hargaDasarTipeKamar?.quad_input}
                  inputBusiness={hargaDasarTipeKamar?.quad_input_business}
                  onHandleEdit={(data) => handleEditTipeDasar(data, "quad")}
                  handleActionSaveData={(data) => handleActionSaveData(data)}
                  handleChangeTipeDasar={(data, value) =>
                    handleChangeTipeDasar(data, "quad", value)
                  }
                  handleChangeTipeBusiness={(data, value) =>
                    handleChangeTipeDasar(data, "quad_business", value)
                  }
                  onHandleEditBusiness={(data) =>
                    handleEditTipeDasar(data, "quad_business")
                  }
                />
                <RoomPriceCard
                  onHandleView={(data) => handleViewData(data, "triple")}
                  data={hargaDasarTipeKamar}
                  title={"Tripple"}
                  img={Triple}
                  isLoading={isLoadingSimpan}
                  price={hargaDasarTipeKamar?.triple_price}
                  priceBusiness={hargaDasarTipeKamar?.triple_bussines_price}
                  checked={hargaDasarTipeKamar?.triple_active}
                  input={hargaDasarTipeKamar?.tripple_input}
                  inputBusiness={hargaDasarTipeKamar?.tripple_input_business}
                  handleSwitch={(data) => handleSwitch(data, "tripple")}
                  onHandleEdit={(data) => handleEditTipeDasar(data, "tripple")}
                  handleActionSaveData={(data) => handleActionSaveData(data)}
                  handleChangeTipeDasar={(data, value) =>
                    handleChangeTipeDasar(data, "tripple", value)
                  }
                  handleChangeTipeBusiness={(data, value) =>
                    handleChangeTipeDasar(data, "tripple_business", value)
                  }
                  onHandleEditBusiness={(data) =>
                    handleEditTipeDasar(data, "tripple_business")
                  }
                />
                <RoomPriceCard
                  onHandleView={(data) => handleViewData(data, "double")}
                  data={hargaDasarTipeKamar}
                  title={"Double"}
                  img={Double}
                  isLoading={isLoadingSimpan}
                  price={hargaDasarTipeKamar?.double_price}
                  priceBusiness={hargaDasarTipeKamar?.double_bussines_price}
                  checked={hargaDasarTipeKamar?.double_active}
                  input={hargaDasarTipeKamar?.double_input}
                  inputBusiness={hargaDasarTipeKamar?.double_input_business}
                  handleSwitch={(data) => handleSwitch(data, "double")}
                  onHandleEdit={(data) => handleEditTipeDasar(data, "double")}
                  handleActionSaveData={(data) => handleActionSaveData(data)}
                  handleChangeTipeDasar={(data, value) =>
                    handleChangeTipeDasar(data, "double", value)
                  }
                  handleChangeTipeBusiness={(data, value) =>
                    handleChangeTipeDasar(data, "double_business", value)
                  }
                  onHandleEditBusiness={(data) =>
                    handleEditTipeDasar(data, "double_business")
                  }
                />

                <RoomPriceCard
                  onHandleView={(data) => handleViewData(data, "single")}
                  data={hargaDasarTipeKamar}
                  title={"Single"}
                  img={Single}
                  price={hargaDasarTipeKamar?.single_price}
                  priceBusiness={hargaDasarTipeKamar?.single_bussines_price}
                  checked={hargaDasarTipeKamar?.single_active}
                  input={hargaDasarTipeKamar?.single_input}
                  inputBusiness={hargaDasarTipeKamar?.single_input_business}
                  handleSwitch={(data) => handleSwitch(data, "single")}
                  isLoading={isLoadingSimpan}
                  onHandleEdit={(data) => handleEditTipeDasar(data, "single")}
                  handleActionSaveData={(data) => handleActionSaveData(data)}
                  handleChangeTipeDasar={(data, value) =>
                    handleChangeTipeDasar(data, "single", value)
                  }
                  handleChangeTipeBusiness={(data, value) =>
                    handleChangeTipeDasar(data, "single_business", value)
                  }
                  onHandleEditBusiness={(data) =>
                    handleEditTipeDasar(data, "single_business")
                  }
                />
              </Fragment>
            </div>
          </div>
        ) : (
          <div className="flex flex-row justify-center items-center">
            <div className="flex flex-col justify-center items-center h-[450px]">
              <div className="flex flex-col h-full justify-center items-center !gap-y-[16px]">
                <img src={IconJamPasir} alt="jam_pasir" />
                <div className="text-[#141414] text-[14px] font-normal text-center font-sans">
                  Belum ada starting harga dasar tipe kamar
                </div>
                <Buttons
                  text="Pilih Starting"
                  customClass="btn-primary !font-semibold btn-small !w-[99px]"
                  onClick={() => setOpenStartingUtama(true)}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="p-5">
        <div className="flex justify-between">
          <h2 className="text-xl font-extrabold mb-4">
            Penambahan Harga Tipe Kamar Per-starting
          </h2>
          <Buttons
            text="Atur Starting"
            customClass="btn-outline !font-extrabold btn-small"
            onClick={() => {
              setOpenStarting(true);
            }}
          />
        </div>
        {[1].length > 0 ? (
          <>
            <div className="grid grid-cols-2 gap-6 mt-5">
              {hargaPenambahanTipeKamar.map((item, idx) => (
                <div className="border-[#E0E0E0] border-[1px] rounded-xl p-[16px]">
                  <div className="rounded-xl " key={idx}>
                    <div className="flex justify-between">
                      <Badge
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        customClass={
                          "!py-[4px] !px-[16px] !text-[12px] !font-semibold rounded-lg"
                        }
                        label={item?.starting?.name}
                        color={item?.starting?.color_text}
                        backgroundColor={item?.starting?.color_bg}
                      />
                    </div>
                    <div className="flex gap-8 mt-3 mb-[8px]">
                      <div>
                        <div className="text-[#828282] text-[14px] font-normal">
                          Berangkat
                        </div>
                        <p className="font-bold">
                          {hargaDasarTipeKamar?.starting?.departure}
                        </p>
                      </div>
                      <div>
                        <div className="text-[#828282] text-[14px] font-normal">
                          Pulang
                        </div>
                        <p className="font-bold">
                          {hargaDasarTipeKamar?.starting?.return}
                        </p>
                      </div>
                    </div>

                    <div className="grid grid-cols-2 gap-5 mt-5 ">
                      <Fragment>
                        <RoomPriceCard
                          onHandleView={(data) => handleViewData(data, "quad")}
                          data={item}
                          title={"Quad"}
                          isPenambahan={true}
                          img={Quad}
                          isLoading={isLoadingSimpan}
                          price={item?.quad_price}
                          priceBusiness={item?.quad_bussines_price}
                          priceAdditional={item?.quad_addition_price}
                          priceAdditionalBusiness={
                            item?.quad_bussines_addition_price
                          }
                          total={item?.quad_total_price}
                          totalBussines={item?.quad_bussines_total_price}
                          checked={item?.quad_active}
                          handleSwitch={(data) =>
                            handleSwitch(data, "quad", true)
                          }
                          input={item?.quad_input}
                          inputBusiness={item?.quad_input_business}
                          onHandleEdit={(data) =>
                            handleEditTipeDasar(data, "quad", true, idx)
                          }
                          handleActionSaveData={(data) =>
                            handleActionSaveData(data, true)
                          }
                          handleChangeTipeDasar={(data, value) =>
                            handleChangeTipeDasar(
                              data,
                              "quad_addition_price",
                              value,
                              true,
                              idx
                            )
                          }
                          handleChangeTipeBusiness={(data, value) =>
                            handleChangeTipeDasar(
                              data,
                              "quad_bussines_addition_price",
                              value,
                              true,
                              idx
                            )
                          }
                          onHandleEditBusiness={(data) =>
                            handleEditTipeDasar(
                              data,
                              "quad_business",
                              true,
                              idx
                            )
                          }
                        />
                        <RoomPriceCard
                          onHandleView={(data) =>
                            handleViewData(data, "triple")
                          }
                          data={item}
                          isPenambahan={true}
                          title={"Tripple"}
                          img={Triple}
                          isLoading={isLoadingSimpan}
                          priceAdditional={item?.triple_addition_price}
                          priceAdditionalBusiness={
                            item?.triple_bussines_addition_price
                          }
                          total={item?.triple_total_price}
                          totalBussines={item?.triple_bussines_total_price}
                          price={item?.triple_price}
                          priceBusiness={item?.triple_bussines_price}
                          checked={item?.triple_active}
                          input={item?.tripple_input}
                          inputBusiness={item?.tripple_input_business}
                          handleSwitch={(data) =>
                            handleSwitch(data, "tripple", true)
                          }
                          onHandleEdit={(data) =>
                            handleEditTipeDasar(data, "tripple", true, idx)
                          }
                          handleActionSaveData={(data) =>
                            handleActionSaveData(data, true)
                          }
                          handleChangeTipeDasar={(data, value) =>
                            handleChangeTipeDasar(
                              data,
                              "triple_addition_price",
                              value,
                              true,
                              idx
                            )
                          }
                          handleChangeTipeBusiness={(data, value) =>
                            handleChangeTipeDasar(
                              data,
                              "triple_bussines_addition_price",
                              value,
                              true,
                              idx
                            )
                          }
                          onHandleEditBusiness={(data) =>
                            handleEditTipeDasar(
                              data,
                              "tripple_business",
                              true,
                              idx
                            )
                          }
                        />
                        <RoomPriceCard
                          onHandleView={(data) =>
                            handleViewData(data, "double")
                          }
                          data={item}
                          title={"Double"}
                          isPenambahan={true}
                          img={Double}
                          isLoading={isLoadingSimpan}
                          price={item?.double_price}
                          priceBusiness={item?.double_bussines_price}
                          priceAdditional={item?.double_addition_price}
                          priceAdditionalBusiness={
                            item?.double_bussines_addition_price
                          }
                          total={item?.double_total_price}
                          totalBussines={item?.double_bussines_total_price}
                          checked={item?.double_active}
                          input={item?.double_input}
                          inputBusiness={item?.double_input_business}
                          handleSwitch={(data) =>
                            handleSwitch(data, "double", true)
                          }
                          onHandleEdit={(data) =>
                            handleEditTipeDasar(data, "double", true, idx)
                          }
                          handleActionSaveData={(data) =>
                            handleActionSaveData(data, true)
                          }
                          handleChangeTipeDasar={(data, value) =>
                            handleChangeTipeDasar(
                              data,
                              "double_addition_price",
                              value,
                              true,
                              idx
                            )
                          }
                          handleChangeTipeBusiness={(data, value) =>
                            handleChangeTipeDasar(
                              data,
                              "double_bussines_addition_price",
                              value,
                              true,
                              idx
                            )
                          }
                          onHandleEditBusiness={(data) =>
                            handleEditTipeDasar(
                              data,
                              "double_business",
                              true,
                              idx
                            )
                          }
                        />

                        <RoomPriceCard
                          onHandleView={(data) =>
                            handleViewData(data, "single")
                          }
                          data={item}
                          title={"Single"}
                          isPenambahan={true}
                          img={Single}
                          price={item?.single_price}
                          priceAdditional={item?.single_addition_price}
                          priceAdditionalBusiness={
                            item?.single_bussines_addition_price
                          }
                          priceBusiness={item?.single_bussines_price}
                          totalBussines={item?.single_bussines_total_price}
                          total={item?.single_total_price}
                          checked={item?.single_active}
                          input={item?.single_input}
                          inputBusiness={item?.single_input_business}
                          handleSwitch={(data) =>
                            handleSwitch(data, "single", true)
                          }
                          isLoading={isLoadingSimpan}
                          onHandleEdit={(data) =>
                            handleEditTipeDasar(data, "single", true, idx)
                          }
                          handleActionSaveData={(data) =>
                            handleActionSaveData(data, true)
                          }
                          handleChangeTipeDasar={(data, value) =>
                            handleChangeTipeDasar(
                              data,
                              "single_addition_price",
                              value,
                              true,
                              idx
                            )
                          }
                          handleChangeTipeBusiness={(data, value) =>
                            handleChangeTipeDasar(
                              data,
                              "single_bussines_addition_price",
                              value,
                              true,
                              idx
                            )
                          }
                          onHandleEditBusiness={(data) =>
                            handleEditTipeDasar(
                              data,
                              "single_business",
                              true,
                              idx
                            )
                          }
                        />
                      </Fragment>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className="flex flex-row justify-center items-center">
            <div className="flex flex-col justify-center items-center h-[450px]">
              <div className="flex flex-col h-full justify-center items-center !gap-y-[16px]">
                <img src={IconJamPasir} alt="jam_pasir" />
                <div className="text-[#141414] text-[14px] font-normal text-center font-sans">
                  Belum ada starting terpilih
                </div>
                <Buttons
                  text="Pilih Starting"
                  customClass="btn-primary !font-semibold btn-small !w-[99px]"
                  onClick={() => setOpenStarting(true)}
                />
              </div>
            </div>
          </div>
        )}
      </div>

      <Popup
        open={isOpenStartingUtama}
        handleClose={() => setOpenStartingUtama(false)}
        title="Pilih Starting Utama"
        width={1020}
      >
        <div className={classes.modal_body}>
          <div className="border rounded-lg w-full">
            <div className="scroll_area">
              <div className="grid grid-cols-4 gap-4">
                {masterStarting?.map((item, idx) => (
                  <StartingCard
                    item={item}
                    choiceMasterStarting={choiceMasterStarting}
                    setChoiceMasterStarting={setChoiceMasterStarting}
                    toggle="is_active"
                    key={idx}
                  />
                ))}
              </div>
            </div>
            <div className="action_area">
              <Buttons
                text="Batalkan"
                customClass="btn-outline btn-medium w-fit "
                onClick={() => {
                  setOpenStartingUtama(false);
                  setChoiceMasterStarting(null);
                }}
              />
              <Buttons
                text="Simpan"
                customClass="btn-primary btn-medium w-fit !ml-[8px]"
                loading={loadingAction}
                onClick={handleSetTipekamarDasar}
              />
            </div>
          </div>
        </div>
      </Popup>

      <Popup
        open={isOpenStarting}
        handleClose={() => setOpenStarting(false)}
        title="Pilih Starting"
        width={1020}
      >
        <div className={classes.modal_body}>
          <div className="border rounded-lg w-full">
            <div className="scroll_area">
              <div className="grid grid-cols-4 gap-4">
                {masterStarting
                  ?.filter(
                    (val) =>
                      val.id_starting !== choiceMasterStarting?.id_starting
                  )
                  ?.map((item, idx) => (
                    <StartingCard
                      choiceMasterStarting={choiceMasterStarting}
                      setChoiceMasterStarting={setChoiceMasterStarting}
                      item={item}
                      toggle="is_active"
                      key={idx}
                      isSwitch={true}
                      handleChecked={handleChecked}
                    />
                  ))}
              </div>
            </div>
            <div className="action_area">
              <Buttons
                text="Batalkan"
                customClass="btn-outline btn-medium w-fit "
                onClick={() => setOpenStarting(false)}
              />
              <Buttons
                text="Simpan"
                customClass="btn-primary btn-medium w-fit !ml-[8px]"
                onClick={handleSetTipekamarStarting}
                loading={loadingAction}
              />
            </div>
          </div>
        </div>
      </Popup>

      <JamaahDrawer
        isOpenDrawer={isOpenDrawer}
        setIsOpenDrawer={setIsOpenDrawer}
        listDataJamaah={listDataJamaah}
        dataHeaderDrawer={dataHeaderDrawer}
        input={input}
        setInput={setInput}
      />
    </Fragment>
  );
};

export default TipeKamar;
