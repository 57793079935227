import React from "react";

import Tab from "components/tab";
import Buttons from "components/buttons";
import AddTestimoniUpload from "./components/Upload";
import AddTestimoniIbadahku from "./components/Ibadahku";

const AppsAddTestimoni = ({ handleClose }) => {
    return (
        <div className="bg-white border-[1px] border-[#E0E0E0] rounded-2xl">
            <div className="px-4 py-6">
                <Tab
                    defaultActiveKey="1"
                    items={[
                        {
                            label: "Upload",
                            key: "1",
                            children: (
                                <AddTestimoniUpload handleClose={handleClose} />
                            ),
                        },
                        {
                            label: "Pilih Dari Ibadahku",
                            key: "2",
                            children: <AddTestimoniIbadahku />,
                        },
                    ]}
                />
            </div>
        </div>
    );
};

export default React.memo(AppsAddTestimoni);
