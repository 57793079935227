// pusatBantuanKontak Reducers
// --------------------------------------------------------

import {
    SET_LOADING,
    CLEAR_ERROR,
    SET_ERROR,
    INIT_DATA,
    SET_DOUBLE_SUBMIT,
    SET_KONTAK_EMAIL_DATA,
    SET_KONTAK_WHATSAPP_DATA,
} from "../../../actions/pusatBantuan/kontak";

const initialState = {
    isLoading: false,
    isDoubleSubmit: false,
    isSubmitting: false,
    error: {},
    kontakEmailData: [],
    kontakWhatsappData: [],
};

export default (state = initialState, { payload, type }) => {
    switch (type) {
        case SET_LOADING:
            return {
                ...state,
                isLoading: payload,
            };
        case SET_DOUBLE_SUBMIT:
            return {
                ...state,
                isDoubleSubmit: payload,
            };
        case SET_ERROR:
            return {
                ...state,
                error: payload,
            };
        case CLEAR_ERROR:
            return {
                ...state,
                error: {},
            };
        case INIT_DATA:
            return {
                ...initialState,
            };
        case SET_KONTAK_EMAIL_DATA:
            return {
                ...state,
                kontakEmailData: payload,
            };
        case SET_KONTAK_WHATSAPP_DATA:
            return {
                ...state,
                kontakWhatsappData: payload,
            };
        default:
            return state;
    }
};
