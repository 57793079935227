import Pesanan from "../assets/icons/pesanan.svg";
import PesananRed from "../assets/icons/pesanan-red.svg"
import TagihanGrey from "../assets/icons/tagihan-grey.svg";
import TagihanRed from "../assets/icons/tagihan-red.svg";

export const calculatorPacketMenuList = [
  {
    title: "Request Paket",
    key: "request_paket",
    icon: Pesanan,
    iconActive: PesananRed,
    order: 0,
  },
  {
    title: "Update Harga",
    key: "update_harga",
    icon: TagihanGrey,
    iconActive: TagihanRed,
    order: 1,
  }
];
