import axios from "axios";
import { BASE_URL_ENDPOINT } from "constant/constanta";

const BookingUmrohAPI = {
  getList: (payload) => axios.post(`${BASE_URL_ENDPOINT}/admin/order`, payload),
  postData: (payload) =>
    axios.post(`${BASE_URL_ENDPOINT}/admin/save-order`, payload),
};

export { BookingUmrohAPI };
