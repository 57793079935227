import { useEffect, useState } from "react";
import { Radio, makeStyles } from "@material-ui/core";
import IconEdit from "assets/icons/edit.svg";
import Popup from "components/popup";
import InputsCurrency from "components/form/inputsCurrency";
import Buttons from "components/buttons";
import ILHotel from "assets/images/hotel.png";
import ICChecklist from "assets/icons/Checklist_Round.svg";
import ICStar from "assets/icons/Star.svg";
import Inputs from "components/form/inputs";
import { BASE_URL_ENDPOINT } from "constant/constanta";
import { getLocalStorage } from "utils/helpers";
import axios from "axios";

import { getDataAkomodasi } from "utils/api/requestPaket";

import IconJamPasir from "assets/icons/Jam-Pasir.svg";
import { Skeleton } from 'antd';

const useStyle = makeStyles({
  step3: {
    "& .select-label": {
      fontSize: "16px",
      fontWeight: "600",
      color: "#141414",
    },
    "& .input": {
      accentColor: "red",
    },
    "& .content_list": {
      overflowY: "auto",
      overflowX: "hidden",
      "&::-webkit-scrollbar": {
        width: "4px",
        // height: scrollHorizontal ? "5px" : "100px",
      },
      "&::-webkit-scrollbar-track": {
        width: "4px",
        borderRight: "10px solid #fff",
        borderLeft: "10px solid #fff",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#E0E0E0",
        height: "30px",
        width: "4px",
        borderRadius: "16px",
      },
      "&::-webkit-scrollbar-track-piece:start": {
        background: "#fff",
      },
      "&::-webkit-scrollbar-track-piece:end": {
        background: "#fff",
      },
      scrollBehavior: "smooth",
    },
  },
});

const Step3 = ({ payload, payloadJumlahHari, setPayload, onHandleError }) => {
  const classes = useStyle();
  const [editJumlahMalamModal, setEditJumlahMalamModal] = useState({
    madinah: false,
    makkah: false,
  });

  const [dataAllHotel, setDataAllHotel] = useState([]);
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    getDataAllHotel();
  }, []);

  const getDataAllHotel = async () => {

    try {
      setIsLoading(true)
      const response = await getDataAkomodasi({ month: payloadJumlahHari?.bulan , year: payloadJumlahHari?.tahun });

      if (response.data.code === 200) {
        setDataAllHotel(response.data.data);
      } else {
        onHandleError({
          show: true,
          subtitle: response.data.message,
        })
      }
      
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      onHandleError({
        show: true,
        subtitle: error.data.message,
      })
    }
  };

  return (
    <div className={classes.step3}>
      <div className="select-label mb-4">Pilih Hotel</div>
      <div className="flex flex-row mb-4 items-center">
        <h1 className="text-base font-semibold text-[#141414]">Madinah</h1>
        <h1 className="text-sm font-normal text-[#E82320] mx-2">
          ({payload.jumlah_malam_madinah} malam)
        </h1>
        <div
          className="flex gap-4 items-center"
          onClick={() => setEditJumlahMalamModal({ madinah: true })}
        >
          <span className="cursor-pointer shadow-hard p-2 rounded-lg">
            <img src={IconEdit} alt="Edit" />
          </span>
        </div>
      </div>
      {
        !dataAllHotel.filter((item) => item.city === "madinah").length && !isLoading ? 
          <div className="flex flex-col justify-center items-center w-full h-full">
            <img src={IconJamPasir} alt="jam_pasir" width="150px" />
            <div className="text-[#141414] text-[14px] font-normal text-center font-sans mt-2 whitespace-pre-line w-64">
              Dibulan & tahun yang anda tentukan belum ada Hotel Madinah yang tersedia
            </div>
          </div>
        : null
      }
      { isLoading ?
          <div className="grid grid-cols-3 gap-4">
            {[...Array(6)].map((_, index) => (
              <Skeleton.Input active={true} size="large" block={true} />
            ))}
          </div>
      : null }
      <div className="grid grid-cols-3 gap-4 mb-4 content_list">
        {dataAllHotel
          .filter((item) => item.city === "madinah")
          .map((item, idx) => {
            return (
              <div
                key={idx}
                className={`flex flex-row gap-4 items-center w-full p-4 rounded-2xl border-[1px] ${
                  payload.madinah === item.akomodasi_id.toString() && "border-[#01F91A]"
                }`}
              >
                <input
                  className="text-red-500 shrink-0 input w-6 h-6 cursor-pointer"
                  type="radio"
                  checked={item.akomodasi_id.toString() === payload.madinah}
                  value={item.akomodasi_id}
                  onChange={(event) => {
                    setPayload({
                      ...payload,
                      madinah: event.target.value.toString(),
                    });
                  }}
                />
                <img
                  src={ILHotel}
                  alt="hotel"
                  className="bg-slate-200 shrink-0 w-[64px] h-[64px] rounded-2xl"
                />
                <div>
                  <h1 className="text-base font-semibold text-[#141414] mb-1">
                    {item._akomodasi.name}
                  </h1>
                  <div className="flex flex-row">
                    {[...Array(item._akomodasi.bintang)].map((_, index) => (
                      <img src={ICStar} alt="star" className="h-4 w-4" />
                    ))} 
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <div className="flex flex-row mb-4 items-center">
        <h1 className="text-base font-semibold text-[#141414]">Makkah</h1>
        <h1 className="text-sm font-normal text-[#E82320] mx-2">
          ({payload.jumlah_malam_makkah} malam)
        </h1>
        <div
          className="flex gap-4 items-center"
          onClick={() => setEditJumlahMalamModal({ makkah: true })}
        >
          <span className="cursor-pointer shadow-hard p-2 rounded-lg">
            <img src={IconEdit} alt="Edit" />
          </span>
        </div>
      </div>
      {
        !dataAllHotel.filter((item) => item.city === "makkah").length && !isLoading ? 
          <div className="flex flex-col justify-center items-center w-full h-full">
            <img src={IconJamPasir} alt="jam_pasir" width="150px" />
            <div className="text-[#141414] text-[14px] font-normal text-center font-sans mt-2 whitespace-pre-line w-64">
              Dibulan & tahun yang anda tentukan belum ada Hotel Makkah yang tersedia
            </div>
          </div>
        : null
      }
      { isLoading ?
          <div className="grid grid-cols-3 gap-4">
            {[...Array(6)].map((_, index) => (
              <Skeleton.Input active={true} size="large" block={true} />
            ))}
          </div>
      : null }
      <div className="grid grid-cols-3 gap-4 content_list">
        {dataAllHotel
          .filter((item) => item.city === "makkah")
          .map((item, idx) => {
            return (
              <div
                key={idx}
                className={`flex flex-row items-center w-full gap-4 p-4 rounded-2xl border-[1px] ${
                  item.akomodasi_id.toString() === payload.makkah && "border-[#01F91A]"
                }`}
              >
                <input
                  className="text-red-500 input w-6 h-6 shrink-0 cursor-pointer"
                  type="radio"
                  checked={item.akomodasi_id.toString() === payload.makkah}
                  value={item.akomodasi_id}
                  onChange={(event) => {
                    setPayload({
                      ...payload,
                      makkah: event.target.value.toString(),
                    });
                  }}
                />
                <img
                  src={ILHotel}
                  alt="hotel"
                  className="bg-slate-200 shrink-0 w-[64px] h-[64px] rounded-2xl"
                />
                <div>
                  <h1 className="text-base font-semibold text-[#141414] mb-1">
                    {item._akomodasi.name}
                  </h1>
                  <div className="flex flex-row">
                    {[...Array(item._akomodasi.bintang)].map((_, index) => (
                      <img src={ICStar} alt="star" className="h-4 w-4" />
                    ))} 
                  </div>
                </div>
              </div>
            );
          })}
      </div>

      <Popup
        open={editJumlahMalamModal.madinah}
        title="Edit Jumlah Malam Hotel Madinah"
        width="640px"
        handleClose={() => {
          //   setSelectedPaket(null);
          setEditJumlahMalamModal({ madinah: false });
        }}
      >
        <div className="border border-gray-5 rounded-xl mt-[7px]">
          <div className="p-4">
            <Inputs
              label="Jumlah Malam"
              placeholder="Jumlah Malam"
              containerClass="w-full mb-2"
              value={payload.jumlah_malam_madinah}
              onChange={(e) => {
                setPayload({
                  ...payload,
                  jumlah_malam_madinah: e.target.value,
                });
              }}
            />
          </div>
          <div className="border-t border-gray-5 p-4 flex justify-end">
            <Buttons
              text="Simpan"
              customClass="btn-primary btn-large"
              onClick={() => {
                setPayload({
                  ...payload,
                  jumlah_malam_madinah: payload.jumlah_malam_madinah,
                });
                setEditJumlahMalamModal({ madinah: false });
              }}
            />
          </div>
        </div>
      </Popup>
      <Popup
        open={editJumlahMalamModal.makkah}
        title="Edit Jumlah Malam Hotel Makkah"
        width="640px"
        handleClose={() => {
          //   setSelectedPaket(null);
          setEditJumlahMalamModal({ makkah: false });
        }}
      >
        <div className="border border-gray-5 rounded-xl mt-[7px]">
          <div className="p-4">
            <Inputs
              label="Jumlah Malam"
              placeholder="Jumlah Malam"
              containerClass="w-full mb-2"
              value={payload.jumlah_malam_makkah}
              onChange={(e) => {
                setPayload({
                  ...payload,
                  jumlah_malam_makkah: e.target.value,
                });
              }}
            />
          </div>
          <div className="border-t border-gray-5 p-4 flex justify-end">
            <Buttons
              text="Simpan"
              customClass="btn-primary btn-large"
              onClick={() => {
                setPayload({
                  ...payload,
                  jumlah_malam_makkah: payload.jumlah_malam_makkah,
                });
                setEditJumlahMalamModal({ makkah: false });
              }}
            />
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default Step3;
