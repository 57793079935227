import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import Buttons from "components/buttons";
import Cards from "components/cards";
import MoslemeTable from "components/Table";
import Input from "components/form/inputs";
import Textarea from "components/form/textarea";
import ImageField from "components/image_field";
import FileField from "components/file_field";
import Badge from "components/badge";

import Logo1 from "assets/images/logo1.png";
import Logo2 from "assets/images/logo2.png";
import IconFile from "assets/icons/icon-file-colored.svg";
import IconTrash from "assets/icons/trash.svg";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getTravelAction,
    saveTravelAction,
} from "stores/actions/setting/travel";
import { globalState } from "stores/actions/general";
import { columns } from "../../columns";

const EditTravel = ({ setIsEdit }) => {
    const dispatch = useDispatch();

    const dataUpload = useSelector(({ uploadFile }) => uploadFile.dataUpload);
    const dataUploadMulti = useSelector(
        ({ uploadFile }) => uploadFile.dataMultiple
    );

    const { dataTravel, isLoading } = useSelector(
        (state) => state.settingTravel
    );

    const [namaTravel, setNamaTravel] = useState(dataTravel?.nama_travel);
    const [descriptionTravel, setDescriptionTravel] = useState(
        dataTravel?.deskripsi_travel
    );
    const [dataFileLegalitas, setDataFileLegalitas] = useState(
        dataTravel?.data_file_legalitas || []
    );

    const [isError, setIsError] = React.useState(false);

    const deleteFile = (name) => {
        const filtered = dataFileLegalitas.filter((item) => item.name !== name);

        setDataFileLegalitas(filtered);
    };

    const payload = {
        logo1:
            dataUpload.filter((file) => file.name == "logo1")[0]?.path ||
            dataTravel?.logo1,
        logo2:
            dataUpload.filter((file) => file.name == "logo2")[0]?.path ||
            dataTravel?.logo2,
        nama_travel: namaTravel,
        deskripsi_travel: descriptionTravel,
        data_file_legalitas: dataFileLegalitas,
    };

    const handleSave = async () => {
        await dispatch(saveTravelAction(payload));
        await dispatch(getTravelAction());
        setIsEdit(false);
    };

    useEffect(() => {
        setDataFileLegalitas(dataTravel.data_file_legalitas);
    }, [dataTravel]);

    useEffect(() => {
        const file = dataUploadMulti
            .filter((item) => item.name == "file_upload_setting")
            .map((item) => ({
                name: item.fileName,
                path: item.path,
            }));
        setDataFileLegalitas([...dataFileLegalitas, ...file]);
    }, [dataUploadMulti]);

    const { global } = useSelector((state) => state.general);
    const { dataAllCabang } = useSelector((state) => state.cabang);
    const total = dataAllCabang?.total_data;

    const pagination = {
        showSizeChanger: true,
        total: total,
        // current: currentpage,
        // pageSize: size,
        current: global?.currentpage,
        pageSize: global?.size,
        defaultPageSize: 10,
        pageSizeOptions: ["10", "20"],
    };

    const allCabang =
        dataAllCabang?.total_data !== 0 &&
        dataAllCabang?.data?.map((item, idx) => {
            return {
                ...item,
                key: idx + 1,
            };
        });

    return (
        <Cards containerClass="!p-0">
            <div className="flex flex-row justify-between items-center p-4">
                <div className="text-[20px] font-semibold">
                    Informasi Travel
                </div>
                <div className="flex gap-5">
                    <Buttons
                        text="Batalkan"
                        customClass="btn-outline btn-small"
                        onClick={() => setIsEdit(false)}
                    />
                    <Buttons
                        text="Simpan"
                        customClass="btn-primary btn-small"
                        onClick={handleSave}
                        loading={isLoading}
                    />
                </div>
            </div>
            <div className="divider"></div>
            <div className="p-4">
                <div className="border-[1px] border-[#E0E0E0] soft-shadow rounded-[16px] p-4">
                    <div className="text-[16px] font-semibold">Logo Travel</div>
                    <div className="mt-4">
                        <Grid container spacing={2}>
                            <Grid item md={4}>
                                <ImageField
                                    customContainer="h-[200px]"
                                    error={isError}
                                    isMulti={false}
                                    setFile={(file) => console.log(file)}
                                    name="logo1"
                                    label="Logo 1"
                                />
                            </Grid>
                            <Grid item md={4}>
                                <ImageField
                                    customContainer="h-[200px]"
                                    error={isError}
                                    isMulti={false}
                                    setFile={(file) => console.log(file)}
                                    name="logo2"
                                    label="Logo 2"
                                />
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <div className="border-[1px] border-[#E0E0E0] soft-shadow rounded-[16px] p-4 mt-4">
                    <div className="text-[16px] font-semibold">
                        Informasi Umum
                    </div>
                    <div className="flex w-full gap-5 mt-5">
                        <Input
                            label="Nama Travel"
                            containerClass="w-full"
                            onChange={(e) => setNamaTravel(e.target.value)}
                            value={namaTravel}
                        />
                        <Textarea
                            label="Deskripsi"
                            containerClass="w-full"
                            onChange={(e) =>
                                setDescriptionTravel(e.target.value)
                            }
                        />
                    </div>
                </div>
                <div className="border-[1px] border-[#E0E0E0] soft-shadow rounded-[16px] p-4 mt-4">
                    <div className="text-[16px] font-semibold">
                        Legalitas Travel
                    </div>
                    <div className="mt-4">
                        <Grid container spacing={2}>
                            <Grid item md={6}>
                                <FileField
                                    customContainer="h-[200px] w-full"
                                    error={isError}
                                    isMulti={true}
                                    setFile={(file) =>
                                        console.log("file", file)
                                    }
                                    name="file_upload_setting"
                                    label="Upload File"
                                />
                            </Grid>
                            <Grid item md={6}>
                                <label class="font-semibold">File</label>
                                {dataFileLegalitas.map((item, index) => (
                                    <div
                                        className="flex flex-col gap-3 mt-3"
                                        key={index}
                                    >
                                        <div className="bg-[#F2F2F2] rounded-lg p-4 flex justify-between">
                                            <div className="flex gap-3">
                                                <img
                                                    src={IconFile}
                                                    alt="icon file"
                                                />
                                                <div>
                                                    <h5 className="font-semibold">
                                                        {item.name}
                                                    </h5>
                                                </div>
                                            </div>
                                            <button
                                                onClick={() =>
                                                    deleteFile(item.name)
                                                }
                                            >
                                                <img
                                                    src={IconTrash}
                                                    alt="icon trash"
                                                    width={20}
                                                />
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <div className="border-[1px] border-[#E0E0E0] soft-shadow rounded-[16px] p-4 mt-4">
                    <div className="text-[16px] font-semibold">List Cabang</div>
                    <div className="border mt-2 rounded">
                        <MoslemeTable
                            expandable={false}
                            columns={columns(dispatch)}
                            data={allCabang}
                            withSelection={false}
                            pagination={pagination}
                            customPaginationClass="pr-4"
                            loading={isLoading}
                            onPageChange={(page) =>
                                dispatch(
                                    globalState({
                                        currentpage: page,
                                        size: global.size,
                                    })
                                )
                            }
                            onSizeChange={(currentpage, pageSize) =>
                                dispatch(
                                    globalState({
                                        currentpage: currentpage,
                                        size: pageSize,
                                    })
                                )
                            }
                        />
                    </div>
                </div>
            </div>
        </Cards>
    );
};

export default React.memo(EditTravel);
