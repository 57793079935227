import { combineReducers } from "redux";
import login from "./login";
import role from "./role";
import cabang from "./cabang";
import errorGeneral from "./errorGeneral";
import uploadFile from "./uploadFile";
import uploadProgress from "./uploadProgress";
import datamaster from "./datamaster";
import itinenary from "./paket/itinenary";
import transportasi from "./paket/transportasi";
import penerbangan from "./paket/penerbangan";
import infoDasar from "./paket/infoDasar";
import additional from "./paket/additional";
import admin from "./member/admin";
import adminStarting from "./datamaster/starting";
import adminAdditional from "./datamaster/adminAdditional";
import adminRekening from "./datamaster/adminRekening";
import musim from "./datamaster/musim";
import perlengkapan from "./paket/perlengkapan";
import dokumen from "./paket/dokumen";
import general from "./general";
import dashboard from "./dashboard";
import artikel from "./apps/artikel";
import agen from "./member/agen";
import gallery from "./apps/gallery";
import marketingKit from "./marketingKit";
import mitra from "./apps/mitra";
import guide from "./apps/guide";
import staff from "./apps/staff";
import jamaah from "./member/jamaah";
import testimoni from "./apps/testimoni";
import slide from "./apps/slide";
import landingPaket from "./paket/landing";
import pusatBantuanKontak from "./pusatBantuan/kontak";
import settingTravel from "./setting/travel";
import faq from "./faq";
import privacy from "./privacy";
import calculator from "./calculator";
import articleSetting from "./article";
import settingAbout from "./settings/about";
import calculatorRequestPaket from "./calculator/request";
import tagihan from "./tagihan";
import bookingUmroh from "./booking/umroh";
import akomodasi from "./paket/akomodasi";
import generalInfo from "./paket/generalInfo";
import tipeKamar from "./paket/tipeKamar";
import paketDetail from "./paketDetail";
import paketFee from "./paket/fee";
import doa from "./layananIslami/doa";
import coupon from "./paket/coupon";
import updateHargaCalculator from "./calculator/updatePrice";
import layout from './layout'

export const rootReducer = combineReducers({
  login,
  errorGeneral,
  role,
  cabang,
  uploadFile,
  uploadProgress,
  datamaster,
  itinenary,
  transportasi,
  penerbangan,
  dashboard,
  infoDasar,
  adminStarting,
  adminAdditional,
  adminRekening,
  musim,
  admin,
  general,
  artikel,
  gallery,
  agen,
  mitra,
  guide,
  staff,
  jamaah,
  testimoni,
  slide,
  perlengkapan,
  dokumen,
  marketingKit,
  landingPaket,
  faq,
  privacy,
  calculator,
  pusatBantuanKontak,
  articleSetting,
  settingAbout,
  calculatorRequestPaket,
  settingTravel,
  tagihan,
  bookingUmroh,
  akomodasi,
  generalInfo,
  additional,
  tipeKamar,
  paketFee,
  doa,
  paketDetail,
  paketFee,
  doa,
  coupon,
  updateHargaCalculator,
  layout
});
