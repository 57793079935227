export const SET_LOADING = "apps/calculator/request-paket/SET_LOADING";
export const CLEAR_ERROR = "apps/calculator/request-paket/CLEAR_ERROR";
export const SET_ERROR = "apps/calculator/request-paket/SET_ERROR";
export const START_FETCHING_REQUEST =
  "apps/calculator/request-paket/START_FETCHING_REQUEST";
export const ERROR_FETCHING_REQUEST =
  "apps/calculator/request-paket/ERROR_FETCHING_REQUEST";
export const SUCCESS_FETCHING_REQUEST =
  "apps/calculator/request-paket/SUCCESS_FETCHING_REQUEST";
export const SET_APPROVE_REQUEST =
  "apps/calculator/request-paket/SET_APPROVE_REQUEST";
export const SET_REJECT_REQUEST =
  "apps/calculator/request-paket/SET_REJECT_REQUEST";
