import React, { useEffect, useState } from "react";
import moment from "moment";

import MoslemeTable from "../../../../../components/Table";
import Badge from "../../../../../components/badge";
import PaketTagihanDetail from "./components/Detail";
import { useSelector, useDispatch } from "react-redux";

import { DocumentCopy, Edit, Eye, Trash } from "iconsax-react";
import AvatarRed from "../../../../../assets/images/Avatar-ikhwan-red.svg";
import IconEye from "../../../../../assets/icons/eye.svg";
import {
  getAllDataTagihan,
  getDetailDataTagihan,
} from "stores/actions/paketDetail";

const PaketTagihan = () => {
  // Selection & type state
  const [selectionType, setSelectionType] = useState("checkbox");
  const [showDetail, setShowDetail] = useState(false);
  const [detailId, setDetailId] = useState("");

  // data table
  const { data, status } = useSelector((state) => state.paketDetail);
  let dataTable = data.data;
  const dispatch = useDispatch();

  // Pagination state
  const [currentpage, setCurrentpage] = useState(1);
  const [size, setSize] = useState(10);
  const total = 100;

  useEffect(() => {
    dispatch(
      getAllDataTagihan({
        page_number: 1,
        per_page: 10,
        sort_column: "order_number",
        sort_order: "ASC",
        tipe_paket: 1,
      })
    );
  }, []);

  const columns = [
    {
      title: "Foto",
      render: (record) => (
        <>
          <img
            className="rounded-full w-10 h-10 object-cover"
            alt="logo maskapai"
            src={record && record?.image_thumbnail}
          />
        </>
      ),
    },
    {
      title: "Title",
      dataIndex: "user_jamaah",
      render: (record) => (
        <>
          <div className="w-max">
            {(record && record?.title === null) || undefined
              ? " - "
              : record?.title}
          </div>
        </>
      ),
    },
    {
      title: "Nama",
      dataIndex: "user_jamaah",
      render: (record) => (
        <>
          <div className="w-max">{record && record?.name}</div>
          {/* <div className="flex flex-row mt-1">
            <Badge label={tags[0]} type="blue light" />
            <Badge label={tags[1]} type="orange old" customClass="ml-1" />
          </div> */}
        </>
      ),
    },
    {
      title: "Nomor Tagihan",
      dataIndex: "order_number",
    },
    {
      title: "Waktu Booking",
      dataIndex: "created_at",
      render: (record) => (
        <>
          <div className="">
            {record && moment(record.created_at).format("LLL")}
          </div>
        </>
      ),
    },
    {
      title: "Jumlah Jamaah",
      render: (record) => (
        <div className="border-[1px] border-[#E0E0E0] rounded-lg px-3 py-2 flex flex-row items-center gap-2 w-max">
          <img src={IconEye} alt="eye" />
          <div>{record && record?.jumlah_pax} pax</div>
        </div>
      ),
    },
    {
      title: "Status Pembayaran",
      dataIndex: "payment_status",
      render: () => <Badge label="DP" />,
    },
    {
      title: "Total Tagihan",
      dataIndex: "total_price",
    },
    {
      title: "Kekurangan",

      render: (record) => (
        <>
          <div className="font-semibold w-max">
            Rp {record.remaining_payment_amount}
          </div>
          {/* <div className="flex flex-row gap-1">
            <Buttons
              text="1"
              customClass="btn-brown-light btn-small !w-fit"
              icon={IconWhatsapp}
            />
            <Buttons
              text="2"
              customClass="btn-brown-light btn-small !w-fit"
              icon={IconWhatsapp}
            />
          </div> */}
          <div className="action">
            <Eye
              className="cursor-pointer"
              onClick={() => {
                dispatch(getDetailDataTagihan(record.id));
                setShowDetail(true);
              }}
              size="15"
              color="#141414"
            />
            <Trash
              className="cursor-pointer"
              // onClick={() => setOpenPopupDeleteCabang(true)}
              size="15"
              color="#141414"
            />
          </div>
        </>
      ),
    },
    {
      title: "Cabang",
      dataIndex: "branch",
      render: (paket) => (
        <>
          <Badge label={paket} />
          <div className="action">
            <DocumentCopy
              className="cursor-pointer"
              onClick={() => alert(`copy id:`)}
              size="15"
              color="#141414"
            />
            <Edit
              className="cursor-pointer"
              onClick={() => alert(`edit id:`)}
              size="15"
              color="#141414"
            />
          </div>
        </>
      ),
    },
  ];

  // const data = [
  //   {
  //     key: "1",
  //     photo: AvatarRed,
  //     title: "Tuan",
  //     name: {
  //       name: "Dimas Andreyean Pradana Putra",
  //       tags: ["Admin Cabang Daerah", "ACEH"],
  //     },

  //     bill_number: "UMR-987",
  //     bookdate: "21/08/2022 14.07 WIB",
  //     total_user: "6",
  //     payment_status: {
  //       code: 1,
  //       value: "DP",
  //     },
  //     total_bill: "135.000.000",
  //     bill_deficit: "100.000.000",
  //     branch: "Pekanbaru",
  //   },
  //   {
  //     key: "2",
  //     photo: AvatarRed,
  //     title: "Tuan",
  //     name: {
  //       name: "Dimas Andreyean Pradana Putra",
  //       tags: ["Admin Cabang Daerah", "ACEH"],
  //     },

  //     bill_number: "UMR-987",
  //     bookdate: "21/08/2022 14.07 WIB",
  //     total_user: "6",
  //     payment_status: {
  //       code: 1,
  //       value: "DP",
  //     },
  //     total_bill: "135.000.000",
  //     bill_deficit: "100.000.000",
  //     branch: "Pekanbaru",
  //   },
  //   {
  //     key: "3",
  //     photo: AvatarRed,
  //     title: "Tuan",
  //     name: {
  //       name: "Dimas Andreyean Pradana Putra",
  //       tags: ["Admin Cabang Daerah", "ACEH"],
  //     },

  //     bill_number: "UMR-987",
  //     bookdate: "21/08/2022 14.07 WIB",
  //     total_user: "6",
  //     payment_status: {
  //       code: 1,
  //       value: "DP",
  //     },
  //     total_bill: "135.000.000",
  //     bill_deficit: "100.000.000",
  //     branch: "Pekanbaru",
  //   },
  // ];

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: total,
    current: currentpage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20"],
  };

  // func to set size pagination
  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setSize(pageSize);
  };

  const expandable = {
    expandedRowRender: (record) => <p style={{ margin: 0 }}>{record.name}</p>,
    rowExpandable: (record) => record.name !== "Not Expandable",
  };

  // action button
  const ButtonAction = ({ value }) => {
    return (
      <div className="cursor-pointer flex justify-around">
        <Eye
          //   onClick={() => router.push("/tagihan/detail")}
          size="15"
          color="#141414"
        />
        <Trash
          onClick={() => alert(`delete id:`, value)}
          size="15"
          color="#141414"
        />
        <DocumentCopy
          onClick={() => alert(`copy id:`, value)}
          size="15"
          color="#141414"
        />
        <Edit
          onClick={() => alert(`edit id:`, value)}
          size="15"
          color="#141414"
        />
      </div>
    );
  };

  // manipulated data with action
  // const sourceData = data.map((v) => {
  //   v.actions = <ButtonAction value={v} />;
  //   return v;
  // });

  return (
    <>
      {showDetail ? (
        <PaketTagihanDetail setShowDetail={setShowDetail} />
      ) : (
        <MoslemeTable
          columns={columns}
          data={dataTable}
          withSelection={true}
          selectionType={selectionType}
          pagination={pagination}
          customPaginationClass="pr-4"
          customClass="mt-4"
          onPageChange={(page) => setCurrentpage(page)}
          onSizeChange={(currentpage, pageSize) =>
            onSizeChange(currentpage, pageSize)
          }
        />
      )}
    </>
  );
};

export default React.memo(PaketTagihan);
