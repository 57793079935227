import { Edit, Trash } from "iconsax-react";

export const columns = (dispatch, setIsDelete, setIsChoice) => [
  {
    title: "No",
    dataIndex: "id_transportation",

    render: (id_transportation, item, idx) => {
      console.log(idx);
      return <div>{idx + 1}</div>;
    },
  },
  {
    title: "Logo",
    dataIndex: "logo",

    render: (logo) => (
      <img src={logo} alt="avatar" className="w-8 h-8 rounded-full" />
    ),
  },
  {
    title: "Nama",
    dataIndex: "name",
    render: (name) => (
      <div className="flex flex-row">
        <div className="ml-2">{name}</div>
      </div>
    ),
  },
  {
    title: "Kategori",
    dataIndex: "category",
    render: (s, item) => {
      return (
        <div className="w-full relative flex flex-row justify-between">
          {s !== null && s?.length > 150 ? `${s?.substr(0, 150)}...` : s}

          <div className="action absolute !w-full top-2 flex !flex-row !justify-end">
            <div className="flex flex-row justify-end pr-10 gap-x-16">
              <Trash
                className="cursor-pointer"
                onClick={() => {
                  setIsDelete(true);
                  setIsChoice(item);
                }}
                size="15"
                color="#141414"
              />
            </div>
          </div>
        </div>
      );
    },
  },
];
