import React, { useState } from "react";

import Inputs from "components/form/inputs";
import AntdSelect from "components/form/antdSelect";
import Buttons from "components/buttons";

const AddOrEditRekening = () => {
  const [optionsBank, setOptionsBank] = useState([
    {
      value: "bca",
      label: "BCA",
    },
    {
      value: "bsi",
      label: "BSI",
    },
  ]);

  return (
    <>
      <div className="bg-white border border-[#E0E0E0] rounded-2xl">
        <div className="p-4">
          <Inputs label="Nama Akun" placeholder="Masukkan Nama" />
          <div className="flex flex-row items-center gap-4 mt-4">
            <AntdSelect
              label="Bank"
              containerClass="!w-[30%]"
              placeholder="Select Bank"
              options={optionsBank}
              onChange={(e, opt) => {}}
            >
              {optionsBank.map((val, idx) => {
                return (
                  <div value={val.value} data={val} label={val.label} key={idx}>
                    {val.label}
                  </div>
                );
              })}
            </AntdSelect>
            <Inputs
              label="Nama Rekening"
              placeholder="Masukkan nomer rekening"
              containerClass="!w-[70%]"
              customClass="h-[54px]"
            />
          </div>
        </div>
        <div className="divider"></div>
        <div className="flex flex-row justify-end p-4">
          <Buttons text="Tambah" customClass="btn-primary btn-large" />
        </div>
      </div>
    </>
  );
};

export default React.memo(AddOrEditRekening);
