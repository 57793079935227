import { useState } from "react";
import AntdSelect from ".";
import { Col, Row } from "antd";
import IconBrush from "../../../assets/icons/brush.svg";

const AntdSelectsDocs = () => {
  const [options, setOptions] = useState([
    {
      id: 1,
      value: "jack",
      label: "Jack",
    },
    {
      id: 2,
      value: "lucy",
      label: "Lucy",
    },
    {
      id: 3,
      value: "tom",
      label: "Tom",
    },
  ]);
  return (
    <Row>
      <Col span={12} className="px-3 mt-[32px]">
        <AntdSelect
          placeholder="Text Input"
          options={options}
          onChange={(e, opt) => {}}
        >
          {options.map((val, idx) => {
            return (
              <div value={val.value} data={val} label={val.label} key={idx}>
                {val.label}
              </div>
            );
          })}
        </AntdSelect>
      </Col>
      <Col span={12} className="px-3">
        <AntdSelect
          label="Label"
          placeholder="Text Input"
          icon={IconBrush}
          iconPosition="left"
          options={options}
          onChange={(e, opt) => {}}
          showSearch
        >
          {options.map((val, idx) => {
            return (
              <div value={val.value} data={val} label={val.label} key={idx}>
                {val.label}
              </div>
            );
          })}
        </AntdSelect>
      </Col>
      <Col span={12} className="px-3 mt-4">
        <AntdSelect
          label="Label"
          customClass="select-disabled"
          disabled
          placeholder="Text Input"
          iconPosition="left"
          options={options}
        />
      </Col>
      <Col span={12} className="px-3 mt-4">
        <AntdSelect
          label="Label"
          customClass="select-error"
          error="Error Message"
          placeholder="Text Input"
          iconPosition="left"
          options={options}
          onChange={(e, opt) => {}}
        >
          {options.map((val, idx) => {
            return (
              <div value={val.value} data={val} label={val.label} key={idx}>
                {val.label}
              </div>
            );
          })}
        </AntdSelect>
      </Col>
      <Col span={12} className="px-3 mt-4">
        <AntdSelect
          label="Label"
          customClass="select-success"
          success="Field validation is successfull"
          placeholder="Text Input"
          iconPosition="left"
          options={options}
          onChange={(e, opt) => {}}
        >
          {options.map((val, idx) => {
            return (
              <div value={val.value} data={val} label={val.label} key={idx}>
                {val.label}
              </div>
            );
          })}
        </AntdSelect>
      </Col>
    </Row>
  );
};

export default AntdSelectsDocs;
