// perlengkapan Actions
// --------------------------------------------------------

import {
  handleError,
  handleErrorBE,
  handleSuccess,
} from "stores/actions/errorGeneral";
import {
  getAllPerlengkapanService,
  savePerlengkapanService,
} from "utils/api/paket/perlengkapan";

/* eslint-disable space-before-function-paren */
export const SET_LOADING = "perlengkapan/SET_LOADING";
export const CLEAR_ERROR = "perlengkapan/CLEAR_ERROR";
export const SET_ERROR = "perlengkapan/SET_ERROR";
export const INIT_DATA = "perlengkapan/INIT_DATA";
export const SET_DOUBLE_SUBMIT = "perlengkapan/SET_DOUBLE_SUBMIT";
export const SET_DATA = "perlengkapan/SET_DATA";

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const setDoubleSubmit = (payload) => ({
  type: SET_DOUBLE_SUBMIT,
  payload,
});

export const setDataPerlengkapan = (payload) => ({
  type: SET_DATA,
  payload,
});

export const handleGetAllPerlengkapan = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await getAllPerlengkapanService(payload);
    dispatch(setLoading(false));

    if (response.status === 200) {
      let convert_to = [];
      if (response.data.data.length) {
        convert_to = await response.data.data.map((item) => {
          const data = {
            key: item.id_equipment,
            name: "John Brown",
            panggilan: "tuan",
            foto: item.logo,
            jenisKelamin: "laki laki",
            starting: "BPN - CGK",
            cabang: "Balikpapan",
            perlengkapan: "1",
          };
          return data;
        });
      }

      const saveData = {
        data: convert_to,
        page: response.data.page,
        total_data: response.data.total_data,
      };
      dispatch(setDataPerlengkapan(saveData));
    } else {
      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const saveDataPerlengkapan = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await savePerlengkapanService(payload);
    dispatch(setLoading(false));
    if (res.status === 200) {
      dispatch(handleSuccess(res.data));
      return res.status;
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};
