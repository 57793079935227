import React, { useState } from "react";

import Buttons from "components/buttons";
import Checkbox from "components/checkbox";
import Inputs from "components/form/inputs";
import Popup from "components/popup";

function Duplicate({ show, data, close }) {
  console.log("DATA", data);
  const [checked, setChecked] = useState({
    tipe: false,
    periode: false,
  });

  return (
    <Popup open={show} handleClose={close} title="Duplicate Coupon">
      <div className="border border-[#E0E0E0] rounded-2xl">
        <div className="p-4">
          <Inputs
            defaultValue={`Clone - ${data?.code_coupon}`}
            label="Code Coupon"
            placeholder="Clone - MSLMRMD"
          />
          <div className="flex flex-col gap-4 mt-4">
            <div className="flex items-center">
              <Checkbox
                checked={checked.tipe}
                onChange={(e) => {
                  setChecked({
                    tipe: e.target.checked,
                    periode: checked.periode,
                  });
                }}
              />
              <p className="ml-2">Tipe Coupon & Diskon</p>
            </div>
            <div className="flex items-center">
              <Checkbox
                checked={checked.periode}
                onChange={(e) => {
                  setChecked({
                    tipe: checked.tipe,
                    periode: e.target.checked,
                  });
                }}
              />
              <p className="ml-2">Periode</p>
            </div>
          </div>
        </div>
        <div className="divider"></div>
        <div className="flex flex-row justify-end p-4">
          <Buttons text="Tambah" customClass="btn-primary btn-large" />
        </div>
      </div>
    </Popup>
  );
}

export default React.memo(Duplicate);
