import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import MoslemeTable from "../../../../../../../../components/Table";
import Badge from "../../../../../../../../components/badge";
import Segment from "../../../../../../../../components/segment";

const columns = [
  {
    title: "ID Transaksi",
    dataIndex: "idtrans",
  },
  {
    title: "Tanggal & Waktu",
    dataIndex: "date",
  },
  {
    title: "Nominal Transaksi",
    dataIndex: "transnominal",
    render: () => (
      <div className="font-semibold text-[16px]">Rp 135.000.000</div>
    ),
  },
  {
    title: "Status Verifikasi",
    dataIndex: "statverif",
    render: () => (
      <Segment
        options={[
          {
            label: <div>Terverifikasi</div>,
            value: "Terverifikasi",
          },
          {
            label: (
              <div className="segment-label-error">Belum Terverifikasi</div>
            ),
            value: "Belum Terverifikasi",
          },
        ]}
      />
    ),
  },
  {
    title: "Status Transaksi",
    dataIndex: "stattrans",
    render: () => <Badge label="BATAL" />,
  },
  {
    title: "Tipe Transaksi",
    dataIndex: "transtype",
    render: () => <Badge label="REFUND" />,
  },
  {
    title: "",
    dataIndex: "actions",
    render: (actions) => actions,
    className: "actions",
  },
];

const data = [
  {
    key: "1",
    idtrans: "20007520076",
    date: "21/08/2022 14:07 WIB",
    transnominal: "Rp. 135.000.000",
    statverif: "Terverifikasi",
    stattrans: "BATAL",
    transtype: "REFUND",
  },
  {
    key: "2",
    idtrans: "20007520076",
    date: "21/08/2022 14:07 WIB",
    transnominal: "Rp. 135.000.000",
    statverif: "Terverifikasi",
    stattrans: "BATAL",
    transtype: "REFUND",
  },
  {
    key: "3",
    idtrans: "20007520076",
    date: "21/08/2022 14:07 WIB",
    transnominal: "Rp. 135.000.000",
    statverif: "Terverifikasi",
    stattrans: "BATAL",
    transtype: "REFUND",
  },
  {
    key: "4",
    idtrans: "20007520076",
    date: "21/08/2022 14:07 WIB",
    transnominal: "Rp. 135.000.000",
    statverif: "Terverifikasi",
    stattrans: "BATAL",
    transtype: "REFUND",
  },
  {
    key: "5",
    idtrans: "20007520076",
    date: "21/08/2022 14:07 WIB",
    transnominal: "Rp. 135.000.000",
    statverif: "Terverifikasi",
    stattrans: "BATAL",
    transtype: "REFUND",
  },
];

const TabPembayaran = () => {
  const router = useHistory();
  // Selection & type state
  const [selectionType, setSelectionType] = useState("checkbox");
  const [isExpand, setIsExpand] = useState(true);

  // Pagination state
  const [currentpage, setCurrentpage] = useState(1);
  const [size, setSize] = useState(10);
  const total = 100;

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: total,
    current: currentpage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20"],
  };

  // func to set size pagination
  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setSize(pageSize);
  };

  const expandable = {
    expandedRowRender: (record) => <p style={{ margin: 0 }}>{record.name}</p>,
    rowExpandable: (record) => record.name !== "Not Expandable",
  };

  // manipulated data with action
  const sourceData = data.map((v) => {
    // v.actions = <ButtonAction value={v} />;
    return v;
  });

  return (
    <>
      <MoslemeTable
        expandable={isExpand && expandable}
        columns={columns}
        data={sourceData}
        withSelection={true}
        selectionType={selectionType}
        pagination={pagination}
        customPaginationClass="pr-4"
        onPageChange={(page) => setCurrentpage(page)}
        onSizeChange={(currentpage, pageSize) =>
          onSizeChange(currentpage, pageSize)
        }
      />
    </>
  );
};

export default TabPembayaran;
