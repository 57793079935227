import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppsMitraAction from "stores/actions/apps/mitra";

import Popup from "components/popup";
import AlertPopup from "components/popup/alert";
import Buttons from "components/buttons";
import MoslemeTable from "components/Table";
import AppsAddMitra from "./components/AddMitra";

import { Edit, Eye, Trash } from "iconsax-react";

const AppsMitra = () => {
  const dispatch = useDispatch();
  const store = useSelector(({ mitra }) => mitra);
  const [listMitra, setListMitra] = useState([]);
  const [openPopup, setOpenPopup] = useState({
    show: false,
    edit: null,
  });
  
  const [mitraIdDelete, setMitraIdDelete] = useState();
  const [openDeleteMitra, setOpenDeleteMitra] = useState(false);
  const [loading, setLoading] = useState(false);

  // Selection & type state
  const [selectionType, setSelectionType] = useState("checkbox");
  const [withSelection, setWithSelection] = useState(true);

  const columns = [
    {
      title: "Logo",
      dataIndex: "logo",
      className: "w-[80px]",
      render: (img) => <img src={img} alt="img" />,
    },
    {
      title: "Nama",
      dataIndex: "name",
      render: ({ id, name, data }) => (
        <>
          <div>{name}</div>
          <div className="action">
            <Edit
              className="cursor-pointer"
              onClick={() => {
                setOpenPopup({
                  show: true,
                  edit: data,
                });
              }}
              size="15"
              color="#141414"
            />
            <Trash
              className="cursor-pointer"
              onClick={() => {
                setOpenDeleteMitra(true);
                setMitraIdDelete(id);
              }}
              size="15"
              color="#141414"
            />
          </div>
        </>
      ),
    },
  ];

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: store.total,
    current: store.page_number,
    pageSize: store.per_page,
    defaultPageSize: store.per_page,
    pageSizeOptions: ["10", "20"],
  };

  useEffect(() => {
    setListMitra(store.data);
  }, [store.data, store.data.length]);

  useEffect(() => {
    dispatch(AppsMitraAction.fetchData());
  }, [dispatch, store.keyword, store.per_page, store.page_number]);

  const confirmDeleteMitra = async () => {
    setLoading(true);
    const status = await dispatch(AppsMitraAction.deleteData(mitraIdDelete));

    if (status === 200) {
      setListMitra(store.data);
      setOpenDeleteMitra(false);
      setLoading(false);
    }
  };

  return (
    <>
      <Popup
        open={openPopup.show}
        handleClose={() =>
          setOpenPopup({
            show: false,
            edit: null,
          })
        }
        title={`${openPopup.edit ? "Edit Pembimbing" : "Tambah Pembimbing"}`}
        width="982px"
        children={
          <AppsAddMitra setOpenPopup={setOpenPopup} edit={openPopup.edit} />
        }
      />
      <AlertPopup
        open={openDeleteMitra}
        subtitle="Apakah anda yakin untuk menghapus mitra?"
        handleClose={() => setOpenDeleteMitra(false)}
        handleContinue={confirmDeleteMitra}
        loading={loading}
      />
      <div className="bg-white soft-shadow rounded-[20px]">
        <div className="flex flex-row justify-between p-4">
          <div className="text-[20px] font-semibold">Data Mitra</div>
          <div className="flex flex-row gap-4">
            <Buttons
              text="Tambah"
              customClass="btn-primary btn-small"
              onClick={() =>
                setOpenPopup({
                  show: true,
                  edit: null,
                })
              }
            />
          </div>
        </div>
        <div className="divider"></div>
        <div className="p-4">
          <MoslemeTable
            customClass="mt-4"
            loading={store.status === "process"}
            columns={columns}
            data={listMitra.map((item, i) => {
              return {
                ...item,
                key: `${i + 1}`,
                id: item.id_mitra,
                logo: item.logo_url,
                name: {
                  id: item.id_mitra,
                  name: item.name,
                  data: item,
                },
              };
            })}
            withSelection={withSelection}
            selectionType={selectionType}
            pagination={pagination}
            onPageChange={(page) => dispatch(AppsMitraAction.setPage(page))}
            onSizeChange={(currentpage, pageSize) => {
              dispatch(AppsMitraAction.setPage(currentpage));
              dispatch(AppsMitraAction.setSize(pageSize));
            }}
          />
        </div>
      </div>
    </>
  );
};

export default React.memo(AppsMitra);
