import { actionLayout } from "stores/actions/layout";


const initialState = {
  isVisible: true,
  isAllowed: true
};

const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionLayout.SET_VISIBILITY_TRUE:
      return {
        ...state,
        isVisible: true,
      };

    case actionLayout.SET_VISIBILITY_FALSE:
      return {
        ...state,
        isVisible: false,
      };
    
    case actionLayout.SET_ALLOWED_TRUE:
      return {
        ...state,
        isAllowed: true,
      };
    
    case actionLayout.SET_ALLOWED_FALSE:
      return {
        ...state,
        isAllowed: false,
      };

    default:
      return state;
  }
};

export default layoutReducer;
