import Inputs from "components/form/inputs";
import Popup from "components/popup";
import React from "react";

const PopUpPassport = ({ show, setModal }) => {
  const close = (e) => {
    setModal(false);
  };

  return (
    <Popup width="1184px" open={show} handleClose={close} title="Passport">
      <div className="w-full grid grid-cols-2 gap-4">
        <div>
          <Inputs
            name="name"
            // onChange={validation.handleChange}
            // onBlur={validation.handleBlur}
            // value={validation.values.name}
            label="Nama Lengkap"
            placeholder="Masukan"
            // error={validation.touched.name && validation.errors.name && validation.errors.name}
          />
        </div>
      </div>
    </Popup>
  );
};

export default PopUpPassport;
