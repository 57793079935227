import React, { useState, useCallback } from "react";
import Inputs from "components/form/inputs";
import IconSearch from "assets/icons/search-normal.svg";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import Buttons from "components/buttons";
import Activity from "assets/icons/activity.svg";
import SendSquare from "assets/icons/send-square.svg";
import Filter from "assets/icons/filter.svg";
import Checkbox from "components/checkbox/index";
import Dropdowns from "components/dropdowns";
import AddPaketPopup from "../AddPaketPopup";
import "./Header.scss";
import { useDispatch } from "react-redux";
import { resetDataUpload } from "stores/actions/uploadFile";

const useStyle = makeStyles({
  datepickerContainer: {
    "& .ant-picker-input": {
      width: "154px !important",
      "& .ant-picker-suffix": {
        width: "154px !important",
      },
    },
  },
});

const Header = ({
  onSearch,
  onStatus,
  onStatusKeberangkatan,
  status,
  status_keberangkatan,
  handleClickTerapkan,
  isLoading,
}) => {
  const classes = useStyle();
  const router = useHistory();

  const [indeterminate, setIndeterminate] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const dispatch = useDispatch();

  const handleClosePopup = useCallback(() => {
    setOpenPopup(false);
  }, []);

  return (
    <>
      <div className="flex flex-row justify-between">
        <div>
          <Inputs
            id="test"
            placeholder="Search"
            customClass="!w-[240px]"
            iconPosition="left"
            icon={IconSearch}
            onChange={onSearch}
          />
        </div>
        <div className="flex flex-row justify-end items-center gap-[16px]">
          <div className="flex flex-row items-center justify-center my-1 py-2 px-3 gap-2 border rounded-lg cursor-pointer">
            <img src={Filter} alt="filter" className="w-4 h-4" />
            <div className="text-[#141414] text-[14px] font-sans font-normal">
              Filter
            </div>
            <Dropdowns
              label="Filter"
              placement="bottomRight"
              customClass="border-0 "
              dropdownArrow={true}
            >
              <div className="dropdown-content !mt-[18px] !w-[202px]">
                <div className="checkbox-container">
                  <p className="mb-[16px] font-semibold text-[14px] text-[#141414]">
                    Keberangkatan
                  </p>
                  <Checkbox
                    containerClass="mb-[16px]"
                    checked={status_keberangkatan.sudah_berangkat}
                    onChange={(e) => {
                      onStatusKeberangkatan(
                        !status_keberangkatan.sudah_berangkat,
                        "sudah_berangkat"
                      );
                    }}
                  >
                    Sudah Berangkat
                  </Checkbox>
                  <Checkbox
                    containerClass="mb-[16px]"
                    checked={status_keberangkatan.sedang_berangkat}
                    onChange={(e) => {
                      onStatusKeberangkatan(
                        !status_keberangkatan.sedang_berangkat,
                        "sedang_berangkat"
                      );
                    }}
                  >
                    Sedang Berangkat
                  </Checkbox>
                  <Checkbox
                    checked={status_keberangkatan.belum_berangkat}
                    onChange={(e) => {
                      onStatusKeberangkatan(
                        !status_keberangkatan.belum_berangkat,
                        "belum_berangkat"
                      );
                    }}
                  >
                    Belum Berangkat
                  </Checkbox>
                </div>
                <div className="checkbox-container">
                  <p className="my-[16px] font-semibold text-[14px] text-[#141414]">
                    Status
                  </p>
                  <Checkbox
                    containerClass="mb-[16px]"
                    checked={status.draft}
                    onChange={(e) => {
                      onStatus(!status.draft, "draft");
                    }}
                  >
                    Draft
                  </Checkbox>
                  <Checkbox
                    indeterminate={indeterminate}
                    checked={status.publish}
                    onChange={(e) => {
                      onStatus(!status.publish, "publish");
                    }}
                  >
                    Public
                  </Checkbox>
                </div>
                <div className="flex flex-row justify-center items-center w-full">
                  <Buttons
                    text="Terapkan"
                    customClass="btn-primary btn-small !w-[154px] h-[30px] !mt-[22px]"
                    onClick={handleClickTerapkan}
                    loading={isLoading}
                  />
                </div>
              </div>
            </Dropdowns>
          </div>
          <div>
            <Buttons
              text="Tambah Paket"
              customClass="btn-primary btn-sm !w-[106px] !h-[32px] mr-[16px] ml-[16px]"
              onClick={() => {
                setOpenPopup(true);

                dispatch(resetDataUpload());
              }}
            />

            <AddPaketPopup
              open={openPopup}
              handleClose={handleClosePopup}
            ></AddPaketPopup>
          </div>
          <div className="flex flex-row gap-[8xp]">
            <img
              src={Activity}
              alt="activity"
              style={{ marginRight: "16px" }}
            />
            <img
              src={SendSquare}
              alt="activity"
              style={{ marginRight: "16px" }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
