/* eslint-disable import/no-anonymous-default-export */
// datamaster Reducers
// --------------------------------------------------------

import {
  SET_LOADING,
  CLEAR_ERROR,
  SET_ERROR,
  INIT_DATA,
  SET_DOUBLE_SUBMIT,
  SET_DATAMASKAPAI,
  SET_DATABANDARA,
  SET_DATATRANSPORT,
  SET_DATAAKOMODASI,
  SET_EDITDATA,
  SET_DETAILDATA,
  SET_MASTER_ID,
  SET_MODAL,
  SET_BUTTON,
  SET_KEYDATA,
  SET_GETREGION,
  SET_NUMBERDATAMASTER,
  SET_DATAPERLENGKAPAN,
  SET_DATAPEMBIMBING,
  SET_STATUSDATAMASTER,
} from "../../actions/datamaster";

const initialState = {
  isLoading: false,
  isModal: false,
  isButton: "",
  isDoubleSubmit: false,
  isSubmitting: false,
  error: {},
  // jumlalh data master
  allDataMaster: {},

  // maskapai
  dataMaskapai: {},
  detailData: {},

  dataBandara: {},
  dataTransport: {},
  dataAkomodasi: {},
  dataPerlengkapan: {},
  dataPembimbing: {},

  masterId: {},

  dataRegion: {},

  editData: "",
  keyData: "maskapai",
  status: "idle",
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_MODAL:
      return {
        ...state,
        isModal: payload,
      };
    case SET_BUTTON:
      return {
        ...state,
        isButton: payload,
      };
    case SET_DOUBLE_SUBMIT:
      return {
        ...state,
        isDoubleSubmit: payload,
      };
    case SET_ERROR:
      return {
        ...state,
        error: payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: {},
      };
    case INIT_DATA:
      return {
        ...initialState,
      };
    case SET_EDITDATA:
      return {
        ...state,
        editData: payload,
      };
    case SET_KEYDATA:
      return {
        ...state,
        keyData: payload,
      };
    case SET_STATUSDATAMASTER:
      return {
        ...state,
        status: payload,
      };
    case SET_NUMBERDATAMASTER:
      return {
        ...state,
        allDataMaster: payload,
      };
    case SET_DATAMASKAPAI:
      return {
        ...state,
        dataMaskapai: payload,
      };
    case SET_DETAILDATA:
      return {
        ...state,
        detailData: payload,
      };
    case SET_DATABANDARA:
      return {
        ...state,
        dataBandara: payload,
      };
    case SET_DATATRANSPORT:
      return {
        ...state,
        dataTransport: payload,
      };
    case SET_DATAAKOMODASI:
      return {
        ...state,
        dataAkomodasi: payload,
      };
    case SET_MASTER_ID:
      return {
        ...state,
        masterId: payload,
      };
    case SET_GETREGION:
      return {
        ...state,
        dataRegion: payload,
      };
    case SET_DATAPERLENGKAPAN:
      return {
        ...state,
        dataPerlengkapan: payload,
      };
    case SET_DATAPEMBIMBING:
      return {
        ...state,
        dataPembimbing: payload,
      };
    default:
      return state;
  }
};
