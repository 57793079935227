import React, { useCallback, useState } from "react";
import { Grid } from "@material-ui/core";

import CalculatorStyle from "./CalculatorStyle";
import SidebarChild from "../../components/template_layout/Siderbar/SidebarChild";
import { calculatorPacketMenuList } from "../../configuration/calculatorPacketMenuList";

import RequestPaket from "./RequestPaket";
import UpdateHarga from "./UpdateHarga";

const PaketPage = () => {
  const [showContent, setShowContent] = useState("request_paket");

  const getContent = useCallback(() => {
    switch (showContent) {
      case "request_paket":
        return <RequestPaket />;
      default:
        return <UpdateHarga />;
    }
  }, [showContent]);

  return (
    <CalculatorStyle>
      <SidebarChild
        menuList={calculatorPacketMenuList}
        handleClick={setShowContent}
        showContent={showContent}
      />
      <div style={{ marginLeft: '174px', paddingLeft: '16px' }}>
        {getContent()}
      </div>
    </CalculatorStyle>
  );
};

export default PaketPage;
