// admin
export const START_FETCHING_ADDITIONAL =
  "paket/additional/START_FETCHING_ADDITIONAL";
export const ERROR_FETCHING_ADDITIONAL =
  "paket/additional/ERROR_FETCHING_ADDITIONAL";
export const SUCCESS_FETCHING_ADDITIONAL =
  "paket/additional/SUCCESS_FETCHING_ADDITIONAL";
export const SET_PAGE_ADDITIONAL = "paket/additional/SET_PAGE";
export const SET_SIZE_ADDITIONAL = "paket/additional/SET_SIZE";
export const SET_TOTAL_ADDITIONAL = "paket/additional/SET_TOTAL_ADDITIONAL";
export const SET_KEYWORD_ADDITIONAL = "paket/additional/SET_KEYWORD_ADDITIONAL";
export const SET_ID_ROLE = "paket/additional/SET_ID_ROLE";
