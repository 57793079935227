import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import IconExit from "../../assets/icons/icon_exit.svg";
import IconArrowLeft from "../../assets/icons/arrow-left.svg";
import PropTypes from "prop-types";

const HeaderCardRender = ({ classes, type, title, handleClose, children }) => {
  switch (type) {
    case "primary":
      return (
        <div className={classes.paper}>
          <div className="card_title">
            <div className="title">{title}</div>
            <div className="icon" onClick={handleClose}>
              <img src={IconExit} height={24} width={24} alt="icon_exit" />
            </div>
          </div>

          <div className="content">{children}</div>
        </div>
      );

    case "secondary":
      return (
        <div className={classes.paper}>
          <div className="card_title flex gap-2 align-middle">
            <div className="icon" onClick={handleClose}>
              <img src={IconArrowLeft} height={24} width={24} alt="icon_exit" />
            </div>
            <div className="title">{title}</div>
          </div>

          <div className="content">{children}</div>
        </div>
      );

    default:
      return (
        <div className={classes.paper}>

          <div className="card_title">
            <div className="title">{title}</div>
            <div className="icon" onClick={handleClose}>
              <img src={IconExit} height={24} width={24} alt="icon_exit" />
            </div>
          </div>

          <div className="content">{children}</div>


        </div>
      );
  }
};

const Popup = ({
  children,
  handleClose,
  open,
  width,
  height,
  title,
  type,
  containerClass,
}) => {
  const useStyles = makeStyles(() => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100%",
      padding: "24px",
    },
    paper: {
      width: width,
      maxHeight: "100%",
      borderRadius: "16px",
      background: "#fff",
      position: "relative",
      padding: "16px",
      // overflow: "hidden",
      "& .card_title": {
        // height: "56px",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        padding: "16px",
        justifyContent: type === "primary" ? "space-between" : "flex-start",
        borderRadius: "16px",
        position: "sticky",
        top: "0",
        left: "16px",
        right: "16px",
        zIndex: "1",
        boxShadow:
          "0px 10px 16px -6px rgba(0, 0, 0, 0.04), 0px 4px 80px -4px rgba(0, 0, 0, 0.04)",
        "& .title": {
          fontFamily: "GeneralSansSemibold",
          fontSize: "18px",
          fontWeight: "600",
          color:
            "linear-gradient(34.99deg, #151515 17.46%, #3D3D3D 58.62%, #595959 87.32%)",
        },
        "& .icon": {
          width: "24px",
          height: "24px",
          cursor: "pointer",
        },
      },

      "& .content": {
        // marginTop: "16px",
        maxHeight: "calc(100vh - 156px)",
        width: "100%",
        overflowY: "auto",
        overflowX: "hidden",
        paddingTop: "9px",
        paddingBottom: "9px",
        "&::-webkit-scrollbar": {
          width: "4px",
          // height: scrollHorizontal ? "5px" : "100px",
        },
        "&::-webkit-scrollbar-track": {
          width: "4px",
          borderRight: "10px solid #fff",
          borderLeft: "10px solid #fff",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#E0E0E0",
          height: "30px",
          width: "4px",
          borderRadius: "16px",
        },
        "&::-webkit-scrollbar-track-piece:start": {
          background: "#fff",
        },
        "&::-webkit-scrollbar-track-piece:end": {
          background: "#fff",
        },
      },
    },
  }));
  const classes = useStyles();

  return (
    <>
      <Modal
        className={`${classes.modal} ${containerClass}`}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        container={() => document.getElementById("area-modal")}
      >
        <Fade in={open}>
          {
            <HeaderCardRender
              classes={classes}
              type={type}
              title={title}
              children={children}
              handleClose={handleClose}
            />
          }
        </Fade>
      </Modal>
    </>
  );
};

Popup.propTypes = {
  type: PropTypes.oneOfType(["primary", "secondary"]),
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  containerClass: PropTypes.string,
};

Popup.defaultProps = {
  type: "primary",
  open: false,
  handleClose: () => {},
  width: "672px",
  height: "476px",
  title: "Duplicate Coupon",
  containerClass: "",
};

export default React.memo(Popup);
