import axios from "axios";
import { BASE_URL_ENDPOINT, BASE_URL_ENDPOINT_V2 } from "constant/constanta";

const PaketFeeAPI = {
  getList: (payload) =>
    axios.post(`${BASE_URL_ENDPOINT}/admin/paket/get-costum-fee`, payload),
  updateFee: (payload) =>
    axios.post(`${BASE_URL_ENDPOINT}/admin/paket/update-costum-fee`, payload),
  updateCustomFee: (payload) =>
    axios.post(
      `${BASE_URL_ENDPOINT}/admin/paket/update-status-costum-fee`,
      payload
    ),
  getListFeeCabang: (payload) =>
    axios.post(`${BASE_URL_ENDPOINT}/admin/paket/get-fee-cabang`, payload),
};

export { PaketFeeAPI };

export const PaketFeeBesic = {
  getBesic: (id) =>
    axios.get(`${BASE_URL_ENDPOINT_V2}/admin/package/${id}/fees/basic-fees`),
  updateBesic: (id, payload) =>
    axios.put(
      `${BASE_URL_ENDPOINT_V2}/admin/package/${id}/fees/update-basic-fee`,
      payload
    ),
};

export const PaketFeeListCabangAndBranch = {
  getListAgent: (id, payload) =>
    axios.post(
      `${BASE_URL_ENDPOINT_V2}/admin/package/${id}/fees/get-list-agent-before-add`,
      payload
    ),
  getListCabang: (id, payload) =>
    axios.post(
      `${BASE_URL_ENDPOINT_V2}/admin/package/${id}/fees/get-list-branch-before-add`,
      payload
    ),
};

export const PaketFeeAddUpdatedDel = {
  addCustomeAgent: (id, payload) =>
    axios.post(
      `${BASE_URL_ENDPOINT_V2}/admin/package/${id}/fees/add-custom-fee-agent`,
      payload
    ),
  addCustomeCabang: (id, payload) =>
    axios.post(
      `${BASE_URL_ENDPOINT_V2}/admin/package/${id}/fees/add-custom-fee-branch`,
      payload
    ),
  updatedCustomeAgent: (id, payload) =>
    axios.put(
      `${BASE_URL_ENDPOINT_V2}/admin/package/${id}/fees/update-custom-fee-agent`,
      payload
    ),
  updatedCustomeCabang: (id, payload) =>
    axios.put(
      `${BASE_URL_ENDPOINT_V2}/admin/package/${id}/fees/update-custom-fee-branch`,
      payload
    ),
};

export const PaketFeeCustomeList = {
  getCabang: (id, payload) =>
    axios.post(
      `${BASE_URL_ENDPOINT_V2}/admin/package/${id}/fees/custom-fee-branches`,
      payload
    ),
  getAgent: (id, payload) =>
    axios.post(
      `${BASE_URL_ENDPOINT_V2}/admin/package/${id}/fees/custom-fee-agents`,
      payload
    ),
};
