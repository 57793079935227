import {
  editPenerbanganDomesticService,
  getAllPenerbanganDomesticService,
  getAllPenerbanganInternationalService,
  getAllPenerbanganService,
  storePenerbanganInternationService,
  updatedMaskapaiPenerbanganService,
} from "../../../../utils/api/paket/penerbangan";
import { handleError, handleErrorBE, handleSuccess } from "../../errorGeneral";

/* eslint-disable space-before-function-paren */
export const SET_LOADING = "penerbangan/SET_LOADING";
export const CLEAR_ERROR = "penerbangan/CLEAR_ERROR";
export const SET_ERROR = "penerbangan/SET_ERROR";
export const INIT_DATA = "penerbangan/INIT_DATA";
export const SET_ALL_FLIGHT = "penerbangan/SET_ALL_FLIGHT";
export const SET_ALL_FLIGHT_INTERNATIONAL =
  "penerbangan/SET_ALL_FLIGHT_INTERNATIONAL";

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const clearError = () => ({
  type: CLEAR_ERROR,
});

export const setError = (payload) => ({
  type: SET_ERROR,
  payload,
});

export const setInitData = () => ({
  type: INIT_DATA,
});

export const setAllFlight = (payload) => ({
  type: SET_ALL_FLIGHT,
  payload,
});

export const setAllFlightInternational = (payload) => ({
  type: SET_ALL_FLIGHT_INTERNATIONAL,
  payload,
});

export const handleGetAllPenerbangan = (id) => async (dispatch) => {
  try {
    dispatch(clearError());
    dispatch(setLoading(true));
    const response = await getAllPenerbanganDomesticService(id);
    dispatch(setLoading(false));
    const { status, data } = response;

    if (status === 200) {
      dispatch(setAllFlight(data.data));
    } else {
      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const handleGetAllPenerbanganInternational =
  (id) => async (dispatch) => {
    try {
      dispatch(clearError());
      dispatch(setLoading(true));
      const response = await getAllPenerbanganInternationalService(id);
      dispatch(setLoading(false));
      const { status, data } = response;

      if (status === 200) {
        dispatch(setAllFlightInternational(data));
      } else {
        dispatch(handleErrorBE(response));
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const updatedMaskapaiPenerbangan =
  (id, payload, actionSuccess, actionError) => async (dispatch) => {
    try {
      dispatch(clearError());
      dispatch(setLoading(true));
      const response = await updatedMaskapaiPenerbanganService(id, payload);
      dispatch(setLoading(false));
      const { status, data } = response;

      if (status === 200) {
        actionSuccess(response);
      } else {
        actionError(response);
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const editPenerbanganDomesticPaket =
  (id, payload, actionSuccess, actionError) => async (dispatch) => {
    try {
      dispatch(clearError());
      dispatch(setLoading(true));
      const response = await editPenerbanganDomesticService(id, payload);
      dispatch(setLoading(false));
      const { status, data } = response;

      if (status === 200) {
        actionSuccess(response);
      } else {
        actionError(response);
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const storePenerbanganInternationalPaket =
  (id, payload, actionSuccess = (message) => {}, actionError = (error) => {}) =>
  async (dispatch) => {
    try {
      dispatch(clearError());
      dispatch(setLoading(true));
      const response = await storePenerbanganInternationService(id, payload);
      dispatch(setLoading(false));
      const { status, data } = response;

      if (status === 200) {
        actionSuccess(response);
      } else {
        actionError(response);
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
