export const SET_LOADING = "apps/staff/SET_LOADING";
export const CLEAR_ERROR = "apps/staff/CLEAR_ERROR";
export const SET_ERROR = "apps/staff/SET_ERROR";
export const START_FETCHING_STAFF = "apps/staff/START_FETCHING_STAFF";
export const ERROR_FETCHING_STAFF = "apps/staff/ERROR_FETCHING_STAFF";
export const SUCCESS_FETCHING_STAFF = "apps/staff/SUCCESS_FETCHING_STAFF";
export const SET_PAGE_STAFF = "apps/staff/SET_PAGE_STAFF";
export const SET_SIZE_STAFF = "apps/staff/SET_SIZE_STAFF";
export const SET_TOTAL_STAFF = "apps/staff/SET_TOTAL_STAFF";
export const SET_KEYWORD_STAFF = "apps/staff/SET_KEYWORD_STAFF";
export const SET_ADD_OR_EDIT_STAFF = "apps/staff/SET_ADD_OR_EDIT_STAFF";
export const SET_DELETE_STAFF = "apps/staff/SET_DELETE_STAFF";
