import Inputs from "components/form/inputs";
import Selects from "components/form/selects";
import Popup from "components/popup";
import { useFormik } from "formik";
import IconExit from "../../../../../../assets/icons/icon_exit.svg";
import { DocumentUpload } from "iconsax-react";
import React from "react";
import { Close } from "@mui/icons-material";
import Buttons from "components/buttons";

const PopUpKtp = ({ show, setModal }) => {
  const validation = useFormik({
    initialValues: {
      nik: "",
      nama_ktp: "",
      jenis_kelamin: "",
    },
    onSubmit: (val) => {
      // dispatch(MemberAction.createData(val, callbackPost));
    },
  });

  const close = (e) => {
    validation.handleReset();
    setModal(false);
  };

  return (
    <Popup
      width="824px"
      containerClass="!z-[1000]"
      open={show}
      handleClose={close}
      title="KTP"
    >
      <div className="h-[48px] w-full bg-[#ffdcdc] rounded-[16px] mb-[16px] flex justify-between px-[16px] py-[12px]">
        <h4 className="text-[#141414] font-[600] text-[16px]">
          Dokumen Scan KTP
        </h4>
        <div className="flex gap-4 ">
          <div className="bg-white px-[12px] py-[6px] flex gap-[9px] rounded-[8px] items-center cursor-pointer">
            <DocumentUpload size="15" color="#141414" />
            <h4 className="text-[14px] font-[600]">Upload</h4>
          </div>
          <div className="rounded-full bg-[#E82320] bg-opacity-[0.4] w-[24px] h-[24px] flex items-center justify-center">
            <Close sx={{ color: "#E82320" }} fontSize="14" />
          </div>
        </div>
      </div>
      <div className="w-full border rounded-[16px]">
        <div className="p-[16px]">
          <Inputs
            name="nik"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.nik}
            label="NIK"
            placeholder="Masukan Nomor NIK"
            error={
              validation.touched.nik &&
              validation.errors.nik &&
              validation.errors.nik
            }
          />
          <Inputs
            name="nama_ktp"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.nama_ktp}
            containerClass="mt-[16px]"
            label="Nama Sesuai KTP"
            placeholder="Masukan Nama"
            error={
              validation.touched.nama_ktp &&
              validation.errors.nama_ktp &&
              validation.errors.nama_ktp
            }
          />
          <Selects
            label="Jenis Kelamin"
            containerClass="mt-[16px]"
            placeholder="Pilih Jenis Kelamin"
            onChange={(e) =>
              validation.setFieldValue("jenis_kelamin", e[0].value)
            }
            options={[
              {
                value: "pria",
                label: "Pria",
              },
              {
                value: "wanita",
                label: "Wanita",
              },
            ]}
            error={
              validation.touched.jenis_kelamin &&
              validation.errors.jenis_kelamin &&
              validation.errors.jenis_kelamin
            }
          />
        </div>
        <div className="w-full flex justify-end border-t p-[16px]">
          <Buttons
            // onClick={() => validation.handleSubmit()}
            // disabled={validation.values.foto === ""}
            text="Simpan"
            style={{ width: 150 }}
            customClass="btn-primary btn-medium mt-3"
          />
        </div>
      </div>
    </Popup>
  );
};

export default PopUpKtp;
