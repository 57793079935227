export const SET_LOADING = "booking/umroh/SET_LOADING";
export const CLEAR_ERROR = "booking/umroh/CLEAR_ERROR";
export const SET_ERROR = "booking/umroh/SET_ERROR";
export const START_FETCHING_BOOKING_UMROH = "booking/umroh/START_FETCHING_BOOKING_UMROH";
export const ERROR_FETCHING_BOOKING_UMROH = "booking/umroh/ERROR_FETCHING_BOOKING_UMROH";
export const SUCCESS_FETCHING_BOOKING_UMROH = "booking/umroh/SUCCESS_FETCHING_BOOKING_UMROH";
export const SET_PAGE_BOOKING_UMROH = "booking/umroh/SET_PAGE_BOOKING_UMROH";
export const SET_SIZE_BOOKING_UMROH = "booking/umroh/SET_SIZE_BOOKING_UMROH";
export const SET_TOTAL_BOOKING_UMROH = "booking/umroh/SET_TOTAL_BOOKING_UMROH";
export const SET_KEYWORD_BOOKING_UMROH = "booking/umroh/SET_KEYWORD_BOOKING_UMROH";
export const SET_ADD_OR_EDIT_BOOKING_UMROH = "booking/umroh/SET_ADD_OR_EDIT_BOOKING_UMROH";
export const SET_DELETE_BOOKING_UMROH = "booking/umroh/SET_DELETE_BOOKING_UMROH";
export const START_ADD_BOOKING_UMROH = "booking/umroh/START_ADD_BOOKING_UMROH";
