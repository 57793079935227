import { useLayoutEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { saveDataPaket } from "../../../../../../stores/actions/paket/infoDasar";

import Popup from "../../../../../../components/popup";
import Steppers from "../../../../../../components/steps/Steppers";
import Buttons from "../../../../../../components/buttons";
import Step1 from "./Step1";
import Step2 from "./Step2";
import { getAllPaket } from "stores/actions/paket/landing";
import Step3 from "./Step3";
import Step4 from "./Step4";
import Step5 from "./Step5";
import { BASE_URL_ENDPOINT } from "constant/constanta";
import axios from "axios";
import { getLocalStorage } from "utils/helpers";
import PopupConfirm from "components/popup/error-handling";

const useStyle = makeStyles({
  custom_stepper: {
    paddingTop: "7px",
    width: "120px",
    "& .ant-steps-item": {
      height: "30px !important",
      "&:last-child": {
        height: "20px !important",
      },
      "& .ant-steps-item-icon": {
        marginInlineEnd: "0 !important",
      },
      "& .ant-steps-item-title": {
        lineHeight: "20px !important",
        paddingInlineEnd: "0 !important",
        textAlign: "center",
      },
      "& .ant-steps-item-tail": {
        left: "49% !important",
      },
    },
  },
  modal_body: {
    display: "flex",
    gap: "20px",
    "& .scroll_area": {
      height: "422px",
      overflow: "auto",
      padding: "16px",
      width: "100%",
    },
    "& .action_area": {
      borderTop: "1px solid #e5e7eb",
      display: "flex",
      padding: "16px",
    },
    "& .total": {
      color: "#71BE0E",
    },
  },
});

const AddPaketPopup = ({ open, handleClose }) => {
  const classes = useStyle();

  const dispatch = useDispatch();
  // const { isLoading } = useSelector((state) => state.infoDasar);

  const [currentStep, setCurrentStep] = useState(0);
  
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCheck, setIsLoadingCheck] = useState(false)

  const [isVisible, setIsVisible] = useState(false);
  const [openPopupSuccess, setOpenPopupSuccess] = useState({
    show: false,
    subtitle: "",
  });
  const [openPopupError, setOpenPopupError] = useState({
    show: false,
    subtitle: "",
  });

  const stepItems = [
    {
      title: "Jumlah Hari",
    },
    {
      title: "Maskapai",
    },
    {
      title: "Hotel",
    },
    {
      title: "Tour",
    },
    {
      title: "Fee Cabang",
    },
  ];

  const [payloadJumlahHari, setPayloadJumlahHari] = useState({
    nama_paket: "",
    kategori_paket: "",
    jumlah_hari: "",
    bulan: "",
    tahun: "",
  });
  const [payloadMaskapai, setPayloadMaskapai] = useState({
    maskapai: "",
  });
  const [payloadHotel, setPayloadHotel] = useState({
    madinah: "",
    makkah: "",
    jumlah_malam_madinah: 3,
    jumlah_malam_makkah: 3,
  });
  const [payloadTour, setPayloadTour] = useState({
    cityTour: "",
    negaraTour: "",
    negaraTourName: "",
    hotel: "",
    jumlah_malam: 3,
  });
  const [payloadFeeCabang, setPayloadFeeCabang] = useState({
    branch_fee: "",
    total_harga: "",
  });

  const isAbleToNextStep1 =
    payloadJumlahHari.nama_paket &&
    payloadJumlahHari.kategori_paket &&
    payloadJumlahHari.jumlah_hari &&
    payloadJumlahHari.bulan &&
    payloadJumlahHari.tahun

  const isAbleToNextStep3 = payloadHotel.madinah && payloadHotel.makkah;

  const isAbleToNextStep4 = payloadTour.negaraTour ? payloadTour.negaraTour && payloadTour.hotel : true

  function numberWithDot(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  const onHandleRequestPaket = async (handleClose) => {
    const url = `${BASE_URL_ENDPOINT}/admin/request-paket/save`; // Replace with your API endpoint
    const data = {
      package_type: payloadJumlahHari.kategori_paket,
      package_title: payloadJumlahHari.nama_paket,
      number_of_days: payloadJumlahHari.jumlah_hari,
      month: payloadJumlahHari.bulan,
      year: payloadJumlahHari.tahun,
      maskapai_id: payloadMaskapai.maskapai,
      akomodasi_makkah_id: payloadHotel.makkah,
      akomodasi_makkah_number_of_days: payloadHotel.jumlah_malam_makkah,
      akomodasi_madinah_id: payloadHotel.madinah,
      akomodasi_madinah_number_of_days: payloadHotel.jumlah_malam_madinah,
      paket_kalkulator_tour_id: payloadTour.negaraTour,
      tour_akomodasi_id: payloadTour.hotel,
      tour_akomodasi_number_of_days: payloadTour.jumlah_malam,
      paket_kalkulator_city_tour_id: payloadTour.cityTour,
      fee_cabang: payloadFeeCabang.branch_fee,
    };
    const accessToken = getLocalStorage("accessToken");

    const headers = {
      "Content-Type": "application/json", // Adjust content type as needed
      Authorization: `Bearer ${accessToken}`, // Add any authorization headers you need
    };

    setIsLoading({
      requestPaket: true,
    });

    try {
      const response = await axios.post(url, data, { headers });
      setIsLoading({
        requestPaket: false,
      });
      if (response.data.code === 200) {
        setOpenPopupSuccess({
          show: true,
          subtitle: response.data.message,
        })
        // handleFetch()
        setIsVisible(false);
        handleClosePopup()
      } else {
        setOpenPopupError({
          show: true,
          subtitle: response.data.message,
        });
      }
    } catch (error) {
      setIsLoading(false);
      setOpenPopupError({
        show: true,
        subtitle: error.data.message,
      });
    }
  };

  const handleCheckHargaPaket = async () => {
    const accessToken = getLocalStorage("accessToken");
    const url = `${BASE_URL_ENDPOINT}/admin/request-paket/cek-harga-paket`; // Replace with your API endpoint
    const data = {
      number_of_days: payloadJumlahHari.jumlah_hari,
      month: payloadJumlahHari.bulan,
      year: payloadJumlahHari.tahun,
      maskapai_id: payloadMaskapai.maskapai,
      akomodasi_makkah_id: payloadHotel.makkah,
      akomodasi_makkah_number_of_days: payloadHotel.jumlah_malam_makkah,
      akomodasi_madinah_id: payloadHotel.madinah,
      akomodasi_madinah_number_of_days: payloadHotel.jumlah_malam_madinah,
      paket_kalkulator_tour_id: payloadTour.negaraTour,
      tour_akomodasi_id: payloadTour.hotel,
      tour_akomodasi_number_of_days: payloadTour.jumlah_malam,
      paket_kalkulator_city_tour_id: payloadTour.cityTour,
      fee_cabang: payloadFeeCabang.branch_fee,
    };

    const headers = {
      "Content-Type": "application/json", // Adjust content type as needed
      Authorization: `Bearer ${accessToken}`, // Add any authorization headers you need
    };

    setIsLoadingCheck(true);

    try {
      const response = await axios.post(url, data, { headers });

      if (response.data.code === 200) {
        setIsVisible(true);
        setPayloadFeeCabang({
          ...payloadFeeCabang,
          total_harga: response.data.data,
        });
      } else {
        setOpenPopupError({
          show: true,
          subtitle: response.data.message,
        });
      }

      setIsLoadingCheck(false);
    } catch (error) {

      setIsVisible(false);
      setIsLoadingCheck(false);
    }
  };

  const handleClosePopup = () => {
    setPayloadJumlahHari({
      nama_paket: "",
      kategori_paket: "",
      jumlah_hari: "",
      bulan: "",
      tahun: "",
    });
    setPayloadMaskapai({
      maskapai: "",
    });
    setPayloadHotel({
      madinah: "",
      makkah: "",
      jumlah_malam_madinah: 3,
      jumlah_malam_makkah: 3,
    });
    setPayloadTour({
      cityTour: "",
      negaraTour: "",
      negaraTourName: "",
      hotel: "",
      jumlah_malam: 3,
    });
    setPayloadFeeCabang({
      branch_fee: "",
      total_harga: "",
    });
    setCurrentStep(0);
  };

  return (
    <>
      <PopupConfirm
        open={openPopupSuccess.show}
        handleClose={() => {
          // handleClose()
          setOpenPopupSuccess({ show: false });
        }}
        // handleClose={handleClose}
        type="success"
        title="Berhasil"
        subtitle="Berhasil menambahkan paket request"
      />
      {/* <PopupConfirm
        open={openPopupError.show}
        handleClose={() => setOpenPopupError({ show: false })}
        type="error"
        title="Error"
        subtitle={openPopupError.subTitle}
      /> */}
      <Popup
        open={open}
        handleClose={() => {
          handleClose();
          handleClosePopup();
        }}
        title="Request Paket"
        height="648px"
        width="1172px"
      >
        <div className={classes.modal_body}>
          <div className="border rounded-2xl py-6 px-8">
            <Steppers
              // containerClass={`${classes.custom_stepper} custom-stepper-vertical m-auto`}
              items={stepItems}
              current={currentStep}
              labelPlacement="vertical"
              direction="vertical"
            />
          </div>
          <div className="border rounded-2xl w-full">
            <div className="scroll_area">
              {currentStep === 0 && (
                <Step1
                  payload={payloadJumlahHari}
                  setPayload={setPayloadJumlahHari}
                />
              )}
              {currentStep === 1 && (
                <Step2
                  payload={payloadMaskapai}
                  payloadJumlahHari={payloadJumlahHari}
                  setPayload={setPayloadMaskapai}
                  onHandleError={(error) => setOpenPopupError(error)}
                />
              )}
              {currentStep === 2 && (
                <Step3 
                  payload={payloadHotel} 
                  payloadJumlahHari={payloadJumlahHari}
                  setPayload={setPayloadHotel}
                  onHandleError={(error) => setOpenPopupError(error)}
                  />
              )}
              {currentStep === 3 && (
                <Step4 
                  payload={payloadTour} 
                  payloadJumlahHari={payloadJumlahHari}
                  setPayload={setPayloadTour} 
                  onHandleError={(error) => setOpenPopupError(error)}
                  />
              )}
              {currentStep === 4 && (
                <>
                  <Step5
                    payload={payloadFeeCabang}
                    setPayload={setPayloadFeeCabang}
                    handleCheckHargaPaket={handleCheckHargaPaket}
                    loading={isLoadingCheck}
                  />
                </>
              )}
              <PopupConfirm
                  open={openPopupError.show}
                  handleClose={() => setOpenPopupError({show: false})}
                  type="error"
                  title="Gagal"
                  subtitle={openPopupError.subtitle}
                />
            </div>
            {currentStep === 0 && (
              <div className="action_area justify-between items-center">
                <div>
                  <h1 className="text-base font-semibold text-[#141414]">
                    Total
                  </h1>
                  {isVisible === false ? (
                    <h1 className="total flex flex-row items-center text-xl font-semibold mt-1">
                      -
                    </h1>
                  ) : (
                    <h1 className="total flex flex-row items-center text-xl font-semibold mt-1">
                      Rp. {numberWithDot(payloadFeeCabang?.total_harga)}
                      <h1 className="text-sm font-normal text-[#141414]">
                        {` /pax`}
                      </h1>
                    </h1>
                  )}
                </div>
                <div className="flex mr-4">
                  <Buttons
                    text="Selanjutnya"
                    customClass={`btn-primary ${
                      !isAbleToNextStep1 && "disabled"
                    } btn-large w-fit ml-4`}
                    onClick={() => setCurrentStep(currentStep + 1)}
                    disabled={!isAbleToNextStep1}
                  />
                </div>
              </div>
            )}
            {currentStep === 1 && (
              <div className="action_area justify-between items-center">
                <div>
                  <h1 className="text-base font-semibold text-[#141414]">
                    Total
                  </h1>
                  {isVisible === false ? (
                    <h1 className="total flex flex-row items-center text-xl font-semibold mt-1">
                      -
                    </h1>
                  ) : (
                    <h1 className="total flex flex-row items-center text-xl font-semibold mt-1">
                      Rp. {numberWithDot(payloadFeeCabang?.total_harga)}
                      <h1 className="text-sm font-normal text-[#141414]">
                        {` /pax`}
                      </h1>
                    </h1>
                  )}
                </div>
                <div className="flex mr-4">
                  <Buttons
                    text="Kembali"
                    customClass="btn-outline btn-large w-fit"
                    onClick={() => setCurrentStep(currentStep - 1)}
                  />
                  <Buttons
                    text="Selanjutnya"
                    customClass={`btn-primary btn-large w-fit ml-4`}
                    onClick={() => setCurrentStep(currentStep + 1)}
                    disabled={payloadMaskapai.maskapai === ""}
                  />
                </div>
              </div>
            )}
            {currentStep === 2 && (
              <div className="action_area justify-between">
                <div>
                  <h1 className="text-base font-semibold text-[#141414]">
                    Total
                  </h1>
                  {isVisible === false ? (
                    <h1 className="total flex flex-row items-center text-xl font-semibold mt-1">
                      -
                    </h1>
                  ) : (
                    <h1 className="total flex flex-row items-center text-xl font-semibold mt-1">
                      Rp. {numberWithDot(payloadFeeCabang?.total_harga)}
                      <h1 className="text-sm font-normal text-[#141414]">
                        {` /pax`}
                      </h1>
                    </h1>
                  )}
                </div>
                <div className="flex mr-4">
                  <Buttons
                    text="Kembali"
                    customClass="btn-outline btn-large w-fit"
                    onClick={() => setCurrentStep(currentStep - 1)}
                  />
                  <Buttons
                    text="Selanjutnya"
                    customClass={`btn-primary btn-large w-fit ml-4`}
                    onClick={() => setCurrentStep(currentStep + 1)}
                    disabled={!isAbleToNextStep3}
                  />
                </div>
              </div>
            )}
            {currentStep === 3 && (
              <div className="action_area justify-between">
                <div>
                  <h1 className="text-base font-semibold text-[#141414]">
                    Total
                  </h1>
                  {isVisible === false ? (
                    <h1 className="total flex flex-row items-center text-xl font-semibold mt-1">
                      -
                    </h1>
                  ) : (
                    <h1 className="total flex flex-row items-center text-xl font-semibold mt-1">
                      Rp. {numberWithDot(payloadFeeCabang?.total_harga)}
                      <h1 className="text-sm font-normal text-[#141414]">
                        {` /pax`}
                      </h1>
                    </h1>
                  )}
                </div>
                <div className="flex mr-4">
                  <Buttons
                    text="Kembali"
                    customClass="btn-outline btn-large w-fit"
                    onClick={() => setCurrentStep(currentStep - 1)}
                  />
                  <Buttons
                    text="Selanjutnya"
                    customClass={`btn-primary btn-large w-fit ml-4`}
                    onClick={() => setCurrentStep(currentStep + 1)}
                    disabled={!isAbleToNextStep4}
                  />
                </div>
              </div>
            )}
            {currentStep === 4 && (
              <div className="action_area justify-between">
                <div>
                  <h1 className="text-base font-semibold text-[#141414]">
                    Total
                  </h1>
                  {isVisible === false ? (
                    <h1 className="total flex flex-row items-center text-xl font-semibold mt-1">
                      -
                    </h1>
                  ) : (
                    <h1 className="total flex flex-row items-center text-xl font-semibold mt-1">
                      Rp. {numberWithDot(payloadFeeCabang?.total_harga) + " "}
                      <h1 className="text-sm font-normal text-[#141414]">
                        {` /pax`}
                      </h1>
                    </h1>
                  )}
                </div>
                <div className="flex gap-5">
                  <Buttons
                    text="Kembali"
                    customClass="btn-outline btn-large w-fit"
                    onClick={() => {
                      setCurrentStep(currentStep - 1)
                      setIsVisible(false)
                      setPayloadFeeCabang({
                        ...payloadFeeCabang,
                        total_harga: null,
                      });
                    }}
                  />
                  <Buttons
                    text="Request Paket"
                    customClass={`btn-primary btn-large w-fit`}
                    onClick={() => onHandleRequestPaket(handleClose)}
                    loading={isLoading.requestPaket}
                    disabled={isVisible === false}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </Popup>
    </>
  );
};

export default AddPaketPopup;
