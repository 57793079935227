import React from "react";

import Progressbars from "../../../../components/progressbars";

const JamaahEquipment = () => {
  return (
    <div>
      <div>
        <div className="text-[16px] font-semibold">Checklist Perlengkapan</div>
        <Progressbars percent={70} info="10/13" />
      </div>
    </div>
  );
};

export default JamaahEquipment;
