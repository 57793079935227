import React, { useEffect, useState } from "react";
import Selects from "../../../../../components/form/selects";
import Popup from "../../../../../components/popup";
import Switch from "../../../../../components/form/switch";
import { getAdditional, saveAdditional } from "utils/api/paket/additional";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import Buttons from "components/buttons";
import InputsCurrency from "components/form/inputsCurrency";
import { useDispatch } from "react-redux";
import Textareas from "components/form/textarea";
import { saveAdditionalPaket } from "stores/actions/paket/additional";
import { handleErrorBE, handleSuccess } from "stores/actions/errorGeneral";

function Create({
  show,
  setIsCreate,
  setIsUpdate,
  type = "",
  detailEdit,
  setType,
}) {
  const [options, setOptions] = useState([]);
  const dispatch = useDispatch();
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [editTrigger, setEditTrigger] = useState({
    id_additional: "",
    price: "",
    is_wajib: "",
  });
  const params = useParams();

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      id_paket: params.id,
      id_additional: "",
      price: "",
      is_wajib: false,
    },
    validationSchema: Yup.object({
      price: Yup.string().required("This field is required"),
      id_additional: Yup.number().required("This field is required"),
    }),
    onSubmit: async (data) => {
      let { is_wajib } = data;
      if (is_wajib) {
        is_wajib = "1";
      } else {
        is_wajib = "0";
      }
      if (type === "") {
        setIsLoadingAction(true);
        dispatch(
          saveAdditionalPaket(
            { ...data, is_wajib },
            (message) => {
              setIsLoadingAction(false);
              onHide();
              setIsUpdate(true);
              dispatch(handleSuccess(message));
            },
            (error) => {
              setIsLoadingAction(false);
              dispatch(handleErrorBE(error));
            }
          )
        );
      } else {
        setIsLoadingAction(true);
        dispatch(
          saveAdditionalPaket(
            { ...data, is_wajib, id: params.id },
            (message) => {
              setIsLoadingAction(false);
              onHide();
              setIsUpdate(true);
              dispatch(handleSuccess(message));
            },
            (error) => {
              setIsLoadingAction(false);
              dispatch(handleErrorBE(error));
            }
          )
        );
      }
    },
  });

  useEffect(() => {
    if (type !== "") {
      validation.setFieldValue("id_additional", detailEdit?.id_additional);
      if (detailEdit?.is_wajib === "1") {
        validation.setFieldValue("is_wajib", true);
      } else {
        validation.setFieldValue("is_wajib", false);
      }
      validation.setFieldValue("price", detailEdit?.price);
    }
  }, [type]);

  useEffect(() => {
    const fetch = async () => {
      const response = await getAdditional();
      const _temp = [];
      response.data.data.forEach((item) => {
        _temp.push({
          value: item.id_additional,
          label: item.name,
        });
      });
      setOptions(_temp);
    };
    fetch();
  }, [type]);

  const handleChangeSwitch = (e) => {
    if (type !== "") {
      setEditTrigger({
        ...editTrigger,
        is_wajib: e,
      });
    }
    validation.setFieldValue("is_wajib", e);
  };

  const onHide = () => {
    setIsCreate(false);
    setType("");
    setEditTrigger({
      id_additional: "",
      price: "",
      is_wajib: "",
    });
    setIsUpdate(true);
    validation.handleReset();
  };

  return (
    <Popup
      open={show}
      handleClose={onHide}
      width="1072px"
      title={` ${detailEdit ? "Edit Additional" : "Tambah Additional"} `}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
        }}
      >
        <div className="w-full border rounded-[16px] flex-col justify-start gap-y-[16px]">
          <div className="m-[16px]">
            <div className="">
              <Selects
                label="Pilih Additional"
                placeholder=""
                options={options}
                value={options.filter(
                  (item) => item.value === validation.values.id_additional
                )}
                searchable
                itemRenderer={({ state, item, methods }) => {
                  return (
                    <div
                      className={`react-dropdown-select-item ${
                        state.values.length !== 0 &&
                        state.values[0].value === item.value &&
                        "react-dropdown-select-item-selected"
                      }`}
                      onClick={() => {
                        methods.addItem(item);
                        validation.setFieldValue("id_additional", item.value);
                      }}
                    >
                      {item.label}
                    </div>
                  );
                }}
              />
              {validation.touched.id_additional &&
              validation.errors.id_additional ? (
                <h4 className="text-red-500">
                  {validation.errors.id_additional}
                </h4>
              ) : null}
            </div>
            <div className="my-[16px] flex flex-row justify-start items-center">
              <Switch
                containerClass=""
                onChange={handleChangeSwitch}
                checked={validation.values.is_wajib}
              />
              <div className="ml-[8px]">Additional Wajib</div>
            </div>
            <div className="mb-[16px]">
              <div className="font-semibold text-[14px] text-[#4F4F4F] mb-[8px]">
                Harga
              </div>
              <InputsCurrency
                id="pricePerDay"
                // label="Harga"
                placeholder="Masukkan Harga"
                name="price"
                value={validation.values.price || ""}
                onChange={(val) => validation.setFieldValue("price", val)}
                // containerClass="!ml-[-16px]"
                // spanCustomeClass="!ml-[16px]"
              />
              {validation.touched.price && validation.errors.price ? (
                <h4 className="text-red-500">{validation.errors.price}</h4>
              ) : null}
            </div>
          </div>
          <div className="w-full flex justify-end @apply border-t-[#E0E0E0] border-t border-solid pb-[16px] pr-[16px]">
            <Buttons
              loading={isLoadingAction}
              onClick={() => validation.handleSubmit()}
              text="Simpan"
              type="submit"
              disabled={!(validation.isValid && validation.dirty)}
              style={{ width: 150 }}
              customClass="btn-primary btn-medium mt-3"
            />
          </div>
        </div>
      </form>
    </Popup>
  );
}

export default Create;
