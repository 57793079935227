import {
  START_FETCHING_MEMBER_AGEN,
  SUCCESS_FETCHING_MEMBER_AGEN,
  ERROR_FETCHING_MEMBER_AGEN,
  SET_PAGE_AGEN,
  SET_SIZE_AGEN,
  SET_KEYWORD_AGEN,
  SET_TOTAL_AGEN,
  SET_ID_ROLE_AGEN,
  SET_ID_AGEN,
} from '../../../actions/member/actionTypes';

const statuslist = {
  idle: 'idle',
  process: 'process',
  success: 'success',
  error: 'error',
};

const initialState = {
  data: [],
  total: 100,
  per_page: 10,
  page_number: 1,
  sort_column: '',
  sort_order: 'Desc',
  keyword: '',
  id_role: '0',
  id_agen: '0',
  status: statuslist.idle,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case START_FETCHING_MEMBER_AGEN:
      return { ...state, status: statuslist.process };

    case ERROR_FETCHING_MEMBER_AGEN:
      return { ...state, status: statuslist.error };

    case SUCCESS_FETCHING_MEMBER_AGEN:
      return {
        ...state,
        data: action.data,
        status: statuslist.success,
      };
    case SET_KEYWORD_AGEN:
      return { ...state, keyword: action.keyword };

    case SET_PAGE_AGEN:
      return { ...state, page_number: action.page };

    case SET_SIZE_AGEN:
      return { ...state, per_page: action.size };

    case SET_TOTAL_AGEN:
      return { ...state, total: action.total };

    case SET_ID_ROLE_AGEN:
      return { ...state, id_role: action.id_role };
    case SET_ID_AGEN:
      return { ...state, id_agen: action.id_agen };

    default:
      return state;
  }
}
