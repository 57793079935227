import React from "react";
import PropTypes from "prop-types";
import { Segmented } from "antd";

import SegmentStyle from "./Segment.Style";

const Segment = ({
  containerClass,
  customClass,
  label,
  options,
  value,
  disabled,
  onChange,
}) => {
  return (
    <SegmentStyle className={`${containerClass}`}>
      {label !== "" && <div className="custom-segment-label">{label}</div>}
      <Segmented
        className={`custom-segment ${customClass}`}
        options={options}
        disabled={disabled}
        value={value}
        onChange={onChange}
      />
    </SegmentStyle>
  );
};

Segment.propTypes = {
  containerClass: PropTypes.string,
  customClass: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

Segment.defaultProps = {
  containerClass: "",
  customClass: "",
  label: "",
  options: [],
  disabled: false,
  onChange: () => {},
};

export default React.memo(Segment);
