import styled from "styled-components";

const SidebarChildStyle = styled.div`
  padding: 16px;
  height: 100vh;
  background: #ffffff;
  box-shadow: 0px 10px 16px -6px rgba(0, 0, 0, 0.04),
    0px 4px 80px -4px rgba(0, 0, 0, 0.04);
  border-radius: 0px 16px 0px 0px;
  position:fixed;
  width: 174px;
  left: 96px;

  .sidebar-child-title {
    color: #bdbdbd;
    margin-left: 8px;

    &.active {
      font-weight: 600;
      background: linear-gradient(204.44deg, #f90601 -18.49%, #8a1212 84.72%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
  }
`;

export default SidebarChildStyle;
