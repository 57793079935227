// login Actions
// --------------------------------------------------------

import { pathnameCONFIG } from "../../../configuration/pathConfig";
import {
  ForgetPasswordServiceV2,
  checkAkunForgetPasswordServiceV2,
  checkNumberPhoneAdminServiceV2,
  checkOTPForgetPasswordServiceV2,
  checkValidEmailAdminService,
  emailForgotPasswordService,
  loginService,
  loginWithPhoneNumberServiceV2,
  submitChangePasswordService,
  verificationOTPService,
} from "../../../utils/api/login";
import { removeAllLocalStorage, setLocalStorage } from "../../../utils/helpers";
import { handleError, handleErrorBE, handleSuccess } from "../errorGeneral";

/* eslint-disable space-before-function-paren */
export const SET_LOADING = "login/SET_LOADING";
export const CLEAR_ERROR = "login/CLEAR_ERROR";
export const SET_ERROR = "login/SET_ERROR";
export const INIT_DATA = "login/INIT_DATA";
export const SET_DOUBLE_SUBMIT = "login/SET_DOUBLE_SUBMIT";
export const SET_AUTHENTICATED = "login/SET_AUTHENTICATED";
export const SET_USERLOGIN = "login/SET_USERLOGIN";
export const SET_VALIDATED_EMAIL = "login/SET_VALIDATED_EMAIL";
export const SET_EMAIL_FORGOT_PASSWORD = "/login/SET_EMAIL_FORGOT_PASSWORD";
export const SET_VERIFICATION_OTP = "/login/SET_VERIFICATION_OTP";
export const SET_SUBMIT_CHANGE_PASSWORD = "/login/SET_SUBMIT_CHANGE_PASSWORD";

export const setInitData = () => ({
  type: INIT_DATA,
});

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const setDoubleSubmit = (payload) => ({
  type: SET_DOUBLE_SUBMIT,
  payload,
});
export const setAuthenticated = (payload) => ({
  type: SET_AUTHENTICATED,
  payload,
});

export const setUserLogin = (payload) => ({
  type: SET_USERLOGIN,
  payload,
});

export const setLoginError = (payload) => ({
  type: SET_ERROR,
  payload,
});

export const clearLoginError = () => ({
  type: CLEAR_ERROR,
});

export const setValidatedEmail = (payload) => ({
  type: SET_VALIDATED_EMAIL,
  payload,
});

export const setEmailForgotPassword = (payload) => ({
  type: SET_EMAIL_FORGOT_PASSWORD,
  payload,
});

export const setVerificationOTP = (payload) => ({
  type: SET_VERIFICATION_OTP,
  payload,
});

export const setSubmitChangePassword = (payload) => ({
  type: SET_SUBMIT_CHANGE_PASSWORD,
  payload,
});

export const logout = (history) => async (dispatch) => {
  dispatch(setUserLogin({}));
  removeAllLocalStorage();

  history.push("/login");
};

export const handleCheckValidEmail =
  (payload, actionCallBackk) => async (dispatch) => {
    try {
      console.log("Hallo");
      dispatch(clearLoginError());

      dispatch(setLoading(true));
      const response = await checkValidEmailAdminService(payload);
      dispatch(setLoading(false));
      const { status, data } = response;

      if (status === 200) {
        dispatch(setValidatedEmail(true));

        actionCallBackk(data);
      } else {
        dispatch(setLoginError(data.message));
        dispatch(setValidatedEmail(false));
        dispatch(handleErrorBE(response));
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const handleCheckValidEmailForgetPassword =
  (payload, actionCallBackk) => async (dispatch) => {
    try {
      dispatch(clearLoginError());
      dispatch(setLoading(true));
      const response = await checkAkunForgetPasswordServiceV2(payload);
      dispatch(setLoading(false));
      const { status, data } = response;

      if (status === 200) {
        dispatch(setValidatedEmail(true));

        actionCallBackk(data);
      } else {
        dispatch(setLoginError(data.message));
        dispatch(setValidatedEmail(false));
        dispatch(handleErrorBE(response));
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const handleSubmitLogin = (payload, history) => async (dispatch) => {
  try {
    dispatch(clearLoginError());
    dispatch(setLoading(true));
    const res = await loginService(payload);
    dispatch(setLoading(false));
    const { data, status } = res;

    if (status === 200) {
      const dataUserResp = {
        isAuthenticated: true,
        ...data,
      };
      dispatch(setAuthenticated(true));
      dispatch(setUserLogin(dataUserResp));
      setLocalStorage("userLogin", JSON.stringify(dataUserResp));
      setLocalStorage("accessToken", JSON.stringify(data.data.token));
      history.push(pathnameCONFIG.DASHBOARD.BASE_URL);
    } else {
      dispatch(setLoginError(data.message));
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const handleEmailForgotPassword = (payload) => async (dispatch) => {
  try {
    dispatch(setInitData());
    dispatch(clearLoginError());
    dispatch(setLoading(true));
    const response = await emailForgotPasswordService(payload);
    dispatch(setLoading(false));
    const { data, status } = response;

    if (status === 200) {
      dispatch(setEmailForgotPassword(data));
    } else {
      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const handleVerificationOTP =
  (payload, callbackAction) => async (dispatch) => {
    try {
      dispatch(setInitData());
      dispatch(clearLoginError());
      dispatch(setLoading(true));
      const response = await checkOTPForgetPasswordServiceV2(payload);
      dispatch(setLoading(false));
      const { data, status } = response;

      if (status === 200) {
        callbackAction(data);
        dispatch(setVerificationOTP(data));
      } else {
        dispatch(handleErrorBE(response));
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };

export const handleSubmitChangePassword = (payload) => async (dispatch) => {
  try {
    dispatch(setInitData());
    dispatch(clearLoginError());
    dispatch(setLoading(true));
    const response = await ForgetPasswordServiceV2(payload);
    dispatch(setLoading(false));
    const { data, status } = response;

    if (status === 200) {
      dispatch(setSubmitChangePassword(data));
      dispatch(handleSuccess(response));
    } else {
      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const handleCheckingValidateNumberPhone = (payload, actionCallBack) => {
  return async (disptach) => {
    try {
      disptach(setLoading(true));
      const res = await checkNumberPhoneAdminServiceV2(payload);
      disptach(setLoading(false));
      if (res.status === 200) {
        actionCallBack(res.data);
      } else {
        disptach(handleErrorBE(res));
      }
    } catch (error) {
      disptach(handleError(error));
    }
  };
};

export const handleCheckingWithPhoneNumber = (payload, history) => {
  return async (disptach) => {
    try {
      const res = await loginWithPhoneNumberServiceV2(payload);
      if (res.status === 200) {
        const dataUserResp = {
          isAuthenticated: true,
          ...res.data,
        };
        disptach(setAuthenticated(true));
        disptach(setUserLogin(dataUserResp));
        setLocalStorage("userLogin", JSON.stringify(dataUserResp));
        setLocalStorage("accessToken", JSON.stringify(res.data.data.token));
        history.push(pathnameCONFIG.DASHBOARD.BASE_URL);
      } else {
        disptach(handleErrorBE(res));
      }
    } catch (error) {
      disptach(handleError(error));
    }
  };
};
