import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import MoslemeTable from "components/Table";
import Badge from "components/badge";

import AvatarRed from "assets/images/Avatar-ikhwan-red.svg";
import IconWhatsapp from "assets/icons/whatsapp.svg";
import { DocumentCopy, Edit, Eye, Trash } from "iconsax-react";
import IconTask from "assets/icons/task-table.svg";

const Jamaah = () => {
  const router = useHistory();
  // Loading
  const [isLoading, setIsLoading] = useState(false);
  // Expaneble
  const [isExpand, setIsExpand] = useState(true);
  // Selection & type state
  const [selectionType, setSelectionType] = useState("checkbox");
  const [withSelection, setWithSelection] = useState(false);

  // Pagination state
  const [currentpage, setCurrentpage] = useState(1);
  const [size, setSize] = useState(10);
  const total = 100;

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: total,
    current: currentpage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20"],
  };

  const columns = [
    {
      title: "Foto",
      dataIndex: "photo",
      render: (img) => <img src={img} alt="img" />,
    },
    {
      title: "Nama Jamaah",
      dataIndex: "name",
      render: (val) => (
        <>
          <div className="w-max">{val[0].name}</div>
          <div className="flex flex-row mt-1">
            <Badge label={val[0].tags[0]} type="blue light" />
            <Badge
              label={val[0].tags[1]}
              type="orange old"
              customClass="ml-1"
            />
          </div>
        </>
      ),
    },
    {
      title: "Kode Booking",
      dataIndex: "booking_code",
    },
    {
      title: "Dokumen",
      dataIndex: "document",
      render: (doc) => (
        <div className="flex flex-row items-center gap-1 bg-[#D8E1BE] border border-[#71BE0E] rounded-lg px-3 py-[6px] w-fit">
          <img src={IconTask} alt="task" />
          <div>Dokumen</div>
          <div>(3/10)</div>
        </div>
      ),
    },
    {
      title: "Perlengkapan",
      dataIndex: "perlengkapan",
      render: (doc) => (
        <div className="flex flex-row items-center gap-1 border-[0.5px] border-[#E0E0E0] rounded-lg px-3 py-[6px] w-fit">
          <img src={IconTask} alt="task" />
          <div>Perlengkapan</div>
          <div>(0/10)</div>
        </div>
      ),
    },
    {
      title: "Follow Up",
      dataIndex: "followup",
      render: () => (
        <>
          <div className="flex flex-row gap-1">
            <div className="px-2 py-1 rounded-xl whatsapp-brown-bg">
              <img
                className="w-[24px] h-[24px]"
                src={IconWhatsapp}
                alt="whatsapp"
              />
            </div>
          </div>
          <div className="action">
            <Eye
              className="cursor-pointer"
              onClick={() => router.push("/booking/detail")}
              size="15"
              color="#141414"
            />
            <Trash
              className="cursor-pointer"
              // onClick={() => setOpenPopupDeleteCabang(true)}
              size="15"
              color="#141414"
            />
          </div>
        </>
      ),
    },
    {
      title: "Paket",
      dataIndex: "paket",
      render: (paket) => (
        <>
          <div>{paket}</div>
          <div className="action">
            <DocumentCopy
              className="cursor-pointer"
              onClick={() => alert(`copy id:`)}
              size="15"
              color="#141414"
            />
            <Edit
              className="cursor-pointer"
              onClick={() => alert(`edit id:`)}
              size="15"
              color="#141414"
            />
          </div>
        </>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      photo: AvatarRed,
      name: [
        {
          name: "Dimas Andreyean Pradana Putra",
          tags: ["BPN-CGK", "ACEH"],
        },
      ],
      booking_code: "#UMR-123",
      document: "3",
      perlengkapan: "3",
      followup: "whatsapp",
      paket: "Umroh Bersama Ustadz Muhammad Abduh Tuasikal 12 September 2022",
    },
  ];

  // func to set size pagination
  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setSize(pageSize);
  };

  // if have expaneble, this is the props example..
  const expandable = {
    expandedRowRender: (record) => {
      return (
        <div className="ml-2">
          <ul className="w-full flex py-3 border-l">
            <li className="w-[15rem]">
              <ul>
                <li className="border-b pl-5 pb-3 font-bold">
                  Tanggal Booking
                </li>
              </ul>
            </li>
            <li className="w-full">
              <ul>
                <li className="border-b pl-5 pb-3 font-bold">Tipe Kamar</li>
              </ul>
            </li>
          </ul>
          <ul className="w-full flex items-center pb-3 border-l">
            <li className="w-[15rem]">
              <ul>
                <li className="pl-5 pt-2">
                  <div>
                    21/08/2022
                    <br />
                    14:07 WIB
                  </div>
                </li>
              </ul>
            </li>
            <li className="w-full">
              <ul>
                <li className="pl-5 pt-2 flex gap-2">
                  <Badge
                    backgroundColor="#92E6A7"
                    color="#141414"
                    label={
                      <div className="flex items-center">
                        <div className="text-[#1B662E] font-semibold">
                          Quad <span className="font-normal">(2pax)</span>
                        </div>
                      </div>
                    }
                  />
                </li>
              </ul>
            </li>
          </ul>
        </div>
      );
    },
    rowExpandable: (record) => record.booking_code !== "Not Expandable",
  };
  // manipulated data with action
  const sourceData = data;

  return (
    <div className="mx-[-14px]">
      {/* Table */}
      <MoslemeTable
        loading={isLoading}
        expandable={isExpand && expandable}
        columns={columns}
        data={sourceData}
        withSelection={true}
        selectionType={selectionType}
        pagination={pagination}
        customPaginationClass="pr-4"
        customClass="mt-4"
        onPageChange={(page) => setCurrentpage(page)}
        onSizeChange={(currentpage, pageSize) =>
          onSizeChange(currentpage, pageSize)
        }
      />
    </div>
  );
};

export default React.memo(Jamaah);
