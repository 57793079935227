import axios from "axios";
import { BASE_URL_ENDPOINT } from "constant/constanta";

const AppsMitraAPI = {
  getList: (payload) =>
    axios.post(`${BASE_URL_ENDPOINT}/admin/get-mitra`, payload),
  postData: (payload) =>
    axios.post(`${BASE_URL_ENDPOINT}/admin/save-mitra`, payload),
  deleteData: (id) => axios.delete(`${BASE_URL_ENDPOINT}/admin/mitra/${id}`),
};

export { AppsMitraAPI };
