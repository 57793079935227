import { applyMiddleware, createStore } from "redux";
import logger from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension";
import { rootReducer } from "./reducers";

import thunk from "redux-thunk";
import {
  requestInterceptors,
  responseInterceptors,
} from "../utils/middlewares/interceptors";

requestInterceptors();

responseInterceptors();

let middleware;

if (process.env.NODE_ENV === "production") {
  middleware = [thunk];
} else {
  middleware = [thunk, logger];
}

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
