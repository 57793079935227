import styled from "styled-components";

const FeeStyle = styled.div`
  .sidebar-child-title {
    color: #bdbdbd;
  }

  .sidebar-tab-active {
    font-weight: 600;
    background: linear-gradient(204.44deg, #f90601 -18.49%, #8a1212 84.72%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  .ant-segmented-group {
    gap: 16px;
  }

  .ant-segmented-item-label {
    padding: 8px 16px;
    border-radius: 12px;
  }

  .ant-table-wrapper .ant-table-tbody > tr > td {
    position: unset;
  }
`;

export default FeeStyle;
