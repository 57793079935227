import styled from "styled-components";

const DrawersStyle = styled.div`
  .ant-drawer .ant-drawer-body {
    padding: 16px;
  }
  .ant-drawer-content-wrapper {
    width:400px!important;
  }
`;

export default DrawersStyle;
