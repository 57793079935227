/* eslint-disable import/no-anonymous-default-export */
// cabang Reducers
// --------------------------------------------------------

import {
  SET_LOADING,
  CLEAR_ERROR,
  SET_ERROR,
  INIT_DATA,
  SET_DOUBLE_SUBMIT,
  SET_DATA_ALL_CABANG,
  SET_DETAIL_CABANG,
  SET_CREATE_CABANG,
  SET_REGION,
  DETAIL_SET_LOADING,
  GENERATE_QR_SET_LOADING,
  HISTORY_SET_LOADING,
  CHECK_LICENSE_SET_LOADING,
  ENABLE_WA_BRANCH_LOADING,
  LOGOUT_WA_LOADING,
  CHECK_QR_STATUS_SET_LOADING,
  SET_QR_STATUS,
} from "../../actions/cabang";

const initialState = {
  isLoading: false,
  isDoubleSubmit: false,
  isSubmitting: false,
  dataAllCabang: {},
  detailCabang: {},
  createCabang: {},
  region: [],
  error: {},
  qrStatus: "",

  detailLoading: false,
  generateQRLoading: false,
  isHistoryLoading: false,
  isCheckLicenseLoading: false,
  isEnableWABranchLoading: false,
  isLogoutWALoading: false,
  isCheckQRStatusLoading: false,
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_DOUBLE_SUBMIT:
      return {
        ...state,
        isDoubleSubmit: payload,
      };
    case SET_ERROR:
      return {
        ...state,
        error: payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: {},
      };
    case INIT_DATA:
      return {
        ...initialState,
      };
    case SET_DATA_ALL_CABANG:
      return {
        ...state,
        dataAllCabang: payload,
      };
    case SET_DETAIL_CABANG:
      return {
        ...state,
        detailCabang: payload,
      };
    case SET_CREATE_CABANG:
      return {
        ...state,
        createCabang: payload,
      };
    case SET_REGION:
      return {
        ...state,
        region: payload,
      };

    case DETAIL_SET_LOADING:
      return {
        ...state,
        detailLoading: payload,
      };

    case GENERATE_QR_SET_LOADING:
      return {
        ...state,
        generateQRLoading: payload,
      };

    case HISTORY_SET_LOADING:
      return {
        ...state,
        isHistoryLoading: payload,
      };

    case CHECK_LICENSE_SET_LOADING:
      return {
        ...state,
        isCheckLicenseLoading: payload,
      };

    case ENABLE_WA_BRANCH_LOADING:
      return {
        ...state,
        isEnableWABranchLoading: payload,
      };
    case LOGOUT_WA_LOADING:
      return {
        ...state,
        isLogoutWALoading: payload,
      };

    case CHECK_QR_STATUS_SET_LOADING:
      return {
        ...state,
        isCheckQRStatusLoading: payload,
      };

    case SET_QR_STATUS:
      return {
        ...state,
        qrStatus: payload,
      };

    default:
      return state;
  }
};
