import React, { useState } from "react";
import Buttons from "../buttons";
import Steppers from "./Steppers";

const SteppersDocs = () => {
  const [current, setCurrent] = useState(0);

  const items = [
    {
      title: "Info Dasar",
      content: "halo",
    },
    {
      title: "Alamat & Koordinasi",
      content: <div>yuhu</div>,
    },
    {
      title: "Whatsapp Settings",
      content: <div>huuuaa</div>,
    },
  ];

  return (
    <>
      <div>
        <Steppers items={items} current={current} labelPlacement="vertical" />
      </div>

      <div className="mt-20">
        <Steppers
          containerClass="custom-stepper-vertical m-auto"
          labelPlacement="vertical"
          direction="vertical"
          items={items}
          current={current}
        />
      </div>

      <div className="mt-10 flex flex-row gap-3">
        {current > 0 && (
          <Buttons
            text="Sebelumnya"
            containerClass="mt-[20px] w-[186px] h-[48px]"
            customClass="btn-primary btn-large"
            onClick={() => setCurrent(current - 1)}
          />
        )}
        {current < items.length - 1 && (
          <Buttons
            text="Selanjutnya"
            containerClass="mt-[20px] w-[186px] h-[48px]"
            customClass="btn-primary btn-large"
            onClick={() => setCurrent(current + 1)}
          />
        )}
        {current === items.length - 1 && (
          <Buttons
            text="Submit"
            containerClass="mt-[20px] w-[186px] h-[48px]"
            customClass="btn-primary btn-large"
            onClick={() => alert("Processing complete!")}
          />
        )}
      </div>
    </>
  );
};

export default SteppersDocs;
