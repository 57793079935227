import React from "react";

import Buttons from "../../../../../../components/buttons";
import Inputs from "../../../../../../components/form/inputs";
import { SettingWebAppAPI } from "utils/api/setting";
import Toast from "components/toast";

const MobileAdmin = () => {
  const [field, setField] = React.useState({
    link_playstore_admin: "",
    link_appstore_admin: "",
  });

  const [success, setSuccess] = React.useState(false);

  const onSubmit = () => {
    SettingWebAppAPI.postData(field).then((res) => {
      if (res.status === 200) {
        setSuccess(true);
        setField({
          link_playstore_admin: "",
          link_appstore_admin: "",
        });
        setTimeout(() => {
          setSuccess(false);
        }, 1500);
      }
    });
  };
  return (
    <div>
      <Toast
        open={success}
        message={"Success"}
        status="success"
        handleClose={() => setSuccess(false)}
      />
      <Inputs
        value={field.link_playstore_admin}
        onChange={(e) =>
          setField({ ...field, [e.target.name]: e.target.value })
        }
        name="link_playstore_admin"
        label="Link Playstore"
        placeholder="Masukkan Link Play Store"
      />
      <Inputs
        value={field.link_appstore_admin}
        onChange={(e) =>
          setField({ ...field, [e.target.name]: e.target.value })
        }
        name="link_appstore_admin"
        containerClass="mt-4"
        label="Link Appstore"
        placeholder="Masukkan Link App Store"
      />
      <div className="flex flex-row justify-end mt-4">
        <Buttons
          onClick={onSubmit}
          disabled={
            field.link_appstore_admin == "" || field.link_playstore_admin == ""
          }
          text="Simpan"
          customClass="btn-primary btn-medium"
        />
      </div>
    </div>
  );
};

export default React.memo(MobileAdmin);
