import styled from "styled-components";

const SteppersStyle = styled.div`
&.custom-stepper {

  .ant-steps-item-icon {
    border:none!important;
  }

  .ant-steps-item-title {
    font-size: 12px!important;
    line-height:16px!important;
    color: #141414!important;
    white-space: nowrap;
  }

  .ant-steps-item {
    .ant-steps-item-icon {
      .ant-steps-icon {
        width: 32px;
        height: 32px;
        border: double 1px transparent;
        border-radius: 50%;
        background-image: linear-gradient(white, white),  radial-gradient(circle at top right, #F90601, #8A1212);
        background-origin: border-box;
        background-clip: content-box, border-box;
        display: inline-block;
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgb(232, 35, 32);
      }
    }
  }

  .ant-steps-item-process .ant-steps-item-icon {
    background: transparent;
  }

  .ant-steps-item-active .ant-steps-item-icon .ant-steps-icon,
  .ant-steps-item-finish .ant-steps-item-icon .ant-steps-icon {
    background-image: linear-gradient(191deg, #F90601 0%, #8A1212 100%);
    border:none;
    color: #fff;
  }

  .ant-steps-item:last-child {
    .ant-steps-item-content {
      padding-bottom: 0px!important;
    }
  }

  &.custom-stepper-horizontal {
    width: 100%;

    .ant-steps-item-container {
      .ant-steps-item-content {
        margin-top: 8px!important;
      }  

      .ant-steps-item-tail {
        padding: 4px 36px;
        &::after {
          background: #E0E0E0!important;
          
        }
      }

    }
    
    .ant-steps-horizontal {
      width: 100%;
    }
  }

  &.custom-stepper-vertical {    
    height:100%;
    padding-left: 24px;
    padding-right: 24px;

    .ant-steps-item:last-child {
      flex: none!important;
    }
    
    .ant-steps-item-title {
      padding-inline-end: 0px!important;
    }

    .ant-steps-item-container {
      display: flex;
      flex-direction: column;
      align-items:center;
      
      .ant-steps-item-tail {
        left:50%!important;
        padding: 38px 0 16px!important;
        
        &::after {
          background: #E0E0E0!important;
        }
      }

      .ant-steps-item-content {
        min-height: auto;
        padding-top:8px;
        padding-bottom: 16px;
        background: #fff;
        z-index: 2;
      }

      .ant-steps-item-icon {
        margin-inline-end: 0px;
      }

    }

    .ant-steps-vertical {
      height: 100%;
    }

  }
  

}

`;

export default SteppersStyle;
