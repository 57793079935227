import React, { useState, useEffect } from "react";
import { Radio, Checkbox } from "antd";
import MoslemeTable from "components/Table";
import { DocumentCopy, Edit, Eye, Trash } from "iconsax-react";
import Avatar from "../../../../../assets/images/Avatar-ikhwan-red.svg";
import Badge from "components/badge";
import PerlengkapanDokumen from "./PerlengkapanDokumen";
import { useDispatch, useSelector } from "react-redux";
import { handleGetAllDokumen } from "stores/actions/paket/dokumen";

const Dokumen = () => {
  const dispatch = useDispatch();
  const { dataDocument, isLoading } = useSelector((state) => state.dokumen);

  useEffect(() => {
    dispatch(handleGetAllDokumen(1));
  }, []);

  const columns = [
    {
      title: "Foto",
      dataIndex: "foto",
      render: (image) => {
        return (
          <img
            src={Avatar}
            alt="foto"
            className="shadow rounded-full w-[32px] h-[32px] max-w-full h-auto align-middle border-none"
          />
        );
      },
    },
    {
      title: "Panggilan",
      dataIndex: "panggilan",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Jenis Kelamin",
      dataIndex: "jenisKelamin",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Starting",
      dataIndex: "starting",
      render: (val) => (
        <Badge
          label={val}
          backgroundColor="rgba(191, 144, 1, 0.2)"
          color="#C08F01"
        />
      ),
    },
    {
      title: "Cabang",
      dataIndex: "cabang",
      render: (val) => (
        <Badge label={val} backgroundColor="#E7BC91" color="#603808" />
      ),
    },
    {
      title: "Perlengkapan",
      dataIndex: "perlengkapan",
      className: "whitespace-nowrap relative",
      render: (value) => <PerlengkapanDokumen value={value} />,
    },
    {
      title: "",
      dataIndex: "Action",
      className: "whitespace-nowrap",
      render: (s) => (
        <div>
          <div className="w-20 overflow-hidden">
            <p className="whitespace-nowrap text-ellipsis">{s}</p>
          </div>
          <div className="action">
            <Eye
              onClick={() => alert(`show id:`)}
              size="15"
              color="#141414"
              className="cursor-pointer"
            />
            <Trash
              onClick={() => alert(`delete id:`)}
              size="15"
              color="#141414"
              className="cursor-pointer"
            />
            <DocumentCopy
              onClick={() => setIsDuplicate(true)}
              size="15"
              color="#141414"
              className="cursor-pointer"
            />
            <Edit
              onClick={() => alert(`edit id:`)}
              size="15"
              color="#141414"
              className="cursor-pointer"
            />
          </div>
        </div>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      name: "John Brown",
      panggilan: "tuan",
      foto: "foto",
      jenisKelamin: "laki laki",
      starting: "BPN - CGK",
      cabang: "Balikpapan",
      perlengkapan: "1",
    },
    {
      key: "2",
      name: "Jim Green",
      panggilan: "tuan",
      foto: "foto",
      jenisKelamin: "laki laki",
      starting: "BPN - CGK",
      cabang: "Balikpapan",
      perlengkapan: "0",
    },
    {
      key: "3",
      name: "Joe Black",
      panggilan: "tuan",
      foto: "foto",
      jenisKelamin: "laki laki",
      starting: "BPN - CGK",
      cabang: "Balikpapan",
      perlengkapan: "1",
    },
    {
      key: "4",
      name: "Joe Black",
      panggilan: "tuan",
      foto: "foto",
      jenisKelamin: "laki laki",
      starting: "BPN - CGK",
      cabang: "Balikpapan",
      perlengkapan: "1",
    },
    {
      key: "5",
      name: "Joe Black",
      panggilan: "tuan",
      foto: "foto",
      jenisKelamin: "laki laki",
      starting: "BPN - CGK",
      cabang: "Balikpapan",
      perlengkapan: "1",
    },
    {
      key: "6",
      name: "Jim Green",
      panggilan: "tuan",
      foto: "foto",
      jenisKelamin: "laki laki",
      starting: "BPN - CGK",
      cabang: "Balikpapan",
      perlengkapan: "0",
    },
  ];

  // Expaneble
  const [isExpand, setIsExpand] = useState(false);
  // Selection & type state
  const [selectionType, setSelectionType] = useState("checkbox");
  const [withSelection, setWithSelection] = useState(false);
  const [isDuplicate, setIsDuplicate] = React.useState(false);

  // Pagination state
  const [currentpage, setCurrentpage] = useState(1);
  const [size, setSize] = useState(10);
  const total = 100;

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: total,
    current: currentpage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20"],
  };

  // func to set size pagination
  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setSize(pageSize);
  };

  // if have expaneble, this is the props example..
  const expandable = {
    expandedRowRender: (record) => <p style={{ margin: 0 }}>{record.name}</p>,
    rowExpandable: (record) => record.name !== "Not Expandable",
  };
  // manipulated data with action
  const sourceData = data;

  return (
    <>
      <div className="overflow-auto">
        <MoslemeTable
          columns={columns}
          loading={isLoading}
          data={data}
          withSelection
          selectionType="checkbox"
          pagination={pagination}
          customClass="overflow-auto z-0"
          onPageChange={(page) => setCurrentpage(page)}
          onSizeChange={(currentpage, pageSize) =>
            onSizeChange(currentpage, pageSize)
          }
        />
      </div>
    </>
  );
};

export default Dokumen;
