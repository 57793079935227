import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMasterId } from "stores/actions/datamaster";
import {
  createOrUpdateMusim,
  getMasterMusim,
} from "stores/actions/datamaster/musim";
import Buttons from "../../../../../components/buttons";
import Card from "../../../../../components/cards";
import Inputs from "../../../../../components/form/inputs";
import Textareas from "../../../../../components/form/textarea";
import RichTextEditor from "components/richTextEditor";

function MusimComponentModal() {
  const dispatch = useDispatch();
  const { masterId } = useSelector((state) => state.datamaster);
  const { failedMusim, resultMusim, isLoading } = useSelector(
    (state) => state.musim
  );
  const [input, setInput] = useState({
    thn_hijriyah: masterId?.data?.thn_hijriyah || "",
    thn_masehi: masterId?.data?.thn_masehi || "",
    description: masterId?.data?.description || "",
  });

  const onHandleAdd = () => {
    if (masterId?.data) {
      input.id_musim = masterId?.data?.id_musim;
      dispatch(createOrUpdateMusim(input));
    } else {
      dispatch(createOrUpdateMusim(input));
    }
  };

  useEffect(() => {
    if (resultMusim?.code === 200) {
      dispatch(getMasterId({}));

      dispatch(getMasterMusim({ sort_order: "DESC" }));
    }
  }, [dispatch, resultMusim?.code]);

  const handleValidationDisable = () => {
    if (
      input.thn_masehi === "" ||
      input.thn_hijriyah === "" ||
      input.description === "<p><br></p>"
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Card containerClass="!px-0 !pb-4 !pt-4 !rounded-2xl !shadow-none !bg-transparent !border !border-gray-200">
      <div className="w-full px-4">
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item sm={12} md={6}>
            <Inputs
              id="hijiriyah"
              label="Tahun  Hijiriyah"
              placeholder="Masukan Tahun Hijriyah"
              value={input.thn_hijriyah}
              onChange={(e) =>
                setInput({ ...input, thn_hijriyah: e.target.value })
              }
              error={
                failedMusim?.data?.thn_hijriyah &&
                failedMusim?.data?.thn_hijriyah[0]
              }
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <Inputs
              id="masehi"
              label="Tahun Masehi"
              placeholder="Masukan Tahun Masehi"
              value={input.thn_masehi}
              onChange={(e) =>
                setInput({ ...input, thn_masehi: e.target.value })
              }
              error={
                failedMusim?.data?.thn_masehi &&
                failedMusim?.data?.thn_masehi[0]
              }
            />
          </Grid>
        </Grid>
        <div className="mt-4">
          <Grid item sm={12}>
            <RichTextEditor
              placeholder="Masukkan deskripsi musim"
              className="text-sm text-[#4f4f4f]"
              data={{ value: input.description }}
              onChange={(e) => {
                setInput({ ...input, description: e });
              }}
            />
          </Grid>
        </div>
      </div>
      <div className="w-full flex justify-end border-t border-gray-200 pt-4 px-4 mt-4">
        <div className="w-64 h-auto">
          <Buttons
            text="Selanjutnya"
            disabled={handleValidationDisable()}
            loading={isLoading ? true : false}
            onClick={onHandleAdd}
            customClass={`btn-primary btn-large `}
          />
        </div>
      </div>
    </Card>
  );
}

export default MusimComponentModal;
