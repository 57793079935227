import React from "react";
import PropTypes from "prop-types";
import Select from "react-dropdown-select";

import SelectStyle from "./Select.style";
import Close from "../../../assets/icons/Close.svg";
import Check from "../../../assets/icons/Check.svg";

const Selects = ({
  containerClass,
  customClass,
  id,
  name,
  searchable,
  required,
  multi,
  label,
  value,
  placeholder,
  icon,
  iconPosition,
  options,
  disabled,
  success,
  error,
  onChange,
  itemRenderer,
  onDropdownOpen,
  onDropdownClose,
  customeLabel,
}) => {
  return (
    <SelectStyle className={`${containerClass}`}>
      {label !== "" && (
        <div
          className={`select-label ${customeLabel} ${
            disabled && "select-label--disabled"
          }`}
        >
          {label}
        </div>
      )}
      <div className={`relative ${disabled && "cursor-not-allowed"}`}>
        {icon && iconPosition === "left" && (
          <img src={icon} className="ic-left" alt="icon-left" />
        )}
        <Select
          id={id}
          name={name}
          values={value}
          options={options}
          className={`custom-select ${
            icon !== "" && "!pl-[44px]"
          } ${customClass}`}
          searchable={searchable}
          required={required}
          multi={multi}
          placeholder={placeholder}
          disabled={disabled}
          onChange={onChange}
          itemRenderer={itemRenderer}
          onDropdownOpen={onDropdownOpen}
          onDropdownClose={onDropdownClose}
        />
      </div>
      {success !== "" && (
        <div className="flex flex-row items-center">
          <img src={Check} alt="check" />
          <div className="select-success-msg">{success}</div>
        </div>
      )}
      {error !== "" && (
        <div className="flex flex-row items-center">
          <img src={Close} alt="close" />
          <div className="select-error-msg">{error}</div>
        </div>
      )}
    </SelectStyle>
  );
};

Selects.propTypes = {
  containerClass: PropTypes.string,
  customClass: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  searchable: PropTypes.bool,
  required: PropTypes.bool,
  multi: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  icon: PropTypes.any,
  iconPosition: PropTypes.string,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  success: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  onDropdownOpen: PropTypes.func,
  onDropdownClose: PropTypes.func,
  customeLabel: PropTypes.string,
};

Selects.defaultProps = {
  containerClass: "",
  customClass: "",
  id: "",
  searchable: false,
  required: false,
  multi: false,
  label: "",
  placeholder: "Placeholder",
  icon: "",
  iconPosition: "left",
  options: [],
  disabled: false,
  success: "",
  error: "",
  onChange: () => {},
  onSearch: () => {},
  onDropdownOpen: () => {},
  onDropdownClose: () => {},
  customeLabel: "",
};

export const { Option } = Select;

export default React.memo(Selects);
