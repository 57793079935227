import React from "react";
import PropType from "prop-types";

import Buttons from "components/buttons";
import Tab from "components/tab";
import FollowUpOne from "./components/FollowUpOne";
import FollowUpTwo from "./components/FollowUpTwo";
import FollowUpThree from "./components/FollowUpThree";
import FollowUpFour from "./components/FollowUpFour";

const EditPesan = ({ setOpenPopupEditPesan }) => {
  return (
    <>
      <div className="border border-[#E0E0E0] rounded-2xl">
        <div className="p-4">
          <Tab
            defaultActiveKey="1"
            items={[
              {
                label: "Follow Up 1 (DP)",
                key: "1",
                children: <FollowUpOne />,
              },
              {
                label: "Follow Up 2 (Pelunasan)",
                key: "2",
                children: <FollowUpTwo />,
              },
              {
                label: "Follow Up 3 (Dokumen)",
                key: "3",
                children: <FollowUpThree />,
              },
              {
                label: "Follow Up 4 (Perlengkapan)",
                key: "4",
                children: <FollowUpFour />,
              },
            ]}
          />
        </div>
        <div className="divider"></div>
        <div className="flex flex-row gap-2 justify-end p-4">
          <Buttons
            text="Batal"
            customClass="btn-outline btn-large"
            onClick={() => setOpenPopupEditPesan(false)}
          />
          <Buttons text="Simpan" customClass="btn-primary btn-large" />
        </div>
      </div>
    </>
  );
};

export default React.memo(EditPesan);
