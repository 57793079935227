import Shalat from "assets/icons/Sholat.svg";
import AlquranIcon from "assets/icons/Quran Grey.svg";
import Qiblat from "assets/icons/Kiblat.svg";
import Masjid from "assets/icons/Masjid.svg";
import Doa from "assets/icons/Doa.svg";
import DoaActive from "assets/icons/colored/Doa-green.svg";

export const LayananIslamiMenuList = [
  {
    title: "Waktu Shalat",
    key: "shalat",
    icon: Shalat,
    order: 0,
  },
  {
    title: "Al Quran",
    key: "quran",
    icon: AlquranIcon,
    order: 1,
  },
  {
    title: "Arah Qiblat",
    key: "qiblat",
    icon: Qiblat,
    order: 2,
  },
  {
    title: "Masjid-Masjid",
    key: "masjid",
    icon: Masjid,
    order: 3,
  },
  {
    title: "Doa",
    key: "doa",
    icon: Doa,
    iconActive: DoaActive,
    order: 3,
  },
];
