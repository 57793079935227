import React from "react";
import { Grid } from "@material-ui/core";
import Buttons from "../../../../../../components/buttons";
import Cards from "../../../../../../components/cards";
import MoslemeTable from "../../../../../../components/Table";

import Logo1 from "../../../../../../assets/images/logo1.png";
import Logo2 from "../../../../../../assets/images/logo2.png";
import Badge from "../../../../../../components/badge";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { globalState } from "stores/actions/general";
import { columns } from "../../columns";

const ViewTravel = ({ setIsEdit }) => {
    const dispatch = useDispatch();
    const { dataTravel } = useSelector((state) => state.settingTravel);
    const [selectionType, setSelectionType] = useState("checkbox");
    const { global } = useSelector((state) => state.general);
    const { dataAllCabang, isLoading } = useSelector((state) => state.cabang);
    const total = dataAllCabang?.total_data;

    const pagination = {
        showSizeChanger: true,
        total: total,
        // current: currentpage,
        // pageSize: size,
        current: global?.currentpage,
        pageSize: global?.size,
        defaultPageSize: 10,
        pageSizeOptions: ["10", "20"],
    };

    const allCabang =
        dataAllCabang?.total_data !== 0 &&
        dataAllCabang?.data?.map((item, idx) => {
            return {
                ...item,
                key: idx + 1,
            };
        });

    return (
        <Cards containerClass="!p-0">
            <div className="flex flex-row justify-between items-center p-4">
                <div className="text-[20px] font-semibold">
                    Informasi Travel
                </div>
                <Buttons
                    text="Edit Data"
                    customClass="btn-primary btn-small"
                    onClick={() => setIsEdit(true)}
                />
            </div>
            <div className="divider"></div>
            <div className="p-4">
                <div className="border-[1px] border-[#E0E0E0] soft-shadow rounded-[16px] p-4">
                    <div className="text-[16px] font-semibold">Logo Travel</div>
                    <div className="mt-4">
                        <Grid container spacing={2}>
                            <Grid item md={4}>
                                <div className="text-[16px] text-[#4F4F4F] font-semibold">
                                    Logo 1
                                </div>
                                <div className="bg-[#F2F2F2] min-h-[190px] rounded-xl w-full p-[52px] mt-2 flex items-center">
                                    <img
                                        className="m-auto"
                                        src={dataTravel?.logo1}
                                        alt="logo1"
                                    />
                                </div>
                            </Grid>
                            <Grid item md={4}>
                                <div className="text-[16px] text-[#4F4F4F] font-semibold">
                                    Logo 2
                                </div>
                                <div className="bg-[#F2F2F2] min-h-[190px] rounded-xl w-full p-[52px] mt-2 flex items-center">
                                    <img
                                        className="m-auto"
                                        src={dataTravel?.logo2}
                                        alt="logo2"
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <div className="border-[1px] border-[#E0E0E0] soft-shadow rounded-[16px] p-4 mt-4">
                    <div className="text-[16px] font-semibold">
                        Informasi Umum
                    </div>
                    <div className="flex flex-row gap-2 mt-4">
                        <div className="min-w-[9%]">Nama Travel</div>
                        <div className="min-w-[1%]">:</div>
                        <div className="font-semibold w-full">
                            {dataTravel?.nama_travel}
                        </div>
                    </div>
                    <div className="flex flex-row gap-2 mt-4">
                        <div className="min-w-[9%]">Deskripsi</div>
                        <div className="min-w-[1%]">:</div>
                        <div className="font-semibold w-full">
                            Ini Adalah Caption. Lorem ipsum dolor sit amet
                            consectetur. Nullam et mi molestie mi in aliquet
                            praesent cursus luctus.
                        </div>
                    </div>
                </div>
                <div className="border-[1px] border-[#E0E0E0] soft-shadow rounded-[16px] p-4 mt-4">
                    <div className="text-[16px] font-semibold">
                        Legalitas Travel
                    </div>
                    <div className="mt-4">
                        <Grid container spacing={2}>
                            {dataTravel?.data_file_legalitas.map(
                                (item, index) => (
                                    <Grid item md={4}>
                                        <div className="text-[16px] text-[#4F4F4F] font-semibold">
                                            File {index + 1}
                                        </div>
                                        <div
                                            onClick={() =>
                                                window.open(item.path)
                                            }
                                            className="!bg-[#F2F2F2] cursor-pointer min-h-[190px] rounded-xl w-full p-[52px] mt-2 flex items-center relative"
                                        >
                                            <div className="p-4 absolute bottom-0 left-0 right-0 bg-[#FFDCDC] rounded-bl-xl rounded-br-xl">
                                                <div className="text-[16px] font-semibold">
                                                    {item.name}
                                                </div>
                                                <div className="text-[12px] text-[#4F4F4F] mt-1">
                                                    Diedit 2 hari yang lalu
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                )
                            )}
                        </Grid>
                    </div>
                </div>
                <div className="border-[1px] border-[#E0E0E0] soft-shadow rounded-[16px] p-4 mt-4">
                    <div className="text-[16px] font-semibold">List Cabang</div>
                    <div className="border mt-2 rounded">
                        <MoslemeTable
                            expandable={false}
                            columns={columns(dispatch)}
                            data={allCabang}
                            withSelection={false}
                            pagination={pagination}
                            customPaginationClass="pr-4"
                            loading={isLoading}
                            onPageChange={(page) =>
                                dispatch(
                                    globalState({
                                        currentpage: page,
                                        size: global.size,
                                    })
                                )
                            }
                            onSizeChange={(currentpage, pageSize) =>
                                dispatch(
                                    globalState({
                                        currentpage: currentpage,
                                        size: pageSize,
                                    })
                                )
                            }
                        />
                    </div>
                </div>
            </div>
        </Cards>
    );
};

export default React.memo(ViewTravel);
