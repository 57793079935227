import { handleError, handleErrorBE } from "stores/actions/errorGeneral";
import {
  SET_LOADING,
  CLEAR_ERROR,
  SET_ERROR,
  SET_CATEGORY_ARTICLE,
  SET_ADD_CATEGORY_ARTICLE,
  SET_DELETE_CATEGORY_ARTICLE,
  SET_SUCCESS,
} from "./actionTypes";
import { AdminKategoriArtikelAPI } from "utils/api/apps/artikel";

export const setLoading = () => ({
  type: SET_LOADING,
});

export const setSuccess = () => ({
  type: SET_SUCCESS,
});

export const clearError = () => ({
  type: CLEAR_ERROR,
});

export const setError = (payload) => ({
  type: SET_ERROR,
  payload,
});

export const setCategoryArticle = (payload) => ({
  type: SET_CATEGORY_ARTICLE,
  payload,
});

export const setAddCategoryArticle = (payload, edit) => ({
  type: SET_ADD_CATEGORY_ARTICLE,
  payload,
  edit,
});

export const setDeleteCategoryArticle = (payload) => ({
  type: SET_DELETE_CATEGORY_ARTICLE,
  payload,
});

const handleFetchCategoryArticle = (payload) => {
  return async (dispatch, getState) => {
    try {
      dispatch(clearError());
      dispatch(setLoading());
      const response = await AdminKategoriArtikelAPI.getListCategory(payload);
      dispatch(setLoading(false));
      if (response.status === 200) {
        dispatch(setCategoryArticle(response.data));
      } else {
        dispatch(handleErrorBE(response));
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

const handleAddCategoryArticle = (payload) => {
  let edit = payload.edit ? true : false;
  return async (dispatch, getState) => {
    try {
      dispatch(clearError());
      dispatch(setLoading(true));
      const response = await AdminKategoriArtikelAPI.postAddCategory(payload);
      dispatch(setLoading(false));
      if (response.status === 200) {
        dispatch(setAddCategoryArticle(response.data, edit));
        return response.status;
      } else {
        dispatch(handleErrorBE(response));
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

const handleDeleteCategoryArticle = (payload) => {
  return async (dispatch, getState) => {
    try {
      dispatch(clearError());
      dispatch(setLoading(true));
      const response = await AdminKategoriArtikelAPI.deleteCategory(payload);
      dispatch(setLoading(false));
      if (response.status === 200) {
        dispatch(setDeleteCategoryArticle(payload));
      } else {
        dispatch(handleErrorBE(response));
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

const ArticleCategoryAction = {
  fetchData: handleFetchCategoryArticle,
  postData: handleAddCategoryArticle,
  deleteData: handleDeleteCategoryArticle,
};

export default ArticleCategoryAction;
