import Buttons from "components/buttons";
import Badge from "components/badge";
import EditIcon from "assets/icons/edit.svg";
import TrashIcon from "assets/icons/trash.svg";
import LocationIcon from "assets/icons/location-black.svg";
import CalendarIcon from "assets/icons/colored/calendar-icon.svg";
import ClockIcon from "assets/icons/colored/clock-icon.svg";
import Places from "components/map";
import { useState } from "react";
import moment from "moment";

const ViewItem = ({
  data,
  setIsEdit,
  removeItem,
  generalInfo,
  onClickEditGeneralInfo,
}) => {
  const [address, setAddress] = useState("");

  return (
    <>
      <div className="w-full flex justify-between">
        <Badge
          label={data.id}
          backgroundColor="#E0E0E0"
          color="#141414"
          customClass="capitalize w-[34px]"
        />

        <div className="flex gap-4">
          <button
            onClick={() => {
              if (generalInfo) {
                onClickEditGeneralInfo(data);
              } else {
                setIsEdit(true);
              }
            }}
          >
            <img src={EditIcon} alt="edit icon" width={22} />
          </button>
          <button onClick={() => removeItem(data.id)}>
            <img src={TrashIcon} alt="trash icon" width={22} />
          </button>
        </div>
      </div>
      <div className="w-full flex justify-between">
        <div className="mt-4">
          <h4 className="font-bold mb-1">Koordinat Lokasi</h4>
          <p className="flex gap-1">
            <img src={LocationIcon} alt="location icon" />
            {data.koordinat.lat ? data.lokasi : "-"}
          </p>
          <h4 className="mt-4 mb-1 font-bold">Pelaksanaan</h4>
          <div className="flex gap-3">
            <div className="flex gap-1">
              <img src={CalendarIcon} alt="calendar icon" />
              {data.tanggal_pelaksanaan
                ? moment(data.tanggal_pelaksanaan)
                    .locale("id")
                    .format("dddd, D MMMM YYYY")
                : "-"}
            </div>
            <div className="flex gap-1">
              <img src={ClockIcon} alt="clock icon" />
              {data.waktu_pelaksanaan ? data.waktu_pelaksanaan : "-"}
            </div>
          </div>
          <Buttons
            text="Kirim Undangan"
            customClass="btn-outline btn-small !w-fit mt-3"
          />
        </div>
        <div className="w-1/2 rounded-lg shadow-lg p-[8px]  mt-5">
          {data.koordinat.lat ? (
            <Places
              style={{
                borderRadius: "10px",
                width: "100%",
                height: "120px",
              }}
              withSearch={false}
              selected={data.koordinat}
              address={address}
              setAddress={(e) => setAddress(e)}
            />
          ) : (
            <div className="bg-gray-100 w-full h-[120px] rounded-md"></div>
          )}
        </div>
      </div>
    </>
  );
};

export default ViewItem;
