import React, { useState } from "react";
import { Grid } from "@material-ui/core";

import Badge from "components/badge";
import Buttons from "components/buttons";
import Popup from "components/popup";
import EditPesan from "./components/EditPesan";

import Photo from "assets/images/Photo.png";
import IconEdit from "assets/icons/edit.svg";
import IconSMS from "assets/icons/sms.svg";
import IconWhatsapp from "assets/icons/whatsapp.svg";
import IconReceipt from "assets/icons/Receipt.svg";
import ImageCard from "assets/images/image-card.jpg";
import Tanggal from "assets/icons/Tanggal.svg";
import {
  IcSeatTerisi,
  IcSeatTersisa,
  IcTotalSeat,
} from "assets/icons/dashboard";

const BookingDetailHeader = () => {
  const [openPopupEditPesan, setOpenPopupEditPesan] = useState(false);

  return (
    <>
      <Popup
        open={openPopupEditPesan}
        handleClose={() => setOpenPopupEditPesan(false)}
        title="Edit Pesan Follow Up"
        width="1080px"
        children={<EditPesan setOpenPopupEditPesan={setOpenPopupEditPesan} />}
      />
      <div className="card-detail-booking-header">
        <Grid container spacing={4}>
          <Grid item md={9}>
            <div className="card-detail-booking-header-pink flex flex-row">
              <div className="flex flex-row w-[70%]">
                <img
                  className="card-detail-booking-header-pink-avatar"
                  src={Photo}
                  alt="user-img"
                />
                <div className="ml-3">
                  <div className="flex flex-row gap-2 items-center">
                    <div className="font-semibold text-[#FFFFFF] text-[20px]">
                      Tn. Dimas Andreyean Pradana Putra
                    </div>
                    <div className="bg-[#FFFFFF] rounded-lg p-2">
                      <img
                        className="w-[20px] h-[20px]"
                        src={IconEdit}
                        alt="user-img"
                      />
                    </div>
                  </div>
                  <div className="flex flex-row gap-2 mt-2">
                    <Badge label="Agen Cabang Daerah" type="green" />
                    <Badge label="Pekanbaru" type="dark purple" />
                  </div>
                </div>
              </div>
              <div className="card-detail-booking-header-pink-transparent w-[30%]">
                <div className="flex flex-row justify-between">
                  <div className="text-[#FFFFFF]">Waktu Booking:</div>
                  <div className="bg-[#FFFFFF] rounded-lg p-2">
                    <img
                      className="w-[20px] h-[20px]"
                      src={IconEdit}
                      alt="user-img"
                    />
                  </div>
                </div>
                <div className="text-[16px] text-[#FFFFFF] font-semibold">
                  12 Agustus 2025 12.30 WIB
                </div>
              </div>
            </div>

            <div className="flex flex-row gap-4">
              <div className="card-detail-booking-header-pink mt-2 w-[25%]">
                <div className="flex flex-row justify-between">
                  <div className="text-[#FFFFFF]">Follow Up:</div>
                  <div className="bg-[#FFFFFF] rounded-lg p-2 cursor-pointer">
                    <img
                      className="w-[20px] h-[20px]"
                      src={IconEdit}
                      alt="user-img"
                      onClick={() => setOpenPopupEditPesan(true)}
                    />
                  </div>
                </div>
                <div className="flex flex-row mt-2">
                  <Buttons
                    text="1"
                    customClass="btn-white-light btn-small !w-fit"
                    icon={IconSMS}
                  />
                  <Buttons
                    text="2"
                    customClass="btn-white-light btn-small !w-fit ml-1"
                    icon={IconSMS}
                  />
                  <Buttons
                    text="3"
                    customClass="btn-white-light btn-small !w-fit ml-1"
                    icon={IconSMS}
                  />
                  <Buttons
                    text="4"
                    customClass="btn-white-light btn-small !w-fit ml-1"
                    icon={IconSMS}
                  />
                </div>
                <div className="flex flex-row mt-2">
                  <Buttons
                    text="1"
                    customClass="btn-white-light btn-small !w-fit"
                    icon={IconWhatsapp}
                  />
                  <Buttons
                    text="2"
                    customClass="btn-white-light btn-small !w-fit ml-1"
                    icon={IconWhatsapp}
                  />
                </div>
              </div>

              <div className="card-detail-booking-header-pink mt-2 w-[75%]">
                <div className="flex flex-row justify-between">
                  <div className="text-[#FFFFFF]">Tagihan:</div>
                  <div className="bg-[#FFFFFF] rounded-lg p-2">
                    <img
                      className="w-[20px] h-[20px]"
                      src={IconReceipt}
                      alt="user-img"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-4">
                  <div>
                    <div className="text-[16px] text-[#ffffff]">
                      Total Biaya :
                    </div>
                    <div className="text-[20px] text-[#ffffff] font-semibold">
                      Rp 135.000.000
                    </div>
                  </div>
                  <div>
                    <div className="text-[16px] text-[#ffffff]">
                      Sudah Dibayarkan :
                    </div>
                    <div className="text-[20px] text-[#ffffff] font-semibold">
                      Rp 35.000.000
                    </div>
                  </div>
                  <div>
                    <div className="text-[16px] text-[#ffffff]">Diskon :</div>
                    <div className="text-[20px] text-[#ffffff] font-semibold">
                      5.000.000
                    </div>
                  </div>
                  <div>
                    <div className="text-[16px] text-[#ffffff]">
                      Kekurangan :
                    </div>
                    <div className="text-[20px] text-[#ffffff] font-semibold">
                      Rp 95.000.000
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item md={3}>
            <div className="card-detail-booking-paket">
              <div
                className="card-detail-booking-image"
                style={{ backgroundImage: `url(${ImageCard})` }}
              ></div>
              <div className="p-4">
                <div className="flex flex-row gap-2 justify-between">
                  <div className="font-semibold text-[16px]">
                    Umrah Berkah Bersama Ust. Abduh Tuasikal
                  </div>
                  <div className="bg-[#FFFFFF] rounded-lg p-2">
                    <img
                      className="w-[20px] h-[20px]"
                      src={IconEdit}
                      alt="user-img"
                    />
                  </div>
                </div>
                <div className="flex flex-row mt-1">
                  <img src={Tanggal} alt="tanggal" />
                  <div>12 September 2025</div>
                </div>

                <div className="bg-[#CBF3F0] rounded-lg p-2 flex flex-row gap-8 w-fit mt-3">
                  <div className="flex flex-row items-center gap-1">
                    <img src={IcTotalSeat} alt="" />
                    <div>45</div>
                  </div>
                  <div className="flex flex-row items-center gap-1">
                    <img src={IcSeatTerisi} alt="" />
                    <div>20</div>
                  </div>
                  <div className="flex flex-row items-center gap-1">
                    <img src={IcSeatTersisa} alt="" />
                    <div>25</div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default React.memo(BookingDetailHeader);
