import axios from "axios";
import {
  BASE_URL_ENDPOINT,
  BASE_URL_ENDPOINT_V2,
} from "../../../../constant/constanta";

export const getAllPenerbanganService = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/paket/get-penerbangan`, payload);

export const getAllPenerbanganDomesticService = (id) =>
  axios.get(`${BASE_URL_ENDPOINT_V2}/admin/package/${id}/flight/domestic`);
export const updatedMaskapaiPenerbanganService = (id, payload) =>
  axios.post(
    `${BASE_URL_ENDPOINT_V2}/admin/package/${id}/set-airline`,
    payload
  );

export const editPenerbanganDomesticService = (id, payload) =>
  axios.post(
    `${BASE_URL_ENDPOINT_V2}/admin/package/${id}/flight/domestic/update`,
    payload
  );

export const storePenerbanganInternationService = (id, payload) =>
  axios.post(
    `${BASE_URL_ENDPOINT_V2}/admin/package/${id}/flight/international`,
    payload
  );

export const getAllPenerbanganInternationalService = (id) =>
  axios.get(`${BASE_URL_ENDPOINT_V2}/admin/package/${id}/flight/international`);
