import axios from "axios";
import {
  BASE_URL_ENDPOINT,
  BASE_URL_ENDPOINT_V2,
} from "../../../constant/constanta";

export const loginService = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT_V2}/admin/auth/login-email`, payload);

export const checkValidEmailAdminService = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT_V2}/admin/auth/check-email`, payload);

export const emailForgotPasswordService = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/request-forgot-password-admin`, payload);

export const verificationOTPService = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/verify-forgot-password-admin`, payload);

export const submitChangePasswordService = (payload) =>
  axios.post(
    `${BASE_URL_ENDPOINT}/change-password-after-forgot-password-admin`,
    payload
  );
export const checkNumberPhoneAdminServiceV2 = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT_V2}/admin/auth/check-phone-number`, payload);
export const loginWithPhoneNumberServiceV2 = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT_V2}/admin/auth/login-phone-number`, payload);

export const checkAkunForgetPasswordServiceV2 = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT_V2}/admin/auth/forgot/account`, payload);

export const checkOTPForgetPasswordServiceV2 = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT_V2}/admin/auth/forgot/check-otp`, payload);

export const ForgetPasswordServiceV2 = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT_V2}/admin/auth/forgot/password`, payload);
