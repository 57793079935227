import styled from "styled-components";

const CardsStyle = styled.div`
  background: #ffffff;
  box-shadow: 0px 10px 16px -6px rgba(0, 0, 0, 0.04),
    0px 4px 80px -4px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  padding: 16px;
  padding-top: 12px;

  &.card-table {
    padding: 0;
    padding-bottom: 16px;
    /* margin-right: 16px; */
    border-bottom: 1px solid #e0e0e0;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 2px 8px rgba(0, 0, 0, 0.04);
    border-radius: 16px 16px 0px 0px;

    .card-table-header {
      padding: 16px;
    }
  }

  &.card-small {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1), 0px 2px 16px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 16px;
  }

  &.card-border {
    background: #ffffff;
    border: 1px solid rgba(64, 72, 82, 0.1);
    border-radius: 8px;
    box-shadow: none;
  }
`;

export default CardsStyle;
