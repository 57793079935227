import React, { useState, useCallback } from "react";
import "./Perlengkapan.scss";
import IconEdit from "../../../../../assets/icons/colored/Edit-white.svg";
import IconDownload from "../../../../../assets/icons/colored/download.svg";
import Popup from "components/popup";
import PerlengkapanPopup from "./PerlengkapanPopup";
import CheckIcon from "../../../../../assets/icons/Checklist-gray.svg";
import { useDispatch } from "react-redux";
import { saveDataPerlengkapan } from "stores/actions/paket/perlengkapan";

const PerlengkapanItem = ({ text }) => (
  <div className="flex flex-row items-center py-[8px] px-[12px] gap-[2px] relative z-[9999999]">
    <div>
      <img src={CheckIcon} className="w-[16px] h-[16px]" />
    </div>
    <div>
      <p>{text}</p>
    </div>
  </div>
);

const PerlengkapanJamaah = () => {
  const [open, setOpen] = useState(false);
  const [opensecond, setOpensecond] = useState(false);
  const [perlengkapanDetail, setPerlengkapanDetail] = useState();
  const dispatch = useDispatch();

  const handleCheckboxPerlengkapan = (name, value) => {
    const newValue = { ...perlengkapanDetail, [name]: ~~value };
    setPerlengkapanDetail(newValue);
    dispatch(saveDataPerlengkapan(newValue));
    console.log(name, value);
  };

  const handleClosePopup = useCallback(() => {
    setOpen(false);
  }, []);
  const handleClosePopupSecond = useCallback(() => {
    setOpensecond(false);
  }, []);

  const items = [
    "Koper Bagasi",
    "Tas Pasport",
    "Kain Seragam",
    "Pin",
    "Kain Ihram",
    "Mukena",
    "Jilbab",
    "Buku Manasik",
    "Gantungan Doa",
    "Tas Sandal",
    "Syal Haji",
  ];

  return (
    <>
      <div className="">
        <div className="flex flex-col items-start w-[286px] h-full perlengkapan-shadow">
          <div className="w-[286px] bg-[#FAAB36] rounded-lg flex flex-row justify-between items-center p-2">
            <div>
              <p className="font-[16px] text-white font-semibold">
                Perlengkapan Jamaah
              </p>
            </div>
            <div className="flex flex-row items-center gap-[8px]">
              <img src={IconEdit} alt="icon edit" />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-[8px] bg-white w-full h-fit absolute">
          {items.map((text) => (
            <PerlengkapanItem key={text} text={text} />
          ))}
        </div>
      </div>
    </>
  );
};

export default PerlengkapanJamaah;
