import React from "react";

import Tab from "components/tab";
import Inputs from "components/form/inputs";
import Penerbangan from "./components/Penerbangan";
import Bus from "./components/Bus";

import IconSearch from "assets/icons/search-normal.svg";
import IconAirplane from "assets/icons/colored/airplane-3-icon.svg";
import IconBusInactive from "assets/icons/colored/bus-inactive-icon.svg";

const BlockseatTabs = [
  {
    label: (
      <span className="flex gap-1">
        <img src={IconAirplane} alt="icon" />
        Pesawat 1 <span className="txt-green font-bold">(72)</span>
      </span>
    ),
    key: "1",
    children: <Penerbangan />,
  },
  {
    label: (
      <span className="flex gap-1">
        <img src={IconBusInactive} alt="icon" />
        Bus 1(36)
      </span>
    ),
    key: "2",
    children: <Bus />,
  },
  {
    label: (
      <span className="flex gap-1">
        <img src={IconBusInactive} alt="icon" />
        Bus 2 (22)
      </span>
    ),
    key: "3",
    children: "Tab 3",
  },
];

const BookingDetailBlockseat = () => {
  return (
    <>
      <div className="p-5 border-b relative">
        <div>
          <h2 className="font-semibold mb-2">Tipe Transportasi</h2>

          <Tab defaultActiveKey="1" items={BlockseatTabs} />
        </div>
        <div className="absolute top-5 right-5">
          <Inputs
            id="search"
            placeholder="Search"
            customClass="!px-3 !bg-white !border !border-solid !border-gray-300 !rounded-lg !py-[6px] !pl-[42px] !max-w-40"
            iconPosition="left"
            icon={IconSearch}
          />
        </div>
      </div>
    </>
  );
};

export default React.memo(BookingDetailBlockseat);
