import React from "react";
import IconJamPasir from "assets/icons/Jam-Pasir.svg";

const Tabungan = () => {
  return (
    <div className="flex flex-row justify-center items-center w-full h-full bg-white py-[10%]">
      <div className="flex flex-col h-full">
        <img src={IconJamPasir} alt="jam_pasir" />
        <div className="text-[#141414] text-[14px] font-normal text-center font-sans">
          Tabungan masih dalam pengerjaan
        </div>
      </div>
    </div>
  );
};

export default Tabungan;
