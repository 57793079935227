import React, { useState } from "react";

import MoslemeTable from "components/Table";
import Badge from "components/badge";

import AvatarRed from "assets/images/Avatar-ikhwan-red.svg";
import Buttons from "components/buttons";

import OutsideClickHandler from "react-outside-click-handler";
import Progressbars from "components/progressbars";
import PopUpFoto from "./foto/PopUpFoto";
import PopUpAkteKelahiran from "./akte/PopUpAkteKelahiran";
import PopUpKtp from "./ktp/PopUpKtp";
import PopUpPassport from "./passport/PopUpPassport";
import PopUpKeluarga from "./keluarga/PopUpKeluarga";
import PopUpVaksin from "./vaksin/PopUpVaksin";
import PopUpMiningitis from "./miningitis/PopUpMiningitis";

const PaketLaporanManifest = () => {
  // Loading
  const [isLoading, setIsLoading] = useState(false);
  // Expaneble
  const [isExpand, setIsExpand] = useState(false);
  // Selection & type state
  const [selectionType, setSelectionType] = useState("checkbox");
  const [withSelection, setWithSelection] = useState(false);
  const [type, setType] = useState("");
  const [isEditShow, setIsEditShow] = useState(false);

  //show dropdown
  const [isShowDropdown, setIsShowDropdown] = useState(false);
  const [id, setId] = useState(null);

  // Pagination state
  const [currentpage, setCurrentpage] = useState(1);
  const [size, setSize] = useState(10);
  const total = 100;

  const handleDropdown = (id) => {
    setIsShowDropdown(!isShowDropdown);
    if (!isShowDropdown) {
      setId(id);
    } else {
      setId(null);
    }
  };

  const handleClickEdit = (e, type) => {
    e.preventDefault();
    setType(type);
    setIsEditShow(true);
    setIsShowDropdown(false);
  };

  const renderManifestDropdown = () => {
    return (
      <>
        <OutsideClickHandler onOutsideClick={handleDropdown}>
          <div className="fixed right-[148px] z-[10000] w-[310px] shadow-lg">
            <div className="bg-[#FAAB36] p-3 rounded-t-lg">
              <p className="text-white font-extrabold text-lg">Manifest</p>
            </div>
            <div className="bg-white rounded-b-lg p-3 max-h-[250px] overflow-y-auto">
              <div className="shadow-lg rounded-lg bg-white px-3 py-2 mb-2">
                <Progressbars
                  percent={70}
                  status="exception"
                  isEditIcon={true}
                  onClick={(e) => handleClickEdit(e, "foto")}
                  label="Foto 4x6"
                  info="2/3"
                  strokeColor="#EA3634"
                />
              </div>
              <div className="shadow-lg rounded-lg bg-white px-3 py-2 mb-2">
                <Progressbars
                  percent={70}
                  status="exception"
                  label="KTP"
                  isEditIcon={true}
                  onClick={(e) => handleClickEdit(e, "ktp")}
                  info="2/3"
                  strokeColor="#EA3634"
                />
              </div>
              <div className="shadow-lg rounded-lg bg-white px-3 py-2 mb-2">
                <Progressbars
                  percent={100}
                  status="exception"
                  label="Passport"
                  onClick={(e) => handleClickEdit(e, "passport")}
                  info="3/3"
                  isEditIcon={true}
                  strokeColor="#E2A246"
                />
              </div>
              <div className="shadow-lg rounded-lg bg-white px-3 py-2 mb-2">
                <Progressbars
                  percent={100}
                  status="exception"
                  label="Keluarga"
                  onClick={(e) => handleClickEdit(e, "keluarga")}
                  info="3/3"
                  isEditIcon={true}
                  strokeColor="#E2A246"
                />
              </div>
              <div className="shadow-lg rounded-lg bg-white px-3 py-2 mb-2">
                <Progressbars
                  percent={70}
                  status="exception"
                  label="Vaksin Covid"
                  onClick={(e) => handleClickEdit(e, "vaksin")}
                  info="1/3"
                  isEditIcon={true}
                  strokeColor="#EA3634"
                />
              </div>
              <div className="shadow-lg rounded-lg bg-white px-3 py-2 mb-2">
                <Progressbars
                  percent={100}
                  status="exception"
                  label="Kartu Miningitis"
                  onClick={(e) => handleClickEdit(e, "miningitis")}
                  info="1/3"
                  isEditIcon={true}
                  strokeColor="#E2A246"
                />
              </div>
              <div className="shadow-lg rounded-lg bg-white px-3 py-2">
                <Progressbars
                  percent={100}
                  status="exception"
                  label="Akte Lahir"
                  onClick={(e) => handleClickEdit(e, "akte")}
                  info="1/3"
                  isEditIcon={true}
                  strokeColor="#E2A246"
                />
              </div>
            </div>
          </div>
        </OutsideClickHandler>
      </>
    );
  };

  const renderManifestBtn = (idParam) => {
    return (
      <>
        <div
          onClick={() => handleDropdown(idParam)}
          className="relative flex w-max cursor-pointer items-center bg-[#D8E1BE] border border-[#71BE0E] py-1 px-2 font-semibold rounded-lg"
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14.0007 13.5H7.33398C7.06065 13.5 6.83398 13.2733 6.83398 13C6.83398 12.7267 7.06065 12.5 7.33398 12.5H14.0007C14.274 12.5 14.5007 12.7267 14.5007 13C14.5007 13.2733 14.274 13.5 14.0007 13.5Z"
              fill="#141414"
            />
            <path
              d="M14.0007 8.83203H7.33398C7.06065 8.83203 6.83398 8.60536 6.83398 8.33203C6.83398 8.0587 7.06065 7.83203 7.33398 7.83203H14.0007C14.274 7.83203 14.5007 8.0587 14.5007 8.33203C14.5007 8.60536 14.274 8.83203 14.0007 8.83203Z"
              fill="#141414"
            />
            <path
              d="M14.0007 4.16797H7.33398C7.06065 4.16797 6.83398 3.9413 6.83398 3.66797C6.83398 3.39464 7.06065 3.16797 7.33398 3.16797H14.0007C14.274 3.16797 14.5007 3.39464 14.5007 3.66797C14.5007 3.9413 14.274 4.16797 14.0007 4.16797Z"
              fill="#141414"
            />
            <path
              d="M2.66695 4.83427C2.54029 4.83427 2.41362 4.7876 2.31362 4.6876L1.64695 4.02094C1.45362 3.8276 1.45362 3.5076 1.64695 3.31427C1.84029 3.12094 2.16029 3.12094 2.35362 3.31427L2.66695 3.6276L4.31362 1.98094C4.50695 1.7876 4.82695 1.7876 5.02029 1.98094C5.21362 2.17427 5.21362 2.49427 5.02029 2.6876L3.02029 4.6876C2.92029 4.7876 2.79362 4.83427 2.66695 4.83427Z"
              fill="#141414"
            />
            <path
              d="M2.66695 9.49833C2.54029 9.49833 2.41362 9.45167 2.31362 9.35167L1.64695 8.685C1.45362 8.49167 1.45362 8.17167 1.64695 7.97833C1.84029 7.785 2.16029 7.785 2.35362 7.97833L2.66695 8.29167L4.31362 6.645C4.50695 6.45167 4.82695 6.45167 5.02029 6.645C5.21362 6.83833 5.21362 7.15833 5.02029 7.35167L3.02029 9.35167C2.92029 9.45167 2.79362 9.49833 2.66695 9.49833Z"
              fill="#141414"
            />
            <path
              d="M2.66695 14.1663C2.54029 14.1663 2.41362 14.1196 2.31362 14.0196L1.64695 13.353C1.45362 13.1596 1.45362 12.8396 1.64695 12.6463C1.84029 12.453 2.16029 12.453 2.35362 12.6463L2.66695 12.9596L4.31362 11.313C4.50695 11.1196 4.82695 11.1196 5.02029 11.313C5.21362 11.5063 5.21362 11.8263 5.02029 12.0196L3.02029 14.0196C2.92029 14.1196 2.79362 14.1663 2.66695 14.1663Z"
              fill="#141414"
            />
          </svg>
          <p className="mx-1">Manifest</p>
          <p>(3/10)</p>
        </div>
      </>
    );
  };

  const columns = [
    {
      title: "Foto",
      dataIndex: "photo",
      render: (img) => <img src={img} alt="user" />,
    },
    {
      title: "Nama Sesuai KTP",
      dataIndex: "name",
    },
    {
      title: "Jenis Kelamin",
      dataIndex: "gender",
    },
    {
      title: "Starting",
      dataIndex: "starting",
      render: (starting) => <Badge label={starting} />,
    },
    {
      title: "Jamaah Cabang",
      dataIndex: "branch",
      render: (branch) => <Badge label={branch} />,
    },
    {
      title: "Manifest",
      dataIndex: "key",
      render: (idParam) => (
        <div>
          {renderManifestBtn(idParam)}
          {isShowDropdown && idParam === id ? renderManifestDropdown() : null}
        </div>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      photo: AvatarRed,
      name: "Dimas Andreyan Prana Putra",
      gender: "Laki-laki",
      starting: "YIA - CGK",
      branch: "Yogyakarta",
      manifest: "Perlengkapan",
    },
    {
      key: "2",
      photo: AvatarRed,
      name: "Dimas Andreyan Prana Putra 1",
      gender: "Laki-laki",
      starting: "YIA - CGK",
      branch: "Yogyakarta",
      manifest: "Perlengkapan",
    },
    {
      key: "3",
      photo: AvatarRed,
      name: "Dimas Andreyan Prana Putra 2",
      gender: "Laki-laki",
      starting: "YIA - CGK",
      branch: "Yogyakarta",
      manifest: "Perlengkapan",
    },
  ];

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: total,
    current: currentpage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20"],
  };

  // func to set size pagination
  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setSize(pageSize);
  };

  // manipulated data with action
  const sourceData = data;

  return (
    <>
      {type === "foto" ? (
        <PopUpFoto show={isEditShow} setModal={setIsEditShow} />
      ) : type === "akte" ? (
        <PopUpAkteKelahiran show={isEditShow} setModal={setIsEditShow} />
      ) : type === "ktp" ? (
        <PopUpKtp show={isEditShow} setModal={setIsEditShow} />
      ) : type === "passport" ? (
        <PopUpPassport show={isEditShow} setModal={setIsEditShow} />
      ) : type === "keluarga" ? (
        <PopUpKeluarga show={isEditShow} setModal={setIsEditShow} />
      ) : type === "vaksin" ? (
        <PopUpVaksin show={isEditShow} setModal={setIsEditShow} />
      ) : type === "miningitis" ? (
        <PopUpMiningitis show={isEditShow} setModal={setIsEditShow} />
      ) : null}
      <div className="flex justify-end py-3 pr-3">
        <Buttons text="Save" customClass="btn-primary btn-small" />
      </div>
      <MoslemeTable
        loading={isLoading}
        columns={columns}
        data={sourceData}
        pagination={pagination}
        onPageChange={(page) => setCurrentpage(page)}
        onSizeChange={(currentpage, pageSize) =>
          onSizeChange(currentpage, pageSize)
        }
      />
    </>
  );
};

export default React.memo(PaketLaporanManifest);
