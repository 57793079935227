import {
  START_FETCHING_ADDITIONAL,
  SUCCESS_FETCHING_ADDITIONAL,
  ERROR_FETCHING_ADDITIONAL,
  SET_PAGE_ADDITIONAL,
  SET_SIZE_ADDITIONAL,
  SET_KEYWORD_ADDITIONAL,
  SET_TOTAL_ADDITIONAL,
  SET_ID_ROLE,
} from "../../../actions/paket/additional/actionTypes";

const statuslist = {
  idle: "idle",
  process: "process",
  success: "success",
  error: "error",
};

const initialState = {
  data: [],
  total: 100,
  per_page: 10,
  page_number: 1,
  sort_column: "",
  sort_order: "Desc",
  keyword: "",
  id_role: "0",
  status: statuslist.idle,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case START_FETCHING_ADDITIONAL:
      return { ...state, status: statuslist.process };

    case ERROR_FETCHING_ADDITIONAL:
      return { ...state, status: statuslist.error };

    case SUCCESS_FETCHING_ADDITIONAL:
      return {
        ...state,
        data: action.data,
        status: statuslist.success,
      };
    case SET_KEYWORD_ADDITIONAL:
      return { ...state, keyword: action.keyword };

    case SET_PAGE_ADDITIONAL:
      return { ...state, page_number: action.page };

    case SET_SIZE_ADDITIONAL:
      return { ...state, per_page: action.size };

    case SET_TOTAL_ADDITIONAL:
      return { ...state, total: action.total };

    case SET_ID_ROLE:
      return { ...state, id_role: action.id_role };

    default:
      return state;
  }
}
