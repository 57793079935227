import React, { useState } from "react";
import { Grid } from "@material-ui/core";

import Buttons from "components/buttons";
import Badge from "components/badge";
import LocationDetailHover from "./components/LocationDetailHover";
import Popup from "components/popup";
import LocationExportCSV from "./components/ExportCSV";

import IconCSV from "assets/icons/CSV.svg";
import IconEyeRed from "assets/icons/eye-red.svg";
import IconLocation from "assets/icons/location-black.svg";

const Location = () => {
  const [hoveredPaketIndex, setHoveredPaketIndex] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);

  const handlePaketCardHover = (index) => {
    setHoveredPaketIndex(index);
  };

  const handlePaketCardUnhover = () => {
    setHoveredPaketIndex(null);
  };

  const getContent = Array.from({ length: 4 }, (_, i) => (
    <Grid item md={3}>
      <div
        key={i}
        className=""
        onMouseEnter={() => handlePaketCardHover(i)}
        onMouseLeave={() => handlePaketCardUnhover()}
      >
        {hoveredPaketIndex === i && <LocationDetailHover />}
        <div className="bg-white soft-shadow rounded-lg p-4">
          <div className="flex flex-row justify-between items-center">
            <Badge className="h-max" label="Yogyakarta" />
            <Buttons
              text="Lihat"
              customClass="btn-outline btn-small !px-2 !py-[6px]"
              icon={IconEyeRed}
              iconPosition="right"
            />
          </div>
          <div className="border-[1px] border-[#4048521a] rounded-lg px-4 py-3 mt-6">
            <div className="bg-[#F3CFCE] rounded-lg px-4 py-1 w-min">
              <div className="txt-maroon font-medium">1</div>
            </div>
            <div className="flex flex-row gap-2 mt-2">
              <img src={IconLocation} alt="location" />
              <div>Kabupaten Sleman, Daerah Istimewa Yogyakarta</div>
            </div>
          </div>
          <div className="border-[1px] border-[#4048521a] rounded-lg px-4 py-3 mt-4">
            <div className="bg-[#F3CFCE] rounded-lg px-4 py-1 w-min">
              <div className="txt-maroon font-medium">2</div>
            </div>
            <div className="flex flex-row gap-2 mt-2">
              <img src={IconLocation} alt="location" />
              <div>Kabupaten Sleman, Daerah Istimewa Yogyakarta</div>
            </div>
          </div>
        </div>
      </div>
    </Grid>
  ));

  return (
    <>
      <Popup
        open={openPopup}
        handleClose={() => setOpenPopup(false)}
        title="Export CSV"
        width="1080px"
        children={<LocationExportCSV />}
      />
      <div className="flex flex-row justify-between items-center p-4 pt-0 mt-4">
        <div className="font-semibold text-[16px]">Daftar Cabang</div>
        <Buttons
          text="Export CSV"
          customClass="btn-outline btn-small"
          icon={IconCSV}
          onClick={() => setOpenPopup(true)}
        />
      </div>
      <div className="divider"></div>
      <div className="p-4">
        <Grid container spacing={2}>
          {getContent}
        </Grid>
      </div>
    </>
  );
};

export default React.memo(Location);
