import { handleError, handleErrorBE } from "stores/actions/errorGeneral";
import {
  CLEAR_ERROR,
  ERROR_FETCHING_REQUEST,
  SET_APPROVE_REQUEST,
  SET_LOADING,
  SET_REJECT_REQUEST,
  START_FETCHING_REQUEST,
  SUCCESS_FETCHING_REQUEST,
} from "./actionTypes";
import { CalculatorRequestHargaAPI } from "utils/api/calculator/request";

const startFetchingRequest = () => ({
  type: START_FETCHING_REQUEST,
});

const errorFetchingRequest = () => ({
  type: ERROR_FETCHING_REQUEST,
});

const successFetchingRequest = (payload) => ({
  type: SUCCESS_FETCHING_REQUEST,
  payload,
});

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const clearError = () => ({
  type: CLEAR_ERROR,
});

export const setApproveRequest = (payload, edit) => ({
  type: SET_APPROVE_REQUEST,
  payload,
});

export const setRejectRequest = (payload, id) => ({
  type: SET_REJECT_REQUEST,
  payload,
  id,
});

const handleFetchListRequest = (payload) => {
  return async (dispatch, getState) => {
    try {
      dispatch(clearError());
      dispatch(setLoading(true));
      dispatch(startFetchingRequest());
      const response = await CalculatorRequestHargaAPI.getList(payload);
      dispatch(setLoading(false));
      if (response.status === 200) {
        dispatch(successFetchingRequest(response.data));
        return response.status;
      } else {
        dispatch(handleErrorBE(response));
      }
    } catch (error) {
      dispatch(errorFetchingRequest());
      dispatch(handleError(error));
    }
  };
};

const handleApproveRequest = (payload) => {
  return async (dispatch, getState) => {
    try {
      dispatch(clearError());
      dispatch(setLoading(true));
      const response = await CalculatorRequestHargaAPI.approveData(payload);
      dispatch(setLoading(false));
      if (response.status === 200) {
        dispatch(setApproveRequest(response.data));
        dispatch(setLoading(false));
        return response.status;
      } else if (response.status === 401) {
        dispatch(setLoading(false));
        window.location.href = "/login";
      } else {
        dispatch(setLoading(false));
        dispatch(handleErrorBE(response));
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(handleError(error));
    }
  };
};

const handleRejectRequest = (payload) => {
  return async (dispatch, getState) => {
    try {
      dispatch(clearError());
      dispatch(setLoading(true));
      const response = await CalculatorRequestHargaAPI.rejectData(payload);
      dispatch(setLoading(false));
      if (response.status === 200) {
        dispatch(setRejectRequest(response.data, payload));
        dispatch(setLoading(false));
        return response.status;
      } else {
        dispatch(setLoading(false));
        dispatch(handleErrorBE(response));
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(handleError(error));
    }
  };
};

const CalculatorRequestPaketAction = {
  fetchData: handleFetchListRequest,
  approveData: handleApproveRequest,
  rejectData: handleRejectRequest,
};

export default CalculatorRequestPaketAction;
