import React, { useEffect, useState } from "react";
import Inputs from "../../../../../../components/form/inputs";
import ImageField from "../../../../../../components/image_field";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Buttons from "../../../../../../components/buttons";
import { editDataMaskapai } from "../../../../../../stores/actions/datamaster";
import TextAreas from "components/form/textarea";
import RichTextEditor from "components/richTextEditor";

function EditInfo({
  valueName,
  valueCode,
  valueDescription,
  descriptionOnChange,
  formikOnChange,
  formikValue,
  handleSave,
}) {
  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item sm={12} md={6}>
        <ImageField
          name="logo"
          label="Logo Bandara"
          customContainer="!rounded-2xl"
          height="16rem"
          width="16rem"
          setFile={(file) => {
            formikValue("logo", file?.path);
          }}
        />
      </Grid>
      <Grid item sm={12} md={6}>
        <ImageField
          label="Thumbnail Bandara"
          customContainer="!rounded-2xl"
          height="16rem"
          width="100%"
          setFile={(file) => {
            formikValue("thumbnail", file?.path);
          }}
          name="thumbnail"
        />
      </Grid>

      <Grid item sm={12} md={6}>
        <Inputs
          id="name"
          name="name"
          defaultValue={valueName}
          label="Nama Bandara"
          placeholder="Masukan Nama Maskapai"
          onChange={formikOnChange}
        />
      </Grid>

      <Grid item sm={12} md={6}>
        <Inputs
          id="domestic_code"
          name="domestic_code"
          defaultValue={valueCode}
          label="Kode Domestik Bandara"
          placeholder="Masukan Kode"
          onChange={formikOnChange}
        />
      </Grid>

      <Grid item sm={12}>
        <RichTextEditor
          data={{ value: valueDescription }}
          onChange={descriptionOnChange}
        />
      </Grid>

      <div className="w-full flex justify-end pt-4 mt-4 items-center border-t border-gray-300">
        <div className="w-64">
          <Buttons
            onClick={handleSave}
            text="Simpan"
            customClass="btn-primary btn-large"
          />
        </div>
      </div>
    </Grid>
  );
}

export default EditInfo;
