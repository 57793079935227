import styled from "styled-components";

const InputsCurrencyStyle = styled.div`
  .custom-input-currency {
    padding: 16px;
    width: 100%;
    background: #f2f2f2;
    border-radius: 12px;
    border: 0;

    &::placeholder {
      color: #828282;
    }

    &:hover,
    &:active {
      background: #ffdcdc;
    }

    &:focus {
      box-shadow: none;
    }

    &:focus-visible {
      outline: none;
    }
  }

  .input-error {
    border: 1px solid #e82320;
  }

  .input-error-msg {
    color: #e82320;
    margin-left: 8px;
  }

  .input-success {
    border: 1px solid #97e03b;
  }

  .input-success-msg {
    color: #97e03b;
    margin-left: 8px;
  }

  .input-disabled {
    color: #828282;

    &:hover {
      cursor: not-allowed;
      background: #f2f2f2;
    }
  }

  .input-label {
    font-size: 16px;
    font-weight: 600;
    color: #4f4f4f;
    margin-bottom: 8px;
    height: 20px;

    &--disabled {
      color: #828282;
    }
  }
`;

export default InputsCurrencyStyle;
