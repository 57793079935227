import { useState } from "react";
import { makeStyles } from "@material-ui/core";

import Inputs from "../../../../../../components/form/inputs";
import Buttons from "../../../../../../components/buttons";
import { Trash } from "iconsax-react";
import InputsCurrency from "components/form/inputsCurrency";

const useStyle = makeStyles({
  step5: {
    "& .select-label": {
      fontSize: "16px",
      fontWeight: "600",
      color: "rgb(79, 79, 79)",
      marginBottom: "8px",
    },
  },
});

const Step5 = ({ payload, setPayload, handleCheckHargaPaket, loading }) => {
  const classes = useStyle();

  return (
    <div className={classes.step5}>
      <div className="select-label mb-6">Fee Cabang</div>
      <InputsCurrency
        label="Nominal Fee"
        placeholder="Masukkan nominal"
        containerClass="mt-4 mb-6"
        customClass="!pl-[42px]"
        currency
        id="dp"
        value={payload.branch_fee}
        onChange={(val) => {
          setPayload({
            ...payload,
            branch_fee: val,
          });
        }}
        // value={values.dp}
        // onBlur={handleBlur}
        // error={touched.dp && errors.dp && errors.dp}
        // onChange={handleChange}
      />
      <div className="flex justify-start">
        <div className="flex gap-5">
          <Buttons
            text="Cek Paket"
            customClass={`btn-secondary btn-medium w-fit ${
              payload.branch_fee === "" && "disabled"
            }`}
            disabled={payload.branch_fee === ""}
            onClick={handleCheckHargaPaket}
            loading={loading}
          />
          <div className="w-fit" />
        </div>
      </div>
    </div>
  );
};

export default Step5;
