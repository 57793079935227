import { useState, useCallback, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Radio, Space, Switch } from "antd";
import Popup from "../../../components/popup";
import Button from "../../../components/buttons";
import Inputs from "../../../components/form/inputs";
import Textareas from "../../../components/form/textarea";
import Selects from "../../../components/form/selects";
import ColorPicker from "../../../components/color_picker";
import useClickOutside from "../../../utils/helpers/useClickOutside";

import { Edit } from "iconsax-react";
import PermissionIcon from "../../../assets/icons/colored/permission-icon.svg";
import { getDataRoleV2Utama } from "stores/actions/role";

const AddRolePopup = ({
  open,
  handleClosePopup,
  handleOpenSecondPopup,
  roleList,
  selectedRole,
  setSelectedRole,
}) => {
  const { dataRole, isLoading, dataRoleV2Utama } = useSelector(
    (state) => state.role
  );
  const dispatch = useDispatch();

  useEffect(() => {
    const payloadV2 = {
      main: 1,
      page: 1,
      offset: 10,
      pagination: false,
    };

    dispatch(getDataRoleV2Utama(payloadV2));
  }, []);

  const [modalState, setModalState] = useState(
    selectedRole ? "Edit" : "Tambah"
  );

  const [options, setOptions] = useState([
    {
      value: "Admin",
      label: "admin",
    },
    {
      value: "Operator",
      label: "operator",
    },
  ]);

  const handleEditRole = useCallback((role) => {
    handleOpenSecondPopup();
    setSelectedRole(role);
  }, []);

  return (
    <>
      <Popup
        open={open}
        handleClose={handleClosePopup}
        title="Tambah Role"
        height="fit-content"
      >
        <div className="flex flex-col gap-3">
          {dataRoleV2Utama?.data?.data?.map((i) => (
            <div
              className="rounded-lg flex gap-2 py-4 px-5 text-sm font-bold cursor-pointer"
              style={{
                backgroundColor: i?.bg_color,
                color: i?.text_color,
              }}
              key={i.key}
              onClick={() => handleEditRole(i.action)}
            >
              <span>{i?.display_name}</span>

              <Edit size="16" />
            </div>
          ))}

          <Button
            text="Tambah Role Baru"
            customClass="btn-outline btn-medium mt-2"
            onClick={handleOpenSecondPopup}
          />
        </div>
      </Popup>
    </>
  );
};

export default AddRolePopup;
