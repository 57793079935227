import React from "react";

import Buttons from "components/buttons";
import Badge from "components/badge";

import IconCSV from "assets/icons/CSV.svg";
import IconTotalSeatWhite from "assets/icons/total-seat-white.svg";
import AvaIkhwan from "assets/icons/ava-ikhwan.svg";
import AvaAkhwat from "assets/icons/ava-akhwat.svg";
import IconSeatTerisi from "assets/icons/seat-terisi.svg";
import IconSeatTersisa from "assets/icons/seat-tersisa.svg";
import IconCabangPusat from "assets/icons/Cabang-Pusat.svg";

const PaketLaporanSeat = () => {
  return (
    <div className="bg-white border-[1px] border-[#4048521a] rounded-bl-lg rounded-br-lg">
      <div className="p-4">
        <div className="font-semibold text-[20px]">Seat</div>
      </div>
      <div className="divider"></div>
      <div className="p-4">
        <div className="flex flex-row justify-between gap-4">
          <div className="flex flex-col gap-4 bg-white border-[1px] border-[#4048521a] rounded-lg normal-shadow p-4">
            <div className="font-semibold text-[16px]">Semua Seat</div>
            <div className="flex flex-row items-center">
              <div className="!rounded-tl-lg !rounded-bl-lg !rounded-tr-none !rounded-br-none dark-grey-bg-shine p-4">
                <div className="flex flex-row items-center gap-3">
                  <div className="flex flex-row items-center gap-1">
                    <img src={IconTotalSeatWhite} alt="tripple" />
                    <div className="font-semibold text-white">Total</div>
                  </div>
                  <div className="txt-green text-[20px] font-semibold">
                    <span className="text-[16px]">20</span>/32
                  </div>
                </div>
              </div>
              <div className="flex flex-row gap-6 p-4 border-[1px] border-[#4048521a] rounded-tr-lg rounded-br-lg">
                <div className="flex flex-row gap-2">
                  <img src={AvaIkhwan} alt="ikhwan" />
                  <div className="txt-green font-semibold text-[16px]">10</div>
                </div>
                <div className="flex flex-row gap-2">
                  <img src={AvaAkhwat} alt="akhwat" />
                  <div className="txt-green font-semibold text-[16px]">10</div>
                </div>
              </div>
            </div>
            <div className="flex flex-row items-center gap-2">
              <div className="flex flex-row">
                <img src={IconSeatTerisi} alt="terisi" />
                <div className="txt-blue font-medium">Terisi</div>
                <div className="txt-green font-semibold ml-2">20</div>
              </div>
              <div className="flex w-px h-full bg-[#4048521a]"></div>
              <div className="flex flex-row">
                <img src={IconSeatTersisa} alt="terisi" />
                <div className="txt-maroon font-medium">Tersisa</div>
                <div className="txt-green font-semibold ml-2">12</div>
              </div>
            </div>
          </div>
          <Buttons
            text="Export CSV"
            customClass="btn-outline btn-small"
            icon={IconCSV}
          />
        </div>
        <div className="mt-5">
          <div className="grid grid-cols-4 gap-4">
            <div className="minim-shadow rounded-lg bg-white">
              <div className="bg-[#FBC8C8] px-4 py-5 flex flex-row gap-2 rounded-tl-lg rounded-tr-lg">
                <img src={IconCabangPusat} alt="cabang" />
                <Badge label="Yogyakarta" />
              </div>
              <div className="divider"></div>
              <div className="p-4">
                <div className="border-[1px] border-[#4048521a] rounded-lg">
                  <div className="flex flex-row px-4 py-2">
                    <img src={IconSeatTerisi} alt="terisi" />
                    <div className="txt-blue text-[16px] font-semibold ml-1">
                      Seat Terisi
                    </div>
                    <div className="txt-green text-[16px] font-semibold ml-2">
                      8
                    </div>
                  </div>
                  <div className="divider"></div>
                  <div className="flex flex-row px-4 py-3 gap-4">
                    <div className="flex flex-row gap-2">
                      <img src={AvaIkhwan} alt="ikhwan" />
                      <div className="txt-green font-semibold text-[16px]">
                        10
                      </div>
                    </div>
                    <div className="flex flex-row gap-2">
                      <img src={AvaAkhwat} alt="akhwat" />
                      <div className="txt-green font-semibold text-[16px]">
                        10
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="minim-shadow rounded-lg bg-white">
              <div className="bg-[#FBE7C8] px-4 py-5 flex flex-row gap-2 rounded-tl-lg rounded-tr-lg">
                <Badge label="Solo" />
              </div>
              <div className="divider"></div>
              <div className="p-4">
                <div className="border-[1px] border-[#4048521a] rounded-lg">
                  <div className="flex flex-row px-4 py-2">
                    <img src={IconSeatTerisi} alt="terisi" />
                    <div className="txt-blue text-[16px] font-semibold ml-1">
                      Seat Terisi
                    </div>
                    <div className="txt-green text-[16px] font-semibold ml-2">
                      8
                    </div>
                  </div>
                  <div className="divider"></div>
                  <div className="flex flex-row px-4 py-3 gap-4">
                    <div className="flex flex-row gap-2">
                      <img src={AvaIkhwan} alt="ikhwan" />
                      <div className="txt-green font-semibold text-[16px]">
                        10
                      </div>
                    </div>
                    <div className="flex flex-row gap-2">
                      <img src={AvaAkhwat} alt="akhwat" />
                      <div className="txt-green font-semibold text-[16px]">
                        10
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="minim-shadow rounded-lg bg-white">
              <div className="bg-[#F3ECCA] px-4 py-5 flex flex-row gap-2 rounded-tl-lg rounded-tr-lg">
                <Badge label="Surabaya" />
              </div>
              <div className="divider"></div>
              <div className="p-4">
                <div className="border-[1px] border-[#4048521a] rounded-lg">
                  <div className="flex flex-row px-4 py-2">
                    <img src={IconSeatTerisi} alt="terisi" />
                    <div className="txt-blue text-[16px] font-semibold ml-1">
                      Seat Terisi
                    </div>
                    <div className="txt-green text-[16px] font-semibold ml-2">
                      8
                    </div>
                  </div>
                  <div className="divider"></div>
                  <div className="flex flex-row px-4 py-3 gap-4">
                    <div className="flex flex-row gap-2">
                      <img src={AvaIkhwan} alt="ikhwan" />
                      <div className="txt-green font-semibold text-[16px]">
                        10
                      </div>
                    </div>
                    <div className="flex flex-row gap-2">
                      <img src={AvaAkhwat} alt="akhwat" />
                      <div className="txt-green font-semibold text-[16px]">
                        10
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="minim-shadow rounded-lg bg-white">
              <div className="bg-[#E7E7E6] px-4 py-5 flex flex-row gap-2 rounded-tl-lg rounded-tr-lg">
                <Badge label="Jakarta" />
              </div>
              <div className="divider"></div>
              <div className="p-4">
                <div className="border-[1px] border-[#4048521a] rounded-lg">
                  <div className="flex flex-row px-4 py-2">
                    <img src={IconSeatTerisi} alt="terisi" />
                    <div className="txt-blue text-[16px] font-semibold ml-1">
                      Seat Terisi
                    </div>
                    <div className="txt-green text-[16px] font-semibold ml-2">
                      8
                    </div>
                  </div>
                  <div className="divider"></div>
                  <div className="flex flex-row px-4 py-3 gap-4">
                    <div className="flex flex-row gap-2">
                      <img src={AvaIkhwan} alt="ikhwan" />
                      <div className="txt-green font-semibold text-[16px]">
                        10
                      </div>
                    </div>
                    <div className="flex flex-row gap-2">
                      <img src={AvaAkhwat} alt="akhwat" />
                      <div className="txt-green font-semibold text-[16px]">
                        10
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="minim-shadow rounded-lg bg-white">
              <div className="bg-[#EEE5C8] px-4 py-5 flex flex-row gap-2 rounded-tl-lg rounded-tr-lg">
                <Badge label="Balikpapan" />
              </div>
              <div className="divider"></div>
              <div className="p-4">
                <div className="border-[1px] border-[#4048521a] rounded-lg">
                  <div className="flex flex-row px-4 py-2">
                    <img src={IconSeatTerisi} alt="terisi" />
                    <div className="txt-blue text-[16px] font-semibold ml-1">
                      Seat Terisi
                    </div>
                    <div className="txt-green text-[16px] font-semibold ml-2">
                      8
                    </div>
                  </div>
                  <div className="divider"></div>
                  <div className="flex flex-row px-4 py-3 gap-4">
                    <div className="flex flex-row gap-2">
                      <img src={AvaIkhwan} alt="ikhwan" />
                      <div className="txt-green font-semibold text-[16px]">
                        10
                      </div>
                    </div>
                    <div className="flex flex-row gap-2">
                      <img src={AvaAkhwat} alt="akhwat" />
                      <div className="txt-green font-semibold text-[16px]">
                        10
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(PaketLaporanSeat);
