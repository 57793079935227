import Inputs from ".";
import InputPhone from "../inputPhone";
import IconBrush from "assets/icons/brush.svg";
import IconAlert from "assets/icons/Warning.svg";
import FlagIndonesia from "assets/icons/flag-indonesia.svg";

const InputsDocs = () => {
  return (
    <>
      <div className="flex flex-row gap-4 m-4">
        <Inputs id="test" placeholder="Text Input" />
        <Inputs
          id="test"
          placeholder="Text Input"
          iconPosition="left"
          icon={IconBrush}
        />
        <Inputs
          id="test"
          placeholder="Text Input"
          iconPosition="right"
          icon={IconBrush}
        />
      </div>
      <div className="flex flex-row gap-4 m-4">
        <Inputs id="test" label="Text input label" placeholder="Text Input" />
        <Inputs
          id="test"
          label="Text input label"
          placeholder="Text Input"
          iconPosition="left"
          icon={IconBrush}
        />
        <Inputs
          id="test"
          label="Text input label"
          placeholder="Text Input"
          iconPosition="right"
          icon={IconBrush}
        />
      </div>

      <div className="flex flex-row gap-4 m-4">
        <Inputs
          id="test"
          placeholder="Text Input"
          customClass="input-disabled"
          disabled
        />
        <Inputs
          id="test"
          placeholder="Text Input"
          customClass="input-disabled"
          iconPosition="left"
          icon={IconBrush}
          disabled
        />
        <Inputs
          id="test"
          placeholder="Text Input"
          customClass="input-disabled"
          iconPosition="right"
          icon={IconBrush}
          disabled
        />
      </div>

      <div className="flex flex-row gap-4 m-4">
        <Inputs
          id="test"
          placeholder="Text Input"
          customClass="input-error"
          error="Error Message"
        />
        <Inputs
          id="test"
          placeholder="Text Input"
          customClass="input-error"
          iconPosition="left"
          icon={IconBrush}
          error="Error Message"
        />
        <Inputs
          id="test"
          placeholder="Text Input"
          customClass="input-error"
          iconPosition="right"
          icon={IconBrush}
          error="Error Message"
        />
      </div>

      <div className="flex flex-row gap-4 m-4">
        <Inputs
          id="test"
          placeholder="Text Input"
          customClass="input-success"
          success="Field validation is successfull"
        />
        <Inputs
          id="test"
          placeholder="Text Input"
          customClass="input-success"
          iconPosition="left"
          icon={IconBrush}
          success="Field validation is successfull"
        />
        <Inputs
          id="test"
          placeholder="Text Input"
          customClass="input-success"
          iconPosition="right"
          icon={IconBrush}
          success="Field validation is successfull"
        />
      </div>

      <div className="flex flex-row gap-4 m-4">
        <InputPhone id="test" placeholder="Text Input" icon={FlagIndonesia} />
        <InputPhone
          id="test"
          placeholder="Text Input"
          customClass="input-disabled"
          icon={FlagIndonesia}
          disabled
        />
        <InputPhone
          id="test"
          placeholder="Text Input"
          customClass="input-success"
          success="Field validation is successfull"
          icon={FlagIndonesia}
        />
        <InputPhone
          id="test"
          placeholder="Text Input"
          customClass="input-error"
          error="Error Message"
          icon={FlagIndonesia}
        />
      </div>
    </>
  );
};

export default InputsDocs;
