import {
  ERROR_FETCHING_PAKET_DETAIL_GENERAL_INFO,
  SET_COU_DATA,
  SET_LIST_BUS_BY_ID,
  SET_UPDATE_TGL,
  START_FETCHING_PAKET_DETAIL_GENERAL_INFO,
  SUCCESS_FETCHING_PAKET_DETAIL_GENERAL_INFO,
  SET_DATA_PEMBIMBING,
} from "stores/actions/paket/generalInfo/actionTypes";

const statuslist = {
  idle: "idle",
  process: "process",
  success: "success",
  error: "error",
};

const initialState = {
  data: null,
  bus: null,
  status: statuslist.idle,
  updateTgl: null,
  couSkt: null,
  dataPembimbing: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case START_FETCHING_PAKET_DETAIL_GENERAL_INFO:
      return { ...state, status: statuslist.process };

    case ERROR_FETCHING_PAKET_DETAIL_GENERAL_INFO:
      return { ...state, status: statuslist.error };

    case SUCCESS_FETCHING_PAKET_DETAIL_GENERAL_INFO:
      return {
        ...state,
        data: action.payload.data,
        status: statuslist.success,
      };
    case SET_COU_DATA:
      return {
        ...state,
        couSkt: action.payload,
      };
    case SET_UPDATE_TGL:
      return {
        ...state,
        updateTgl: action.payload,
      };
    case SET_LIST_BUS_BY_ID:
      return {
        ...state,
        bus: action.payload.data,
      };
    case SET_DATA_PEMBIMBING:
      return {
        ...state,
        dataPembimbing: action.payload,
      };
    default:
      return state;
  }
}
