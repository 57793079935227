import styled from "styled-components";

const RadiosStyle = styled.div`
  padding: 4px;
  background: rgba(138, 18, 18, 0.08);
  border-radius: 8px;

  .ant-radio-button-wrapper {
    padding: 4px 16px;
    height: auto;

    &:hover,
    &:active {
      color: rgba(0, 0, 0, 0.88);
    }
  }

  .ant-radio-button-wrapper,
  .ant-radio-button-wrapper:first-child {
    border: 0;
    background: transparent;
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    width: 0;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: linear-gradient(204.44deg, #f90601 -18.49%, #8a1212 84.72%);
    color: white;

    .radio-value {
      color: white;
    }

    &:hover,
    &:active {
      background: linear-gradient(204.44deg, #f90601 -18.49%, #8a1212 84.72%);
      color: white;
    }
  }
`;

export default RadiosStyle;
