import React, { useState } from "react";
import Inputs from "components/form/inputs";
import Selects from "components/form/selects";
import IconSearch from "assets/icons/search-normal.svg";
import Buttons from "components/buttons";
import FotoProfil from "assets/images/Avatar-ikhwan-1.svg";
import Badge from "components/badge";
import JPGIcon from "assets/icons/colored/JPG.svg";
import PDFIcon from "assets/icons/colored/PDF.svg";
import IconClipboardSuccess from "assets/icons/colored/clipboard-tick.svg";
import IconClipboardFailed from "assets/icons/colored/clipboard-close.svg";
import Popup from "components/popup";
import PopupUploadVisa from "./PopupUploadVisa";

const Visa = () => {
  const [openPopupAddVisa, setOpenPopupAddVisa] = useState(false);
  const [options, setOptions] = useState([
    {
      id: 1,
      value: "semua-visa",
      label: "Semua Visa",
    },
    {
      id: 2,
      value: "visa-tidak-ditampilkan",
      label: "Visa Tidak Ditampilkan",
    },
    {
      id: 3,
      value: "visa-ditampilkan",
      label: "Visa Ditampilkan",
    },
  ]);

  return (
    <>
      <Popup
        open={openPopupAddVisa}
        handleClose={() => {
          setOpenPopupAddVisa(false);
        }}
        title="Upload E-Visa"
        containerClass=""
        children={<PopupUploadVisa />}
      />

      <div className="p-[16px]">
        <div className="flex flex-row justify-between items-start p-[16px] gap-[518px] shadow-md rounded-lg">
          <div className="flex flex-row items-center gap-[20px]">
            <h2 className="font-semibold">Daftar Jamaah</h2>
            <div>
              <Selects
                placeholder="Semua Visa"
                customClass="!w-[200px] z-30"
                options={options}
                searchable
                itemRenderer={({ state, item, methods }) => {
                  return (
                    <div
                      className={`react-dropdown-select-item ${
                        state.values.length !== 0 &&
                        state.values[0].value === item.value &&
                        "react-dropdown-select-item-selected"
                      }`}
                      onClick={() => methods.addItem(item)}
                    >
                      {item.label}
                    </div>
                  );
                }}
              />
            </div>
          </div>
          <div>
            <Inputs
              id="test"
              placeholder="Search"
              customClass="!w-[240px]"
              iconPosition="left"
              icon={IconSearch}
            />
          </div>
        </div>
        <div className="flex flex-row justify-end m-[16px]">
          <Buttons
            text="Edit Pesan Info E-visa"
            customClass="btn-outline btn-medium"
          />
        </div>
        <div className="flex flex-col justify-center items-start p-[16px] gap-[16px] bg-white shadow-md rounded-lg">
            <div className="flex flex-row justify-between items-center gap-[8px] w-full">
              <div className="flex flex-row items-center gap-[16px]">
                <div className="flex flex-row items-center gap-[16px]">
                  <img
                    src={FotoProfil}
                    alt="foto profil"
                    className="w-[40px] h-[40px] rounded-full"
                  />
                  <h2>Dimas Andreyan Putra</h2>
                </div>
                <div className="flex flex-row items-start gap-[8px]">
                  <Badge
                    label="YOGYAKARTA"
                    color="#FE0000"
                    backgroundColor="#F7A399"
                  />
                  <Badge
                    label="YIA-CGK"
                    color="#FE0000"
                    backgroundColor="rgba(254, 0, 0, 0.2)"
                  />
                </div>
              </div>
              <div className="flex flex-row items-start gap-[20px]">
                <div className="flex flex-row items-start gap-[16px]">
                  <img src={JPGIcon} alt="jpg icon" />
                  <img src={PDFIcon} alt="pdf icon" />
                </div>
                <div className="flex flex-row items-center py-[4px] px-[11px] gap-[8px] border border-green-600 rounded-lg bg-green-200 w-[88px] h-[32px]">
                  <p className="text-green-500 text-xs">E-visa</p>
                  <img src={IconClipboardSuccess} alt="icon success" />
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-between items-center gap-[16px] w-full">
              <div className="flex flex-row items-center gap-[16px]">
                <div className="px-[12px] py-[8px] w-[116px] h-[32px] border border-green-600 rounded-lg cursor-pointer">
                  <p className="text-green-500 font-semibold text-xs">
                    Kirim Info E-Visa
                  </p>
                </div>
                <div>
                  <Badge
                    label="Terkirim"
                    color="#71BE0E"
                    backgroundColor="rgba(0, 183, 40, 0.04)"
                  />
                </div>
              </div>
              <div>
                <Buttons
                  text="Upload E-Visa"
                  customClass="btn-secondary btn-medium"
                  onClick={() => setOpenPopupAddVisa(true)}
                />
              </div>
            </div>
        </div>
        <div className="flex flex-col justify-center items-start p-[16px] gap-[16px] bg-white shadow-md rounded-lg mt-[16px]">
          <div className="flex flex-row justify-between items-center gap-[8px] w-full">
            <div className="flex flex-row items-center gap-[16px]">
              <div className="flex flex-row items-center gap-[16px]">
                <img
                  src={FotoProfil}
                  alt="foto profil"
                  className="w-[40px] h-[40px] rounded-full"
                />
                <h2>Dimas Andreyan Putra</h2>
              </div>
              <div className="flex flex-row items-start gap-[8px]">
                <Badge label="SOLO" color="#FE9A00" backgroundColor="#FCDE9C" />
                <Badge
                  label="SOC-CGK"
                  color="#FE9A00"
                  backgroundColor="rgba(254, 153, 1, 0.2)"
                />
              </div>
            </div>
            <div className="flex flex-row items-start gap-[20px]">
              <div className="flex flex-row items-center py-[4px] px-[11px] gap-[8px] border border-red-600 rounded-lg bg-red-200 w-[88px] h-[32px]">
                <p className="text-red-500 text-xs">E-visa</p>
                <img src={IconClipboardFailed} alt="icon success" />
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-between items-center gap-[16px] w-full">
            <div className="flex flex-row items-center gap-[16px]">
              <div className="px-[12px] py-[8px] w-[116px] h-[32px] border border-green-600 rounded-lg cursor-pointer">
                <p className="text-green-500 font-semibold text-xs">
                  Kirim Info E-Visa
                </p>
              </div>
              <div>
                <Badge
                  label="Gagal Terkirim"
                  color="#E82320"
                  backgroundColor="rgba(0, 183, 40, 0.04)"
                />
              </div>
            </div>
            <div>
              <Buttons
                text="Upload E-Visa"
                customClass="btn-secondary btn-medium"
                onClick={() => setOpenPopupAddVisa(true)}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center items-start p-[16px] gap-[16px] bg-white shadow-md rounded-lg mt-[16px]">
            <div className="flex flex-row justify-between items-center gap-[8px] w-full">
              <div className="flex flex-row items-center gap-[16px]">
                <div className="flex flex-row items-center gap-[16px]">
                  <img
                    src={FotoProfil}
                    alt="foto profil"
                    className="w-[40px] h-[40px] rounded-full"
                  />
                  <h2>Dimas Andreyan Putra</h2>
                </div>
                <div className="flex flex-row items-start gap-[8px]">
                  <Badge
                    label="YOGYAKARTA"
                    color="#FE0000"
                    backgroundColor="#F7A399"
                  />
                  <Badge
                    label="YIA-CGK"
                    color="#FE0000"
                    backgroundColor="rgba(254, 0, 0, 0.2)"
                  />
                </div>
              </div>
              <div className="flex flex-row items-start gap-[20px]">
                <div className="flex flex-row items-start gap-[16px]">
                  <img src={JPGIcon} alt="jpg icon" />
                  <img src={PDFIcon} alt="pdf icon" />
                </div>
                <div className="flex flex-row items-center py-[4px] px-[11px] gap-[8px] border border-green-600 rounded-lg bg-green-200 w-[88px] h-[32px]">
                  <p className="text-green-500 text-xs">E-visa</p>
                  <img src={IconClipboardSuccess} alt="icon success" />
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-between items-center gap-[16px] w-full">
              <div className="flex flex-row items-center gap-[16px]">
                <div className="px-[12px] py-[8px] w-[116px] h-[32px] border border-green-600 rounded-lg cursor-pointer">
                  <p className="text-green-500 font-semibold text-xs">
                    Kirim Info E-Visa
                  </p>
                </div>
                <div>
                  <Badge
                    label="Terkirim"
                    color="#71BE0E"
                    backgroundColor="rgba(0, 183, 40, 0.04)"
                  />
                </div>
              </div>
              <div>
                <Buttons
                  text="Upload E-Visa"
                  customClass="btn-secondary btn-medium"
                  onClick={() => setOpenPopupAddVisa(true)}
                />
              </div>
            </div>
        </div>
      </div>
    </>
  );
};

export default Visa;
