import Tab from "components/tab";
import React from "react";
import RatingPerjamaah from "./Tabs/RatingPerjamaah";
import RatingQuestion from "./Tabs/RatingQuestion";

const RatingTabs = [
  {
    label: <span className="flex gap-1">Rating Perjamaah</span>,
    key: "1",
    children: <RatingPerjamaah />,
  },
  {
    label: <span className="flex gap-1">Rating Question</span>,
    key: "2",
    children: <RatingQuestion />,
  },
];

const Rating = () => {
  return (
    <div className="p-5 border-b relative">
      <div>
        <Tab defaultActiveKey="1" items={RatingTabs} />
      </div>
    </div>
  );
};

export default Rating;
