// datamaster Actions
// --------------------------------------------------------

import { async } from "q";
import {
  addAkomodasi,
  addBandara,
  addMaskapai,
  addPembimbing,
  addPerlengkapan,
  addTransport,
  deleteAkomodasi,
  deleteBandara,
  deleteMaskapai,
  deletePembimbing,
  deletePerlengkapan,
  deleteTransport,
  duplicateAkomodasi,
  duplicateBandara,
  duplicateMaskapai,
  duplicatePembimbing,
  duplicatePerlengkapan,
  duplicateTransport,
  editAkomodasi,
  editBandara,
  editMaskapai,
  editPembimbing,
  editPerlengkapan,
  editTransport,
  getAllAkomodasi,
  getAllBandara,
  getAllMaskapai,
  getAllPembimbing,
  getAllPerlengkapan,
  getAllTransport,
  getDetailAkomodasi,
  getDetailBandara,
  getDetailMaskapai,
  getDetailPembimbing,
  getDetailPerlengkapan,
  getDetailTransport,
  getNumberDataMaster,
  getRegion,
} from "../../../utils/api/datamaster";
import { handleError, handleErrorBE, handleSuccess } from "../errorGeneral";
import { clearData, setDataUpload, setDataUploadMulti } from "../uploadFile";
import Pembimbing from "pages/master/components/subpage/pembimbing";

/* eslint-disable space-before-function-paren */
export const SET_LOADING = "datamaster/SET_LOADING";
export const SET_MODAL = "datamaster/SET_MODAL";
export const SET_BUTTON = "datamaster/SET_BUTTON";
export const CLEAR_ERROR = "datamaster/CLEAR_ERROR";
export const SET_ERROR = "datamaster/SET_ERROR";
export const INIT_DATA = "datamaster/INIT_DATA";
export const SET_DOUBLE_SUBMIT = "datamaster/SET_DOUBLE_SUBMIT";

// type jumlah data master
export const SET_NUMBERDATAMASTER = "datamaster/SET_NUMBERDATAMASTER";

// type status
export const SET_STATUSDATAMASTER = "datamaster/SET_STATUSDATAMASTER";

// type maskapai
export const SET_DATAMASKAPAI = "datamaster/SET_DATA_MASKAPAI";
export const SET_ADDMASKAPAI = "datamaster/SET_ADD_MASKAPAI";
export const SET_EDITMASKAPAI = "datamaster/SET_EDITDATA_MASKAPAI";
export const SET_DETAILDATA = "datamaster/SET_EDIT_MASKAPAI";
export const SET_DELETEMASKAPAI = "datamaster/SET_DELETE_MASKAPAI";
export const SET_DUPLICATEMASKAPAI = "datamaster/SET_DUPLICATE_MASKAPAI";

// edit data
export const SET_EDITDATA = "datamaster/SET_EDIT_DATA";
export const SET_KEYDATA = "datamaster/SET_KEY_DATA";

// type bandara
export const SET_DATABANDARA = "datamaster/SET_DATA_BANDARA";
export const SET_ADDBANDARA = "datamaster/SET_ADD_BANDARA";

// get region
export const SET_GETREGION = "datamaster/SET_GETREGION";

// type transportasi
export const SET_DATATRANSPORT = "datamaster/SET_DATA_TRANSPORT";
export const SET_ADDTRANSPORT = "datamaster/SET_DATA_TRANSPORT";

// type akomodasi
export const SET_DATAAKOMODASI = "datamaster/SET_DATA_AKOMODASI";

// type perlengkapan
export const SET_DATAPERLENGKAPAN = "datamaster/SET_DATA_PERLENGKAPAN";

// type perlengkapan
export const SET_DATAPEMBIMBING = "datamaster/SET_DATA_PEMBIMBING";

export const SET_MASTER_ID = "datamaster/SET_MASTER_ID";

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const setModal = (payload) => ({
  type: SET_MODAL,
  payload,
});

export const setButton = (payload) => ({
  type: SET_BUTTON,
  payload,
});

export const setDoubleSubmit = (payload) => ({
  type: SET_DOUBLE_SUBMIT,
  payload,
});

export const setEditData = (payload) => ({
  type: SET_EDITDATA,
  payload,
});

export const setKeyData = (payload) => ({
  type: SET_KEYDATA,
  payload,
});

export const setStatusData = (payload) => ({
  type: SET_STATUSDATAMASTER,
  payload,
});

// type maskapai

export const setDataMaskapai = (payload) => ({
  type: SET_DATAMASKAPAI,
  payload,
});

export const setAddMaskapai = (payload) => ({
  type: SET_ADDMASKAPAI,
  payload,
});

export const setDetailData = (payload) => ({
  type: SET_DETAILDATA,
  payload,
});

export const setEditMaskapai = (payload) => ({
  type: SET_EDITMASKAPAI,
  payload,
});

export const setDeleteMaskapai = (payload) => ({
  type: SET_DELETEMASKAPAI,
  payload,
});

export const setDuplicateMaskapai = (payload) => ({
  type: SET_DUPLICATEMASKAPAI,
  payload,
});

// type bandara

export const setDataBandara = (payload) => ({
  type: SET_DATABANDARA,
  payload,
});

export const setAddBandara = (payload) => ({
  type: SET_ADDBANDARA,
  payload,
});

// type transport

export const setDataTransport = (payload) => ({
  type: SET_DATATRANSPORT,
  payload,
});

export const setDataAkomodasi = (payload) => ({
  type: SET_DATAAKOMODASI,
  payload,
});

export const setMasterId = (payload) => ({
  type: SET_MASTER_ID,
  payload,
});

export const setAddTransport = (payload) => ({
  type: SET_ADDTRANSPORT,
  payload,
});

export const setGetRegion = (payload) => ({
  type: SET_GETREGION,
  payload,
});

export const setDataMaster = (payload) => ({
  type: SET_NUMBERDATAMASTER,
  payload,
});

// perlengkapan
export const setDataPerlengkapan = (payload) => ({
  type: SET_DATAPERLENGKAPAN,
  payload,
});

// Pembimbing
export const setDataPembimbing = (payload) => ({
  type: SET_DATAPEMBIMBING,
  payload,
});

// get jumalah semua data master
export const getAllDataMaster = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await getNumberDataMaster(payload);
    dispatch(setLoading(false));
    if (response.status === 200) {
      console.log("getAllDataMaster", response.data);
      dispatch(setDataMaster(response.data));
    } else {
      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

// action get region
export const getAllRegion = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await getRegion(payload);
    dispatch(setLoading(false));
    if (response.status === 200) {
      let filteredRegion = [];

      for (let data of response.data.data) {
        filteredRegion.push({
          value: `${data.subdistrict_name} ${data.city_name} , ${data.province_name} , ${data.postal_code}`,
          label: `${data.subdistrict_name} ${data.city_name} , ${data.province_name} , ${data.postal_code}`,
        });
      }

      dispatch(setGetRegion(filteredRegion));
    } else {
      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const editDataMaster = (payload) => async (dispatch) => {
  dispatch(setEditData(payload));
  dispatch(getAllDataMaster());
};

// cek status
export const statusChecking = (payload) => async (dispatch) => {
  dispatch(setStatusData(payload));
};

export const keyDataMaster = (payload) => async (dispatch) => {
  dispatch(setKeyData(payload));
};

// action maskapai
export const getAllDataMaskapai = (payload) => async (dispatch) => {
  try {
    dispatch(statusChecking("process"));
    dispatch(setLoading(true));
    const response = await getAllMaskapai(payload);
    dispatch(setLoading(false));

    if (response.status === 200) {
      dispatch(setDataMaskapai(response.data));
      dispatch(statusChecking("idle"));
    } else {
      dispatch(handleErrorBE(response));
      dispatch(statusChecking("idle"));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const duplicateDataMaskapai = (payload) => async (dispatch) => {
  try {
    dispatch(statusChecking("process"));
    dispatch(setLoading(true));
    const response = await duplicateMaskapai(payload);
    dispatch(setLoading(false));
    if (response.status === 200) {
      dispatch(statusChecking("idle"));
      dispatch(clearData());
      dispatch(
        getAllDataMaskapai({
          per_page: 10,
          page_number: 1,
          sort_column: "name",
          sort_order: "",
          keyword: "",
        })
      );
      dispatch(getAllDataMaster());
    } else {
      dispatch(statusChecking("idle"));

      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const editDataMaskapai = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    dispatch(statusChecking("process"));

    const response = await editMaskapai(payload);
    dispatch(setLoading(false));
    if (response.status === 200) {
      dispatch(statusChecking("idle"));
      dispatch(clearData());

      dispatch(handleSuccess(response.data));

      dispatch(
        getAllDataMaskapai({
          per_page: 10,
          page_number: 1,
          sort_column: "name",
          sort_order: "",
          keyword: "",
        })
      );
    } else {
      dispatch(statusChecking("idle"));

      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const addDataMaskapai = (payload) => async (dispatch) => {
  try {
    dispatch(statusChecking("process"));
    dispatch(setLoading(true));
    const response = await addMaskapai(payload);
    dispatch(setLoading(false));
    if (response.status === 200) {
      dispatch(statusChecking("idle"));
      dispatch(clearData());
      dispatch(handleSuccess(response.data));

      dispatch(
        getAllDataMaskapai({
          per_page: 10,
          page_number: 1,
          sort_column: "name",
          sort_order: "",
          keyword: "",
        })
      );
      dispatch(getAllDataMaster());
    } else {
      dispatch(statusChecking("idle"));
      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getDetailDataMaskapai = (payload, id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await getDetailMaskapai(payload);
    dispatch(setLoading(false));
    if (response.status === 200) {
      dispatch(setDetailData(response.data));

      let imgLogo = [
        {
          id: id,
          fileName: response.data.data.name,
          path: response.data.data.logo,
          name: "logo",
        },
        {
          id: id,
          fileName: response.data.data.name,
          path: response.data.data.thumbnail,
          name: "thumbnail",
        },
      ];

      let imgMulti = [];
      let key = 0;

      for (let data of response.data.data.gallery) {
        imgMulti.push({
          id: `${id} ${key}`,
          fileName: response.data.data.name,
          path: data.link,
          name: "gallery",
          type: data.type,
        });

        key++;
      }

      dispatch(setDataUpload(imgLogo));
      dispatch(setDataUploadMulti(imgMulti));
    } else {
      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const deleteDataMaskapai = (payload) => async (dispatch) => {
  try {
    dispatch(statusChecking("process"));
    dispatch(setLoading(true));
    const response = await deleteMaskapai(payload);
    if (response.status === 200) {
      dispatch(statusChecking("idle"));

      dispatch(handleSuccess(response.data));
      dispatch(
        getAllDataMaskapai({
          per_page: 10,
          page_number: 1,
          sort_column: "name",
          sort_order: "",
          keyword: "",
        })
      );
      dispatch(getAllDataMaster());
    } else {
      dispatch(statusChecking("idle"));

      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

// action bandara

export const getAllDataBandara = (payload) => async (dispatch) => {
  try {
    dispatch(statusChecking("process"));

    dispatch(setLoading(true));
    const response = await getAllBandara(payload);
    dispatch(setLoading(false));
    if (response.status === 200) {
      dispatch(statusChecking("idle"));

      dispatch(setDataBandara(response.data));
    } else {
      dispatch(statusChecking("idle"));

      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const addDataBandara = (payload) => async (dispatch) => {
  try {
    dispatch(statusChecking("process"));

    dispatch(setLoading(true));
    const response = await addBandara(payload);
    dispatch(setLoading(false));
    if (response.status === 200) {
      dispatch(statusChecking("idle"));
      dispatch(clearData());

      dispatch(handleSuccess(response.data));

      dispatch(
        getAllDataBandara({
          page_number: 1,
          per_page: 10,
          keyword: "",
          sort_column: "",
          sort_order: "DESC",
        })
      );
      dispatch(getAllDataMaster());
    } else {
      dispatch(statusChecking("idle"));

      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const deleteDataBandara = (payload) => async (dispatch) => {
  try {
    dispatch(statusChecking("process"));

    dispatch(setLoading(true));
    const response = await deleteBandara(payload);
    if (response.status === 200) {
      dispatch(statusChecking("idle"));

      dispatch(handleSuccess(response.data));
      dispatch(
        getAllDataBandara({
          page_number: 1,
          per_page: 10,
          keyword: "",
          sort_column: "",
          sort_order: "DESC",
        })
      );
      dispatch(getAllDataMaster());
    } else {
      dispatch(statusChecking("idle"));

      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const duplicateDataBandara = (payload) => async (dispatch) => {
  try {
    dispatch(statusChecking("process"));

    dispatch(setLoading(true));
    const response = await duplicateBandara(payload);
    dispatch(setLoading(false));
    if (response.status === 200) {
      dispatch(statusChecking("idle"));

      dispatch(clearData());
      dispatch(
        getAllDataBandara({
          page_number: 1,
          per_page: 10,
          keyword: "",
          sort_column: "",
          sort_order: "DESC",
        })
      );
    } else {
      dispatch(statusChecking("idle"));

      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const editDataBandara = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    dispatch(statusChecking("process"));

    const response = await editBandara(payload);
    dispatch(setLoading(false));
    if (response.status === 200) {
      dispatch(statusChecking("idle"));

      dispatch(handleSuccess(response.data));
      dispatch(clearData());
      dispatch(
        getAllDataBandara({
          page_number: 1,
          per_page: 10,
          keyword: "",
          sort_column: "",
          sort_order: "DESC",
        })
      );
      dispatch(getAllDataMaster());
    } else {
      dispatch(statusChecking("idle"));

      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getDetailDataBandara = (payload, id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await getDetailBandara(payload);
    dispatch(setLoading(false));
    if (response.status === 200) {
      dispatch(setDetailData(response.data));

      let imgLogo = [
        {
          id: id,
          fileName: response.data.data.name,
          path: response.data.data.logo,
          name: "logo",
        },
        {
          id: id,
          fileName: response.data.data.name,
          path: response.data.data.thumbnail,
          name: "thumbnail",
        },
      ];

      let imgMulti = [];
      let key = 0;

      for (let data of response.data.data.gallery) {
        imgMulti.push({
          id: `${id} ${key}`,
          fileName: response.data.data.name,
          path: data.link,
          name: "gallery",
          type: data.type,
        });

        key++;
      }

      dispatch(setDataUpload(imgLogo));
      dispatch(setDataUploadMulti(imgMulti));
    } else {
      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

// action transport

export const getAllDataTransport = (payload) => async (dispatch) => {
  try {
    dispatch(statusChecking("process"));

    dispatch(setLoading(true));
    const response = await getAllTransport(payload);
    dispatch(setLoading(false));
    if (response.status === 200) {
      dispatch(statusChecking("idle"));

      dispatch(setDataTransport(response.data));
    } else {
      dispatch(statusChecking("idle"));

      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const addDataTransport = (payload) => async (dispatch) => {
  try {
    dispatch(statusChecking("process"));

    dispatch(setLoading(true));
    const response = await addTransport(payload);
    dispatch(setLoading(false));
    if (response.status === 200) {
      dispatch(statusChecking("idle"));
      dispatch(handleSuccess(response.data));

      dispatch(
        getAllDataTransport({
          page_number: 1,
          per_page: 10,
          keyword: "",
          sort_column: "",
          sort_order: "DESC",
        })
      );
      dispatch(getAllDataMaster());
    } else {
      dispatch(statusChecking("idle"));

      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const deleteDataTransport = (payload) => async (dispatch) => {
  try {
    dispatch(statusChecking("process"));

    dispatch(setLoading(true));
    const response = await deleteTransport(payload);
    if (response.status === 200) {
      dispatch(statusChecking("idle"));

      dispatch(handleSuccess(response.data));
      dispatch(
        getAllDataTransport({
          page_number: 1,
          per_page: 10,
          keyword: "",
          sort_column: "",
          sort_order: "DESC",
        })
      );
      dispatch(getAllDataMaster());
    } else {
      dispatch(statusChecking("idle"));

      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const duplicateDataTransport = (payload) => async (dispatch) => {
  try {
    dispatch(statusChecking("process"));

    dispatch(setLoading(true));
    const response = await duplicateTransport(payload);
    dispatch(setLoading(false));
    if (response.status === 200) {
      dispatch(statusChecking("idle"));

      dispatch(clearData());
      dispatch(
        getAllDataTransport({
          page_number: 1,
          per_page: 10,
          keyword: "",
          sort_column: "",
          sort_order: "DESC",
        })
      );
    } else {
      dispatch(statusChecking("idle"));

      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getDetailDataTransport = (payload, id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await getDetailTransport(payload);
    dispatch(setLoading(false));
    if (response.status === 200) {
      dispatch(setDetailData(response.data));

      let imgLogo = [
        {
          id: id,
          fileName: response.data.data.name,
          path: response.data.data.logo,
          name: "logo",
        },
        {
          id: id,
          fileName: response.data.data.name,
          path: response.data.data.thumbnail,
          name: "thumbnail",
        },
      ];

      let imgMulti = [];
      let key = 0;

      for (let data of response.data.data.gallery) {
        imgMulti.push({
          id: `${id} ${key}`,
          fileName: response.data.data.name,
          path: data.link,
          name: "gallery",
          type: data.type,
        });

        key++;
      }

      dispatch(setDataUpload(imgLogo));
      dispatch(setDataUploadMulti(imgMulti));
    } else {
      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const editDataTransport = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    dispatch(statusChecking("process"));

    const response = await editTransport(payload);
    dispatch(setLoading(false));
    if (response.status === 200) {
      dispatch(statusChecking("idle"));

      dispatch(handleSuccess(response.data));
      dispatch(clearData());
      dispatch(
        getAllDataTransport({
          page_number: 1,
          per_page: 10,
          keyword: "",
          sort_column: "",
          sort_order: "DESC",
        })
      );
    } else {
      dispatch(statusChecking("idle"));

      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

// action akomodasi
export const getAllDataAkomodasi = (payload) => async (dispatch) => {
  try {
    dispatch(statusChecking("process"));

    dispatch(setLoading(true));
    const response = await getAllAkomodasi(payload);
    dispatch(setLoading(false));
    if (response.status === 200) {
      dispatch(statusChecking("idle"));

      dispatch(setDataAkomodasi(response.data));
    } else {
      dispatch(statusChecking("idle"));

      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const deleteDataAkomodasi = (payload) => async (dispatch) => {
  try {
    dispatch(statusChecking("process"));

    dispatch(setLoading(true));
    const response = await deleteAkomodasi(payload);
    if (response.status === 200) {
      dispatch(statusChecking("idle"));

      dispatch(handleSuccess(response.data));
      dispatch(
        getAllDataAkomodasi({
          page_number: 1,
          per_page: 10,
          keyword: "",
          sort_column: "",
          sort_order: "DESC",
        })
      );
      dispatch(getAllDataMaster());
    } else {
      dispatch(statusChecking("idle"));

      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const duplicateDataAkomodasi = (payload) => async (dispatch) => {
  try {
    dispatch(statusChecking("process"));

    dispatch(setLoading(true));
    const response = await duplicateAkomodasi(payload);
    dispatch(setLoading(false));
    if (response.status === 200) {
      dispatch(statusChecking("idle"));

      dispatch(clearData());
      dispatch(
        getAllDataAkomodasi({
          page_number: 1,
          per_page: 10,
          keyword: "",
          sort_column: "",
          sort_order: "DESC",
        })
      );
    } else {
      dispatch(statusChecking("idle"));

      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const addDataAkomodasi = (payload) => async (dispatch) => {
  try {
    dispatch(statusChecking("process"));

    dispatch(setLoading(true));
    const response = await addAkomodasi(payload);
    dispatch(setLoading(false));
    if (response.status === 200) {
      dispatch(statusChecking("idle"));
      dispatch(handleSuccess(response.data));
      dispatch(clearData());

      dispatch(
        getAllDataAkomodasi({
          page_number: 1,
          per_page: 10,
          keyword: "",
          sort_column: "",
          sort_order: "DESC",
        })
      );
      dispatch(getAllDataMaster());
    } else {
      dispatch(statusChecking("idle"));

      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getDetailDataAkomodasi = (payload, id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await getDetailAkomodasi(payload);
    dispatch(setLoading(false));
    if (response.status === 200) {
      dispatch(setDetailData(response.data));

      let imgLogo = [
        {
          id: id,
          fileName: response.data.data.name,
          path: response.data.data.logo,
          name: "logo",
        },
        {
          id: id,
          fileName: response.data.data.name,
          path: response.data.data.thumbnail,
          name: "thumbnail",
        },
      ];

      let imgMulti = [];
      let key = 0;

      for (let data of response.data.data.gallery_akomodasi) {
        imgMulti.push({
          id: `${id} ${key}`,
          fileName: response.data.data.name,
          path: data.link,
          name: "gallery",
          type: data.type,
        });

        key++;
      }

      dispatch(setDataUpload(imgLogo));
      dispatch(setDataUploadMulti(imgMulti));
    } else {
      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const editDataAkomodasi = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    dispatch(statusChecking("process"));

    const response = await editAkomodasi(payload);
    dispatch(setLoading(false));
    if (response.status === 200) {
      dispatch(statusChecking("idle"));

      dispatch(handleSuccess(response.data));
      dispatch(clearData());
      dispatch(
        getAllDataAkomodasi({
          page_number: 1,
          per_page: 10,
          keyword: "",
          sort_column: "",
          sort_order: "DESC",
        })
      );
    } else {
      dispatch(statusChecking("idle"));

      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getMasterId = (payload) => async (dispatch) => {
  try {
    dispatch(setMasterId(payload));
    // dispatch(setModal(true));
  } catch (error) {
    // dispatch(setErrorUpload(error.message || "Upload image error"));
    dispatch(handleError(error));
  }
};

// data perlengkapan
export const getAllDataPerlengkapan = (payload) => async (dispatch) => {
  try {
    dispatch(statusChecking("process"));

    dispatch(setLoading(true));
    const response = await getAllPerlengkapan(payload);
    dispatch(setLoading(false));
    if (response.status === 200) {
      dispatch(statusChecking("idle"));

      dispatch(setDataPerlengkapan(response.data));
    } else {
      dispatch(statusChecking("idle"));

      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const addDataPerlengkapan = (payload) => async (dispatch) => {
  try {
    dispatch(statusChecking("process"));

    dispatch(setLoading(true));
    const response = await addPerlengkapan(payload);
    dispatch(setLoading(false));
    if (response.status === 200) {
      dispatch(statusChecking("idle"));

      dispatch(handleSuccess(response.data));
      dispatch(clearData());
      dispatch(
        getAllDataPerlengkapan({
          per_page: 10,
          page_number: 1,
          sort_column: "",
          sort_order: "Desc",
          keyword: "",
        })
      );
      dispatch(getAllDataMaster());
    } else {
      dispatch(statusChecking("idle"));

      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const deleteDataPerlengkapan = (payload) => async (dispatch) => {
  try {
    dispatch(statusChecking("process"));

    dispatch(setLoading(true));
    const response = await deletePerlengkapan(payload);
    if (response.status === 200) {
      dispatch(statusChecking("idle"));

      dispatch(handleSuccess(response.data));
      dispatch(
        getAllDataPerlengkapan({
          per_page: 10,
          page_number: 1,
          sort_column: "",
          sort_order: "Desc",
          keyword: "",
        })
      );
      dispatch(getAllDataMaster());
    } else {
      dispatch(statusChecking("idle"));

      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const duplicateDataPerlengkapan = (payload) => async (dispatch) => {
  try {
    dispatch(statusChecking("process"));

    dispatch(setLoading(true));
    const response = await duplicatePerlengkapan(payload);
    dispatch(setLoading(false));
    if (response.status === 200) {
      dispatch(statusChecking("idle"));

      dispatch(clearData());
      dispatch(
        getAllDataPerlengkapan({
          per_page: 10,
          page_number: 1,
          sort_column: "",
          sort_order: "Desc",
          keyword: "",
        })
      );
    } else {
      dispatch(statusChecking("idle"));

      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getDetailDataPerlengkapan = (payload, id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await getDetailPerlengkapan(payload);
    dispatch(setLoading(false));
    if (response.status === 200) {
      dispatch(setDetailData(response.data));

      let imgLogo = [
        {
          id: id,
          fileName: response.data.data.name,
          path: response.data.data.logo,
          name: "logo",
        },
        {
          id: id,
          fileName: response.data.data.name,
          path: response.data.data.thumbnail,
          name: "thumbnail",
        },
      ];

      let imgMulti = [];
      let key = 0;

      for (let data of response.data.data.gallery) {
        imgMulti.push({
          id: `${id} ${key}`,
          fileName: response.data.data.name,
          path: data.link,
          name: "gallery",
          type: data.type,
        });

        key++;
      }

      dispatch(setDataUpload(imgLogo));
      dispatch(setDataUploadMulti(imgMulti));
    } else {
      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const editDataPerlengkapan = (payload) => async (dispatch) => {
  try {
    dispatch(statusChecking("process"));

    dispatch(setLoading(true));
    const response = await editPerlengkapan(payload);
    dispatch(setLoading(false));
    if (response.status === 200) {
      dispatch(statusChecking("idle"));

      dispatch(handleSuccess(response.data));
      dispatch(clearData());
      dispatch(
        getAllDataPerlengkapan({
          per_page: 10,
          page_number: 1,
          sort_column: "",
          sort_order: "Desc",
          keyword: "",
        })
      );
      dispatch(getAllDataMaster());
    } else {
      dispatch(statusChecking("idle"));

      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

// data pembimbing
export const getAllDataPembimbing = (payload) => async (dispatch) => {
  try {
    dispatch(statusChecking("process"));
    dispatch(setLoading(true));
    const response = await getAllPembimbing(payload);
    dispatch(setLoading(false));
    if (response.status === 200) {
      dispatch(statusChecking("idle"));
      dispatch(setDataPembimbing(response.data));
    } else {
      dispatch(statusChecking("idle"));
      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const addDataPembimbing = (payload) => async (dispatch) => {
  try {
    dispatch(statusChecking("process"));
    dispatch(setLoading(true));
    const response = await addPembimbing(payload);
    dispatch(setLoading(false));
    if (response.status === 200) {
      dispatch(statusChecking("idle"));
      dispatch(clearData());
      dispatch(handleSuccess(response.data));

      dispatch(
        getAllDataPembimbing({
          per_page: 10,
          page_number: 1,
          sort_column: "",
          sort_order: "Desc",
          keyword: "",
        })
      );
      dispatch(getAllDataMaster());
    } else {
      dispatch(statusChecking("idle"));
      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const deleteDataPembimbing = (payload) => async (dispatch) => {
  try {
    dispatch(statusChecking("process"));

    dispatch(setLoading(true));
    const response = await deletePembimbing(payload);
    if (response.status === 200) {
      dispatch(statusChecking("idle"));
      dispatch(handleSuccess(response.data));
      dispatch(
        getAllDataPembimbing({
          per_page: 10,
          page_number: 1,
          sort_column: "",
          sort_order: "Desc",
          keyword: "",
        })
      );
      dispatch(getAllDataMaster());
    } else {
      dispatch(statusChecking("idle"));
      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const duplicateDataPembimbing = (payload) => async (dispatch) => {
  try {
    dispatch(statusChecking("process"));

    dispatch(setLoading(true));
    const response = await duplicatePembimbing(payload);
    dispatch(setLoading(false));
    if (response.status === 200) {
      dispatch(statusChecking("idle"));
      dispatch(clearData());
      dispatch(
        getAllDataPembimbing({
          per_page: 10,
          page_number: 1,
          sort_column: "",
          sort_order: "Desc",
          keyword: "",
        })
      );
    } else {
      dispatch(statusChecking("idle"));
      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getDetailDataPembimbing = (payload, id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await getDetailPembimbing(payload);
    dispatch(setLoading(false));
    if (response.status === 200) {
      dispatch(setDetailData(response.data));

      let imgLogo = [
        {
          id: id,
          fileName: response.data.data.name,
          path: response.data.data.photo,
          name: "image_photo",
        },
      ];

      dispatch(setDataUpload(imgLogo));
    } else {
      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const editDataPembimbing = (payload) => async (dispatch) => {
  try {
    dispatch(statusChecking("process"));

    dispatch(setLoading(true));
    const response = await editPembimbing(payload);

    dispatch(setLoading(false));
    if (response.status === 200) {
      dispatch(statusChecking("idle"));
      dispatch(clearData());
      dispatch(handleSuccess(response.data));

      dispatch(
        getAllDataPembimbing({
          per_page: 10,
          page_number: 1,
          sort_column: "",
          sort_order: "Desc",
          keyword: "",
        })
      );
      dispatch(getAllDataMaster());
    } else {
      dispatch(statusChecking("idle"));

      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};
