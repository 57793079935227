import React, { useState, useCallback } from "react";
import "./index.scss";
import IconEdit from "../../../../../assets/icons/colored/Edit-white.svg";
import CheckIcon from "../../../../../assets/icons/Checklist-Green.svg";
import { DocumentCopy, Edit, Eye, Trash } from "iconsax-react";
import Popup from "components/popup";
import { makeStyles } from "@material-ui/core";
import DokumenUploadKTP from "./DokumenUploadKTP";
import DokumenUploadPassport from "./DokumenUploadPassport";
import DokumenUploadKK from "./DokumenUploadKK";

const useStyle = makeStyles({
  containerPopup: {
    "& .card_title": {
      background: "#F2994A !important",
    },

    "& .title": {
      color: "white !important",
    },
  },
});

const DokumenItem = ({ title, image, contents }) => {
  const classes = useStyle();

  const [open, setOpen] = useState(false);
  const handleClosePopup = useCallback(() => {
    setOpen(false);
  }, []);

  const titlePopup = `Upload ${title}`;

  return (
    <>
      <div className="">
        <div className="paket-dokumen-perlengkapan-content px-[8px] py-[8px]">
          <div className="flex flex-row gap-[8px] items-center ml-[8px]">
            <div>
              <img src={image} className="w-[16px] h-[16px]" />
            </div>
            <div>
              <p>{title}</p>
            </div>
          </div>
          <div className="flex flex-row gap-[8px] items-center mr-[8px]">
            <Eye size="15" color="#141414" className="cursor-pointer" />
            <Edit
              size="15"
              color="#141414"
              className="cursor-pointer"
              onClick={() => setOpen(true)}
            />

            <Popup
              open={open}
              handleClose={handleClosePopup}
              title={titlePopup}
              width="611px"
              containerClass={classes.containerPopup}
            >
              <div>{contents}</div>
            </Popup>
          </div>
        </div>
      </div>
    </>
  );
};

const DokumenJamaah = () => {
  const items = [
    {
      title: "KTP",
      image: CheckIcon,
      content: <DokumenUploadKTP />,
    },
    {
      title: "Passport",
      image: CheckIcon,
      content: <DokumenUploadPassport />,
    },
    {
      title: "KK",
      image: CheckIcon,
      content: <DokumenUploadKK />,
    },
    {
      title: "Pas Foto 4X6",
      image: CheckIcon,
      content: <div>Pas Foto</div>,
    },
    {
      title: "Akta Kelahiran",
      image: CheckIcon,
      content: <div>Akta Kelahiran</div>,
    },
    {
      title: "Bukti Vaksin Miningitis",
      image: CheckIcon,
      content: <div>Bukti Vaksin</div>,
    },
    {
      title: "Sertifikat Vaksin Dosis 1",
      image: CheckIcon,
      content: <div>Dosis 1</div>,
    },
    {
      title: "Sertifikat Vaksin Dosis 2",
      image: CheckIcon,
      content: <div>Dosis 2</div>,
    },
    {
      title: "Sertifikat Vaksin Dosis 3",
      image: CheckIcon,
      content: <div>Dosis 3</div>,
    },
  ];

  return (
    <>
      <div className="z-[9999]">
        <div className="flex flex-col items-start w-[286px] h-full perlengkapan-shadow">
          <div className="w-[286px] bg-[#FAAB36] rounded-lg flex flex-row px-[8px] py-[8px]">
            <div>
              <p className="font-[16px] text-white font-semibold">
                Dokumen Jamaah
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-[8px] bg-white w-full h-[324px] absolute z-50">
          {items.map((item) => (
            <DokumenItem
              key={item.title}
              title={item.title}
              image={item.image}
              contents={item.content}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default DokumenJamaah;
