import Inputs from "components/form/inputs";
import Popup from "components/popup";
import React from "react";
import ReactQuill from "react-quill";
import { createWaEmailApi, getDetailCabangService } from "utils/api/cabang";
import EditorToolbar, {
  modules,
  formats,
} from "components/richTextEditor/EditorToolbar";
import Buttons from "components/buttons";
import { useDispatch } from "react-redux";
import { getAllCabang } from "stores/actions/cabang";
import { convertBase64 } from "utils/helpers/convertBase64";

function EditDescription({ show, close, id, data_detail }) {
  const dispatch = useDispatch();
  const [detail, setDetail] = React.useState(null);
  const [status, setStatus] = React.useState("idle");

  const onClose = () => {
    close();
    setDetail(null);
  };

  const getDetailCabang = () => {
    setDetail({
      ...data_detail,
      description: convertBase64("DECRYP", data_detail.description),
    });
    // getDetailCabangService(id).then((res) => {
    //   let _data = {
    //     ...res.data.data,
    //     description: convertBase64("DECRYP", res.data.data.description),
    //   };
    //   if (res.status === 200) setDetail(_data);
    // });
  };

  const onChange = (name, value) => {
    let field = { ...detail };
    field[name] = value;
    setDetail(field);
  };

  const onSubmit = () => {
    setStatus("submitting");
    let payload = {
      url_website: detail.url_website,
      judul_domain: detail.judul_domain,
      deskripsi: convertBase64("ENCRYP", detail.description),
      id: id,
    };
    createWaEmailApi(payload)
      .then((res) => {
        if (res.status === 200) {
          setDetail(null);
          setStatus("idle");
          dispatch(getAllCabang());
          close();
        }
      })
      .catch((err) => {
        console.log("err: ", err);
        setStatus("error");
      });
  };

  React.useEffect(() => {
    if (id) getDetailCabang();
  }, [id, data_detail]);

  return (
    <Popup
      width="982px"
      open={show}
      handleClose={onClose}
      title="Edit Deskripsi"
    >
      <div className="grid grid-cols-2 gap-4">
        <Inputs
          id="judul_domain"
          placeholder="Mosleme Travel Pusat"
          label="Judul Website"
          name="judul_domain"
          value={detail?.judul_domain}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
        <Inputs
          id="url_website"
          label="Domain Website"
          placeholder="mosleme.travel"
          name="url_website"
          value={detail?.url_website}
          onChange={(e) => onChange(e.target.name, e.target.value)}
        />
      </div>
      <div className="text-editor mt-4">
        <EditorToolbar />
        <ReactQuill
          id="description"
          name="description"
          theme="snow"
          value={detail?.description}
          modules={modules}
          formats={formats}
          onChange={(val) => onChange("description", val)}
          className="bg-[#EDF2F4] w-full h-[204px]"
        />
      </div>
      <div className="py-3 flex justify-end">
        <Buttons
          loading={status === "submitting"}
          onClick={onSubmit}
          text="Simpan"
          customClass="btn-primary btn-medium !w-fit"
        />
      </div>
    </Popup>
  );
}

export default EditDescription;
