import {
  CloseOutlined,
  DeleteOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from "@ant-design/icons";
import { Modal } from "@material-ui/core";
import Badge from "components/badge";
import AlertPopup from "components/popup/alert";
import { createRef, useState } from "react";

const ModalImage = ({ listDataGalery }) => {
  let imgRef = createRef();
  const [count, setCount] = useState(1);
  const [idImage, setIdImage] = useState({});
  const [modal, setModal] = useState({
    open: false,
    delete: false,
  });

  const onHandleIdImage = (item) => {
    setModal({ ...modal, open: true });
    setIdImage(item);
  };

  const onHandleDelete = () => {
    setModal({
      ...modal,
      delete: false,
    });
  };

  return (
    <main>
      <section className="flex justify-between items-center text-base my-[16px]">
        <Badge
          label={`Hari ke-${listDataGalery?.day}`}
          backgroundColor="#D8E1BE"
          color="#3D3D3D"
        />
        <div className="rounded-tl-2xl  rounded-br-2xl px-3 py-[6px] text-[12px] font-semibold bg-[#EDBABA] text-[#FE0000]">
          {listDataGalery?.rute?.join(",")}
        </div>
      </section>
      <div className="grid grid-cols-4 items-center gap-3">
        {listDataGalery?.gallery?.map((item, idx) => {
          return (
            <div key={idx} className="relative cursor-pointer">
              <img
                alt="gambar"
                src={item?.link}
                className="rounded-xl  h-[160px] w-[160px]  object-cover"
                onClick={() => onHandleIdImage(item.link)}
              />
            </div>
          );
        })}
      </div>

      <Modal open={modal.open}>
        <main className="flex justify-center h-screen items-center ln-random">
          <section className="drop-shadow z-50 absolute top-0 w-full items-center flex justify-between  py-4 px-10">
            <div
              onClick={() => setModal({ ...modal, delete: true })}
              className="btn-modal cursor-pointer"
            >
              <DeleteOutlined />
            </div>
            <div className="flex gap-x-8 items-center noselect">
              <div
                className={`btn-modal `}
                onClick={() =>
                  count >= 4 ? setCount(4) : setCount(count + 0.5)
                }
              >
                <ZoomInOutlined
                  className={`${count === 4 ? "text-gray-400" : "text-white"}`}
                />
              </div>
              <div
                className={`btn-modal `}
                onClick={() =>
                  count <= 1 ? setCount(0.5) : setCount(count - 0.5)
                }
              >
                <ZoomOutOutlined
                  className={`${
                    count === 0.5 ? "text-gray-400" : "text-white"
                  }`}
                />
              </div>
              <div
                className="bg-[#8D8D8D] w-7 h-7 flex justify-center items-center rounded-full cursor-pointer"
                onClick={() => {
                  setModal({ ...modal, open: false });
                  setCount(1);
                }}
              >
                <CloseOutlined className="text-white" />
              </div>
            </div>
          </section>
          <img
            alt="gambar"
            ref={imgRef}
            style={{
              transition: 2,
              transitionDelay: 3,
              transform: `scale(${count})`,
            }}
            src={idImage}
            className="max-w-auto max-h-auto"
          />
        </main>
      </Modal>
      <AlertPopup
        open={modal.delete}
        handleClose={() => setModal({ ...modal, delete: false })}
        handleContinue={onHandleDelete}
        title="Hapus gambar"
        subtitle="Apakah Anda sudah yakin ingin menghapus data ini? "
      />
    </main>
  );
};

export default ModalImage;
