import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";

import Selects from "../../../../../../components/form/selects";
import Rates from "../../../../../../components/form/rate";
import Hotel from "../../../../../../assets/images/hotel.jpg";
import IconLocation from "../../../../../../assets/icons/locationblack.svg";
import Timepickers from "../../../../../../components/timepickers";
import DatePicker from "../../../../../../components/datepicker/DatePicker";
import Buttons from "../../../../../../components/buttons";

import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useParams } from "react-router-dom";
import { createPaketAkomodasi } from "stores/actions/paket/akomodasi";

import { handleErrorBE, handleSuccess } from "stores/actions/errorGeneral";

const AddOrEditAkomodasi = ({
  setIsOpen,
  allPaketAkomodasi,
  choice,
  setChoce,
}) => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const { dataAkomodasi } = useSelector((state) => state.datamaster);
  const [masterAkomodasi, setMasterAkomodasi] = useState([]);
  const [isLoadingAction, setIsLoadingAction] = useState(false);
  const [valueAkomodasi, setValueAkomodasi] = useState([
    { label: "", value: "" },
  ]);

  useEffect(() => {
    if (dataAkomodasi) {
      const result_map = dataAkomodasi?.data?.map((item) => ({
        ...item,
        value: item?.id_akomodasi,
        label: item?.name,
      }));

      setMasterAkomodasi(result_map);
    }
  }, [dataAkomodasi]);

  const [form, setForm] = useState({
    id_paket: id,
    id_akomodasi: "",
    checkin_date: null,
    checkin_time: null,
    checkout_date: null,
    checkout_time: null,
  });

  useEffect(() => {
    if (choice !== null) {
      setForm({
        ...form,
        id_akomodasi: choice?.id_akomodasi,
        checkin_date: choice?.checkin_date,
        checkin_time: choice?.checkin_time,
        checkout_time: choice?.checkout_time,
        checkout_date: choice?.checkout_date,
      });
    }
  }, [choice]);

  const onSubmit = () => {
    if (choice !== null) {
      setIsLoadingAction(true);
      dispatch(
        createPaketAkomodasi(
          { ...form, id: choice?.id },
          (message) => {
            setIsLoadingAction(false);
            setIsOpen(false);
            allPaketAkomodasi();
            setChoce(null);

            dispatch(handleSuccess(message));
          },
          (error) => {
            setIsLoadingAction(false);
            setIsOpen(false);
            setChoce(null);

            dispatch(handleErrorBE(error));
          }
        )
      );
    } else {
      setIsLoadingAction(true);
      dispatch(
        createPaketAkomodasi(
          form,
          (message) => {
            setIsLoadingAction(false);
            setIsOpen(false);
            allPaketAkomodasi();
            setChoce(null);
            dispatch(handleSuccess(message));
          },
          (error) => {
            setIsLoadingAction(false);
            setIsOpen(false);
            setChoce(null);

            dispatch(handleErrorBE(error));
          }
        )
      );
    }
  };

  const handleDisableAction = () => {
    if (
      form.id_akomodasi === "" ||
      form.checkin_date === "" ||
      form.checkin_time === "" ||
      form.checkin_date === ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div className="border-[1px] border-[#E0E0E0] rounded-2xl">
        <div className="p-5">
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Selects
                label="Pilih Akomodasi"
                placeholder="Pilih Akomodasi"
                value={
                  choice !== null
                    ? masterAkomodasi.filter(
                        (item) => item.value === form.id_akomodasi
                      )
                    : valueAkomodasi
                }
                options={masterAkomodasi}
                onChange={(e) => {
                  setForm({ ...form, id_akomodasi: e[0]?.value });
                  setValueAkomodasi(e);
                }}
                searchable
                itemRenderer={({ state, item, methods }) => {
                  return (
                    <div
                      className={`react-dropdown-select-item flex flex-row gap-8 !py-6 ${
                        state?.values?.length === 0 &&
                        state?.values[0]?.value === item?.value &&
                        "custom-item-selected"
                      }`}
                      onClick={() => methods.addItem(item)}
                    >
                      <div className="flex flex-row items-center gap-4">
                        <div className=" bg-white h-[40px] w-[40px] rounded-[20px] flex flex-row items-center p-[2px]">
                          <img
                            src={item?.logo}
                            alt="maskapai"
                            width={40}
                            height={40}
                            className="overflow-hidden rounded-[20px]"
                          />
                        </div>
                        <div className="flex flex-col gap-2">
                          <div className="font-semibold">{item?.label}</div>
                          <Rates value={item?.bintang} />
                        </div>
                      </div>
                      <div className="flex flex-row items-center gap-2">
                        <img src={IconLocation} alt="location" />
                        <div>{item?.alamat_lengkap}</div>
                      </div>
                    </div>
                  );
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} alignItems="flex-start">
            <Grid item md={6}>
              <div className="mb-4">Tanggal Checkin</div>
              <DatePicker
                customClass="w-full"
                placeholder={form?.checkin_date ?? "Pilih Tanggal Checkin"}
                onChange={(val) =>
                  setForm({
                    ...form,
                    checkin_date: moment(val, "DD-MM-YYYY").format(
                      "YYYY-MM-DD"
                    ),
                  })
                }
              />
            </Grid>
            <Grid item md={6}>
              <div className="mb-4">Waktu Checkin</div>
              <Timepickers
                containerClass="relative"
                placeholder={form?.checkin_time ?? "Pilih Waktu Checkin"}
                onSelect={(data) => {
                  setForm({
                    ...form,
                    checkin_time: moment(data?.$d).format("HH:mm"),
                  });
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} alignItems="flex-start">
            <Grid item md={6}>
              <div className="mb-4">Tanggal Checkout</div>
              <DatePicker
                customClass="w-full"
                placeholder={form?.checkout_date ?? "Pilih Tanggal Checkout"}
                onChange={(val) =>
                  setForm({
                    ...form,
                    checkout_date: moment(val, "DD-MM-YYYY").format(
                      "YYYY-MM-DD"
                    ),
                  })
                }
              />
            </Grid>
            <Grid item md={6}>
              <div className="mb-4">Waktu Checkout</div>
              <Timepickers
                containerClass="relative"
                placeholder={form?.checkout_time ?? "Pilih Waktu Checkout"}
                onSelect={(data) => {
                  setForm({
                    ...form,
                    checkout_time: moment(data?.$d).format("HH:mm"),
                  });
                }}
              />
            </Grid>
          </Grid>
        </div>
        <div className="divider"></div>
        <div className="px-[20px] py-[16px] flex flex-row justify-end">
          <Buttons
            text="Simpan"
            customClass="btn-primary btn-large"
            onClick={onSubmit}
            loading={isLoadingAction}
            disabled={handleDisableAction()}
          />
        </div>
      </div>
    </>
  );
};

export default React.memo(AddOrEditAkomodasi);
