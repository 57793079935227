import React from "react";
import PropTypes from "prop-types";
import Popup from "../../../popup";
import Inputs from "../../../form/inputs";
import Buttons from "../../../buttons";
import { makeStyles } from "@material-ui/core";
import FotoProfile from "../../../../assets/images/profile_new.png";
const useStyles = makeStyles({
  card_popup_profile: {
    width: "100%",
    height: "330px",
    padding: "20px",
    borderWidth: "1px 1px 1px 1px",
    borderStyle: "solid",
    borderColor: "rgba(64, 72, 82, 0.1)",
    borderRadius: "16px 16px 0px 0px",
    "& .icon_profile": {
      marginBottom: "10px",
      width: "60px",
      height: "60px",
      borderRadius: "30px",
      cursor: "pointer",
      position: "relative",
      "& .icon_active": {
        width: "10px",
        height: "10px",
        borderRadius: "5px",
        background: "#A7C957",
        border: "1px solid #ffff",
        position: "absolute",
        left: "2px",
        bottom: "4px",
      },
    },
  },
  container_button: {
    marginTop: "10px",
    marginBottom: "10px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
});

const EditProfile = ({ openProfile, handleClosePopupProfile, dataProfile }) => {
  const classes = useStyles();
  return (
    <Popup
      type="secondary"
      width="1080px"
      height="486px"
      open={openProfile}
      handleClose={handleClosePopupProfile}
      title="Edit Profil"
    >
      <div className={classes.card_popup_profile}>
        <div className="icon_profile">
          <img src={FotoProfile} alt="fotoProfile" width={56} height={56} />
          <div className="icon_active"></div>
        </div>
        <Inputs
          id="test"
          label="Fullname"
          placeholder="Text Input"
          customClass="mt-[5px]"
        />
        <div className="mt-3">
          <Inputs
            id="test"
            label="Nickname"
            placeholder="Text Input"
            customClass="mt-[5px]"
          />
        </div>
      </div>
      <div className={classes.container_button}>
          <Buttons text="Simpan" customClass="btn-primary btn-medium" />
        </div>
    </Popup>
  );
};

EditProfile.propTypes = {
  dataProfile: PropTypes.object.isRequired,
  openProfile: PropTypes.bool.isRequired,
  handleClosePopupProfile: PropTypes.func.isRequired,
};
EditProfile.defaultProps = {
  dataProfile: {},
  openProfile: false,
  handleClosePopupProfile: () => {},
};

export default React.memo(EditProfile);
