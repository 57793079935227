import React, { useState } from "react";
import TaskIcon from "../../../../../assets/icons/task-table.svg";
import DokumenJamaah from "./DokumenJamaah";

const PerlengkapanDokumen = ({ value }) => {
  const [isClicked, setIsClicked] = useState(false);
  const [maxPerlengkapan, setMaxPerlengkapan] = useState(10);
  return (
    <>
      <div className="flex flex-row absolute top-[20px]">
        <div
          className={`flex flex-row items-center px-[6px] py-[12px] gap-[4px] w-[153px] h-[28px] border rounded-lg cursor-pointer  ${
            value > 0 ? "bg-green-100" : ""
          }`}
          onClick={() => setIsClicked(!isClicked)}
        >
          <div>
            <img
              src={TaskIcon}
              alt="perlengkapan"
              className="w-[16px] h-[16px]"
            />
          </div>
          <div className="flex flex-row gap-[4px]">
            <p className="font-[14px]">Perlengkapan</p>
            <p className="font-[14px]">
              ({value}/{maxPerlengkapan})
            </p>
          </div>
        </div>
        {isClicked && value > 0 && (
          <div className="absolute z-50 top-[20px] bottom-0 z-[9999]">
            <DokumenJamaah />
          </div>
        )}
      </div>
    </>
  );
};

export default PerlengkapanDokumen;
