import React, { useState } from "react";
import IconFoto from "assets/icons/gallery-black.svg";
import Selects from "components/form/selects";
import Buttons from "components/buttons";
import FotoUmroh from "assets/images/umrohImg.png";
import { Grid } from "@material-ui/core";
import Checkbox from "components/checkbox";
import Popup from "components/popup";
import UploadFoto from "./UploadFoto";

const AlbumFoto = () => {
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [showImages, setShowImages] = useState(true);
  const [openPopupAddFoto, setOpenPopupAddFoto] = useState(false);

  const [options, setOptions] = useState([
    {
      id: 1,
      value: "semua-foto",
      label: "Semua Foto",
    },
    {
      id: 2,
      value: "foto-tidak-ditampilkan",
      label: "Foto Tidak Ditampilkan",
    },
    {
      id: 3,
      value: "foto-ditampilkan",
      label: "Foto Ditampilkan",
    },
  ]);

  return (
    <>
      <Popup
        open={openPopupAddFoto}
        handleClose={() => {
          setOpenPopupAddFoto(false);
        }}
        title="Upload Foto"
        containerClass=""
        children={<UploadFoto />}
      />

      <div className="p-[16px] flex flex-col gap-[16px] bg-white soft-shadow rounded-3xl">
        <div className="flex flex-row justify-between items-center p-[16px] gap-[8px] rounded-xl border">
          <div className="flex flex-row items-center gap-[28px]">
            <div className="flex flex-row items-center gap-[8px]">
              <img src={IconFoto} alt="galery foto" />
              <p className="text-sm text-black font-semibold">Foto</p>
            </div>
            <div>
              <Selects
                placeholder="Semua Foto"
                customClass="!w-[200px] z-30"
                options={options}
                searchable
                itemRenderer={({ state, item, methods }) => {
                  return (
                    <div
                      className={`react-dropdown-select-item ${
                        state.values.length !== 0 &&
                        state.values[0].value === item.value &&
                        "react-dropdown-select-item-selected"
                      }`}
                      onClick={() => methods.addItem(item)}
                    >
                      {item.label}
                    </div>
                  );
                }}
              />
            </div>
          </div>
          <div>
            <Buttons
              text="Upload Foto"
              customClass="btn-outline btn-medium"
              onClick={() => setOpenPopupAddFoto(true)}
            />
          </div>
        </div>
        <div className="flex justify-center items-center">
          <Grid container spacing={2}>
            <Grid item md={4}>
              <div className="w-[256.5px] h-[158px] m-auto relative">
                <div className="flex flex-row justify-center items-center w-[105px] h-[32px] bg-white z-10 absolute top-0 right-0 bottom-0 rounded-bl-xl gap-[9px]">
                  <p className="text-sm text-black font-semibold">
                    Ditampilkan
                  </p>
                  <div>
                    <Checkbox
                      indeterminate={indeterminate}
                      checked={checked}
                      onChange={(e) => {
                        setChecked(e.target.checked);
                        setIndeterminate(false);
                      }}
                    />
                  </div>
                </div>
                <img
                  src={FotoUmroh}
                  alt="Foto Hotel"
                  className="w-full h-full rounded-lg bg-cover bg-center"
                />
              </div>
            </Grid>
            <Grid item md={4}>
              <div className="w-[256.5px] h-[158px] m-auto relative">
                <div className="flex flex-row justify-center items-center w-[105px] h-[32px] bg-white z-10 absolute top-0 right-0 bottom-0 rounded-bl-xl gap-[9px]">
                  <p className="text-sm text-black font-semibold">
                    Ditampilkan
                  </p>
                  <div>
                    <Checkbox
                      indeterminate={indeterminate}
                      checked={checked}
                      onChange={(e) => {
                        setChecked(e.target.checked);
                        setIndeterminate(false);
                      }}
                    />
                  </div>
                </div>
                <img
                  src={FotoUmroh}
                  alt="Foto Hotel"
                  className="w-full h-full rounded-lg bg-cover bg-center"
                />
              </div>
            </Grid>
            <Grid item md={4}>
              <div className="w-[256.5px] h-[158px] m-auto relative">
                <div className="flex flex-row justify-center items-center w-[105px] h-[32px] bg-white z-10 absolute top-0 right-0 bottom-0 rounded-bl-xl gap-[9px]">
                  <p className="text-sm text-black font-semibold">
                    Ditampilkan
                  </p>
                  <div>
                    <Checkbox
                      indeterminate={indeterminate}
                      checked={checked}
                      onChange={(e) => {
                        setChecked(e.target.checked);
                        setIndeterminate(false);
                      }}
                    />
                  </div>
                </div>
                <img
                  src={FotoUmroh}
                  alt="Foto Hotel"
                  className="w-full h-full rounded-lg bg-cover bg-center"
                />
              </div>
            </Grid>
            <Grid item md={4}>
              <div className="w-[256.5px] h-[158px] m-auto relative">
                <div className="flex flex-row justify-center items-center w-[105px] h-[32px] bg-white z-10 absolute top-0 right-0 bottom-0 rounded-bl-xl gap-[9px]">
                  <p className="text-sm text-black font-semibold">
                    Ditampilkan
                  </p>
                  <div>
                    <Checkbox
                      indeterminate={indeterminate}
                      checked={checked}
                      onChange={(e) => {
                        setChecked(e.target.checked);
                        setIndeterminate(false);
                      }}
                    />
                  </div>
                </div>
                <img
                  src={FotoUmroh}
                  alt="Foto Hotel"
                  className="w-full h-full rounded-lg bg-cover bg-center"
                />
              </div>
            </Grid>
            <Grid item md={4}>
              <div className="w-[256.5px] h-[158px] m-auto relative">
                <div className="flex flex-row justify-center items-center w-[105px] h-[32px] bg-white z-10 absolute top-0 right-0 bottom-0 rounded-bl-xl gap-[9px]">
                  <p className="text-sm text-black font-semibold">
                    Ditampilkan
                  </p>
                  <div>
                    <Checkbox
                      indeterminate={indeterminate}
                      checked={checked}
                      onChange={(e) => {
                        setChecked(e.target.checked);
                        setIndeterminate(false);
                      }}
                    />
                  </div>
                </div>
                <img
                  src={FotoUmroh}
                  alt="Foto Hotel"
                  className="w-full h-full rounded-lg bg-cover bg-center"
                />
              </div>
            </Grid>
            <Grid item md={4}>
              <div className="w-[256.5px] h-[158px] m-auto relative">
                <div className="flex flex-row justify-center items-center w-[105px] h-[32px] bg-white z-10 absolute top-0 right-0 bottom-0 rounded-bl-xl gap-[9px]">
                  <p className="text-sm text-black font-semibold">
                    Ditampilkan
                  </p>
                  <div>
                    <Checkbox
                      indeterminate={indeterminate}
                      checked={checked}
                      onChange={(e) => {
                        setChecked(e.target.checked);
                        setIndeterminate(false);
                      }}
                    />
                  </div>
                </div>
                <img
                  src={FotoUmroh}
                  alt="Foto Hotel"
                  className="w-full h-full rounded-lg bg-cover bg-center"
                />
              </div>
            </Grid>
          </Grid>
        </div>
        {showImages && (
          <div className="flex justify-center items-center">
            <Grid container spacing={2}>
              <Grid item md={4}>
                <div className="w-[256.5px] h-[158px] m-auto relative">
                  <div className="flex flex-row justify-center items-center w-[105px] h-[32px] bg-white z-10 absolute top-0 right-0 bottom-0 rounded-bl-xl gap-[9px]">
                    <p className="text-sm text-black font-semibold">
                      Ditampilkan
                    </p>
                    <div>
                      <Checkbox
                        indeterminate={indeterminate}
                        checked={checked}
                        onChange={(e) => {
                          setChecked(e.target.checked);
                          setIndeterminate(false);
                        }}
                      />
                    </div>
                  </div>
                  <img
                    src={FotoUmroh}
                    alt="Foto Hotel"
                    className="w-full h-full rounded-lg bg-cover bg-center"
                  />
                </div>
              </Grid>
              <Grid item md={4}>
                <div className="w-[256.5px] h-[158px] m-auto relative">
                  <div className="flex flex-row justify-center items-center w-[105px] h-[32px] bg-white z-10 absolute top-0 right-0 bottom-0 rounded-bl-xl gap-[9px]">
                    <p className="text-sm text-black font-semibold">
                      Ditampilkan
                    </p>
                    <div>
                      <Checkbox
                        indeterminate={indeterminate}
                        checked={checked}
                        onChange={(e) => {
                          setChecked(e.target.checked);
                          setIndeterminate(false);
                        }}
                      />
                    </div>
                  </div>
                  <img
                    src={FotoUmroh}
                    alt="Foto Hotel"
                    className="w-full h-full rounded-lg bg-cover bg-center"
                  />
                </div>
              </Grid>
              <Grid item md={4}>
                <div className="w-[256.5px] h-[158px] m-auto relative">
                  <div className="flex flex-row justify-center items-center w-[105px] h-[32px] bg-white z-10 absolute top-0 right-0 bottom-0 rounded-bl-xl gap-[9px]">
                    <p className="text-sm text-black font-semibold">
                      Ditampilkan
                    </p>
                    <div>
                      <Checkbox
                        indeterminate={indeterminate}
                        checked={checked}
                        onChange={(e) => {
                          setChecked(e.target.checked);
                          setIndeterminate(false);
                        }}
                      />
                    </div>
                  </div>
                  <img
                    src={FotoUmroh}
                    alt="Foto Hotel"
                    className="w-full h-full rounded-lg bg-cover bg-center"
                  />
                </div>
              </Grid>
              <Grid item md={4}>
                <div className="w-[256.5px] h-[158px] m-auto relative">
                  <div className="flex flex-row justify-center items-center w-[105px] h-[32px] bg-white z-10 absolute top-0 right-0 bottom-0 rounded-bl-xl gap-[9px]">
                    <p className="text-sm text-black font-semibold">
                      Ditampilkan
                    </p>
                    <div>
                      <Checkbox
                        indeterminate={indeterminate}
                        checked={checked}
                        onChange={(e) => {
                          setChecked(e.target.checked);
                          setIndeterminate(false);
                        }}
                      />
                    </div>
                  </div>
                  <img
                    src={FotoUmroh}
                    alt="Foto Hotel"
                    className="w-full h-full rounded-lg bg-cover bg-center"
                  />
                </div>
              </Grid>
              <Grid item md={4}>
                <div className="w-[256.5px] h-[158px] m-auto relative">
                  <div className="flex flex-row justify-center items-center w-[105px] h-[32px] bg-white z-10 absolute top-0 right-0 bottom-0 rounded-bl-xl gap-[9px]">
                    <p className="text-sm text-black font-semibold">
                      Ditampilkan
                    </p>
                    <div>
                      <Checkbox
                        indeterminate={indeterminate}
                        checked={checked}
                        onChange={(e) => {
                          setChecked(e.target.checked);
                          setIndeterminate(false);
                        }}
                      />
                    </div>
                  </div>
                  <img
                    src={FotoUmroh}
                    alt="Foto Hotel"
                    className="w-full h-full rounded-lg bg-cover bg-center"
                  />
                </div>
              </Grid>
              <Grid item md={4}>
                <div className="w-[256.5px] h-[158px] m-auto relative">
                  <div className="flex flex-row justify-center items-center w-[105px] h-[32px] bg-white z-10 absolute top-0 right-0 bottom-0 rounded-bl-xl gap-[9px]">
                    <p className="text-sm text-black font-semibold">
                      Ditampilkan
                    </p>
                    <div>
                      <Checkbox
                        indeterminate={indeterminate}
                        checked={checked}
                        onChange={(e) => {
                          setChecked(e.target.checked);
                          setIndeterminate(false);
                        }}
                      />
                    </div>
                  </div>
                  <img
                    src={FotoUmroh}
                    alt="Foto Hotel"
                    className="w-full h-full rounded-lg bg-cover bg-center"
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        )}
        <div>
          <Buttons
            text={
              showImages
                ? "Sembunyikan Foto Sebelumnya"
                : "Lihat Foto Selengkapnya"
            }
            onClick={() => setShowImages(!showImages)}
            customClass="btn-secondary btn-medium"
          />
        </div>
      </div>
    </>
  );
};

export default AlbumFoto;
