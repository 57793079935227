import styled from "styled-components";

const SelectStyle = styled.div`
  width: 100%;

  .ic-left {
    position: absolute;
    left: 24px;
    top: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  .ic-right {
    position: absolute;
    right: 0;
    top: 50%;
    bottom: 50%;
    transform: translate(-50%, -50%);
  }

  .custom-select.select-padding--left {
    padding: 16px 48px 16px 32px;
  }

  .custom-select {
    width: 100%;
    border-color: transparent;
    box-shadow: none !important;
    padding: 16px;
    min-height: 54px;
    width: 100%;
    background: #f2f2f2;
    border: 0;
    border-radius: 12px;

    .react-dropdown-select-content {
      flex-wrap: nowrap!important;
      white-space: nowrap;
      font-size: 14px;
    }

    &::placeholder {
      color: #828282;
    }

    &:hover {
      background: #ffdcdc;
      border: none;
    }

    &:focus {
      box-shadow: none;
    }

    .react-dropdown-select-input {
      font-size: 14px;
    }

    .react-dropdown-select-dropdown {
      border: none;
      border-radius: 8px;
      font-size: 14px;
    }

    .react-dropdown-select-item {
      border: none;
      cursor: pointer;
      padding: 8px 16px;

      &:hover {
        background: #ffdcdc;
        cursor: pointer;
      }
    }

    .react-dropdown-select-dropdown
      .react-dropdown-select-item:not(:first-child),
    .react-dropdown-select-dropdown
      .react-dropdown-select-item:not(:last-child) {
      border-bottom: 1px solid #e0e0e0;
    }

    .react-dropdown-select-item-selected {
      background: linear-gradient(rgb(234, 54, 52) 0%, rgb(148, 17, 15) 100%);
      color: white;
      &:hover {
        background: linear-gradient(rgb(222, 26, 23) 0%, rgb(111, 13, 11) 100%);
        color: white;
      }
    }

    .custom-item-selected {
      background: #ffdcdc;
      &:hover {
        background: #ffdcdc;
      }
    }

    .react-dropdown-select-no-data {
      color: rgb(232, 35, 32);
    }

    .react-dropdown-select-dropdown-handle {
      color: #0b090a;
      &:hover {
        color: #0b090a;
      }

      svg {
        path {
          stroke: none;
        }
      }
    }
  }

  .select-error {
    border: 1px solid #e82320;
    border-radius: 12px;

    &:hover {
      border: 1px solid #e82320;
    }

    &:focus-within {
      border: 1px solid #e82320;
    }
  }

  .select-error-msg {
    color: #e82320;
    margin-left: 8px;
  }

  .select-success {
    border: 1px solid #97e03b;
    border-radius: 12px;

    &:hover {
      border: 1px solid #97e03b;
    }

    &:focus-within {
      border: 1px solid #97e03b;
    }
  }

  .select-success-msg {
    color: #97e03b;
    margin-left: 8px;
  }

  .custom-select.select-disabled {
    background: #f2f2f2 !important;
  }

  .select-label {
    font-size: 14px;
    font-weight: 600;
    color: #4f4f4f;
    margin-bottom: 8px;

    &--disabled {
      color: #828282;
    }
  }
`;

export default SelectStyle;
