import { useState } from "react";
import { makeStyles } from "@material-ui/core";

import Inputs from "../../../../../../components/form/inputs";
import Buttons from "../../../../../../components/buttons";
import { Trash } from "iconsax-react";

const useStyle = makeStyles({
  step2: {
    "& .input-label, .select-label": {
      fontSize: "14px",
      fontWeight: 600,
      color: "#4f4f4f",
      marginBottom: "4px",
    },
  },
});

const Step2 = ({ items, handleFormChange, setItems, handleDeletBus }) => {
  const classes = useStyle();

  return (
    <div className={classes.step2}>
      {items.map((item, index) => (
        <div className="flex gap-5" key={index}>
          <Inputs
            label={`Bus ${index + 1}`}
            placeholder="Masukan Bus"
            containerClass="w-full mb-2"
            name="nama_bus"
            value={item.nama_bus}
            onChange={(e) => handleFormChange(index, e)}
          />
          <Inputs
            label="Jumlah Seat"
            placeholder="Masukan Seat"
            name="jumlah_seat"
            containerClass=" mb-2"
            value={item.jumlah_seat}
            onChange={(e) => handleFormChange(index, e)}
          />
          {index !== 0 && (
            <Trash
              className="cursor-pointer mt-9"
              onClick={() => handleDeletBus(index)}
              size="20"
              color="#141414"
            />
          )}
        </div>
      ))}
      <Buttons
        text="Tambah Bus"
        customClass="btn-outline btn-medium mt-4"
        onClick={() => setItems([...items, { nama_bus: "", jumlah_seat: "" }])}
      />
    </div>
  );
};

export default Step2;
