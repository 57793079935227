import styled from "styled-components";

import BackgroundPink from "../../../assets/images/bg-pink-shine.jpg";

const BookingDetailStyle = styled.div`
  .card-detail-booking-header {
    padding: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 16px;

    .card-detail-booking-header-pink {
      background-image: url(${BackgroundPink});
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      border-radius: 12px;
      padding: 12px 24px;

      .card-detail-booking-header-pink-avatar {
        width: 56px;
        height: 56px;
      }

      .card-detail-booking-header-pink-transparent {
        background: rgba(255, 255, 255, 0.2);
        border-radius: 8px;
        padding: 12px 16px;
      }
    }
  }

  .card-detail-booking-paket {
    background: #ffffff;
    border: 0.5px solid #e0e0e0;
    box-shadow: 0px 10px 16px -6px rgba(0, 0, 0, 0.04),
      0px 4px 80px -4px rgba(0, 0, 0, 0.04);
    border-radius: 12px;

    .card-detail-booking-image {
      height: 109px;
      width: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }

  .card-detail-booking-content {
    background: #ffffff;
    border: 0.5px solid #e0e0e0;
    box-shadow: 0px 10px 16px -6px rgba(0, 0, 0, 0.04),
      0px 4px 80px -4px rgba(0, 0, 0, 0.04);
    border-radius: 12px;

    .card-detail-booking-content-total-jamaah {
      background: linear-gradient(204.44deg, #01f91a -18.49%, #359b05 84.72%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      font-weight: 600;
      font-size: 20px;
    }
  }
`;

export default BookingDetailStyle;
