// import React from "react";
// import { useSelector } from "react-redux";
// import { Route, Redirect } from "react-router-dom";
// import { getLocalStorage } from "./utils/helpers";
// const PrivateRoute = ({ component: Component, ...rest }) => {
//   const { isAuthenticated } = useSelector((state) => state.login);
//   const userLogin =
//     getLocalStorage("userLogin") && JSON.parse(getLocalStorage("userLogin"));

//     console.log("userLogin", userLogin.data.account.access_data)

//   return (
//     <Route
//       {...rest}
//       render={(props) =>
//         isAuthenticated || userLogin.isAuthenticated ? (
//           <Component {...props} />
//         ) : (
//           <Redirect to="/login" />
//         )
//       }
//     />
//   );
// };

// export default PrivateRoute;

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import { getLocalStorage } from "./utils/helpers";
import UnauthorizedPage from "pages/unauthorized";
import { setVisibilityFalse, setAllowedFalse, setAllowedTrue } from "stores/actions/layout";
const PrivateRoute = ({ component: Component, allowedAccessData, allowedPermissions, ...rest }) => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector((state) => state.login);
  const userLogin =
    getLocalStorage("userLogin") && JSON.parse(getLocalStorage("userLogin"));

  const accessData = userLogin?.data?.account?.access_data;
  const permissions = userLogin?.data?.account?.permissions;

  const isAuth = isAuthenticated || userLogin.isAuthenticated;

  const onHandleCheckPermissions = () => {
    return permissions.some(permission => allowedPermissions.includes(permission));
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuth) {
          if (allowedAccessData.includes(accessData)) {
            
            if(allowedPermissions.length) {
              
              if(onHandleCheckPermissions()) {
                dispatch(setAllowedTrue());
              } else {
                dispatch(setAllowedFalse());
              }

            } else {
              dispatch(setAllowedTrue());
            }

          } 
          else {
            dispatch(setVisibilityFalse());
          }

          return <Component {...props} />;
        } else {
          // Pengguna tidak terautentikasi
          dispatch(setVisibilityFalse());

          return <Redirect to="/login" />;
        }
      }}
      // isAuth && allowedAccessData.includes(accessData) ? (
      //   <Component {...props} />
      // ) : (
      //   <Redirect to={isAuth ? "/unauthorized" : "/login"} />
      // )
    />
  );
};

export default PrivateRoute;
