import { Radio, Checkbox } from 'antd';
import React, { useState } from 'react';
import MoslemeTable from '.';
import './action.css';
import { DocumentCopy, Edit, Eye, Trash } from 'iconsax-react';

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    render: (text) => <span>{text}</span>,
  },
  {
    title: 'Age',
    dataIndex: 'age',
  },
  {
    title: 'Address',
    dataIndex: 'address',
  },
  {
    title: 'Status aktif',
    dataIndex: 'status_active',
    className: 'whitespace-nowrap relative',
    render: (s) => (
      <div>
        {s}
        <div className="action">
          <Eye onClick={() => alert(`show id:`)} size="15" color="#141414" />
          <Trash onClick={() => alert(`delete id:`)} size="15" color="#141414" />
        </div>
      </div>
    ),
  },

  {
    title: 'Status email',
    dataIndex: 'status_email',
    className: 'whitespace-nowrap',
    render: (s) => (
      <div>
        {s}
        <div className="action">
          <DocumentCopy onClick={() => alert(`copy id:`)} size="15" color="#141414" />
          <Edit onClick={() => alert(`edit id:`)} size="15" color="#141414" />
        </div>
      </div>
    ),
  },
];

const data = [
  {
    key: '1',
    name: 'John Brown',
    age: 32,
    address: 'New York No. 1 Lake Park',
  },
  {
    key: '2',
    name: 'Jim Green',
    age: 42,
    address: 'London No. 1 Lake Park',
  },
  {
    key: '3',
    name: 'Joe Black',
    age: 32,
    address: 'Sydney No. 1 Lake Park',
  },
  {
    key: '4',
    name: 'Disabled User',
    age: 99,
    address: 'Sydney No. 1 Lake Park',
  },
];

function TableDocs() {
  // Loading
  const [isLoading, setIsLoading] = useState(false);
  // Expaneble
  const [isExpand, setIsExpand] = useState(false);
  // Selection & type state
  const [selectionType, setSelectionType] = useState('checkbox');
  const [withSelection, setWithSelection] = useState(false);

  // Pagination state
  const [currentpage, setCurrentpage] = useState(1);
  const [size, setSize] = useState(10);
  const total = 100;

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: total,
    current: currentpage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ['10', '20'],
  };

  // func to set size pagination
  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setSize(pageSize);
  };

  // if have expaneble, this is the props example..
  const expandable = {
    expandedRowRender: (record) => <p style={{ margin: 0 }}>{record.name}</p>,
    rowExpandable: (record) => record.name !== 'Not Expandable',
  };
  // manipulated data with action
  const sourceData = data;

  return (
    <div>
      <Checkbox onChange={(e) => setIsLoading(e.target.checked)} />
      <label className="mx-3">Loading</label>
      <Checkbox onChange={(e) => setIsExpand(e.target.checked)} />
      <label className="mx-3">Expandable</label>
      <Checkbox onChange={(e) => setWithSelection(e.target.checked)} />
      <label className="mx-3">With selection</label>

      <Radio.Group
        className="mb-5"
        onChange={({ target: { value } }) => {
          setSelectionType(value);
        }}
        value={selectionType}
        disabled={!withSelection}
      >
        <Radio value="checkbox">Checkbox</Radio>
        <Radio value="radio">radio</Radio>
      </Radio.Group>
      <MoslemeTable
        loading={isLoading}
        expandable={isExpand && expandable}
        columns={columns}
        data={sourceData}
        withSelection={withSelection}
        selectionType={selectionType}
        pagination={pagination}
        onPageChange={(page) => setCurrentpage(page)}
        onSizeChange={(currentpage, pageSize) => onSizeChange(currentpage, pageSize)}
      />
    </div>
  );
}

export default TableDocs;
