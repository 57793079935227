import React, { useState } from "react";

import Buttons from "components/buttons";
import MoslemeTable from "components/Table";
import Badge from "components/badge";
import Popup from "components/popup";
import PaketLaporanPembayaranDaftarPopup from "./components/DaftarPembayaran";

import IconCSV from "assets/icons/CSV.svg";
import BCA from "assets/images/bca.png";

const PaketLaporanPembayaran = () => {
  const [openPopup, setOpenPopup] = useState(false);
  // Loading
  const [isLoading, setIsLoading] = useState(false);
  // Expaneble
  const [isExpand, setIsExpand] = useState(true);
  const [tableExpand, setTableExpand] = useState(false);
  // Selection & type state
  const [selectionType, setSelectionType] = useState("checkbox");
  const [withSelection, setWithSelection] = useState(false);

  // Pagination state
  const [currentpage, setCurrentpage] = useState(1);
  const [size, setSize] = useState(10);
  const total = 100;

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: total,
    current: currentpage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20"],
  };

  // func to set size pagination
  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setSize(pageSize);
  };

  const columns = [
    {
      title: "Kode Tagihan",
      dataIndex: "bill_code",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Nama Jamaah",
      dataIndex: "name",
      render: (name) => (
        <>
          <div>
            <div className="whitespace-nowrap mb-1">{name.title}</div>
          </div>
          <div className="flex flex-row gap-1">
            {tableExpand ? (
              <Badge customClass="uppercase" label="Dibayar Sebagian" />
            ) : (
              <>
                <Badge label={name.tags[0]} />
                <Badge label={name.tags[1]} />
              </>
            )}
          </div>
        </>
      ),
    },
    {
      title: "Tanggal Booking",
      dataIndex: "booking_date",
    },
    {
      title: "Banyak Transaksi",
      dataIndex: "total_transaction",
    },
    {
      title: "Total Dibayar",
      dataIndex: "total_paid",
      className: "whitespace-nowrap",
      render: (paid) => <div className="font-semibold">Rp {paid}</div>,
    },
    {
      title: "Total DP",
      dataIndex: "total_dp",
      className: "whitespace-nowrap",
      render: (dp) => <div className="font-semibold">Rp {dp}</div>,
    },
    {
      title: "Total Tagihan",
      dataIndex: "total_bill",
      className: "whitespace-nowrap",
      render: (bill) => <div className="font-semibold">Rp {bill}</div>,
    },
  ];

  const data = [
    {
      key: "1",
      bill_code: "#UMR-123-1",
      name: {
        title: "Dimas Andreyan Pradana Putra",
        tags: ["Admin Cabang Daerah", "Aceh"],
      },
      booking_date: "31/12/2023 14:07 WIB",
      total_transaction: "5x Transaksi",
      total_paid: "10.000.000",
      total_dp: "10.000.000",
      total_bill: "40.000.000",
    },
  ];

  // if have expaneble, this is the props example..
  const expandable = {
    expandedRowRender: (record) => (
      //   <p style={{ margin: 0 }}>{record.name.title}</p>
      <div className="ml-2 relative">
        <ul className="w-full flex py-3 border-l">
          <li className="w-full">
            <ul>
              <li className="border-b pl-5 pb-3 font-bold">
                Tanggal Pembayaran
              </li>
            </ul>
          </li>
          <li className="w-full">
            <ul>
              <li className="border-b pl-5 pb-3 font-bold">Nomor Pembayaran</li>
            </ul>
          </li>
          <li className="w-full">
            <ul>
              <li className="border-b pl-5 pb-3 font-bold">
                Nominal Pembayaran
              </li>
            </ul>
          </li>
          <li className="w-full">
            <ul>
              <li className="border-b pl-5 pb-3 font-bold">Akun Penerima</li>
            </ul>
          </li>
          <li className="w-full">
            <ul>
              <li className="border-b pl-5 pb-3 font-bold">Akun Pengirim</li>
            </ul>
          </li>
        </ul>
        <ul className="w-full flex items-center pb-3 border-l border-b">
          <li className="w-full">
            <div className="pl-5 pt-2">
              21/08/2022 <br />
              14:07 WIB
            </div>
          </li>
          <li className="w-full">
            <div className="pl-5 pt-2">#UMR-123-1</div>
          </li>
          <li className="w-full">
            <div className="pl-5 pt-2 font-semibold">Rp 900.000.000</div>
          </li>
          <li className="w-full whitespace-nowrap">
            <div className="pl-5 pt-2">
              <div className="flex flex-row items-center gap-2">
                <img className="flex w-[16px] h-[16px]" src={BCA} alt="bank" />
                <div>7625 3464 756</div>
              </div>
              <div className="font-semibold mt-1">
                Dimas Andreyan Pradana Putra
              </div>
            </div>
          </li>
          <li className="w-full whitespace-nowrap">
            <div className="pl-5 pt-2">
              <div className="flex flex-row items-center gap-2">
                <img className="flex w-[16px] h-[16px]" src={BCA} alt="bank" />
                <div>7625 3464 756</div>
              </div>
              <div className="font-semibold mt-1">
                Dimas Andreyan Pradana Putra
              </div>
            </div>
          </li>
        </ul>
        <ul className="w-full flex items-center pb-3 border-l">
          <li className="w-full">
            <div className="pl-5 pt-2">
              21/08/2022 <br />
              14:07 WIB
            </div>
          </li>
          <li className="w-full">
            <div className="pl-5 pt-2">#UMR-123-1</div>
          </li>
          <li className="w-full">
            <div className="pl-5 pt-2 font-semibold">Rp 900.000.000</div>
          </li>
          <li className="w-full whitespace-nowrap">
            <div className="pl-5 pt-2">
              <div className="flex flex-row items-center gap-2">
                <img className="flex w-[16px] h-[16px]" src={BCA} alt="bank" />
                <div>7625 3464 756</div>
              </div>
              <div className="font-semibold mt-1">
                Dimas Andreyan Pradana Putra
              </div>
            </div>
          </li>
          <li className="w-full whitespace-nowrap">
            <div className="pl-5 pt-2">
              <div className="flex flex-row items-center gap-2">
                <img className="flex w-[16px] h-[16px]" src={BCA} alt="bank" />
                <div>7625 3464 756</div>
              </div>
              <div className="font-semibold mt-1">
                Dimas Andreyan Pradana Putra
              </div>
            </div>
          </li>
        </ul>
        <Buttons
          text="Lihat Selengkapnya"
          customClass="btn-secondary btn-small !w-fit mt-4"
        />
      </div>
    ),
    rowExpandable: (record) => record.name.title !== "Not Expandable",
    onExpand: (check) => setTableExpand(check),
  };
  // manipulated data with action
  const sourceData = data;

  return (
    <>
      <Popup
        open={openPopup}
        handleClose={() => setOpenPopup(false)}
        title="Daftar Pembayaran #ID"
        width="1080px"
        children={<PaketLaporanPembayaranDaftarPopup />}
      />
      <div className="bg-white border-[1px] border-[#4048521a] rounded-bl-lg rounded-br-lg">
        <div className="flex flex-row justify-between items-center p-4">
          <div className="text-[20px] font-semibold">Laporan Pembayaran</div>
          <Buttons
            text="Export CSV"
            customClass="btn-outline btn-small"
            icon={IconCSV}
            onClick={() => setOpenPopup(true)}
          />
        </div>
        <div className="mt-4">
          <MoslemeTable
            loading={isLoading}
            expandable={isExpand && expandable}
            columns={columns}
            data={sourceData}
            withSelection={withSelection}
            selectionType={selectionType}
            pagination={pagination}
            onPageChange={(page) => setCurrentpage(page)}
            onSizeChange={(currentpage, pageSize) =>
              onSizeChange(currentpage, pageSize)
            }
          />
        </div>
      </div>
      <div id="area-modal"></div>
    </>
  );
};

export default React.memo(PaketLaporanPembayaran);
