import React, { useState } from "react";
import PropTypes from "prop-types";
import { Input } from "antd";

import InputsStyle from "./Inputs.style";
import Close from "assets/icons/Close.svg";
import Check from "assets/icons/Check.svg";
import EyeOff from "assets/icons/Invisible.svg";
import Eye from "assets/icons/eye.svg";

const Inputs = ({
  containerClass,
  customClass,
  id,
  name,
  label,
  iconPosition,
  icon,
  placeholder,
  value,
  type,
  maxLength,
  success,
  error,
  disabled,
  currency,
  onChange,
  onPressEnter,
  onBlur,
  defaultValue,
  onClick,
  customeLabel,
}) => {
  const [inputType, setInputType] = useState(type);
  const [showPassword, setShowPassword] = useState(false);
  return (
    <InputsStyle className={`${containerClass}`}>
      {label !== "" && (
        <div
          className={`input-label ${customeLabel} ${
            disabled && "input-label--disabled"
          }`}
        >
          {label}
        </div>
      )}
      <div className="flex flex-row relative">
        {icon && iconPosition === "left" && (
          <img
            src={icon}
            className="ic-left"
            alt={`icon-left ${onClick && "cursor-pointer"}`}
            onClick={onClick}
          />
        )}
        {currency && (
          <div className="text-[#828282] font-semibold text-[16px] absolute z-10 left-[14px] top-[10px]">
            Rp
          </div>
        )}
        <Input
          id={id}
          name={name}
          type={inputType ? inputType : "text"}
          value={value}
          defaultValue={defaultValue}
          className={`custom-input ${
            icon && iconPosition === "left"
              ? "input-icon--left"
              : icon && iconPosition === "right"
              ? "input-icon--right"
              : type === "password"
              ? "input-icon--right"
              : ""
          } ${customClass} ${success !== "" ? "success" : ""} ${
            error !== "" ? "error" : ""
          }`}
          disabled={disabled}
          placeholder={placeholder}
          maxLength={maxLength ? maxLength : 255}
          onChange={onChange}
          onPressEnter={onPressEnter}
          onBlur={onBlur}
        />
        {icon && iconPosition === "right" && (
          <img
            src={icon}
            className={`ic-right ${onClick && "cursor-pointer"}`}
            alt="icon-right"
            onClick={onClick}
          />
        )}
        {type === "password" && (
          <img
            className="input-icon-password"
            src={showPassword ? Eye : EyeOff}
            onClick={() => {
              setInputType(showPassword ? "password" : "text");
              setShowPassword(!showPassword);
            }}
            alt="icon-password"
          />
        )}
      </div>

      {success !== "" && (
        <div className="flex flex-row items-center mt-1">
          <img src={Check} alt="check" />
          <div className="input-success-msg">{success}</div>
        </div>
      )}
      {error !== "" && (
        <div className="flex flex-row items-center mt-1">
          <img src={Close} alt="close" />
          <div className="input-error-msg">{error}</div>
        </div>
      )}
    </InputsStyle>
  );
};

Inputs.propTypes = {
  containerClass: PropTypes.string,
  customClass: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  iconPosition: PropTypes.string,
  icon: PropTypes.any,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  maxLength: PropTypes.number,
  success: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  currency: PropTypes.bool,
  onChange: PropTypes.func,
  onPressEnter: PropTypes.func,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  customeLabel: PropTypes.string,
};

Inputs.defaultProps = {
  containerClass: "",
  customClass: "",
  customeLabel: "",
  id: "",
  name: "",
  label: "",
  iconPosition: "left",
  icon: "",
  placeholder: "",
  type: "text",
  maxLength: 255,
  success: "",
  error: "",
  disabled: false,
  currency: false,
  onChange: () => {},
  onPressEnter: () => {},
  onBlur: () => {},
  onClick: () => {},
};

export default React.memo(Inputs);
