import React, { useState } from "react";
import { Grid } from "@material-ui/core";

import CardRoleTable from "./component/CardRoleTable";

const RolePage = () => {
  return (
    <div className="flex flex-row  w-full">
      <CardRoleTable />
    </div>
  );
};

export default RolePage;
