import Popup from "components/popup";
import Tab from "components/tab";
import { useFormik } from "formik";
import React, { useState } from "react";
import Vaksin from "./Vaksin";

const PopUpVaksin = ({ show, setModal }) => {
  const [activeTab, setActiveTab] = useState("1");

  const validation = useFormik({
    initialValues: {
      form: [
        {
          jenis_vaksin: "",
        },
        {
          jenis_vaksin: "",
        },
        {
          jenis_vaksin: "",
        },
      ],
    },
    onSubmit: (val) => {
      // dispatch(MemberAction.createData(val, callbackPost));
    },
  });

  const close = (e) => {
    setModal(false);
    validation.handleReset();
  };

  return (
    <Popup width="824px" open={show} handleClose={close} title="Vaksin">
      <Tab
        defaultActiveKey="1"
        onChange={(e) => setActiveTab(e)}
        items={[
          {
            label: (
              <div className="flex flex-row items-center gap-2">
                <div>Vaksin 1</div>
              </div>
            ),
            key: "1",
            children: <Vaksin validation={validation} index={0} />,
          },
          {
            label: (
              <div className="flex flex-row items-center gap-2">
                <div>Vaksin 2</div>
              </div>
            ),
            key: "2",
            children: <Vaksin validation={validation} index={1} />,
          },
          {
            label: (
              <div className="flex flex-row items-center gap-2">
                <div>Vaksin 3</div>
              </div>
            ),
            key: "3",
            children: <Vaksin validation={validation} index={2} />,
          },
        ]}
      />
    </Popup>
  );
};

export default PopUpVaksin;
