import React, { useState } from "react";
import { Steps } from "antd";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { message } from "antd";
import Buttons from "./../buttons/index";

const StepsComponent = ({
  currentSteps,
  itemContent,
  labelPosition,
  direction,
}) => {
  const useStyles = makeStyles({
    root: {
      "&:where(.css-dev-only-do-not-override-diro6f).ant-steps .ant-steps-item-process .ant-steps-item-icon":
        {
          background:
            "linear-gradient(204.44deg, #F90601 -18.49%, #8A1212 84.72%)",
          borderColor: "red",
          width: "32px",
          height: "32px",
        },

      "&:where(.css-dev-only-do-not-override-diro6f).ant-steps .ant-steps-item-wait .ant-steps-item-icon":
        {
          background: "none",
          borderColor: "red",
          width: "32px",
          height: "32px",
        },

      "&:where(.css-dev-only-do-not-override-diro6f).ant-steps .ant-steps-item-finish .ant-steps-item-icon":
        {
          background:
            "linear-gradient(204.44deg, #F90601 -18.49%, #8A1212 84.72%);",
        },

      "&:where(.css-dev-only-do-not-override-diro6f).ant-steps .ant-steps-item-icon .ant-steps-icon":
        {
          color: "#fff",
        },

      "&:where(.css-dev-only-do-not-override-diro6f).ant-steps .ant-steps-item-wait .ant-steps-item-icon >.ant-steps-icon":
        {
          color: "red",
        },

      "& .anticon": {
        verticalAlign: "0px !important",
      },

      "&:where(.css-dev-only-do-not-override-diro6f).ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after":
        {
          background:
            "linear-gradient(204.44deg, #F90601 -18.49%, #8A1212 84.72%)",
        },

      "&:where(.css-dev-only-do-not-override-diro6f).ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after":
        {
          background:
            "linear-gradient(204.44deg, #F90601 -18.49%, #8A1212 84.72%)",
        },

      "& .ant-steps-item-title": {
        display: "inline-block",
        fontSize: "12px",
        transformOrigin: "right",
        paddingRight: "10px",
        whiteSpace: "nowrap",
      },
    },

    buttonContainer: {
      display: "flex",
      gap: "20px",
      marginTop: "20px",
    },
  });

  const { Step } = Steps;
  const [current, setCurrent] = useState(currentSteps);
  const classes = useStyles();

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  return (
    <>
      <Steps
        current={current}
        labelPlacement={labelPosition}
        className={classes.root}
        type="primary"
        direction={direction}
      >
        {itemContent.map(({ title, content }, index) => (
          <Step key={index} title={title} />
        ))}
      </Steps>
      <div>{itemContent[current].content}</div>
      <div className={classes.buttonContainer}>
        {current < itemContent.length - 1 && (
          <Buttons
            text="Selanjutnya"
            containerClass="mt-[20px] w-[186px] h-[48px]"
            customClass="btn-primary btn-large"
            onClick={() => next()}
          />
        )}
        {current > 0 && (
          <Buttons
            text="Sebelumnya"
            containerClass="mt-[20px] w-[186px] h-[48px]"
            customClass="btn-primary btn-large"
            onClick={() => prev()}
          />
        )}
        {current === itemContent.length - 1 && (
          <Buttons
            text="Submit"
            containerClass="mt-[20px] w-[186px] h-[48px]"
            customClass="btn-primary btn-large"
            onClick={() => message.success("Processing complete!")}
          />
        )}
      </div>
    </>
  );
};

StepsComponent.propTypes = {
  currentSteps: PropTypes.number,
  labelPosition: PropTypes.string, // vertical or horizontal
  itemContent: PropTypes.array,
  direction: PropTypes.string, // vertical or horizontal
};

export default StepsComponent;
