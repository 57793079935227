import { useEffect, useState } from "react";
import { IcCamera, IcDeleteCircle } from "assets/icons/dashboard";
import Buttons from "components/buttons";
import ImageField from "components/image_field";
import { DatePicker, Input } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { setDataUpload, setDataUploadMulti } from "stores/actions/uploadFile";
import uuid from "react-uuid";
const { TextArea } = Input;

const EditItinenary = ({ inserItenary, isLoadingAction, itenary }) => {
  const dispatch = useDispatch();
  const { dataUpload, dataMultiple } = useSelector((state) => state.uploadFile);
  const [placeholderDate, setPlaceHolderDate] = useState(null);

  const [form, setForm] = useState({
    date: null,
    deskripsi: "",
    rute: [],
  });

  useEffect(() => {
    if (itenary) {
      setForm({
        ...form,
        date: moment(itenary?.date, "dddd, D MMMM YYYY").format("DD-MM-YYYY"),
        deskripsi: itenary?.description,
        rute: itenary?.rute,
      });
      setPlaceHolderDate(itenary?.date);

      let _temp = [];

      for (let data of itenary.gallery) {
        _temp.push({
          id: uuid(),
          fileName: data.itinerarie_id,
          path: data.link,
          name: "galery_foto",
          type: data.type,
        });
      }

      dispatch(setDataUploadMulti(_temp));
      dispatch(
        setDataUpload([
          ...dataUpload,
          {
            name: "thumbnail",
            path: itenary?.thumbnail,
            fileName: "thumniail",
            id: uuid(),
          },
        ])
      );
    }
  }, [itenary]);

  const [input, setInput] = useState("");

  const handleChange = (val, name) => {
    setForm({
      ...form,
      [name]: val,
    });
  };

  const resultMap = () => {
    if (itenary) {
      return {
        id: itenary?.id_itinerarie,
        date:
          form.date !== null &&
          moment(form.date, "DD-MM-YYYY").format("dddd, D MMMM YYYY"),
        rute: form.rute,
        description: form.deskripsi,
        gallery: dataMultiple?.map((item) => ({
          link: item.path,
          type: item.type,
        })),
        thumbnail: dataUpload[0].path,
      };
    } else {
      return {
        date:
          form.date !== null &&
          moment(form.date, "DD-MM-YYYY").format("dddd, D MMMM YYYY"),
        rute: form.rute,
        description: form.deskripsi,
        gallery: dataMultiple?.map((item) => ({
          link: item.path,
          type: item.type,
        })),
        thumbnail: dataUpload[0].path,
      };
    }
  };

  return (
    <main className="drop-shadow rounded-lg bg-white p-5">
      <div className="mb-4 font-semibold">Tanggal Perjalanan</div>
      <div className="w-full">
        <DatePicker
          placeholder={
            placeholderDate === null ? " Pilih Tanggal" : placeholderDate
          }
          onChange={(val) => {
            setPlaceHolderDate(null);
            let date = moment(val?.$d).format("DD-MM-YYYY");
            handleChange(date, "date");
          }}
          popupClassName="popup-date-picker"
          className="single-date-picker w-full"
        />
      </div>
      <main className="drop-shadow bg-white p-5 mt-5">
        <section className="grid-cols-2 grid gap-x-5 ">
          <div>
            <div className="mt-5 font-semibold mb-2">Thumbnail</div>
            <div className="rounded-lg bg-gray-1 p-5">
              <ImageField
                label=""
                customWrapper="h-[180px] !border-none !bg-gray-1"
                customContainer="!w-full !border-none !bg-gray-1"
                src={IcCamera}
                isMulti={false}
                status={false}
                setFile={(file) => {
                  //   setFieldValue("images", file[0]?.path);
                }}
                name="thumbnail"
              />
            </div>
          </div>
          <div className="flex flex-col justify-between">
            <div className="mt-5 font-semibold mb-2">Deskripsi</div>
            <TextArea
              rows={7}
              className="bg-gray-1 outline-none"
              id="deskripsi"
              name="deskripsi"
              value={form.deskripsi}
              onChange={(e) => handleChange(e.target.value, "deskripsi")}
            />
          </div>
        </section>
        <section className="drop-shadow bg-white p-5 my-5">
          <div className="font-semibold">Rute</div>
          <div className="flex items-center justify-between px-4 py-2 mt-4 border rounded-lg bg-gray-1">
            <input
              placeholder="Masukan nama kota"
              name="input"
              className="flex-1 py-2 outline-none bg-gray-1"
              value={input}
              onChange={(e) => {
                setInput(e.target.value);
              }}
            />
            <Buttons
              text="Tambahkan"
              customClass="btn-primary btn-small outline-none"
              onClick={() => {
                let _temp = { ...form };
                _temp.rute.push(input);
                setInput("");
                setForm(_temp);
              }}
            />
          </div>

          <div className="flex justify-start items-start mt-5">
            {form.rute?.map((item, idx) => (
              <div className="flex items-center" key={idx}>
                <div className="flex gap-x-2 items-center justify-start bg-gray-1 rounded-lg py-1 px-2">
                  <div>{item}</div>
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      let _temp = { ...form };
                      _temp.rute.splice(idx, 1);
                      setForm(_temp);
                    }}
                  >
                    <img alt="delete-icon" src={IcDeleteCircle} />
                  </div>
                </div>
                {idx !== form.rute.length - 1 && (
                  <span className="ln-blue w-5 h-1 mx-2"></span>
                )}
              </div>
            ))}
          </div>
        </section>

        <ImageField
          label="Tambahkan Foto"
          isMulti={true}
          setFile={(a) => console.log(a)}
          name="galery_foto"
        />
      </main>
      <div className="flex justify-end items-end mt-4">
        <Buttons
          text="Simpan"
          loading={isLoadingAction}
          customClass="btn-primary btn-medium"
          onClick={() => {
            inserItenary(resultMap());
          }}
        />
      </div>
    </main>
  );
};

export default EditItinenary;
