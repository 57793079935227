import styled from "styled-components";

const CabangStyle = styled.div`
  .notif {
    width: 32px;
    height: 32px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
  }

  .round {
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 48px;
    background: linear-gradient(180deg, #f5e97c 0%, #e2a246 100%);
    right: 0px;
    top: 0px;
    color: linear-gradient(
      34.99deg,
      #151515 17.46%,
      #3d3d3d 58.62%,
      #595959 87.32%
    );
    font-weight: 600;
    font-size: 6px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
  }
`;

export default CabangStyle;
