import { useDispatch } from "react-redux";
import { getBelumDp, getKelengkapanData } from "stores/actions/dashboard";
import { detailById } from "stores/actions/general";
import {
  IcBatasPerlunasan,
  IcDp3,
  IcKelengkapanData,
  IcPerlengkapanMaroon,
  IcSeatTerisi,
  IcSeatTersisa,
  IcTotalSeat,
} from "../../../assets/icons/dashboard";
import Buttons from "../../../components/buttons";
import CardMain from "./CardMain";
import CardProgress from "./CardProgress";
import ItemSeat from "./ItemSeat";
import UserDesc from "./UserDesc";

const PaketAktif = ({
  judul_paket,
  image,
  tanggal_keberangkatan,
  tipe_paket,
  total_kuota_jamaah,
  total_seat,
  seat_terisi,
  sisa_seat,
  data,
}) => {
  const dispatch = useDispatch();

  const onBelum = (type) => {
    // return console.log("belum dp", type);
    dispatch(
      getBelumDp({
        paket_id: data?.paketid,
        // paket_id: 1,
        keyword: "",
        per_page: 10,
        page_number: 1,
        sort_column: "ordered_at",
        sort_order: "ASC",
      })
    ).then((item) => {
      // console.log("ini adalah", item);
      if (item.data.length !== 0 && type !== "") {
        dispatch(
          detailById({
            status: type,
            data: item,
          })
        );
      }
    });
  };

  const onKelengkapanData = (type) => {
    dispatch(
      getKelengkapanData({
        // paket_id: 1,
        paket_id: data?.paketid,
        keyword: "",
        per_page: 10,
        page_number: 1,
        sort_column: "ordered_at",
        sort_order: "ASC",
      })
    ).then((item) => {
      // console.log("ini adalah", item);
      if (item.data.length !== 0 && type !== "") {
        dispatch(
          detailById({
            status: type,
            data: item,
          })
        );
      }
    });
  };

  return (
    <main className="bg-white rounded-[20px] shadow-soft border border-[#f2f2f2] p-4 flex flex-col gap-y-4">
      <UserDesc
        name={judul_paket}
        linkImg={image}
        desc={tanggal_keberangkatan}
        status={tipe_paket}
        className="object-cover"
      />
      <section className="bg-white shadow-soft rounded-xl p-4 flex flex-col gap-y-4">
        <CardMain className="gap-[155px]">
          <div className="text-sm">Total Jamaah</div>
          <div className="text-[28px] font-semibold text-green-2">
            {total_kuota_jamaah}
          </div>
        </CardMain>
        <CardMain className="!grid grid-cols-3 items-center">
          <ItemSeat
            icon={IcTotalSeat}
            seat="Total Seat"
            totalSeat={total_seat}
          />
          <ItemSeat
            className="text-blue-3"
            icon={IcSeatTerisi}
            seat="Seat Terisi"
            totalSeat={seat_terisi}
          />
          <ItemSeat
            className="text-[#5C9D0A]"
            icon={IcSeatTersisa}
            seat={"Seat Tersisa"}
            totalSeat={sisa_seat}
          />
          <div className="col-span-3 mt-4">
            <Buttons
              text="Lihat Seat Terisi"
              customClass="btn-secondary btn-small"
            />
          </div>
        </CardMain>
      </section>
      <section className="flex flex-col gap-y-2">
        <CardProgress
          icon={IcDp3}
          title="Belum DP"
          percent={data?.belum_dp}
          info={`${data?.belum_dp_persen} Tagihan`}
          // onPress={belumDp}
          onPress={() => onBelum("belumDp")}
        />
        <CardProgress
          icon={IcBatasPerlunasan}
          title="Belum Lunas"
          percent={data?.belum_lunas}
          info={`${data?.belum_lunas_persen} Tagihan`}
          onPress={() => onBelum("belumLunas")}
        />
        <CardProgress
          icon={IcPerlengkapanMaroon}
          title="Perlengkapan"
          percent={data?.jml_data_belum_lengkap}
          info={`${data?.jml_data_belum_lengkap_persen} Pax`}
          onPress={() => onKelengkapanData("perlengkapan")}
          // onPress={() =>
          //   dispatch(
          //     detailById({
          //       perlengkapan: true,
          //     })
          //   )
          // }
        />
        <CardProgress
          icon={IcKelengkapanData}
          title="Kelengkapan Data"
          percent={data?.jml_perlengkapan_jamaah_belum_lengkap}
          info={`${data?.jml_perlengkapan_jamaah_belum_lengkap_persen} Pax`}
          onPress={() => onKelengkapanData("kelengkapan")}
        />
      </section>
    </main>
  );
};

export default PaketAktif;
