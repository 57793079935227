import React, { useState } from "react";
import StarIcon from "assets/icons/Star.svg";
import Popup from "components/popup";
import PopupRatingQuestion from "./PopupRatingQuestion";

const RatingQuestionData = [
  {
    valueTotalStar: "4,8/5",
    pertanyaan: `Apakah pelayanan paket yang ditawarkan kami sebanding dengan
        harga paketnya?`,
    valueStarSatu: "0",
    valueStarDua: "0",
    valueStarTiga: "0",
    valueStarEmpat: "8",
    valueStarLima: "12",
  },
  {
    valueTotalStar: "4,8/5",
    pertanyaan: `Apakah pelayanan paket yang ditawarkan kami sebanding dengan harga paketnya?`,
    valueStarSatu: "0",
    valueStarDua: "0",
    valueStarTiga: "0",
    valueStarEmpat: "8",
    valueStarLima: "12",
  },
  {
    valueTotalStar: "4,8/5",
    pertanyaan: `Apakah pelayanan paket yang ditawarkan kami sebanding dengan harga paketnya?`,
    valueStarSatu: "0",
    valueStarDua: "0",
    valueStarTiga: "0",
    valueStarEmpat: "8",
    valueStarLima: "12",
  },
  {
    valueTotalStar: "4,8/5",
    pertanyaan: `Apakah pelayanan paket yang ditawarkan kami sebanding dengan harga paketnya?`,
    valueStarSatu: "0",
    valueStarDua: "0",
    valueStarTiga: "0",
    valueStarEmpat: "8",
    valueStarLima: "12",
  },
];

const RatingQuestionCard = ({
  valueTotalStar,
  pertanyaan,
  valueStarSatu,
  valueStarDua,
  valueStarTiga,
  valueStarEmpat,
  valueStarLima,
}) => {
  const [openPopupRatingQuestion, setOpenPopupRatingQuestion] = useState(false);

  return (
    <>
      <Popup
        open={openPopupRatingQuestion}
        handleClose={() => {
          setOpenPopupRatingQuestion(false);
        }}
        title={pertanyaan}
        width="676px"
        height="536px"
        containerClass=""
        children={<PopupRatingQuestion />}
      />

      <div
        className="flex flex-row items-center p-[16px] gap-[16px] shadow-md cursor-pointer"
        onClick={() => setOpenPopupRatingQuestion(true)}
      >
        <div className="flex flex-row gap-[4px] border rounded-lg py-[12px] px-[16px]">
          <img src={StarIcon} alt="rating" />
          <h2 className="font-semibold">{valueTotalStar}</h2>
        </div>
        <div className="flex flex-col items-start gap-[8px]">
          <div>
            <h2>{pertanyaan}</h2>
          </div>
          <div className="flex flex-row items-start gap-[16px]">
            <div className="flex flex-row items-center gap-[4px]">
              <img src={StarIcon} alt="rating" />
              <p className="font-semibold">({valueStarSatu})</p>
            </div>
            <div className="flex flex-row items-center gap-[4px]">
              <img src={StarIcon} alt="rating" />
              <img src={StarIcon} alt="rating" />
              <p className="font-semibold">({valueStarDua})</p>
            </div>
            <div className="flex flex-row items-center gap-[4px]">
              <img src={StarIcon} alt="rating" />
              <img src={StarIcon} alt="rating" />
              <img src={StarIcon} alt="rating" />
              <p className="font-semibold">({valueStarTiga})</p>
            </div>
            <div className="flex flex-row items-center gap-[4px]">
              <img src={StarIcon} alt="rating" />
              <img src={StarIcon} alt="rating" />
              <img src={StarIcon} alt="rating" />
              <img src={StarIcon} alt="rating" />
              <p className="font-semibold">({valueStarEmpat})</p>
            </div>
            <div className="flex flex-row items-center gap-[4px]">
              <img src={StarIcon} alt="rating" />
              <img src={StarIcon} alt="rating" />
              <img src={StarIcon} alt="rating" />
              <img src={StarIcon} alt="rating" />
              <p className="font-semibold">({valueStarLima})</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const RatingQuestion = () => {
  return (
    <>
      <div className="flex flex-col gap-[16px]">
        {RatingQuestionData.map((item) => (
          <RatingQuestionCard
            valueTotalStar={item.valueTotalStar}
            pertanyaan={item.pertanyaan}
            valueStarSatu={item.valueStarSatu}
            valueStarDua={item.valueStarDua}
            valueStarTiga={item.valueStarTiga}
            valueStarEmpat={item.valueStarEmpat}
            valueStarLima={item.valueStarLima}
          />
        ))}
      </div>
    </>
  );
};

export default RatingQuestion;
