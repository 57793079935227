import React, { useState } from "react";

import Buttons from "components/buttons";

import IconCSV from "assets/icons/CSV.svg";
import AvaIkhwan from "assets/icons/ava-ikhwan.svg";
import AvaAkhwat from "assets/icons/ava-akhwat.svg";

const listCabang = [
  "all",
  "yogyakarta",
  "solo",
  "surabaya",
  "jakarta",
  "palembang",
  "jambi",
  "pekanbaru",
  "medan",
  "aceh",
  "lubuklinggau",
  "makassar",
  "gorontalo",
  "tarakan",
  "banjarmasin",
  "balikpapan",
];

const PaketLaporanPerlengkapan = () => {
  const [activeFilter, setActiveFilter] = useState("all");

  const getFilterCabang = () => {
    return listCabang.map((val, idx) => {
      return (
        <div
          key={idx}
          className={`border-[1px] border-[#4048521a] rounded-lg px-4 py-1 cursor-pointer uppercase font-semibold flex flex-row items-center ${
            activeFilter === val && "bg-[#F3CFCE]"
          }`}
          onClick={() => setActiveFilter(val)}
        >
          <div
            className={`flex flex-row justify-center items-center ${
              activeFilter === val && "txt-maroon"
            }`}
          >
            {val}
          </div>
          <div className="ml-2 bg-white border-[1px] border-[#4048521a] rounded-lg p-[2px] pt-0 w-[18px] h-[18px] flex justify-center items-center">
            2
          </div>
        </div>
      );
    });
  };

  return (
    <div className="bg-white border-[1px] border-[#4048521a] rounded-bl-lg rounded-br-lg">
      <div className="p-4">
        <div className="font-semibold text-[20px]">Penggunaan Perlengkapan</div>
        <div className="p-4 border-[1px] border-[#4048521a] rounded-lg mt-6">
          <div className="flex flex-row flex-wrap gap-4">
            {getFilterCabang()}
          </div>
        </div>
      </div>
      <div className="divider"></div>
      <div className="p-4">
        <div className="flex flex-row justify-between gap-4">
          <div className="flex flex-row gap-4 bg-white border-[1px] border-[#4048521a] rounded-lg normal-shadow p-4">
            <div className="flex flex-col">
              <div
                className={`border-[1px] border-[#4048521a] bg-[#F3CFCE] rounded-lg px-4 py-1 cursor-pointer uppercase font-semibold w-fit`}
              >
                <div className={`txt-maroon uppercase`}>All</div>
              </div>
              <div className="font-semibold text-[16px] mt-4">
                Total Perlengkapan
              </div>
            </div>
            <div className="flex flex-row items-center">
              <div className="!rounded-tl-lg !rounded-bl-lg !rounded-tr-none !rounded-br-none dark-grey-bg-shine p-4">
                <div className="txt-green text-[20px] font-semibold">
                  <span className="text-[16px]">169</span>/212
                </div>
              </div>
              <div className="flex flex-row gap-3 p-4 border-[1px] border-[#4048521a] rounded-tr-lg rounded-br-lg">
                <div className="flex flex-row gap-2">
                  <img src={AvaIkhwan} alt="ikhwan" />
                  <div className="txt-green font-semibold text-[16px]">89</div>
                </div>
                <div className="flex flex-row gap-2">
                  <img src={AvaAkhwat} alt="akhwat" />
                  <div className="txt-green font-semibold text-[16px]">90</div>
                </div>
              </div>
            </div>
          </div>
          <Buttons
            text="Export CSV"
            customClass="btn-outline btn-small"
            icon={IconCSV}
          />
        </div>

        <div className="grid grid-cols-5 gap-4 mt-5">
          <div className="bg-white minim-shadow rounded-lg">
            <div className="flex flex-col justify-between items-center dark-grey-bg-shine !rounded-bl-none !rounded-br-none gap-3">
              <div className="text-white font-semibold">Koper Bagasi</div>
              <div className="txt-green text-[20px] font-semibold">
                <span className="text-[16px]">10</span>/14
              </div>
            </div>
            <div className="flex flex-row justify-center gap-6 px-4 py-6">
              <div className="flex flex-row gap-2">
                <img src={AvaIkhwan} alt="ikhwan" />
                <div className="txt-green font-semibold text-[16px]">6</div>
              </div>
              <div className="flex flex-row gap-2">
                <img src={AvaAkhwat} alt="akhwat" />
                <div className="txt-green font-semibold text-[16px]">4</div>
              </div>
            </div>
          </div>
          <div className="bg-white minim-shadow rounded-lg">
            <div className="flex flex-col justify-between items-center dark-grey-bg-shine !rounded-bl-none !rounded-br-none gap-3">
              <div className="text-white font-semibold">Tas Pasport</div>
              <div className="txt-green text-[20px] font-semibold">
                <span className="text-[16px]">11</span>/18
              </div>
            </div>
            <div className="flex flex-row justify-center gap-6 px-4 py-6">
              <div className="flex flex-row gap-2">
                <img src={AvaIkhwan} alt="ikhwan" />
                <div className="txt-green font-semibold text-[16px]">6</div>
              </div>
              <div className="flex flex-row gap-2">
                <img src={AvaAkhwat} alt="akhwat" />
                <div className="txt-green font-semibold text-[16px]">5</div>
              </div>
            </div>
          </div>
          <div className="bg-white minim-shadow rounded-lg">
            <div className="flex flex-col justify-between items-center dark-grey-bg-shine !rounded-bl-none !rounded-br-none gap-3">
              <div className="text-white font-semibold">Kain Seragam</div>
              <div className="txt-green text-[20px] font-semibold">24/24</div>
            </div>
            <div className="flex flex-row justify-center gap-6 px-4 py-6">
              <div className="flex flex-row gap-2">
                <img src={AvaIkhwan} alt="ikhwan" />
                <div className="txt-green font-semibold text-[16px]">12</div>
              </div>
              <div className="flex flex-row gap-2">
                <img src={AvaAkhwat} alt="akhwat" />
                <div className="txt-green font-semibold text-[16px]">12</div>
              </div>
            </div>
          </div>
          <div className="bg-white minim-shadow rounded-lg">
            <div className="flex flex-col justify-between items-center dark-grey-bg-shine !rounded-bl-none !rounded-br-none gap-3">
              <div className="text-white font-semibold">Pin</div>
              <div className="txt-green text-[20px] font-semibold">18/24</div>
            </div>
            <div className="flex flex-row justify-center gap-6 px-4 py-6">
              <div className="flex flex-row gap-2">
                <img src={AvaIkhwan} alt="ikhwan" />
                <div className="txt-green font-semibold text-[16px]">8</div>
              </div>
              <div className="flex flex-row gap-2">
                <img src={AvaAkhwat} alt="akhwat" />
                <div className="txt-green font-semibold text-[16px]">10</div>
              </div>
            </div>
          </div>
          <div className="bg-white minim-shadow rounded-lg">
            <div className="flex flex-col justify-between items-center dark-grey-bg-shine !rounded-bl-none !rounded-br-none gap-3">
              <div className="text-white font-semibold">Kain Ihram</div>
              <div className="txt-green text-[20px] font-semibold">24/24</div>
            </div>
            <div className="flex flex-row justify-center gap-6 px-4 py-6">
              <div className="flex flex-row gap-2">
                <img src={AvaIkhwan} alt="ikhwan" />
                <div className="txt-green font-semibold text-[16px]">12</div>
              </div>
              <div className="flex flex-row gap-2">
                <img src={AvaAkhwat} alt="akhwat" />
                <div className="txt-green font-semibold text-[16px]">12</div>
              </div>
            </div>
          </div>
          <div className="bg-white minim-shadow rounded-lg">
            <div className="flex flex-col justify-between items-center dark-grey-bg-shine !rounded-bl-none !rounded-br-none gap-3">
              <div className="text-white font-semibold">Kain Ihram</div>
              <div className="txt-green text-[20px] font-semibold">24/24</div>
            </div>
            <div className="flex flex-row justify-center gap-6 px-4 py-6">
              <div className="flex flex-row gap-2">
                <img src={AvaIkhwan} alt="ikhwan" />
                <div className="txt-green font-semibold text-[16px]">12</div>
              </div>
              <div className="flex flex-row gap-2">
                <img src={AvaAkhwat} alt="akhwat" />
                <div className="txt-green font-semibold text-[16px]">12</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(PaketLaporanPerlengkapan);
