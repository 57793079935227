import React, { useState } from "react";

import Dropdowns, {
  closeDropdown,
} from "../../../../../../components/dropdowns";
import Tab from "../../../../../../components/tab";
import ReminderContent from "./components/ReminderContent";

import Filter from "../../../../../../assets/icons/filter.svg";

const Reminder = () => {
  const [activeDate, setActiveDate] = useState("1");

  return (
    <div className="mt-4 relative">
      <Tab
        defaultActiveKey="1"
        onChange={(e) => setActiveDate(e)}
        items={[
          {
            label: "30 Hari",
            key: "1",
            children: <ReminderContent activeDate={activeDate} />,
          },
          {
            label: "2 Pekan",
            key: "2",
            children: <ReminderContent activeDate={activeDate} />,
          },
          {
            label: "1 Pekan",
            key: "3",
            children: <ReminderContent activeDate={activeDate} />,
          },
          {
            label: "3 Hari",
            key: "4",
            children: <ReminderContent activeDate={activeDate} />,
          },
        ]}
      />
      <div className="flex flex-row gap-4 absolute right-0 top-[7px]">
        <Dropdowns
          fullIconText
          fullIconImg={Filter}
          label="Pembayaran Tagihan"
          customClass="border-[1px] border-[#E0E0E0] rounded-lg px-3 py-1"
        >
          <ul className="flex flex-col bg-white hard-shadow rounded-lg">
            <li
              className="px-4 py-2 cursor-pointer"
              onClick={() => closeDropdown()}
            >
              Master Paket
            </li>
            <li
              className="px-4 py-2 cursor-pointer"
              onClick={() => closeDropdown()}
            >
              Data Booking
            </li>
            <li
              className="px-4 py-2 cursor-pointer"
              onClick={() => closeDropdown()}
            >
              Tagihan
            </li>
          </ul>
        </Dropdowns>
        <Dropdowns
          fullIconText
          fullIconImg={Filter}
          label="Admin"
          customClass="border-[1px] border-[#E0E0E0] rounded-lg px-3 py-1"
        >
          <ul className="flex flex-col bg-white hard-shadow rounded-lg">
            <li
              className="px-4 py-2 cursor-pointer"
              onClick={() => closeDropdown()}
            >
              Master Paket
            </li>
            <li
              className="px-4 py-2 cursor-pointer"
              onClick={() => closeDropdown()}
            >
              Data Booking
            </li>
            <li
              className="px-4 py-2 cursor-pointer"
              onClick={() => closeDropdown()}
            >
              Tagihan
            </li>
          </ul>
        </Dropdowns>
      </div>
    </div>
  );
};

export default React.memo(Reminder);
