import { Grid } from "@material-ui/core";
import AvatarIkhwan from "assets/images/Avatar-ikhwan-1.svg";
import Badge from "components/badge";
import React from "react";

const PopupRatingQuestion = () => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <div className="flex flex-row justify-center p-[16px] gap-[16px] !w-[308px] !h-[84px] shadow-md rounded-lg">
            <div>
              <img
                src={AvatarIkhwan}
                alt="foto profil"
                className="w-[40px] h-[40px] rounded-full"
              />
            </div>
            <div className="flex flex-col items-start gap-[8px]">
              <h2>Dimas Andreyan Pradana Putra</h2>
              <Badge
                label="Yogyakarta"
                color="#FE0000"
                backgroundColor="#F7A399"
              />
            </div>
          </div>
        </Grid>
        <Grid item md={6}>
          <div className="flex flex-row justify-center p-[16px] gap-[16px] !w-[308px] !h-[84px] shadow-md rounded-lg">
            <div>
              <img
                src={AvatarIkhwan}
                alt="foto profil"
                className="w-[40px] h-[40px] rounded-full"
              />
            </div>
            <div className="flex flex-col items-start gap-[8px]">
              <h2>Dimas Andreyan Pradana Putra</h2>
              <Badge
                label="Yogyakarta"
                color="#FE0000"
                backgroundColor="#F7A399"
              />
            </div>
          </div>
        </Grid>
        <Grid item md={6}>
          <div className="flex flex-row justify-center p-[16px] gap-[16px] !w-[308px] !h-[84px] shadow-md rounded-lg">
            <div>
              <img
                src={AvatarIkhwan}
                alt="foto profil"
                className="w-[40px] h-[40px] rounded-full"
              />
            </div>
            <div className="flex flex-col items-start gap-[8px]">
              <h2>Dimas Andreyan Pradana Putra</h2>
              <Badge
                label="Yogyakarta"
                color="#FE0000"
                backgroundColor="#F7A399"
              />
            </div>
          </div>
        </Grid>
        <Grid item md={6}>
          <div className="flex flex-row justify-center p-[16px] gap-[16px] !w-[308px] !h-[84px] shadow-md rounded-lg">
            <div>
              <img
                src={AvatarIkhwan}
                alt="foto profil"
                className="w-[40px] h-[40px] rounded-full"
              />
            </div>
            <div className="flex flex-col items-start gap-[8px]">
              <h2>Dimas Andreyan Pradana Putra</h2>
              <Badge
                label="Yogyakarta"
                color="#FE0000"
                backgroundColor="#F7A399"
              />
            </div>
          </div>
        </Grid>
        <Grid item md={6}>
          <div className="flex flex-row justify-center p-[16px] gap-[16px] !w-[308px] !h-[84px] shadow-md rounded-lg">
            <div>
              <img
                src={AvatarIkhwan}
                alt="foto profil"
                className="w-[40px] h-[40px] rounded-full"
              />
            </div>
            <div className="flex flex-col items-start gap-[8px]">
              <h2>Dimas Andreyan Pradana Putra</h2>
              <Badge
                label="Yogyakarta"
                color="#FE0000"
                backgroundColor="#F7A399"
              />
            </div>
          </div>
        </Grid>
        <Grid item md={6}>
          <div className="flex flex-row justify-center p-[16px] gap-[16px] !w-[308px] !h-[84px] shadow-md rounded-lg">
            <div>
              <img
                src={AvatarIkhwan}
                alt="foto profil"
                className="w-[40px] h-[40px] rounded-full"
              />
            </div>
            <div className="flex flex-col items-start gap-[8px]">
              <h2>Dimas Andreyan Pradana Putra</h2>
              <Badge
                label="Yogyakarta"
                color="#FE0000"
                backgroundColor="#F7A399"
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default PopupRatingQuestion;
