export const SET_LOADING = "apps/calculator/SET_LOADING";
export const CLEAR_ERROR = "apps/calculator/CLEAR_ERROR";
export const SET_ERROR = "apps/calculator/SET_ERROR";
export const START_FETCHING_CALCULATOR =
  "apps/calculator/START_FETCHING_CALCULATOR";
export const ERROR_FETCHING_CALCULATOR =
  "apps/calculator/ERROR_FETCHING_CALCULATOR";
export const SUCCESS_FETCHING_CALCULATOR =
  "apps/calculator/SUCCESS_FETCHING_CALCULATOR";
export const SET_ADD_CALCULATOR = "apps/calculator/SET_ADD_CALCULATOR";
export const SET_DELETE_CALCULATOR = "apps/calculator/SET_DELETE_CALCULATOR";
