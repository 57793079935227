import React from "react";
import { Checkbox as AntdCheckbox } from "antd";
import PropTypes from "prop-types";
import CheckboxContainer from "./Checkbox.style";

const Checkbox = (props) => {
  const { containerClass, indeterminate, checked, onChange, children } = props;

  return (
    <CheckboxContainer className={`${containerClass}`}>
      <AntdCheckbox
        indeterminate={indeterminate}
        onChange={onChange}
        checked={checked}
      >
        {children}
      </AntdCheckbox>
    </CheckboxContainer>
  );
};

export default Checkbox;

Checkbox.propTypes = {
  containerClass: PropTypes.string,
  indeterminate: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

Checkbox.defaultProps = {
  indeterminate: false,
  checked: false,
  onChange: () => {},
};
