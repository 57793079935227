import { useState } from "react";
import Radios from ".";
import IconBrush from "../../../assets/icons/brush.svg";

const RadiosDocs = () => {
  const [radio, setRadio] = useState([
    {
      value: "jack",
      label: "Jack",
    },
    {
      value: "lucy",
      label: "Lucy",
    },
    {
      value: "tom",
      label: "Tom",
    },
  ]);
  return <Radios options={radio} label="Radio" icon={IconBrush} />;
};

export default RadiosDocs;
