import {
  START_FETCHING_MEMBER_ADMIN,
  SUCCESS_FETCHING_MEMBER_ADMIN,
  ERROR_FETCHING_MEMBER_ADMIN,
  SET_PAGE_ADMIN,
  SET_SIZE_ADMIN,
  SET_KEYWORD_ADMIN,
  SET_TOTAL_ADMIN,
  SET_ID_ROLE,
  SET_ID_ADMIN,
  SET_SAVE_ADMIN_LOADING,
} from "../actionTypes";
import { AdminMemberAPI } from "../../../../utils/api/member";
import debounce from "debounce-promise";
import { handleError, handleErrorBE } from "stores/actions/errorGeneral";
let debounceGetAdmin = debounce(AdminMemberAPI.getListPage, 1500);

const startFetchingPMemberAdmin = () => ({
  type: START_FETCHING_MEMBER_ADMIN,
});

const errorFetchingPMemberAdmin = () => ({
  type: ERROR_FETCHING_MEMBER_ADMIN,
});

const successFetchingPMemberAdmin = (data) => ({
  type: SUCCESS_FETCHING_MEMBER_ADMIN,
  data,
});

const setSaveLoading = (payload) => ({
  type: SET_SAVE_ADMIN_LOADING,
  payload,
});

const setPage = (page) => ({
  type: SET_PAGE_ADMIN,
  page,
});

const setSize = (size) => ({
  type: SET_SIZE_ADMIN,
  size,
});

const setTotal = (total) => ({
  type: SET_TOTAL_ADMIN,
  total,
});

const setKeyword = (keyword) => ({
  type: SET_KEYWORD_ADMIN,
  keyword,
});

const setRoleId = (id_role) => ({
  type: SET_ID_ROLE,
  id_role,
});

const setAdminId = (id_admin) => ({
  type: SET_ID_ADMIN,
  id_admin,
});
const fetchDataAdmin = (params) => {
  return async (dispatch, getState) => {
    dispatch(startFetchingPMemberAdmin());
    try {
      let per_page = getState().admin.per_page;
      let page_number = getState().admin.page_number;
      let sort_column = getState().admin.sort_column;
      let sort_order = getState().admin.sort_order;
      let keyword = getState().admin.keyword;
      let id_role = getState().admin.id_role;
      let payload;
      if (params) {
        payload = params;
      } else {
        payload = {
          per_page,
          page_number,
          sort_column,
          sort_order,
          keyword,
        };
      }

      if (id_role !== "0") {
        payload.id_role = id_role;
      }
      let res = await debounceGetAdmin(payload);
      if (res.data.code === 401) {
        window.location.href = "/example";
      }

      if (res.status === 200) {
        let data = res.data.data === "" ? [] : res.data.data;
        dispatch(successFetchingPMemberAdmin(data));
        dispatch(setTotal(res.data.total_data));
        dispatch(setPage(res.data.page));
      } else {
        dispatch(handleErrorBE(res));
      }
    } catch (error) {
      dispatch(errorFetchingPMemberAdmin());
    }
  };
};

const createAdmin = (payload, action, actionError) => {
  return async (dispatch) => {
    try {
      dispatch(setSaveLoading(true));
      let res = await AdminMemberAPI.postData(payload);
      dispatch(setSaveLoading(false));
      if (res.status === 200) {
        action(res.data);
      } else {
        actionError(res);
        if (res.status !== 400) {
          dispatch(handleErrorBE(res));
        }
      }
    } catch (error) {
      dispatch(setSaveLoading(false));
      actionError(error);
      dispatch(handleError(error));
    }
  };
};
const updatedAdmin = (payload, id, action, callbackError = (message) => {}) => {
  return async (dispatch) => {
    try {
      let res = await AdminMemberAPI.updateData(payload, id);
      if (res.status === 200) {
        action(res.data);
      } else {
        callbackError(res.data);
        dispatch(handleErrorBE(res));
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

const deleteAdmin = (id, action) => {
  return async (dispatch) => {
    dispatch(startFetchingPMemberAdmin());
    try {
      let res = await AdminMemberAPI.deleteData(id);
      if (res.status === 200) {
        action(res.data);
      } else {
        dispatch(handleErrorBE(res));
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

const MemberAction = {
  fetchData: fetchDataAdmin,
  createData: createAdmin,
  deleteData: deleteAdmin,
  setPage: setPage,
  setSize: setSize,
  setKeyword: setKeyword,
  setRoleId: setRoleId,
  setAdminId: setAdminId,
  updatedAdmin: updatedAdmin,
};

export default MemberAction;
