import styled from "styled-components";

const LoginStyle = styled.div`
  .card-login-title {
    font-weight: 600;
    font-size: 16px;
    background: linear-gradient(
      34.99deg,
      #151515 17.46%,
      #3d3d3d 58.62%,
      #595959 87.32%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
  .card-login-desc {
    margin-top: 16px;
    color: #888888;
  }

  .card-login-forgot-pass {
    font-weight: 600;
    color: #101010;
    background-clip: text;
    text-align: right;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    margin: 8px 0 auto auto;
    width: fit-content;
  }

  .card-login-with-phone {
    background: linear-gradient(
      34.99deg,
      #151515 17.46%,
      #3d3d3d 58.62%,
      #595959 87.32%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-align: center;
    font-weight: 600;
    margin-top: 24px;
  }

  .card-line-divider {
    width: 70%;
    margin: auto;
    margin-top: 24px;
    height: 20px;
    border-bottom: 1px solid #bdbdbd;
    text-align: center;

    span {
      background-color: white;
      color: #101010;
      padding: 0 24px;
      position: relative;
      top: 8px;
    }
  }
`;

export default LoginStyle;
