import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import { Table } from "antd";

import MoslemeTable from "../../../components/Table";
import Badge from "../../../components/badge";
import Buttons from "../../../components/buttons";

import AvatarRed from "../../../assets/images/Avatar-ikhwan-red.svg";

import IconWhatsapp from "../../../assets/icons/whatsapp.svg";
import IconVisible from "../../../assets/icons/visible.svg";

import { DocumentCopy, Edit, Eye, Trash } from "iconsax-react";
import BookingUmrohAction from "stores/actions/booking/umroh";

const BookingTable = () => {
  const router = useHistory();
  const dispatch = useDispatch();
  const store = useSelector(({ bookingUmroh }) => bookingUmroh);
  const [listBooking, setListBooking] = useState([]);
  // Expaneble
  const [isExpand, setIsExpand] = useState(true);
  // Selection & type state
  const [selectionType, setSelectionType] = useState("checkbox");
  const [withSelection, setWithSelection] = useState(false);

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: store.total,
    current: store.page_number,
    pageSize: store.per_page,
    defaultPageSize: store.per_page,
    pageSizeOptions: ["10", "20"],
  };

  useEffect(() => {
    dispatch(
      BookingUmrohAction.fetchData({
        page_number: store.page_number,
        per_page: store.per_page,
        keyword: store.keyword,
        sort_column: "",
        sort_order: "DESC",
      })
    );
  }, [store.keyword, store.per_page, store.page_number]);

  useEffect(() => {
    setListBooking(store.data);
  }, [store.data, store.data.length]);

  const columns = [
    Table.SELECTION_COLUMN,
    Table.EXPAND_COLUMN,
    {
      title: "Kode Booking",
      dataIndex: "booking_code",
      render: (val) => (
        <>
          {'#' + val}
        </>
      )
    },
    {
      title: "Foto",
      dataIndex: "photo",
      render: (img) => <img src={img} className="w-8 h-8 rounded-full object-cover" alt="img" />,
    },
    {
      title: "Nama Pemesan",
      dataIndex: "name",
      render: (pemesan) => (
        <>
          <div className="w-max">{pemesan.name}</div>
          <div className="flex flex-row mt-1">
            <Badge 
              label={pemesan.tags[0].role_name}
              backgroundColor={pemesan.tags[0].color_bg_role}
              color={pemesan.tags[0].color_text_role}
               />
            {pemesan.tags[1].branchExist && 
              <Badge
                label={pemesan.tags[1].branch_name}
                customClass="ml-1"
                backgroundColor={pemesan.tags[1].color_bg_branch}
                color={pemesan.tags[1].color_text_branch}
              />
            }
          </div>
        </>
      ),
    },
    {
      title: "Pax",
      dataIndex: "pax",
      render: (pax) => (
        <div className="px-3 gap-2 py-1.5 border-[0.5px] border-[#E0E0E0] rounded-lg text-center inline-flex items-center w-auto cursor-pointer">
          <img src={IconVisible}/>
          <span>{pax} Pax</span>
        </div>
      ),
    },
    {
      title: "Follow Up",
      dataIndex: "followup",
      render: () => (
        <>
          <div className="flex flex-row gap-1">
            <Buttons
              text="1"
              customClass="btn-brown-light btn-small !w-fit"
              icon={IconWhatsapp}
            />
            <Buttons
              text="2"
              customClass="btn-brown-light btn-small !w-fit"
              icon={IconWhatsapp}
            />
            <Buttons
              text="3"
              customClass="btn-brown-light btn-small !w-fit"
              icon={IconWhatsapp}
            />
            <Buttons
              text="4"
              customClass="btn-brown-light btn-small !w-fit"
              icon={IconWhatsapp}
            />
          </div>
          {/* <div className="action">
            
          </div> */}
        </>
      ),
    },
    {
      title: "Paket",
      dataIndex: "paket",
      render: (paket) => (
        <>
          <div>{paket}</div>
          <div className="action">
            <Eye
              className="cursor-pointer"
              onClick={() => router.push("/booking/detail")}
              size="15"
              color="#141414"
            />
            <Trash
              className="cursor-pointer"
              // onClick={() => setOpenPopupDeleteCabang(true)}
              size="15"
              color="#141414"
            />
            <DocumentCopy
              className="cursor-pointer"
              onClick={() => alert(`copy id:`)}
              size="15"
              color="#141414"
            />
            <Edit
              className="cursor-pointer"
              onClick={() => alert(`edit id:`)}
              size="15"
              color="#141414"
            />
          </div>
        </>
      ),
    },
  ];

  // if have expaneble, this is the props example..
  const expandable = {
    expandedRowRender: (record) => {
      return (
        <div>
          <ul className="w-full flex py-3 border-l">
            <li className="w-[15rem]">
              <ul>
                <li className="border-b pl-5 pb-3 font-bold">
                  Tanggal Booking
                </li>
              </ul>
            </li>
            <li className="w-[34rem]">
              <ul>
                <li className="border-b pl-5 pb-3 font-bold">Tipe Kamar</li>
              </ul>
            </li>
            <li className="w-full">
              <ul>
                <li className="border-b pl-5 pb-3 font-bold">Starting</li>
              </ul>
            </li>
          </ul>
          <ul className="w-full flex items-center pb-3 border-l">
            <li className="w-[15rem]">
              <ul>
                <li className="pl-5 pt-2">
                  <div>
                    {moment(record.ordered_at).format("DD/MM/YYYY HH:mm")} WIB
                  </div>
                </li>
              </ul>
            </li>
            <li className="w-[34rem]">
              <ul>
                <li className="pl-5 pt-2 flex gap-2">
                  {
                    record.jumlah_quad_terpakai ? 
                    <Badge
                      backgroundColor="#92E6A7"
                      color="#141414"
                      label={
                        <div className="flex items-center">
                          <div className="text-[#1B662E] font-semibold">
                            Quad <span className="font-normal">({record.jumlah_quad_terpakai} Pax)</span>
                          </div>
                        </div>
                      }
                    />
                    : ''
                  }
                  {
                    record.jumlah_triple_terpakai ? 
                    <Badge
                      backgroundColor="#92E6A7"
                      color="#141414"
                      label={
                        <div className="flex items-center">
                          <div className="text-[#1B662E] font-semibold">
                            Triple <span className="font-normal">({record.jumlah_triple_terpakai} Pax)</span>
                          </div>
                        </div>
                      }
                    />
                    : ''
                  }
                  {
                    record.jumlah_double_terpakai ? 
                    <Badge
                      backgroundColor="#92E6A7"
                      color="#141414"
                      label={
                        <div className="flex items-center">
                          <div className="text-[#1B662E] font-semibold">
                            Double <span className="font-normal">({record.jumlah_double_terpakai} Pax)</span>
                          </div>
                        </div>
                      }
                    />
                    : ''
                  }
                  {
                    record.jumlah_single_terpakai ? 
                    <Badge
                      backgroundColor="#92E6A7"
                      color="#141414"
                      label={
                        <div className="flex items-center">
                          <div className="text-[#1B662E] font-semibold">
                            Single <span className="font-normal">({record.jumlah_single_terpakai} Pax)</span>
                          </div>
                        </div>
                      }
                    />
                    : ''
                  }
                  {
                    !record.jumlah_quad_terpakai 
                    & !record.jumlah_double_terpakai 
                    & !record.jumlah_triple_terpakai
                    & !record.jumlah_quad_terpakai && '-'}
                </li>
              </ul>
            </li>
            <li className="w-full">
              <ul>
                <li className="pl-5 pt-2 flex gap-2">
                  {/* <Badge
                    backgroundColor="rgba(254, 153, 1, 0.2);"
                    color="#FE9A00"
                    label={
                      <div className="flex items-center">
                        <div className="text-[#FE9A00] font-semibold">
                          SOC-CGK <span className="font-normal">(2pax)</span>
                        </div>
                      </div>
                    }
                  /> */}
                  -
                </li>
              </ul>
            </li>
          </ul>
        </div>
      );
    },
    rowExpandable: (record) => record.booking_code !== "Not Expandable",
  };

  console.log({listBooking})

  return (
    <div className="mx-[-14px]">
      {/* Table */}
      <MoslemeTable
        loading={store.status === "process"}
        expandable={isExpand && expandable}
        columns={columns}
        data={
          listBooking &&
          listBooking?.map((item, i) => {
            return {
              ...item,
              key: `${i + 1}`,
              id: item && item.id,
              booking_code: item && item.order_number,
              photo:
                item?.pemesan?.image_profile
                  ? item?.pemesan?.image_profile
                  : AvatarRed,
              name: {
                      name: item && item?.pemesan?.name,
                      tags: [
                        {
                          role_name: item?.user_admin_id ? item?.pemesan?.nama_role : item?.pemesan?.role_name, // karena dr API itu Admin `nama_role`, tapi agent & jamaah `role_name`
                          color_bg_role: item?.pemesan?.color_bg_role,
                          color_text_role: item?.pemesan?.color_text_role
                        },
                        {
                          branchExist: item?.user_jamaah_id ? false : true,
                          branch_name: item?.user_admin_id ? item?.pemesan?.nama_cabang : item?.pemesan?.cabang_name, // karena dr API itu Admin `nama_cabang`, tapi agent & jamaah `cabang_name`
                          color_bg_branch: item?.pemesan?.color_bg_cabang,
                          color_text_branch: item?.pemesan?.color_text_cabang
                        }
                      ],
                    },
              pax: item && item?.kamar_mappings.length,
              followup: item && item?.user_jamaah?.phone,
              paket: item && item?.paket?.judul_paket,
            };
          })
        }
        withSelection={true}
        selectionType={selectionType}
        pagination={pagination}
        customPaginationClass="pr-4"
        customClass="mt-4"
        onPageChange={(page) => dispatch(BookingUmrohAction.setPage(page))}
        onSizeChange={(currentpage, pageSize) => {
          dispatch(BookingUmrohAction.setPage(currentpage));
          dispatch(BookingUmrohAction.setSize(pageSize));
        }}
      />
    </div>
  );
};

export default React.memo(BookingTable);
