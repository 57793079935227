import React, { useCallback, useState } from "react";
import { Grid } from "@material-ui/core";

import SidebarChild from "../../components/template_layout/Siderbar/SidebarChild";
import { settingMenuList } from "../../configuration/settingMenuList";
import SettingAbout from "./components/SettingAbout";
import SettingApps from "./components/SettingApps";
import SettingHelpCenter from "./components/SettingHelpCenter";
import SettingPrivacyPolicy from "./components/SettingPrivacyPolicy";
import SettingTravel from "./components/SettingTravel";
import SettingStyle from "./Setting.Style";
import SettingFAQ from "./components/SettingFAQ";
import SettingNotification from "./components/SettingNotification";

const SettingPage = () => {
  const [showContent, setShowContent] = useState("travel");

  const getContent = useCallback(() => {
    switch (showContent) {
      case "travel":
        return <SettingTravel />;
      case "apps":
        return <SettingApps />;
      case "notification":
        return <SettingNotification />;
      case "help-center":
        return <SettingHelpCenter />;
      case "privacy-policy":
        return <SettingPrivacyPolicy />;
      case "about":
        return <SettingAbout />;
      case "faq":
        return <SettingFAQ />;
      default:
        return <SettingTravel />;
    }
  }, [showContent]);

  return (
    <SettingStyle>
          <SidebarChild
            menuList={settingMenuList}
            handleClick={setShowContent}
            path="/setting"
            showContent={showContent}
          />
          <div style={{ marginLeft: '174px', paddingLeft: '16px' }}>
            {getContent()}
          </div>
    </SettingStyle>
  );
};

export default SettingPage;
