import { useEffect, useState } from "react";
import { Radio, makeStyles } from "@material-ui/core";
import IconEdit from "assets/icons/edit.svg";
import Popup from "components/popup";
import InputsCurrency from "components/form/inputsCurrency";
import Buttons from "components/buttons";
import ILHotel from "assets/images/hotel.png";
import ICChecklist from "assets/icons/Checklist_Round.svg";
import ICStar from "assets/icons/Star.svg";
import Inputs from "components/form/inputs";
import { getLocalStorage } from "utils/helpers";
import { BASE_URL_ENDPOINT } from "constant/constanta";
import axios from "axios";

import { getDataAkomodasiTourCountry } from "utils/api/requestPaket";

import IconJamPasir from "assets/icons/Jam-Pasir.svg";
import { Skeleton } from 'antd';

const useStyle = makeStyles({
  step4: {
    "& .select-label": {
      fontSize: "16px",
      fontWeight: "600",
      color: "#141414",
    },
    "& .input": {
      accentColor: "red",
    },
    "& .content_list": {
      overflowY: "auto",
      overflowX: "hidden",
      "&::-webkit-scrollbar": {
        width: "4px",
        // height: scrollHorizontal ? "5px" : "100px",
      },
      "&::-webkit-scrollbar-track": {
        width: "4px",
        borderRight: "10px solid #fff",
        borderLeft: "10px solid #fff",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#E0E0E0",
        height: "30px",
        width: "4px",
        borderRadius: "16px",
      },
      "&::-webkit-scrollbar-track-piece:start": {
        background: "#fff",
      },
      "&::-webkit-scrollbar-track-piece:end": {
        background: "#fff",
      },
      scrollBehavior: "smooth",
    },
  },
});

const datasHotel = [
  {
    id: 1,
    title: "Movenpick Hotel",
    value: "1",
  },
  {
    id: 2,
    title: "Taiba Suites Hotel",
    value: "2",
  },
  {
    id: 3,
    title: "Taiba Suites Hotel",
    value: "3",
  },
  {
    id: 4,
    title: "Taiba Suites Hotel",
    value: "4",
  },
  {
    id: 5,
    title: "Taiba Suites Hotel",
    value: "5",
  },
];

const Step4 = ({ payload, payloadJumlahHari, setPayload, onHandleError }) => {
  const classes = useStyle();
  const [editJumlahMalamModal, setEditJumlahMalamModal] = useState({
    madinah: false,
    makkah: false,
  });
  const [dataCityTour, setDataCityTour] = useState([]);
  const [dataNegaraTour, setDataNegaraTour] = useState([]);
  const [dataNegaraTourhotel, setDataNegaraTourHotel] = useState([]);
  const [isLoadingCityTour, setIsLoadingCityTour] = useState(false)
  const [isLoadingCountryTour, setIsLoadingCountryTour] = useState(false)
  const [isLoadingHotelTour, setIsLoadingHotelTour] = useState(false)

  useEffect(() => {
    getDataCityTour();
    getDataNegaraTour();

    if(payload.negaraTour) {
      getDataNegaraTourHotel(payload.negaraTour)
    }

  }, []);

  const getDataCityTour = async () => {
    const accessToken = getLocalStorage("accessToken");
    const url = `${BASE_URL_ENDPOINT}/admin/kalkulator-paket/get-all-city-tour`; // Replace with your API endpoint

    const headers = {
      "Content-Type": "application/json", // Adjust content type as needed
      Authorization: `Bearer ${accessToken}`, // Add any authorization headers you need
    };

    try {
      setIsLoadingCityTour(true)
      const response = await axios.post(url, { headers });

      if (response.data.code === 200) {
        setDataCityTour(response.data.data);
      } else {
        onHandleError({
          show: true,
          subtitle: response.data.message,
        })
      }

      setIsLoadingCityTour(false)
    } catch (error) {
      setIsLoadingCityTour(false)
      onHandleError({
        show: true,
        subtitle: error.data.message,
      })
    }
  };

  const getDataNegaraTour = async () => {
    const accessToken = getLocalStorage("accessToken");
    const url = `${BASE_URL_ENDPOINT}/admin/kalkulator-paket/get-all-tour`; // Replace with your API endpoint

    const headers = {
      "Content-Type": "application/json", // Adjust content type as needed
      Authorization: `Bearer ${accessToken}`, // Add any authorization headers you need
    };

    try {
      setIsLoadingCountryTour(true)
      const response = await axios.post(url, { headers });

      if (response.data.code === 200) {
        setDataNegaraTour(response.data.data);
      } else {
        onHandleError({
          show: true,
          subtitle: response.data.message,
        })
      }

      setIsLoadingCountryTour(false)
    } catch (error) {
      setIsLoadingCountryTour(false)
      onHandleError({
        show: true,
        subtitle: error.data.message,
      })
    }
  };

  const getDataNegaraTourHotel = async (value) => {

    try {
      setIsLoadingHotelTour(true)
      const response = await getDataAkomodasiTourCountry({ month: payloadJumlahHari?.bulan , year: payloadJumlahHari?.tahun, paket_kalkulator_tour_id: value });
      
      if (response.data.code === 200) {
        setDataNegaraTourHotel(response.data.data);
      } else {
        onHandleError({
          show: true,
          subtitle: response.data.message,
        })
      }

      setIsLoadingHotelTour(false)
    } catch (error) {
      setIsLoadingHotelTour(false)
      onHandleError({
        show: true,
        subtitle: error.data.message,
      })
    }
  };

  return (
    <div className={classes.step4}>
      <div className="flex justify-between items-center">
        <div className="flex flex-row mb-4 items-center">
          <h1 className="text-base font-semibold text-[#141414]">
            Pilih City Tour
          </h1>
          <h1 className="text-sm font-normal text-[#E82320] mx-2">(Optional)</h1>
        </div>
        { payload.cityTour ? 
          <span 
            className="text-red-3 cursor-pointer" 
            onClick={() => {
              setPayload({
                ...payload,
                cityTour: null,
              })
            }}>
            Reset
          </span> 
          : null
        }
      </div>
      { isLoadingCityTour ?
          <div className="grid grid-cols-3 gap-4">
            {[...Array(6)].map((_, index) => (
              <Skeleton.Input active={true} size="large" block={true} />
            ))}
          </div>
      : null }
      <div className="grid grid-cols-3 gap-3 mb-4 content_list">
        {!isLoadingCityTour ? dataCityTour.map((item, idx) => {
          return (
            <div
              key={idx}
              className={`flex flex-row items-center w-full gap-4 p-4 rounded-2xl border-[1px] ${
                item.id.toString() === payload.cityTour && "border-[#01F91A]"
              }`}
            >
              <input
                className="text-red-500 shrink-0 input w-6 h-6 cursor-pointer"
                type="radio"
                checked={item.id.toString() === payload.cityTour}
                value={item.id}
                onChange={(event) => {
                  setPayload({
                    ...payload,
                    cityTour: event.target.value.toString(),
                  });
                }}
              />
              <h1 className="text-base font-semibold text-[#141414]">
                {item.name}
              </h1>
            </div>
          );
        }) : null}
      </div>
      <div className="flex justify-between items-center">
        <div className="flex flex-row mb-4 items-center">
          <h1 className="text-base font-semibold text-[#141414]">
            Pilih Negara Tour
          </h1>
          <h1 className="text-sm font-normal text-[#E82320] mx-2">(Optional)</h1>
        </div>
        { payload.negaraTour ? 
          <span 
            className="text-red-3 cursor-pointer" 
            onClick={() => {
              setPayload({
                ...payload,
                negaraTour: null,
                negaraTourName: "",
                hotel: null
              })
            }}>
            Reset
          </span> 
          : null
        }
      </div>
      { isLoadingCountryTour ?
          <div className="grid grid-cols-3 gap-4">
            {[...Array(6)].map((_, index) => (
              <Skeleton.Input active={true} size="large" block={true} />
            ))}
          </div>
      : null }
      <div className="grid grid-cols-3 gap-4 mb-4 content_list">
        {!isLoadingCountryTour ? dataNegaraTour.map((item, idx) => {
          return (
            <div
              key={idx}
              className={`flex flex-row gap-4 items-center w-full p-4 rounded-2xl border-[1px] ${
                item.id.toString() === payload.negaraTour && "border-[#01F91A]"
              }`}
            >
              <input
                className="text-red-500 input shrink-0 w-6 h-6 cursor-pointer"
                type="radio"
                checked={item.id.toString() === payload.negaraTour}
                value={item.id}
                onChange={(event) => {
                  setPayload({
                    ...payload,
                    negaraTour: event.target.value.toString(),
                    negaraTourName: item.name,
                    hotel: null,
                  });
                  getDataNegaraTourHotel(event.target.value.toString());
                }}
              />
              <h1 className="text-base font-semibold text-[#141414]">
                {item.name}
              </h1>
            </div>
          );
        }) : null }
      </div>
      {payload.negaraTour ? 
        <div className="flex justify-between items-center">
          <div className="flex flex-row mb-4 items-center">
            <h1 className="text-base font-semibold text-[#141414]">
              Hotel {payload.negaraTourName}
            </h1>
            <h1 className="text-sm font-normal text-[#E82320] mx-2">
              ({payload.jumlah_malam} malam)
            </h1>
            <div
              className="flex gap-4 items-center"
              onClick={() => setEditJumlahMalamModal({ madinah: true })}
            >
              <span className="cursor-pointer shadow-hard p-2 rounded-lg">
                <img src={IconEdit} alt="Edit" />
              </span>
            </div>
          </div>
          { payload.hotel ? 
            <span 
              className="text-red-3 cursor-pointer" 
              onClick={() => setPayload({
                ...payload,
                hotel: null,
              })}>
              Reset
            </span> 
            : null
           }
        </div>
      : null}
      {
        !dataNegaraTourhotel.length && payload.negaraTour && !isLoadingHotelTour ? 
          <div className="flex flex-col justify-center items-center w-full h-full">
            <img src={IconJamPasir} alt="jam_pasir" width="150px" />
            <div className="text-[#141414] text-[14px] font-normal text-center font-sans mt-2 whitespace-pre-line w-64">
              Dibulan & tahun yang anda tentukan belum ada Hotel {payload.negaraTourName} yang tersedia
            </div>
          </div>
        : null
      }
      { isLoadingHotelTour ?
          <div className="grid grid-cols-3 gap-4">
            {[...Array(6)].map((_, index) => (
              <Skeleton.Input active={true} size="large" block={true} />
            ))}
          </div>
      : null }
      {!isLoadingHotelTour && payload.negaraTour ? dataNegaraTourhotel.length > 0 && (
        <>
          <div className="grid grid-cols-3 gap-4 mb-4 content_list">
            {dataNegaraTourhotel?.map((item, idx) => {
              return (
                <div
                  key={idx}
                  className={`flex flex-row gap-4 items-center w-full p-4 rounded-2xl border-[1px] ${
                    item.akomodasi_id.toString() === payload.hotel &&
                    "border-[#01F91A]"
                  }`}
                >
                  <input
                    className="text-red-500 shrink-0 input w-6 h-6 cursor-pointer"
                    type="radio"
                    checked={item.akomodasi_id.toString() === payload.hotel}
                    value={item.akomodasi_id}
                    onChange={(event) => {
                      setPayload({
                        ...payload,
                        hotel: event.target.value.toString(),
                      });
                    }}
                  />
                  <img
                    src={ILHotel}
                    alt="hotel"
                    className="bg-slate-200 shrink-0 w-[64px] h-[64px] rounded-2xl"
                  />
                  <div>
                    <h1 className="text-base font-semibold text-[#141414] mb-1">
                      {item._akomodasi.name}
                    </h1>
                    <div className="flex flex-row">
                      {[...Array(item._akomodasi.bintang)].map((_, index) => (
                        <img src={ICStar} alt="star" className="h-4 w-4" />
                      ))} 
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      ) : null}

      <Popup
        open={editJumlahMalamModal.madinah}
        title="Edit Jumlah Malam Hotel"
        width="640px"
        handleClose={() => {
          //   setSelectedPaket(null);
          setEditJumlahMalamModal({ madinah: false });
        }}
      >
        <div className="border border-gray-5 rounded-xl mt-[7px]">
          <div className="p-4">
            <Inputs
              label="Edit jumlah Malam Hotel"
              placeholder="Jumlah Malam"
              containerClass="w-full mb-2"
              value={payload.jumlah_malam}
              onChange={(e) => {
                setPayload({
                  ...payload,
                  jumlah_malam: e.target.value,
                });
              }}
            />
          </div>
          <div className="border-t border-gray-5 p-4 flex justify-end">
            <Buttons
              text="Simpan"
              customClass="btn-primary btn-large"
              onClick={() => {
                setPayload({
                  ...payload,
                  malam: payload.jumlah_malam,
                });
                setEditJumlahMalamModal({ madinah: false });
              }}
            />
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default Step4;
