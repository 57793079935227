import styled from "styled-components";

const SlidersStyle = styled.div`
  .custom-slick {
    .slick-slide {
      // width: auto!important;
      margin-right: 8px;
      span {
        white-space: nowrap;
      }
    }
    
  
    .slick-track {
      display: flex;
      // gap: 8px;
      margin-left: unset;
      margin-right: unset;
    }

    .slick-dots li.slick-active button:before {
      color: #e82320;
    }

    .slick-dots li button:before {
      color: #f99898;
    }

    .custom-slick-arrow-prev {
      left: -7px;
    }

    .custom-slick-arrow-next {
      right: -7px;
    }

    .custom-slick-arrow-prev,
    .custom-slick-arrow-next {
      border-radius: 40px;
      height: 24px;
      width: 24px;
      z-index: 100;

      .slick-prev.slick-disabled:before,
      .slick-next.slick-disabled:before {
        opacity: 0.3;
      }
    }

    .custom-slick-arrow-prev img {
      transform: rotate(180deg);
    }

    .slick-prev::before,
    .slick-next::before {
      content: "";
    }

    .slick-disabled.custom-slick-arrow-prev,
    .slick-disabled.custom-slick-arrow-next {
      opacity: 0;
    }

    .active {
      background: linear-gradient(204.44deg, #01F91A -18.49%, #359B05 84.72%);
      border: none;
      box-shadow: 0px 10px 16px -6px rgba(0, 0, 0, 0.04), 0px 4px 80px -4px rgba(0, 0, 0, 0.04);
    }

    .slider-item-custom {
      border: 0.5px solid #E0E0E0;
    }
  }
`;

export default SlidersStyle;
