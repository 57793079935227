import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { handleError, handleSuccess } from "stores/actions/errorGeneral";

import { PriceUpdateAPI } from "utils/api/calculator/price-update";

import InputsCurrency from "components/form/inputsCurrency";
import Buttons from "components/buttons";

const OperationalPricUpdate = () => {
    const dispatch = useDispatch()

    const [isLoadingGet, setIsLoadingGet] = useState(false)
    const [isLoadingSave, setIsLoadingSave] = useState(false)

    const [operational, setOperational] = useState({
        penjemputan: "",
        sopir: "",
        handling_perpax: "",
        portal_hotel: "",
        perlengkapan: "",
        visa: "",
        muassasah: "",
        handling: "",
        porter_bandara: "",
        air_zamzam: "",
        konsumsi: "",
        bus: "",
        jasa_guide: "",
    });


    useEffect(() => {
        onHandleGet()
    },[])

    const onHandleGet = async () => {
        try {
            setIsLoadingGet(true)
            const res = await PriceUpdateAPI.operationalGet()

            if(res.status === 200) {
                setOperational(res.data.data)
            } else {
                dispatch(handleError(res))
            }

            setIsLoadingGet(false)
            
        } catch (error) {
            dispatch(handleError(error))
            setIsLoadingGet(false)
        }
    }

    const onHandleSave = async () => {
        try {
            setIsLoadingSave(true)
            const res = await PriceUpdateAPI.operationalSave(operational)

            if(res.status === 200) {
                dispatch(handleSuccess(res))
                setOperational(res.data.data)
            } else {
                dispatch(handleError(res))
            }

            setIsLoadingSave(false)
            
        } catch (error) {
            dispatch(handleError(error))
            setIsLoadingSave(false)
        }
    }

    const handleInputOperational = (key, value) => {
        const newOperational = { ...operational };
        newOperational[key] = value;
        setOperational(newOperational);
    };

    return (

        <>
            <div className="flex flex-row">
              <div className="w-[25%]">
                <div className="text-sm font-semibold">Biaya Operasional</div>
              </div>
              <div className="w-[75%]">
                <div className="flex justify-end gap-4 items-center">
                  <div />
                  <Buttons
                    text="Simpan"
                    loading={isLoadingSave}
                    onClick={onHandleSave}
                    // loading={updateHargaStore.saveMaskapaiLoading}
                    // onClick={onHandleAddBiayaOperasional}
                    // disabled={
                    //   addMaskapai.maskapai_id === "" &&
                    //   addMaskapai.month === "" &&
                    //   addMaskapai.year === "" &&
                    //   addMaskapai.price === "0"
                    //     ? true
                    //     : false
                    // }
                    customClass="btn-primary btn-small"
                  />
                </div>
                <div className="flex gap-4 mt-4">
                  <div className="w-1/2 flex flex-col gap-4">
                    <InputsCurrency
                      label="Penjemputan"
                      value={operational.penjemputan}
                      placeholder="Masukan harga"
                      onChange={(val) =>
                        handleInputOperational("penjemputan", val)
                      }
                    />
                    <InputsCurrency
                      label="Sopir"
                      value={operational.sopir}
                      placeholder="Masukan harga"
                      onChange={(val) => handleInputOperational("sopir", val)}
                    />
                    <InputsCurrency
                      label="Handling Perpax"
                      value={operational.handling_perpax}
                      placeholder="Masukan harga"
                      onChange={(val) =>
                        handleInputOperational("handling_perpax", val)
                      }
                    />
                    <InputsCurrency
                      label="Porter Hotel"
                      value={operational.portal_hotel}
                      placeholder="Masukan harga"
                      onChange={(val) =>
                        handleInputOperational("portal_hotel", val)
                      }
                    />
                    <InputsCurrency
                      label="Perlengkapan"
                      value={operational.perlengkapan}
                      placeholder="Masukan harga"
                      onChange={(val) =>
                        handleInputOperational("perlengkapan", val)
                      }
                    />
                    <InputsCurrency
                      label="Visa"
                      value={operational.visa}
                      placeholder="Masukan harga"
                      onChange={(val) => handleInputOperational("visa", val)}
                    />
                  </div>

                  <div className="w-1/2 flex flex-col gap-4">
                    <InputsCurrency
                      label="Muassasah"
                      value={operational.muassasah}
                      placeholder="Masukan harga"
                      onChange={(val) =>
                        handleInputOperational("muassasah", val)
                      }
                    />
                    <InputsCurrency
                      label="Handling"
                      value={operational.handling}
                      placeholder="Masukan harga"
                      onChange={(val) =>
                        handleInputOperational("handling", val)
                      }
                    />
                    <InputsCurrency
                      label="Porter Bandara"
                      value={operational.porter_bandara}
                      placeholder="Masukan harga"
                      onChange={(val) =>
                        handleInputOperational("porter_bandara", val)
                      }
                    />
                    <InputsCurrency
                      label="Air Zam-Zam"
                      value={operational.air_zamzam}
                      placeholder="Masukan harga"
                      onChange={(val) =>
                        handleInputOperational("air_zamzam", val)
                      }
                    />
                    <InputsCurrency
                      label="Konsumsi"
                      value={operational.konsumsi}
                      placeholder="Masukan harga"
                      onChange={(val) =>
                        handleInputOperational("konsumsi", val)
                      }
                    />
                    <InputsCurrency
                      label="Bus"
                      value={operational.bus}
                      placeholder="Masukan harga"
                      onChange={(val) => handleInputOperational("bus", val)}
                    />
                  </div>
                </div>
                <div className="w-full mt-4">
                  <InputsCurrency
                    label="Jasa Per-1 guide"
                    value={operational.jasa_guide}
                    placeholder="Masukan harga"
                    onChange={(val) =>
                      handleInputOperational("jasa_guide", val)
                    }
                  />
                </div>
              </div>
            </div>
        </>

    )
}

export default OperationalPricUpdate