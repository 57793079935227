import React from "react";

const ItemText = ({ title, total, styleTitle, styleTotal }) => {
  return (
    <div className="text-sm text-white">
      <div className={`${styleTitle}`}>{title}</div>
      <div className={`font-semibold ${styleTotal}`}>{total}</div>
    </div>
  );
};

export default ItemText;
