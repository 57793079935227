import React, { useState } from "react";

import Selects from "components/form/selects";
import Rates from "components/form/rate";
import Badge from "components/badge";

import IconStar from "assets/icons/Star.svg";
import AvatarRed from "assets/images/Avatar-ikhwan-red.svg";
import IconDelete from "assets/icons/trash.svg";
import Buttons from "components/buttons";

const AddTestimoniIbadahku = () => {
  const [value, setValue] = useState(3);
  const [options, setOptions] = useState([
    {
      value: "jack",
      label: "Jack",
    },
    {
      value: "lucy",
      label: "Lucy",
    },
    {
      value: "tom",
      label: "Tom",
    },
  ]);

  return (
    <>
      <div className="border border-dashed border-[#E0E0E0] rounded-2xl p-4 flex flex-row justify-between">
        <div className="flex flex-col gap-2">
          <div className="font-semibold">Filter Rating</div>
          <div className="flex flex-row flex-wrap w-[400px] gap-2">
            <div className="flex flex-row items-center gap-2 border-[0.5px] border-[#BDBDBD] rounded-lg p-2">
              <img src={IconStar} alt="star" />
              <div>
                <span className="font-semibold">1</span> (Sangat buruk)
              </div>
            </div>
            <div className="flex flex-row items-center gap-2 border-[0.5px] border-[#BDBDBD] rounded-lg p-2">
              <img src={IconStar} alt="star" />
              <div>
                <span className="font-semibold">2</span> (Buruk)
              </div>
            </div>
            <div className="flex flex-row items-center gap-2 border-[0.5px] border-[#BDBDBD] rounded-lg p-2">
              <img src={IconStar} alt="star" />
              <div>
                <span className="font-semibold">3</span> (Cukup)
              </div>
            </div>
            <div className="flex flex-row items-center gap-2 bg-[#F3E9DC] border-[0.5px] border-[#F2994A] rounded-lg p-2">
              <img src={IconStar} alt="star" />
              <div>
                <span className="font-semibold">4</span> (Baik)
              </div>
            </div>
            <div className="flex flex-row items-center gap-2 border-[0.5px] border-[#BDBDBD] rounded-lg p-2">
              <img src={IconStar} alt="star" />
              <div>
                <span className="font-semibold">5</span> (Sangat Baik)
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center gap-4">
          <Selects
            label="Jenis Paket"
            placeholder="Semua Jenis"
            customClass="!w-[200px]"
            options={options}
            searchable
            itemRenderer={({ state, item, methods }) => {
              return (
                <div
                  className={`react-dropdown-select-item ${
                    state.values.length !== 0 &&
                    state.values[0].value === item.value &&
                    "react-dropdown-select-item-selected"
                  }`}
                  onClick={() => methods.addItem(item)}
                >
                  {item.label}
                </div>
              );
            }}
          />
          <Selects
            label="Paket"
            placeholder="Semua Paket"
            customClass="!w-[200px]"
            options={options}
            searchable
            itemRenderer={({ state, item, methods }) => {
              return (
                <div
                  className={`react-dropdown-select-item ${
                    state.values.length !== 0 &&
                    state.values[0].value === item.value &&
                    "react-dropdown-select-item-selected"
                  }`}
                  onClick={() => methods.addItem(item)}
                >
                  {item.label}
                </div>
              );
            }}
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 mt-4">
        <div className="bg-white soft-shadow rounded-2xl">
          <div className="flex flex-row justify-between p-4">
            <div className="flex flex-row items-center gap-2 relative">
              <img src={AvatarRed} alt="foto" />
              <div>
                <div className="font-semibold">Aisyah Nabila Andreyan</div>
                <Rates
                  containerClass="mt-1"
                  value={value}
                  setValue={setValue}
                />
              </div>
              <Badge
                customClass="!absolute top-0 right-[-68px] !px-2 !py-1"
                label="Tabungan"
              />
            </div>
            <div className="bg-white hard-shadow rounded-lg p-2 h-fit">
              <img className="cursor-pointer" src={IconDelete} alt="delete" />
            </div>
          </div>
          <div className="divider"></div>
          <div className="p-4 text-[#828282]">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam,
            purus sit amet luctus venenatis, lectus magna fringilla urna,
            porttitor rhoncus dolor purus non enim praesent elementum facilisis
            leo, vel fringilla est
          </div>
          <div className="divider"></div>
          <div className="flex flex-row justify-end p-4">
            <Buttons text="Tambahkan" customClass="btn-primary btn-small" />
          </div>
        </div>
        <div className="bg-white soft-shadow rounded-2xl">
          <div className="flex flex-row justify-between p-4">
            <div className="flex flex-row items-center gap-2 relative">
              <img src={AvatarRed} alt="foto" />
              <div>
                <div className="font-semibold">Aisyah Nabila Andreyan</div>
                <Rates
                  containerClass="mt-1"
                  value={value}
                  setValue={setValue}
                />
              </div>
              <Badge
                customClass="!absolute top-0 right-[-68px] !px-2 !py-1"
                label="Tabungan"
              />
            </div>
            <div className="bg-white hard-shadow rounded-lg p-2 h-fit">
              <img className="cursor-pointer" src={IconDelete} alt="delete" />
            </div>
          </div>
          <div className="divider"></div>
          <div className="p-4 text-[#828282]">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam,
            purus sit amet luctus venenatis, lectus magna fringilla urna,
            porttitor rhoncus dolor purus non enim praesent elementum facilisis
            leo, vel fringilla est
          </div>
          <div className="divider"></div>
          <div className="flex flex-row justify-end p-4">
            <Buttons text="Tambahkan" customClass="btn-primary btn-small" />
          </div>
        </div>
        <div className="bg-white soft-shadow rounded-2xl">
          <div className="flex flex-row justify-between p-4">
            <div className="flex flex-row items-center gap-2 relative">
              <img src={AvatarRed} alt="foto" />
              <div>
                <div className="font-semibold">Aisyah Nabila Andreyan</div>
                <Rates
                  containerClass="mt-1"
                  value={value}
                  setValue={setValue}
                />
              </div>
              <Badge
                customClass="!absolute top-0 right-[-68px] !px-2 !py-1"
                label="Tabungan"
              />
            </div>
            <div className="bg-white hard-shadow rounded-lg p-2 h-fit">
              <img className="cursor-pointer" src={IconDelete} alt="delete" />
            </div>
          </div>
          <div className="divider"></div>
          <div className="p-4 text-[#828282]">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam,
            purus sit amet luctus venenatis, lectus magna fringilla urna,
            porttitor rhoncus dolor purus non enim praesent elementum facilisis
            leo, vel fringilla est
          </div>
          <div className="divider"></div>
          <div className="flex flex-row justify-end p-4">
            <Buttons text="Tambahkan" customClass="btn-primary btn-small" />
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(AddTestimoniIbadahku);
