import IconGalery from "assets/icons/Galery.svg";
import IconTestimoni from "assets/icons/Testimoni-Grey.svg";
import IconSlide from "assets/icons/Slide-Grey.svg";
import IconArtikel from "assets/icons/Artikel-Desktop.svg";
import IconGuide from "assets/icons/Muthowwif-Guide.svg";
import IcondDataStaff from "assets/icons/Data-Staff.svg";
import IconDataMitra from "assets/icons/Data-Mitra.svg";

export const appSidebarList = [
  {
    title: "Galeri",
    key: "galeri",
    icon: IconGalery,
    order: 0,
  },
  {
    title: "Testimoni",
    key: "testimoni",
    icon: IconTestimoni,
    order: 1,
  },
  {
    title: "Slide",
    key: "slide",
    icon: IconSlide,
    order: 2,
  },
  {
    title: "Artikel",
    key: "artikel",
    icon: IconArtikel,
    order: 3,
  },
  {
    title: "Pembimbing & Guide",
    key: "guide",
    icon: IconGuide,
    order: 4,
  },
  {
    title: "Data Staff",
    key: "staff",
    icon: IcondDataStaff,
    order: 5,
  },
  {
    title: "Data Mitra",
    key: "mitra",
    icon: IconDataMitra,
    order: 3,
  },
];
