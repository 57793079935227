import React from 'react';
import PropType from 'prop-types';

import Buttons from '../../../../../components/buttons';

import { Edit, Eye, Trash } from 'iconsax-react';
import { useDispatch, useSelector } from 'react-redux';
import SettingAction from 'stores/actions/article';
import { Spin } from 'antd';
const parse = require('html-react-parser');

const ArtikelHelpCenter = ({ setOpenPopupAddArticle }) => {
  const dispatch = useDispatch();
  const store = useSelector(({ articleSetting }) => articleSetting);
  // console.log('store: ', store);

  React.useEffect(() => {
    dispatch(SettingAction.fetchData());
  }, [dispatch]);

  return (
    <div>
      <Buttons
        text="Tambah Artikel"
        customClass="btn-primary btn-small !w-fit absolute right-[0] top-[-52px]"
        onClick={() => setOpenPopupAddArticle(true)}
      />
      {store.status === 'process' ? (
        <div className="flex justify-center items-center py-10">
          <Spin size="large" />
        </div>
      ) : (
        store.data.map((v) => {
          return (
            <div
              key={v.id}
              className="card-article border-[1px] border-[#4048521a] rounded-[12px] p-4 mt-2"
            >
              <div className="font-semibold">{v.judul}</div>
              <div className="mt-2 line-clamp-2">{parse(v.isi)}</div>
              <div className="flex flex-row gap-2">
                {/* <div className="border-[1px] border-[#BDBDBD] rounded-[40px] px-2 py-1 mt-4">Thawaf</div>
          <div className="border-[1px] border-[#BDBDBD] rounded-[40px] px-2 py-1 mt-4">Umroh</div> */}
              </div>
              <div className="card-article-actions gap-6">
                {/* <Eye
                  className="cursor-pointer"
                  onClick={() => alert(`edit id:`)}
                  size="15"
                  color="#141414"
                /> */}
                <Trash
                  className="cursor-pointer"
                  onClick={() => dispatch(SettingAction.deleteData(v.id_artikel_bantuan))}
                  size="15"
                  color="#141414"
                />
                {/* <Edit
                  className="cursor-pointer"
                  onClick={() => alert(`edit id:`)}
                  size="15"
                  color="#141414"
                /> */}
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

ArtikelHelpCenter.propType = {
  setOpenPopupAddArticle: PropType.func,
};

export default React.memo(ArtikelHelpCenter);
