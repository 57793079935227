import React, { useCallback, useState } from "react";
import { Grid } from "@material-ui/core";
import SidebarChild from "../../components/template_layout/Siderbar/SidebarChild";
import { menuMarketingSidebarList } from "configuration/MarketingMenuList";
import Marketing from "./detail/marketing";

const MarketingKit = () => {
  const [showContent, setShowContent] = useState("marketing");

  const getContent = useCallback(() => {
    switch (showContent) {
      case "marketing":
        return <Marketing />;
      default:
        return <div>marketing</div>;
    }
  }, [showContent]);

  return (
    <>
      <SidebarChild
        menuList={menuMarketingSidebarList}
        handleClick={setShowContent}
        showContent={showContent}
      />
      <div style={{ marginLeft: '174px', paddingLeft: '16px' }}>
        {getContent()}
      </div>

    </>
  );
};

export default MarketingKit;
