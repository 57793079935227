
import { 
    MASKAPAI_FETCH_SET_LOADING,
    MASKAPAI_FETCH_SUCCESS,
    MASKAPAI_SAVE_SET_LOADING,
    MASKAPAI_SAVE_SUCCESS,

    MASKAPAI_REMOVE_SET_LOADING,
    MASKAPAI_GET_PRICE_SET_LOADING
} from "stores/actions/calculator/price-update/actionTypes";

const initialState = {
    listMaskapai: [],

    isSavingMaskapaiLoading: false,
    maskapai: {},

    isLoadingRemoveMaskapai: false,
    isGetPriceLoading: false
}

export default function reducer(state = initialState, action) {

    switch(action.type) {
        case MASKAPAI_FETCH_SUCCESS:
            return {
                ...state,
                listMaskapai: action.payload.data
            }
        
        case MASKAPAI_SAVE_SET_LOADING:
            return {
                ...state,
                isSavingMaskapaiLoading: action.payload
            }
        
        case MASKAPAI_SAVE_SUCCESS: 
            return {
                ...state,
                maskapai: action.payload.data
            }

        case MASKAPAI_REMOVE_SET_LOADING:
            return {
                ...state,
                isLoadingRemoveMaskapai: action.payload
            }

        case MASKAPAI_GET_PRICE_SET_LOADING:
            return {
                ...state,
                isGetPriceLoading: action.payload
            }

        default:
            return state;
    }

}