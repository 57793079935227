import React, { useState } from "react";
import { Grid } from "@material-ui/core";

import Accordions from "../../../../components/accordions";
import Badge from "../../../../components/badge";
import Buttons from "../../../../components/buttons";
import Progressbars from "../../../../components/progressbars";
import Tab from "../../../../components/tab";
import MoslemeTable from "../../../../components/Table";

import AvatarRed from "../../../../assets/images/Avatar-ikhwan-red.svg";
import IconEdit from "../../../../assets/icons/edit.svg";
import IconSMS from "../../../../assets/icons/sms.svg";
import IconWhatsapp from "../../../../assets/icons/whatsapp.svg";
import AdditionalTable from "./AdditionalTable";
import JamaahEquipment from "./JamaahEquipment";

const BookingDetailDataJamaah = () => {
  const getContent = () => {
    return (
      <>
        <div>
          <Grid container spacing={2}>
            <Grid item md={7}>
              <div className="pink-bg-shine">
                <div className="flex flex-row justify-between">
                  <div className="text-[#FFFFFF]">Waktu Booking:</div>
                  <div className="bg-[#FFFFFF] rounded-lg p-2">
                    <img
                      className="w-[20px] h-[20px]"
                      src={IconEdit}
                      alt="user-img"
                    />
                  </div>
                </div>
                <div className="flex flex-row justify-between mt-4 items-center">
                  <div className="font-semibold text-[40px] txt-yellow">
                    Double
                  </div>
                  <Badge
                    label="BPN-CGK"
                    customClass="h-fit !px-4 !py-1 !text-[16px] !font-semibold drop-bg-50"
                  />
                </div>
              </div>
            </Grid>
            <Grid item md={5}>
              <div className="pink-bg-shine">
                <div className="flex flex-row justify-between">
                  <div className="text-white text-[16px]">Follow Up:</div>
                  <div className="bg-white p-2 rounded-lg">
                    <img
                      className="w-[20px] h-[20px]"
                      src={IconEdit}
                      alt="user-img"
                    />
                  </div>
                </div>

                <div className="flex flex-row gap-14 items-center mt-6">
                  <div>
                    <div className="flex flex-row">
                      <img src={IconSMS} alt="sms" />
                      <div className="ml-1 text-white">rifusid17@gmail.com</div>
                    </div>
                    <div className="flex flex-row mt-2">
                      <Buttons
                        text="1"
                        customClass="btn-white-light btn-small !w-fit"
                        icon={IconSMS}
                      />
                      <Buttons
                        text="2"
                        customClass="btn-white-light btn-small !w-fit ml-1"
                        icon={IconSMS}
                      />
                      <Buttons
                        text="3"
                        customClass="btn-white-light btn-small !w-fit ml-1"
                        icon={IconSMS}
                      />
                      <Buttons
                        text="4"
                        customClass="btn-white-light btn-small !w-fit ml-1"
                        icon={IconSMS}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="flex flex-row">
                      <img src={IconWhatsapp} alt="whatsapp" />
                      <div className="ml-1 text-white">0812-2528-5261</div>
                    </div>
                    <div className="flex flex-row mt-2">
                      <Buttons
                        text="1"
                        customClass="btn-white-light btn-small !w-fit"
                        icon={IconWhatsapp}
                      />
                      <Buttons
                        text="2"
                        customClass="btn-white-light btn-small !w-fit ml-1"
                        icon={IconWhatsapp}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>

          <div className="mt-4">
            <Grid container spacing={2}>
              <Grid item md={6}>
                <div className="bg-white soft-shadow rounded-2xl p-4">
                  <div className="flex flex-row justify-between">
                    <div className="text-[16px] font-semibold">
                      Pas Foto 4x6
                    </div>
                    <div className="bg-[#FFFFFF] rounded-lg p-2">
                      <img
                        className="w-[20px] h-[20px]"
                        src={IconEdit}
                        alt="user-img"
                      />
                    </div>
                  </div>
                  <Progressbars containerClass="mt-2" percent={40} info="1/3" />
                </div>
              </Grid>
              <Grid item md={6}>
                <div className="bg-white soft-shadow rounded-2xl p-4">
                  <div className="flex flex-row justify-between">
                    <div className="text-[16px] font-semibold">
                      Identitas (KTP & Passport)
                    </div>
                    <div className="bg-[#FFFFFF] rounded-lg p-2">
                      <img
                        className="w-[20px] h-[20px]"
                        src={IconEdit}
                        alt="user-img"
                      />
                    </div>
                  </div>
                  <Progressbars containerClass="mt-2" percent={40} info="1/3" />
                </div>
              </Grid>
              <Grid item md={6}>
                <div className="bg-white soft-shadow rounded-2xl p-4">
                  <div className="flex flex-row justify-between">
                    <div className="text-[16px] font-semibold">
                      Informasi Kontak
                    </div>
                    <div className="bg-[#FFFFFF] rounded-lg p-2">
                      <img
                        className="w-[20px] h-[20px]"
                        src={IconEdit}
                        alt="user-img"
                      />
                    </div>
                  </div>
                  <Progressbars containerClass="mt-2" percent={40} info="1/3" />
                </div>
              </Grid>
              <Grid item md={6}>
                <div className="bg-white soft-shadow rounded-2xl p-4">
                  <div className="flex flex-row justify-between">
                    <div className="text-[16px] font-semibold">
                      Informasi Keluarga
                    </div>
                    <div className="bg-[#FFFFFF] rounded-lg p-2">
                      <img
                        className="w-[20px] h-[20px]"
                        src={IconEdit}
                        alt="user-img"
                      />
                    </div>
                  </div>
                  <Progressbars containerClass="mt-2" percent={40} info="1/3" />
                </div>
              </Grid>
              <Grid item md={6}>
                <div className="bg-white soft-shadow rounded-2xl p-4">
                  <div className="flex flex-row justify-between">
                    <div className="text-[16px] font-semibold">
                      Vaksin Covid
                    </div>
                    <div className="bg-[#FFFFFF] rounded-lg p-2">
                      <img
                        className="w-[20px] h-[20px]"
                        src={IconEdit}
                        alt="user-img"
                      />
                    </div>
                  </div>
                  <Progressbars containerClass="mt-2" percent={40} info="1/3" />
                </div>
              </Grid>
              <Grid item md={6}>
                <div className="bg-white soft-shadow rounded-2xl p-4">
                  <div className="flex flex-row justify-between">
                    <div className="text-[16px] font-semibold">Meningitis</div>
                    <div className="bg-[#FFFFFF] rounded-lg p-2">
                      <img
                        className="w-[20px] h-[20px]"
                        src={IconEdit}
                        alt="user-img"
                      />
                    </div>
                  </div>
                  <Progressbars containerClass="mt-2" percent={40} info="1/3" />
                </div>
              </Grid>
            </Grid>

            <Tab
              containerClass="mt-4"
              defaultActiveKey="1"
              items={[
                {
                  label: "Additional",
                  key: "1",
                  children: <AdditionalTable />,
                },
                {
                  label: "Perlengkapan",
                  key: "2",
                  children: <JamaahEquipment />,
                },
              ]}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      <Accordions
        avatar={AvatarRed}
        title="Tn. Abdul Wahid Ngatono"
        customTitleClass="font-semibold"
        content={getContent()}
      />
    </div>
  );
};

export default React.memo(BookingDetailDataJamaah);
