import React, { useEffect, useState } from "react";
import PropType from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import Buttons from "components/buttons";
import AntdSelect from "components/form/antdSelect";
import "../styles/index.scss";

import IconTrash from "assets/icons/trash.svg";
import { getAllDataPembimbing } from "stores/actions/datamaster";
import { handleSaveOrEditPembimbing } from "stores/actions/paket/infoDasar";
import { handleSuccess } from "stores/actions/errorGeneral";

let data_state = {
  guide: {
    id: 2,
    name: "Guide Khoir",
    description:
      "Paket Umrah adalah kesepakatan khusus yang ditawarkan oleh agen perjalanan kepada jamaah Muslim yang ingin menjalani ibadah umrah di Tanah Suci, Mekah dan Madinah. Dalam paket ini, jamaah akan mendapatkan akomodasi di dekat Masjidil Haram dan Masjid Nabaw",
    photo:
      "https://s3-ap-southeast-1.amazonaws.com/mosleme.travel.bucket/mosleme-travels/admin/MjAyMzEwMjAwODAxc2V0TVJ2U0xhRU9tQQ.jpg",
    value: "Guide Khoir",
    label: "Guide Khoir",
  },
  pembimbing: {
    id: 1,
    name: "Abdullah Khoir",
    description:
      "Paket Umrah adalah kesepakatan khusus yang ditawarkan oleh agen perjalanan kepada jamaah Muslim yang ingin menjalani ibadah umrah di Tanah Suci, Mekah dan Madinah. Dalam paket ini, jamaah akan mendapatkan akomodasi di dekat Masjidil Haram dan Masjid Nabaw",
    photo:
      "https://s3-ap-southeast-1.amazonaws.com/mosleme.travel.bucket/mosleme-travels/admin/MjAyMzEwMTcxMTM0T3RUYUFNTEVtc01vVg.png",
    value: "Abdullah Khoir",
    label: "Abdullah Khoir",
  },
};

const PembimbingGuide = ({
  info,
  closePopup,
  setSuccessEdit,
  selectedPembimbing,
  selectedGuide,
}) => {
  const dispatch = useDispatch();
  const store = useSelector(({ datamaster }) => datamaster.dataPembimbing);
  let data = info;
  const [listPembimbing, setListPembimbing] = useState([]);
  const [listGuide, setListGuide] = useState([]);

  const {
    values,
    touched,
    errors,
    handleSubmit,
    setFieldValue,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    initialValues: {
      forms: [data_state],
    },
    validationSchema: Yup.object().shape({}),
    onSubmit: (values, actions) => {
      let value = [];
      values.forms.map((val, idx) => {
        if (val.pembimbing) {
          value.push(val.pembimbing.id);
        }
        if (val.guide) {
          value.push(val.guide.id);
        }
      });

      dispatch(
        handleSaveOrEditPembimbing(
          {
            paket_id: data.id_paket,
            pembimbing_id: value,
          },
          (message) => {
            actions.resetForm();
            setSuccessEdit(true);
            closePopup(false);

            dispatch(handleSuccess(message));
          },
          (error) => {
            setSubmitting(false);
          }
        )
      );
    },
  });

  useEffect(() => {
    dispatch(
      getAllDataPembimbing({
        sort_column: "",
        sort_order: "Desc",
        keyword: "",
      })
    );
  }, []);

  useEffect(() => {
    let loopGuide = [];
    let loopPembimbing = [];
    store?.data?.map((val, idx) => {
      if (val.type === "guide") {
        loopGuide.push({
          id: val.id_mentor,
          name: val.name,
          description: val.description,
          photo: val.photo,
          value: val.name,
          label: val.name,
        });
        setListGuide(loopGuide);
      } else {
        loopPembimbing.push({
          id: val.id_mentor,
          name: val.name,
          description: val.description,
          photo: val.photo,
          value: val.name,
          label: val.name,
        });
        setListPembimbing(loopPembimbing);
      }
    });
  }, [store?.data, store?.data?.length]);

  useEffect(() => {
    if (info.pembimbing_paket?.length > 0) {
      setFieldValue("forms", info.pembimbing_paket);
    }
  }, [info]);

  return (
    <>
      <div className="flex flex-col items-center gap-[20px] p-4">
        {values.forms.map((item, idx) => (
          <div className="flex flex-row items-center justify-between gap-[16px] w-[100%]">
            <div className="w-[100%]">
              <AntdSelect
                label={`Pembimbing ${idx + 1}`}
                placeholder="Select Pembimbing"
                options={listPembimbing}
                containerClass="!w-[423px]"
                error={touched.forms && errors.forms && errors.forms}
                customClass={`!w-[423px] ${
                  touched.forms && errors.forms && "select-error"
                }`}
                value={item.pembimbing.name}
                onChange={(value, opt) => {
                  let _temp = [...values.forms];
                  _temp[idx]["pembimbing"] = opt;
                  setFieldValue("forms", _temp);
                }}
              >
                {listPembimbing.map((val, idx) => {
                  return (
                    <div
                      value={val.value}
                      data={val}
                      label={val.label}
                      key={idx}
                    >
                      {val.label}
                    </div>
                  );
                })}
              </AntdSelect>
            </div>
            <div className="w-[100%]">
              <AntdSelect
                label={`Guide ${idx + 1}`}
                placeholder="Select Guide"
                options={listGuide}
                containerClass="!w-[423px]"
                error={touched.forms && errors.forms && errors.forms}
                customClass={`!w-[423px] ${
                  touched.forms && errors.forms && "select-error"
                }`}
                value={item.guide.name}
                onChange={(value, opt) => {
                  let _temp = [...values.forms];
                  _temp[idx]["guide"] = opt;
                  setFieldValue("forms", _temp);
                }}
              >
                {listGuide.map((val, idx) => {
                  return (
                    <div
                      value={val.value}
                      data={val}
                      label={val.label}
                      key={idx}
                    >
                      {val.label}
                    </div>
                  );
                })}
              </AntdSelect>
            </div>
            {idx !== 0 && (
              <img
                src={IconTrash}
                className="cursor-pointer h-[24px] w-[24px] relative top-4"
                onClick={() => {
                  let _temp = [...values.forms];
                  _temp.splice(idx, 1);
                  setFieldValue("forms", _temp);
                }}
                alt="trash"
              />
            )}
          </div>
        ))}

        <div className="w-[100%]">
          <Buttons
            text="Tambah Pembimbing & Guide"
            customClass="btn-outline !w-full btn-medium"
            onClick={() => {
              let _temp = [...values.forms, { ...values.forms[0] }];
              setFieldValue("forms", _temp);
            }}
          />
        </div>
      </div>
      <div className="flex flex-col justify-end border-t py-[16px] px-[20px]">
        <Buttons
          text="Simpan"
          loading={isSubmitting}
          customClass={`btn-primary btn-medium !w-[186px] float-right ${
            isSubmitting && "loading"
          }`}
          onClick={handleSubmit}
        />
      </div>
    </>
  );
};

PembimbingGuide.propTypes = {
  info: PropType.object,
  closePopup: PropType.func,
  setSuccessEdit: PropType.func,
};

export default React.memo(PembimbingGuide);
