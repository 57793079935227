import React, { useState } from "react";
import IconVideo from "assets/icons/video-play.svg";
import Selects from "components/form/selects";
import Buttons from "components/buttons";
import FotoUmroh from "assets/images/umrohImg.png";
import { Grid } from "@material-ui/core";
import Checkbox from "components/checkbox";
import VideoPlayer from "components/video";
import Popup from "components/popup";
import UploadVideo from "./UploadVideo";

const AlbumVideo = () => {
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [showImages, setShowImages] = useState(true);
  const [openPopupAddVideo, setOpenPopupAddVideo] = useState(false);

  const [options, setOptions] = useState([
    {
      id: 1,
      value: "semua-video",
      label: "Semua Video",
    },
    {
      id: 2,
      value: "video-tidak-ditampilkan",
      label: "Video Tidak Ditampilkan",
    },
    {
      id: 3,
      value: "video-ditampilkan",
      label: "Video Ditampilkan",
    },
  ]);

  return (
    <>
      <Popup
        open={openPopupAddVideo}
        handleClose={() => {
          setOpenPopupAddVideo(false);
        }}
        title="Upload Video"
        containerClass=""
        children={<UploadVideo />}
      />

      <div className="p-[16px] flex flex-col gap-[16px] bg-white soft-shadow rounded-3xl">
        <div className="flex flex-row justify-between items-center p-[16px] gap-[8px] rounded-xl border">
          <div className="flex flex-row items-center gap-[28px]">
            <div className="flex flex-row items-center gap-[8px]">
              <img src={IconVideo} alt="galery foto" />
              <p className="text-sm text-black font-semibold">Video</p>
            </div>
            <div>
              <Selects
                placeholder="Semua Video"
                customClass="!w-[200px] z-30"
                options={options}
                searchable
                itemRenderer={({ state, item, methods }) => {
                  return (
                    <div
                      className={`react-dropdown-select-item ${
                        state.values.length !== 0 &&
                        state.values[0].value === item.value &&
                        "react-dropdown-select-item-selected"
                      }`}
                      onClick={() => methods.addItem(item)}
                    >
                      {item.label}
                    </div>
                  );
                }}
              />
            </div>
          </div>
          <div>
            <Buttons
              text="Upload Video"
              customClass="btn-outline btn-medium"
              onClick={() => setOpenPopupAddVideo(true)}
            />
          </div>
        </div>
        <div className="flex justify-center items-center">
          <Grid container spacing={2}>
            <Grid item md={4}>
              <div className="w-[256.5px] h-[158px] m-auto relative">
                <div className="flex flex-row justify-center items-center w-[105px] h-[32px] bg-white z-10 absolute top-0 right-0 bottom-0 rounded-bl-xl gap-[9px]">
                  <p className="text-sm text-black font-semibold">
                    Ditampilkan
                  </p>
                  <div>
                    <Checkbox
                      indeterminate={indeterminate}
                      checked={checked}
                      onChange={(e) => {
                        setChecked(e.target.checked);
                        setIndeterminate(false);
                      }}
                    />
                  </div>
                </div>
                <div className="">
                  <VideoPlayer
                    videoUrl="https://www.youtube.com/watch?v=1kUE0BZtTRc"
                    width="247px"
                    height="158px"
                  />
                </div>
              </div>
            </Grid>
            <Grid item md={4}>
              <div className="w-[256.5px] h-[158px] m-auto relative">
                <div className="flex flex-row justify-center items-center w-[105px] h-[32px] bg-white z-10 absolute top-0 right-0 bottom-0 rounded-bl-xl gap-[9px]">
                  <p className="text-sm text-black font-semibold">
                    Ditampilkan
                  </p>
                  <div>
                    <Checkbox
                      indeterminate={indeterminate}
                      checked={checked}
                      onChange={(e) => {
                        setChecked(e.target.checked);
                        setIndeterminate(false);
                      }}
                    />
                  </div>
                </div>
                <div className="">
                  <VideoPlayer
                    videoUrl="https://www.youtube.com/watch?v=1kUE0BZtTRc"
                    width="247px"
                    height="158px"
                  />
                </div>
              </div>
            </Grid>
            <Grid item md={4}>
              <div className="w-[256.5px] h-[158px] m-auto relative">
                <div className="flex flex-row justify-center items-center w-[105px] h-[32px] bg-white z-10 absolute top-0 right-0 bottom-0 rounded-bl-xl gap-[9px]">
                  <p className="text-sm text-black font-semibold">
                    Ditampilkan
                  </p>
                  <div>
                    <Checkbox
                      indeterminate={indeterminate}
                      checked={checked}
                      onChange={(e) => {
                        setChecked(e.target.checked);
                        setIndeterminate(false);
                      }}
                    />
                  </div>
                </div>
                <div className="">
                  <VideoPlayer
                    videoUrl="https://www.youtube.com/watch?v=1kUE0BZtTRc"
                    width="247px"
                    height="158px"
                  />
                </div>
              </div>
            </Grid>
            <Grid item md={4}>
              <div className="w-[256.5px] h-[158px] m-auto relative">
                <div className="flex flex-row justify-center items-center w-[105px] h-[32px] bg-white z-10 absolute top-0 right-0 bottom-0 rounded-bl-xl gap-[9px]">
                  <p className="text-sm text-black font-semibold">
                    Ditampilkan
                  </p>
                  <div>
                    <Checkbox
                      indeterminate={indeterminate}
                      checked={checked}
                      onChange={(e) => {
                        setChecked(e.target.checked);
                        setIndeterminate(false);
                      }}
                    />
                  </div>
                </div>
                <div className="">
                  <VideoPlayer
                    videoUrl="https://www.youtube.com/watch?v=1kUE0BZtTRc"
                    width="247px"
                    height="158px"
                  />
                </div>
              </div>
            </Grid>
            <Grid item md={4}>
              <div className="w-[256.5px] h-[158px] m-auto relative">
                <div className="flex flex-row justify-center items-center w-[105px] h-[32px] bg-white z-10 absolute top-0 right-0 bottom-0 rounded-bl-xl gap-[9px]">
                  <p className="text-sm text-black font-semibold">
                    Ditampilkan
                  </p>
                  <div>
                    <Checkbox
                      indeterminate={indeterminate}
                      checked={checked}
                      onChange={(e) => {
                        setChecked(e.target.checked);
                        setIndeterminate(false);
                      }}
                    />
                  </div>
                </div>
                <div className="">
                  <VideoPlayer
                    videoUrl="https://www.youtube.com/watch?v=1kUE0BZtTRc"
                    width="247px"
                    height="158px"
                  />
                </div>
              </div>
            </Grid>
            <Grid item md={4}>
              <div className="w-[256.5px] h-[158px] m-auto relative">
                <div className="flex flex-row justify-center items-center w-[105px] h-[32px] bg-white z-10 absolute top-0 right-0 bottom-0 rounded-bl-xl gap-[9px]">
                  <p className="text-sm text-black font-semibold">
                    Ditampilkan
                  </p>
                  <div>
                    <Checkbox
                      indeterminate={indeterminate}
                      checked={checked}
                      onChange={(e) => {
                        setChecked(e.target.checked);
                        setIndeterminate(false);
                      }}
                    />
                  </div>
                </div>
                <div className="">
                  <VideoPlayer
                    videoUrl="https://www.youtube.com/watch?v=1kUE0BZtTRc"
                    width="247px"
                    height="158px"
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        {showImages && (
          <div className="flex justify-center items-center">
            <Grid container spacing={2}>
              <Grid item md={4}>
                <div className="w-[256.5px] h-[158px] m-auto relative">
                  <div className="flex flex-row justify-center items-center w-[105px] h-[32px] bg-white z-10 absolute top-0 right-0 bottom-0 rounded-bl-xl gap-[9px]">
                    <p className="text-sm text-black font-semibold">
                      Ditampilkan
                    </p>
                    <div>
                      <Checkbox
                        indeterminate={indeterminate}
                        checked={checked}
                        onChange={(e) => {
                          setChecked(e.target.checked);
                          setIndeterminate(false);
                        }}
                      />
                    </div>
                  </div>
                  <div className="">
                    <VideoPlayer
                      videoUrl="https://www.youtube.com/watch?v=1kUE0BZtTRc"
                      width="247px"
                      height="158px"
                    />
                  </div>
                </div>
              </Grid>
              <Grid item md={4}>
                <div className="w-[256.5px] h-[158px] m-auto relative">
                  <div className="flex flex-row justify-center items-center w-[105px] h-[32px] bg-white z-10 absolute top-0 right-0 bottom-0 rounded-bl-xl gap-[9px]">
                    <p className="text-sm text-black font-semibold">
                      Ditampilkan
                    </p>
                    <div>
                      <Checkbox
                        indeterminate={indeterminate}
                        checked={checked}
                        onChange={(e) => {
                          setChecked(e.target.checked);
                          setIndeterminate(false);
                        }}
                      />
                    </div>
                  </div>
                  <div className="">
                    <VideoPlayer
                      videoUrl="https://www.youtube.com/watch?v=1kUE0BZtTRc"
                      width="247px"
                      height="158px"
                    />
                  </div>
                </div>
              </Grid>
              <Grid item md={4}>
                <div className="w-[256.5px] h-[158px] m-auto relative">
                  <div className="flex flex-row justify-center items-center w-[105px] h-[32px] bg-white z-10 absolute top-0 right-0 bottom-0 rounded-bl-xl gap-[9px]">
                    <p className="text-sm text-black font-semibold">
                      Ditampilkan
                    </p>
                    <div>
                      <Checkbox
                        indeterminate={indeterminate}
                        checked={checked}
                        onChange={(e) => {
                          setChecked(e.target.checked);
                          setIndeterminate(false);
                        }}
                      />
                    </div>
                  </div>
                  <div className="">
                    <VideoPlayer
                      videoUrl="https://www.youtube.com/watch?v=1kUE0BZtTRc"
                      width="247px"
                      height="158px"
                    />
                  </div>
                </div>
              </Grid>
              <Grid item md={4}>
                <div className="w-[256.5px] h-[158px] m-auto relative">
                  <div className="flex flex-row justify-center items-center w-[105px] h-[32px] bg-white z-10 absolute top-0 right-0 bottom-0 rounded-bl-xl gap-[9px]">
                    <p className="text-sm text-black font-semibold">
                      Ditampilkan
                    </p>
                    <div>
                      <Checkbox
                        indeterminate={indeterminate}
                        checked={checked}
                        onChange={(e) => {
                          setChecked(e.target.checked);
                          setIndeterminate(false);
                        }}
                      />
                    </div>
                  </div>
                  <div className="">
                    <VideoPlayer
                      videoUrl="https://www.youtube.com/watch?v=1kUE0BZtTRc"
                      width="247px"
                      height="158px"
                    />
                  </div>
                </div>
              </Grid>
              <Grid item md={4}>
                <div className="w-[256.5px] h-[158px] m-auto relative">
                  <div className="flex flex-row justify-center items-center w-[105px] h-[32px] bg-white z-10 absolute top-0 right-0 bottom-0 rounded-bl-xl gap-[9px]">
                    <p className="text-sm text-black font-semibold">
                      Ditampilkan
                    </p>
                    <div>
                      <Checkbox
                        indeterminate={indeterminate}
                        checked={checked}
                        onChange={(e) => {
                          setChecked(e.target.checked);
                          setIndeterminate(false);
                        }}
                      />
                    </div>
                  </div>
                  <div className="">
                    <VideoPlayer
                      videoUrl="https://www.youtube.com/watch?v=1kUE0BZtTRc"
                      width="247px"
                      height="158px"
                    />
                  </div>
                </div>
              </Grid>
              <Grid item md={4}>
                <div className="w-[256.5px] h-[158px] m-auto relative">
                  <div className="flex flex-row justify-center items-center w-[105px] h-[32px] bg-white z-10 absolute top-0 right-0 bottom-0 rounded-bl-xl gap-[9px]">
                    <p className="text-sm text-black font-semibold">
                      Ditampilkan
                    </p>
                    <div>
                      <Checkbox
                        indeterminate={indeterminate}
                        checked={checked}
                        onChange={(e) => {
                          setChecked(e.target.checked);
                          setIndeterminate(false);
                        }}
                      />
                    </div>
                  </div>
                  <div className="">
                    <VideoPlayer
                      videoUrl="https://www.youtube.com/watch?v=1kUE0BZtTRc"
                      width="247px"
                      height="158px"
                    />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        )}
        <div>
          <Buttons
            text={
              showImages
                ? "Sembunyikan Video Sebelumnya"
                : "Lihat Video Selengkapnya"
            }
            onClick={() => setShowImages(!showImages)}
            customClass="btn-secondary btn-medium"
          />
        </div>
      </div>
    </>
  );
};

export default AlbumVideo;
