import EditorToolbar, {
  formats,
  modules,
} from "components/richTextEditor/EditorToolbar";
import parse from "html-react-parser";
import React, { useCallback, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import { detailById } from "stores/actions/general";
import privacyAction from "stores/actions/privacy";
import { toBase64 } from "utils/helpers";
import Buttons from "components/buttons";
import Cards from "components/cards";
import styled from "styled-components";

const SettingPrivacyPolicy = () => {
  const dispatch = useDispatch();

  const { byDetail } = useSelector((state) => state.general);
  const { allPrivacy, isLoading } = useSelector((state) => state.privacy);
  const [input, setInput] = useState();

  const policyPrivacy = useCallback(() => {
    dispatch(privacyAction.data());
  }, [dispatch]);

  useEffect(() => {
    byDetail?.data
      ? setInput(toBase64("decode", byDetail?.data))
      : policyPrivacy();
  }, [byDetail?.data, policyPrivacy]);

  const onHandleSave = () => {
    dispatch(
      privacyAction.createOrUpdate({
        kebijakan_privasi: toBase64("encode", input),
      })
    ).then(() => {
      policyPrivacy();
      dispatch(detailById({}));
    });
  };

  const onHandleButton = () => {
    byDetail?.update
      ? onHandleSave()
      : dispatch(
          detailById({
            update: true,
            data: allPrivacy?.data?.kebijakan_privasi,
          })
        );
  };

  const SelectWrapper = styled.div`
    p strong {
      font-weight: 600;
      font-size: 20px;
      color: #000000;
    }
  `;

  return (
    <Cards containerClass="!p-0">
      <div className="flex flex-row justify-between items-center p-4">
        <div className="text-[20px] font-semibold">Kebijakan Privasi</div>
        <div className="flex gap-x-4 items-center">
          {byDetail?.update && (
            <Buttons
              text="Batalkan"
              customClass="btn-outline btn-small"
              onClick={() => dispatch(detailById({}))}
            />
          )}
          <Buttons
            text={byDetail?.update ? "Simpan" : "Edit Data"}
            customClass={`btn-primary btn-small ${isLoading && "loading"}`}
            onClick={onHandleButton}
            loading={isLoading}
          />
        </div>
      </div>
      <div className="divider"></div>
      {isLoading ? (
        <div className="text-center">Loading...</div>
      ) : byDetail?.update ? (
        <div className="text-editor mt-4 p-4">
          <EditorToolbar />
          <ReactQuill
            id="content"
            name="content"
            theme="snow"
            value={input}
            onChange={(e) => setInput(e)}
            modules={modules}
            formats={formats}
            className="bg-[#EDF2F4] w-full h-[68vh]"
          />
        </div>
      ) : (
        <div className="p-4">
          <SelectWrapper>
            {allPrivacy?.data &&
              parse(window?.atob(allPrivacy?.data?.kebijakan_privasi))}
          </SelectWrapper>
        </div>
      )}
    </Cards>
  );
};

export default React.memo(SettingPrivacyPolicy);
