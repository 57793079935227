import React, { useCallback, useState } from "react";
import { Grid } from "@material-ui/core";

import PaketStyle from "./Paket.Style";
import SidebarChild from "../../components/template_layout/Siderbar/SidebarChild";
import { menuStepSidebarList } from "./../../configuration/stepMenuList";
import Umroh from "./components/Umroh";
import Haji from "./components/Haji";
import Tour from "./components/Tour";
import JasaBadal from "./components/JasaBadal";
import Tabungan from "./components/Tabungan";
import RequestPaket from "./components/RequestPaket";

const PaketPage = () => {
  const [showContent, setShowContent] = useState("umroh");

  const getContent = useCallback(() => {
    switch (showContent) {
      case "umroh":
        return <Umroh />;
      case "haji":
        return <Haji />;
      case "tour":
        return <Tour />;
      case "jasabadal":
        return <JasaBadal />;
      case "tabungan":
        return <Tabungan />;
        case "request_paket":
          return <RequestPaket />;
      default:
        return <Umroh />;
    }
  }, [showContent]);

  return (
    <PaketStyle>
      <SidebarChild
        menuList={menuStepSidebarList}
        handleClick={setShowContent}
        showContent={showContent}
      />
      <div style={{ marginLeft: "174px", paddingLeft: "16px" }}>
        {getContent()}
      </div>
    </PaketStyle>
  );
};

export default PaketPage;
