import React from "react";
import { Grid } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { NumericFormat } from "react-number-format";

import Buttons from "../../../../components/buttons";
import Badge from "../../../../components/badge";

import IconPrint from "../../../../assets/icons/Print.svg";
import Photo from "../../../../assets/images/Photo.png";
import IconEdit from "../../../../assets/icons/edit.svg";
import IconSMS from "../../../../assets/icons/sms.svg";
import IconWhatsapp from "../../../../assets/icons/whatsapp.svg";

const CardDetailTagihanHeader = () => {
  const { detailData } = useSelector((state) => state.tagihan);
  const data = detailData.data;
  return (
    <div className="card-detail-tagihan-header">
      <Grid container spacing={2} alignItems="center">
        <Grid item md={10} className="flex flex-row items-center">
          <div className="card-detail-tagihan-header-code">#UMR-987</div>
          <div className="card-detail-tagihan-header-divider"></div>
          <div className="card-detail-tagihan-header-date">
            Senin, 12 Oktober 2022 <span>14.07 WIB</span>
          </div>
          <Buttons
            text="Print"
            customClass="btn-outline btn-small card-detail-tagihan-header-print"
            icon={IconPrint}
          />
        </Grid>
        <Grid item md={2} className="flex justify-end">
          <Badge
            label="Dibayar Sebagian"
            type="orange dark"
            customClass="uppercase"
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} className="!mt-3">
        <Grid item md={4}>
          <div className="card-detail-tagihan-header-yellow flex flex-row">
            <img
              className="card-detail-tagihan-header-yellow-avatar"
              src={Photo}
              alt="user-img"
            />
            <div className="ml-3">
              <div className="card-detail-tagihan-header-yellow-name">
                {data?.user_jamaah?.name}
              </div>
              <div className="flex flex-row gap-2 mt-2">
                <Badge label="Agen Cabang Daerah" type="green" />
                <Badge label="Pekanbaru" type="dark purple" />
              </div>
            </div>
          </div>
        </Grid>
        <Grid item md={4}>
          <div className="card-detail-tagihan-header-yellow">
            <div className="card-detail-tagihan-header-yellow-label">
              Paket:
            </div>
            <div className="card-detail-tagihan-header-yellow-name mt-2">
              Umroh Bersama Ustadz Abduh Tuasikal
            </div>
          </div>
        </Grid>
        <Grid item md={4}>
          <div>
            <div className="font-semibold text-[16px]">Catatan:</div>
            <div className="mt-2">
              Pembayaran dilakukan melalui transfer. Transfer bisa menggunakan
              platfom apapun. Transfer dengan nominal pas, jika kelebihan
              silahkan diikhlaskan.
            </div>
          </div>
        </Grid>
      </Grid>

      <Grid container spacing={2} className="!mt-3">
        <Grid item md={8}>
          <div className="card-detail-tagihan-header-yellow">
            <div className="flex flex-row justify-between">
              <div className="card-detail-tagihan-header-yellow-label">
                Rincian Tagihan:
              </div>
              <div className="card-detail-tagihan-header-yellow-edit">
                <img
                  className="w-[20px] h-[20px]"
                  src={IconEdit}
                  alt="user-img"
                />
              </div>
            </div>

            <div className="flex flex-row items-center gap-14 mt-4">
              <div className="card-detail-tagihan-header-yellow-wrapper">
                <div className="card-detail-tagihan-header-yellow-label">
                  Total Tagihan:
                </div>
                <div className="card-detail-tagihan-header-yellow-name mt-2">
                  <NumericFormat
                    value={data?.total_price}
                    displayType="text"
                    thousandSeparator={true}
                    prefix="Rp"
                  />
                </div>
              </div>

              <div>
                <div className="card-detail-tagihan-header-yellow-label">
                  Sudah Dibayar:
                </div>
                <div className="card-detail-tagihan-header-yellow-name mt-2">
                  <NumericFormat
                    value={data?.down_payment}
                    displayType="text"
                    thousandSeparator={true}
                    prefix="Rp"
                  />
                </div>
              </div>

              <div>
                <div className="card-detail-tagihan-header-yellow-label">
                  Diskon:
                </div>
                <div className="card-detail-tagihan-header-yellow-name mt-2">
                  Rp 5.000.000
                </div>
              </div>

              <div>
                <div className="card-detail-tagihan-header-yellow-label">
                  Kekurangan:
                </div>
                <div className="card-detail-tagihan-header-yellow-name mt-2">
                  <NumericFormat
                    value={data?.remaining_payment_amount}
                    displayType="text"
                    thousandSeparator={true}
                    prefix="Rp"
                  />
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item md={4}>
          <div className="card-detail-tagihan-header-yellow">
            <div className="flex flec-row justify-between">
              <div className="card-detail-tagihan-header-yellow-label">
                Ditagihkan Kepada:
              </div>
              <div className="card-detail-tagihan-header-yellow-edit">
                <img
                  className="w-[20px] h-[20px]"
                  src={IconEdit}
                  alt="user-img"
                />
              </div>
            </div>
            <div className="card-detail-tagihan-header-yellow-name mt-2">
              Agus Nugroho
            </div>

            <div className="flex flex-row gap-14 items-center mt-4">
              <div>
                <div className="flex flex-row">
                  <img src={IconSMS} alt="sms" />
                  <div className="ml-1 text-white">rifusid17@gmail.com</div>
                </div>
                <div className="flex flex-row mt-2">
                  <Buttons
                    text="1"
                    customClass="btn-white-light btn-small !w-fit"
                    icon={IconSMS}
                  />
                  <Buttons
                    text="2"
                    customClass="btn-white-light btn-small !w-fit ml-1"
                    icon={IconSMS}
                  />
                </div>
              </div>
              <div>
                <div className="flex flex-row">
                  <img src={IconWhatsapp} alt="whatsapp" />
                  <div className="ml-1 text-white">0812-2528-5261</div>
                </div>
                <div className="flex flex-row mt-2">
                  <Buttons
                    text="1"
                    customClass="btn-white-light btn-small !w-fit"
                    icon={IconWhatsapp}
                  />
                  <Buttons
                    text="2"
                    customClass="btn-white-light btn-small !w-fit ml-1"
                    icon={IconWhatsapp}
                  />
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default CardDetailTagihanHeader;
