import React from "react";
import { makeStyles } from "@material-ui/core";
import { Col, Row } from "antd";
import { motion, AnimatePresence } from "framer-motion";
import moment from "moment";
import { useHistory } from "react-router-dom";

import UmrohImg from "../../../../../../assets/images/umrohImg.png";
import Badge from "../../../../../../components/badge/index";
import Calendar from "../../../../../../assets/icons/colored/calendar-icon.svg";
import ClockIcon from "../../../../../../assets/icons/colored/clock-icon.svg";
import PlaneIcon from "../../../../../../assets/icons/colored/airplane-icon.svg";
import GarudaIndonesia from "../../../../../../assets/images/garuda-indonesia-logo.png";
import LandingPlaneIcon from "../../../../../../assets/icons/colored/airplane-2-icon.svg";
import PaketVipIcon from "../../../../../../assets/icons/colored/paket-vip.svg";
import Quad from "../../../../../../assets/icons/colored/Quad.svg";
import TripleQuad from "../../../../../../assets/icons/colored/TripleBed.svg";
import DoubleQuad from "../../../../../../assets/icons/colored/DoubleBed.svg";
import SingleBed from "../../../../../../assets/icons/colored/SingleBed.svg";
import HotelIcon from "../../../../../../assets/icons/colored/Hotel-Akomodasi.svg";
import Address from "../../../../../../assets/icons/colored/Address.svg";
import TipeKamar from "../../../../../../assets/icons/colored/Tipe-Kamar.svg";
import Rating from "../../../../../../assets/icons/colored/Rating.svg";
import Buttons from "./../../../../../../components/buttons/index";
import SeatBlack from "../../../../../../assets/icons/colored/seat-black.svg";
import SeatBlue from "../../../../../../assets/icons/colored/seat-blue.svg";
import SeatGreen from "../../../../../../assets/icons/colored/seat-green.svg";

const useStyle = makeStyles({
  container: {
    width: "456px",
    height: "fit-content",
    background: "#FFFFFF",
    boxShadow:
      "0px 6px 64px -4px rgba(0, 0, 0, 0.04), 0px 10px 16px rgba(0, 0, 0, 0.04), 0px 6px 32px rgba(0, 0, 0, 0.04)",
    borderRadius: "24px",
    zIndex: "9999999999",
    position: "absolute",
    left: "57%",
    top: "50%",
    transform: "translate(-50%,-50%)",
    "& .image-container": {
      width: "448px",
      height: "145px",
      left: "4px",
      right: "4px",
      top: "4px",
      margin: "0 auto",
      paddingTop: "4px",
      alignItems: "center",

      "& .hover-image": {
        width: "448px",
        height: "145px",
        borderRadius: "20px 20px 0px 0px",
        objectFit: "fit",
      },
    },

    "& .content-container": {
      margin: "16px",

      "& .title": {
        marginTop: "4px",
        width: "387px",
        height: "48px",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "18px",
        lineHeight: "24px",
        background:
          "linear-gradient(34.99deg, #151515 17.46%, #3D3D3D 58.62%, #595959 87.32%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        backgroundClip: "text",
        textFillColor: "transparent",
        // marginBottom: '16px',
      },

      "& .info-paket": {
        display: "flex",
        flexDirection: "row",
        marginBottom: "12px",

        "& .title-paket": {
          width: "126px",
          height: "18px",
          fontStyle: "normal",
          fontWeight: "400",
          fontSize: "12px",
          lineHeight: "18px",
          marginLeft: "8px",
        },
      },

      "& .harga-dasar-container": {
        width: "424px",
        height: "111px",
        background: "#FBF3F2",
        borderRadius: "8px",

        "& .quad-container": {
          width: "157px",
          height: "44px",
          paddingTop: "12px",
          textAlign: "center",
          margin: "0 auto",
          marginBottom: "8px",
        },

        "& .title-quad": {
          width: "387px",
          height: "48px",
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "16px",
          lineHeight: "20px",
          background:
            "linear-gradient(34.99deg, #151515 17.46%, #3D3D3D 58.62%, #595959 87.32%)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          backgroundClip: "text",
          textFillColor: "transparent",
          marginLeft: "8px",
        },

        "& .title-icon": {
          width: "65px",
          height: "20px",
          display: "flex",
          flexDirection: "row",
          textAlign: "center",
          margin: "0 auto",
        },

        "& .price-quad": {
          width: "157px",
          height: "24px",
          display: "flex",
          flexDirection: "row",
        },

        "& .price": {
          fontStyle: "normal",
          fontWeight: "600",
          fontSize: "18px",
          lineHeight: "24px",
          background:
            "linear-gradient(204.44deg, #01F91A -18.49%, #359B05 84.72%)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          backgroundClip: "text",
          textFillColor: "transparent",
        },
        "& .pax": {
          color: "black !important",
        },

        "& .quad-bottom": {
          display: "flex",
          flexDirection: "row",
          marginLeft: "12px",

          "& .title-icon-bottom": {
            display: "flex",
            flexDirection: "row",

            "& .title-quad-triple": {
              marginLeft: "8px",
            },
          },
        },
      },
    },
  },

  penginapanContainer: {
    width: "424px",
    height: "296px",
    margin: "8px 16px 16px 0px",

    "& .paket-content": {
      width: "424px",
      height: "68px",
      background: "#FBF3F2",
      borderRadius: "8px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      marginTop: "8px",

      "& .paket-title": {
        display: "flex",
      },
    },

    "& .top": {
      margin: "12px",
    },

    "& .bottom": {
      margin: "12px",
    },

    "& .paket-seat": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignContent: "space-around",
      alignItems: "center",
      // marginLeft: '12px',
      gap: "100px",
      margin: "0 auto",

      "& .seat-black": {
        background:
          "linear-gradient(34.99deg, #151515 17.46%, #3D3D3D 58.62%, #595959 87.32%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        backgroundClip: "text",
        textFillColor: "transparent",
        fontWeight: "500",
      },

      "& .blue-seat": {
        background:
          "linear-gradient(204.44deg, #4590F4 -18.49%, #1361C9 84.72%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        backgroundClip: "text",
        textFillColor: "transparent",
        fontWeight: "500",
      },

      "& .green-seat": {
        background:
          "linear-gradient(204.44deg, #01F91A -18.49%, #359B05 84.72%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        backgroundClip: "text",
        textFillColor: "transparent",
        fontWeight: "500",
      },
    },
  },
});

const PaketDetailHover = ({ data }) => {
  const router = useHistory();
  const classes = useStyle();

  const dateFormatter = (val) => {
    if (val) {
      return moment(val).format("DD MMMM YYYY") || "-";
    }
  };

  const moneyFormatter = (val) => {
    return new Intl.NumberFormat().format(val);
  };

  return (
    <AnimatePresence>
      <motion.div
        className={classes.container}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="image-container">
          <img
            src={data?.image_thumbnail}
            alt="detail"
            className="hover-image object-cover"
          />
        </div>
        <div className="content-container">
          <Badge
            style={{
              zIndex: 2334,
            }}
            label={data?.status === 0 ? "Draft" : "Publish"}
            color="#333D29"
            backgroundColor="rgba(46, 178, 67, 0.2)"
          />
          <h1 className="title">{data?.judul_paket}</h1>
          <Row gutter={16}>
            <Col className="gutter-row" span={8}>
              <div className="info-paket">
                <img src={Calendar} alt="calendar" />
                <p className="title-paket">
                  {dateFormatter(data?.jadwal_keberangkatan)}
                </p>
              </div>
            </Col>
            <Col className="gutter-row" span={8}>
              <div className="info-paket">
                <img src={PlaneIcon} alt="calendar" />
                <p className="title-paket">{data?.maskapai?.name}</p>
              </div>
            </Col>
            <Col className="gutter-row" span={8}>
              <div className="info-paket">
                <img src={PaketVipIcon} alt="calendar" />
                <p className="title-paket">{data?.kategori}</p>
              </div>
            </Col>
            <Col className="gutter-row" span={8}>
              <div className="info-paket">
                <img src={ClockIcon} alt="calendar" />
                <p className="title-paket">{data?.jumlah_hari} Hari</p>
              </div>
            </Col>
            <Col className="gutter-row" span={8}>
              <div className="info-paket">
                <img src={LandingPlaneIcon} alt="calendar" />
                <p className="title-paket">{data?.mendarat_di}</p>
              </div>
            </Col>
          </Row>
          {data.harga_kamar?.price_double_basic !== 0 && (
            <div className="harga-dasar-container">
              {data.harga_kamar.is_active_price_tripple && (
                <div className="quad-container">
                  <div className="title-icon">
                    <img src={Quad} alt="kasur" />
                    <h1 className="title-quad">Quad</h1>
                  </div>
                  <div className="price-quad justify-center">
                    <p className="price">
                      RP {moneyFormatter(data.harga_kamar.price_quad_basic)}
                    </p>
                    <p className="pax">/pax</p>
                  </div>
                </div>
              )}
              <div className="quad-bottom mt-4">
                {data.harga_kamar.is_active_price_tripple && (
                  <div className="quad-bottom-container">
                    <div className="title-icon-bottom">
                      <img src={TripleQuad} alt="kasur" />
                      <h1 className="title-quad-triple">Triple</h1>
                    </div>
                    <div className="price-quad">
                      <p className="price !text-[12px]">
                        RP{" "}
                        {moneyFormatter(data.harga_kamar.price_tripple_basic)}
                      </p>
                      <p className="pax !text-[10px]">/pax</p>
                    </div>
                  </div>
                )}
                {data.harga_kamar.is_active_price_double && (
                  <div className="quad-bottom-container">
                    <div className="title-icon-bottom">
                      <img src={DoubleQuad} alt="kasur" />
                      <h1 className="title-quad-triple">Double</h1>
                    </div>
                    <div className="price-quad">
                      <p className="price !text-[12px]">
                        RP {moneyFormatter(data.harga_kamar.price_double_basic)}
                      </p>
                      <p className="pax !text-[10px]">/pax</p>
                    </div>
                  </div>
                )}
                {data.harga_kamar.is_active_price_single && (
                  <div className="quad-bottom-container">
                    <div className="title-icon-bottom">
                      <img src={SingleBed} alt="kasur" />
                      <h1 className="title-quad-triple">Single</h1>
                    </div>
                    <div className="price-quad">
                      <p className="price !text-[12px]">
                        RP {moneyFormatter(data.harga_kamar.price_single_basic)}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          {data.kuota !== 0 && (
            <div className={classes.penginapanContainer}>
              <div className="paket-content">
                <div className="top">
                  <div className="paket-title">
                    <img src={HotelIcon} alt="hotel akomodasi" />
                    <p className="pl-[8px] text-xs">Hotel Jedah Hilton</p>
                  </div>
                  <div className="paket-title mt-2">
                    <img src={TipeKamar} alt="hotel akomodasi" />
                    <img src={Rating} alt="Address" className="pl-[8px]" />
                  </div>
                </div>
                <div className="bottom">
                  <div className="paket-title">
                    <img src={Address} alt="Address" />
                    <p className="pl-[8px] text-xs">Kota Jedah</p>
                  </div>
                  <div className="paket-title mt-2">
                    <img src={TipeKamar} alt="hotel akomodasi" />
                    <p className="pl-[8px] text-[8px] !text-center align-middle">
                      +-800m dari halaman Masjid Nabawi
                    </p>
                  </div>
                </div>
              </div>
              <div className="paket-content">
                <div className="top">
                  <div className="paket-title">
                    <img src={HotelIcon} alt="hotel akomodasi" />
                    <p className="pl-[8px] text-xs">Hotel Jedah Hilton</p>
                  </div>
                  <div className="paket-title  mt-2">
                    <img src={TipeKamar} alt="hotel akomodasi" />
                    <img src={Rating} alt="Address" className="pl-[8px]" />
                  </div>
                </div>
                <div className="bottom">
                  <div className="paket-title">
                    <img src={Address} alt="Address" />
                    <p className="pl-[8px] text-xs">Kota Jedah</p>
                  </div>
                  <div className="paket-title mt-2">
                    <img src={TipeKamar} alt="hotel akomodasi" />
                    <p className="pl-[8px] text-[8px] !text-center align-middle">
                      +-800m dari halaman Masjid Nabawi
                    </p>
                  </div>
                </div>
              </div>
              <div className="paket-content !h-[50px]">
                <div className="paket-seat">
                  <div className="paket-title">
                    <img
                      src={SeatBlack}
                      alt="hotel akomodasi"
                      className="!w-[13.05px] !h-[12.36px] align-center align-middle m-0"
                    />
                    <p className="pl-[8px] text-[12px] !text-center align-middle seat-black">
                      {data?.kuota} Seat
                    </p>
                  </div>
                  <div className="paket-title">
                    <img
                      src={SeatBlue}
                      alt="hotel akomodasi"
                      className="!w-[13.05px] !h-[12.36px] !align-middle"
                    />
                    <p className="pl-[8px] text-[12px] !text-center align-middle blue-seat">
                      {data?.jumlah_jamaah} Seat
                    </p>
                  </div>
                  <div className="paket-title">
                    <img
                      src={SeatGreen}
                      alt="hotel akomodasi"
                      className="!w-[13.05px] !h-[12.36px] align-middle"
                    />
                    <p className="pl-[8px] text-[12px] !text-center align-middle green-seat">
                      {data?.kuota_tersedia} Seat
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          <Buttons
            text="Detail Paket"
            customClass="btn-outline btn-large mt-[12px]"
            onClick={() => router.push(`/paket/detail/${data?.id_paket}`)}
          />
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

export default PaketDetailHover;
