import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import { menuStepSidebarList } from "../../configuration/stepMenuList";

import SidebarChild from "../../components/template_layout/Siderbar/SidebarChild";
import CardUmrohTable from "./components/CardUmrohTable";
import TagihanStyle from "./Tagihan.style";

const TagihanPage = () => {
  const [showContent, setShowContent] = useState("umroh");

  return (
    <TagihanStyle className="flex">
      <SidebarChild
        menuList={menuStepSidebarList}
        handleClick={setShowContent}
        showContent={showContent}
      />

      <div
        className="w-full"
        style={{ marginLeft: "174px", paddingLeft: "16px" }}
      >
        <CardUmrohTable />
      </div>
    </TagihanStyle>
  );
};

export default TagihanPage;
