import React, { useEffect, useState } from "react";
import Badge from "components/badge";
import Buttons from "components/buttons";
import Item from "./Item";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import AlertPopup from "components/popup/alert";
import {
  deleteManasikCabangPaket,
  getListManasikPaket,
  getManasikCabangPaket,
} from "stores/actions/paket/generalInfo";
import { handleSuccess } from "stores/actions/errorGeneral";

const Manasik = ({ data }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [dataCabangManasik, setDataCabangManasik] = useState([]);
  const [choiceManasik, setChoiceManasik] = useState(0);
  const [isDeleteManasik, setIsDeleteManasik] = useState(false);
  const [isLoadingDelManasik, setIsLoadingDeleteManasik] = useState(false);
  const [choiceIndex, setChoiceIndex] = useState(null);

  const [listManasik, setListManasik] = useState([]);
  let { id } = useParams();
  const dispatch = useDispatch();

  const addItem = () => {
    let _temp = [...dataCabangManasik];
    _temp.push({
      koordinat: {
        lat: 0,
        lng: 0,
      },
      tanggal_pelaksanaan: "",
      waktu_pelaksanaan: "",
      pesan_undangan: "",
      lokasi: "",
      cabang_id: _temp[0].cabang_id,
    });

    setDataCabangManasik(_temp);
  };

  useEffect(() => {
    handleClickManasikCabang(1);
  }, []);

  useEffect(() => {
    dispatch(
      getListManasikPaket(id, (message) => {
        setListManasik(message.data);
      })
    );
  }, [id]);

  const handleClickManasikCabang = (id_cabang) => {
    const payload = {
      id: id,
      cabang_id: id_cabang,
    };

    dispatch(
      getManasikCabangPaket(payload, (message) => {
        if (message.data.length > 0) {
          let resultMap = message.data?.map((item) => {
            return {
              ...item,
              koordinat: {
                lat: parseFloat(item.lotitude),
                lng: parseFloat(item.langitude),
              },
              lokasi: item.lokasi,
              tanggal_pelaksanaan: item.date,
              waktu_pelaksanaan: `${item.start_at}-${item.end_at}`,
              pesan_undangan: item.message,
            };
          });
          setDataCabangManasik(resultMap);
        } else {
          let objectMap = {
            koordinat: {
              lat: 0,
              lng: 0,
            },
            lokasi: "",
            tanggal_pelaksanaan: "",
            waktu_pelaksanaan: `-selesai`,
            pesan_undangan: "",
            cabang_id: id_cabang,
          };

          setDataCabangManasik([objectMap]);
        }
      })
    );
  };

  const removeItem = (item_index) => {
    setIsLoadingDeleteManasik(true);
    let data = dataCabangManasik[item_index - 1];
    dispatch(
      deleteManasikCabangPaket(data.id_manasik, (message) => {
        handleClickManasikCabang(data.cabang_id);
        setIsLoadingDeleteManasik(false);
        setIsDeleteManasik(false);
        dispatch(handleSuccess(message));
      })
    );
  };
  const handleClosePopUpConfirmManasik = () => {
    setIsDeleteManasik(false);
  };

  return (
    <>
      <div className="flex flex-col items-start p-6 gap-[16px]">
        <div className="flex flex-col justify-center items-start gap-[16px]">
          <div className="flex flex-row items-start gap-[16px] pb-5 border-b flex-wrap">
            {listManasik?.map((item, idx) => (
              <div
                className={`flex flex-row items-center gap-[12px] cursor-pointer hover:bg-[#fff] hover:shadow-md hover:p-[2px] ${
                  choiceManasik === idx ? "p-[2px] shadow-md [#fff]" : ""
                }`}
                onClick={() => {
                  handleClickManasikCabang(item.cabang_id);
                  setChoiceManasik(idx);
                }}
              >
                <Badge
                  label={`${item.cabang_name}`}
                  color={`${item.text_color}`}
                  backgroundColor={`${item.bg_color}`}
                  customClass="!capitalize font-bold text-[12px]"
                />
                <div className="px-[8px] py-[2px] rounded-md text-[#141414] flex flex-row items-center justify-center !bg-[#D8E1BE] font-sans font-semibold">
                  {item?.manasik_count}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <p>
        *Ketentuan : Untuk manasik umroh, maks. 2 kali setiap kota. Untuk
        manasik haji, maks. 10 kali setiap kota.
      </p>
      <div className="flex flex-col gap-3 w-full">
        {dataCabangManasik.map((item, index) => {
          const data = { ...item, id: index + 1 };
          return (
            <Item
              removeItem={(data) => {
                setIsDeleteManasik(true);
                setChoiceIndex(data);
              }}
              data={data}
              key={index}
              handleClickManasikCabang={handleClickManasikCabang}
            />
          );
        })}
      </div>
      <Buttons
        text="Tambah Manasik"
        customClass="btn-outline btn-large  mt-4 !w-full"
        containerClass="!w-full"
        onClick={addItem}
      />
      {/* AlertPopup */}
      <AlertPopup
        open={isDeleteManasik}
        handleClose={handleClosePopUpConfirmManasik}
        handleContinue={() => removeItem(choiceIndex)}
        title="Hapus Manasik?"
        subtitle={`Apakah kamu yakin ingin menghapus manasik ini ?`}
        loading={isLoadingDelManasik}
      />
    </>
  );
};

export default Manasik;
