import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CalculatorRequestPaketAction from "stores/actions/calculator/request";
import { getLocalStorage, toCurrencyFormat } from "utils/helpers";

import Badge from "../../components/badge";
import Popup from "../../components/popup";
import Buttons from "../../components/buttons";
import PopupConfirm from "components/popup/error-handling";
import Cards from "components/cards";
import Accordions from "components/accordions";

import Dummy from "./icons/dummy.png";
import Arrow from "./icons/arrow.svg";
import ArrowDown from "./icons/arrow_down.svg";
import ArrowTop from "./icons/arrow_top.svg";
import CalendarIC from "./icons/calendar.svg";
import FlightIC from "./icons/flight.svg";
import HotelIC from "./icons/hotel.svg";
import TourIC from "./icons/tour.svg";
import FeeIC from "./icons/fee.svg";
import IconRatingLight from "assets/icons/star-light.svg";
import IconEdit from "assets/icons/edit.svg";

import Rates from "components/form/rate";
import Inputs from "components/form/inputs";
import InputsCurrency from "components/form/inputsCurrency";
import { BASE_URL_ENDPOINT } from "constant/constanta";
import axios from "axios";

function RequestPaket() {
  const dispatch = useDispatch();
  const store = useSelector(
    ({ calculatorRequestPaket }) => calculatorRequestPaket
  );
  const [allPaket, setAllPaket] = useState([]);
  const [detailPaket, setDetailPaket] = useState([]);
  const [totalOperasionals, setTotalOperasionals] = useState(0);
  const [totalAkomodasis, setTotalAkomodasis] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPaket, setSelectedPaket] = useState(null);
  const [opensecond, setOpensecond] = React.useState(false);
  const [openPopup, setOpenPopup] = useState({
    show: false,
    type: "",
    title: "",
    subtitle: "",
  });
  const [listRequestPaket, setListRequestPaket] = useState([]);
  const [showContentDetail, setShowContentDetail] = useState({
    jangka: false,
    maskapai: false,
    hotel: false,
    tour: false,
    cabang: false,
    operational: false,
  });
  const [editPriceModal, setEditPriceModal] = useState({
    id: null,
    show: false,
    title: "Edit Harga",
    price: 0,
  });

  useEffect(() => {
    setEditPriceModal({
      id: null,
      show: false,
      title: "Edit Harga",
      price: 0,
    });
  }, []);

  useEffect(() => {
    dispatch(CalculatorRequestPaketAction.fetchData());
  }, [dispatch]);

  useEffect(() => {
    setListRequestPaket(store.data);
  }, [store, store.data.length]);

  useEffect(() => {
    getDataAllRequestPaket();
  }, []);

  const getDataAllRequestPaket = async () => {
    const url = `${BASE_URL_ENDPOINT}/admin/request-paket/get-all`;
    const data = {
      per_page: 25,
      // page_number: currentPage,
      // keyword: searchValue,
      sort_column: "package_title",
      sort_order: "ASC",
    };
    const accessToken = getLocalStorage("accessToken");

    const headers = {
      "Content-Type": "application/json", // Adjust content type as needed
      Authorization: `Bearer ${accessToken}`, // Add any authorization headers you need
    };

    // setIsLoading(true);

    try {
      const response = await axios.post(url, data, { headers });
      setIsLoading(false);
      if (response.data.code === 200) {
        setAllPaket(response.data.data);
      }
    } catch (error) {
      setIsLoading(false);
      // Handle errors here, e.g., show an error message
      console.error("Error:", error);
      // setIsLoading(false);
    }
  };

  const getDetailRequestPaket = async (id) => {
    const url = `${BASE_URL_ENDPOINT}/admin/request-paket/${id}`;
    const accessToken = getLocalStorage("accessToken");

    const headers = {
      "Content-Type": "application/json", // Adjust content type as needed
      Authorization: `Bearer ${accessToken}`, // Add any authorization headers you need
    };

    setIsLoading(true);

    try {
      const response = await axios.get(url, { headers });
      if (response.data.code === 200) {
        const fetchData = response.data.data;
        setDetailPaket(fetchData);
        setIsLoading(false);
        setOpensecond(true);
        const pricesOperasinals = fetchData?._operasionals?.map((item) =>
          parseFloat(item.price)
        );
        const totalOperasional = pricesOperasinals.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        );

        const pricesAkomodasis = fetchData?._akomodasis?.map((item) =>
          parseFloat(item.price) * parseInt(item.number_of_days)
        );
        const totalAkomodasi = pricesAkomodasis.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        );

        setTotalOperasionals(totalOperasional);
        setTotalAkomodasis(totalAkomodasi);
      }
    } catch (error) {
      setIsLoading(false);
      // Handle errors here, e.g., show an error message
      console.error("Error:", error);
    }
  };

  const onHandleUpdateHargaMaskapai = async () => {
    const url = `${BASE_URL_ENDPOINT}/admin/request-paket/update-harga-maskapai`;
    const payload = {
      paket_request_id: editPriceModal.id,
      maskapai_price: editPriceModal.price,
    };
    const accessToken = getLocalStorage("accessToken");

    const headers = {
      "Content-Type": "application/json", // Adjust content type as needed
      Authorization: `Bearer ${accessToken}`, // Add any authorization headers you need
    };

    setIsLoading(true);

    try {
      const response = await axios.post(url, payload, { headers });
      setIsLoading(false);
      if (response.data.code === 200) {
        getDetailRequestPaket(selectedPaket.id);
        setOpenPopup({
          show: true,
          type: "success",
          title: "Berhasil",
          subtitle: response.data.message,
        });
      }
    } catch (error) {
      setIsLoading(false);
      setOpenPopup({
        show: true,
        type: "error",
        title: "Gagal",
        subtitle: "",
      });
      console.error("Error:", error);
    }
  };

  const onHandleUpdateHargaAkomodasi = async () => {
    const url = `${BASE_URL_ENDPOINT}/admin/request-paket/update-harga-akomodasi`;
    const payload = {
      paket_request_akomodasi_id: editPriceModal.id,
      price: editPriceModal.price,
    };
    const accessToken = getLocalStorage("accessToken");

    const headers = {
      "Content-Type": "application/json", // Adjust content type as needed
      Authorization: `Bearer ${accessToken}`, // Add any authorization headers you need
    };

    setIsLoading(true);

    try {
      const response = await axios.post(url, payload, { headers });
      setIsLoading(false);
      if (response.data.code === 200) {
        getDetailRequestPaket(selectedPaket.id);
        setOpenPopup({
          show: true,
          type: "success",
          title: "Berhasil",
          subtitle: response.data.message,
        });
      }
    } catch (error) {
      setIsLoading(false);
      setOpenPopup({
        show: true,
        type: "error",
        title: "Gagal",
        subtitle: "",
      });
      console.error("Error:", error);
    }
  };

  const onHandleUpdateHargaCityTour = async () => {
    const url = `${BASE_URL_ENDPOINT}/admin/request-paket/update-harga-city-tour`;
    const payload = {
      paket_request_id: editPriceModal.id,
      city_tour_price: editPriceModal.price,
    };
    const accessToken = getLocalStorage("accessToken");

    const headers = {
      "Content-Type": "application/json", // Adjust content type as needed
      Authorization: `Bearer ${accessToken}`, // Add any authorization headers you need
    };

    setIsLoading(true);

    try {
      const response = await axios.post(url, payload, { headers });
      setIsLoading(false);
      if (response.data.code === 200) {
        getDetailRequestPaket(selectedPaket.id);
        setOpenPopup({
          show: true,
          type: "success",
          title: "Berhasil",
          subtitle: response.data.message,
        });
      }
    } catch (error) {
      setIsLoading(false);
      setOpenPopup({
        show: true,
        type: "error",
        title: "Gagal",
        subtitle: "",
      });
      console.error("Error:", error);
    }
  };

  const onHandleUpdateHargaNegaraTour = async () => {
    const url = `${BASE_URL_ENDPOINT}/admin/request-paket/update-harga-tour`;
    const payload = {
      paket_request_id: editPriceModal.id,
      tour_price: editPriceModal.price,
    };
    const accessToken = getLocalStorage("accessToken");

    const headers = {
      "Content-Type": "application/json", // Adjust content type as needed
      Authorization: `Bearer ${accessToken}`, // Add any authorization headers you need
    };

    setIsLoading(true);

    try {
      const response = await axios.post(url, payload, { headers });
      setIsLoading(false);
      if (response.data.code === 200) {
        getDetailRequestPaket(selectedPaket.id);
        setOpenPopup({
          show: true,
          type: "success",
          title: "Berhasil",
          subtitle: response.data.message,
        });
      }
    } catch (error) {
      setIsLoading(false);
      setOpenPopup({
        show: true,
        type: "error",
        title: "Gagal",
        subtitle: "",
      });
      console.error("Error:", error);
    }
  };

  const onHandleUpdateHargaHotelNegaraTour = async () => {
    const url = `${BASE_URL_ENDPOINT}/admin/request-paket/update-harga-tour-akomodasi`;
    const payload = {
      paket_request_id: editPriceModal.id,
      tour_akomodasi_price: editPriceModal.price,
    };
    const accessToken = getLocalStorage("accessToken");

    const headers = {
      "Content-Type": "application/json", // Adjust content type as needed
      Authorization: `Bearer ${accessToken}`, // Add any authorization headers you need
    };

    setIsLoading(true);

    try {
      const response = await axios.post(url, payload, { headers });
      setIsLoading(false);
      if (response.data.code === 200) {
        getDetailRequestPaket(selectedPaket.id);
        setOpenPopup({
          show: true,
          type: "success",
          title: "Berhasil",
          subtitle: response.data.message,
        });
      }
    } catch (error) {
      setIsLoading(false);
      setOpenPopup({
        show: true,
        type: "error",
        title: "Gagal",
        subtitle: "",
      });
      console.error("Error:", error);
    }
  };

  const onHandleUpdateHargaFeeCabang = async () => {
    const url = `${BASE_URL_ENDPOINT}/admin/request-paket/update-fee-cabang`;
    const payload = {
      paket_request_id: editPriceModal.id,
      fee_cabang: editPriceModal.price,
    };
    const accessToken = getLocalStorage("accessToken");

    const headers = {
      "Content-Type": "application/json", // Adjust content type as needed
      Authorization: `Bearer ${accessToken}`, // Add any authorization headers you need
    };

    setIsLoading(true);

    try {
      const response = await axios.post(url, payload, { headers });
      setIsLoading(false);
      if (response.data.code === 200) {
        getDetailRequestPaket(selectedPaket.id);
        setOpenPopup({
          show: true,
          type: "success",
          title: "Berhasil",
          subtitle: response.data.message,
        });
      }
    } catch (error) {
      setIsLoading(false);
      setOpenPopup({
        show: true,
        type: "error",
        title: "Gagal",
        subtitle: "",
      });
      console.error("Error:", error);
    }
  };

  const onHandleUpdateHargaBiayaOperasional = async () => {
    const url = `${BASE_URL_ENDPOINT}/admin/request-paket/update-harga-operasional`;
    const payload = {
      paket_request_operasional_id: editPriceModal.id,
      price: editPriceModal.price,
    };
    const accessToken = getLocalStorage("accessToken");

    const headers = {
      "Content-Type": "application/json", // Adjust content type as needed
      Authorization: `Bearer ${accessToken}`, // Add any authorization headers you need
    };

    setIsLoading(true);

    try {
      const response = await axios.post(url, payload, { headers });
      setIsLoading(false);
      if (response.data.code === 200) {
        getDetailRequestPaket(selectedPaket.id);
        setOpenPopup({
          show: true,
          type: "success",
          title: "Berhasil",
          subtitle: response.data.message,
        });
      }
    } catch (error) {
      setIsLoading(false);
      setOpenPopup({
        show: true,
        type: "error",
        title: "Gagal",
        subtitle: "",
      });
      console.error("Error:", error);
    }
  };

  const handleRejectPaket = async (id) => {
    const status = await dispatch(CalculatorRequestPaketAction.rejectData(id));
    if (status === 200) {
      setOpensecond(false);
      setOpenPopup({
        show: true,
        type: "success",
        title: "Berhasil",
        subtitle: "Anda telah berhasil menolak request paket.",
      });
    }
  };
  const handleApprovePaket = async (id) => {
    const status = await dispatch(
      CalculatorRequestPaketAction.approveData({
        dp: "",
        id_request_paket: id,
      })
    );
    if (status === 200) {
      setOpensecond(false);
      setOpenPopup({
        show: true,
        type: "success",
        title: "Berhasil",
        subtitle: "Anda telah berhasil menerima request paket.",
      });
    }
  };

  const conditionHandleEditHarga =
    editPriceModal.title === "Edit Harga Maskapai"
      ? () => onHandleUpdateHargaMaskapai()
      : editPriceModal.title === "Edit Harga Hotel Madinah" ||
        editPriceModal.title === "Edit Harga Hotel Makkah"
      ? () => onHandleUpdateHargaAkomodasi()
      : editPriceModal.title === "Edit Harga City Tour"
      ? () => onHandleUpdateHargaCityTour()
      : editPriceModal.title === "Edit Harga Negara Tour"
      ? () => onHandleUpdateHargaNegaraTour()
      : editPriceModal.title === "Edit Harga Hotel Negara Tour"
      ? () => onHandleUpdateHargaHotelNegaraTour()
      : editPriceModal.title === "Edit Harga Fee Cabang"
      ? () => onHandleUpdateHargaFeeCabang()
      : () => onHandleUpdateHargaBiayaOperasional();

  const onHandleEditDetailPacket = (id, title, price) => {
    setEditPriceModal({
      id: id,
      show: true,
      title: `Edit Harga ${title}`,
      price: price,
    });
  };

  return (
    <>
      <PopupConfirm
        open={openPopup.show}
        handleClose={() => {
          setOpenPopup(false);
          setEditPriceModal({
            id: null,
            show: false,
            title: "Edit Harga",
            price: 0,
          });
        }}
        type={openPopup.type}
        title={openPopup.title}
        subtitle={openPopup.subtitle}
      />

      <Cards containerClass="!p-0">
        <div>
          <p className="font-bold text-2xl px-4 py-[17px]">Request Paket</p>
          <hr />

          <div className="p-4">
            <div className="grid grid-cols-2 gap-4">
              {allPaket?.map((item, idx) => {
                return (
                  <div
                    key={idx}
                    onClick={() => {
                      setSelectedPaket(item);
                      getDetailRequestPaket(item.id);
                    }}
                    className="flex justify-between cursor-pointer p-4 shadow-soft rounded-2xl w-[545px]"
                  >
                    <div className="flex">
                      <img
                        className="rounded-2xl w-[144px] h-[144px] object-cover shadow-soft"
                        src={item?._cabang?.img}
                        alt="img"
                      />
                      <div className="ml-4 my-1 mr-1 flex justify-between flex-col">
                        <p className="font-bold text-xl">
                          {item?.package_title}
                        </p>
                        <div className="flex py-1 items-center">
                          <p className="font-bold">{item?._user?.name}</p>
                          <span className="px-2 font-bold">-</span>
                          <Badge
                            backgroundColor="#FCDE9C"
                            color="#FE9A00"
                            label={item?._cabang?.name}
                          />
                        </div>
                        <div className="flex">
                          <p className="font-bold">Total Estimasi Harga</p>
                          <span className="px-2 font-bold">:</span>
                          <p className="font-bold text-green-2">
                            Rp. {toCurrencyFormat(item?.total_price)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <Popup
            type="secondary"
            open={opensecond}
            title="Rincian Paket"
            handleClose={() => {
              setSelectedPaket(null);
              setOpensecond(false);
            }}
          >
            <div className="cursor-pointer p-4 shadow-soft rounded-2xl mt-[7px] mb-4">
              <div className="flex">
                <img
                  className="rounded-2xl w-[80px] h-[80px]"
                  src={Dummy}
                  alt="img"
                />
                <div className="ml-4">
                  <p className="font-bold text-base">
                    {detailPaket?.package_title}
                  </p>
                  <div className="flex py-1 items-center">
                    <p className="text-sm">{detailPaket?._user?.name}</p>
                    <span className="px-2">-</span>
                    <Badge
                      backgroundColor="#FCDE9C"
                      color="#FE9A00"
                      label={detailPaket?._user?.nama_cabang}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-2">
              <div className="bg-white rounded-2xl overflow-hidden shadow-soft">
                <Accordions
                  title="Maskapai"
                  arrowIcon={ArrowDown}
                  customClass="!rounded-none !shadow-none"
                  customSummaryClass="!border-b-0 summary-m-4"
                  customTitleClass="font-bold text-sm"
                  customContentClass="text-sm border-t border-[#E0E0E0] !px-0 mx-4 !py-3"
                  avatar={FlightIC}
                  content={
                    <>
                      <div className="flex justify-between py-2">
                        <span>{detailPaket?.maskapai_name}</span>
                        <div
                          className="flex gap-4 items-center"
                          onClick={() =>
                            onHandleEditDetailPacket(
                              detailPaket.id,
                              "Maskapai",
                              detailPaket?.maskapai_price
                            )
                          }
                        >
                          <span className="cursor-pointer shadow-hard p-2 rounded-lg">
                            <img src={IconEdit} />
                          </span>
                          <span>
                            Rp {toCurrencyFormat(detailPaket?.maskapai_price)}
                          </span>
                        </div>
                      </div>
                    </>
                  }
                />

                <div className="px-4 pt-0 pb-4 text-sm">
                  <div className="flex justify-between">
                    <span>Total</span>
                    <span>
                      Rp {toCurrencyFormat(detailPaket?.maskapai_price)}
                    </span>
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-2xl overflow-hidden shadow-soft">
                <Accordions
                  title="Hotel"
                  arrowIcon={ArrowDown}
                  customClass="!rounded-none !shadow-none"
                  customSummaryClass="!border-b-0 summary-m-4"
                  customTitleClass="font-bold text-sm"
                  customContentClass="text-sm border-t border-[#E0E0E0] !px-0 mx-4 !py-3"
                  avatar={HotelIC}
                  content={
                    detailPaket?._akomodasis?.length > 0 && (
                      <>
                        <div className="font-bold">Madinah</div>
                        {detailPaket?._akomodasis
                          .filter((item) => item?.city === "madinah")
                          .map((item, idx) => {
                            return (
                              <div
                                className="flex justify-between py-2"
                                key={idx}
                              >
                                <div className="flex justify-start items-center gap-4">
                                  <span>{item?.akomodasi_name}</span>
                                  <div className="flex items-center">
                                    {Array.from(Array(5), () => (
                                      <img src={IconRatingLight} />
                                    ))}
                                  </div>
                                </div>
                                <div
                                  className="flex gap-4 items-center"
                                  onClick={() =>
                                    onHandleEditDetailPacket(
                                      item.id,
                                      "Hotel Madinah",
                                      item.price
                                    )
                                  }
                                >
                                  <span className="cursor-pointer shadow-hard p-2 rounded-lg">
                                    <img src={IconEdit} />
                                  </span>
                                  <span className="text-red-3">
                                    {item?.number_of_days}x
                                  </span>
                                  <span>
                                    Rp {toCurrencyFormat(item?.price)}
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                        <div className="font-bold">Mekah</div>
                        {detailPaket?._akomodasis
                          .filter((item) => item?.city === "makkah")
                          .map((item, idx) => {
                            return (
                              <div
                                className="flex justify-between py-2"
                                key={idx}
                              >
                                <div className="flex justify-start items-center gap-4">
                                  <span>{item?.akomodasi_name}</span>
                                  <div className="flex items-center">
                                    {Array.from(Array(5), () => (
                                      <img src={IconRatingLight} />
                                    ))}
                                  </div>
                                </div>
                                <div
                                  className="flex gap-4 items-center"
                                  onClick={() =>
                                    onHandleEditDetailPacket(
                                      item.id,
                                      "Hotel Makkah",
                                      item.price
                                    )
                                  }
                                >
                                  <span className="cursor-pointer shadow-hard p-2 rounded-lg">
                                    <img src={IconEdit} />
                                  </span>
                                  <span className="text-red-3">
                                    {item?.number_of_days}x
                                  </span>
                                  <span>
                                    Rp {toCurrencyFormat(item?.price)}
                                  </span>
                                </div>
                              </div>
                            );
                          })}
                      </>
                    )
                  }
                />

                <div className="px-4 pt-0 pb-4 text-sm">
                  <div className="flex justify-between">
                    <span>Total</span>
                    <span>Rp {toCurrencyFormat(totalAkomodasis)}</span>
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-2xl overflow-hidden shadow-soft">
                <Accordions
                  title="City Tour"
                  arrowIcon={ArrowDown}
                  customClass="!rounded-none !shadow-none"
                  customSummaryClass="!border-b-0 summary-m-4"
                  customTitleClass="font-bold text-sm"
                  customContentClass="text-sm border-t border-[#E0E0E0] !px-0 mx-4 !py-3"
                  avatar={TourIC}
                  content={
                    <>
                      <div className="flex justify-between py-2">
                        <span className="flex">
                          {detailPaket?.city_tour_name}
                        </span>
                        <div
                          className="flex gap-4 items-center"
                          onClick={() =>
                            onHandleEditDetailPacket(
                              detailPaket?.id,
                              "City Tour",
                              detailPaket?.city_tour_price
                            )
                          }
                        >
                          <span className="cursor-pointer shadow-hard p-2 rounded-lg">
                            <img src={IconEdit} />
                          </span>
                          <span>
                            Rp {toCurrencyFormat(detailPaket?.city_tour_price)}
                          </span>
                        </div>
                      </div>
                    </>
                  }
                />

                <div className="px-4 pt-0 pb-4 text-sm">
                  <div className="flex justify-between">
                    <span>Total</span>
                    <span>
                      Rp {toCurrencyFormat(detailPaket?.city_tour_price)}
                    </span>
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-2xl overflow-hidden shadow-soft">
                <Accordions
                  title="Negara Tour"
                  arrowIcon={ArrowDown}
                  customClass="!rounded-none !shadow-none"
                  customSummaryClass="!border-b-0 summary-m-4"
                  customTitleClass="font-bold text-sm"
                  customContentClass="text-sm border-t border-[#E0E0E0] !px-0 mx-4 !py-3"
                  avatar={TourIC}
                  content={
                    <>
                      <div className="flex justify-between py-2">
                        <span className="flex">{detailPaket?.tour_name}</span>
                        <div
                          className="flex gap-4 items-center"
                          onClick={() =>
                            onHandleEditDetailPacket(
                              detailPaket.id,
                              "Negara Tour",
                              detailPaket.tour_price
                            )
                          }
                        >
                          <span className="cursor-pointer shadow-hard p-2 rounded-lg">
                            <img src={IconEdit} />
                          </span>
                          <span>
                            Rp {toCurrencyFormat(detailPaket?.tour_price)}
                          </span>
                        </div>
                      </div>
                    </>
                  }
                />

                <div className="px-4 pt-0 pb-4 text-sm">
                  <div className="flex justify-between">
                    <span>Total</span>
                    <span>Rp {toCurrencyFormat(detailPaket?.tour_price)}</span>
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-2xl overflow-hidden shadow-soft">
                <Accordions
                  title="Hotel Negara Tour"
                  arrowIcon={ArrowDown}
                  customClass="!rounded-none !shadow-none"
                  customSummaryClass="!border-b-0 summary-m-4"
                  customTitleClass="font-bold text-sm"
                  customContentClass="text-sm border-t border-[#E0E0E0] !px-0 mx-4 !py-3"
                  avatar={HotelIC}
                  content={
                    <>
                      <div className="flex justify-between py-2">
                        <div className="flex justify-start items-center gap-4">
                          <span>{detailPaket?.tour_akomodasi_name}</span>
                          <div className="flex items-center">
                            {Array.from(Array(5), () => (
                              <img src={IconRatingLight} />
                            ))}
                          </div>
                        </div>
                        <div
                          className="flex gap-4 items-center"
                          onClick={() =>
                            onHandleEditDetailPacket(
                              detailPaket.id,
                              "Hotel Negara Tour",
                              detailPaket?.tour_akomodasi_price
                            )
                          }
                        >
                          <span className="cursor-pointer shadow-hard p-2 rounded-lg">
                            <img src={IconEdit} />
                          </span>
                          <span className="text-red-3">
                            {detailPaket?.tour_akomodasi_number_of_days}x
                          </span>
                          <span>
                            Rp{" "}
                            {toCurrencyFormat(
                              detailPaket?.tour_akomodasi_price
                            )}
                          </span>
                        </div>
                      </div>
                    </>
                  }
                />

                <div className="px-4 pt-0 pb-4 text-sm">
                  <div className="flex justify-between">
                    <span>Total</span>
                    <span>
                      Rp {toCurrencyFormat(detailPaket?.tour_akomodasi_price * detailPaket?.tour_akomodasi_number_of_days)}
                    </span>
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-2xl overflow-hidden shadow-soft">
                <Accordions
                  title="Fee Cabang"
                  arrowIcon={ArrowDown}
                  customClass="!rounded-none !shadow-none"
                  customSummaryClass="!border-b-0 summary-m-4"
                  customTitleClass="font-bold text-sm"
                  customContentClass="text-sm border-t border-[#E0E0E0] !px-0 mx-4 !py-3"
                  avatar={FeeIC}
                  content={
                    <>
                      <div className="flex justify-between py-2">
                        <span className="flex">Fee Cabang</span>
                        <div
                          className="flex gap-4 items-center"
                          onClick={() =>
                            onHandleEditDetailPacket(
                              detailPaket.id,
                              "Fee Cabang",
                              detailPaket?.fee_cabang
                            )
                          }
                        >
                          <span className="cursor-pointer shadow-hard p-2 rounded-lg">
                            <img src={IconEdit} />
                          </span>
                          <span>
                            Rp {toCurrencyFormat(detailPaket?.fee_cabang)}
                          </span>
                        </div>
                      </div>
                    </>
                  }
                />

                <div className="px-4 pt-0 pb-4 text-sm">
                  <div className="flex justify-between">
                    <span>Total</span>
                    <span>Rp {toCurrencyFormat(detailPaket?.fee_cabang)}</span>
                  </div>
                </div>
              </div>

              <div className="bg-white rounded-2xl overflow-hidden shadow-soft">
                <Accordions
                  title="Biaya Operasional"
                  arrowIcon={ArrowDown}
                  customClass="!rounded-none !shadow-none"
                  customSummaryClass="!border-b-0 summary-m-4"
                  customTitleClass="font-bold text-sm"
                  customContentClass="text-sm border-t border-[#E0E0E0] !px-0 mx-4 !py-3"
                  avatar={FeeIC}
                  content={
                    <>
                      {detailPaket?._operasionals?.map((item, idx) => {
                        return (
                          <div key={idx} className="flex justify-between py-2">
                            <span className="flex">{item.item}</span>
                            <div
                              className="flex gap-4 items-center"
                              onClick={() =>
                                onHandleEditDetailPacket(
                                  item.id,
                                  item.item,
                                  item.price
                                )
                              }
                            >
                              <span className="cursor-pointer shadow-hard p-2 rounded-lg">
                                <img src={IconEdit} />
                              </span>
                              <span>Rp {toCurrencyFormat(item.price)}</span>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  }
                />

                <div className="px-4 pt-0 pb-4 text-sm">
                  <div className="flex justify-between">
                    <span>Total</span>
                    <span>Rp {toCurrencyFormat(totalOperasionals)}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white w-full flex flex-row relative justify-between items-center mt-4 pt-4">
              <Buttons
                text="Tolak"
                customClass="btn-outline btn-large w-full"
                style={{
                  width: "304px",
                }}
              />
              <div classsName="px-2" />
              <Buttons
                text="Terima"
                customClass="btn-primary btn-large w-full"
                style={{
                  width: "304px",
                }}
              />
            </div>
          </Popup>

          <Popup
            type="secondary"
            open={editPriceModal.show}
            title={editPriceModal.title}
            width="640px"
            handleClose={() => {
              //   setSelectedPaket(null);
              let editPriceModalData = { ...editPriceModal };
              editPriceModalData["show"] = false;

              setEditPriceModal(editPriceModalData);
            }}
          >
            <div className="border border-gray-5 rounded-xl mt-[7px]">
              <div className="p-4">
                <InputsCurrency
                  label="Harga"
                  value={editPriceModal?.price}
                  onChange={(val) =>
                    setEditPriceModal({
                      ...editPriceModal,
                      price: val,
                    })
                  }
                />
              </div>
              <div className="border-t border-gray-5 p-4 flex justify-end">
                <Buttons
                  text="Simpan"
                  customClass={`btn-primary btn-large ${
                    isLoading && "disabled"
                  }`}
                  loading={isLoading}
                  onClick={conditionHandleEditHarga}
                />
              </div>
            </div>
          </Popup>
        </div>
      </Cards>
    </>
  );
}

export default RequestPaket;
