// tipeKamar Actions
// --------------------------------------------------------

import {
  handleError,
  handleErrorBE,
  handleSuccess,
} from "stores/actions/errorGeneral";
import { tipeKamarApi } from "utils/api/paket/tipeKamar";

/* eslint-disable space-before-function-paren */
export const SET_LOADING = "tipeKamar/SET_LOADING";
export const CLEAR_ERROR = "tipeKamar/CLEAR_ERROR";
export const SET_ERROR = "tipeKamar/SET_ERROR";
export const INIT_DATA = "tipeKamar/INIT_DATA";
export const SET_DOUBLE_SUBMIT = "tipeKamar/SET_DOUBLE_SUBMIT";
export const SET_GET_ALL = "tipeKamar/SET_GET_ALL";
export const SET_GET_AKTIF = "tipeKamar/SET_GET_AKTIF";
export const SET_DETAIL = "tipeKamar/SET_DETAIL";

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const setDoubleSubmit = (payload) => ({
  type: SET_DOUBLE_SUBMIT,
  payload,
});

export const setGetAll = (payload) => ({
  type: SET_GET_ALL,
  payload,
});

export const setGetAktif = (payload) => ({
  type: SET_GET_AKTIF,
  payload,
});

export const setDetail = (payload) => ({
  type: SET_DETAIL,
  payload,
});

export const getAllTipeKamarDasarPaket = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await tipeKamarApi.allTipeKamarDasar(id);
    if (res.status === 200) {
      dispatch(setGetAll(res.data));
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const setTipeKamarDasarPaket =
  (id, payload, actionSuccess, actionError) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const res = await tipeKamarApi.setTipedasarKamar(id, payload);
      if (res.status === 200) {
        actionSuccess(res);
      } else {
        actionError(res);
      }
    } catch (error) {
      dispatch(handleError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const setTipeKamarStartingPaket =
  (id, payload, actionSuccess, actionError) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const res = await tipeKamarApi.setTipeKamarStarting(id, payload);
      if (res.status === 200 || res.status === 201) {
        actionSuccess(res);
      } else {
        actionError(res);
      }
    } catch (error) {
      dispatch(handleError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const setUpdatedTipeKamarDasarPaket =
  (id, payload, actionSuccess, actionError) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const res = await tipeKamarApi.setUpdatedTipeKamarDasar(id, payload);
      if (res.status === 200) {
        actionSuccess(res);
      } else {
        actionError(res);
      }
    } catch (error) {
      dispatch(handleError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const getAllTipeKamarPenambahan = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await tipeKamarApi.allTipeKamarPenambahan(id);
    if (res.status === 200) {
      dispatch(setGetAktif(res.data));
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const setUpdatedTipeKamarPenambahanPaket =
  (id, payload, actionSuccess, actionError) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const res = await tipeKamarApi.setUpdatedTipeKamarPenambahan(id, payload);
      if (res.status === 200) {
        actionSuccess(res);
      } else {
        actionError(res);
      }
    } catch (error) {
      dispatch(handleError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const gerListStartingJamaahPaket =
  (id, payload, actionSuccess, actionError) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const res = await tipeKamarApi.getListJamaahStarting(id, payload);
      if (res.status === 200) {
        actionSuccess(res.data);
      } else {
        actionError(res);
      }
    } catch (error) {
      dispatch(handleError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };

const updateHarga = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await tipeKamarApi.updateHarga(payload);
    if (res.status === 200) {
      dispatch(handleSuccess(res.data));
      return res.status;
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

const updateHargaBatch = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await tipeKamarApi.updateHargaBatch(payload);
    if (res.status === 200) {
      dispatch(handleSuccess(res.data));
      return res.data;
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

const deleteById = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await tipeKamarApi.delete(payload);
    if (res.status === 200) {
      dispatch(handleSuccess(res.data));
      return res.status;
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

const detailById =
  ({ paket_id, starting_id, tipe_kamar }) =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const res = await tipeKamarApi.detail({
        paket_id: paket_id,
        starting_id: starting_id,
        tipe_kamar: tipe_kamar,
      });

      if (res.status === 200) {
        dispatch(setDetail(res.data));
        return res.data;
      } else {
        dispatch(handleErrorBE(res));
      }
    } catch (error) {
      dispatch(handleError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const tipeKamar = {
  aktif: () => {},
  updateHarga: updateHarga,
  updateHargaBatch: updateHargaBatch,
  delete: deleteById,
  detail: detailById,
};
