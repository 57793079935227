import Buttons from "components/buttons";
import DatePicker from "components/datepicker/DatePicker";
import Inputs from "components/form/inputs";
import React from "react";

const Vaksin = ({ validation, index }) => {
  const handleChange = (e) => {
    console.log(e);
  };

  const handleChangeInput = (e) => {
    validation.setValues(`form[${index}].jenis_vaksin`);
  };

  return (
    <>
      <div className="h-[48px] w-full bg-[#ffdcdc] rounded-[16px] mb-[16px] flex justify-between px-[16px] py-[12px]">
        <h4 className="text-[#141414] font-[600] text-[16px]">
          Dokumen Vaksin {index + 1}
        </h4>
        <div className="">test</div>
      </div>
      <div className="w-full border rounded-[16px]">
        <div className="p-[16px]">
          <Inputs
            name="jenis_vaksin"
            onChange={handleChangeInput}
            onBlur={validation.handleBlur}
            value={validation.values.form?.[index].jenis_vaksin}
            label="Jenis Vaksin"
            placeholder="Masukan Jenis Vaksin"
            error={
              validation.touched.form?.[index].jenis_vaksin &&
              validation.errors.form?.[index].jenis_vaksin &&
              validation.errors.form?.[index].jenis_vaksin
            }
          />
          <div className="w-full mt-[16px]">
            <div className="input-label">Tanggal Vaksin</div>
            <DatePicker
              placeholder="Masukkan Tanggal Vaksin"
              onChange={handleChange}
              containerClass="mt-2"
              customClass="w-full"
            />
          </div>
        </div>
        <div className="w-full flex justify-end border-t p-[16px]">
          <Buttons
            // onClick={() => validation.handleSubmit()}
            // disabled={validation.values.foto === ""}
            text="Simpan"
            style={{ width: 150 }}
            customClass="btn-primary btn-medium mt-3"
          />
        </div>
      </div>
    </>
  );
};

export default Vaksin;
