import React, { useCallback, useState } from "react";

import TagihanDetailStyle from "./TagihanDetail.style";
import Tab from "../../../components/tab";
import Popup from "../../../components/popup";
import CardDetailTagihanHeader from "./components/CardDetailTagihanHeader";
import Inputs from "../../../components/form/inputs";
import AddAdditional from "./components/AddAdditional";
import TabTagihan from "./components/TabTagihan";
import TabPembayaran from "./components/TabPembayaran";

import IconSearch from "../../../assets/icons/search-normal.svg";
import Photo from "../../../assets/images/Photo.png";
import IconEdit from "../../../assets/icons/edit.svg";
import IconDelete from "../../../assets/icons/trash.svg";
import Buttons from "../../../components/buttons";
import AddPayment from "./components/AddPayment";

const TagihanDetailPage = () => {
  const columns = [
    {
      title: "Nama",
      dataIndex: "name",
    },
    {
      title: "Jumlah",
      dataIndex: "total",
    },
    {
      title: "Catatan",
      dataIndex: "note",
    },
    {
      title: "Harga",
      dataIndex: "price",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: () => (
        <div className="flex flex-row gap-6">
          <img
            className="cursor-pointer"
            src={IconEdit}
            alt="edit"
            onClick={() => setOpenPopupAdditional(true)}
          />
          <img className="cursor-pointer" src={IconDelete} alt="delete" />
        </div>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      name: "Suntik Meningitis",
      total: "1",
      note: "-",
      price: "Rp 500.000",
      action: "whatsapp",
    },
    {
      key: "2",
      name: "Kerete Cepat",
      total: "1",
      note: "-",
      price: "Rp 2.000.000",
      action: "whatsapp",
    },
    {
      key: "3",
      name: "Kursi Roda",
      total: "1",
      note: "-",
      price: "Rp 1.000.000",
      action: "whatsapp",
    },
  ];

  const dropdownItems = [
    {
      name: "Upgrade Business Class",
      price: "Rp 2.000.000",
    },
    {
      name: "Naik Kereta Cepat",
      price: "Rp 2.000.000",
    },
    {
      name: "Kursi Roda",
      price: "Rp 2.000.000",
    },
    {
      name: "Layanan Pembuatan Passport",
      price: "Rp 2.000.000",
    },
    {
      name: "Layanan Suntik Meningitis",
      price: "Rp 2.000.000",
    },
    {
      name: "Skuter Untuk Thowaf",
      price: "Rp 2.000.000",
    },
  ];

  const jamaahItems = [
    {
      name: "Upgrade Business Class",
      price: "Rp 2.000.000",
    },
    {
      name: "Naik Kereta Cepat",
      price: "Rp 2.000.000",
    },
    {
      name: "Kursi Roda",
      price: "Rp 2.000.000",
    },
    {
      name: "Layanan Pembuatan Passport",
      price: "Rp 2.000.000",
    },
    {
      name: "Layanan Suntik Meningitis",
      price: "Rp 2.000.000",
    },
    {
      name: "Skuter Untuk Thowaf",
      price: "Rp 2.000.000",
    },
  ];

  const [openPopupAdditional, setOpenPopupAdditional] = useState(false);
  const [openPopupPayment, setOpenPopupPayment] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [selectedJamaahItem, setSelectedJamaahItem] = useState("");
  const [activeTab, setActiveTab] = useState("1");
  const handleClosePopup = useCallback(() => {
    if (openPopupAdditional) {
      setOpenPopupAdditional(false);
    }
    if (openPopupPayment) {
      setOpenPopupPayment(false);
    }
  }, [openPopupAdditional, openPopupPayment]);

  return (
    <TagihanDetailStyle>
      <Popup
        open={openPopupAdditional}
        handleClose={handleClosePopup}
        title="Tambah Additional"
        containerClass="custom-tab-jamaah"
        children={
          <AddAdditional
            dropdownItems={dropdownItems}
            jamaahItems={jamaahItems}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            selectedJamaahItem={selectedJamaahItem}
            setSelectedJamaahItem={setSelectedJamaahItem}
          />
        }
      />
      <Popup
        open={openPopupPayment}
        handleClose={handleClosePopup}
        title="Tambah Pembayaran"
        containerClass=""
        children={<AddPayment />}
      />
      <CardDetailTagihanHeader />
      <div className="relative">
        <div className="flex flex-row absolute items-center right-0 top-[14px]">
          <Inputs
            id="test"
            placeholder="Search"
            customClass="!px-3 !py-[6px] !pl-[42px] !w-[230px]"
            iconPosition="left"
            icon={IconSearch}
          />
          {activeTab === "2" ? (
            <div className="flex flex-row gap-2 ml-2">
              <Buttons
                text="Tambah Pembayaran"
                customClass="btn-primary px-3 py-2"
                onClick={() => setOpenPopupPayment(true)}
              />
            </div>
          ) : (
            <div className="flex flex-row gap-2 ml-2">
              <Buttons
                text="Tambah Additonal"
                customClass="btn-primary px-3 py-2"
                onClick={() => setOpenPopupAdditional(true)}
              />
            </div>
          )}
        </div>
        <Tab
          customClass="mt-10 custom-tab-jamaah"
          defaultActiveKey="1"
          onChange={(e) => setActiveTab(e)}
          items={[
            {
              label: "Tagihan (6 Jamaah)",
              key: "1",
              children: (
                <TabTagihan avatar={Photo} columns={columns} data={data} />
              ),
            },
            { label: "Pembayaran", key: "2", children: <TabPembayaran /> },
          ]}
        />
      </div>
      <div id="area-modal"></div>
    </TagihanDetailStyle>
  );
};

export default TagihanDetailPage;
