import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "utils/helpers/useDebounce";
import moment from "moment";
import { toCapital, getLocalStorage } from "../../../utils/helpers/index"

import MemberAction from "stores/actions/member/admin";
import AgenAction from "stores/actions/member/agen";
import JamaahAction from "stores/actions/member/jamaah";
import { getAllPaket } from "stores/actions/paket/landing";
import BookingAction from 'stores/actions/booking/umroh'

import Dropdowns, { closeDropdown } from "components/dropdowns";
import Inputs from "components/form/inputs";
import Badge from "components/badge";
import Tab from "components/tab";
import Buttons from "components/buttons";

import IconSearch from "assets/icons/search-normal.svg";
import Tanggal from "assets/icons/Tanggal.svg";
import Photo from "assets/images/Photo.png";
import IconTotalSeat from "assets/icons/total-seat.svg";
import IconSeatTerisi from "assets/icons/seat-terisi.svg";
import IconSeatTersisa from "assets/icons/seat-tersisa.svg";


import * as Yup from 'yup';

const AddBooking = () => {
  const dispatch = useDispatch();
  const { error, error400, error404, success } = useSelector(
    (state) => state.errorGeneral
  );
  

  const storePaket = useSelector(({ landingPaket }) => landingPaket);
  const storeAdmin = useSelector(({ admin }) => admin);
  const storeAgen = useSelector(({ agen }) => agen);
  const storeJamaah = useSelector(({ jamaah }) => jamaah);
  const storeBooking = useSelector(({ bookingUmroh }) => bookingUmroh);

  const [listPaket, setListPaket] = useState();
  const [searchValuePaket, setSearchValuePaket] = useState("");
  const searchDebouncePaket = useDebounce(searchValuePaket, 1000);

  const [listPemesan, setListPemesan] = useState();
  const [activeTab, setActiveTab] = useState("admin");
  const [searchValuePemesan, setSearchValuePemesan] = useState("");
  const searchDebouncePemesan = useDebounce(searchValuePemesan, 1000);

  const [formData, setFormData] = useState({
      paket_id : null,
      user_order_role: 'admin',
      user_order_id: null
  })

  const [errors, setErrors] = useState({});
  const [isError, setIsError] = useState(true);

  const [selectPaketLable, setSelecPaketLable] = useState("Pilih Paket")
  const [selectPemesanLable, setSelecPemesanLable] = useState("Pilih Pemesan")

  const [selectMeStatus, setSelectMeStatus] = useState(false)

  const me = JSON.parse(getLocalStorage('userLogin')).data

  useEffect(() => {
    const payload = {
      per_page: 3,
      page_number: 1,
      keyword: searchValuePaket,
      sort_column: "tipe_paket",
      sort_order: "asc",
    };
    dispatch(getAllPaket(payload));
  }, [searchDebouncePaket]);

  useEffect(() => {
    setListPaket(storePaket.allPaket.data);
  }, [storePaket?.allPaket?.data, storePaket?.allPaket?.data?.length]);

  useEffect(() => {
      console.log("Success Child : " + success.isSuccess);
  }, [success]);

  useEffect(() => {
    switch (activeTab) {
      case "admin":
        dispatch(
          MemberAction.fetchData({
            per_page: 3,
            page_number: 1,
            keyword: searchValuePemesan,
            sort_column: "",
            sort_order: "asc",
          })
        );
        break;
      case "agent":
        dispatch(
          AgenAction.fetchData({
            per_page: 3,
            page_number: 1,
            keyword: searchValuePemesan,
            sort_column: "email",
            sort_order: "asc",
            id_role: "",
          })
        );
        break;
      case "jamaah":
        dispatch(
          JamaahAction.fetchData({
            per_page: 3,
            page_number: 1,
            keyword: searchValuePemesan,
            sort_column: "email",
            sort_order: "asc",
            id_role: "",
          })
        );
        break;
      default:
        dispatch(
          MemberAction.fetchData({
            per_page: 3,
            page_number: 1,
            keyword: searchValuePemesan,
            sort_column: "",
            sort_order: "asc",
          })
        );
    }
  }, [searchDebouncePemesan, activeTab]);

  useEffect(() => {
    setListPemesan(storeAdmin.data);
  }, [storeAdmin, storeAdmin.data.length]);

  useEffect(() => {
    setListPemesan(storeAgen.data);
  }, [storeAgen, storeAgen.data.length]);

  useEffect(() => {
    setListPemesan(storeJamaah.data);
  }, [storeJamaah, storeJamaah.data.length]);

  useEffect(() =>{

    if(formData?.user_order_id == me?.id) {
      setSelectMeStatus(true)
    }

    handleValidation()

  }, [formData])

  const handleSelectPackage = (paket) => {
    setFormData((pervFormData) => ({
      ...pervFormData,
      paket_id: paket.id_paket
    }))
    setSelecPaketLable(paket.judul_paket)
  }

  const handleSelectPemesan = (pemesan) => {
    setFormData((pervFormData) => ({
      ...pervFormData,
      user_order_id: pemesan.id
    }))
    setSelecPemesanLable(`${toCapital(pemesan.title)}. ${pemesan.name}`)
  }

  const handleSelectMe = () => {
    setFormData((pervFormData) => ({
      ...pervFormData,
      user_order_id: me.id
    }))
    setSelecPemesanLable(`${toCapital(me?.title)}. ${me?.name}`)
    setFormData((pervFormData) => ({
      ...pervFormData,
      user_order_role: 'admin'
    }))
  }

  const validationSchema = Yup.object().shape({
    paket_id: Yup.number().integer().nullable(false).required("Anda belum memilih paket"),
    user_order_id: Yup.number().integer().nullable(false).required("Anda belum memilih pemesan"),
    user_order_role: Yup.string().nullable(false).required("Pemesan yang anda pilih harus admin/agent/jamaah")
  })

  const handleValidation = async (onSave = false) => {

    try {
      await validationSchema.validate(formData, { abortEarly: false })
      !onSave && setIsError(false)
    } catch(err) {
      !onSave && setIsError(true)
    }

  }

  const handleSave = async () => {
    dispatch(BookingAction.postData(formData))
  }

  return (
    <>
      <div className="border border-[#E0E0E0] rounded-2xl">
        <div className="p-4">
          <div className="font-medium">Paket</div>
          <Dropdowns
            customClass={`bg-[#f2f2f2] p-4 rounded-lg flex mt-2 justify-between items-center`}
            placement="bottom"
            labelDropdown={selectPaketLable}
            dropdownArrow={true}
          >
            
            <div className="bg-white w-full p-4 soft-shadow rounded-2xl">
              <Inputs
                id="test"
                placeholder="Search"
                customClass="!px-3 !py-3.5 !pl-[42px] text-sm"
                iconPosition="left"
                onChange={(e) => setSearchValuePaket(e.target.value)}
                icon={IconSearch}
              />

              {listPaket ? (
                listPaket.map((val, idx) => {
                  return (
                    <div
                      key={idx}
                      className="flex flex-row bg-white rounded-2xl border-[0.5px] border-[#e0e0e0] py-2 px-4 mt-4 cursor-pointer"
                      onClick={() => {
                        handleSelectPackage(val);
                        closeDropdown();
                      }}
                    >
                      <div
                        className="card-detail-booking-image w-[104px] h-[104px] rounded-xl overflow-hidden"
                        // style={{
                        //   backgroundImage: `url(${val.image_thumbnail})`,
                        // }}
                      >
                        <img src={val.image_thumbnail} />
                      </div>
                      <div className="ml-4 flex flex-col gap-2 justify-center">
                        <div className="font-bold text-xl">
                          {val.judul_paket}
                        </div>
                        <div className="flex flex-row items-center gap-2">
                          <img src={Tanggal} className="w-6 h-6" alt="tanggal" />
                          <div className="text-sm">
                            {moment(val.jadwal_keberangkatan).format(
                              "DD MMMM YYYY"
                            )}
                          </div>
                        </div>
                        <div className="flex flex-row gap-4 w-fit">
                          <div className="flex flex-row items-center gap-2">
                            <img className="w-6 h-6" src={IconTotalSeat} alt="" />
                            <div>{val.kuota}</div>
                          </div>
                          <div className="flex flex-row items-center gap-2">
                            <img className="w-6 h-6" src={IconSeatTerisi} alt="" />
                            <div>{val.jumlah_jamaah}</div>
                          </div>
                          <div className="flex flex-row items-center gap-2">
                            <img className="w-6 h-6" src={IconSeatTersisa} alt="" />
                            <div>{val.kuota_tersedia}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="mt-4">Paket Tidak ditemukan</div>
              )}
            </div>
          </Dropdowns>
          <div className="font-medium mt-4">
            Pemesan { !selectMeStatus &&  <a className="inline-block text-red-1 cursor-pointer" onClick={handleSelectMe}>pilih diri saya</a> }
          </div>
          <Dropdowns
            customClass={`bg-[#f2f2f2] p-4 rounded-lg flex mt-2 justify-between  items-center`}
            placement="bottom"
            labelDropdown={selectPemesanLable}
            dropdownArrow={true}
          >
            <div className="bg-white w-full p-4 soft-shadow rounded-2xl">
              <Tab
                defaultActiveKey="admin"
                onChange={(e) => {
                  setSearchValuePemesan("");
                  setActiveTab(e);
                  setFormData((pervFormData) => ({
                    ...pervFormData,
                    user_order_role: e
                  }))
                  handleValidation()
                }}
                items={[
                  {
                    label: "Admin",
                    key: "admin",
                    children: (
                      <>
                        <Inputs
                          id="test"
                          placeholder="Search"
                          customClass="!px-3 !py-3.5 !pl-[42px] text-sm"
                          iconPosition="left"
                          onChange={(e) =>
                            setSearchValuePemesan(e.target.value)
                          }
                          icon={IconSearch}
                        />
                        {listPemesan && listPemesan.length !== 0 ? (
                          listPemesan.map((val, idx) => {
                            return (
                              <div
                                key={idx}
                                className="flex flex-row bg-white rounded-xl border-[0.5px] border-[#e0e0e0] p-4 mt-3 cursor-pointer items-center"
                                onClick={() => {
                                  handleSelectPemesan(val);
                                  closeDropdown();
                                }}
                              >
                                <img
                                  className="w-[56px] h-[56px] rounded-full"
                                  src={Photo}
                                  alt="avatar"
                                />
                                <div className="ml-4">
                                  <div className="font-semibold text-[20px]">
                                    {toCapital(val.title)}. {val.name}
                                  </div>
                                  <div className="flex gap-2 mt-2">
                                    <Badge backgroundColor={val.color_bg_role} color={val.color_text_role} label={val.nama_role} />
                                    <Badge backgroundColor={val.color_bg_cabang} color={val.color_text_cabang} label={val.nama_cabang} />
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <div className="mt-4">Admin tidak ditemukan.</div>
                        )}
                      </>
                    ),
                  },
                  {
                    label: "Agen",
                    key: "agent",
                    children: (
                      <>
                        <Inputs
                          id="test"
                          placeholder="Search"
                          customClass="!px-3 !py-3.5 !pl-[42px] text-sm"
                          iconPosition="left"
                          onChange={(e) =>
                            setSearchValuePemesan(e.target.value)
                          }
                          icon={IconSearch}
                        />
                        {listPemesan && listPemesan.length !== 0 ? (
                          listPemesan.map((val, idx) => {
                            return (
                              <div
                                key={idx}
                                className="flex flex-row bg-white rounded-xl border-[0.5px] border-[#e0e0e0] p-4 mt-3 cursor-pointer items-center"
                                onClick={() => {
                                  handleSelectPemesan(val);
                                  closeDropdown();
                                }}
                              >
                                <img
                                  className="w-[56px] h-[56px] rounded-full"
                                  src={Photo}
                                  alt="avatar"
                                />
                                <div className="ml-3">
                                  <div className="font-semibold text-[20px]">
                                    {val.name}
                                  </div>
                                  <div className="flex gap-2 mt-2">
                                    <Badge backgroundColor={val.color_bg_role} color={val.color_text_role} label={val.role_name} />
                                    <Badge backgroundColor={val.color_bg_cabang} color={val.color_text_cabang} label={val.cabang_name} />
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <div className="mt-4">Agen tidak ditemukan.</div>
                        )}
                      </>
                    ),
                  },
                  {
                    label: "Jamaah",
                    key: "jamaah",
                    children: (
                      <>
                        <Inputs
                          id="test"
                          placeholder="Search"
                          customClass="!px-3 !py-4 !pl-[42px]"
                          iconPosition="left"
                          onChange={(e) =>
                            setSearchValuePemesan(e.target.value)
                          }
                          icon={IconSearch}
                        />
                        {listPemesan && listPemesan.length !== 0 ? (
                          listPemesan.map((val, idx) => {
                            return (
                              <div
                                key={idx}
                                className="flex flex-row bg-white rounded-xl border-[0.5px] border-[#e0e0e0] p-4 mt-3 cursor-pointer items-center"
                                onClick={() => {
                                  handleSelectPemesan(val);
                                  closeDropdown();
                                }}
                              >
                                <img
                                  className="w-[56px] h-[56px] rounded-full"
                                  src={Photo}
                                  alt="avatar"
                                />
                                <div className="ml-3">
                                  <div className="font-semibold text-[20px]">
                                    {toCapital(val.title)}. {val.name}
                                  </div>
                                  <div className="flex gap-2 mt-2">
                                    <Badge backgroundColor={val.color_bg_role} color={val.color_text_role} label={val.role_name} />
                                    {/* <Badge label={val.city} /> */}
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <div className="mt-4">Jamaah tidak ditemukan.</div>
                        )}
                      </>
                    ),
                  },
                ]}
              />
            </div>
          </Dropdowns>
        </div>
        <div className="divider">
        </div>
        <div className="flex flex-row justify-end p-4">
          <Buttons 
            text="Simpan" 
            customClass={`btn-primary btn-large`}
            onClick={handleSave}
            disabled={isError}
            loading={storeBooking.isLoading}
            />
        </div>
      </div>
    </>
  );
};

export default React.memo(AddBooking);
