import React, { useState } from "react";
import { Grid } from "@material-ui/core";

import Cards from "components/cards";
import Buttons from "components/buttons";
import Inputs from "components/form/inputs";
import Tab from "components/tab";
import BookingTable from "./BookingTable";

import IconSearch from "assets/icons/search-normal.svg";
import IconSquare from "assets/icons/send-square.svg";
import IconActivity from "assets/icons/activity.svg";
import Jamaah from "./Jamaah";

const CardBookingTable = ({ setPopupAddBooking }) => {
  return (
    <Cards containerClass="card-table !mr-0 !rounded-2xl !shadow-none !p-0">
      <div className="card-table-header">
        <Grid container spacing={2}>
          <Grid item md={4}>
            <Inputs
              id="test"
              placeholder="Search"
              customClass="!w-[245px]"
              iconPosition="left"
              icon={IconSearch}
            />
          </Grid>
          <Grid item md={8} className="flex justify-end items-center">
            <Buttons
              text="Tambah Booking"
              customClass="btn-primary btn-small !w-fit"
              onClick={() => setPopupAddBooking(true)}
            />
            <img
              className="ml-4 cursor-pointer w-4"
              src={IconSquare}
              alt="square"
            />
            <img
              className="ml-4 cursor-pointer w-4"
              src={IconActivity}
              alt="activity"
            />
          </Grid>
        </Grid>
      </div>
      <div className="divider"></div>
      <div className="px-4 py-2">
        <Tab
          defaultActiveKey="1"
          items={[
            { label: "Per-Booking", key: "1", children: <BookingTable /> },
            { label: "Semua Jamaah", key: "2", children: <Jamaah /> },
          ]}
        />
      </div>
    </Cards>
  );
};

export default React.memo(CardBookingTable);
