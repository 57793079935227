import React from "react";
import PropTypes from "prop-types";
import { Radio } from "antd";

import RadiosStyle from "./Radios.style";

const Radios = ({
  containerClass,
  customClass,
  label,
  options,
  onChange,
  defaultValue,
}) => {
  return (
    <div className="flex flex-row items-center">
      {label !== "" && <label className="mr-2">{label}</label>}
      <RadiosStyle className={`${containerClass}`}>
        <Radio.Group
          className={`${customClass}`}
          defaultValue={defaultValue ? defaultValue : options[0].value}
          buttonStyle="solid"
          onChange={onChange}
        >
          {options.map((val, idx) => {
            return (
              <Radio.Button
                key={idx}
                value={val.value}
                disabled={val.disabled === undefined ? false : val.disabled}
              >
                <div className="flex flex-row items-center">
                  {val.icon && <img src={val.icon} alt="radio-icon" />}
                  <span className="radio-value ml-2">{val.value}</span>
                </div>
              </Radio.Button>
            );
          })}
        </Radio.Group>
      </RadiosStyle>
    </div>
  );
};

Radios.propTypes = {
  containerClass: PropTypes.string,
  customClass: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func,
};

Radios.defaultProps = {
  containerClass: "",
  customClass: "",
  label: "",
  options: [],
  onChange: () => {},
};

export default React.memo(Radios);
