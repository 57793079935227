import React from "react";
import PropTypes from "prop-types";
import { DatePicker } from "antd";
import "./datepicker.scss";
import DatePickerIcon from "./icons/DatePickerIcon";
import dayjs from "dayjs";
import moment from "moment";

const { RangePicker } = DatePicker;

const CalendarIcon = <DatePickerIcon />;

const rangePresets = [
  { label: "Last 7 Days", value: [dayjs().add(-7, "d"), dayjs()] },
  { label: "Last 14 Days", value: [dayjs().add(-14, "d"), dayjs()] },
  { label: "Last 30 Days", value: [dayjs().add(-30, "d"), dayjs()] },
  { label: "Last 90 Days", value: [dayjs().add(-90, "d"), dayjs()] },
];

const DateRangePicker = ({
  containerClass,
  customClass,
  placeholder,
  value,
  onCalendarChange,
  onChange,
  showRangePresets,
  onBlur,
  id,
  name,
}) => {
  const onCalendarChangeHandler = (val) => {
    onCalendarChange(formatDateValue(val));
  };

  const onChangeHandler = (val) => {
    onChange(formatDateValue(val));
  };

  const formatDateValue = (val) => {
    return [
      moment(val[0]?.$d).format("YYYY-MM-DD"),
      moment(val[1]?.$d).format("YYYY-MM-DD"),
    ];
  };

  return (
    <div className={`${containerClass}`}>
      <RangePicker
        id={id}
        className={`${customClass}`}
        placeholder={[placeholder, ""]}
        popupClassName="popup-date-picker"
        onCalendarChange={onCalendarChangeHandler}
        onChange={onChangeHandler}
        presets={showRangePresets && rangePresets}
        suffixIcon={CalendarIcon}
        separator="/"
        defaultValue={
          value && value.length > 0
            ? [moment(value[0]), moment(value[1])]
            : null
        }
        format="YYYY-MM-DD"
        getPopupContainer={() => document.getElementById("area-datepicker")}
        name={name}
        onBlur={onBlur}
      />
      <div id="area-datepicker" className="custom-datepicker-open"></div>
    </div>
  );
};

DateRangePicker.propTypes = {
  containerClass: PropTypes.string,
  customClass: PropTypes.string,
  placeholder: PropTypes.string,
  onCalendarChange: PropTypes.func,
  onChange: PropTypes.func,
  showRangePresets: PropTypes.bool,
  onBlur: PropTypes.func,
};

DateRangePicker.defaultProps = {
  containerClass: "",
  customClass: "",
  placeholder: "",
  onCalendarChange: () => {},
  onChange: () => {},
  showRangePresets: false,
  onBlur: () => {},
};

export default DateRangePicker;
