import React from "react";

const FollowUpFour = () => {
  return (
    <div>
      <div></div>
    </div>
  );
};

export default React.memo(FollowUpFour);
