import React from "react";
import IL404 from "../../assets/images/404.jpg";

const UnauthorizedPage = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="text-center">
        <img src={IL404} alt="404" className="mb-[90px] w-[658px] h-[539px]" />
        <p className="text-[28px] text-black font-semibold">
          Maaf, halaman yang Anda cari tidak ditemukan.
        </p>
      </div>
    </div>
  );
};

export default UnauthorizedPage;