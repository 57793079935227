import React, { useState } from "react";
import CheckboxContainer from "./Checkbox.style";
import { Checkbox } from "antd";

function CheckboxMaster({ customClass, value, title, onChange, checked }) {
  return (
    <div className={customClass}>
      <CheckboxContainer>
        <Checkbox onChange={onChange} value={value} checked={checked}>
          {title}
        </Checkbox>
      </CheckboxContainer>
    </div>
  );
}

export default CheckboxMaster;
