import Buttons from "components/buttons";
import Inputs from "components/form/inputs";
import React from "react";

const SebagaiJamaah = ({ validation }) => {
  return (
    <>
      <Inputs
        name="nama_ayah"
        onChange={validation.handleChange}
        onBlur={validation.handleBlur}
        value={validation.values.nama_ayah}
        label="Nama Ayah"
        placeholder="Masukan Nama Ayah"
        error={
          validation.touched.nama_ayah &&
          validation.errors.nama_ayah &&
          validation.errors.nama_ayah
        }
      />
      <div className="w-full">
        <Buttons
          onClick={() => validation.handleSubmit()}
          // disabled={validation.values.foto === ""}
          text="Simpan"
          style={{ width: 150 }}
          customClass="btn-primary btn-medium mt-3 text-end"
        />
      </div>
    </>
  );
};

export default SebagaiJamaah;
