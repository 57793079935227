import React, { useState } from "react";

import MoslemeTable from "../../../../components/Table";

import { Edit, Trash } from "iconsax-react";

const AdditionalTable = () => {
  // Pagination state
  const [currentpage, setCurrentpage] = useState(1);
  const [size, setSize] = useState(10);
  const total = 100;

  const columns = [
    {
      title: "No",
      dataIndex: "index",
    },
    {
      title: "Nama Additional",
      dataIndex: "additional_name",
    },
    {
      title: "Jumlah",
      dataIndex: "total",
    },
    {
      title: "Catatan",
      dataIndex: "note",
    },
    {
      title: "Harga",
      dataIndex: "price",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: () => (
        <div className="flex flex-row gap-4">
          <Edit
            className="cursor-pointer"
            onClick={() => alert(`edit id:`)}
            size="15"
            color="#141414"
          />
          <Trash
            className="cursor-pointer"
            // onClick={() => setOpenPopupDeleteCabang(true)}
            size="15"
            color="#141414"
          />
        </div>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      index: "1",
      additional_name: "Buku Manasik",
      total: "1",
      note: "-",
      price: "Rp 50.000",
      action: "",
    },
    {
      key: "2",
      index: "2",
      additional_name: "Sepatu Haji",
      total: "1",
      note: "-",
      price: "Rp 200.000",
      action: "",
    },
    {
      key: "3",
      index: "3",
      additional_name: "Buku Manasik",
      total: "1",
      note: "-",
      price: "Rp 50.000",
      action: "",
    },
  ];

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: total,
    current: currentpage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20"],
  };

  // func to set size pagination
  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setSize(pageSize);
  };

  return (
    <MoslemeTable
      columns={columns}
      data={data}
      withSelection={false}
      selectionType={false}
      pagination={pagination}
      customPaginationClass="pr-4"
      customClass="mt-4"
      onPageChange={(page) => setCurrentpage(page)}
      onSizeChange={(currentpage, pageSize) =>
        onSizeChange(currentpage, pageSize)
      }
    />
  );
};

export default React.memo(AdditionalTable);
