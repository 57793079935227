import axios from "axios";
import { BASE_URL_ENDPOINT } from "constant/constanta";

const CalculatorRequestHargaAPI = {
  getList: (payload) =>
    axios.post(`${BASE_URL_ENDPOINT}/admin/get-request-paket`, payload),
  approveData: (payload) =>
    axios.post(`${BASE_URL_ENDPOINT}/admin/request-paket/approve`, payload),
  rejectData: (id) =>
    axios.get(`${BASE_URL_ENDPOINT}/admin/request-paket/reject/${id}`),
};

export { CalculatorRequestHargaAPI };
