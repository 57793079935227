import React, { useState } from "react";
import AlbumFoto from "./AlbumFoto";
import AlbumVideo from "./AlbumVideo";

const AlbumSelamaBeribadah = () => {
  return (
    <div className="flex flex-col items-start gap-[16px]">
      <AlbumFoto />
      <AlbumVideo />
    </div>
  );
};

export default AlbumSelamaBeribadah;
