import styled from "styled-components";

import BackgroundOranye from "../../../assets/images/bg-orange-shine.jpg";

const TagihanDetailStyle = styled.div`
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08), 0px 2px 8px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  padding: 16px;

  .custom-tab-jamaah {
    .ant-tabs-nav {
      width: max-content;
    }

    .ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container {
      border: 0.5px solid #bdbdbd;
      border-radius: 8px;
    }

    .ant-table-wrapper .ant-table-thead > tr > th {
      background: #ffffff;
    }

    .ant-table-wrapper
      .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > thead
      > tr
      > th,
    .ant-table-wrapper
      .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tbody
      > tr
      > td {
      border: none;
    }

    .ant-table-tbody .ant-table-row:last-child .ant-table-cell:first-child {
      border-bottom-left-radius: 7px;
    }

    .ant-table-tbody .ant-table-row:last-child .ant-table-cell:last-child {
      border-bottom-right-radius: 7px;
    }

    .ant-table-wrapper .ant-table-tbody > tr > td {
      padding: 8px;
    }
  }

  .card-detail-tagihan-header {
    padding: 16px;
    box-shadow: 0px 6px 64px -4px rgba(0, 0, 0, 0.04),
      0px 10px 16px rgba(0, 0, 0, 0.04), 0px 6px 32px rgba(0, 0, 0, 0.04);
    border-radius: 16px;
    background: white;

    .card-detail-tagihan-header-code {
      background: linear-gradient(
        34.99deg,
        #151515 17.46%,
        #3d3d3d 58.62%,
        #595959 87.32%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      font-weight: 600;
      font-size: 20px;
    }

    .card-detail-tagihan-header-divider {
      width: 1px;
      height: 24px;
      margin: 0 16px;
      background-color: #595959;
    }

    .card-detail-tagihan-header-date {
      font-size: 18px;
      background: linear-gradient(
        34.99deg,
        #151515 17.46%,
        #3d3d3d 58.62%,
        #595959 87.32%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;

      span {
        margin: 0 16px;
        font-size: 18px;
        background: linear-gradient(
          34.99deg,
          #151515 17.46%,
          #3d3d3d 58.62%,
          #595959 87.32%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
    }

    .card-detail-tagihan-header-yellow {
      background-image: url(${BackgroundOranye});
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      border-radius: 12px;
      padding: 12px 24px;

      .card-detail-tagihan-header-yellow-avatar {
        width: 56px;
        height: 56px;
        border: 1px solid #ffffff;
        border-radius: 48px;
      }

      .card-detail-tagihan-header-yellow-label {
        color: #ffffff;
        font-weight: 600;
        font-size: 16px;
      }

      .card-detail-tagihan-header-yellow-edit {
        background: white;
        border-radius: 8px;
        padding: 4px;
        box-shadow: 0px 4px 6px -4px rgba(0, 0, 0, 0.34),
          0px 6px 8px -2px rgba(0, 0, 0, 0.12),
          0px 2px 16px -2px rgba(0, 0, 0, 0.06);
        cursor: pointer;
      }

      .card-detail-tagihan-header-yellow-name {
        font-weight: 600;
        font-size: 20px;
        background: linear-gradient(187.8deg, #fffdc9 8.68%, #fff700 282.6%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }

      .card-detail-tagihan-header-yellow-wrapper {
        padding: 16px;
        border-radius: 12px;
        background: rgba(255, 255, 255, 0.2);

        .card-detail-tagihan-header-yellow-name {
          font-size: 24px;
          background: linear-gradient(187.8deg, #a09421 8.68%, #fff500 282.6%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
        }
      }
    }
  }

  .card-tagihan-jamaah-wrapper {
    background: #ffffff;
    box-shadow: 0px 10px 16px -6px rgba(0, 0, 0, 0.04),
      0px 4px 80px -4px rgba(0, 0, 0, 0.04);
    border-radius: 16px;
  }

  .card-tagihan-jamaah-header {
    border-bottom: 0.5px solid #e0e0e0;
    padding: 16px;

    .card-tagihan-jamaah-header-avatar {
      border-radius: 48px;
      width: 32px;
      height: 32px;
    }

    .card-tagihan-jamaah-header-name {
      font-weight: 600;
      font-size: 16px;
      margin-left: 16px;
      background: linear-gradient(
        34.99deg,
        #151515 17.46%,
        #3d3d3d 58.62%,
        #595959 87.32%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
  }

  .card-tagihan-jamaah-content {
    .card-tagihan-jamaah-content-wrapper {
      .card-tagihan-jamaah-content-wrapper-label {
        font-size: 16px;
        background: linear-gradient(
          34.99deg,
          #151515 17.46%,
          #3d3d3d 58.62%,
          #595959 87.32%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
      .card-tagihan-jamaah-content-wrapper-value {
        font-weight: 600;
        font-size: 20px;
        background: linear-gradient(204.44deg, #01f91a -18.49%, #359b05 84.72%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
    }
  }

  .card-tagihan-jamaah-footer {
    border-top: 0.5px solid #e0e0e0;
    .card-tagihan-jamaah-footer-wrapper {
      background: #faab36;
      backdrop-filter: blur(8px);
      border-radius: 12px;
      padding: 12px 24px;

      .card-tagihan-jamaah-footer-wrapper-label {
        color: #ffffff;
        font-weight: 600;
        font-size: 16px;
      }

      .card-tagihan-jamaah-footer-wrapper-name {
        font-weight: 600;
        font-size: 20px;
        background: linear-gradient(187.8deg, #fffdc9 8.68%, #fff700 282.6%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }
    }
  }

  .additional-header-container {
    border: 0.5px solid #bdbdbd;
    box-shadow: 0px 10px 16px -6px rgba(0, 0, 0, 0.04),
      0px 4px 80px -4px rgba(0, 0, 0, 0.04);
    border-radius: 16px;

    .additional-header-wrapper {
      background-image: url(${BackgroundOranye});
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      border-radius: 16px;
      padding: 16px;
      width: 100%;

      .additional-header-label {
        color: white;
      }

      .additional-header-price {
        font-weight: 600;
        font-size: 20px;
        background: linear-gradient(187.8deg, #fffdc9 8.68%, #fff700 282.6%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        margin-top: 4px;
      }
    }
  }

  .additional-content-container {
    background: #fbf3f2;
    backdrop-filter: blur(8px);
    border-radius: 16px;
    padding: 16px;
    margin-top: 16px;
    box-shadow: none;

    .container-dropdown-jamaah {
      padding: 16px;
      background: #f2f2f2;
      border-radius: 8px;
      margin-top: 4px;
      margin-bottom: 16px;
      display: flex;
      justify-content: flex-end;
    }
  }

  .payment-header-container {
    .payment-header-receiver {
      padding: 16px;
      background: #ffffff;
      box-shadow: 0px 10px 16px -6px rgba(0, 0, 0, 0.04),
        0px 4px 80px -4px rgba(0, 0, 0, 0.04);
      border-radius: 8px;
    }
  }
`;

export default TagihanDetailStyle;
