import React from "react";

import Buttons from "components/buttons";
import Inputs from "components/form/inputs";

import IconAlert from "assets/icons/Warning.svg";
import BNI from "assets/images/bni-bank.png";

const DaftarRequest = ({ setOpenPopup }) => {
  return (
    <>
      <div className="border border-[#E0E0E0] rounded-2xl">
        <div className="p-4">
          <div className="flex flex-row gap-4">
            <img src={IconAlert} alt="alert" />
            <div className="text-[#E82320]">
              Apakah anda yakin menggunakan akun ini sebagai pengirim
            </div>
          </div>

          <div className="flex flex-row items-center gap-4 mt-4 w-full">
            <Inputs
              placeholder="Masukkan nama akun"
              label="Nama Akun"
              containerClass="w-[45%]"
            />
            <Inputs
              placeholder="Masukkan Nomer Rekening"
              label="Nomer Rekening"
              containerClass="w-[45%]"
            />
            <img src={BNI} alt="bank" />
          </div>
        </div>
        <div className="divider"></div>
        <div className="flex flex-row justify-end gap-2 p-4">
          <Buttons
            text="Kembali"
            customClass="btn-outline btn-large"
            onClick={() => setOpenPopup(false)}
          />
          <Buttons text="Pilih Pengirim" customClass="btn-primary btn-large" />
        </div>
      </div>
    </>
  );
};

export default React.memo(DaftarRequest);
