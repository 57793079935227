import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import Buttons from "components/buttons";
import DatePicker from "components/datepicker/DatePicker";
import Inputs from "components/form/inputs";
import Switch from "components/form/switch";
import Textarea from "components/form/textarea";
import ImageField from "components/image_field";
import { saveDataMarketing } from "stores/actions/marketingKit";
import moment from "moment";

const MarketingPopup = ({ handleClosePopup }) => {
  const onDatePickerChange = (val) => {
    console.log(val);
  };

  const dispatch = useDispatch();

  const { dataUpload, dataMultiple } = useSelector((state) => state.uploadFile);
  console.log(dataMultiple, "multiple");
  console.log(dataUpload, "upload");

  const saveData = () => {
    const payload = {
      title: formikValidation.values.title,
      period_start: "2023-01-01",
      period_end: "2023-01-30",
      thumbnail_url: dataUpload?.find(
        (file) => file.name === "thumbnailMarketingKit"
      ).path,
      decription: formikValidation.values.deskripsi,
      gallery_url: dataMultiple
        ?.filter((file) => file.name === "attachment")
        .map((item) => item.path),
    };
    dispatch(saveDataMarketing(payload));
    handleClosePopup();
  };

  const { isLoading } = useSelector((state) => state.marketingKit);

  const formikValidation = useFormik({
    initialValues: {
      title: "",
      jadwal_keberangkatan: "",
      deskripsi: "",
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required("Judul Wajib diisi"),
      jadwal_keberangkatan: Yup.string().required(
        "Jadwal Keberangkatan Wajib Diisi"
      ),
      deskripsi: Yup.string().required("Deskripsi Wajib Diisi"),
    }),
    onSubmit: saveData,
  });

  console.log(formikValidation.initialValues, "formik");
  console.log(formikValidation.values, "valuesformik");

  return (
    <>
      <div className="border rounded-lg w-full">
        <div className="w-full h-[476px] overflow-auto p-[20px]">
          <div className="flex flex-col items-start gap-[16px]">
            <div className="flex flex-row items-center gap-[8px]">
              <Switch customClass="!w-[52px] !h-[20px]" />
              <h1 className="text-sm text-[#141414]">Periode Aktif</h1>
            </div>
            <div className="flex flex-row justify-start items-start gap-[16px]">
              <Inputs
                id="title"
                name="title"
                label="Judul"
                placeholder="Masukan judul"
                containerClass="w-full"
                customClass="!w-[451px]"
                value={formikValidation.values.title}
                error={formikValidation.errors.title}
                onChange={formikValidation.handleChange}
              />
              <div>
                <div class="input-label">Jadwal Keberangkatan</div>
                <DatePicker
                  placeholder="Pilih Tanggal"
                  containerClass="mt-2"
                  customClass="w-[401px]"
                  name="jadwal_keberangkatan"
                  onChange={(evt) => console.log(evt)}
                  value={formikValidation.values.jadwal_keberangkatan}
                />
              </div>
            </div>
            <div className="flex flex-row items-start gap-[16px]">
              <div>
                <ImageField
                  error={false}
                  isMulti={false}
                  setFile={(file) => console.log("image", file?.path)}
                  name="thumbnailMarketingKit"
                  label="Thumbnail"
                  customContainer="cursor-pointer !w-[451px] h-[200px]"
                />
              </div>
              <div>
                <Textarea
                  placeholder="Deskripsi"
                  id="deskripsi"
                  name="deskripsi"
                  rows="5"
                  label="Deskripsi"
                  customClass="!w-[401px] !h-[200px]"
                  onChange={formikValidation.handleChange}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start gap-[8px]">
            <div>
              <ImageField
                error={false}
                isMulti={true}
                name="attachment"
                setFile={(file) => console.log("image", file?.path)}
                label="Attachment"
                customContainer="cursor-pointer !w-[870px] h-[200px]"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-end border-t rounded-lg py-[8px] px-[20px]">
          <div className="flex flex-row justify-end gap-[8px]">
            <Buttons
              text="Kembali"
              customClass="btn-outline btn-medium !w-[186px] float-right"
              onClick={handleClosePopup}
            />
            <Buttons
              text="Tambah"
              customClass="btn-primary btn-medium !w-[186px] float-right"
              onClick={saveData}
              loading={isLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketingPopup;
