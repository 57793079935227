/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import PropType from "prop-types";
import Inputs from "components/form/inputs";
import Selects from "components/form/selects";

import IconTrash from "assets/icons/trash.svg";
import IconPlane from "assets/icons/Penerbangan-aktif.svg";
import { debounce } from "lodash";
import IconAddCircle from "assets/icons/add-circle.svg";
import Buttons from "components/buttons";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import PaketDetailGeneralInfoAction, {
  syaratKetentuan,
} from "stores/actions/paket/generalInfo";
import { useParams } from "react-router-dom";
import { detailById } from "stores/actions/general";
import { handleSuccess } from "stores/actions/errorGeneral";
import AlertPopup from "components/popup/alert";
import { Skeleton } from "antd";

const SyaratKetentuan = (data, closePopup, setSuccessEdit) => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const { byDetail } = useSelector((state) => state.general);
  const [input, setInput] = useState("");

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    setFieldTouched,
    setFieldError,
  } = useFormik({
    initialValues: {
      tgl_batas_pendaftaran: data?.data?.tgl_batas_pendaftaran,
      tgl_batas_pengumpulan_berkas: data?.data?.tgl_batas_pengumpulan_berkas,
      tgl_batas_dp: data?.data?.tgl_batas_dp,
      tgl_batas_pelunasan: data?.data?.tgl_batas_pelunasan,
      dp: data?.data?.dp,
    },
    validationSchema: Yup.object().shape({
      tgl_batas_pendaftaran: Yup.number().required(
        "Batas Pendaftaran wajib diisi."
      ),
      tgl_batas_pengumpulan_berkas: Yup.number().required(
        "Batas Pengumpulan wajib dipilih."
      ),
      tgl_batas_dp: Yup.string().required("Batas dp wajib dipilih."),
      tgl_batas_pelunasan: Yup.number().required(
        "Batas Pelunasan wajib diisi."
      ),
      dp: Yup.number().required("DP wajib diisi."),
    }),
    onSubmit: async (values, actions) => {
      values.id_paket = id;
      const status = await dispatch(syaratKetentuan.updateTgl(values));

      if (status === 200) {
        dispatch(PaketDetailGeneralInfoAction.fetchData(id));
        actions.resetForm();
        dispatch(detailById({}));
      }
    },
  });

  const [options, setOptions] = useState([
    {
      value: "1x24jam",
      label: "1x24jam",
    },
    {
      value: "2x24jam",
      label: "2x24jam",
    },
    {
      value: "3x24jam",
      label: "3x24jam",
    },
    {
      value: "4x24jam",
      label: "4x24jam",
    },
    {
      value: "5x24jam",
      label: "5x24jam",
    },
  ]);
  const [syarat, setSyarat] = useState(data?.data?.syarat);
  const [isDeleteItem, setIsDeleteItem] = useState(false);
  const [inputSyarat, setInputSayarat] = useState("");
  const [inputHargaSudah, setInputHargaSudah] = useState("");
  const [inputHargaBelum, setInputHargaBelum] = useState("");
  const [inputKetntuanPembatalan, setInputKetentuanPembatalan] = useState("");
  const [dataChoice, setDataChoice] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingAction, setIsloadingAction] = useState(false);

  useEffect(() => {
    setSyarat(data?.data?.syarat);
  }, [data?.data?.syarat]);
  console.log("syarat:", syarat);
  console.log("byDetail:", byDetail);

  const addSyaratNew = (type, data) => {
    setIsloadingAction(true);
    dispatch(
      syaratKetentuan.createOrUpdateData(
        {
          id_paket: id,
          icon_type: "icon",
          type: type,
          item: data,
        },
        (message) => {
          dispatch(handleSuccess(message));
          setIsloadingAction(false);
          setInputSayarat("");
          setInputHargaSudah("");
          setInputHargaBelum("");
          setInputKetentuanPembatalan("");

          dispatch(PaketDetailGeneralInfoAction.fetchData(id));
        },
        (error) => {
          setIsloadingAction(false);
        }
      )
    );
  };

  const onHandleRemove = (data, idx = 1) => {
    setIsLoading(true);
    dispatch(
      syaratKetentuan.delete(
        data.id,
        (message) => {
          setIsDeleteItem(false);
          setIsLoading(false);

          dispatch(handleSuccess(message));
          dispatch(PaketDetailGeneralInfoAction.fetchData(id));
        },
        (errror) => {
          setIsLoading(false);
        }
      )
    );
  };

  const handleUpdateItemAll = (e, data, type) => {
    setIsloadingAction(true);
    dispatch(
      syaratKetentuan.createOrUpdateData(
        {
          id_paket: id,
          icon_type: "icon",
          type: type,
          item: e?.target?.value,
          id: data?.id,
        },
        (message) => {
          setIsloadingAction(false);
          dispatch(PaketDetailGeneralInfoAction.fetchData(id));
        },
        (error) => {
          setIsloadingAction(false);
        }
      )
    );
  };

  const handleClosePopUp = () => {
    setIsDeleteItem(false);
  };

  return (
    <>
      {isLoadingAction && <Skeleton />}
      {/* AlertPopup */}
      <AlertPopup
        open={isDeleteItem}
        handleClose={handleClosePopUp}
        handleContinue={() => onHandleRemove(dataChoice)}
        title="Hapus Data?"
        subtitle={`Apakah kamu yakin ingin menghapus data ini ?`}
        loading={isLoading}
      />
      <div className="border border-[#E0E0E0] rounded-2xl p-4">
        <div className="font-semibold">Keterangan Batas Waktu</div>
        <div className="grid grid-cols-4 gap-4 mt-4">
          <Inputs
            label="Batas Pendaftaran"
            placeholder="Masukkan jumlah hari"
            id="tgl_batas_pendaftaran"
            value={values.tgl_batas_pendaftaran}
            onBlur={handleBlur}
            error={
              touched.tgl_batas_pendaftaran &&
              errors.tgl_batas_pendaftaran &&
              errors.tgl_batas_pendaftaran
            }
            customClass={
              touched.tgl_batas_pendaftaran &&
              errors.tgl_batas_pendaftaran &&
              "input-error"
            }
            onChange={handleChange}
          />
          <Inputs
            label="Batas Pengumpulan Berkas"
            placeholder="Masukkan jumlah hari"
            id="tgl_batas_pengumpulan_berkas"
            value={values.tgl_batas_pengumpulan_berkas}
            onBlur={handleBlur}
            error={
              touched.tgl_batas_pengumpulan_berkas &&
              errors.tgl_batas_pengumpulan_berkas &&
              errors.tgl_batas_pengumpulan_berkas
            }
            customClass={
              touched.tgl_batas_pengumpulan_berkas &&
              errors.tgl_batas_pengumpulan_berkas &&
              "input-error"
            }
            onChange={handleChange}
          />
          <Selects
            label="Batas DP"
            placeholder="Pilih waktu"
            customClass="!py-3 !min-h-[46px]"
            options={options}
            onChange={(e) => {
              setFieldValue("tgl_batas_dp", e[0].value);
              setFieldError("tgl_batas_dp", "", true);
            }}
            value={[
              {
                value: `${
                  data?.data?.tgl_batas_dp
                    ? data?.data?.tgl_batas_dp
                    : "1x24jam"
                }`,
                label: `${
                  data?.data?.tgl_batas_dp
                    ? data?.data?.tgl_batas_dp
                    : "1x24jam"
                }`,
              },
            ]}
            error={
              touched.tgl_batas_dp && errors.tgl_batas_dp && errors.tgl_batas_dp
            }
            onDropdownClose={() => {
              setFieldTouched("tgl_batas_dp", true, false);
            }}
          />
          <Inputs
            label="Batas Pelunasan"
            placeholder="Masukkan jumlah hari"
            id="tgl_batas_pelunasan"
            value={values.tgl_batas_pelunasan}
            onBlur={handleBlur}
            error={
              touched.tgl_batas_pelunasan &&
              errors.tgl_batas_pelunasan &&
              errors.tgl_batas_pelunasan
            }
            customClass={
              touched.tgl_batas_pelunasan &&
              errors.tgl_batas_pelunasan &&
              "input-error"
            }
            onChange={handleChange}
          />
        </div>
        <Inputs
          label="Minimal DP"
          placeholder="Masukkan nominal"
          containerClass="mt-4"
          customClass="!pl-[42px]"
          currency
          id="dp"
          value={values.dp}
          onBlur={handleBlur}
          error={touched.dp && errors.dp && errors.dp}
          onChange={handleChange}
        />
      </div>
      {byDetail.showAll ? (
        <>
          <div className="border border-[#E0E0E0] rounded-2xl p-4 mt-4">
            <div className="font-semibold">Syarat-syarat Pendaftaran</div>
            {syarat
              ?.filter((item) => item.type === 1)
              ?.map((val, idx) => (
                <div key={idx} className="mt-4">
                  <div className="flex flex-row justify-between items-center">
                    <div className="flex flex-row gap-2 flex-1">
                      <span className="font-semibold text-[14px] text-[#000] mr-[8px]">
                        {idx + 1}
                      </span>
                      <input
                        placeholder="Masukan list"
                        className="flex-1 outline-none w-full"
                        defaultValue={val.item}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleUpdateItemAll(e, val, 1);
                          }
                        }}
                      />
                    </div>
                    <img
                      src={IconTrash}
                      alt="trash"
                      className="cursor-pointer"
                      onClick={() => {
                        setDataChoice(val);
                        setIsDeleteItem(true);
                      }}
                    />
                  </div>
                  <div className="divider mt-2"></div>
                </div>
              ))}

            <div className="flex flex-row justify-between items-center mt-3 border-b pb-2">
              <div className="flex flex-row gap-2 flex-1">
                <div className="border font-semibold border-black rounded-full w-5 h-5 flex justify-center items-center">
                  +
                </div>
                <input
                  placeholder="Masukan list"
                  className="flex-1 outline-none w-full"
                  onChange={(e) => setInputSayarat(e.target.value)}
                  value={inputSyarat}
                />
              </div>
            </div>
            <Buttons
              loading={inputSyarat !== "" ? isLoadingAction : false}
              text="Tambah List"
              customClass="btn-outline btn-small mt-4 !w-fit"
              onClick={() => addSyaratNew(1, inputSyarat)}
            />
          </div>

          <div className="border border-[#E0E0E0] rounded-2xl p-4 mt-4">
            <div className="font-semibold">Harga Sudah Termasuk</div>
            {syarat
              ?.filter((item) => item.type === 2)
              ?.map((val, idx) => (
                <div key={idx} className="mt-4">
                  <div className="flex flex-row justify-between items-center">
                    <div className="flex flex-row gap-2 flex-1">
                      <span className="font-semibold text-[14px] text-[#000] mr-[8px]">
                        {idx + 1}
                      </span>
                      <input
                        placeholder="Masukan list"
                        className="flex-1 outline-none w-full"
                        defaultValue={val.item}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleUpdateItemAll(e, val, 2);
                          }
                        }}
                      />
                    </div>
                    <img
                      src={IconTrash}
                      alt="trash"
                      className="cursor-pointer"
                      onClick={() => {
                        setDataChoice(val);
                        setIsDeleteItem(true);
                      }}
                    />
                  </div>
                  <div className="divider mt-2"></div>
                </div>
              ))}
            <div className="flex flex-row justify-between items-center mt-3 border-b pb-2">
              <div className="flex flex-row gap-2 flex-1">
                <div className="border font-semibold border-black rounded-full w-5 h-5 flex justify-center items-center">
                  +
                </div>
                <input
                  placeholder="Masukan list"
                  className="flex-1 outline-none w-full"
                  onChange={(e) => setInputHargaSudah(e.target.value)}
                  value={inputHargaSudah}
                />
              </div>
            </div>
            <Buttons
              loading={inputHargaSudah !== "" ? isLoadingAction : false}
              text="Tambah List"
              customClass="btn-outline btn-small mt-4 !w-fit"
              onClick={() => addSyaratNew(2, inputHargaSudah)}
            />
          </div>

          <div className="border border-[#E0E0E0] rounded-2xl p-4 mt-4">
            <div className="font-semibold">Harga Belum Termasuk</div>
            {syarat
              ?.filter((item) => item.type === 3)
              ?.map((val, idx) => (
                <div key={idx} className="mt-4">
                  <div className="flex flex-row justify-between items-center">
                    <div className="flex flex-row gap-2 flex-1">
                      <span className="font-semibold text-[14px] text-[#000] mr-[8px]">
                        {idx + 1}
                      </span>
                      <input
                        placeholder="Masukan list"
                        className="flex-1 outline-none w-full"
                        defaultValue={val.item}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleUpdateItemAll(e, val, 3);
                          }
                        }}
                      />
                    </div>
                    <img
                      src={IconTrash}
                      alt="trash"
                      className="cursor-pointer"
                      onClick={() => {
                        setDataChoice(val);
                        setIsDeleteItem(true);
                      }}
                    />
                  </div>
                  <div className="divider mt-2"></div>
                </div>
              ))}
            <div className="flex flex-row justify-between items-center mt-3 border-b pb-2">
              <div className="flex flex-row gap-2 flex-1">
                <div className="border font-semibold border-black rounded-full w-5 h-5 flex justify-center items-center  flex-row">
                  +
                </div>
                <input
                  placeholder="Masukan list"
                  className="flex-1 outline-none w-full"
                  onChange={(e) => setInputHargaBelum(e.target.value)}
                  value={inputHargaBelum}
                />
              </div>
            </div>
            <Buttons
              text="Tambah List"
              loading={inputHargaBelum !== "" ? isLoadingAction : false}
              customClass="btn-outline btn-small mt-4 !w-fit"
              onClick={() => addSyaratNew(3, inputHargaBelum)}
            />
          </div>
          <div className="border border-[#E0E0E0] rounded-2xl p-4 mt-4">
            <div className="font-semibold">Ketentuan Pembatalan</div>
            {syarat
              ?.filter((item) => item.type === 4)
              ?.map((val, idx) => (
                <div key={idx} className="mt-4">
                  <div className="flex flex-row justify-between items-center">
                    <div className="flex flex-row gap-2 flex-1">
                      <span className="font-semibold text-[14px] text-[#000] mr-[8px]">
                        {idx + 1}
                      </span>
                      <input
                        placeholder="Masukan list"
                        className="flex-1 outline-none w-full"
                        defaultValue={val.item}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleUpdateItemAll(e, val, 3);
                          }
                        }}
                      />
                    </div>
                    <img
                      src={IconTrash}
                      alt="trash"
                      className="cursor-pointer"
                      onClick={() => {
                        setDataChoice(val);
                        setIsDeleteItem(true);
                      }}
                    />
                  </div>
                  <div className="divider mt-2"></div>
                </div>
              ))}
            <div className="flex flex-row justify-between items-center mt-3 border-b pb-2">
              <div className="flex flex-row gap-2 flex-1">
                <div className="border font-semibold border-black rounded-full w-5 h-5 flex justify-center items-center flex-row">
                  +
                </div>
                <input
                  placeholder="Masukan list"
                  className="flex-1 outline-none w-full"
                  onChange={(e) => setInputKetentuanPembatalan(e.target.value)}
                  value={inputKetntuanPembatalan}
                />
              </div>
            </div>
            <Buttons
              loading={inputKetntuanPembatalan !== "" ? isLoadingAction : false}
              text="Tambah List"
              customClass="btn-outline btn-small mt-4 !w-fit"
              onClick={() => addSyaratNew(4, inputKetntuanPembatalan)}
            />
          </div>
        </>
      ) : (
        <>
          <div className="border border-[#E0E0E0] rounded-2xl p-4 mt-4">
            <div className="font-semibold">
              {byDetail?.type === 2
                ? "Harga Sudah Termasuk"
                : byDetail?.type === 3
                ? "Harga Belum Termasuk"
                : byDetail?.type === 4
                ? "Ketentuan Pembatalan"
                : "Syarat-syarat Pendaftaran"}
            </div>
            {syarat
              ?.filter((nil) => nil.type === byDetail?.type)
              ?.map((item, idx) => {
                return (
                  <div
                    key={`${byDetail?.type}-${idx}-${item.id}`}
                    className="mt-4"
                  >
                    <div className="flex flex-row justify-between items-center">
                      <div className="flex flex-row gap-2 flex-1">
                        <span className="font-semibold text-[14px] text-[#000] mr-[8px]">
                          {idx + 1}
                        </span>
                        <input
                          placeholder="Masukan list"
                          className="flex-1 outline-none w-full"
                          defaultValue={item.item}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleUpdateItemAll(e, item, byDetail?.type);
                            }
                          }}
                        />
                      </div>
                      <img
                        src={IconTrash}
                        alt="trash"
                        className="cursor-pointer"
                        onClick={() => {
                          setDataChoice(item);
                          setIsDeleteItem(true);
                        }}
                      />
                    </div>
                    <div className="divider mt-2"></div>
                  </div>
                );
              })}
            <div className="flex flex-row justify-between items-center mt-3 border-b pb-2">
              <div className="flex flex-row gap-2 flex-1">
                <div className="border border-black rounded-full w-5 h-5 flex justify-center items-center">
                  +
                </div>
                <input
                  placeholder="Masukan list"
                  className="flex-1 outline-none w-full"
                  onChange={(e) => setInput(e.target.value)}
                  value={input}
                />
              </div>
            </div>
            <Buttons
              loading={input !== "" ? isLoadingAction : false}
              text="Tambah List"
              customClass="btn-outline btn-small mt-4 !w-fit"
              onClick={() => addSyaratNew(byDetail?.type, input)}
            />
          </div>
        </>
      )}

      <div className="flex flex-col justify-end border-t rounded-lg py-[16px] px-[20px]">
        <Buttons
          text="Simpan"
          customClass={`btn-primary btn-medium !w-[186px] float-right ${
            isSubmitting && "loading"
          }`}
          loading={isSubmitting}
          onClick={() => handleSubmit()}
        />
      </div>
    </>
  );
};

SyaratKetentuan.propTypes = {
  data: PropType.object,
  closePopup: PropType.func,
  setSuccessEdit: PropType.func,
};
export default SyaratKetentuan;
