import React, { useState } from "react";

import AntdSelect from "components/form/antdSelect";
import InputsCurrency from "components/form/inputsCurrency";
import Buttons from "components/buttons";

const PopupWithdrawFee = () => {
  const [options, setOptions] = useState([
    {
      value: "jack",
      label: "Jack",
    },
    {
      value: "lucy",
      label: "Lucy",
    },
    {
      value: "tom",
      label: "Tom",
    },
  ]);

  const [optionsCurrency, setOptionsCurrency] = useState([
    {
      value: "idr",
      label: "IDR",
    },
    {
      value: "dollar",
      label: "USD",
    },
  ]);

  return (
    <>
      <div className="bg-white soft-shadow rounded-2xl p-4">
        <AntdSelect
          label="Bank"
          placeholder="Select Bank"
          options={options}
          onChange={(e, opt) => {}}
        >
          {options.map((val, idx) => {
            return (
              <div value={val.value} data={val} label={val.label} key={idx}>
                <div className={`flex flex-row items-center justify-between`}>
                  <div className="flex flex-col gap-1">
                    <div>BCA</div>
                    <div className="font-semibold text-[16px]">7564347652</div>
                  </div>
                  <div>{val.label}</div>
                </div>
              </div>
            );
          })}
        </AntdSelect>
        <div className="flex flex-row items-center gap-4 mt-4">
          <AntdSelect
            label="Mata Uang"
            containerClass="!w-[20%]"
            placeholder="Select Currency"
            options={optionsCurrency}
            onChange={(e, opt) => {}}
          >
            {optionsCurrency.map((val, idx) => {
              return (
                <div value={val.value} data={val} label={val.label} key={idx}>
                  {val.label}
                </div>
              );
            })}
          </AntdSelect>
          <InputsCurrency
            label="Nominal"
            placeholder="Masukkan nominal"
            containerClass="!w-[80%]"
          />
        </div>
        <Buttons text="Withdraw" customClass="btn-primary btn-medium mt-4" />
      </div>
    </>
  );
};

export default React.memo(PopupWithdrawFee);
