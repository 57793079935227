export const APP_GOOGLE_MAP = process.env.REACT_APP_MY_GOOGLE_MAP;
export const BASE_URL_ENDPOINT = process.env.REACT_APP_MY_BASE_URL;
export const BASE_URL_ENDPOINT_V2 = process.env.REACT_APP_MY_BASE_URL_V2;
export const LOCATION_NOW = "Gunakan Lokasi Sekarang";
export const IMAGE = "s3-ap-southeast-1.amazonaws.com";
export const ADDRSESS_NOW =
  "Yogyakarta, Yogyakarta City, Special Region of Yogyakarta, Indonesia";
export const EMAIL_REGEX =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
export const TRAVEL_SHORT_NAME = process.env.REACT_APP_SHORT_NAME
export const TRAVEL_LONG_NAME = process.env.REACT_APP_LONG_NAME