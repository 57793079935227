// dokumen Actions
// --------------------------------------------------------

import { handleError, handleErrorBE } from "stores/actions/errorGeneral";
import { setDataPerlengkapan } from "../perlengkapan";
import { getAllDokumentasiService } from "utils/api/paket/dokumentasi";

/* eslint-disable space-before-function-paren */
export const SET_LOADING = "dokumen/SET_LOADING";
export const CLEAR_ERROR = "dokumen/CLEAR_ERROR";
export const SET_ERROR = "dokumen/SET_ERROR";
export const INIT_DATA = "dokumen/INIT_DATA";
export const SET_DOUBLE_SUBMIT = "dokumen/SET_DOUBLE_SUBMIT";
export const SET_DATA = "dokumen/SET_DATA";

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const setDoubleSubmit = (payload) => ({
  type: SET_DOUBLE_SUBMIT,
  payload,
});

export const handleGetAllDokumen = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await getAllDokumentasiService(payload);
    dispatch(setLoading(false));
    const { status, data } = response;
    const resultMap = data?.data?.map((item, index) => {
      return {
        key: index,
        name: "John Brown",
        panggilan: "tuan",
        foto: item.logo,
        jenisKelamin: "laki laki",
        starting: "BPN - CGK",
        cabang: "Balikpapan",
        perlengkapan: item.perlengkapan,
      };
    });

    if (status === 200) {
      dispatch(setDataPerlengkapan(resultMap));
    } else {
      dispatch(handleErrorBE(response));
    }
  } catch (error) {
    dispatch(handleError(error));
  }
};
