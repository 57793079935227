import React, { useState } from 'react';
import Checkbox from '.';

function CheckboxDocs() {
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  return (
    <div>
      <Checkbox
        indeterminate={indeterminate}
        checked={checked}
        onChange={(e) => {
          setChecked(e.target.checked);
          setIndeterminate(false);
        }}
      >
        Checkbox
      </Checkbox>
      <Checkbox checked={indeterminate} onChange={(e) => setIndeterminate(e.target.checked)}>
        indeterminate
      </Checkbox>
    </div>
  );
}

export default CheckboxDocs;
