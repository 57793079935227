import React, { useEffect } from "react";
import ICUploadFile from "../../assets/icons/upload_file.svg";
import ICCloseUpload from "../../assets/icons/close_upload.svg";
import { FileUploader } from "react-drag-drop-files";
import { CloseCircle } from "iconsax-react";
import PropTypes from "prop-types";
import style from "./index.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
    setDataUpload,
    setDataUploadMulti,
    uploadFileSubmite,
} from "../../stores/actions/uploadFile";
import uuid from "react-uuid";

const fileTypes = ["PDF"];

function FileField(props) {
    const dispatch = useDispatch();
    const dataUpload = useSelector(({ uploadFile }) => uploadFile.dataUpload);
    const dataUploadMulti = useSelector(
        ({ uploadFile }) => uploadFile.dataMultiple
    );
    const {
        setFile,
        isMulti,
        label,
        error,
        errorMesssage,
        customFileContainer,
        customWrapper,
        customContainer,
        customPlaceholder,
        name,
        onBlur,
        onRemove,
    } = props;

    const removeFile = (id, name) => {
        let data = isMulti
            ? dataUploadMulti.filter((v) => v.name === name)
            : dataUpload;
        let data2 = dataUploadMulti.filter((v) => v.name !== name);

        let image = data.filter((value) => value.id !== id);

        console.log(image);
        console.log(data2);
        console.log("id, name", id, name);

        if (isMulti) {
            dispatch(setDataUploadMulti([...image, ...data2]));
        } else {
            dispatch(setDataUpload(image));
        }
    };

    const handleChange = (e) => {
        const validFileFiles = [...dataUpload];
        const files = e;
        validFileFiles.push(files);

        dispatch(
            uploadFileSubmite(
                [files],
                name,
                uuid(),
                isMulti ? "multiple" : "single"
            )
        );
    };

    useEffect(() => {
        setFile(dataUpload.find((data) => data.name === name));
    }, [dataUpload, name]);

    const fileRender = isMulti ? dataUploadMulti : dataUpload;
    const fileCheck = fileRender?.find((v) => v?.name === name);
    const isMultiEmpty =
        fileRender.length !== 0
            ? Object.keys(fileCheck ? fileCheck : {})?.length > 0
            : false;

    return (
        <>
            <label className="font-semibold">{label}</label>
            <div
                className={`${style.container} ${
                    error && style.error
                } ${customContainer}`}
            >
                {isMulti ? (
                    <div
                        className={`${style.dashWrapper} ${style.scrollStyle} ${customWrapper}`}
                    >
                        {!isMultiEmpty ? (
                            <FileUploader
                                onBlur={onBlur}
                                multiple={isMulti}
                                handleChange={handleChange}
                                name="fileSingle"
                                types={fileTypes}
                                hoverTitle=" "
                            >
                                <div className={style.uploadContainer}>
                                    {customPlaceholder ? (
                                        customPlaceholder
                                    ) : (
                                        <>
                                            <img
                                                className="w-10"
                                                src={ICUploadFile}
                                                alt="upload"
                                            />

                                            <p>
                                                Drag & drop or
                                                <span className="underline ml-1">
                                                    <label htmlFor="upload">
                                                        browse
                                                    </label>
                                                </span>
                                            </p>
                                        </>
                                    )}
                                </div>
                            </FileUploader>
                        ) : (
                            <>
                                {isMulti && (
                                    <FileUploader
                                        onBlur={onBlur}
                                        multiple={isMulti}
                                        handleChange={handleChange}
                                        name="fileMulti"
                                        types={fileTypes}
                                        hoverTitle=" "
                                    >
                                        <img
                                            className="w-10 mx-auto"
                                            src={ICUploadFile}
                                            alt="upload"
                                        />
                                        <p>
                                            Drag & drop or
                                            <span className="underline ml-1">
                                                <label htmlFor="upload">
                                                    browse
                                                </label>
                                            </span>
                                        </p>
                                    </FileUploader>
                                )}
                            </>
                        )}
                    </div>
                ) : fileRender.find((image) => image.name === name) ? (
                    <div
                        className={`${style.dashWrapper} ${style.scrollStyle} ${customWrapper}`}
                    ></div>
                ) : (
                    <div
                        className={`${style.dashWrapper} ${style.scrollStyle} ${customWrapper}`}
                    >
                        <FileUploader
                            onBlur={onBlur}
                            multiple={isMulti}
                            handleChange={handleChange}
                            name="fileSingle"
                            types={fileTypes}
                            hoverTitle=" "
                        >
                            <div className={style.uploadContainer}>
                                {customPlaceholder ? (
                                    customPlaceholder
                                ) : (
                                    <>
                                        <img
                                            className="w-10"
                                            src={ICUploadFile}
                                            alt="upload"
                                        />

                                        <p>
                                            Drag & drop or
                                            <span className="underline ml-1">
                                                <label htmlFor="upload">
                                                    browse
                                                </label>
                                            </span>
                                        </p>
                                    </>
                                )}
                            </div>
                        </FileUploader>
                    </div>
                )}
            </div>

            {error && (
                <div className={style.errorMessage}>
                    <CloseCircle size={17} />
                    <p className="ml-1">{errorMesssage}</p>
                </div>
            )}
            <input className="hidden" type="file" id="upload" />
            {isMulti ? (
                <>
                    {fileRender
                        .filter((v) => v.name === name)
                        .map((item) => {
                            return (
                                <div className="flex" key={item.id}>
                                    <div className="relative mb-2">
                                        <div className="text-sm w-fit">
                                            <a
                                                href={item.path}
                                                className="block"
                                            >
                                                {item.fileName}
                                            </a>
                                        </div>
                                        <button
                                            onClick={() =>
                                                removeFile(item.id, item.name)
                                            }
                                            className="absolute cursor-pointer -right-5 top-0"
                                        >
                                            <img
                                                onClick={onRemove}
                                                src={ICCloseUpload}
                                                alt="src"
                                            />
                                        </button>
                                    </div>
                                </div>
                            );
                        })}
                </>
            ) : (
                <>
                    {fileRender.find((image) => image.name === name) && (
                        <div className="flex flex-col gap-2">
                            {fileRender && fileRender.length && (
                                <div className="flex">
                                    <div className="relative mb-2">
                                        <div className="text-sm w-fit">
                                            <a
                                                href={
                                                    fileRender.find(
                                                        (image) =>
                                                            image.name === name
                                                    ).path
                                                }
                                                className="block"
                                            >
                                                {
                                                    fileRender.find(
                                                        (image) =>
                                                            image.name === name
                                                    ).fileName
                                                }
                                            </a>
                                        </div>
                                        <button
                                            onClick={() =>
                                                removeFile(
                                                    fileRender.find(
                                                        (image) =>
                                                            image.name === name
                                                    ).id,
                                                    fileRender.find(
                                                        (image) =>
                                                            image.name === name
                                                    ).name
                                                )
                                            }
                                            className="absolute cursor-pointer -right-5 top-0"
                                        >
                                            <img
                                                onClick={onRemove}
                                                src={ICCloseUpload}
                                                alt="src"
                                            />
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </>
            )}
        </>
    );
}

FileField.propTypes = {
    label: PropTypes.string,
    setFile: PropTypes.func,
    onBlur: PropTypes.func,
    isMulti: PropTypes.bool,
    errorMesssage: PropTypes.string,
    error: PropTypes.bool,
    customPlaceholder: PropTypes.any,
    customContainer: PropTypes.string,
    customWrapper: PropTypes.string,
    customFileContainer: PropTypes.string,
    name: PropTypes.string,
    onRemove: PropTypes.func,
};

FileField.defaultProps = {
    isMulti: false,
    error: false,
    errorMesssage: "This field is required",
    label: "File field label",
};

export default FileField;
