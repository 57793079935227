import React, { useState } from "react";
import Badge from "components/badge";
import Buttons from "components/buttons";
import Switch from "components/form/switch";
import Check from "../../components/Generalinfo/check";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { saveDataPerlengkapan } from "stores/actions/paket/perlengkapan";

const useStyle = makeStyles({
  switchContainer: {
    "& .switch-background": {
      "& .ant-switch.ant-switch-checked": {
        backgroundColor:
          "linear-gradient(73.4deg, #E82320 20.62%, #FA504D 136.74%) !important",
      },

      "& .ant-switch-checked": {
        backgroundColor:
          "linear-gradient(73.4deg, #E82320 20.62%, #FA504D 136.74%) !important",
      },
    },
  },
});

const PerlengkapanCard = ({
  label,
  backgroundColor,
  color,
  switchValue,
  setSwitchValue,
  setPaketId,
  paketId,
}) => {
  const handleChange = (checked) => {
    setSwitchValue(checked);
    setPaketId(paketId); // Update the selected paketId
  };

  return (
    <div className="flex flex-row justify-between p-4 gap-[8px] w-[320px] h-[58px] items-center">
      <Badge label={label} backgroundColor={backgroundColor} color={color} />
      <Switch defaultChecked={switchValue} onChange={handleChange} />
    </div>
  );
};

const PerlengkapanPopup = ({ setOpen }) => {
  const [switchValues, setSwitchValues] = useState([]);
  const [paketId, setPaketId] = useState(1);
  const classes = useStyle();

  const items = [
    {
      id: 1,
      label: "Koper Bagasi",
      backgroundColor: "rgba(146, 226, 0, 0.2)",
      color: "#359B05",
    },
    {
      id: 2,
      label: "Tas Pasport",
      backgroundColor: "rgba(146, 226, 0, 0.2)",
      color: "#359B05",
    },
    {
      id: 3,
      label: "Koper Seragam",
      backgroundColor: "rgba(146, 226, 0, 0.2)",
      color: "#359B05",
    },
    {
      id: 4,
      label: "Pin",
      backgroundColor: "rgba(146, 226, 0, 0.2)",
      color: "#359B05",
    },
    {
      id: 5,
      label: "Kain Ihram",
      backgroundColor: "rgba(146, 226, 0, 0.2)",
      color: "#359B05",
    },
    {
      id: 6,
      label: "Mukena",
      backgroundColor: "rgba(146, 226, 0, 0.2)",
      color: "#359B05",
    },
    {
      id: 7,
      label: "Jilbab",
      backgroundColor: "rgba(146, 226, 0, 0.2)",
      color: "#359B05",
    },
    {
      id: 8,
      label: "Buku Manasik",
      backgroundColor: "rgba(146, 226, 0, 0.2)",
      color: "#359B05",
    },
    {
      id: 9,
      label: "Gantungan Dada",
      backgroundColor: "rgba(146, 226, 0, 0.2)",
      color: "#359B05",
    },
  ];

  const dispatch = useDispatch();

  const { isLoading } = useSelector((state) => state.perlengkapan);

  const saveDataSwitch = async () => {
    let selectedPerlengkapan = items
      .filter((item, index) => switchValues[index])
      .map((item) => ({ equipment_id: item.id, label: item.label }));

    let payload = {
      paket_id: paketId,
      perlengkapan: selectedPerlengkapan,
    };
    console.log(payload, "payload");
    dispatch(saveDataPerlengkapan(payload));
    setOpen(false);
  };

  return (
    <>
      <div className="flex flex-col px-4 py-8 gap-4 overflow-auto">
        <div className="grid grid-cols-4 gap-4">
          {items.map((item, index) => (
            <div
              className="flex justify-between items-center p-[16px] gap-[16px] w-full h-[58px] perlengkapan-card"
              key={index}
            >
              <PerlengkapanCard
                {...item}
                switchValue={switchValues[index] || false}
                setSwitchValue={(checked) =>
                  setSwitchValues((prevValues) => {
                    const newValues = [...prevValues];
                    newValues[index] = checked;
                    return newValues;
                  })
                }
                setPaketId={setPaketId}
                paketId={item.id}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-col justify-center items-end px-[8px] py-[20px] gap-[8px] border rounded-lg">
        <Buttons
          text="Simpan"
          customClass="btn-primary btn-medium !w-[186px] float-right"
          onClick={saveDataSwitch}
          loading={isLoading}
        />
      </div>
    </>
  );
};

export default PerlengkapanPopup;
