import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import MoslemeTable from "../../../../components/Table";
import Badge from "../../../../components/badge";
import Segment from "../../../../components/segment";
import moment from "moment";

import { DocumentCopy, Edit, Eye, Trash } from "iconsax-react";

import { useSelector, useDispatch } from "react-redux";
import { getAllDataPembayaran } from "stores/actions/tagihan";

const TabPembayaran = () => {
  const { detailData } = useSelector((state) => state.tagihan);

  const router = useHistory();
  // Selection & type state
  const [selectionType, setSelectionType] = useState("checkbox");
  const [isExpand, setIsExpand] = useState(true);

  const { dataPembayaran, status } = useSelector((state) => state.tagihan);
  let dataTable = dataPembayaran.data;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getAllDataPembayaran({
        page_number: 1,
        per_page: 10,
        sort_column: "date",
        sort_order: "ASC",
        id_order: detailData?.data?.id,
      })
    );
  }, []);

  // Pagination state
  const [currentpage, setCurrentpage] = useState(1);
  const [size, setSize] = useState(10);
  const total = 100;

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: total,
    current: currentpage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20"],
  };

  const columns = [
    {
      title: "ID Transaksi",
      dataIndex: "id",
    },
    {
      title: "Tanggal & Waktu",
      dataIndex: "date",
      render: (record) => (
        <>
          <div className="">{moment(record).format("LLL")}</div>
        </>
      ),
    },
    {
      title: "Nominal Transaksi",
      dataIndex: "amount",
    },
    {
      title: "Status Verifikasi",
      // dataIndex: "statverif",
      // render: () => (
      //   <Segment
      //     options={[
      //       {
      //         label: <div>Terverifikasi</div>,
      //         value: "Terverifikasi",
      //       },
      //       {
      //         label: (
      //           <div className="segment-label-error">Belum Terverifikasi</div>
      //         ),
      //         value: "Belum Terverifikasi",
      //       },
      //     ]}
      //   />
      // ),
    },
    {
      title: "Status Transaksi",
      dataIndex: "status_payment",
      render: () => <Badge label="BATAL" />,
    },
    {
      title: "Tipe Transaksi",
      dataIndex: "type",
      render: (record) => <Badge label={record} />,
    },
    {
      title: "",
      dataIndex: "actions",
      render: (actions) => actions,
      className: "actions",
    },
  ];

  // func to set size pagination
  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setSize(pageSize);
  };

  const expandable = {
    expandedRowRender: (record) => <p style={{ margin: 0 }}>{record.name}</p>,
    rowExpandable: (record) => record.name !== "Not Expandable",
  };

  // // action button
  // const ButtonAction = ({ value }) => {
  //   return (
  //     <div className="cursor-pointer flex justify-around">
  //       <Eye
  //         onClick={() => alert(`show id:`, value)}
  //         size="15"
  //         color="#141414"
  //       />
  //       <Trash
  //         onClick={() => alert(`delete id:`, value)}
  //         size="15"
  //         color="#141414"
  //       />
  //       <DocumentCopy
  //         onClick={() => alert(`copy id:`, value)}
  //         size="15"
  //         color="#141414"
  //       />
  //       <Edit
  //         onClick={() => alert(`edit id:`, value)}
  //         size="15"
  //         color="#141414"
  //       />
  //     </div>
  //   );
  // };

  // // manipulated data with action
  // const sourceData = data.map((v) => {
  //   v.actions = <ButtonAction value={v} />;
  //   return v;
  // });

  return (
    <>
      <MoslemeTable
        expandable={isExpand && expandable}
        columns={columns}
        data={dataTable}
        withSelection={true}
        selectionType={selectionType}
        pagination={pagination}
        customPaginationClass="pr-4"
        onPageChange={(page) => setCurrentpage(page)}
        onSizeChange={(currentpage, pageSize) =>
          onSizeChange(currentpage, pageSize)
        }
        onRow={(record, rowIndex) => ({
          onClick: (e) => {
            // console.log("RECORD", record);
            router.push("/tagihan");
          },
        })}
      />
    </>
  );
};

export default TabPembayaran;
