import React, { useEffect, useState } from "react";
import Buttons from "../../../../../components/buttons";
import Create from "./Create";
import TableAdditional from "./TableAdditional";
import { IcJamPasir } from "assets/icons/dashboard";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import AdditionalAction from "stores/actions/paket/additional";

function Additional() {
  const [isCreate, setIsCreate] = React.useState(false);
  const [isUpdate, setIsUpdate] = React.useState(false);
  const [detailEdit, setDetailEdit] = useState({});
  const dispatch = useDispatch();
  const [type, setType] = useState("");
  const params = useParams();
  const store = useSelector(({ additional }) => additional);

  useEffect(() => {
    dispatch(AdditionalAction.fetchData(params.id));
  }, []);

  useEffect(() => {
    if (isUpdate) {
      dispatch(AdditionalAction.fetchData(params.id));
    }
    setIsUpdate(false);
  }, [isUpdate]);

  return (
    <div className="">
      {store?.data.length > 0 && (
        <div className="flex justify-end py-[16px] pr-[16px]">
          <Buttons
            onClick={() => setIsCreate(true)}
            text="Tambah Data"
            customClass="btn-primary btn-small"
          />
        </div>
      )}

      {store?.data.length === 0 ? (
        <main className="flex flex-col items-center gap-y-4 justify-center h-[69vh]">
          <img alt="hourglass" src={IcJamPasir} />
          <div className="text-[#141414] text-[16px] font-normal">
            Belum ada Additonal di kategori ini
          </div>
          <Buttons
            text="Tambah Additional"
            customClass="btn-primary btn-medium"
            onClick={() => setIsCreate(true)}
          />
        </main>
      ) : (
        <>
          <TableAdditional
            setIsUpdate={setIsUpdate}
            isUpdate={isUpdate}
            setDetailEdit={setDetailEdit}
            setType={setType}
            setIsCreate={setIsCreate}
          />
        </>
      )}

      <Create
        detailEdit={detailEdit}
        show={isCreate}
        setIsCreate={setIsCreate}
        setIsUpdate={setIsUpdate}
        type={type}
        setType={setType}
      />
    </div>
  );
}

export default Additional;
