import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import ButtonsSocialStyle from "./ButtonsSocial.style";
import SocialGoogle from "../../../assets/icons/social-google.svg";
import SocialApple from "../../../assets/icons/social-apple.svg";
import SocialFacebook from "../../../assets/icons/social-facebook.svg";

const ButtonsSocial = ({ containerClass, customClass, id, type, onClick }) => {
  const checkIcon = () => {
    switch (type) {
      case "google":
        return <img src={SocialGoogle} />;
      case "apple":
        return <img src={SocialApple} />;
      case "facebook":
        return <img src={SocialFacebook} />;
      default:
        return null;
    }
  };

  return (
    <ButtonsSocialStyle className={`${containerClass}`}>
      <Button
        id={id}
        className={`custom-btn-social ${customClass}`}
        onClick={onClick}
      >
        {checkIcon()}
      </Button>
    </ButtonsSocialStyle>
  );
};

ButtonsSocial.propTypes = {
  containerClass: PropTypes.string,
  customClass: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
};

ButtonsSocialStyle.defaultProps = {
  containerClass: "",
  customClass: "",
  id: "",
  type: "",
  onClick: () => {},
};

export default React.memo(ButtonsSocial);
