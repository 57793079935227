import axios from "axios";
import { BASE_URL_ENDPOINT } from "../../../../constant/constanta";

export const paketAkomodasiApi = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/paket/get-akomodasi`, payload);

export const createPaketAkomodasiApi = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/paket/save-akomodasi`, payload);

export const deletePaketAkomodasiApi = (id) =>
  axios.delete(`${BASE_URL_ENDPOINT}/admin/paket/akomodasi/${id}`);
