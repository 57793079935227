import React from "react";
import BadgeTypePaket from "components/badge/badgeTypePaket";

const UserDesc = ({ name, linkImg, status, date, desc }) => {
  return (
    <section className="flex gap-x-4">
      <div
        className={`object-cover ${
          status ? "w-[108px] h-[110px] rounded-3xl" : "w-20 h-20 rounded-xl"
        } bg-white drop-shadow`}
      >
        <img
          alt="users"
          src={linkImg}
          className={
            "shadow-highlight" + status
              ? "object-cover w-20 h-20 rounded-xl"
              : "object-cover w-[108px] h-[108px] overflow-hidden rounded-3xl"
          }
        />
      </div>
      <div className="flex flex-col justify-start items-start">
        {status && <BadgeTypePaket typePaket={status} />}
        <div className="mt-[2px]">
          <div className="font-semibold text-[#141414] text-base capitalize">
            {name}
          </div>
          {date && <div className="font-semibold text-[#141414]">{date}</div>}
        </div>
        <div className="text-xs mt-2">{desc}</div>
      </div>
    </section>
  );
};

export default UserDesc;
