import React, { useState } from "react";
import { Grid } from "@material-ui/core";

import MoslemeTable from "components/Table";
import Buttons from "components/buttons";
import Chart from "components/chart";
import Badge from "components/badge";
import RekeningCard from "components/RekeningCard";
import Popup from "components/popup";
import PopupWithdrawFee from "./components/PopupWithdrawFee";
import AddOrEditRekening from "./components/AddOrEditRekening";
import HistoryPendapatanFee from "./components/HistoryPendapatanFee";
import HistoryPenarikanFee from "./components/HistoryPenarikanFee";

import { DocumentCopy, Edit, Eye, Trash } from "iconsax-react";
import BankBCA from "assets/images/bca-rounded.svg";

const PenarikanFee = () => {
  const series = [
    {
      name: "series1",
      data: [31, 40, 28, 51, 42, 109, 100],
    },
    {
      name: "series2",
      data: [11, 32, 45, 32, 34, 52, 41],
    },
  ];
  const categories = [
    "2018-09-19T00:00:00.000Z",
    "2018-09-19T01:30:00.000Z",
    "2018-09-19T02:30:00.000Z",
    "2018-09-19T03:30:00.000Z",
    "2018-09-19T04:30:00.000Z",
    "2018-09-19T05:30:00.000Z",
    "2018-09-19T06:30:00.000Z",
  ];

  // Loading
  const [isLoading, setIsLoading] = useState(false);
  // Expaneble
  const [isExpand, setIsExpand] = useState(false);
  // Selection & type state
  const [selectionType, setSelectionType] = useState("checkbox");
  const [withSelection, setWithSelection] = useState(false);

  // Pagination state
  const [currentpage, setCurrentpage] = useState(1);
  const [size, setSize] = useState(10);
  const total = 100;

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: total,
    current: currentpage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20"],
  };

  // func to set size pagination
  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setSize(pageSize);
  };

  // if have expaneble, this is the props example..
  const expandable = {
    expandedRowRender: (record) => <p style={{ margin: 0 }}>{record.name}</p>,
    rowExpandable: (record) => record.name !== "Not Expandable",
  };

  const columns = [
    {
      title: "Nama Paket",
      dataIndex: "name",
      render: (text) => (
        <div>
          <div>{text}</div>
          <Badge label="Umroh" customClass="mt-1" />
        </div>
      ),
    },
    {
      title: "Tanggal Pendapatan",
      dataIndex: "date",
    },
    {
      title: "Jumlah Pax",
      dataIndex: "pax",
      render: (s) => (
        <div>
          <div>{s} pax</div>
          <div className="action">
            <Eye onClick={() => alert(`show id:`)} size="15" color="#141414" />
            <Trash
              onClick={() => alert(`delete id:`)}
              size="15"
              color="#141414"
            />
          </div>
        </div>
      ),
    },
    {
      title: "Nominal Pendapatan",
      dataIndex: "income",
      className: "whitespace-nowrap relative",
      render: (s) => (
        <div>
          <div className="font-semibold">Rp {s}</div>
          <div className="action">
            <DocumentCopy
              onClick={() => alert(`copy id:`)}
              size="15"
              color="#141414"
            />
            <Edit onClick={() => alert(`edit id:`)} size="15" color="#141414" />
          </div>
        </div>
      ),
    },
  ];

  // manipulated data with action
  const sourceData = [
    {
      key: "1",
      name: "Umroh Bersama Ustadz Muhamammad",
      date: "15 Desember 2023",
      pax: "2",
      income: "5.000.000",
    },
    {
      key: "2",
      name: "Umroh Bersama Ustadz Muhamammad",
      date: "15 Desember 2023",
      pax: "2",
      income: "5.000.000",
    },
    {
      key: "3",
      name: "Umroh Bersama Ustadz Muhamammad",
      date: "15 Desember 2023",
      pax: "2",
      income: "5.000.000",
    },
  ];

  const historyFee = [
    {
      bank: "bca",
      name: "Agus Nughroho",
      date: "5 Desember 2021 - 14.24 WIB",
      status: "Berhasil",
      nominal: "5.000.000",
    },
    {
      bank: "bca",
      name: "Agus Nughroho",
      date: "5 Desember 2021 - 14.24 WIB",
      status: "Tertunda",
      nominal: "5.000.000",
    },
    {
      bank: "bca",
      name: "Agus Nughroho",
      date: "5 Desember 2021 - 14.24 WIB",
      status: "Gagal",
      nominal: "5.000.000",
    },
    {
      bank: "bca",
      name: "Agus Nughroho",
      date: "5 Desember 2021 - 14.24 WIB",
      status: "Berhasil",
      nominal: "5.000.000",
    },
  ];

  const [openPopupWithdrawFee, setOpenPopupWithdrawFee] = useState(false);
  const [openPopupAddRekening, setOpenPopupAddRekening] = useState({
    show: false,
    edit: null,
  });
  const [openPopupHistoryPendapatanFee, setOpenPopupHistoryPendapatanFee] =
    useState(false);
  const [openPopupHistoryPenarikanFee, setOpenPopupHistoryPenarikanFee] =
    useState(false);

  return (
    <>
      <Popup
        open={openPopupWithdrawFee}
        handleClose={() => setOpenPopupWithdrawFee(false)}
        title="Withdraw Fee"
        width="768px"
        children={<PopupWithdrawFee />}
      />
      <Popup
        open={openPopupAddRekening.show}
        handleClose={() =>
          setOpenPopupAddRekening({
            show: false,
            edit: null,
          })
        }
        title={`${
          openPopupAddRekening.edit ? "Edit Rekening" : "Tambah Rekening"
        }`}
        width="768px"
        children={<AddOrEditRekening />}
      />
      <Popup
        open={openPopupHistoryPendapatanFee}
        handleClose={() => setOpenPopupHistoryPendapatanFee(false)}
        title="History Pendapatan Fee"
        width="1080px"
        children={<HistoryPendapatanFee />}
      />
      <Popup
        open={openPopupHistoryPenarikanFee}
        handleClose={() => setOpenPopupHistoryPenarikanFee(false)}
        title="History Penarikan Fee"
        width="1080px"
        children={<HistoryPenarikanFee />}
      />
      <div className="bg-white soft-shadow rounded-[20px]">
        <div className="p-4">
          <div className="font-semibold text-[20px]">Penarikan Fee</div>
        </div>
        <div className="divider"></div>
        <div className="p-4">
          <Grid container spacing={2}>
            <Grid item md={7}>
              <div className="flex flex-row items-center justify-between">
                <div className="flex flex-col gap-1">
                  <div>Total Earning</div>
                  <div className="font-semibold text-[20px]">Rp 50.000.000</div>
                </div>
                <Buttons
                  text="Withdraw Fee"
                  customClass="btn-primary btn-small"
                  onClick={() => setOpenPopupWithdrawFee(true)}
                />
              </div>
              <div className="mt-2">
                <Chart
                  data={series}
                  categories={categories}
                  colors={["#1F4BFF"]}
                />
              </div>
              <div className="mt-6">
                <div className="flex flex-row items-center justify-between">
                  <div className="font-semibold text-[20px]">
                    History Pendapatan Fee
                  </div>
                  <Buttons
                    text="Lihat Semua"
                    customClass="btn-primary btn-small"
                    onClick={() => setOpenPopupHistoryPendapatanFee(true)}
                  />
                </div>
                <MoslemeTable
                  loading={isLoading}
                  expandable={isExpand && expandable}
                  columns={columns}
                  data={sourceData}
                  withSelection={withSelection}
                  selectionType={selectionType}
                  pagination={pagination}
                  onPageChange={(page) => setCurrentpage(page)}
                  onSizeChange={(currentpage, pageSize) =>
                    onSizeChange(currentpage, pageSize)
                  }
                />
              </div>
            </Grid>
            <Grid item md={5}>
              <div className="bg-white soft-shadow rounded-2xl">
                <div className="flex flex-row justify-between items-center p-4">
                  <div className="font-semibold text-[20px]">Rekening Anda</div>
                  <div className="flex flex-row gap-2">
                    <Buttons
                      text="Edit"
                      customClass="btn-secondary btn-small"
                      onClick={() =>
                        setOpenPopupAddRekening({
                          show: true,
                          edit: true,
                        })
                      }
                    />
                    <Buttons
                      text="Tambah Rekening"
                      customClass="btn-primary btn-small"
                      onClick={() =>
                        setOpenPopupAddRekening({
                          show: true,
                          edit: false,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="divider"></div>
                <div className="flex flex-row gap-4 overflow-x-auto p-4">
                  <RekeningCard />
                  <RekeningCard />
                </div>
              </div>
              <div className="bg-white soft-shadow rounded-2xl mt-4">
                <div className="flex flex-row justify-between items-center p-4">
                  <div className="font-semibold text-[20px]">
                    History Penarikan Fee
                  </div>
                  <Buttons
                    text="Lihat Semua"
                    customClass="btn-primary btn-small"
                    onClick={() => setOpenPopupHistoryPenarikanFee(true)}
                  />
                </div>
                <div className="divider"></div>
                <div>
                  {historyFee.map((val, idx) => {
                    return (
                      <div
                        className={`flex flex-row items-center justify-between px-4 py-5 ${
                          idx % 2 === 0 ? "bg-white" : "bg-[#FBF3F2]"
                        }`}
                      >
                        <div className="flex flex-row items-center gap-4">
                          <img src={BankBCA} alt="logo" />
                          <div className="flex flex-col gap-1">
                            <div className="font-semibold">{val.name}</div>
                            <div>{val.date}</div>
                          </div>
                        </div>
                        <div className="flex flex-col gap-1">
                          <div
                            className={`font-semibold ${
                              val.status === "Berhasil"
                                ? "txt-green"
                                : val.status === "Tertunda"
                                ? "txt-yellow"
                                : "txt-maroon"
                            }`}
                          >
                            {val.status}
                          </div>
                          <div className="font-semibold">Rp {val.nominal}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default PenarikanFee;
