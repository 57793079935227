import React, { useCallback, useState } from "react";
import { Grid } from "@material-ui/core";

import SidebarChild from "components/template_layout/Siderbar/SidebarChild";
import Manasik from "./components/Manasik";
import PaketLaporanPerlengkapan from "./components/Perlengkapan";
import PaketLaporanRoomlist from "./components/Roomlist";
import PaketLaporanSeat from "./components/Seat";
import PaketLaporanPembayaran from "./components/Pembayaran";

import IconManasik from "../../../../../assets/icons/Manasik.svg";
import IconPerlengkapan from "../../../../../assets/icons/perlengkapan.svg";
import IconRoomlist from "../../../../../assets/icons/Roomlist.svg";
import IconPembayaran from "../../../../../assets/icons/Pembayaran.svg";
import IconSeat from "../../../../../assets/icons/Seat.svg";

export const menuSidebar = [
  {
    title: "Manasik",
    key: "manasik",
    icon: IconManasik,
    order: 0,
  },
  {
    title: "Perlengkapan",
    key: "perlengkapan",
    icon: IconPerlengkapan,
    order: 1,
  },
  {
    title: "Roomlist",
    key: "roomlist",
    icon: IconRoomlist,
    order: 2,
  },
  {
    title: "Pembayaran",
    key: "pembayaran",
    icon: IconPembayaran,
    order: 3,
  },
  {
    title: "Seat",
    key: "seat",
    icon: IconSeat,
    order: 4,
  },
];

const PaketLaporan = () => {
  const [showContent, setShowContent] = useState("manasik");

  const getContent = useCallback(() => {
    switch (showContent) {
      case "manasik":
        return <Manasik />;
      case "perlengkapan":
        return <PaketLaporanPerlengkapan />;
      case "roomlist":
        return <PaketLaporanRoomlist />;
      case "pembayaran":
        return <PaketLaporanPembayaran />;
      case "seat":
        return <PaketLaporanSeat />;
      default:
        return <Manasik />;
    }
  }, [showContent]);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item md={2}>
          <SidebarChild
            containerClass="!rounded-none border-[1px] border-[#4048521a] !relative !w-full !left-0"
            menuList={menuSidebar}
            handleClick={setShowContent}
            path="/paket"
            showContent={showContent}
          />
        </Grid>
        <Grid item md={10}>
          {getContent()}
        </Grid>
      </Grid>
    </div>
  );
};

export default React.memo(PaketLaporan);
