import MarketingKit from "../assets/icons/colored/MarketingKIT-icon.svg";
import Notification from "../assets/icons/notification.svg";
import Broadcast from "../assets/icons/Broadcast.svg";
import JasaBadal from "../assets/icons/Jasa_badal.svg";
import Tabungan from "../assets/icons/Tabungan.svg";

export const menuMarketingSidebarList = [
  {
    title: "Marketing KIT",
    key: "marketing",
    icon: MarketingKit,
    order: 0,
  },
  {
    title: "Notification",
    key: "notification",
    icon: Notification,
    order: 1,
  },
  {
    title: "Broadcast",
    key: "broadcast",
    icon: Broadcast,
    order: 2,
  },
];
