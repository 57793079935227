import Inputs from "components/form/inputs";
import Selects from "components/form/selects";
import Popup from "components/popup";
import Tab from "components/tab";
import { useFormik } from "formik";
import React, { useState } from "react";
import BukanJamaah from "./BukanJamaah";
import SebagaiJamaah from "./SebagaiJamaah";
import Buttons from "components/buttons";

const PopUpKeluarga = ({ show, setModal }) => {
  const [activeTab, setActiveTab] = useState("1");
  const validation = useFormik({
    initialValues: {
      nama_ayah: "",
      nama_ibu: "",
    },
    onSubmit: (val) => {
      // dispatch(MemberAction.createData(val, callbackPost));
    },
  });

  const close = (e) => {
    setModal(false);
    validation.handleReset();
  };
  return (
    <Popup
      width="824px"
      open={show}
      handleClose={close}
      title="Keluarga"
      containerClass="-z-100"
    >
      <div className="w-full border rounded-[16px]">
        <div className="p-[16px]">
          <Selects
            label="Nama Ayah"
            containerClass="mt-[16px]"
            placeholder="Ayah"
            onChange={(e) => validation.setFieldValue("nama_ayah", e[0].value)}
            options={[
              {
                value: "ayah",
                label: "Ayah",
              },
            ]}
            error={
              validation.touched.nama_ayah &&
              validation.errors.nama_ayah &&
              validation.errors.nama_ayah
            }
            itemRenderer={({ item, methods }) => (
              <div className="px-[16px] py-[24px]">
                <Tab
                  defaultActiveKey="1"
                  onChange={(e) => setActiveTab(e)}
                  items={[
                    {
                      label: (
                        <div className="flex flex-row items-center gap-2">
                          <div>Bukan Jamaah</div>
                        </div>
                      ),
                      key: "1",
                      children: <BukanJamaah validation={validation} />,
                    },
                    {
                      label: (
                        <div className="flex flex-row items-center gap-2">
                          <div>Sebagai Jamaah</div>
                        </div>
                      ),
                      key: "2",
                      children: <SebagaiJamaah validation={validation} />,
                    },
                  ]}
                />
              </div>
            )}
          />
          <Selects
            label="Nama Ibu"
            containerClass="mt-[16px]"
            placeholder="Ibu"
            onChange={(e) => validation.setFieldValue("nama_ibu", e[0].value)}
            options={[
              {
                value: "ibu",
                label: "Ibu",
              },
            ]}
            error={
              validation.touched.nama_ibu &&
              validation.errors.nama_ibu &&
              validation.errors.nama_ibu
            }
            itemRenderer={({ item, methods }) => (
              <div className="px-[16px] py-[24px]">
                <Tab
                  defaultActiveKey="1"
                  onChange={(e) => setActiveTab(e)}
                  items={[
                    {
                      label: (
                        <div className="flex flex-row items-center gap-2">
                          <div>Bukan Jamaah</div>
                        </div>
                      ),
                      key: "1",
                      children: <BukanJamaah validation={validation} />,
                    },
                    {
                      label: (
                        <div className="flex flex-row items-center gap-2">
                          <div>Sebagai Jamaah</div>
                        </div>
                      ),
                      key: "2",
                      children: <SebagaiJamaah validation={validation} />,
                    },
                  ]}
                />
              </div>
            )}
          />
        </div>
        <div className="w-full flex justify-end border-t p-[16px]">
          <Buttons
            // onClick={() => validation.handleSubmit()}
            // disabled={validation.values.foto === ""}
            text="Simpan"
            style={{ width: 150 }}
            customClass="btn-primary btn-medium mt-3"
          />
        </div>
      </div>
    </Popup>
  );
};

export default PopUpKeluarga;
