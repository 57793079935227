import styled from "styled-components";

const SwitchsStyle = styled.div`
  label {
    margin-right: 12px;
  }

  .ant-switch {
    background: #edf2f4;
    &:hover {
      background: #edf2f4;
    }
  }

  .ant-switch.ant-switch-checked {
    background: linear-gradient(201deg, #F9F218 3.37%, #E2A246 86.36%);
    &:hover {
      background: linear-gradient(201deg, #F9F218 3.37%, #E2A246 86.36%);
    }
  }
`;

export default SwitchsStyle;
