import React, { useState } from "react";
import StarIcon from "assets/icons/Star.svg";
import AvatarIkhwan from "assets/images/Avatar-ikhwan-1.svg";
import Badge from "components/badge";
import CalendarIcon from "assets/icons/colored/calendar-icon.svg";
import { Rating } from "@mui/material";

const listRating = [
  "Semua",
  "Bintang 1 (0)",
  "Bintang 2 (0)",
  "Bintang 3 (0)",
  "Bintang 4 (8)",
  "Bintang 5 (12)",
];

const RatingCardData = [
  {
    name: "Dimas Andreyan Prana Putra",
    badgeLabel: "Yogyakarta",
    badgeColor: "#FE0000",
    badgeBackgroundColor: "#F7A399",
    fotoProfilImg: AvatarIkhwan,
    date: "10/10/2023",
    textReview: `Lorem ipsum dolor sit amet, consectetur adipiscing elit ut
        aliquam, purus sit amet luctus venenatis, lectus magna fringilla
        urna, porttitor rhoncus dolor purus non enim praesent elementum
        facilisis leo, velLorem ipsum dolor sit amet, consectetur
        adipiscing elit ut aliquam, purus sit amet luctus venenatis,
        lectus magna fringilla urna, porttitor rhoncus dolor purus non
        enim praesent elementum facilisis leo, vel`,
  },
  {
    name: "Dimas Andreyan Prana Putra",
    badgeLabel: "Yogyakarta",
    badgeColor: "#FE0000",
    badgeBackgroundColor: "#F7A399",
    fotoProfilImg: AvatarIkhwan,
    date: "10/10/2023",
    textReview: `Lorem ipsum dolor sit amet, consectetur adipiscing elit ut
        aliquam, purus sit amet luctus venenatis, lectus magna fringilla
        urna, porttitor rhoncus dolor purus non enim praesent elementum
        facilisis leo, velLorem ipsum dolor sit amet, consectetur
        adipiscing elit ut aliquam, purus sit amet luctus venenatis,
        lectus magna fringilla urna, porttitor rhoncus dolor purus non
        enim praesent elementum facilisis leo, vel`,
  },
  {
    name: "Dimas Andreyan Prana Putra",
    badgeLabel: "Yogyakarta",
    badgeColor: "#FE0000",
    badgeBackgroundColor: "#F7A399",
    fotoProfilImg: AvatarIkhwan,
    date: "10/10/2023",
    textReview: `Lorem ipsum dolor sit amet, consectetur adipiscing elit ut
        aliquam, purus sit amet luctus venenatis, lectus magna fringilla
        urna, porttitor rhoncus dolor purus non enim praesent elementum
        facilisis leo, velLorem ipsum dolor sit amet, consectetur
        adipiscing elit ut aliquam, purus sit amet luctus venenatis,
        lectus magna fringilla urna, porttitor rhoncus dolor purus non
        enim praesent elementum facilisis leo, vel`,
  },
];

const RatingPerjamaahCard = ({
  name,
  fotoProfilImg,
  badgeLabel,
  badgeColor,
  badgeBackgroundColor,
  date,
  textReview,
}) => {
  return (
    <div className="flex flex-col items-start p-[16px] gap-[16px] shadow-md">
      <div className="flex flex-row justify-between items-start gap-[16px] w-full">
        <div className="flex flex-row items-center gap-[16px]">
          <img
            src={fotoProfilImg}
            alt="foto profil"
            className="w-[40px] h-[40px] rounded-full"
          />
          <div className="flex flex-col items-start gap-[8px]">
            <h2>{name}</h2>
            <Badge
              color={badgeColor}
              backgroundColor={badgeBackgroundColor}
              label={badgeLabel}
            />
          </div>
        </div>
        <div className="flex flex-row items-center gap-[8px]">
          <img src={CalendarIcon} alt="calendar icon" />
          <p>{date}</p>
        </div>
      </div>
      <div className="flex flex-col items-start gap-[8px]">
        <div>
          <Rating name="size-medium" defaultValue={5} />
        </div>
        <div>
          <p>{textReview}</p>
        </div>
      </div>
    </div>
  );
};

const RatingPerjamaah = () => {
  const [activeFilter, setActiveFilter] = useState("Semua");

  const getFilterRating = () => {
    return listRating.map((val, idx) => {
      return (
        <div
          key={idx}
          className={`border-[1px] border-[#4048521a] rounded-lg px-4 py-1 cursor-pointer uppercase font-semibold ${
            activeFilter === val && "bg-[#F3CFCE]"
          }`}
          onClick={() => setActiveFilter(val)}
        >
          <div className={`${activeFilter === val && "txt-maroon"}`}>{val}</div>
        </div>
      );
    });
  };

  return (
    <>
      <div className="flex flex-col gap-[16px]">
        <div className="flex flex-row items-center p-[20px] gap-[31px] rounded-lg bg-white shadow-md">
          <div className="flex flex-row gap-[4px]">
            <img src={StarIcon} alt="rating" />
            <h2 className="font-semibold">4,8/5</h2>
          </div>
          <div className="font-medium">Filter Cabang</div>
          <div className="flex flex-row flex-wrap items-center gap-4 mt-4">
            {getFilterRating()}
          </div>
        </div>
        <div className="flex gap-[16px] flex-col">
          {RatingCardData.map((item) => (
            <RatingPerjamaahCard
              key={item.name}
              name={item.name}
              badgeLabel={item.badgeLabel}
              badgeColor={item.badgeColor}
              badgeBackgroundColor={item.badgeBackgroundColor}
              fotoProfilImg={item.fotoProfilImg}
              date={item.date}
              textReview={item.textReview}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default RatingPerjamaah;
