import { handleError, handleErrorBE, handleSuccess } from "stores/actions/errorGeneral";
import debounce from "debounce-promise";
import { BookingUmrohAPI } from "utils/api/booking";
import {
  CLEAR_ERROR,
  ERROR_FETCHING_BOOKING_UMROH,
  START_ADD_BOOKING_UMROH,
  SET_ADD_OR_EDIT_BOOKING_UMROH,
  SET_DELETE_BOOKING_UMROH,
  SET_KEYWORD_BOOKING_UMROH,
  SET_LOADING,
  SET_PAGE_BOOKING_UMROH,
  SET_SIZE_BOOKING_UMROH,
  SET_TOTAL_BOOKING_UMROH,
  START_FETCHING_BOOKING_UMROH,
  SUCCESS_FETCHING_BOOKING_UMROH,
} from "./actionTypes";

let debounceGetAdmin = debounce(BookingUmrohAPI.getList, 1500);

const startFetchingBookingUmroh = () => ({
  type: START_FETCHING_BOOKING_UMROH,
});

const errorFetchingBookingUmroh = () => ({
  type: ERROR_FETCHING_BOOKING_UMROH,
});

const successFetchingBookingUmroh = (payload) => ({
  type: SUCCESS_FETCHING_BOOKING_UMROH,
  payload,
});

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload
});

export const clearError = () => ({
  type: CLEAR_ERROR,
});

const setPage = (page) => ({
  type: SET_PAGE_BOOKING_UMROH,
  page,
});

const setSize = (size) => ({
  type: SET_SIZE_BOOKING_UMROH,
  size,
});

const setTotal = (total) => ({
  type: SET_TOTAL_BOOKING_UMROH,
  total,
});

const setKeyword = (keyword) => ({
  type: SET_KEYWORD_BOOKING_UMROH,
  keyword,
});

export const startAddBookingUmroh = () => ({
  type: START_ADD_BOOKING_UMROH
});

export const setAddOrEditBookingUmroh = (payload) => ({
  type: SET_ADD_OR_EDIT_BOOKING_UMROH,
  payload
});

export const setDeleteBookingUmroh = (payload, id) => ({
  type: SET_DELETE_BOOKING_UMROH,
  payload,
  id,
});

const handleFetchListBookingUmroh = (payload) => {
  return async (dispatch, getState) => {
    console.log("Tesssssssssssssssssssssst");
    dispatch(startFetchingBookingUmroh());
    try {
      let per_page = getState().mitra.per_page;
      let page_number = getState().mitra.page_number;
      let sort_column = getState().mitra.sort_column;
      let sort_order = getState().mitra.sort_order;
      let keyword = getState().mitra.keyword;
      let cabang_id = payload.cabang_id;
      const container = {
        per_page,
        page_number,
        sort_column,
        sort_order,
        keyword,
        cabang_id,
      };

      let res = await debounceGetAdmin(container);
      if (res.data.code === 401) {
        window.location.href = "/login";
      }
      let data = res.data.data === "" ? [] : res.data.data;
      dispatch(successFetchingBookingUmroh(data));
      dispatch(setTotal(res.data.total_data));
      dispatch(setPage(res.data.page));
    } catch (error) {
      console.log("error: ", error.response);
      dispatch(errorFetchingBookingUmroh());
    }
  };
};

const handleAddOrEditBookingUmroh = (payload) => {
  return async (dispatch, getState) => {
    // let edit = payload.edit ? true : false;

    try {
      dispatch(clearError());
      dispatch(setLoading(true));
      const response = await BookingUmrohAPI.postData(payload);
      dispatch(setLoading(false));

      if (response.status === 201) {
        dispatch(handleFetchListBookingUmroh())
        dispatch(handleSuccess(response))
        dispatch(setAddOrEditBookingUmroh(response.data))
      } else {
        dispatch(handleErrorBE(response));
      }
      
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

const handleDeleteBookingUmroh = (payload) => {
  return async (dispatch, getState) => {
    try {
      dispatch(clearError());
      dispatch(setLoading(true));
      const response = await BookingUmrohAPI.deleteData(payload);
      dispatch(setLoading(false));
      if (response.status === 200) {
        dispatch(setDeleteBookingUmroh(response.data, payload));
        return response.status;
      } else {
        dispatch(handleErrorBE(response));
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

const handleStartNewOrderUmroh = () => {
  return async (dispatch) => {
    dispatch(startAddBookingUmroh())
  }
}

const BookingUmrohAction = {
  fetchData: handleFetchListBookingUmroh,
  startNewOrder: handleStartNewOrderUmroh,
  postData: handleAddOrEditBookingUmroh,
  deleteData: handleDeleteBookingUmroh,
  setPage: setPage,
  setSize: setSize,
};

export default BookingUmrohAction;
