import {
  saveInfoDasar,
  saveOrEditPembimbing,
} from "../../../../utils/api/paket/infoDasar";
import { saveBus } from "../../../../utils/api/paket/bus";
import { handleError, handleErrorBE, handleSuccess } from "../../errorGeneral";

// infoDasar Actions
// --------------------------------------------------------

/* eslint-disable space-before-function-paren */
export const SET_LOADING = "infoDasar/SET_LOADING";
export const CLEAR_ERROR = "infoDasar/CLEAR_ERROR";
export const SET_ERROR = "infoDasar/SET_ERROR";
export const INIT_DATA = "infoDasar/INIT_DATA";
export const SET_DOUBLE_SUBMIT = "infoDasar/SET_DOUBLE_SUBMIT";

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const setDoubleSubmit = (payload) => ({
  type: SET_DOUBLE_SUBMIT,
  payload,
});

export const saveDataPaket = (payload, actionCallback) => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const res = await saveInfoDasar(payload.info_dasar);
    if (res.status === 200) {
      const res2 = await saveBus({
        bus: [...payload.bus],
        paket_id: res.data.data.id_paket,
      });
      if (res2.status == 200) {
        actionCallback(res2.data);
      }
      actionCallback(res2.data);
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const editPaketInfoDasar = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await saveInfoDasar(payload);
    if (res.status === 200) {
      dispatch(handleSuccess(res));
      return res.status;
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const handleSaveOrEditPembimbing =
  (payload, callbackActionSuccess, callbackError) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const res = await saveOrEditPembimbing(payload);
      dispatch(setLoading(false));
      if (res.status === 200) {
        callbackActionSuccess(res.data);
      } else {
        callbackError(res.data);
        dispatch(handleErrorBE(res));
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
