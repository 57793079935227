import React, { useState } from "react";

import Selects from "components/form/selects";
import Checkbox from "components/checkbox";

import IconClose from "assets/icons/close-black.svg";
import ImageOne from "assets/images/galery-1.jpg";
import ImageTwo from "assets/images/galery-2.jpg";
import ImageThree from "assets/images/galery-3.jpg";

const AddGaleriIbadahku = () => {
  const [checked, setChecked] = useState(false);
  const [options, setOptions] = useState([
    {
      value: "jack",
      label: "Jack",
    },
    {
      value: "lucy",
      label: "Lucy",
    },
    {
      value: "tom",
      label: "Tom",
    },
  ]);

  return (
    <div>
      <div className="border border-dashed border-[#E0E0E0] rounded-2xl p-4 flex flex-row justify-between">
        <div className="flex flex-row items-center gap-2">
          <img src={IconClose} alt="close" />
          <div className="font-semibold">10 Selected</div>
        </div>
        <div className="flex flex-row items-center gap-4">
          <Selects
            label="Jenis Paket"
            placeholder="Semua Jenis"
            customClass="!w-[200px]"
            options={options}
            searchable
            itemRenderer={({ state, item, methods }) => {
              return (
                <div
                  className={`react-dropdown-select-item ${
                    state.values.length !== 0 &&
                    state.values[0].value === item.value &&
                    "react-dropdown-select-item-selected"
                  }`}
                  onClick={() => methods.addItem(item)}
                >
                  {item.label}
                </div>
              );
            }}
          />
          <Selects
            label="Paket"
            placeholder="Semua Paket"
            customClass="!w-[200px]"
            options={options}
            searchable
            itemRenderer={({ state, item, methods }) => {
              return (
                <div
                  className={`react-dropdown-select-item ${
                    state.values.length !== 0 &&
                    state.values[0].value === item.value &&
                    "react-dropdown-select-item-selected"
                  }`}
                  onClick={() => methods.addItem(item)}
                >
                  {item.label}
                </div>
              );
            }}
          />
          <Selects
            label="Jenis File"
            placeholder="Semua Jenis"
            customClass="!w-[200px]"
            options={options}
            searchable
            itemRenderer={({ state, item, methods }) => {
              return (
                <div
                  className={`react-dropdown-select-item ${
                    state.values.length !== 0 &&
                    state.values[0].value === item.value &&
                    "react-dropdown-select-item-selected"
                  }`}
                  onClick={() => methods.addItem(item)}
                >
                  {item.label}
                </div>
              );
            }}
          />
        </div>
      </div>
      <div className="mt-4">
        <div className="grid grid-cols-4 gap-4 mt-4">
          <div
            className="h-[140px] rounded-2xl relative"
            style={{
              backgroundImage: `url(${ImageOne})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: "cover",
            }}
          >
            <Checkbox
              containerClass="absolute right-4 top-4"
              checked={checked}
              onChange={(e) => {
                setChecked(e.target.checked);
              }}
            ></Checkbox>
          </div>
          <div
            className="h-[140px] rounded-2xl relative"
            style={{
              backgroundImage: `url(${ImageTwo})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: "cover",
            }}
          >
            <Checkbox
              containerClass="absolute right-4 top-4"
              checked={checked}
              onChange={(e) => {
                setChecked(e.target.checked);
              }}
            ></Checkbox>
          </div>
          <div
            className="h-[140px] rounded-2xl relative"
            style={{
              backgroundImage: `url(${ImageThree})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: "cover",
            }}
          >
            <Checkbox
              containerClass="absolute right-4 top-4"
              checked={checked}
              onChange={(e) => {
                setChecked(e.target.checked);
              }}
            ></Checkbox>
          </div>
          <div
            className="h-[140px] rounded-2xl relative"
            style={{
              backgroundImage: `url(${ImageOne})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: "cover",
            }}
          >
            <Checkbox
              containerClass="absolute right-4 top-4"
              checked={checked}
              onChange={(e) => {
                setChecked(e.target.checked);
              }}
            ></Checkbox>
          </div>
          <div
            className="h-[140px] rounded-2xl relative"
            style={{
              backgroundImage: `url(${ImageTwo})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: "cover",
            }}
          >
            <Checkbox
              containerClass="absolute right-4 top-4"
              checked={checked}
              onChange={(e) => {
                setChecked(e.target.checked);
              }}
            ></Checkbox>
          </div>
          <div
            className="h-[140px] rounded-2xl relative"
            style={{
              backgroundImage: `url(${ImageThree})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: "cover",
            }}
          >
            <Checkbox
              containerClass="absolute right-4 top-4"
              checked={checked}
              onChange={(e) => {
                setChecked(e.target.checked);
              }}
            ></Checkbox>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(AddGaleriIbadahku);
