import { DocumentCopy, Edit, Eye, Trash } from "iconsax-react";
import uuid from "react-uuid";
import {
  editDataMaster,
  getDetailDataMaskapai,
} from "../../../../../stores/actions/datamaster";
import { getLocalStorage } from "utils/helpers";

const userLogin =
  getLocalStorage("userLogin") && JSON.parse(getLocalStorage("userLogin"));
const permissions = userLogin?.data?.account?.permissions;

export const columns = (
  handleOpen,
  setActionHandle,
  setIsModal,
  openModal,
  dispatch,
  formikValue,
  setDuplicateCode,
  setDuplicateLogo,
  setDuplicateDescription
) => [
  {
    title: "Logo",
    dataIndex: "logo",
    render: (img) => (
      <img
        className="rounded-full w-10 h-10 object-cover"
        alt="logo maskapai"
        src={img}
      />
    ),
  },
  {
    title: "Nama Maskapai",
    dataIndex: "name",
  },
  {
    title: "Kode Maskapai",
    dataIndex: "code",
  },
  {
    title: "Deskripsi",
    dataIndex: "description",
    className: "whitespace-nowrap relative",
    render: (s, record) => (
      <div className="w-full relative flex flex-row justify-between">
        {s !== null && s?.length > 150 ? `${s?.substr(0, 150)}...` : s}

        <div className="action absolute !w-full top-2 flex !flex-row !justify-end">
          <div className="flex flex-row justify-end pr-10 gap-x-16">
            {permissions?.includes("delete_master-data") && (
              <Trash
                onClick={() => {
                  handleOpen();
                  setActionHandle(record.id_maskapai);
                }}
                size="16"
                color="#141414"
                className="cursor-pointer"
              />
            )}
            {permissions?.includes("create_master-data") && (
              <DocumentCopy
                onClick={() => {
                  setDuplicateCode(record.code);
                  setDuplicateLogo(record.logo);
                  setDuplicateDescription(record.description);
                  formikValue("code", record.code);
                  formikValue("name", record.name);
                  formikValue("logo", record.logo);
                  formikValue("description", record.description);
                  setIsModal(true);
                }}
                size="16"
                color="#141414"
                className="cursor-pointer"
              />
            )}
            {permissions.includes("update_master-data") && (
              <Edit
                className="cursor-pointer"
                onClick={() => {
                  openModal(true);
                  dispatch(editDataMaster("edit maskapai"));
                  dispatch(getDetailDataMaskapai(record.id_maskapai, uuid()));
                }}
                size="16"
                color="#141414"
              />
            )}
          </div>
        </div>
      </div>
    ),
  },
];
