import React from "react";

import Buttons from "components/buttons";
import Badge from "components/badge";

import IconCSV from "assets/icons/CSV.svg";
import IconSingle from "assets/icons/Single.svg";
import IconDouble from "assets/icons/Double.svg";
import IconTripple from "assets/icons/Triple.svg";
import IconQuad from "assets/icons/Quad.svg";
import IconCabangPusat from "assets/icons/Cabang-Pusat.svg";

const PaketLaporanRoomlist = () => {
  return (
    <div className="bg-white border-[1px] border-[#4048521a] rounded-bl-lg rounded-br-lg">
      <div className="p-4">
        <div className="font-semibold text-[20px]">Kebutuhan Roomlist</div>
      </div>
      <div className="divider"></div>
      <div className="p-4">
        <div className="flex flex-row justify-between gap-4">
          <div className="flex flex-col gap-4 bg-white border-[1px] border-[#4048521a] rounded-lg normal-shadow p-4">
            <div className="font-semibold text-[16px]">
              Total Semua Kebutuhan Roomlist
            </div>
            <div className="flex flex-row gap-4">
              <div className="flex flex-row items-center gap-2">
                <img src={IconSingle} alt="single" />
                <div className="font-semibold">Single</div>
                <div className="txt-green text-[16px] font-semibold">89</div>
              </div>
              <div className="flex flex-row items-center gap-2">
                <img src={IconDouble} alt="single" />
                <div className="font-semibold">Double</div>
                <div className="txt-green text-[16px] font-semibold">89</div>
              </div>
              <div className="flex flex-row items-center gap-2">
                <img src={IconTripple} alt="single" />
                <div className="font-semibold">Tripple</div>
                <div className="txt-green text-[16px] font-semibold">89</div>
              </div>
              <div className="flex flex-row items-center gap-2">
                <img src={IconQuad} alt="single" />
                <div className="font-semibold">Quad</div>
                <div className="txt-green text-[16px] font-semibold">89</div>
              </div>
            </div>
          </div>
          <Buttons
            text="Export CSV"
            customClass="btn-outline btn-small"
            icon={IconCSV}
          />
        </div>

        <div className="mt-5">
          <div className="grid grid-cols-4 gap-4">
            <div className="minim-shadow rounded-lg bg-white">
              <div className="bg-[#FBC8C8] px-4 py-5 flex flex-row gap-2 rounded-tl-lg rounded-tr-lg">
                <img src={IconCabangPusat} alt="cabang" />
                <Badge label="Yogyakarta" />
              </div>
              <div className="divider"></div>
              <div className="p-4">
                <div className="grid grid-cols-2 gap-4">
                  <div className="flex flex-row items-center gap-2">
                    <img src={IconSingle} alt="single" />
                    <div className="font-semibold">Single</div>
                    <div className="txt-green text-[16px] font-semibold">
                      89
                    </div>
                  </div>
                  <div className="flex flex-row items-center gap-2">
                    <img src={IconDouble} alt="single" />
                    <div className="font-semibold">Double</div>
                    <div className="txt-green text-[16px] font-semibold">
                      89
                    </div>
                  </div>
                  <div className="flex flex-row items-center gap-2">
                    <img src={IconTripple} alt="single" />
                    <div className="font-semibold">Tripple</div>
                    <div className="txt-green text-[16px] font-semibold">
                      89
                    </div>
                  </div>
                  <div className="flex flex-row items-center gap-2">
                    <img src={IconQuad} alt="single" />
                    <div className="font-semibold">Quad</div>
                    <div className="txt-green text-[16px] font-semibold">
                      89
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="minim-shadow rounded-lg bg-white">
              <div className="bg-[#FBE7C8] px-4 py-5 flex flex-row gap-2 rounded-tl-lg rounded-tr-lg">
                <Badge label="Solo" />
              </div>
              <div className="divider"></div>
              <div className="p-4">
                <div className="grid grid-cols-2 gap-4">
                  <div className="flex flex-row items-center gap-2">
                    <img src={IconSingle} alt="single" />
                    <div className="font-semibold">Single</div>
                    <div className="txt-green text-[16px] font-semibold">
                      89
                    </div>
                  </div>
                  <div className="flex flex-row items-center gap-2">
                    <img src={IconDouble} alt="single" />
                    <div className="font-semibold">Double</div>
                    <div className="txt-green text-[16px] font-semibold">
                      89
                    </div>
                  </div>
                  <div className="flex flex-row items-center gap-2">
                    <img src={IconTripple} alt="single" />
                    <div className="font-semibold">Tripple</div>
                    <div className="txt-green text-[16px] font-semibold">
                      89
                    </div>
                  </div>
                  <div className="flex flex-row items-center gap-2">
                    <img src={IconQuad} alt="single" />
                    <div className="font-semibold">Quad</div>
                    <div className="txt-green text-[16px] font-semibold">
                      89
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="minim-shadow rounded-lg bg-white">
              <div className="bg-[#F3ECCA] px-4 py-5 flex flex-row gap-2 rounded-tl-lg rounded-tr-lg">
                <Badge label="Surabaya" />
              </div>
              <div className="divider"></div>
              <div className="p-4">
                <div className="grid grid-cols-2 gap-4">
                  <div className="flex flex-row items-center gap-2">
                    <img src={IconSingle} alt="single" />
                    <div className="font-semibold">Single</div>
                    <div className="txt-green text-[16px] font-semibold">
                      89
                    </div>
                  </div>
                  <div className="flex flex-row items-center gap-2">
                    <img src={IconDouble} alt="single" />
                    <div className="font-semibold">Double</div>
                    <div className="txt-green text-[16px] font-semibold">
                      89
                    </div>
                  </div>
                  <div className="flex flex-row items-center gap-2">
                    <img src={IconTripple} alt="single" />
                    <div className="font-semibold">Tripple</div>
                    <div className="txt-green text-[16px] font-semibold">
                      89
                    </div>
                  </div>
                  <div className="flex flex-row items-center gap-2">
                    <img src={IconQuad} alt="single" />
                    <div className="font-semibold">Quad</div>
                    <div className="txt-green text-[16px] font-semibold">
                      89
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="minim-shadow rounded-lg bg-white">
              <div className="bg-[#E7E7E6] px-4 py-5 flex flex-row gap-2 rounded-tl-lg rounded-tr-lg">
                <Badge label="Jakarta" />
              </div>
              <div className="divider"></div>
              <div className="p-4">
                <div className="grid grid-cols-2 gap-4">
                  <div className="flex flex-row items-center gap-2">
                    <img src={IconSingle} alt="single" />
                    <div className="font-semibold">Single</div>
                    <div className="txt-green text-[16px] font-semibold">
                      89
                    </div>
                  </div>
                  <div className="flex flex-row items-center gap-2">
                    <img src={IconDouble} alt="single" />
                    <div className="font-semibold">Double</div>
                    <div className="txt-green text-[16px] font-semibold">
                      89
                    </div>
                  </div>
                  <div className="flex flex-row items-center gap-2">
                    <img src={IconTripple} alt="single" />
                    <div className="font-semibold">Tripple</div>
                    <div className="txt-green text-[16px] font-semibold">
                      89
                    </div>
                  </div>
                  <div className="flex flex-row items-center gap-2">
                    <img src={IconQuad} alt="single" />
                    <div className="font-semibold">Quad</div>
                    <div className="txt-green text-[16px] font-semibold">
                      89
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="minim-shadow rounded-lg bg-white">
              <div className="bg-[#EEE5C8] px-4 py-5 flex flex-row gap-2 rounded-tl-lg rounded-tr-lg">
                <Badge label="Balikpapan" />
              </div>
              <div className="divider"></div>
              <div className="p-4">
                <div className="grid grid-cols-2 gap-4">
                  <div className="flex flex-row items-center gap-2">
                    <img src={IconSingle} alt="single" />
                    <div className="font-semibold">Single</div>
                    <div className="txt-green text-[16px] font-semibold">
                      89
                    </div>
                  </div>
                  <div className="flex flex-row items-center gap-2">
                    <img src={IconDouble} alt="single" />
                    <div className="font-semibold">Double</div>
                    <div className="txt-green text-[16px] font-semibold">
                      89
                    </div>
                  </div>
                  <div className="flex flex-row items-center gap-2">
                    <img src={IconTripple} alt="single" />
                    <div className="font-semibold">Tripple</div>
                    <div className="txt-green text-[16px] font-semibold">
                      89
                    </div>
                  </div>
                  <div className="flex flex-row items-center gap-2">
                    <img src={IconQuad} alt="single" />
                    <div className="font-semibold">Quad</div>
                    <div className="txt-green text-[16px] font-semibold">
                      89
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(PaketLaporanRoomlist);
