import SecureLS from "secure-ls";

const ls = new SecureLS({
  encodingType: "aes",
  encryptionSecret: "SLAskwqekasdfjSJdswqke",
});

// local storage action
export const setLocalStorage = (key, value) => ls.set(key, value);

export const getLocalStorage = (key) => ls.get(key);

export const removeLocalStorage = (key) => ls.remove(key);
export const removeAllLocalStorage = () => ls.removeAll();

export const formdataToJson = (formData) => {
  var object = {};
  formData.forEach(function (value, key) {
    object[key] = value;
  });
  var json = JSON.stringify(object);

  return json;
};

export const URL_REGEX = new RegExp(
  "^(https?:\\/\\/)?" + // validate protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
    "(\\#[-a-z\\d_]*)?$",
  "i" // validate fragment locator
);

export const toBase64 = (type = "decode", value) => {
  if (type === "encode") {
    return window?.btoa(value);
  } else {
    return window?.atob(value);
  }
};

export const toCurrencyFormat = (number) => {
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  })
    .format(number)
    .replace("Rp", "")
    .replace(/\s/g, "");
};

export const toCapital = (text) => {
  if(text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  return ''
}

export const getInitials = (str) => {
  return str.split(' ').map(word => word.charAt(0).toUpperCase()).join('');
}