import { useEffect, useState } from "react";
import { Radio, makeStyles } from "@material-ui/core";

import Inputs from "../../../../../../components/form/inputs";
import Buttons from "../../../../../../components/buttons";
import { Trash } from "iconsax-react";
import { BASE_URL_ENDPOINT } from "constant/constanta";
import axios from "axios";
import { getLocalStorage } from "utils/helpers";

import { getDataMaskapai } from "utils/api/requestPaket";
import IconJamPasir from "assets/icons/Jam-Pasir.svg";

import { Skeleton } from 'antd';

const useStyle = makeStyles({
  step2: {
    "& .select-label": {
      fontSize: "16px",
      fontWeight: "600",
      color: "#141414",
    },
    "& .input": {
      accentColor: "red",
    },
    "& .content_list": {
      overflowY: "auto",
      overflowX: "hidden",
      "&::-webkit-scrollbar": {
        width: "4px",
        // height: scrollHorizontal ? "5px" : "100px",
      },
      "&::-webkit-scrollbar-track": {
        width: "4px",
        borderRight: "10px solid #fff",
        background: "#fff",
        borderLeft: "10px solid #fff",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#E0E0E0",
        height: "30px",
        width: "4px",
        borderRadius: "16px",
      },
      "&::-webkit-scrollbar-track-piece:start": {
        background: "#fff",
      },
      "&::-webkit-scrollbar-track-piece:end": {
        background: "#fff",
      },
      scrollBehavior: "smooth",
    },
  },
});

const Step2 = ({ payload, payloadJumlahHari, setPayload, onHandleError }) => {
  const classes = useStyle();
  const [dataMaskapai, setDataMaskapai] = useState([]);
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    getDataAllMaskapai();
  }, []);

  const getDataAllMaskapai = async () => {

    try {
      setIsLoading(true)
      const response = await getDataMaskapai({ month: payloadJumlahHari?.bulan, year: payloadJumlahHari.tahun });

      if (response.data.code === 200) {
        setDataMaskapai(response.data.data);
      } else {
        onHandleError({
          show: true,
          subtitle: response.data.message,
        })
      }

      setIsLoading(false)
      
    } catch (error) {
      setIsLoading(false)
      onHandleError({
        show: true,
        subtitle: error.data.message,
      })
    }
  };

  return (
    <div className={classes.step2}>
      <div className="select-label mb-4">Pilih Maskapai</div>
      {
        !dataMaskapai.length && !isLoading ? 
          <div className="flex flex-col justify-center items-center w-full h-[320px]">
            <img src={IconJamPasir} alt="jam_pasir" width="150px" />
            <div className="text-[#141414] text-[14px] font-normal text-center font-sans mt-2 whitespace-pre-line w-64">
              Dibulan & tahun yang anda tentukan belum ada maskapai yang tersedia
            </div>
          </div>
        : null
      }
      { isLoading ?
          <div className="grid grid-cols-3 gap-4">
            {[...Array(6)].map((_, index) => (
              <Skeleton.Input active={true} size="large" block={true} />
            ))}
          </div>
      : null }
      <div className="grid grid-cols-3 gap-4">
        {dataMaskapai.length ? dataMaskapai?.map((item, idx) => {
          return (
            <div
              key={idx}
              className={`flex flex-row items-center w-full p-4 rounded-2xl border-[1px] ${
                item.id.toString() === payload.maskapai && "border-[#01F91A]"
              }`}
            >
              <input
                className="mr-4 text-red-500 input w-6 h-6 cursor-pointer"
                type="radio"
                checked={item.maskapai_id.toString() === payload.maskapai}
                value={item.maskapai_id}
                onChange={(event) => {
                  setPayload({
                    ...payload,
                    maskapai: event.target.value.toString(),
                  });
                }}
              />
              <h1 className="text-base font-semibold text-[#141414]">
                {item._maskapai.name}
              </h1>
            </div>
          );
        }) : null}
      </div>
    </div>
  );
};

export default Step2;
