import {
  getAllRoleService,
  saveRoleService,
  deleteRoleService,
  updatePermissionRoleService,
  getAllRoleServiceV2,
  getAllPermissionV2,
  deleteRoleServiceV2,
  saveRoleServiceV2,
  dupicatedRoleServiceV2,
  getDetailRoleV2,
  updatedRoleServiceV2,
  updatedPermisiionsServiceV2,
  getRolePermisionV2,
} from "../../../utils/api/role";
import { handleError, handleErrorBE } from "../errorGeneral";

// role Actions
// --------------------------------------------------------

/* eslint-disable space-before-function-paren */
export const SET_LOADING = "role/SET_LOADING";
export const SET_LOADING_DATA = "role/SET_LOADING_DATA";
export const CLEAR_ERROR = "role/CLEAR_ERROR";
export const SET_ERROR = "role/SET_ERROR";
export const INIT_DATA = "role/INIT_DATA";
export const SET_DOUBLE_SUBMIT = "role/SET_DOUBLE_SUBMIT";
export const SET_DATA_ROLE = "role/SET_DATA_ROLE";
export const SET_DATA_ROLEV2 = "role/SET_DATA_ROLEV2";
export const SET_DATA_ROLEV2_UTAMA = "role/SET_DATA_ROLEV2_UTAMA";
export const SET_DATA_PERMISSION = "role/SET_DATA_PERMISSION";
export const SET_DATA_DETAIL_ROLEV2 = "role/SET_DATA_DETAIL_ROLEV2_UTAMA";
export const SET_DATA_ROLE_PERMISSION = "role/SET_DATA_ROLE_PERMISSION";

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const setLoadingData = (payload) => ({
  type: SET_LOADING_DATA,
  payload,
});

export const setDoubleSubmit = (payload) => ({
  type: SET_DOUBLE_SUBMIT,
  payload,
});

export const setDataRole = (payload) => ({
  type: SET_DATA_ROLE,
  payload,
});

export const setDataRoleV2 = (payload) => ({
  type: SET_DATA_ROLEV2,
  payload,
});

export const setDataRoleV2Utama = (payload) => ({
  type: SET_DATA_ROLEV2_UTAMA,
  payload,
});

export const setDataPermisionV2 = (payload) => ({
  type: SET_DATA_PERMISSION,
  payload,
});

export const setDataDetailRoleV2 = (payload) => ({
  type: SET_DATA_DETAIL_ROLEV2,
  payload,
});

export const setDataRolePermisionV2 = (payload) => ({
  type: SET_DATA_ROLE_PERMISSION,
  payload,
});

export const saveDataRole = (payload, callBackAcion) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await saveRoleServiceV2(payload);
    if (res.status == 200) {
      callBackAcion(res.data);
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const updatedeDataRoleV2 =
  (payload, display_name, callBackAcion) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const res = await updatedRoleServiceV2(payload, display_name);
      if (res.status == 200) {
        callBackAcion(res.data);
      } else {
        dispatch(handleErrorBE(res));
      }
    } catch (error) {
      dispatch(handleError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const deleteDataRole = (payload, callbackAction) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await deleteRoleServiceV2(payload);
    if (res.status == 200) {
      callbackAction(res.data);
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getDataRole = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await getAllRoleService(payload);
    if (res.status == 200) {
      let convert_to = [];
      if (res.data.data.length) {
        convert_to = await res.data.data.map((item) => {
          const data = {
            key: item.id,
            role: {
              tags: item.name,
              color: "#333",
              backgroundColor:
                item.color_bg === "#00000" ? "#999" : item.color_bg,
              // backgroundColor: "#c1c5c9",
            },
            permission: {
              total: 10,
            },
            deskripsi: item.description,
            action: item,
          };
          return data;
        });
      }
      const saved_data = {
        data: convert_to,
        page: res.data.page,
        total_data: res.data.total_data,
      };

      dispatch(setDataRole(saved_data));
      // dispatch(setDataRole(res.data));
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const getDataRoleV2 = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(setLoadingData(true));
      const res = await getAllRoleServiceV2(payload);

      if (res.status === 200) {
        dispatch(setDataRoleV2(res.data));
      } else {
        dispatch(handleErrorBE(res));
      }
    } catch (error) {
      dispatch(handleError(error));
    } finally {
      dispatch(setLoadingData(false));
    }
  };
};

export const getDataDetailRoleV2 = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(setLoadingData(true));
      const res = await getDetailRoleV2(payload);

      if (res.status === 200) {
        dispatch(setDataDetailRoleV2(res.data));
      } else {
        dispatch(handleErrorBE(res));
      }
    } catch (error) {
      dispatch(handleError(error));
    } finally {
      dispatch(setLoadingData(false));
    }
  };
};

export const getDataPermissionV2 = () => {
  return async (dispatch) => {
    try {
      const res = await getAllPermissionV2();

      if (res.status === 200) {
        dispatch(setDataPermisionV2(res.data));
      } else {
        dispatch(handleErrorBE(res));
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

export const getDataRoleV2Utama = (payload) => {
  return async (dispatch) => {
    try {
      dispatch(setLoadingData(true));
      const res = await getAllRoleServiceV2(payload);

      if (res.status === 200) {
        dispatch(setDataRoleV2Utama(res.data));
      } else {
        dispatch(handleErrorBE(res));
      }
    } catch (error) {
      dispatch(handleError(error));
    } finally {
      dispatch(setLoadingData(false));
    }
  };
};

export const updatePermissionRoleAction = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await updatePermissionRoleService(payload);
    if (res.status == 200) {
      console.log("success");
      return res.status;
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const duplicatedDataRoleV2 =
  (payload, callBackAcion, callActionError) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const res = await dupicatedRoleServiceV2(payload);
      if (res.status == 200) {
        callBackAcion(res.data);
      } else {
        callActionError();
        dispatch(handleErrorBE(res));
      }
    } catch (error) {
      dispatch(handleError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const updatePermissionV2 =
  (payload, display_name, callBackAction) => async (dispatch) => {
    try {
      dispatch(setLoading(true));
      const res = await updatedPermisiionsServiceV2(payload, display_name);
      if (res.status == 200) {
        callBackAction(res.data);
      } else {
        dispatch(handleErrorBE(res));
      }
    } catch (error) {
      dispatch(handleError(error));
    } finally {
      dispatch(setLoading(false));
    }
  };

export const getDataRolePermisionV2 = (payload) => {
  return async (dispatch) => {
    try {
      const res = await getRolePermisionV2(payload);

      if (res.status === 200) {
        dispatch(setDataRolePermisionV2(res.data));
      } else {
        dispatch(handleErrorBE(res));
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};
