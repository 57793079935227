import Buttons from "components/buttons";
import ImageField from "components/image_field";
import Popup from "components/popup";
import { useFormik } from "formik";

import React from "react";

const PopUpFoto = ({ show, setModal }) => {
  const validation = useFormik({
    initialValues: {
      foto: "",
    },
    onSubmit: (val) => {
      // dispatch(MemberAction.createData(val, callbackPost));
    },
  });

  const close = (e) => {
    setModal(false);
    validation.handleReset();
  };

  return (
    <Popup width="824px" open={show} handleClose={close} title="Foto 4x6">
      <div className="w-full border rounded-[16px]">
        <div className="p-[16px]">
          <ImageField
            customContainer="h-[248px]"
            customImageContainer="w-full h-full"
            setFile={(file) => validation.setFieldValue("foto", file?.path)}
            label="Upload Foto"
            name="foto"
          />
        </div>
        <div className="w-full flex justify-end border-t p-[16px]">
          <Buttons
            onClick={() => validation.handleSubmit()}
            // disabled={validation.values.foto === ""}
            text="Simpan"
            style={{ width: 150 }}
            customClass="btn-primary btn-medium mt-3"
          />
        </div>
      </div>
    </Popup>
  );
};

export default PopUpFoto;
