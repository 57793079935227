import React from "react";
import PropTypes from "prop-types";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import AccordionsStyle from "./Accordions.Style";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Accordions = ({
  containerClass,
  customClass,
  customSummaryClass,
  customTitleClass,
  customContentClass,
  avatar,
  title,
  content,
  disabled,
  arrowIcon
}) => {
  return (
    <AccordionsStyle className={`${containerClass}`}>
      <Accordion
        className={`custom-accordion ${customClass}`}
        disabled={disabled}
      >
        <AccordionSummary
          className={customSummaryClass}
          expandIcon={
            !arrowIcon 
              ? <ExpandMoreIcon sx={{ color: "#141414" }} />
              : <img src={arrowIcon} className="w-8 h-8" />
          }
          //   aria-controls="panel1a-content"
          //   id="panel1a-header"
        >
          <div className="flex flex-row items-center gap-2">
            {avatar !== "" && <img src={avatar} alt="avatar" />}
            <div className={`text-[16px] ${customTitleClass}`}>{title}</div>
          </div>
        </AccordionSummary>
        <AccordionDetails className={customContentClass}>{content}</AccordionDetails>
      </Accordion>
    </AccordionsStyle>
  );
};

Accordions.propTypes = {
  containerClass: PropTypes.string,
  customClass: PropTypes.string,
  customSummaryClass: PropTypes.string,
  customTitleClass: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  arrowIcon: PropTypes.string,
  customContentClass: PropTypes.string
};

Accordions.defaultProps = {
  containerClass: "",
  customClass: "",
  customSummaryClass: "",
  customTitleClass: "",
  customContentClass: "",
  avatar: "",
  title: "Accordion",
  disabled: false,
  arrowIcon: null,
};

export default React.memo(Accordions);
