import React from "react";
import PropTypes from "prop-types";
import { Steps } from "antd";

import SteppersStyle from "./Steppers.Style";

const Steppers = ({
  containerClass,
  customClass,
  current,
  items,
  direction,
  labelPlacement,
}) => {
  return (
    <SteppersStyle className={`custom-stepper custom-stepper-${direction ? direction : 'horizontal'}`}>
      <Steps
        // className={`custom-stepper ${customClass}`}
        current={current}
        items={items}
        direction={direction}
        labelPlacement={labelPlacement}
        type="primary"
      />
    </SteppersStyle>
  );
};

Steppers.propTypes = {
  containerClass: PropTypes.string,
  customClass: PropTypes.string,
  current: PropTypes.number,
  items: PropTypes.array,
  direction: PropTypes.string,
  labelPlacement: PropTypes.string,
};

Steppers.propTypes = {
  containerClass: "",
  customClass: "",
  current: 0,
  items: [],
  direction: "horizontal",
  labelPlacement: "horizontal",
};

export default React.memo(Steppers);
