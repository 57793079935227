import { AdminArtikelAPI } from "utils/api/apps/artikel";
import debounce from "debounce-promise";
import {
  CLEAR_ERROR,
  ERROR_FETCHING_ARTICLE,
  SET_ADD_OR_EDIT_ARTICLE,
  SET_DELETE_ARTICLE,
  SET_ID_ROLE_ARTICLE,
  SET_KEYWORD_ARTICLE,
  SET_LOADING,
  SET_PAGE_ARTICLE,
  SET_SIZE_ARTICLE,
  SET_TOTAL_ARTICLE,
  START_FETCHING_ARTICLE,
  SUCCESS_FETCHING_ARTICLE,
} from "./actionTypes";
import { handleError, handleErrorBE } from "stores/actions/errorGeneral";

let debounceGetAdmin = debounce(AdminArtikelAPI.getListPage, 1500);

const startFetchingArticle = () => ({
  type: START_FETCHING_ARTICLE,
});

const errorFetchingArticle = () => ({
  type: ERROR_FETCHING_ARTICLE,
});

const successFetchingArticle = (data) => ({
  type: SUCCESS_FETCHING_ARTICLE,
  data,
});

const setPage = (page) => ({
  type: SET_PAGE_ARTICLE,
  page,
});

const setSize = (size) => ({
  type: SET_SIZE_ARTICLE,
  size,
});

const setTotal = (total) => ({
  type: SET_TOTAL_ARTICLE,
  total,
});

const setKeyword = (keyword) => ({
  type: SET_KEYWORD_ARTICLE,
  keyword,
});

const setRoleId = (id_role) => ({
  type: SET_ID_ROLE_ARTICLE,
  id_role,
});

export const setLoading = () => ({
  type: SET_LOADING,
});

export const clearError = () => ({
  type: CLEAR_ERROR,
});

export const setAddOrEditArticle = (payload, edit) => ({
  type: SET_ADD_OR_EDIT_ARTICLE,
  payload,
  edit,
});

export const setDeleteArticle = (payload) => ({
  type: SET_DELETE_ARTICLE,
  payload,
});

const handleFetchDataArticle = (payload) => {
  return async (dispatch, getState) => {
    dispatch(startFetchingArticle());
    try {
      let per_page = getState().artikel.per_page;
      let page_number = getState().artikel.page_number;
      let sort_column = getState().artikel.sort_column;
      let sort_order = getState().artikel.sort_order;
      let keyword = getState().artikel.keyword;
      let kategori_id = payload.kategori_id;
      let status = payload.status;
      const container = {
        per_page,
        page_number,
        sort_column,
        sort_order,
        keyword,
        kategori_id,
        status,
      };

      let res = await debounceGetAdmin(container);
      if (res.data.code === 401) {
        window.location.href = "/login";
      }
      let data = res.data.data === "" ? [] : res.data.data;
      dispatch(successFetchingArticle(data));
      dispatch(setTotal(res.data.total_data));
      dispatch(setPage(res.data.page));
    } catch (error) {
      console.log("error: ", error.response);
      dispatch(errorFetchingArticle());
    }
  };
};

const handleAddOrEditArticle = (payload) => {
  return async (dispatch, getState) => {
    let edit = payload.edit ? true : false;
    try {
      dispatch(clearError());
      dispatch(setLoading(true));
      const response = await AdminArtikelAPI.postData(payload);
      dispatch(setLoading(false));
      if (response.status === 200) {
        dispatch(setAddOrEditArticle(response.data, edit));
        return response.status;
      } else {
        dispatch(handleErrorBE(response));
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

const handleDeleteArticle = (payload) => {
  return async (dispatch, getState) => {
    try {
      dispatch(clearError());
      dispatch(setLoading(true));
      const response = await AdminArtikelAPI.deleteData(payload);
      dispatch(setLoading(false));
      if (response.status === 200) {
        dispatch(setDeleteArticle(response.data));
        return response.status;
      } else {
        dispatch(handleErrorBE(response));
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

const ArticleAction = {
  fetchData: handleFetchDataArticle,
  postData: handleAddOrEditArticle,
  deleteData: handleDeleteArticle,
  setPage: setPage,
  setSize: setSize,
  setKeyword: setKeyword,
  setRoleId: setRoleId,
};

export default ArticleAction;
