import React, { useCallback, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";

import Buttons from "../../../../components/buttons";
import Cards from "../../../../components/cards";
import Dropdowns from "../../../../components/dropdowns";
import Popup from "../../../../components/popup";

import { Edit, Trash } from "iconsax-react";
import IconMore from "../../../../assets/icons/More.svg";
import AddFAQ from "./components/AddFAQ";
import { useDispatch, useSelector } from "react-redux";
import { deleteIdFAQ, getDataFAQ, setDataFAQ } from "stores/actions/faq";
import { convertBase64 } from "utils/helpers/convertBase64";
import AlertPopup from "../../../../components/popup/alert";
import Spinner from "components/spinner";
import { Spin } from "antd";

const parse = require("html-react-parser");

const SettingFAQ = () => {
  const [openPopupAddFAQ, setOpenPopupAddFAQ] = useState(false);
  const dispatch = useDispatch();
  const { dataFAQ, isPopUP } = useSelector((state) => state.faq);
  const { isLoading } = useSelector((state) => state.faq);
  const [editData, setEditData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [showAlertPopup, setShowAlertPopup] = useState(false);
  const [faqToDelete, setFaqToDelete] = useState(null);

  useEffect(() => {
    if (isPopUP) {
      setOpenPopupAddFAQ(false);
      dispatch(getDataFAQ());
    }
  }, [isPopUP]);

  useEffect(() => {
    dispatch(getDataFAQ());
  }, [getDataFAQ]);

  const handleEditClick = (id) => {
    let dataToEdit = dataFAQ.find((item) => item.id_faq === id);

    if (dataToEdit) {
      setEditData(dataToEdit);
      setIsEdit(true);
      setOpenPopupAddFAQ(true);
    }
  };

  const handleConfirmDeleteFAQ = (id) => {
    const dataToDelete = dataFAQ.find((item) => item.id_faq === id);

    if (dataToDelete) {
      setFaqToDelete(dataToDelete);
      setIsDelete(true);
      setShowAlertPopup(true);
    }
  };

  const handleDeleteFAQ = async () => {
    setShowAlertPopup(false);
    setFaqToDelete(null);
    await dispatch(deleteIdFAQ(faqToDelete.id_faq));
  };

  return (
    <>
      <AlertPopup
        open={showAlertPopup}
        handleClose={() => setShowAlertPopup(false)}
        handleContinue={handleDeleteFAQ}
        loading={isLoading}
        title="Hapus FAQ"
        subtitle={`Apakah anda sudah yakin ingin menghapus FAQ ${faqToDelete?.pertanyaan} `}
      />

      <Popup
        open={openPopupAddFAQ}
        handleClose={() => {
          setOpenPopupAddFAQ(false);
          setIsEdit(false);
        }}
        title={isEdit ? "Edit FAQ" : "Tambah FAQ"}
        containerClass=""
        children={
          <AddFAQ setOpenPopupAddFAQ={setOpenPopupAddFAQ} editData={editData} />
        }
      />
      <Cards containerClass="!p-0">
        <div className="flex flex-row justify-between items-center p-4">
          <div className="text-[20px] font-semibold">Daftar FAQ</div>
          <Buttons
            text="Tambah FAQ"
            customClass="btn-primary btn-small"
            onClick={() => {
              setOpenPopupAddFAQ(true);
              setEditData(null);
            }}
          />
        </div>
        <div className="divider"></div>
        <div className="p-4">
          <Grid container spacing={2}>
            {isLoading ? (
              <div className="flex justify-center items-center py-10 z-50 w-full">
                <Spin
                  size="large"
                  wrapperClassName="m-auto"
                  rootClassName="m-auto"
                />
              </div>
            ) : (
              dataFAQ &&
              dataFAQ.map((item, index) => (
                <Grid item md={4} key={item.id_faq}>
                  <div className="normal-shadow rounded-2xl bg-white">
                    <div className="rounded-tl-2xl rounded-tr-2xl bg-[#F2F2F2] p-4">
                      <div className="flex flex-row justify-between">
                        <div className="green-bg rounded-[32px] px-1 text-white font-semibold">
                          {index + 1}
                        </div>
                        <Dropdowns iconOnly iconImg={IconMore}>
                          <div className="flex flex-row gap-6 bg-white hard-shadow rounded-[6px] px-4 py-2">
                            <Edit
                              className="cursor-pointer"
                              onClick={() => handleEditClick(item.id_faq)}
                              size="24"
                              color="#141414"
                            />
                            <Trash
                              className="cursor-pointer"
                              onClick={() =>
                                handleConfirmDeleteFAQ(item.id_faq)
                              }
                              size="24"
                              color="#141414"
                            />
                          </div>
                        </Dropdowns>
                      </div>
                      <div className="text-[16px] font-semibold line-clamp-2 mt-2">
                        {item.pertanyaan}
                      </div>
                    </div>
                    <div className="p-4">
                      <div className="font-semibold">Jawaban</div>
                      <div className="text-base line-clamp-2 mt-2">
                        {parse(convertBase64("DECRYP", item.jawaban))}
                      </div>
                      <Buttons
                        text="Edit"
                        customClass="btn-secondary btn-medium mt-4"
                        onClick={() => handleEditClick(item.id_faq)}
                      />
                    </div>
                  </div>
                </Grid>
              ))
            )}
          </Grid>
        </div>
      </Cards>
    </>
  );
};

export default React.memo(SettingFAQ);
