import { Tooltip } from "antd";
import { IcArrowBottom } from "assets/icons/dashboard";
import Badge from "components/badge";
const { Eye } = require("iconsax-react");

export const text = (item) => (
  <div className="text-white items-center text-center justify-between p-3 text-sm flex-col">
    <div className="text-center font-semibold capitalize">
      {item?.airport_departure_city}
    </div>
    <div class="flex z-50 items-center">
      <div class="border-b border-white flex-grow"></div>
      <div class="px-2">
        <img alt="arrow" src={IcArrowBottom} />
      </div>
      <div class="border-b border-white flex-grow"></div>
    </div>
    <div className="text-center font-semibold capitalize">
      {item?.airport_arrival_city}
    </div>
  </div>
);

export const columns = (dispatch, setIsDrawer, getDetailItenaryService) => [
  {
    title: "Starting",
    dataIndex: "nama_starting",
    width: "132px",
    render: (val, data, index) => (
      <Tooltip placement="right" title={text(data)}>
        <Badge
          label={data?.starting?.name}
          backgroundColor={data?.starting?.bg_color}
          color={data?.starting?.text_color}
          customClass="px-3 py-1 text-xs font-semibold  rounded-lg"
        />
      </Tooltip>
    ),
  },
  {
    title: "Durasi",
    dataIndex: "jumlah_pax",
    width: "100px",
    render: (val, data, index) => (
      <div className="flex flex-row">
        <div className="ml-2">{data?.day_duration} hari</div>
      </div>
    ),
  },
  {
    title: "Itinerary",
    dataIndex: "rute",
    render: (rute, data) => {
      // console.log("penasawarn", data);
      return (
        <div className="flex">
          {data?.itineraries?.map((item, idx) => (
            <section
              key={idx}
              className={`rounded rounded-tl-3xl rounded-br-3xl border-white border-2 bg-gray-200 px-6 ${
                (idx === 0 || idx === data.itenary?.length) && "bg-red-400"
              }`}
              style={{ position: "relative", left: idx * -16 }}
            >
              {item?.rute.join(",")}
            </section>
          ))}
        </div>
      );
    },
  },
  {
    title: "",
    dataIndex: "color",
    render: (color, item, idx) => {
      return (
        <>
          <div className="action absolute !w-full top-2 flex !flex-row !justify-end">
            <div className="flex flex-row justify-end pr-10 gap-x-16">
              <Eye
                className="cursor-pointer"
                onClick={() => {
                  getDetailItenaryService(item);
                }}
                size="15"
                color="#141414"
              />
            </div>
          </div>
        </>
      );
    },
  },
];
