import styled from "styled-components";

import BackgroundOranye from "../../assets/images/bg-orange-shine.jpg";

const DropdownsStyle = styled.div`
  .custom-dropdown {
    cursor: pointer;

    &.error {
      border:1px solid #E82320!important;
    }

  }
  .custom-dropdown-open {
    z-index: 1400;

    .custom-dropdown-header {
      padding: 12px 16px;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      background-image: url(${BackgroundOranye});
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }

    .custom-dropdown-menu {
      background: white;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1),
        0px 2px 16px rgba(0, 0, 0, 0.1);
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;

      .custom-dropdown-menu-item {
        padding: 8px 16px;
        color: #151515;
        cursor: pointer;

        &:hover {
          background: #fbf3f2;
        }

        .custom-dropdown-menu-item-label {
          font-size: 16px;
        }

        .custom-dropdown-menu-item-price {
          font-weight: 600;
          background: linear-gradient(200.57deg, #f9f218 3.37%, #e2a246 86.36%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }
      }

      .custom-dropdown-menu-item:last-child {
        &:hover {
          background: #fbf3f2;
          border-bottom-left-radius: 16px;
          border-bottom-right-radius: 16px;
        }
      }

      .custom-dropdown-menu-item-selected {
        background: #fbf3f2;
      }

      .custom-dropdown-menu-item-selected:hover {
        background: #fbf3f2;
      }

      .custom-dropdown-menu-item-selected:last-child {
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
      }
    }
  }
`;

export default DropdownsStyle;
