import React, { useCallback, useEffect, useState } from "react";
import MoslemeTable from "components/Table";
import { DocumentCopy, Edit, Eye, Trash } from "iconsax-react";
import Badge from "components/badge";
import { makeStyles } from "@material-ui/core";
import IconSearch from "../../../../../assets/icons/search-normal.svg";
import IconActivity from "../../../../../assets/icons/activity.svg";
import PerlengkapanContent from "./PerlengkapanContent";
import { useDispatch, useSelector } from "react-redux";
import { handleGetAllPerlengkapan } from "stores/actions/paket/perlengkapan";
import Inputs from "components/form/inputs";
import Dropdowns from "components/dropdowns";
import Buttons from "components/buttons";
import Popup from "components/popup";
import PerlengkapanPopup from "./PerlengkapanPopup";
import Filter from "assets/icons/filter.svg";
import Selects from "components/form/selects";

const useStyle = makeStyles({
  container: {
    overflow: "scroll",
    "& .mosleme-pagination": {
      display: "none !important",
    },

    "& .custom-dropdown": {
      border: "1px solid black",
    },

    "& .dropdown-content": {
      width: "220px",
      marginTop: "16px",
    },
  },
});

const Perlengkapan = () => {
  const classes = useStyle();
  const dispatch = useDispatch();
  const { isLoading, dataPerlengkapan } = useSelector(
    (state) => state.perlengkapan
  );

  const columns = [
    {
      title: "Foto",
      dataIndex: "foto",
      render: (image) => {
        return (
          <img
            src={image}
            alt="foto"
            className="shadow rounded-full w-[32px] h-[32px] max-w-full h-auto align-middle border-none"
          />
        );
      },
    },
    {
      title: "Panggilan",
      dataIndex: "panggilan",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Jenis Kelamin",
      dataIndex: "jenisKelamin",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Starting",
      dataIndex: "starting",
      render: (val) => (
        <Badge
          label={val}
          backgroundColor="rgba(191, 144, 1, 0.2)"
          color="#C08F01"
        />
      ),
    },
    {
      title: "Cabang",
      dataIndex: "cabang",
      render: (val) => (
        <Badge label={val} backgroundColor="#E7BC91" color="#603808" />
      ),
    },
    {
      title: "Perlengkapan",
      dataIndex: "perlengkapan",
      className: "whitespace-nowrap",
      render: (value) => (
        <>
          <div className="flex flex-row">
            <PerlengkapanContent value={value} />
          </div>
        </>
      ),
    },
    {
      title: "",
      dataIndex: "Action",
      className: "whitespace-nowrap",
      render: (s) => (
        <div>
          <div className="w-20 overflow-hidden">
            <p className="whitespace-nowrap text-ellipsis">{s}</p>
          </div>
          <div className="action">
            <Eye
              onClick={() => alert(`show id:`)}
              size="15"
              color="#141414"
              className="cursor-pointer"
            />
            <Trash
              onClick={() => alert(`delete id:`)}
              size="15"
              color="#141414"
              className="cursor-pointer"
            />
            <DocumentCopy
              onClick={() => setIsDuplicate(true)}
              size="15"
              color="#141414"
              className="cursor-pointer"
            />
            <Edit
              onClick={() => alert(`edit id:`)}
              size="15"
              color="#141414"
              className="cursor-pointer"
            />
          </div>
        </div>
      ),
    },
  ];

  const [options, setOptions] = useState([
    {
      value: "cabang",
      label: "Berdasarkan Starting",
    },
    {
      value: "cabang",
      label: "Berdasarkan Cabang",
    },
  ]);

  // Loading
  // const [isLoading, setIsLoading] = useState(false);
  // Expaneble
  const [isExpand, setIsExpand] = useState(false);
  // Selection & type state
  const [selectionType, setSelectionType] = useState("checkbox");
  const [withSelection, setWithSelection] = useState(false);
  const [isDuplicate, setIsDuplicate] = React.useState(false);

  // Pagination state
  const [currentpage, setCurrentpage] = useState(1);
  const [size, setSize] = useState(10);
  const total = 100;

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: dataPerlengkapan?.total_data,
    current: currentpage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20"],
  };

  // func to set size pagination
  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setSize(pageSize);
  };

  // if have expaneble, this is the props example..
  const expandable = {
    expandedRowRender: (record) => <p style={{ margin: 0 }}>{record.name}</p>,
    rowExpandable: (record) => record.name !== "Not Expandable",
  };
  // manipulated data with action
  // const sourceData = data;
  const [open, setOpen] = useState(false);
  const handleClosePopup = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    const payload = {
      sort_column: "",
      page_number: currentpage,
      sort_order: "DESC",
      keyword: "",
    };
    dispatch(handleGetAllPerlengkapan(payload));
  }, [currentpage]);

  return (
    <>
      <div className={classes.container}>
        <div className="w-[100%] flex flex-row items-center justify-between px-[16px] !py-[9px]">
          <div className="flex flex-row gap-[16px]">
            <div>
              <Inputs
                id="test"
                placeholder="Search"
                customClass="!px-3 !py-[6px] !pl-[42px] !w-[283px]"
                iconPosition="left"
                icon={IconSearch}
              />
            </div>
            <div>
              <img src={IconActivity} alt="activity" className="py-[9px]" />
            </div>
            <div>
              <img src={IconActivity} alt="activity" className="py-[9px]" />
            </div>
          </div>
          <div className="flex flex-row gap-[16px] pt-[9px]">
            <div className="border rounded-lg !w-[112px] !h-[36px] flex flex-row items-center gap-[8px] justify-center">
              <img src={Filter} alt="filter" className="w-4 h-4 ml-[6px]" />
              <Dropdowns
                label="Filter"
                placement="bottomRight"
                customClass="border-0"
                containerClass="!w-[300px]"
              >
                <div className="dropdown-content !p-[8px]">
                  <div className="flex flex-col gap-[16px] justify-center items-center">
                    <div>
                      <Selects
                        label=""
                        placeholder="Berdasarkan Cabang"
                        customClass="!w-[200px] !bg-white"
                        containerClass="!border !rounded-lg"
                        options={options}
                        itemRenderer={({ state, item, methods }) => {
                          return (
                            <div
                              className={`react-dropdown-select-item ${
                                state.values.length !== 0 &&
                                state.values[0].value === item.value &&
                                "react-dropdown-select-item-selected"
                              }`}
                              onClick={() => methods.addItem(item)}
                            >
                              {item.label}
                            </div>
                          );
                        }}
                      />
                    </div>
                    <div className="overflow-auto flex flex-col gap-[16px] justify-start items-start w-full h-[140px]">
                      <Badge
                        label="BPN-CGK"
                        color="#C08F01"
                        backgroundColor="rgba(191, 144, 1, 0.2)"
                      />
                      <Badge
                        label="SUB-CGK"
                        color="#D5B60A"
                        backgroundColor="rgba(213, 182, 10, 0.2)"
                      />
                      <Badge
                        label="SOC-CGK"
                        color="#FE9A00"
                        backgroundColor="rgba(254, 153, 1, 0.2)"
                      />
                    </div>
                    <div className="absolute inset-x-0 bottom-0 py-[8px] px-[12px]">
                      <Buttons
                        text="Terapkan"
                        customClass="btn-primary btn-medium"
                      />
                    </div>
                  </div>
                </div>
              </Dropdowns>
            </div>
            <Popup
              open={open}
              handleClose={handleClosePopup}
              title="Tambah Perlengkapan"
              containerClass=""
              width="1080px"
            >
              <PerlengkapanPopup setOpen={setOpen} />
            </Popup>

            <div>
              <Buttons
                customClass="btn-secondary btn-sm !w-[100px] !h-[32px]"
                text="Tambah Data"
                onClick={() => setOpen(true)}
              />
            </div>
            <div>
              <Buttons
                customClass="btn-primary btn-sm !w-[100px] !h-[32px]"
                text="Simpan"
              />
            </div>
          </div>
        </div>
        <MoslemeTable
          loading={isLoading}
          columns={columns}
          data={dataPerlengkapan?.data ? dataPerlengkapan?.data : []}
          withSelection
          selectionType="checkbox"
          pagination={pagination}
          customClass="overflow-auto"
          customPaginationClass="z-1 py-4 px-8"
          onPageChange={(page) => setCurrentpage(page)}
          onSizeChange={(currentpage, pageSize) =>
            onSizeChange(currentpage, pageSize)
          }
        />
      </div>
    </>
  );
};

export default Perlengkapan;
