import { useDispatch, useSelector } from "react-redux";
import Sliders from "../../../../components/slider";
import {
  getAllDataMaster,
  keyDataMaster,
} from "../../../../stores/actions/datamaster";
import { ReactComponent as Maskapai } from "../../../../assets/icons/Maskapai.svg";
import { ReactComponent as MaskapaiActived } from "../../../../assets/icons/Maskapai1.svg";
import { ReactComponent as Bandara } from "../../../../assets/icons/Bandara.svg";
import { ReactComponent as BandaraActived } from "../../../../assets/icons/Bandara1.svg";
import { ReactComponent as Transportasi } from "../../../../assets/icons/Kendaraan.svg";
import { ReactComponent as TransportasiActived } from "../../../../assets/icons/Kendaraan1.svg";
import { ReactComponent as Akomodasi } from "../../../../assets/icons/Hotel Akomodasi.svg";
import { ReactComponent as AkomodasiActived } from "../../../../assets/icons/Hotel Akomodasi1.svg";
import { ReactComponent as Musim } from "../../../../assets/icons/Musim.svg";
import { ReactComponent as MusimActived } from "../../../../assets/icons/Musim1.svg";
import { ReactComponent as Rekening } from "../../../../assets/icons/Rekening.svg";
import { ReactComponent as RekeningActived } from "../../../../assets/icons/Rekening1.svg";
import { ReactComponent as Additional } from "../../../../assets/icons/Additional.svg";
import { ReactComponent as AdditionalActived } from "../../../../assets/icons/Additional1.svg";
import { ReactComponent as Starting } from "../../../../assets/icons/Starting.svg";
import { ReactComponent as StartingActived } from "../../../../assets/icons/Starting1.svg";
import { ReactComponent as Perlengkapan } from "../../../../assets/icons/perlengkapan.svg";
import { ReactComponent as Perlengkapan1 } from "../../../../assets/icons/Perlengkapan1.svg";
import { ReactComponent as Pembimbing } from "../../../../assets/icons/Pembimbing.svg";
import { ReactComponent as Pembimbing1 } from "../../../../assets/icons/Pembimbing1.svg";

import { useEffect } from "react";

function SliderTab() {
  const dispatch = useDispatch();
  const { keyData, allDataMaster } = useSelector((state) => state.datamaster);

  useEffect(() => {
    dispatch(getAllDataMaster());
  }, [dispatch]);

  let dataMaster = allDataMaster?.data;
  // console.log('master data',allDataMaster);

  const breakPoint = [
    {
      breakpoint: 1875,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1712,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1535,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1279,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
  ];

  const menuMasterData = [
    {
      title: `Maskapai (${
        dataMaster?.jml_masakapai === undefined
          ? "0"
          : dataMaster?.jml_masakapai
      })`,
      key: "maskapai",
      icon: Maskapai,
      icon_actived: MaskapaiActived,
    },
    {
      title: `Bandara (${
        dataMaster?.jml_bandara === undefined ? "0" : dataMaster?.jml_bandara
      })`,
      key: "bandara",
      icon: Bandara,
      icon_actived: BandaraActived,
    },
    {
      title: `Transportasi (${
        dataMaster?.jml_transportasi === undefined
          ? "0"
          : dataMaster?.jml_transportasi
      })`,
      key: "transportasi",
      icon: Transportasi,
      icon_actived: TransportasiActived,
    },
    {
      title: `Akomodasi (${
        dataMaster?.jml_akomodasi === undefined
          ? "0"
          : dataMaster?.jml_akomodasi
      })`,
      key: "akomodasi",
      icon: Akomodasi,
      icon_actived: AkomodasiActived,
    },
    {
      title: `Musim (${
        dataMaster?.jml_musim === undefined ? "0" : dataMaster?.jml_musim
      })`,
      key: "musim",
      icon: Musim,
      icon_actived: MusimActived,
    },
    {
      title: `Rekening (${
        dataMaster?.jml_rekening === undefined ? "0" : dataMaster?.jml_rekening
      })`,
      key: "rekening",
      icon: Rekening,
      icon_actived: RekeningActived,
    },

    {
      title: `Additional (${
        dataMaster?.jml_additional === undefined
          ? "0"
          : dataMaster?.jml_additional
      })`,
      key: "additional",
      icon: Additional,
      icon_actived: AdditionalActived,
    },

    {
      title: `Starting (${
        dataMaster?.jml_starting === undefined ? "0" : dataMaster?.jml_starting
      })`,
      key: "starting",
      icon: Starting,
      icon_actived: StartingActived,
    },

    {
      title: `Perlengkapan (${
        dataMaster?.jml_perlengkapan === undefined
          ? "0"
          : dataMaster?.jml_perlengkapan
      })`,
      key: "perlengkapan",
      icon: Perlengkapan,
      icon_actived: Perlengkapan1,
    },

    {
      title: `Pembimbing (${
        dataMaster?.jml_pembimbing === undefined
          ? "0"
          : dataMaster?.jml_pembimbing
      })`,
      key: "pembimbing & guide",
      icon: Pembimbing,
      icon_actived: Pembimbing1,
    },
  ];

  return (
    <>
      <div className="m-auto lg:w-[45rem]  xl:w-[50rem] 2xl:w-[70rem] 3xl:w-[77rem] 4xl:w-[92rem] ">
        <Sliders
          responsive={breakPoint}
          slidesToShow={6}
          customClass="flex flex-row"
        >
          {menuMasterData.map((data, i) => (
            <div
              key={i}
              onClick={() => dispatch(keyDataMaster(data.key))}
              className={`px-3 py-1.5  !flex items-center justify-center cursor-pointer rounded-xl font-normal slider-item-custom ${
                data?.key === keyData
                  ? "active text-white font-semibold"
                  : "bg-transparent"
              }`}
            >
              {data?.key === keyData ? (
                <data.icon_actived className="w-7 h-7 text-green-500 mr-2" />
              ) : (
                <data.icon className="w-7 h-7 text-green-500 mr-2" />
              )}

              <span className="text-sm"> {data.title}</span>
            </div>
          ))}
        </Sliders>
      </div>
    </>
  );
}

export default SliderTab;
