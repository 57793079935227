import axios from "axios";
import { BASE_URL_ENDPOINT } from "constant/constanta";

export const saveFAQDataService = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/save-faq`, payload);

export const saveEditFAQDataService = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/save-faq`, payload);

export const getAllFAQService = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/get-faq/`, payload);

export const getEditFAQService = (payload) =>
  axios.get(`${BASE_URL_ENDPOINT}/admin/faq/${payload}`);

export const deleteIdFAQService = (id) =>
  axios.delete(`${BASE_URL_ENDPOINT}/admin/faq/${id}`);
