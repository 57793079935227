import {
  START_FETCHING_ADDITIONAL,
  SUCCESS_FETCHING_ADDITIONAL,
  ERROR_FETCHING_ADDITIONAL,
  SET_PAGE_ADDITIONAL,
  SET_SIZE_ADDITIONAL,
  SET_KEYWORD_ADDITIONAL,
  SET_TOTAL_ADDITIONAL,
  SET_ID_ROLE,
} from "./actionTypes";
import {
  deleteAdditional,
  getAdditionalByPaket,
  saveAdditional,
} from "utils/api/paket/additional";
import debounce from "debounce-promise";
import { handleError } from "stores/actions/errorGeneral";
let debounceGetAdditional = debounce(getAdditionalByPaket, 500);

const startFetchingAdditional = () => ({
  type: START_FETCHING_ADDITIONAL,
});

const errorFetchingAdditional = () => ({
  type: ERROR_FETCHING_ADDITIONAL,
});

const successFetchingAdditional = (data) => ({
  type: SUCCESS_FETCHING_ADDITIONAL,
  data,
});

const setPage = (page) => ({
  type: SET_PAGE_ADDITIONAL,
  page,
});

const setSize = (size) => ({
  type: SET_SIZE_ADDITIONAL,
  size,
});

const setTotal = (total) => ({
  type: SET_TOTAL_ADDITIONAL,
  total,
});

const setKeyword = (keyword) => ({
  type: SET_KEYWORD_ADDITIONAL,
  keyword,
});

const setRoleId = (id_role) => ({
  type: SET_ID_ROLE,
  id_role,
});

const fetchDataAdditional = (params) => {
  return async (dispatch, getState) => {
    dispatch(startFetchingAdditional());
    try {
      let per_page = getState().additional.per_page;
      let page_number = getState().additional.page_number;
      let sort_column = getState().additional.sort_column;
      let sort_order = getState().additional.sort_order;
      let keyword = getState().additional.keyword;
      let id_paket = params;
      const payload = {
        per_page,
        page_number,
        sort_column,
        sort_order,
        keyword,
        id_paket,
      };

      // if (id_paket !== "0") {
      //   payload.id_paket = id_paket;
      // }
      let res = await debounceGetAdditional(payload);
      if (res.data.code === 401) {
        window.location.href = "/example";
      }
      let data = res.data.data === "" ? [] : res.data.data;
      dispatch(successFetchingAdditional(data));
      dispatch(setTotal(res.data.total_data));
      dispatch(setPage(res.data.page));
    } catch (error) {
      console.log("error: ", error.response);
      dispatch(errorFetchingAdditional());
    }
  };
};

const createAdditional = async (payload, action) => {
  try {
    let res = await saveAdditional(payload);
    if (res.status === 200) action();
  } catch (error) {
    console.log("error: ", error.response);
  }
};

export const saveAdditionalPaket = (payload, actionSuccess, actionError) => {
  return async (dispatch) => {
    try {
      const res = await saveAdditional(payload);

      if (res.status === 200) {
        actionSuccess(res.data);
      } else {
        actionError(res.data);
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

const deleteAdditionals = (id, actionSuccess, actionError) => {
  return async (dispatch) => {
    dispatch(startFetchingAdditional());
    try {
      let res = await deleteAdditional(id);
      if (res.status === 200) {
        actionSuccess(res.data);
      } else {
        actionError(res.data);
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

const AdditionalAction = {
  fetchData: fetchDataAdditional,
  createData: createAdditional,
  deleteData: deleteAdditionals,
  setPage: setPage,
  setSize: setSize,
  setKeyword: setKeyword,
  setRoleId: setRoleId,
};

export default AdditionalAction;
