import Rates from "components/form/rate";
import { Trash, Edit } from "iconsax-react";
import moment from "moment";
import { detailById } from "stores/actions/general";

export const columns = (
  dispatch,
  paketAkomodasi,
  setIsDelete,
  setChoce,
  setIsOpen
) => [
  {
    title: "No",

    render: (_, item) => (
      <div className="flex flex-row">
        <div className="ml-2">
          {paketAkomodasi.length > 0 && paketAkomodasi.indexOf(item) + 1}
        </div>
      </div>
    ),
  },
  {
    title: "Nama Hotel",
    dataIndex: "kategori",
    render: (_, _akomodasi) => (
      <div className="flex flex-row">
        <div className="ml-2">{_akomodasi.name}</div>
      </div>
    ),
  },
  {
    title: "Kota",
    dataIndex: "alamat_lengkap",
    render: (_, item) => (
      <div className="flex flex-row">
        <div className="ml-2">{item.city}</div>
      </div>
    ),
  },
  {
    title: "Rating",
    dataIndex: "rating",
    render: (_, item) => (
      <>
        <Rates value={item?.bintang} />
      </>
    ),
  },
  {
    title: "Tanggal Check In",
    dataIndex: "checkin_date",
    render: (_, item) => {
      return (
        <div className="flex flex-row">
          <div className="ml-2">{item.checkin_date}</div>
        </div>
      );
    },
  },
  {
    title: "Tanggal Check Out",
    dataIndex: "checkout_date",
    render: (checkout_date, item) => (
      <>
        <div className="flex flex-row">
          <div className="ml-2">{checkout_date}</div>
        </div>

        <div className="action absolute !w-full top-2 flex !flex-row !justify-end">
          <div className="flex flex-row justify-end pr-10 gap-x-16">
            <Trash
              className="cursor-pointer"
              size="15"
              color="#141414"
              onClick={() => {
                setIsDelete(true);
                setChoce(item);
              }}
            />
            <Edit
              onClick={() => {
                setIsOpen(true);
                setChoce(item);
              }}
              size="15"
              color="#141414"
              className="cursor-pointer"
            />
          </div>
        </div>
      </>
    ),
  },
];
