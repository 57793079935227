import { Rate } from "antd";
import RateContainer from "./Rate.style";
import PropTypes from "prop-types";

function Rates({ containerClass, setValue, value, disabled }) {
  return (
    <RateContainer className={`${containerClass}`}>
      <Rate onChange={setValue} value={value} disabled={disabled} />
    </RateContainer>
  );
}

Rates.propTypes = {
  containerClass: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func,
};

export default Rates;
