import axios from "axios";
import {
  BASE_URL_ENDPOINT
} from "../../../constant/constanta";


export const getDataMaskapai = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/kalkulator-paket/get-maskapai-by-month-and-year`, payload);

export const getDataAkomodasi = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/kalkulator-paket/get-akomodasi-by-month-and-year`, payload);

export const getDataAkomodasiTourCountry = (payload) =>
axios.post(`${BASE_URL_ENDPOINT}/admin/kalkulator-paket/get-tour-akomodasi-by-month-and-year`, payload);