/* eslint-disable import/no-anonymous-default-export */
// general Reducers
// --------------------------------------------------------

import {
  SET_LOADING,
  CLEAR_ERROR,
  SET_ERROR,
  INIT_DATA,
  SET_DOUBLE_SUBMIT,
  SET_DETAIL_BY_ID,
  SET_GLOBAL_STATE,
  SET_AFTER_VALIDATION,
  SET_VALIDATION,
} from "../../actions/general";

const initialState = {
  isLoading: false,
  isDoubleSubmit: false,
  isSubmitting: false,
  error: {},
  byDetail: {},
  global: {
    currentPage: 1,
    size: 10,
  },
  afterValidation: {},
  validation: {},
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_DOUBLE_SUBMIT:
      return {
        ...state,
        isDoubleSubmit: payload,
      };
    case SET_ERROR:
      return {
        ...state,
        error: payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: {},
      };
    case INIT_DATA:
      return {
        ...initialState,
      };
    case SET_DETAIL_BY_ID:
      return {
        ...state,
        byDetail: payload,
      };
    case SET_GLOBAL_STATE:
      return {
        ...state,
        global: payload,
      };
    case SET_AFTER_VALIDATION:
      return {
        ...state,
        afterValidation: payload,
      };
    case SET_VALIDATION:
      return {
        ...state,
        validation: payload,
      };
    default:
      return state;
  }
};
