import axios from "axios";
import { BASE_URL_ENDPOINT } from "../../../constant/constanta";

// url jumlah data master
export const getNumberDataMaster = (payload) =>
  axios.get(`${BASE_URL_ENDPOINT}/admin/get-jml-data`);

// url maskapai
export const getAllMaskapai = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/get-maskapai`, payload);

export const getDetailMaskapai = (payload) =>
  axios.get(`${BASE_URL_ENDPOINT}/admin/maskapai/${payload}`);

export const addMaskapai = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/save-maskapai`, payload);

export const editMaskapai = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/save-maskapai`, payload);

export const duplicateMaskapai = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/maskapai/duplicate`, payload);

export const deleteMaskapai = (payload) =>
  axios.delete(`${BASE_URL_ENDPOINT}/admin/maskapai/${payload}`);

// url bandara

export const getAllBandara = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/get-bandara`, payload);

export const addBandara = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/save-bandara`, payload);

export const duplicateBandara = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/bandara/duplicate`, payload);

export const deleteBandara = (payload) =>
  axios.delete(`${BASE_URL_ENDPOINT}/admin/bandara/${payload}`);

export const getRegion = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/region`, payload);

export const editBandara = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/save-bandara`, payload);

export const getDetailBandara = (payload) =>
  axios.get(`${BASE_URL_ENDPOINT}/admin/bandara/${payload}`);

// url transport
export const getAllTransport = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/get-transportasi`, payload);

export const addTransport = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/save-transportasi`, payload);

export const deleteTransport = (payload) =>
  axios.delete(`${BASE_URL_ENDPOINT}/admin/transportasi/${payload}`);

export const duplicateTransport = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/transportasi/duplicate`, payload);

export const getDetailTransport = (payload) =>
  axios.get(`${BASE_URL_ENDPOINT}/admin/transportasi/${payload}`);

export const editTransport = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/save-transportasi`, payload);

// url akomodasi
export const getAllAkomodasi = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/get-akomodasi`, payload);

export const addAkomodasi = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/save-akomodasi`, payload);

export const deleteAkomodasi = (payload) =>
  axios.delete(`${BASE_URL_ENDPOINT}/admin/akomodasi/${payload}`);

export const duplicateAkomodasi = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/akomodasi/duplicate`, payload);

export const getDetailAkomodasi = (payload) =>
  axios.get(`${BASE_URL_ENDPOINT}/admin/akomodasi/${payload}`);

export const editAkomodasi = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/save-akomodasi`, payload);

// url perlengkapan
export const getAllPerlengkapan = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/get-perlengkapan`, payload);

export const addPerlengkapan = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/save-perlengkapan`, payload);

export const deletePerlengkapan = (payload) =>
  axios.delete(`${BASE_URL_ENDPOINT}/admin/perlengkapan/${payload}`);

export const duplicatePerlengkapan = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/perlengkapan/duplicate`, payload);

export const getDetailPerlengkapan = (payload) =>
  axios.get(`${BASE_URL_ENDPOINT}/admin/perlengkapan/${payload}`);

export const editPerlengkapan = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/save-perlengkapan`, payload);

// url pembimbing
export const getAllPembimbing = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/get-pembimbing`, payload);

export const deletePembimbing = (payload) =>
  axios.delete(`${BASE_URL_ENDPOINT}/admin/pembimbing/${payload}`);

export const duplicatePembimbing = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/pembimbing/duplicate`, payload);

export const addPembimbing = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/save-pembimbing`, payload);

export const getDetailPembimbing = (payload) =>
  axios.get(`${BASE_URL_ENDPOINT}/admin/pembimbing/${payload}`);

export const editPembimbing = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/save-pembimbing`, payload);
