import React, { useState } from "react";

import MoslemeTable from "components/Table";
import Badge from "components/badge";

import { DocumentCopy, Edit, Eye, Trash } from "iconsax-react";

const HistoryPendapatanFee = () => {
  // Loading
  const [isLoading, setIsLoading] = useState(false);
  // Expaneble
  const [isExpand, setIsExpand] = useState(false);
  // Selection & type state
  const [selectionType, setSelectionType] = useState("checkbox");
  const [withSelection, setWithSelection] = useState(false);

  // Pagination state
  const [currentpage, setCurrentpage] = useState(1);
  const [size, setSize] = useState(10);
  const total = 100;

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: total,
    current: currentpage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20"],
  };

  // func to set size pagination
  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setSize(pageSize);
  };

  // if have expaneble, this is the props example..
  const expandable = {
    expandedRowRender: (record) => <p style={{ margin: 0 }}>{record.name}</p>,
    rowExpandable: (record) => record.name !== "Not Expandable",
  };

  const columns = [
    {
      title: "Nama Paket",
      dataIndex: "name",
      render: (text) => <div>{text}</div>,
    },
    {
      title: "Jenis Paket",
      dataIndex: "paket",
      render: (pkt) => <Badge label="Umroh" customClass="mt-1" />,
    },
    {
      title: "Tanggal Pendapatan",
      dataIndex: "date",
    },
    {
      title: "Jumlah Pax",
      dataIndex: "pax",
      render: (s) => (
        <div>
          <div>{s} pax</div>
          <div className="action">
            <Eye onClick={() => alert(`show id:`)} size="15" color="#141414" />
            <Trash
              onClick={() => alert(`delete id:`)}
              size="15"
              color="#141414"
            />
          </div>
        </div>
      ),
    },
    {
      title: "Nominal Pendapatan",
      dataIndex: "income",
      className: "whitespace-nowrap relative",
      render: (s) => (
        <div>
          <div className="font-semibold">Rp {s}</div>
          <div className="action">
            <DocumentCopy
              onClick={() => alert(`copy id:`)}
              size="15"
              color="#141414"
            />
            <Edit onClick={() => alert(`edit id:`)} size="15" color="#141414" />
          </div>
        </div>
      ),
    },
  ];

  // manipulated data with action
  const sourceData = [
    {
      key: "1",
      name: "Umroh Bersama Ustadz Muhamammad",
      paket: "Umroh",
      date: "15 Desember 2023",
      pax: "2",
      income: "5.000.000",
    },
    {
      key: "2",
      name: "Umroh Bersama Ustadz Muhamammad",
      paket: "Umroh",
      date: "15 Desember 2023",
      pax: "2",
      income: "5.000.000",
    },
    {
      key: "3",
      name: "Umroh Bersama Ustadz Muhamammad",
      paket: "Umroh",
      date: "15 Desember 2023",
      pax: "2",
      income: "5.000.000",
    },
  ];

  return (
    <>
      <MoslemeTable
        loading={isLoading}
        expandable={isExpand && expandable}
        columns={columns}
        data={sourceData}
        withSelection={withSelection}
        selectionType={selectionType}
        pagination={pagination}
        onPageChange={(page) => setCurrentpage(page)}
        onSizeChange={(currentpage, pageSize) =>
          onSizeChange(currentpage, pageSize)
        }
      />
    </>
  );
};

export default React.memo(HistoryPendapatanFee);
