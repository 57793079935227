import Tab from "components/tab";
import Inputs from "components/form/inputs";

import IconAlbumPerjalanan from "assets/icons/colored/Album-Perjalanan.svg";
import IconDataJamaah from "assets/icons/Album-Jamaah-2.svg";
import AlbumSelamaBeribadah from "./Tabs/AlbumSelamaBeribadah";
import AlbumDataJamaah from "./Tabs/AlbumDataJamaah";
import Buttons from "components/buttons";
// import IconAirplane from "../../../../../assets/icons/colored/airplan-3-icon.svg";
// import IconBusInactive from "../../../../../assets/icons/colored/bus-inactive-icon.svg";

const AlbumTabs = [
  {
    label: (
      <span className="flex gap-1">
        <img src={IconAlbumPerjalanan} alt="icon" />
        Album Selama Beribadah
      </span>
    ),
    key: "1",
    children: <AlbumSelamaBeribadah />,
  },
  {
    label: (
      <span className="flex gap-1">
        <img src={IconDataJamaah} alt="icon" />
        Album Data Jamaah
      </span>
    ),
    key: "2",
    children: <AlbumDataJamaah />,
  },
];

const Album = () => {
  return (
    <>
      <div className="p-5 border-b relative">
        <div>
          <Tab defaultActiveKey="1" items={AlbumTabs} />
        </div>
      </div>
    </>
  );
};

export default Album;
