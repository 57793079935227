import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AlertPopup from "../../../../../components/popup/alert";
import MoslemeTable from "../../../../../components/Table";
import useDebounce from "../../../../../utils/helpers/useDebounce";

import { getMasterId } from "stores/actions/datamaster";
import {
  deleteIdMasterStarting,
  duplicateStarting,
  getMasterStarting,
} from "stores/actions/datamaster/starting";
import { columns } from "./columns";
import Popup from "components/popup";
import Inputs from "components/form/inputs";
import CheckboxMaster from "../../checkbox";
import Buttons from "components/buttons";
import {
  setValidation,
  set_after_validation,
} from "stores/actions/general";

const Starting = ({ filter, onHandleEdit }) => {
  const { masterId } = useSelector((state) => state.datamaster);
  const { global, validation, afterValidation } = useSelector(
    (state) => state.general
  );
  const { allStarting, isLoading } = useSelector(
    (state) => state.adminStarting
  );

  // Selection & type state
  const [selectionType, setSelectionType] = useState("checkbox");
  const [input, setInput] = useState({
    name: "",
    id_bandara_departure: true,
    id_bandara_return: true,
    price_single: true,
    price_double: true,
    price_tripple: true,
    price_quad: true,
    description: true,
  });
  const [currentpage, setCurrentpage] = useState(1);
  const [size, setSize] = useState(10);

  useEffect(() => {
    masterId?.data?.name &&
      setInput({
        name: `clone - ${masterId?.data?.name}`,
        id_bandara_departure: true,
        id_bandara_return: true,
        price_single: true,
        price_double: true,
        price_tripple: true,
        price_quad: true,
        description: true,
      });
  }, [masterId?.data?.name]);
  // Pagination state
  const total = allStarting?.total_data;

  // data table
  let dataTable = allStarting.data;

  const dispatch = useDispatch();

  // filter data table
  const startingDebounce = useDebounce(filter, 1000);

  const onHandleDelete = () => {
    dispatch(deleteIdMasterStarting(masterId?.data?.id_starting)).then(() => {
      dispatch(getMasterId({}));
      masterStarting();
    });
  };

  const masterStarting = useCallback(() => {
    dispatch(
      getMasterStarting({
        page_number: currentpage,
        per_page: size,
        keyword: startingDebounce,
        sort_column: "id_starting",
        sort_order: "DESC",
      })
    );
  }, [currentpage, dispatch, size, startingDebounce]);

  useEffect(() => {
    masterStarting();
  }, [masterStarting]);

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: total,
    current: currentpage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20", "50", "100"],
  };

  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setSize(pageSize);
  };

  const onHandleDuplicate = () => {
    input.id = masterId?.data?.id_starting;
    input.id_bandara_departure = input.id_bandara_departure ? 1 : 0;
    input.id_bandara_return = input.id_bandara_return ? 1 : 0;
    input.price_single = input.price_single ? 1 : 0;
    input.price_double = input.price_double ? 1 : 0;
    input.price_tripple = input.price_tripple ? 1 : 0;
    input.price_quad = input.price_quad ? 1 : 0;
    input.description = input.description ? 1 : 0;
    input.color = 1;

    // return console.log("senggol dong", input);
    dispatch(duplicateStarting(input));
  };

  useEffect(() => {
    if (afterValidation?.code === 200) {
      dispatch(getMasterId({}));
      masterStarting();
      dispatch(setValidation({}));
      dispatch(set_after_validation({}));
    }
  }, [afterValidation?.code, dispatch, masterStarting]);

  return (
    <div>
      <AlertPopup
        open={masterId?.modal}
        handleClose={() => dispatch(getMasterId({}))}
        handleContinue={onHandleDelete}
        loading={isLoading ? true : false}
      />

      <Popup
        open={masterId?.popup}
        handleClose={() => {
          dispatch(getMasterId({}));
          dispatch(setValidation({}));
          dispatch(set_after_validation({}));
        }}
        title="Duplicate Starting"
      >
        <Inputs
          id="test"
          placeholder="Text Input"
          // customClass="input-disabled"
          value={input.name}
          onChange={({ target }) => setInput({ ...input, name: target.value })}
          error={validation?.data?.name && validation?.data?.name[0]}
        />
        <CheckboxMaster
          customClass="mt-4"
          title="Berangkat"
          value={input.id_bandara_departure}
          checked={input.id_bandara_departure}
          onChange={(e) =>
            setInput({
              ...input,
              id_bandara_departure: e.target.checked,
            })
          }
        /> 
        <CheckboxMaster
          customClass="mt-4"
          title="Pulang"
          value={input.id_bandara_return}
          checked={input.id_bandara_return}
          onChange={(e) =>
            setInput({
              ...input,
              id_bandara_return: e.target.checked,
            })
          }
        />
        <CheckboxMaster
          customClass="mt-4"
          title="Single"
          value={input.price_single}
          checked={input.price_single}
          onChange={(e) =>
            setInput({
              ...input,
              price_single: e.target.checked,
            })
          }
        />
        <CheckboxMaster
          customClass="mt-4"
          title="Double"
          value={input.price_double}
          checked={input.price_double}
          onChange={(e) =>
            setInput({
              ...input,
              price_double: e.target.checked,
            })
          }
        />
        <CheckboxMaster
          customClass="mt-4"
          title="Quad"
          value={input.price_quad}
          checked={input.price_quad}
          onChange={(e) =>
            setInput({
              ...input,
              price_quad: e.target.checked,
            })
          }
        />
        <CheckboxMaster
          customClass="mt-4"
          title="Description"
          value={input.description}
          checked={input.description}
          onChange={(e) =>
            setInput({
              ...input,
              description: e.target.checked,
            })
          }
        />
        <div className="grid grid-cols-2 mt-4 items-center gap-x-4">
          <Buttons
            onClick={() => dispatch(getMasterId({}))}
            text="Batal"
            customClass="btn-secondary btn-large"
          />

          <Buttons
            onClick={onHandleDuplicate}
            text="Simpan"
            loading={isLoading ? true : false}
            disabled={isLoading ? true : false}
            customClass="btn-primary btn-large"
          />
        </div>
      </Popup>

      <MoslemeTable
        columns={columns(dispatch)}
        loading={isLoading}
        data={dataTable}
        rowId={(record) => record.id_starting}
        withSelection={true}
        selectionType={selectionType}
        rowKey={(record) => record.id_starting}
        pagination={pagination}
        customPaginationClass="pr-4"
        onPageChange={(page) => setCurrentpage(page)}
        onSizeChange={(currentpage, pageSize) => {
          onSizeChange(currentpage, pageSize);
        }}
      />
    </div>
  );
};

export default Starting;
