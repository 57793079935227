import React, { useState } from "react";

import Cards from "../../../../components/cards";
import Popup from "../../../../components/popup";
import Tab from "../../../../components/tab";
import AddArticle from "./components/AddArticle";
import AddContact from "./components/AddContact";
import ArtikelHelpCenter from "./components/ArtikelHelpCenter";
import KontakHelpCenter from "./components/KontakHelpCenter";
import EditContact from "./components/EditContact";

const SettingHelpCenter = () => {
  const [openPopupAddArticle, setOpenPopupAddArticle] = useState(false);
  const [openPopupAddContact, setOpenPopupAddContact] = useState(false);
  const [openPopupEditContact, setOpenPopupEditContact] = useState(false);
  const [activeKontakToEdit, setActiveKontakToEdit] = useState(null);

  const handleEditContact = (data) => {
    setOpenPopupEditContact(true);
    setActiveKontakToEdit(data);
  };

  return (
    <>
      <Popup
        open={openPopupAddArticle}
        handleClose={() => setOpenPopupAddArticle(false)}
        title="Tambah Artikel"
        containerClass=""
        children={
          <AddArticle handleClose={() => setOpenPopupAddArticle(false)} />
        }
      />
      <Popup
        open={openPopupAddContact}
        handleClose={() => setOpenPopupAddContact(false)}
        title="Tambah Kontak"
        containerClass=""
        children={
          <AddContact handleClose={() => setOpenPopupAddContact(false)} />
        }
      />
      <Popup
        open={openPopupEditContact}
        handleClose={() => setOpenPopupEditContact(false)}
        title="Edit Kontak"
        containerClass=""
        children={
          <EditContact
            handleClose={() => setOpenPopupEditContact(false)}
            activeKontakToEdit={activeKontakToEdit}
          />
        }
      />
      <Cards containerClass="!p-0">
        <div className="flex flex-row justify-between items-center p-4">
          <div className="text-[20px] font-semibold">Pusat Bantuan</div>
        </div>
        <div className="divider"></div>
        <div className="p-4">
          <Tab
            defaultActiveKey="1"
            items={[
              {
                label: "Artikel",
                key: "1",
                children: (
                  <ArtikelHelpCenter
                    setOpenPopupAddArticle={setOpenPopupAddArticle}
                  />
                ),
              },
              {
                label: "Kontak",
                key: "2",
                children: (
                  <KontakHelpCenter
                    setOpenPopupAddContact={setOpenPopupAddContact}
                    handleEdit={handleEditContact}
                  />
                ),
              },
            ]}
          />
        </div>
      </Cards>
      <div id="area-modal"></div>
    </>
  );
};

export default React.memo(SettingHelpCenter);
