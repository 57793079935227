// doa Reducers
// --------------------------------------------------------

import {
    SET_LOADING,
    CLEAR_ERROR,
    SET_ERROR,
    INIT_DATA,
    SET_DOUBLE_SUBMIT,
    SET_DOA,
    SET_DOA_CATEGORY,
} from "../../../actions/layananIslami/doa";

const initialState = {
    isLoading: false,
    isDoubleSubmit: false,
    isSubmitting: false,
    error: {},
    doa: [],
    doa_category: [],
};

export default (state = initialState, { payload, type }) => {
    switch (type) {
        case SET_LOADING:
            return {
                ...state,
                isLoading: payload,
            };
        case SET_DOUBLE_SUBMIT:
            return {
                ...state,
                isDoubleSubmit: payload,
            };
        case SET_ERROR:
            return {
                ...state,
                error: payload,
            };
        case CLEAR_ERROR:
            return {
                ...state,
                error: {},
            };
        case INIT_DATA:
            return {
                ...initialState,
            };
        case SET_DOA:
            return {
                ...state,
                doa: payload,
            };
        case SET_DOA_CATEGORY:
            return {
                ...state,
                doa_category: payload,
            };
        default:
            return state;
    }
};
