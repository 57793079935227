import axios from "axios";
import { BASE_URL_ENDPOINT } from "../../../constant/constanta";

const SettingArticleAPI = {
  getListPage: () =>
    axios.post(`${BASE_URL_ENDPOINT}/admin/pusat-bantuan/get-artikel`),
  getArticle: (paylaod) =>
    axios.post(`${BASE_URL_ENDPOINT}/admin/artikel`, paylaod),
  postData: (payload) =>
    axios.post(
      `${BASE_URL_ENDPOINT}/admin/pusat-bantuan/save-artikel`,
      payload
    ),
  deleteData: (id) =>
    axios.delete(`${BASE_URL_ENDPOINT}/admin/pusat-bantuan/artikel/${id}`),
};

const SettingWebAppAPI = {
  postData: (payload) =>
    axios.post(`${BASE_URL_ENDPOINT}/admin/save-setting`, payload),
};
export { SettingArticleAPI, SettingWebAppAPI };
