import { Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";

import Cards from "../../../../components/cards";
import Inputs from "../../../../components/form/inputs";
import Segment from "../../../../components/segment";
import Selects from "../../../../components/form/selects";
import DatePicker from "../../../../components/datepicker/DatePicker";
import ImageField from "../../../../components/image_field";
import Textareas from "../../../../components/form/textarea";
import Buttons from "../../../../components/buttons";
import { listBank } from "../../../../configuration/listBank";

import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  addDataPembayaran,
  getDataRekening,
} from "../../../../stores/actions/tagihan";

const AddPayment = () => {
  const { detailData, dataRekening } = useSelector((state) => state.tagihan);
  const dispatch = useDispatch();

  const [isError, setIsError] = React.useState(false);
  const [options, setOptions] = useState(listBank);
  const formik = useFormik({
    initialValues: {
      type: "Payment",
      date: "",
      sender_name: "",
      sender_rekening: "",
      sender_bank: "",
      amount: "",
      payment_method: "1",
      receipent_account: "1",
      status_payment: "",
      proof_url: "",
      description: "",
    },
  });

  const [currency, setCurrency] = useState([
    {
      value: "idr",
      label: "IDR",
    },
    {
      value: "dollar",
      label: "Dollar",
    },
  ]);

  console.log(dataRekening);

  useEffect(() => {
    dispatch(
      getDataRekening({
        page_number: 1,
        per_page: 10,
        sort_column: "bank_name",
        sort_order: "ASC",
      })
    );
  }, []);

  // submit form
  const handleSave = () => {
    dispatch(
      addDataPembayaran(
        {
          ...formik.values,
          id_order: detailData?.data?.id,
        },
        detailData?.data?.id
      )
    );
  };

  return (
    <Cards containerClass="payment-header-container">
      <Segment
        label="Tipe Transaksi"
        containerClass="mb-4"
        customClass="mt-1"
        options={[
          {
            label: <div>Payment</div>,
            value: "Payment",
          },
          {
            label: <div>Refund</div>,
            value: "Refund",
          },
        ]}
        onChange={(values) => formik.setFieldValue("type", values)}
      />
      <Grid container spacing={2}>
        <Grid item md={5}>
          <Inputs
            id="sender_name"
            name="sender_name"
            label="Akun Pengirim"
            onChange={formik.handleChange}
            value={formik.values.sender_name}
          />
        </Grid>
        <Grid item md={4}>
          <Inputs
            id="sender_rekening"
            name="sender_rekening"
            label="Nomer Rekening"
            onChange={formik.handleChange}
            value={formik.values.sender_rekening}
          />
        </Grid>
        <Grid item md={3}>
          <Selects
            customClass="!text-xs"
            label="Bank"
            options={options}
            onChange={(values) =>
              formik.setFieldValue("sender_bank", values.map((x) => x.value)[0])
            }
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item md={3}>
          <Selects label="Mata Uang" options={currency} />
        </Grid>
        <Grid item md={9}>
          <Inputs
            id="amount"
            name="amount"
            label="Total Harga"
            onChange={formik.handleChange}
            value={formik.values.amount}
          />
        </Grid>
      </Grid>

      <div className="mt-4">
        <Grid item md={12}>
          <Selects label="Akun Penerima" options={dataRekening} />
        </Grid>
      </div>

      <h2 className="font-semibold mt-5 mb-2">Tanggal</h2>
      <DatePicker
        containerClass="mt-4"
        customClass="w-full"
        placeholder="Tanggal"
        onChange={(val) => formik.setFieldValue("date", val)}
      />

      <Segment
        label="Status Verifikasi"
        containerClass="mt-4"
        customClass="mt-1"
        options={[
          {
            label: <div>Terverifikasi</div>,
            value: "1",
          },
          {
            label: (
              <div className="segment-label-error">Belum Terverifikasi</div>
            ),
            value: "2",
          },
        ]}
        onChange={(value) => formik.setFieldValue("payment_method", value)}
      />

      <Segment
        label="Status Transaksi"
        containerClass="mt-4"
        customClass="mt-1"
        options={
          formik.values.payment_method === "1"
            ? [
                {
                  label: <div>Sukses</div>,
                  value: "1",
                },
              ]
            : [
                {
                  label: <div className="segment-label-yellow">Pending</div>,
                  value: "2",
                },
                {
                  label: <div className="segment-label-error">Dibatalkan</div>,
                  value: "3",
                },
              ]
        }
        onChange={(value) => formik.setFieldValue("status_payment", value)}
      />

      <Segment
        label="Diverifikasi Oleh"
        containerClass="mt-4"
        customClass="mt-1"
        options={[
          {
            label: <div>Manual</div>,
            value: "1",
          },
          {
            label: <div className="segment-label-blue">Sistem</div>,
            value: "2",
          },
        ]}
        onChange={(value) => formik.setFieldValue("receipent_account", value)}
      />

      <div className="mt-4">
        <ImageField
          label="Upload Struk"
          customContainer="!w-full"
          error={isError}
          setFile={(file) => formik.setFieldValue("proof_url", file?.path)}
        />
      </div>
      <Textareas
        id="description"
        name="description"
        value={formik.values.description}
        placeholder=""
        label="Catatan"
        containerClass="mt-4"
        onChange={formik.handleChange}
      />
      <Buttons
        text="Simpan"
        onClick={handleSave}
        containerClass="mt-6"
        customClass="btn-primary btn-large"
      />
    </Cards>
  );
};

export default AddPayment;
