import styled from "styled-components";

const PaketDetailStyle = styled.div`
  width: calc(100vw - 124px);

  .ant-tabs-nav {
    width: max-content;
  }

  .ant-table-wrapper .ant-table.ant-table-bordered > .ant-table-container {
    border: 0.5px solid #bdbdbd;
    border-radius: 8px;
  }

  .ant-table-wrapper .ant-table-thead > tr > th {
    background: #ffffff;
  }

  .ant-table-wrapper
    .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > thead
    > tr
    > th,
  .ant-table-wrapper
    .ant-table.ant-table-bordered
    > .ant-table-container
    > .ant-table-content
    > table
    > tbody
    > tr
    > td {
    border: none;
  }

  .ant-table-wrapper .ant-table-thead > tr > th {
    border-top: 1px solid #f0f0f0;
  }

  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:first-child {
    border-start-start-radius: 0;
  }

  .ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:last-child {
    border-start-end-radius: 0;
  }
`;

export default PaketDetailStyle;
