import React from "react";

import Accordions from "./";

import AvatarRed from "../../assets/images/Avatar-ikhwan-red.svg";

const AccordionsDocs = () => {
  return (
    <div className="p-4">
      <Accordions title="Accordion Title 1" content="Test Content 1" />
      <Accordions
        avatar={AvatarRed}
        title="Accordion Title 2"
        content="Test Content 2"
      />
      <Accordions title="Accordion Title 3" content="Test Content 3" />
    </div>
  );
};

export default AccordionsDocs;
