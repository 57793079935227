import { ReactComponent as Maskapai } from "../../../../assets/icons/Maskapai.svg";
import { ReactComponent as MaskapaiActived } from "../../../../assets/icons/Maskapai1.svg";
import { ReactComponent as Bandara } from "../../../../assets/icons/Bandara.svg";
import { ReactComponent as BandaraActived } from "../../../../assets/icons/Bandara1.svg";
import { ReactComponent as Transportasi } from "../../../../assets/icons/Kendaraan.svg";
import { ReactComponent as TransportasiActived } from "../../../../assets/icons/Kendaraan1.svg";
import { ReactComponent as Akomodasi } from "../../../../assets/icons/Hotel Akomodasi.svg";
import { ReactComponent as AkomodasiActived } from "../../../../assets/icons/Hotel Akomodasi1.svg";
import { ReactComponent as Musim } from "../../../../assets/icons/Musim.svg";
import { ReactComponent as MusimActived } from "../../../../assets/icons/Musim1.svg";
import { ReactComponent as Rekening } from "../../../../assets/icons/Rekening.svg";
import { ReactComponent as RekeningActived } from "../../../../assets/icons/Rekening1.svg";
import { ReactComponent as Additional } from "../../../../assets/icons/Additional.svg";
import { ReactComponent as AdditionalActived } from "../../../../assets/icons/Additional1.svg";
import { ReactComponent as Starting } from "../../../../assets/icons/Starting.svg";
import { ReactComponent as StartingActived } from "../../../../assets/icons/Starting1.svg";
import { ReactComponent as Perlengkapan } from "../../../../assets/icons/perlengkapan.svg";
import { ReactComponent as Perlengkapan1 } from "../../../../assets/icons/Perlengkapan1.svg";
import { ReactComponent as Pembimbing } from "../../../../assets/icons/Pembimbing.svg";
import { ReactComponent as Pembimbing1 } from "../../../../assets/icons/Pembimbing1.svg";

export const menuModal = [
  {
    title: "Maskapai",
    key: "maskapai",
    icon: Maskapai,
    icon_actived: MaskapaiActived,
  },
  {
    title: "Bandara",
    key: "bandara",
    icon: Bandara,
    icon_actived: BandaraActived,
  },
  {
    title: "Transportasi",
    key: "transportasi",
    icon: Transportasi,
    icon_actived: TransportasiActived,
  },
  {
    title: "Akomodasi",
    key: "akomodasi",
    icon: Akomodasi,
    icon_actived: AkomodasiActived,
  },
  {
    title: "Musim",
    key: "musim",
    icon: Musim,
    icon_actived: MusimActived,
  },
  {
    title: "Rekening",
    key: "rekening",
    icon: Rekening,
    icon_actived: RekeningActived,
  },

  {
    title: "Additional",
    key: "additional",
    icon: Additional,
    icon_actived: AdditionalActived,
  },

  {
    title: "Starting",
    key: "starting",
    icon: Starting,
    icon_actived: StartingActived,
  },

  {
    title: "Perlengkapan",
    key: "perlengkapan",
    icon: Perlengkapan,
    icon_actived: Perlengkapan1,
  },

  {
    title: "Pembimbing & Guide",
    key: "pembimbing & guide",
    icon: Pembimbing,
    icon_actived: Pembimbing1,
  },
];
