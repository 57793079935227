import React, { useState } from "react";

import Buttons from "../../../../../../../components/buttons";
import Badge from "../../../../../../../components/badge";
import Tab from "../../../../../../../components/tab";
import TabTagihan from "./components/TabTagihan";
import { useSelector, useDispatch } from "react-redux";
import { NumericFormat } from "react-number-format";

import IconBack from "../../../../../../../assets/icons/arrow-left.svg";
import IconPrint from "../../../../../../../assets/icons/Print.svg";
import Photo from "../../../../../../../assets/images/Photo.png";
import IconEdit from "../../../../../../../assets/icons/edit.svg";
import IconDelete from "../../../../../../../assets/icons/trash.svg";
import TabTagihanStyle from "./TabTagihan.Style";
import TabPembayaran from "./components/TabPembayaran";
import { Grid } from "@material-ui/core";

const PaketTagihanDetail = ({ setShowDetail }) => {
  const [activeTab, setActiveTab] = useState("1");

  const { detailData, status } = useSelector((state) => state.paketDetail);
  let datas = detailData.data;
  const dispatch = useDispatch();

  const columns = [
    {
      title: "Nama",
      dataIndex: "name",
    },
    {
      title: "Jumlah",
      dataIndex: "total",
    },
    {
      title: "Harga",
      dataIndex: "price",
    },
    {
      title: "Catatan",
      dataIndex: "note",
    },
  ];

  const data = [
    {
      key: "1",
      name: "Suntik Meningitis",
      total: "1",
      note: "-",
      price: "Rp 500.000",
      action: "whatsapp",
    },
    {
      key: "2",
      name: "Kerete Cepat",
      total: "1",
      note: "-",
      price: "Rp 2.000.000",
      action: "whatsapp",
    },
    {
      key: "3",
      name: "Kursi Roda",
      total: "1",
      note: "-",
      price: "Rp 1.000.000",
      action: "whatsapp",
    },
  ];

  console.log(datas?.user_jamaah?.name);

  return (
    <TabTagihanStyle>
      <div className="flex flex-row gap-2">
        <img
          className="cursor-pointer"
          src={IconBack}
          alt="back"
          onClick={() => setShowDetail(false)}
        />
        <div className="text-[18px] font-semibold">Tagihan Jamaah</div>
      </div>
      <div className="border-[1px] border-[#E0E0E0] p-4 rounded-2xl flex flex-row justify-between items-center mt-4">
        <div className="flex flex-row items-center gap-4">
          <div className="text-[20px] font-semibold">#UMR-987</div>
          <div className="w-[0.5px] h-[24px] bg-[#141414]"></div>
          <div className="text-[18px]">12 Oktober 2022</div>
          <div className="text-[18px]">14:07 WIB</div>
          <Buttons
            text="Print"
            customClass="btn-outline btn-small !px-3 !py-1"
            icon={IconPrint}
          />
        </div>
        <Badge
          label="Dibayar Sebagian"
          type="orange dark"
          customClass="uppercase !px-4 !py-[6px]"
        />
      </div>
      <div className="mt-4 w-max">
        <div className="flex flex-row rounded-xl px-6 py-3 pink-bg-shine">
          <img
            className="w-[56px] h-[56px] border-[1px] border-white rounded-[48px]"
            src={Photo}
            alt="user-img"
          />
          <div className="ml-3">
            <div className="text-[20px] font-semibold text-white">
              {datas?.user_jamaah?.name}
            </div>
            <div className="flex flex-row gap-2 mt-2">
              <Badge label="Agen Cabang Daerah" type="green" />
              <Badge label="Pekanbaru" type="dark purple" />
            </div>
          </div>
        </div>
      </div>

      <Grid container spacing={2} className="!mt-3">
        <Grid item md={8}>
          <div className="rounded-xl px-6 py-3 pink-bg-shine">
            <div className="text-white font-medium">Rincian Tagihan :</div>
            <div className="flex flex-row items-center gap-14 mt-4">
              <div className="p-4 drop-bg-50 rounded-xl">
                <div className="txt-yellow text-[16px]">Total Tagihan :</div>
                <div className="txt-yellow text-[24px] font-semibold mt-1">
                  <NumericFormat
                    value={datas?.total_price}
                    displayType="text"
                    thousandSeparator={true}
                    prefix="Rp"
                  />
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <div className="text-white text-[16px]">Sudah dibayar :</div>
                <div className="text-white text-[20px] font-semibold mt-1">
                  <NumericFormat
                    value={datas?.down_payment}
                    displayType="text"
                    thousandSeparator={true}
                    prefix="Rp"
                  />
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <div className="text-white text-[16px]">Diskon :</div>
                <div className="text-white text-[20px] font-semibold mt-1">
                  Rp 5.000.000
                </div>
              </div>
              <div className="flex flex-col gap-1">
                <div className="text-white text-[16px]">Kekurangan :</div>
                <div className="text-white text-[20px] font-semibold mt-1">
                  <NumericFormat
                    value={datas?.remaining_payment_amount}
                    displayType="text"
                    thousandSeparator={true}
                    prefix="Rp"
                  />
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item md={4}>
          <div className="rounded-xl px-6 py-3 pink-bg-shine min-h-[150px]  flex flex-col justify-center">
            <div className="text-white font-medium">Catatan :</div>
            <div className="mt-3 text-white text-[16px] leading-6">
              Pembayaran dilakukan melalui transfer. Transfer bisa menggunakan
              platfom apapun. Transfer dengan nominal pas, jika kelebihan
              silahkan diikhlaskan.
            </div>
          </div>
        </Grid>
      </Grid>

      <Tab
        customClass="mt-10 custom-tab-jamaah"
        defaultActiveKey="1"
        onChange={(e) => setActiveTab(e)}
        items={[
          {
            label: "Tagihan (6 Jamaah)",
            key: "1",
            children: (
              <TabTagihan avatar={Photo} columns={columns} data={data} />
            ),
          },
          { label: "Pembayaran", key: "2", children: <TabPembayaran /> },
        ]}
      />
    </TabTagihanStyle>
  );
};

export default React.memo(PaketTagihanDetail);
