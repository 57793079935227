import React, { useState } from "react";

import Buttons from "components/buttons";
import MoslemeTable from "components/Table";
import Dropdowns, { closeDropdown } from "components/dropdowns";
import Segment from "components/segment";
import PenarikanHistoryCabang from "./components/History";
import Badge from "components/badge";
import Checkbox from "components/checkbox";

import IconEye from "assets/icons/eye.svg";
import BNI from "assets/images/bni-bank.png";
import IconBCA from "assets/images/bca-bank.png";
import Popup from "components/popup";
import DaftarRequest from "./components/DaftarRequest";

const PenarikanCabang = () => {
  const [status, setStatus] = useState("pending");
  const [openPopup, setOpenPopup] = useState(false);
  const [history, setHistory] = useState(false);

  // Loading
  const [isLoading, setIsLoading] = useState(false);
  // Selection & type state
  const [selectionType, setSelectionType] = useState("checkbox");
  const [withSelection, setWithSelection] = useState(true);
  const [checked, setChecked] = useState(false);

  // Pagination state
  const [currentpage, setCurrentpage] = useState(1);
  const [size, setSize] = useState(10);
  const total = 100;

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: total,
    current: currentpage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20"],
  };

  const columns = [
    {
      title: "Cabang",
      dataIndex: "branch",
      render: () => <Badge label="Yogyakarta" />,
    },
    {
      title: "Tanggal Penarikan",
      dataIndex: "withdrawal",
    },
    {
      title: "Nominal Request",
      dataIndex: "nominal_request",
      render: (nominal) => <div className="font-semibold">Rp {nominal}</div>,
    },
    {
      title: "Info Bank",
      dataIndex: "bank",
      render: (bank) => (
        <Dropdowns
          placement="bottom"
          customAreaDropdown="-cabang"
          dropdownHeader={
            <>
              <div className="flex flex-row justify-between items-center border border-[#4048521a] rounded-xl bg-transparent w-[116px] px-2 py-[10px]">
                <img src={BNI} alt="bank" />
                <img src={IconEye} alt="eye" />
              </div>
            </>
          }
        >
          {status === "berhasil" ? (
            <div className="bg-white soft-shadow rounded-2xl w-[472px] border border-[#E0E0E0] overflow-auto">
              <div className="w-full p-4">
                <div className="font-semibold">Pilih Bank Pengirim</div>
              </div>
              <div className="divider"></div>
              <div className="flex flex-col gap-4 p-4">
                <div className="flex flex-row items-center gap-4">
                  <Checkbox
                    checked={checked}
                    onChange={(e) => {
                      setChecked(e.target.checked);
                    }}
                  ></Checkbox>
                  <div className="flex flex-row justify-between items-center border border-[#E0E0E0] rounded-lg p-4 w-full">
                    <div className="flex flex-col gap-2">
                      <div>Nama Akun</div>
                      <div>Abdul Wahid Ngatono</div>
                    </div>
                    <div className="flex flex-col gap-2">
                      <div>Nomer Rekening</div>
                      <div>2415 6410 2351 </div>
                    </div>
                    <img src={BNI} alt="bank" />
                  </div>
                </div>
              </div>
              <div className="divider"></div>
              <div className="flex flex-col gap-4 p-4">
                <div className="flex flex-row items-center gap-4">
                  <Checkbox
                    checked={checked}
                    onChange={(e) => {
                      setChecked(e.target.checked);
                    }}
                  ></Checkbox>
                  <div className="flex flex-row justify-between items-center border border-[#E0E0E0] rounded-lg p-4 w-full">
                    <div className="flex flex-col gap-2">
                      <div>Nama Akun</div>
                      <div>Abdul Wahid Ngatono</div>
                    </div>
                    <div className="flex flex-col gap-2">
                      <div>Nomer Rekening</div>
                      <div>2415 6410 2351 </div>
                    </div>
                    <img src={BNI} alt="bank" />
                  </div>
                </div>
              </div>
              <div className="divider"></div>

              <div className="flex flex-row gap-2 p-4">
                <Buttons
                  text="Batal"
                  containerClass="w-full"
                  customClass="btn-secondary btn-medium w-full"
                  onClick={() => closeDropdown()}
                />
                <Buttons
                  text="Pilih Pengirim"
                  containerClass="w-full"
                  customClass="btn-primary btn-medium w-full"
                  onClick={() => {
                    closeDropdown();
                    setOpenPopup(true);
                  }}
                />
              </div>
            </div>
          ) : (
            <div className="bg-white soft-shadow rounded-2xl w-[240px]">
              <div className="p-4">
                <div className="flex flex-col gap-1">
                  <div className="text-[#828282]">Nama Akun Bank</div>
                  <div>Dimas Andreyan Pradana Putra</div>
                </div>
                <div className="flex flex-col gap-1 mt-4">
                  <div className="text-[#828282]">Nomer Rekening</div>
                  <div>2415 6410 2351 </div>
                </div>
                <img className="mt-4" src={BNI} alt="bank" />
              </div>
            </div>
          )}
        </Dropdowns>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (desc) => {
        return (
          <div className="flex">
            <Segment
              containerClass="flex flex-row justify-start border border-[#E0E0E0] rounded-2xl basis-[1]"
              customClass="!bg-transparent !p-1"
              onChange={(e) => setStatus(e)}
              options={[
                {
                  label: <div className="segment-label-blue">Pending</div>,
                  value: "pending",
                },
                {
                  label: <div>Berhasil</div>,
                  value: "berhasil",
                },
                {
                  label: <div className="segment-label-error">Gagal</div>,
                  value: "gagal",
                },
              ]}
            />

            {/* <div className="action">
            <Eye onClick={() => alert(`show id:`)} size="15" color="#141414" />
            <Trash
              onClick={() => alert(`delete id:`)}
              size="15"
              color="#141414"
            />
            <DocumentCopy
              onClick={() => alert(`copy id:`)}
              size="15"
              color="#141414"
            />
            <Edit onClick={() => alert(`edit id:`)} size="15" color="#141414" />
          </div> */}
          </div>
        );
      },
    },
  ];

  const data = [
    {
      key: "1",
      name: "Dimas Andreyan Pradana Putra",
      withdrawal: "21/08/2022 11:27:29",
      nominal_request: "10.500.000",
    },
    {
      key: "2",
      name: "Dimas Andreyan Pradana Putra",
      withdrawal: "21/08/2022 11:27:29",
      nominal_request: "10.500.000",
    },
    {
      key: "3",
      name: "Dimas Andreyan Pradana Putra",
      withdrawal: "21/08/2022 11:27:29",
      nominal_request: "10.500.000",
    },
  ];

  // func to set size pagination
  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setSize(pageSize);
  };
  // manipulated data with action
  const sourceData = data;

  return (
    <>
      <Popup
        open={openPopup}
        handleClose={() => setOpenPopup(false)}
        title="Daftar Request Paket"
        width="982px"
        children={<DaftarRequest setOpenPopup={setOpenPopup} />}
      />
      {history ? (
        <PenarikanHistoryCabang setHistory={setHistory} />
      ) : (
        <>
          <div className="flex flex-row justify-between items-center py-4">
            <div className="text-xl font-semibold">
              Daftar Request Penarikan Cabang
            </div>
            <Buttons
              text="History"
              customClass="btn-outline btn-small"
              onClick={() => setHistory(true)}
            />
          </div>
          <div className="mx-[-1rem] border-t border-[##E0E0E0]">
            <MoslemeTable
              loading={isLoading}
              columns={columns}
              data={sourceData}
              withSelection={withSelection}
              selectionType={selectionType}
              pagination={pagination}
              onPageChange={(page) => setCurrentpage(page)}
              onSizeChange={(currentpage, pageSize) =>
                onSizeChange(currentpage, pageSize)
              }
            />
          </div>
        </>
      )}
    </>
  );
};

export default React.memo(PenarikanCabang);
