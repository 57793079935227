import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { handleError, handleSuccess } from "stores/actions/errorGeneral";

import { PriceUpdateAPI } from "utils/api/calculator/price-update";

import Buttons from "components/buttons";
import AlertPopup from "components/popup/alert";

import IconTrash from "assets/icons/trash-maroon.svg";

import Inputs from "components/form/inputs";
import InputsCurrency from "components/form/inputsCurrency";

const TourCityUpdatePrice = () => {

    const dispatch = useDispatch()
    const masterDataStore = useSelector(({ datamaster }) => datamaster);

    const [actionIndex, setActionIndex] = useState(null)
    const [removeConfirm, setRemoveConfirm] = useState({ show: false, index: null })

    const [isGetAllLoading, setGetAllLoading] = useState(false)
    const [isSaveLoading, setIsSaveLoading] = useState(false)
    const [isUpdateLoading, setIsUpdateLoading] = useState(false)
    const [isRemoveLoading, setIsRemoveLoading] = useState(false)

    const [cityTours, setCityTours] = useState([])

    useEffect(() => {
        onHandleCityTours()
    }, [])

    const onHandleCityTours = async () => {
        try {

            setGetAllLoading(true)
            
            const res = await PriceUpdateAPI.cityToursGet()
            if(res.status = 200) {
                handleFetchCityTours(res.data.data)
            } else {
                dispatch(handleError(res))
            }

            setGetAllLoading(false)

        } catch(error) {
            setGetAllLoading(false)
            dispatch(handleError(error))
        }
    }
    const onHandleSave = async (index) => {
        setActionIndex(index)

        try {
            setIsSaveLoading(true)

            let cityToursTemp = [...cityTours]
            const cityTour = cityToursTemp[index];

            const res = await PriceUpdateAPI.cityToursSave(cityTour.data)

            if(res.status === 200) {
                dispatch(handleSuccess(res))
                cityToursTemp[index].props.active = true
                cityToursTemp[index].props.updated = false

                cityToursTemp[index].data.id = res.data.data.id

                setCityTours(cityToursTemp)
            } else {
                dispatch(handleError(res))
            }

            setIsSaveLoading(false)
        } catch(error) {
            setIsSaveLoading(false)
            dispatch(handleError(error))
        }
        
    };
    const onHandleUpdate = async (index) => {
        setActionIndex(index)
        setIsUpdateLoading(index)

        try {
            setIsUpdateLoading(true)

            let cityToursTemp = [...cityTours]
            const cityTour = cityToursTemp[index];

            const res = await PriceUpdateAPI.cityToursUpdate(cityTour.data)

            if(res.status === 200) {
                dispatch(handleSuccess(res))
                cityToursTemp[index].props.active = true
                cityToursTemp[index].props.updated = false

                setCityTours(cityToursTemp)
            } else {
                dispatch(handleError(res))
            }

            setIsUpdateLoading(false)
        } catch(error) {
            setIsUpdateLoading(false)
            dispatch(handleError(error))
        }
        
    };
    const onHandleRemove = async (index) => {
        try {

            setIsRemoveLoading(true)

            const cityToursTemp = [...cityTours]
            const cityTour = cityToursTemp[index];

            const res = await PriceUpdateAPI.cityTourRemove(cityTour.data.id)

            if(res.status === 200) {
                cityToursTemp.splice(index, 1)
                
                dispatch(handleSuccess(res))
                setCityTours(cityToursTemp)
            } else {
                dispatch(handleError(res))
            }

            setIsRemoveLoading(false)
            setRemoveConfirm({ show: false, index: 0 })
        } catch(error) {
            setIsRemoveLoading(false)
            dispatch(handleError(error))
        }
    }

    const handleFetchCityTours = (cityToursData) => {
        let cityToursTemp = []

        cityToursData.map((item) => {
            cityToursTemp.push({
                props: {
                    active: true,
                    updated: false,
                },
                data: item,
            });
        })

        setCityTours(cityToursTemp)
    }

    const handleAddCityTours = () => {
        const newCityTour = {
          props: {
            active: false,
            updated: false,
          },
          data: {
            id: null,
            name: "",
            price: 0,
          },
        }
    
        let cityToursTemp = [...cityTours]
        cityToursTemp.push(newCityTour)
    
        setCityTours(cityToursTemp)
    }
    const handleCancelAddCityTour = (index) => {
        let cityToursTemp = [...cityTours];
        
        cityToursTemp.splice(index, 1);
    
        setCityTours(cityToursTemp)
    }

    const cityToursActive = cityTours.filter(function (cityTour) {
        return cityTour.props.active === true;
    });
    
    const cityToursDeactive = cityTours.filter(function (cityTour) {
        return cityTour.props.active === false;
    });

    return (
        <>

            <AlertPopup
                open={removeConfirm.show}
                handleClose={() => setRemoveConfirm({ show: false, index: 0 })}
                handleContinue={() => onHandleRemove(removeConfirm.index)}
                loading={isRemoveLoading}
                subtitle="Apakah anda yakin menghapus city tour ini?"
            />

            <div className="flex flex-row">
                
                <div className="w-[25%]">
                <div className="text-sm font-semibold">City Tour</div>
                </div>
                <div className="w-[75%]">
                
                <div className="flex flex-col gap-4">
                    {cityTours.map((cityTour, index) => {
                        return (
                            <div
                                className="border border-[#E0E0E0] p-4 rounded-2xl"
                                key={index}
                                >
                                    {/* <!----------- HEADER -----------> */}
                                    <div className="flex justify-between items-center">
                                        <div className="font-bold mb-4">
                                            {!cityTour.props.active && "Tambah "}City Tour{" "}
                                            {index + 1}
                                        </div>
                                        <div className="flex justify-end gap-4 items-center">
                                            {!cityTour.props.active ? (
                                                <>
                                                    <Buttons
                                                        text="Batal"
                                                        onClick={() => handleCancelAddCityTour(index)}
                                                        customClass="btn-primary btn-outline btn-small"
                                                        />
                                                    <Buttons
                                                        text="Simpan"
                                                        loading={isSaveLoading}
                                                        onClick={() => onHandleSave(index)}
                                                        customClass="btn-primary btn-small"
                                                    />
                                                </>
                                            ): null}
                                            {cityTour.props.active ? (
                                                <>
                                                    {cityToursActive.length > 1 && (
                                                        <i
                                                            className="!cursor-pointer"
                                                            onClick={() =>
                                                                setRemoveConfirm({ show: true, index })
                                                            }
                                                            >
                                                            <img
                                                                src={IconTrash}
                                                                className={`w-6 h-6`}
                                                                />
                                                        </i>
                                                    )}
                                                    <Buttons
                                                        text="Simpan"
                                                        loading={isUpdateLoading ? actionIndex === index : false}
                                                        onClick={() => {
                                                            onHandleUpdate(index);
                                                        }}
                                                        disabled={!cityTour.props.updated}
                                                        customClass="btn-primary btn-small"
                                                    />
                                                </>
                                            ) : null}
                                        </div>
                                    </div>

                                    {/* <!----------- CONTENT ----------------> */}
                                    <div className="flex gap-4 items-center mb-4">
                                        <div className="w-[83%]">
                                            <Inputs
                                                label="Kota Tour"
                                                placeholder="Masukan nama Kota Tour"
                                                value={cityTour.data.name}
                                                onChange={(val) => {
                                                    const cityToursTemp = [...cityTours];

                                                    cityToursTemp[index].props.updated = true;
                                                    cityToursTemp[index].data.name = val.target.value;

                                                    setCityTours(cityToursTemp)
                                                }}
                                            />
                                        </div>
                                        <div className="w-[83%]">
                                            <InputsCurrency
                                                label="Harga"
                                                value={cityTour.data.price}
                                                placeholder="Masukan harga City Tour"
                                                onChange={(val) => {
                                                    const cityToursTemp = [...cityTours];

                                                    cityToursTemp[index].props.updated = true;
                                                    cityToursTemp[index].data.price = val;

                                                    setCityTours(cityToursTemp)
                                                }}
                                            />
                                        </div>

                                    </div>
                            </div>
                        )
                    })}
                </div>
                
                <Buttons
                    text="+ Tambah City Tour"
                    customClass="btn-outline btn-medium mt-4 !py-4"
                    disabled={cityToursDeactive.length}
                    onClick={() => handleAddCityTours()}
                />

                </div>

            </div>
        </>
    )

}

export default TourCityUpdatePrice