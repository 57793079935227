import React, { useEffect, useState } from "react";
import PropType from "prop-types";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { handleSaveOrEditBus } from "stores/actions/paket/generalInfo";

import Buttons from "components/buttons";
import AntdSelect from "components/form/antdSelect";

import IconTrash from "assets/icons/trash.svg";
import Inputs from "components/form/inputs";
import { handleSuccess } from "stores/actions/errorGeneral";

const data_state = [
  {
    id_bus: 37,
    paket_id: 2,
    nama_bus: "",
    jumlah_seat: "",
  },
];

const JumlahSeatBus = ({ data, closePopup, setSuccessEdit }) => {
  const dispatch = useDispatch();
  const params = useParams();

  const [listBus, setListBus] = useState([]);

  const [input, setInput] = useState([{}]);
  const [validateInput, setValidateInput] = useState(false);

  const {
    values,
    touched,
    errors,
    handleSubmit,
    setFieldValue,
    setValues,
    isSubmitting,
  } = useFormik({
    initialValues: {
      forms: [],
    },
    validationSchema: Yup.object().shape({}),
    onSubmit: (values, actions) => {
      dispatch(
        handleSaveOrEditBus(
          {
            paket_id: parseInt(params.id),
            bus: values.forms,
          },
          (message) => {
            dispatch(handleSuccess(message));
            actions.resetForm();
            setSuccessEdit(true);
            closePopup(false);
          }
        )
      );
    },
  });

  useEffect(() => {
    if (data.length > 0) {
      setFieldValue("forms", data);
    } else {
      setFieldValue("forms", data_state);
    }
  }, [data, data?.length]);

  return (
    <>
      <div className="flex flex-col items-center gap-[20px] p-4">
        {values.forms.map((val, idx) => (
          <div
            className="flex flex-row items-center justify-between gap-[16px] w-[100%]"
            key={idx}
          >
            <div className="w-[100%]">
              <Inputs
                label={`Bus ${idx + 1}`}
                placeholder="Masukkan bus"
                error={touched.forms && errors.forms && errors.forms}
                customClass={`!w-[423px] ${
                  touched.forms && errors.forms && "input-error"
                }`}
                value={val?.nama_bus}
                onChange={(e) => {
                  let _temp = [...values.forms];
                  _temp[idx]["nama_bus"] = e.target.value;
                  setFieldValue("forms", _temp);
                }}
              />
            </div>
            <div className="w-[100%]">
              <Inputs
                label="Jumlah Seat"
                placeholder="Masukkan jumlah seat"
                error={touched.forms && errors.forms && errors.forms}
                customClass={`!w-[423px] ${
                  touched.forms && errors.forms && "input-error"
                }`}
                value={val?.jumlah_seat}
                onChange={(e) => {
                  let list = [...values.forms];
                  list[idx]["jumlah_seat"] = e.target.value;
                  setFieldValue("forms", list);
                }}
              />
            </div>
            {values.forms.length > 1 && idx > 0 && (
              <img
                src={IconTrash}
                className="cursor-pointer h-[24px] w-[24px] relative top-4"
                onClick={() => {
                  let _temp = [...values.forms];
                  _temp.splice(idx, 1);
                  setFieldValue("forms", _temp);
                }}
                alt="trash"
              />
            )}
          </div>
        ))}
        <div className="w-[100%]">
          <Buttons
            text="Tambah Bus"
            customClass="btn-outline !w-full btn-medium"
            onClick={() => {
              let _temp = [
                ...values.forms,
                {
                  id_bus: 37,
                  paket_id: 2,
                  nama_bus: "",
                  jumlah_seat: "",
                },
              ];
              setFieldValue("forms", _temp);
            }}
          />
        </div>
      </div>
      <div className="flex flex-col justify-end border-t py-[16px] px-[20px]">
        <Buttons
          text="Simpan"
          loading={isSubmitting}
          customClass={`btn-primary btn-medium !w-[186px] float-right ${
            isSubmitting && "loading"
          }`}
          onClick={handleSubmit}
        />
      </div>
    </>
  );
};

JumlahSeatBus.propTypes = {
  data: PropType.array,
  closePopup: PropType.func,
  setSuccessEdit: PropType.func,
};

export default React.memo(JumlahSeatBus);
