import { Route, Switch, useHistory } from "react-router-dom";
import Dashboard from "../pages/dashboard";
import React, {
  useState,
  useCallback,
  useEffect,
  useLayoutEffect,
} from "react";
import Container from "../layout";
import { ThemeProvider } from "@material-ui/core";
import theme from "../theme/theme";
import Documentation from "../pages/documentation";
import { pathnameCONFIG } from "../configuration/pathConfig";
import PrivateRoute from "../PrivateRoute";
import PopupConfirm from "../components/popup/error-handling/index";
import Toast from "../components/toast";
import { useDispatch, useSelector } from "react-redux";
import {
  clearError,
  clearError400,
  clearError404,
  clearSucces200,
} from "../stores/actions/errorGeneral";
import TagihanDetailPage from "../pages/tagihan/detail";
import TagihanPage from "../pages/tagihan/index.js";
import LoginPage from "../pages/login";
import RolePage from "../pages/role";
import Master from "../pages/master";
import Member from "../pages/member";
import CabangPage from "../pages/cabang";
import BookingPage from "../pages/booking";
import BookingDetailPage from "../pages/booking/detail";
import SettingPage from "../pages/setting";
import CalculatorPage from "../pages/calculator";
import PaketPage from "../pages/paket";
import PaketDetail from "../pages/paket/detail";
import AppPage from "pages/apps";
import LogActivity from "pages/master/components/logactivity";

import MarketingKit from "pages/marketingKit";
import FeePage from "pages/fee";
import PenarikanFeePage from "pages/penarikan-fee";
import Activity from "pages/cabang/activity";
import LayananIslami from "pages/layananIslami";
import UnauthorizedPage from "pages/unauthorized";


import Toastify from "components/toastify";

const Router = () => {
  const history = useHistory();
  const [secondContainer, setSecondContainer] = useState(false);
  const dispatch = useDispatch();
  const { error, error400, error404, success } = useSelector(
    (state) => state.errorGeneral
  );

  useEffect(() => {
    switch (history.location.pathname) {
      case "/tagihan":
        setSecondContainer(true);
        break;
      case "/member":
        setSecondContainer(true);
        break;
      case "/booking":
        setSecondContainer(true);
        break;
      case "/paket":
        setSecondContainer(true);
        break;
      case "/apps":
        setSecondContainer(true);
        break;
      case "/marketing":
        setSecondContainer(true);
        break;
      default:
        setSecondContainer(false);
    }
  }, [history.location.pathname]);

  const memoizeclearError = () => {
    dispatch(clearError());
  };

  const memoizeClearError400 = () => {
    dispatch(clearError400());
  };

  const memoizeClearError404 = () => {
    dispatch(clearError404());
  };

  const memoizeClearSuccess = () => {
    dispatch(clearSucces200());
  };

  const switchMuslimTravel = () => {
    return (
      <Switch>
        {/* Sprint 1 */}

        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/unauthorized" component={UnauthorizedPage} />

        <Container secondContainer={secondContainer}>
          <Route exact path="/documentation" component={Documentation} />
          {/* example handle private route */}

          <PrivateRoute
            path={pathnameCONFIG.ROOT.BASE_URL}
            exact
            component={Dashboard}
            allowedAccessData={["all_branches", "self_branch"]}
            allowedPermissions={[]}
          />

          <PrivateRoute
            path={pathnameCONFIG.DASHBOARD.BASE_URL}
            exact
            component={Dashboard}
            allowedAccessData={["all_branches", "self_branch"]}
            allowedPermissions={[]}
          />

          <PrivateRoute
            exact
            path={pathnameCONFIG.PAKET.BASE_URL}
            component={PaketPage}
            allowedAccessData={["all_branches", "self_branch"]}
            allowedPermissions={[]}
          />
          <PrivateRoute
            exact
            path={pathnameCONFIG.PAKET.DETAIL}
            component={PaketDetail}
            allowedAccessData={["all_branches", "self_branch"]}
            allowedPermissions={[]}
          />

          <PrivateRoute
            exact
            path={pathnameCONFIG.TAGIHAN.BASE_URL}
            component={TagihanPage}
            allowedAccessData={["all_branches", "self_branch"]}
            allowedPermissions={[]}
          />
          <PrivateRoute
            exact
            path={pathnameCONFIG.TAGIHAN.DETAIL}
            component={TagihanDetailPage}
            allowedAccessData={["all_branches", "self_branch"]}
            allowedPermissions={[]}
          />
          <PrivateRoute
            exact
            path={pathnameCONFIG.DATAMASTER.BASE_URL}
            component={Master}
            allowedAccessData={["all_branches"]}
            allowedPermissions={["view_master-data"]}
          />

          <PrivateRoute
            exact
            path={pathnameCONFIG.DATAMASTER.LOG_ACTIVITY}
            component={LogActivity}
            allowedAccessData={["all_branches"]}
            allowedPermissions={["view_master-data"]}
          />

          <PrivateRoute
            exact
            path={pathnameCONFIG.ROLE.BASE_URL}
            component={RolePage}
            allowedAccessData={["all_branches", "view_role"]}
            allowedPermissions={["view_role"]}
          />
          <PrivateRoute
            exact
            path={pathnameCONFIG.MEMEBER.BASE_URL}
            component={Member}
            allowedAccessData={["all_branches", "self_branch"]}
            allowedPermissions={["view_member"]}
          />
          <PrivateRoute
            exact
            path={pathnameCONFIG.CABANG.BASE_URL}
            component={CabangPage}
            allowedAccessData={["all_branches", "view_branch"]}
            allowedPermissions={["view_branch"]}
          />
          <PrivateRoute
            exact
            path={pathnameCONFIG.LOG_ACTIVITY_CABANG.DETAIL}
            component={Activity}
            allowedAccessData={["all_branches", "view_branch"]}
            allowedPermissions={["view_branch"]}
          />
          <PrivateRoute
            exact
            path={pathnameCONFIG.BOOKING.BASE_URL}
            component={BookingPage}
            allowedAccessData={["all_branches", "self_branch"]}
            allowedPermissions={[]}
          />
          <PrivateRoute
            exact
            path={pathnameCONFIG.BOOKING.DETAIL}
            component={BookingDetailPage}
            allowedAccessData={["all_branches", "self_branch"]}
            allowedPermissions={[]}
          />
          <PrivateRoute
            exact
            path={pathnameCONFIG.SETTING.BASE_URL}
            component={SettingPage}
            allowedAccessData={["all_branches"]}
            allowedPermissions={[]}
          />
          <PrivateRoute
            exact
            path={pathnameCONFIG.CALCULATOR.BASE_URL}
            component={CalculatorPage}
            allowedAccessData={["all_branches"]}
            allowedPermissions={[]}
          />
          <PrivateRoute
            exact
            path={pathnameCONFIG.APPS.BASE_URL}
            component={AppPage}
            allowedAccessData={["all_branches"]}
            allowedPermissions={[]}
          />
          <PrivateRoute
            exact
            path={pathnameCONFIG.MARKETING.BASE_URL}
            component={MarketingKit}
            allowedAccessData={["all_branches", "self_branch"]}
            allowedPermissions={[]}
          />
          <PrivateRoute
            exact
            path={pathnameCONFIG.FEE.BASE_URL}
            component={FeePage}
            allowedAccessData={["all_branches"]}
            allowedPermissions={[]}
          />
          <PrivateRoute
            exact
            path={pathnameCONFIG.FEE.PENARIKAN}
            component={PenarikanFeePage}
            allowedAccessData={["all_branches"]}
            allowedPermissions={[]}
          />
          <PrivateRoute
            exact
            path={pathnameCONFIG.LAYANANISLAMI.BASE_URL}
            component={LayananIslami}
            allowedAccessData={["all_branches"]}
            allowedPermissions={[]}
          />
        </Container>
      </Switch>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      {switchMuslimTravel()}
      {/* <Toast
        open={error?.isError}
        message={error?.message ?? null}
        handleClose={memoizeclearError}
      /> */}

      <Toastify 
        type="success"
        show={success.isSuccess}
        handleClose={memoizeClearSuccess}
        message={success?.message ?? null}
        />

      {/* <PopupConfirm
        open={success.isSuccess}
        handleClose={memoizeClearSuccess}
        type="success"
        title="Success"
        subtitle={success?.message ?? null}
      /> */}  

      <Toastify 
        type="error"
        show={error?.isError}
        handleClose={memoizeclearError}
        message={error?.message ?? null}
        />
      
      {/* <PopupConfirm
        open={error?.isError}
        handleClose={memoizeclearError}
        type="error"
        title="Gagal"
        subtitle={error?.message ?? null}
      /> */}

      <Toastify 
        type="error"
        show={error400?.isError400}
        handleClose={memoizeClearError400}
        message={error400?.message}
        />
      
      {/* <PopupConfirm
        open={error400?.isError400}
        handleClose={memoizeClearError400}
        type="error"
        title="Gagal"
        subtitle={error400?.message}
      /> */}

      <Toastify 
        type="error"
        show={error404?.isError404}
        handleClose={memoizeClearError404}
        message={error400?.message}
        />

      {/* <PopupConfirm
        open={error404?.isError404}
        handleClose={memoizeClearError404}
        type="warning"
        title="Warning"
      /> */}
    </ThemeProvider>
  );
};

export default Router;
