export const listBank = [
  {
    value: "BANK BRI",
    label: "BANK BRI",
  },
  {
    value: "BANK MANDIRI",
    label: "BANK MANDIRI",
  },
  {
    value: "BANK BNI",
    label: "BANK BNI",
  },
  {
    value: "BANK DANAMON",
    label: "BANK DANAMON",
  },
  {
    value: "BANK PERMATA",
    label: "BANK PERMATA",
  },
  {
    value: "BANK PERMATA SYARIAH",
    label: "BANK PERMATA SYARIAH",
  },
  {
    value: "BANK BCA",
    label: "BANK BCA",
  },
  {
    value: "BLL MAYBANK",
    label: "BLL MAYBANK",
  },
  {
    value: "MAYBANK SYARIAH",
    label: "MAYBANK SYARIAH",
  },
  {
    value: "BANK PANIN",
    label: "BANK PANIN",
  },
  {
    value: "CIMB NIAGA",
    label: "CIMB NIAGA",
  },
  {
    value: "BANK UOB INDONESIA",
    label: "BANK UOB INDONESIA",
  },
  {
    value: "BANK OCBC NISP",
    label: "BANK OCBC NISP",
  },
  {
    value: "CITIBANK",
    label: "CITIBANK",
  },
  {
    value: "BANK WINDU KENTJANA INTERNATIONAL",
    label: "BANK WINDU KENTJANA INTERNATIONAL",
  },
  {
    value: "BANK ARTHA GRAHA",
    label: "BANK ARTHA GRAHA",
  },
  {
    value: "BANK TOKYO MITSUBISHI UFJ",
    label: "BANK TOKYO MITSUBISHI UFJ",
  },
  {
    value: "BANK DBS",
    label: "BANK DBS",
  },
  {
    value: "STANDART CHARTERED",
    label: "STANDART CHARTERED",
  },
  {
    value: "BANK CAPITAL",
    label: "BANK CAPITAL",
  },
  {
    value: "ANZ INDONESIA",
    label: "ANZ INDONESIA",
  },
  {
    value: "BANK OF CHINA",
    label: "BANK OF CHINA",
  },
  {
    value: "BANK BUMI ARTA",
    label: "BANK BUMI ARTA",
  },
  {
    value: "BANK HSBC",
    label: "BANK HSBC",
  },
  {
    value: "BANK ANTARDAERAH",
    label: "BANK ANTARDAERAH",
  },
  {
    value: "BANK RABOBANK",
    label: "BANK RABOBANK",
  },
  {
    value: "BANK JTRUST INDONESIA",
    label: "BANK JTRUST INDONESIA",
  },
  {
    value: "BANK MAYAPADA",
    label: "BANK MAYAPADA",
  },
  {
    value: "BANK JAWA BARAT",
    label: "BANK JAWA BARAT",
  },
  {
    value: "BANK DKI",
    label: "BANK DKI",
  },
  {
    value: "BANK BPD DIY",
    label: "BANK BPD DIY",
  },
  {
    value: "BANK JATENG",
    label: "BANK JATENG",
  },
  {
    value: "BANK JATIM",
    label: "114",
  },
  {
    value: "BANK JAMBI",
    label: "BANK JAMBI",
  },
  {
    value: "BANK JAMBI SYARIAH",
    label: "BANK JAMBI SYARIAH",
  },
  {
    value: "BANK ACEH",
    label: "BANK ACEH",
  },
  {
    value: "BANK ACEH SYARIAH",
    label: "BANK ACEH SYARIAH",
  },
  {
    value: "BANK SUMUT",
    label: "BANK SUMUT",
  },
  {
    value: "BANK NAGARI",
    label: "BANK NAGARI",
  },
  {
    value: "BANK RIAU",
    label: "BANK RIAU",
  },
  {
    value: "BANK RIAU SYARIAH",
    label: "BANK RIAU SYARIAH",
  },
  {
    value: "BANK SUMSEL BABEL",
    label: "BANK SUMSEL BABEL",
  },
  {
    value: "BANK SUMSEL BABEL SYARIAH",
    label: "BANK SUMSEL BABEL SYARIAH",
  },
  {
    value: "BANK LAMPUNG",
    label: "BANK LAMPUNG",
  },
  {
    value: "BANK KALSEL",
    label: "BANK KALSEL",
  },
  {
    value: "BANK KALBAR",
    label: "BANK KALBAR",
  },
  {
    value: "BANK BPD KALTIM",
    label: "BANK BPD KALTIM",
  },
  {
    value: "BANK BPD KALTIM",
    label: "BANK BPD KALTIM",
  },
  {
    value: "BANK SULSELBAR",
    label: "BANK SULSELBAR",
  },
  {
    value: "BANK SULUT",
    label: "BANK SULUT",
  },
  {
    value: "BANK NTB",
    label: "BANK NTB",
  },
  {
    value: "BANK NTB SYARIAH",
    label: "BANK NTB SYARIAH",
  },
  {
    value: "BANK BPD BALI",
    label: "BANK BPD BALI",
  },
  {
    value: "BANK NTT",
    label: "BANK NTT",
  },
  {
    value: "BANK MALUKU",
    label: "BANK MALUKU",
  },
  {
    value: "BANK BPD PAPUA",
    label: "BANK BPD PAPUA",
  },
  {
    value: "BANK SULTENG",
    label: "BANK SULTENG",
  },
  {
    value: "BANK SULTRA",
    label: "BANK SULTRA",
  },
  {
    value: "BANK BANTEN",
    label: "BANK BANTEN",
  },
  {
    value: "BANK NUSANTARA PARAHYANGAN",
    label: "BANK NUSANTARA PARAHYANGAN",
  },
  {
    value: "BANK OF INDIA INDONESIA",
    label: "BANK OF INDIA INDONESIA",
  },
  {
    value: "BANK MUAMALAT",
    label: "BANK MUAMALAT",
  },
  {
    value: "BANK MESTIKA",
    label: "BANK MESTIKA",
  },
  {
    value: "BANK SHINHAN",
    label: "BANK SHINHAN",
  },
  {
    value: "BANK SINARMAS",
    label: "BANK SINARMAS",
  },
  {
    value: "BANK MASPION",
    label: "BANK MASPION",
  },
  {
    value: "BANK GANESHA",
    label: "BANK GANESHA",
  },
  {
    value: "BANK ICBC",
    label: "BANK ICBC",
  },
  {
    value: "BANK QNB INDONESIA",
    label: "BANK QNB INDONESIA",
  },
  {
    value: "BANK BTN",
    label: "BANK BTN",
  },
  {
    value: "BANK WOORI SAUDARA",
    label: "BANK WOORI SAUDARA",
  },
  {
    value: "BANK BTPN",
    label: "BANK BTPN",
  },
  {
    value: "BANK VICTORIA SYARIAH",
    label: "BANK VICTORIA SYARIAH",
  },
  {
    value: "BANK BRI SYARIAH",
    label: "BANK BRI SYARIAH",
  },
  {
    value: "BANK JABAR BANTEN SYARIAH",
    label: "BANK JABAR BANTEN SYARIAH",
  },
  {
    value: "BANK MEGA",
    label: "BANK MEGA",
  },
  {
    value: "BANK BNI SYARIAH",
    label: "BANK BNI SYARIAH",
  },
  {
    value: "BANK BUKOPIN",
    label: "BANK BUKOPIN",
  },
  {
    value: "BANK SYARIAH MANDIRI",
    label: "BANK SYARIAH MANDIRI",
  },
  {
    value: "BANK JASA JAKARTA",
    label: "BANK JASA JAKARTA",
  },
  {
    value: "BANK KEB HANA",
    label: "BANK KEB HANA",
  },
  {
    value: "BANK MNC INTERNATIONAL",
    label: "BANK MNC INTERNATIONAL",
  },
  {
    value: "BANK YUDHA BHAKTI",
    label: "BANK YUDHA BHAKTI",
  },
  {
    value: "BANK RAKYAT INDONESIA AGRONIAGA",
    label: "BANK RAKYAT INDONESIA AGRONIAGA",
  },
  {
    value: "BANK SBI INDONESIA (INDOMONEX)",
    label: "BANK SBI INDONESIA (INDOMONEX)",
  },
  {
    value: "BANK ROYAL",
    label: "BANK ROYAL",
  },
  {
    value: "BANK NATIONAL NOBU",
    label: "BANK NATIONAL NOBU",
  },
  {
    value: "BANK MEGA SYARIAH",
    label: "BANK MEGA SYARIAH",
  },
  {
    value: "BANK INA",
    label: "BANK INA",
  },
  {
    value: "BANK PANIN SYARIAH",
    label: "BANK PANIN SYARIAH",
  },
  {
    value: "PRIMA MASTER BANK",
    label: "PRIMA MASTER BANK",
  },
  {
    value: "BANK SYARIAH BUKOPIN",
    label: "BANK SYARIAH BUKOPIN",
  },
  {
    value: "BANK SAHABAT SAMPOERNA",
    label: "BANK SAHABAT SAMPOERNA",
  },
  {
    value: "BANK DINAR",
    label: "BANK DINAR",
  },
  {
    value: "BANK KESEJAHTERAAN EKONOMI",
    label: "BANK KESEJAHTERAAN EKONOMI",
  },
  {
    value: "BANK BCA SYARIAH",
    label: "BANK BCA SYARIAH",
  },
  {
    value: "BANK ARTOS",
    label: "BANK ARTOS",
  },
  {
    value: "BANK BTPN SYARIAH",
    label: "BANK BTPN SYARIAH",
  },
  {
    value: "BANK MULTIARTA SENTOSA",
    label: "BANK MULTIARTA SENTOSA",
  },
  {
    value: "BANK MAYORA",
    label: "BANK MAYORA",
  },
  {
    value: "BANK INDEX",
    label: "BANK INDEX",
  },
  {
    value: "CNB",
    label: "CNB",
  },
  {
    value: "BANK MANTAP",
    label: "BANK MANTAP",
  },
  {
    value: "BANK VICTORIA INTL",
    label: "BANK VICTORIA INTL",
  },
  {
    value: "HARDA",
    label: "HARDA",
  },
  {
    value: "IBK",
    label: "IBK",
  },
  {
    value: "BANK COMMONWEALTH",
    label: "BANK COMMONWEALTH",
  },
  {
    value: "OVO",
    label: "OVO",
  },
  {
    value: "LINKAJA",
    label: "linkaja",
  },
  {
    value: "DANA",
    label: "dana",
  },
  {
    value: "GOPAY",
    label: "gopay",
  },
];
