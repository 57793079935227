import React from "react";

import Badge from "../../../../../../../../components/badge";
import MoslemeTable from "../../../../../../../../components/Table";

const TabTagihan = ({ avatar, columns, data }) => {
  const CardTagihanJamaah = () => {
    return (
      <div className="bg-white soft-shadow rounded-2xl">
        <div className="flex flex-row items-center p-4 border-b-[0.5px] border-b-[#E0E0E0]">
          <img
            src={avatar}
            className="rounded-[48px] w-[32px] h-[32px]"
            alt="avatar"
          />
          <div className="text-[16px] font-semibold ml-4">
            Tn. Abdul Wahid Ngatono
          </div>
        </div>
        <div>
          <div className="flex flex-row justify-between p-4">
            <div>
              <div className="text-[16px]">Tipe Kamar</div>
              <div className="text-[20px] font-semibold txt-green">Quad</div>
            </div>
            <div>
              <div className="text-[16px]">Harga Paket</div>
              <div className="text-[20px] font-semibold txt-green">
                Rp 30.000.000
              </div>
            </div>
            <div>
              <Badge label="BPN-CGK" type="old brown" />
            </div>
          </div>

          <div className="px-4 mt-1 mb-4">
            <div className="text-[16px]">Additional</div>
            <MoslemeTable
              bordered
              columns={columns}
              data={data}
              customClass="tbl-jamaah mt-2"
              withSelection={false}
              selectionType=""
              withPagination={false}
              customPaginationClass="pr-4"
            />
          </div>
        </div>
        <div className="border-t-[0.5px] border-[#E0E0E0] p-4">
          <div className="orange-bg rounded-xl px-6 py-3">
            <div className="text-white text-[16px] font-semibold">
              Total Tagihan:
            </div>
            <div className="text-[20px] font-semibold txt-yellow-soft mt-2">
              Rp 33.500.000
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <>
        <div className="grid grid-cols-2 gap-2 ">
          <CardTagihanJamaah />
          <CardTagihanJamaah />
          <CardTagihanJamaah />
          <CardTagihanJamaah />
          <CardTagihanJamaah />
          <CardTagihanJamaah />
        </div>
      </>
    </>
  );
};

export default React.memo(TabTagihan);
