import React from "react";
import { useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import IconSidebar from "../../../assets/images/logo-mosleme-travel.svg";
import IconFee from "../../../assets/icons/IconFee.svg";
import FotoProfile from "../../../assets/images/profile_new.png";
import IconMoon from "../../../assets/icons/Mode.svg";
import IconScring from "../../../assets/icons/brush.svg";
import IconNotif from "../../../assets/icons/notif_new.svg";

import AvatarIkhwan from"../../../assets/icons/ava-ikhwan.svg"
import NurRamadhanLogo from "../../../assets/images/nur-ramadhan-logo.png";

import { getLocalStorage } from "utils/helpers";

const useStyles = makeStyles({
  header: {
    padding: "18px 24px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "#fff !important",
    background: "green",
    // margin: "16px 16px 0px",
    position: "fixed",
    top: "16px",
    zIndex: "999",
    left: "108px",
    right: "16px",
    borderRadius: "16px",
    boxShadow:
      "0px 10px 16px -6px rgba(0, 0, 0, 0.04), 0px 4px 80px -4px rgba(0, 0, 0, 0.04)",
    "& .lefsidebar": {
      display: "flex",
      flexDirection: "row",
      "& .container": {
        marginLeft: "32px",
        "& .title": {
          color: " #000",
          fontFamily: "GeneralSansSemibold",
          fontWeight: "600",
          fontSize: "18px",
          "& .label": {
            fontSize: "18px",
            fontFamily: "GeneralSans",
            color:
              "linear-gradient(34.99deg, #151515 17.46%, #3D3D3D 58.62%, #595959 87.32%)",
            fontWeight: "400",
          },
        },
        "& .subtitle": {
          color: "#FFBFAB",
          fontFamily: "GeneralSansRegular",
        },
      },
    },
    "& .rightsidebar": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      alignItems: "center",
      width: "400px",
      "& .cardFee": {
        boxSizing: "border-box",
        width: "160px",
        height: "47px",
        borderRadius: "12px",
        backgroundColor: "rgba(46, 179, 67, 0.2)",
        padding: "16px",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        "& .columnContainer": {
          display: "flex",
          flexDirection: "column",
          "& .label": {
            fontFamily: "GeneralSansRegular",
            fontSize: "14px",
            fontWeight: "400",
            color: "#151515",
          },
          "& .saldo": {
            fontFamily: "GeneralSansBold",
            fontSize: "14px",
            fontWeight: "bolder",
            color: "#01F91A !important",
            lineHeight: "none !important",
          },
        },
        "& .icon": {},
      },
      "& .icon_img": {
        display: "flex",
        justifyContent: "space-between",
        width: "182px",
        "& .notif": {
          width: "32px",
          height: "32px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          position: "relative",
          "& .round": {
            position: "absolute",
            width: "16px",
            height: "16px",
            borderRadius: "48px",
            background: "linear-gradient(180deg, #F5E97C 0%, #E2A246 100%)",
            right: "0px",
            top: "0px",
            color:
              "linear-gradient(34.99deg, #151515 17.46%, #3D3D3D 58.62%, #595959 87.32%)",
            fontFamily: "GeneralSans",
            fontWeight: "600",
            fontSize: "6px",
            textAlign: "center",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          },
        },
        "& .profile": {
          width: "38px",
          height: "38px",
          borderRadius: "20px",
          cursor: "pointer",
          position: "relative",
          "& .icon_active": {
            width: "10px",
            height: "10px",
            borderRadius: "5px",
            background: "#A7C957",
            border: "1px solid #ffff",
            position: "absolute",
            left: "2px",
            bottom: "4px",
          },
        },
      },
    },
  },
});

const HeaderLayout = ({ handleClick }) => {
  const classes = useStyles();
  const router = useHistory();

  const userLogin = getLocalStorage("userLogin");
  const userLoginJsonParse = userLogin && JSON.parse(userLogin);

  return (
    <div className={classes.header}>
      <div className="lefsidebar">
        <img src={NurRamadhanLogo} width="170px" alt="icon-sidebar" />
        <div className="container">
          <Typography className="title">
            <b>Assalamu'alaikum,</b>{" "}
            <label className="label">{userLoginJsonParse?.data?.account?.name}</label>👋
          </Typography>
          <Typography className="subtitle">{userLoginJsonParse?.data?.account?.nama_role}</Typography>
        </div>
      </div>
      <div className="rightsidebar flex !justify-end">
        {userLoginJsonParse?.data?.account?.access_data === 'self_branch' && (
          <div
            className="cardFee cursor-pointer mr-6"
            onClick={() => router.push("/fee-penarikan")}
          >
            <div className="columnContainer">
              <div className="label">Total Fee</div>
              <div className="saldo">Rp 5.000.000</div>
            </div>
            <div className="icon">
              <img src={IconFee} alt="icon_fee" />
            </div>
          </div>
        )}
        <div className="icon_img">
          <img src={IconMoon} alt="icon_moon" width={20} height={20} />
          <img src={IconScring} alt="icon_scring" width={20} height={20} />
          <div className="notif">
            <div className="round">99+</div>
            <img src={IconNotif} alt="icon_notif" width={20} height={20} />
          </div>

          <div className="profile" onClick={handleClick}>
            <img src={AvatarIkhwan} alt="fotoProfile" width={32} height={32} />

            <div className="icon_active"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(HeaderLayout);
