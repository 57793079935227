import "./style.scss";
import LocationNow from "../../assets/icons/locasi_sekarang.svg";
import Place from "../../assets/icons/place_new.svg";
import React from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import PropTypes from "prop-types";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
import { makeStyles } from "@material-ui/core";
import Scroll from "../scroll";
import { APP_GOOGLE_MAP } from "../../constant/constanta";
const useStyles = makeStyles({
  input: {
    position: "absolute",
    height: "54px",
    width: "100%",
    padding: "10px 20px 10px 12px",
    boxShadow:
      "0px 4px 4px rgba(0, 0, 0, 0.1), 0px 2px 16px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
  },
  containermap: {
    width: "532px",
    height: "318px",
    position: "relative",
    padding: "0px",
  },
  containerPover: {
    borderBottomRightRadius: "8px",
    borderBottomLeftRadius: "8px",
    zIndex: 1400,
    "& .comboboxOption": {
      padding: "12px",
      borderBottom: "1px solid rgba(64, 72, 82, 0.1)",
      width: "100%",
      "&:hover": {},
    },
  },
  icon_container: {
    display: "flex",
    padding: "12px",
  },
});

const PlacesAutocomplete = ({ classes, setSelected, setAdress }) => {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();

  const handleSelect = async (address) => {
    console.log("address:", address);
    setAdress(address);
    setValue(address, false);
    clearSuggestions();

    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);
    await setSelected({ lat, lng });
  };

  return (
    <Combobox onSelect={handleSelect}>
      <ComboboxInput
        value={value}
        onChange={(e) => setValue(e.target.value)}
        disabled={!ready}
        className={classes.input}
        placeholder="Cari Alamat"
      />
      <ComboboxPopover className={`custom-map ${classes.containerPover}`}>
        <ComboboxList>
          {status === "OK" && (
            <Scroll>
              {data.map(({ place_id, description }) => {
                return (
                  <div className={classes.icon_container} key={place_id}>
                    <img src={Place} alt="place" />
                    <ComboboxOption
                      className="comboboxOption"
                      key={place_id}
                      value={description}
                    />
                  </div>
                );
              })}
            </Scroll>
          )}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  );
};

function Map({ classes, selected, setSelected, setAdress, withSearch }) {
  return (
    <React.Fragment>
      <GoogleMap
        zoom={10}
        center={selected}
        mapContainerClassName="map-container"
      >
        {withSearch && (
          <div className="places-container">
            <PlacesAutocomplete
              classes={classes}
              setSelected={setSelected}
              setAdress={setAdress}
            />
          </div>
        )}
        {selected !== null && <Marker color="red" position={selected} />}
      </GoogleMap>
    </React.Fragment>
  );
}

const Places = ({
  containerClass,
  style,
  selected,
  setSelected,
  setAdress,
  withSearch,
}) => {
  const classes = useStyles();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: APP_GOOGLE_MAP,
    libraries: ["places"],
  });

  if (!isLoaded) return <div>Loading...</div>;
  return (
    <div className={`${containerClass}`} style={style}>
      <Map
        classes={classes}
        selected={selected}
        setSelected={setSelected}
        setAdress={setAdress}
        withSearch={withSearch}
      />{" "}
    </div>
  );
};

Places.propTypes = {
  selected: PropTypes.object.isRequired,
  containerClass: PropTypes.string,
  style: PropTypes.object.isRequired,

  setSelected: PropTypes.func.isRequired,
  setAdress: PropTypes.func.isRequired,
  withSearch: PropTypes.bool.isRequired,
};

Places.defaultProps = {
  containerClass: "",
  style: {
    height: "300px",
    width: "400px",
  },
  selected: { lat: -7.7955798, lng: 110.3694896 },
  setSelected: () => {},
  setAdress: () => {},
  withSearch: true,
};

export default Places;
