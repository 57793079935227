import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Drawers from "../../components/drawer";
import Popup from "../../components/popup";
import AlertPopup from "../../components/popup/alert";
import {
  activeNoWACabang,
  checkQRWAStatusLoading,
  checkingLisensiNoWA,
  checkingQRWAStatus,
  deleteIdCabang,
  getAllCabang,
  getBranchDetail,
  getHistoryWA,
  logoutWA,
  setQRWAStatus,
} from "../../stores/actions/cabang";
import { resetDataUpload } from "../../stores/actions/uploadFile";
import CabangStyle from "./Cabang.style";
import AddOrEditCabang from "./components/AddOrEditCabang";
import DetailCabang from "./components/DetailCabang";
import ListCabang from "./components/ListCabang";

import moment from "moment";

import { detailById } from "stores/actions/general";
import Selects from "components/form/selects";
import { makeStyles } from "@material-ui/core";
import { Trash, RefreshLeftSquare } from "iconsax-react";

import IconCalender from "../../assets/icons/icon-calendar-maroon.svg";
import IconAvatarIkhawn from "../../assets/icons/ava-ikhwan.svg";
import IconWA from "../../assets/icons/icon-whatsapp-white.svg";

import Buttons from "components/buttons";
import Inputs from "components/form/inputs";
import { useFormik } from "formik";
import * as Yup from "yup";
import FlagIndonesia from "assets/icons/flag-indonesia.svg";
import IconSearch from "assets/icons/Search.svg";
import InputPhone from "components/form/inputPhone";
import { MESSAGE_WA } from "./helper";
import Spinner from "components/spinner";
import { generateQRwaWA, getInfoKontakWA } from "stores/actions/cabang";
import { Skeleton } from "antd";

import { getLocalStorage } from "utils/helpers";

const useStyles = makeStyles({
  tabContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    borderRadius: "16px",
    padding: "16px",
    border: "1px solid #E0E0E0",
    fontSize: "14px",
    color: "#141414",
    marginRight: "16px",
    cursor: "pointer",
  },
  active: {
    backgroundColor: "#FFDCDC",
    fontWeight: 600,
    color: "#E82320",
  },
  sucess: {
    backgroundColor: "#00B72814",
    fontWeight: 600,
    color: "#00B728",
  },
  pending: {
    backgroundColor: "rgba(255, 132, 0, 0.08)",
    fontWeight: 600,
    color: "#FF8400",
  },
  container: {
    marginTop: "16px",
    display: "flex",
    flexDirection: "column",
    borderRadius: "16px",
    border: "1px solid #E0E0E0",
    padding: "16px",
    "& .header": {
      display: "flex",
      justifyContent: "space-between",
      "& .container_profile": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        "& .photo": {
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "24px",
          height: "24px",
          borderRadius: "12px",
        },
        "& .title": {
          fontFamily: "GeneralSans",
          fontSize: "12px",
          fontWeight: 600,
        },
      },
      "& .badge_success": {
        fontSize: "12px",
        fontWeight: 600,
        color: "#00B728",
        backgroundColor: " #00B72814",
        padding: "4px 8px 4px 8px",
        gap: 4,
        borderRadius: "12px",
      },
      "& .badge_pending": {
        gap: 4,
        borderRadius: "12px",
        fontSize: "12px",
        fontWeight: 600,
        color: "#FF8400",
        backgroundColor: "rgba(255, 132, 0, 0.08)",
        padding: "4px 8px 4px 8px",

        borderRadius: "12px",
      },
      "& .badge_gagal": {
        fontSize: "12px",
        fontWeight: 600,
        color: "#E82320",
        backgroundColor: "rgba(255, 220, 220, 1)",
        padding: "4px 8px 4px 8px",
        gap: 4,

        borderRadius: "12px",
      },
    },
    "& .body": {
      marginTop: "16px",
      marginBottom: "16px",
      "& .title": {
        fontSize: "12px",
        color: "#141414",
      },
    },
    "& .footer": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      "& .date": {
        display: "flex",
        flexDirection: "column",
        "& .container_profile": {
          display: "flex",
          flexDirection: "row",
          "& .photo": {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "16px",
            height: "16px",
          },
          "& .title": {
            fontFamily: "GeneralSans",
            fontSize: "12px",
            fontWeight: 400,
            color: "#4F4F4F",
            marginLeft: "8px",
          },
        },
      },
      "& .icon": {
        display: "flex",
        flexDirection: "column",
        "& .container_icon": {
          display: "flex",
          flexDirection: "row",
          "& .icon_reload": {
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "16px",
            height: "16px",
            marginLeft: "16px",
          },
        },
      },
    },
  },
  containerRequestWA: {
    padding: "16px",
    boxShadow: "0px 4px 80px -4px #0000000A",
    marginTop: "7px",
    "& .content": {
      color: "#141414",
      fontSize: "14px",
      fontWeight: 400,
      textAlign: "justify",
    },
    "& .title": {
      fontSize: "14px",
      fontWeight: 600,
      color: "#4F4F4F",
      marginBottom: "8px",
    },
    "& .footer": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      borderTop: " 1px solid #E0E0E0",
      paddingTop: "16px",
    },
  },
});

const CabangPage = () => {
  const [modal, setModal] = useState({
    addCabang: false,
    status: false,
  });
  const { global } = useSelector((state) => state.general);
  const dispatch = useDispatch();

  const {
    isLoading,
    isHistoryLoading,
    isCheckLicenseLoading,
    isEnableWABranchLoading,
    isLogoutWALoading,
    qrStatus,
  } = useSelector((state) => state.cabang);
  const { byDetail } = useSelector((state) => state.general);
  const [historyChat, setHistoryChat] = useState(false);
  const [is_requestWA, setIsRequestWA] = useState(false);
  const [connectWA, setConnectWA] = useState(false);

  const [confirmLogoutWA, setConfirmLogoutWA] = useState(false);

  const [sucessField, setSuccessField] = useState({
    checkLicense: "",
  });

  const [valueQR, setValueQR] = useState("");
  const [licenseWAValid, setLicenseWAValid] = useState(false);

  const [listHistoryChat, setHistoryChatList] = useState([]);
  const [choiceHistory, setChoiceHistory] = useState([
    {
      label: "Month",
      value: "month",
    },
  ]);

  const { generateQRLoading, isCheckQRStatusLoading } = useSelector(
    (state) => state.cabang
  );

  const [filter_badge, setFilterBadge] = useState("all");

  const classes = useStyles();

  const formikWaRequest = useFormik({
    initialValues: {
      lisensi: "",
    },
    validationSchema: Yup.object({
      lisensi: Yup.string().required("Lisensi WA wajib diisi"),
    }),
  });

  const formikWaRequestCabang = useFormik({
    initialValues: {
      nama: "hh",
      telephone: "",
    },
    validationSchema: Yup.object({
      nama: Yup.string().required("This field required"),
      telephone: Yup.string().required("This field required"),
    }),
  });

  const allCabang = useCallback(() => {
    dispatch(
      getAllCabang({
        page_number: global?.currentpage,
        per_page: global?.size,
        sort_column: "",
        sort_order: "Desc",
        keyword: "",
      })
    );
  }, [dispatch, global?.currentpage, global?.size]);

  useEffect(() => {
    if (qrStatus === "online") {
      handleQRCode("closed");
      dispatch(getBranchDetail(byDetail?.data));
    }
  }, [byDetail?.data, dispatch, qrStatus]);

  useEffect(() => {
    if (choiceHistory || filter_badge) {
      const payload = {
        id_cabang: byDetail?.data?.id_cabang,
        time_choose: choiceHistory[0]?.value,
        status: filter_badge,
      };

      if (byDetail?.data?.id_cabang) {
        dispatch(
          getHistoryWA(payload, (message) => {
            setHistoryChatList(message?.data?.wa_history);
          })
        );
      }
    }
  }, [choiceHistory, filter_badge]);

  useEffect(() => {
    if (isHistoryLoading) {
      setHistoryChatList([]);
    }
  }, [isHistoryLoading]);

  useEffect(() => {
    moment.locale("id");
  });

  useEffect(() => {
    allCabang();
  }, [allCabang]);

  useEffect(() => {
    if (valueQR.length > 0) {
      const payload = {
        id_cabang: byDetail?.data?.id_cabang,
      };

      let generateQRRepeat = setInterval(() => {
        if (valueQR !== "closed" || qrStatus !== "online") {
          dispatch(
            generateQRwaWA(payload, (message) => {
              handleQRCode(message?.data?.qrcode);
            })
          );
          dispatch(checkQRWAStatusLoading(true));
          dispatch(
            checkingQRWAStatus(
              { id_cabang: byDetail?.data?.id_cabang },
              (res) => {
                if (res.message === "online") {
                  setQRWAStatus("online");
                  dispatch(
                    detailById({
                      detail: true,
                      scanQR: false,
                      data: byDetail?.data,
                      contact: byDetail?.contact,
                    })
                  );
                  dispatch(checkQRWAStatusLoading(false));
                  setTimeout(() => {
                    handleQRCode("closed");
                  }, 1000);
                  dispatch(getBranchDetail(byDetail?.data));
                }
              },
              (err) => {
                dispatch(checkQRWAStatusLoading(false));
              }
            )
          );
        }
      }, 30000);

      if (!byDetail?.scanQR || byDetail?.contact?.data?.wa_info?.statu) {
        clearInterval(generateQRRepeat);
      } else {
        return () => clearInterval(generateQRRepeat);
      }
    }
  }, [valueQR, qrStatus]);

  const handleConfirmDeleteCabang = useCallback(() => {
    dispatch(deleteIdCabang(byDetail?.data?.id_cabang)).then((item) => {
      allCabang();
      dispatch(detailById({}));
    });
  }, [dispatch, byDetail?.data?.id_cabang, allCabang]);

  const handleCheckLisensi = () => {
    const payload = {
      id_cabang: byDetail?.data?.id_cabang,
      license: formikWaRequest.values.lisensi,
    };
    setSuccessField({
      checkLicense: "",
    });
    setLicenseWAValid(false);
    dispatch(
      checkingLisensiNoWA(
        payload,
        (message) => {
          setLicenseWAValid(true);
          setSuccessField({
            checkLicense: message.message,
          });
        },
        (err) => {
          formikWaRequest.setFieldError("lisensi", err.message);
        }
      )
    );
  };

  const handleActifkanNoWACabang = () => {
    const payload = {
      id_cabang: byDetail?.data?.id_cabang,
      license: formikWaRequest.values.lisensi,
    };

    dispatch(
      activeNoWACabang(payload, (res) => {
        dispatch(detailById({ detail: false }));
        setIsRequestWA(false);

        dispatch(
          getAllCabang({
            page_number: global?.currentpage,
            per_page: global?.size,
            sort_column: "",
            sort_order: "Desc",
            keyword: "",
          })
        );

        setTimeout(() => {
          formikWaRequest.resetForm();
          setSuccessField({});
        }, 1000);
      })
    );
  };

  const handleRequestNomerWA = () => {
    let link = `https://wa.me/${
      formikWaRequestCabang.values.telephone
    }?text=${MESSAGE_WA(byDetail?.data?.name)}`;
    window.open(link, "_blank");
  };

  const handleLogoutWA = () => {
    setConfirmLogoutWA(true);
  };

  const handleLogoutWAService = () => {
    dispatch(
      logoutWA({ id_cabang: byDetail?.data?.id_cabang }, (res) => {
        dispatch(
          detailById({
            detail: false,
            contact: {
              ...byDetail?.contact,
              data: {
                ...byDetail?.contact.data,
                status: "online",
                wa_info: { ...byDetail?.contact.data, status: "online" },
              },
            },
          })
        );
        // dispatch(detailById({ detail: false }));
        setConfirmLogoutWA(false);
        setQRWAStatus("offline");
        dispatch(
          getAllCabang({
            page_number: global?.currentpage,
            per_page: global?.size,
            sort_column: "",
            sort_order: "Desc",
            keyword: "",
          })
        );
      })
    );
  };

  const handleQRCode = (message) => {
    setValueQR(message);
  };

  const handleSubmitSudahScan = () => {
    dispatch(checkQRWAStatusLoading(true));
    setTimeout(() => {
      dispatch(
        checkingQRWAStatus(
          { id_cabang: byDetail?.data?.id_cabang },
          (res) => {
            if (res.message === "online") {
              setQRWAStatus("online");
              handleQRCode("closed");
              dispatch(getBranchDetail(byDetail?.data));
              dispatch(checkQRWAStatusLoading(false));
            }
          },
          (err) => {
            dispatch(checkQRWAStatusLoading(false));
          }
        )
      );
    }, 25000);
  };

  const userLogin =
    getLocalStorage("userLogin") && JSON.parse(getLocalStorage("userLogin"));
  const permissions = userLogin.data.account.permissions;

  const renderBadge = (status) => {
    if (status === "Terkirim") {
      return <div className="badge_success">{status}</div>;
    } else if (status === "Gagal") {
      return <div className="badge_gagal">{status}</div>;
    } else if (status === "Tertunda") {
      return <div className="badge_pending">{status}</div>;
    } else {
      return <div className="badge_gagal">{status}</div>;
    }
  };

  const getContent = () => {
    return (
      <>
        <AlertPopup
          open={byDetail?.delete}
          handleClose={() => dispatch(detailById({}))}
          handleContinue={handleConfirmDeleteCabang}
          loading={isLoading}
          title="Hapus Cabang"
          subtitle={`Apakah Anda sudah yakin ingin menghapus Cabang ${byDetail?.data?.name}?`}
        />

        <AlertPopup
          open={confirmLogoutWA}
          handleClose={() => setConfirmLogoutWA(false)}
          handleContinue={handleLogoutWAService}
          loading={isLogoutWALoading}
          title="Logout WA"
          text_accept="Logout"
          subtitle={`Apakah anda yakin untuk logout WA?`}
        />

        {/* {permissions?.includes("create_branch") || permissions?.includes("update_branch") && ( */}
        <Popup
          open={byDetail?.update || byDetail?.add}
          handleClose={() => {
            dispatch(resetDataUpload());

            if (byDetail?.add) {
              dispatch(detailById({ add: false }));
            } else if (byDetail?.update) {
              dispatch(detailById({ detail: true, data: byDetail?.data }));
            }
          }}
          title={byDetail?.update ? "Edit Cabang" : "Tambah Cabang"}
          width="1080px"
          containerClass="!z-[1000]"
          children={<AddOrEditCabang />}
        />
        {/* } */}

        <Popup
          open={byDetail?.scanQR}
          handleClose={() => {
            dispatch(
              detailById({
                detail: true,
                scanQR: false,
                data: byDetail?.data,
                contact: byDetail?.contact,
              })
            );
            setTimeout(() => {
              handleQRCode("closed");
            }, 1000);
          }}
          title="Scan QR Whatsapp"
          width="384px"
          children={
            <div className="flex justify-center">
              {generateQRLoading ? (
                <div className="my-12">
                  <Spinner width="75px" height="75px" />
                </div>
              ) : (
                <div>
                  <img src={`data:image/jpeg;base64,${valueQR}`} />
                  <Buttons
                    text="Saya sudah Scan"
                    customClass="btn-primary btn-medium mt-4"
                    loading={isCheckQRStatusLoading}
                    disabled={isCheckQRStatusLoading}
                    onClick={handleSubmitSudahScan}
                  />
                </div>
              )}
            </div>
          }
        />

        <Popup
          open={is_requestWA}
          handleClose={() => setIsRequestWA(false)}
          title="Nomor WA Cabang"
          width="760px"
          children={
            <div className={classes.containerRequestWA}>
              <div className="h-auto">
                <div className="title">Lisensi</div>
                <div className="mb-[20px]">
                  <Inputs
                    placeholder="Lisensi"
                    name="lisensi"
                    value={formikWaRequest.values.lisensi}
                    onChange={formikWaRequest.handleChange}
                    onBlur={formikWaRequest.handleBlur}
                    success={sucessField.checkLicense}
                    error={
                      formikWaRequest.touched && formikWaRequest.errors.lisensi
                    }
                  />
                </div>
                <div className="w-[162px]">
                  <Buttons
                    text="Cek Lisensi"
                    customClass="btn-primary btn-medium"
                    loading={isCheckLicenseLoading}
                    disabled={
                      !(formikWaRequest.dirty && formikWaRequest.isValid)
                    }
                    onClick={() => handleCheckLisensi()}
                  />
                </div>
                <div className="footer mt-4">
                  <div className="w-[162px]">
                    <Buttons
                      text="Aktifkan"
                      customClass="btn-primary btn-large"
                      onClick={() => handleActifkanNoWACabang()}
                      disabled={!licenseWAValid}
                      loading={isEnableWABranchLoading}
                    />
                  </div>
                </div>
              </div>
            </div>
          }
        />

        <Popup
          open={connectWA}
          handleClose={() => setConnectWA(!connectWA)}
          title="Request Nomor Whatsapp Cabang"
          width="760px"
          children={
            <div className={classes.containerRequestWA}>
              <div className="h-auto">
                <div className="content !p-0">
                  Untuk dapat menggunakan nomor Whatsapp Cabang sendiri,
                  silahkan masukan nama cabang dan nomor yang akan dijadikan WA
                  cabang Anda. Anda akan diarahkan melaui Whatsapp untuk
                  melakukan konfirmasi dengan menekan tombol request di bawah
                  ini
                </div>

                <div className="mb-4 mt-4">
                  <Inputs
                    readonly={true}
                    placeholder="Masukan Nama"
                    name="nama"
                    label="Nama Cabang"
                    value={byDetail?.data?.name}
                    onChange={formikWaRequestCabang.handleChange}
                    onBlur={formikWaRequestCabang.handleBlur}
                    error={
                      formikWaRequestCabang.touched &&
                      formikWaRequestCabang.errors.nama
                    }
                  />
                </div>

                <div className="mb-4">
                  <InputPhone
                    id="test"
                    name="telephone"
                    value={formikWaRequestCabang.values.telephone}
                    placeholder="Text Input"
                    label="Nomor Whatsapp Cabang"
                    onChange={formikWaRequestCabang.handleChange}
                    onBlur={formikWaRequestCabang.handleBlur}
                    icon={FlagIndonesia}
                    enableSearch
                    onClickIcon={() => {}}
                  />
                </div>

                <div className="footer">
                  <div className="w-[162px]">
                    <Buttons
                      text="Request"
                      customClass="btn-primary btn-large"
                      onClick={() => handleRequestNomerWA()}
                      icon={IconWA}
                    />
                  </div>
                </div>
              </div>
            </div>
          }
        />

        <Popup
          open={historyChat}
          handleClose={() => {
            setHistoryChat(false);
            setFilterBadge("all");
          }}
          title="History Whatsapp"
          width="760px"
          children={
            <div className="flex flex-col h-[540px] p-[16px] shadow-soft rounded-2xl">
              <div className="flex flex-row justify-between">
                <div className="flex flex-row w-full">
                  <div
                    className={`${classes.tabContainer} ${
                      filter_badge === "all" ? classes.active : ""
                    }`}
                    onClick={() => setFilterBadge("all")}
                  >
                    Semua
                  </div>
                  <div
                    className={`${classes.tabContainer} ${
                      filter_badge === "success" ? classes.sucess : ""
                    }`}
                    onClick={() => setFilterBadge("success")}
                  >
                    Terkirim
                  </div>
                  <div
                    className={`${classes.tabContainer} ${
                      filter_badge === "pending" ? classes.pending : ""
                    }`}
                    onClick={() => setFilterBadge("pending")}
                  >
                    Pending
                  </div>
                  <div
                    className={`${classes.tabContainer} ${
                      filter_badge === "failed" ? classes.active : ""
                    }`}
                    onClick={() => setFilterBadge("failed")}
                  >
                    Gagal
                  </div>
                </div>
                <div className="flex flex-col">
                  <Selects
                    placeholder="Semua tanggal"
                    customClass="gap-4 items-center"
                    options={[
                      {
                        label: "Hari Ini",
                        value: "today",
                      },
                      {
                        label: "Kemarin",
                        value: "yesterday",
                      },
                      {
                        label: "7 hari terakhir",
                        value: "7days",
                      },
                      {
                        label: "30 hari terakhir",
                        value: "month",
                      },
                    ]}
                    onChange={(e) => {
                      setChoiceHistory(e);
                    }}
                  />
                </div>
              </div>

              {isHistoryLoading && (
                <div className="mt-6">
                  <div className="mb-10">
                    <div className="flex gap-4 mb-2">
                      <Skeleton.Avatar active size="small" shape="circle" />
                      <Skeleton.Input active size="small" />
                    </div>
                    <div className="flex flex-col gap-2">
                      <Skeleton.Input active size="small" block />
                      <Skeleton.Input active size="small" block />
                    </div>
                  </div>
                  <div className="mb-10">
                    <div className="flex gap-4 mb-2">
                      <Skeleton.Avatar active size="small" shape="circle" />
                      <Skeleton.Input active size="small" />
                    </div>
                    <div className="flex flex-col gap-2">
                      <Skeleton.Input active size="small" block />
                      <Skeleton.Input active size="small" block />
                    </div>
                  </div>
                  <div className="mb-10">
                    <div className="flex gap-4 mb-2">
                      <Skeleton.Avatar active size="small" shape="circle" />
                      <Skeleton.Input active size="small" />
                    </div>
                    <div className="flex flex-col gap-2">
                      <Skeleton.Input active size="small" block />
                      <Skeleton.Input active size="small" block />
                    </div>
                  </div>
                </div>
              )}

              {!isHistoryLoading && (
                <div>
                  {listHistoryChat.length > 0 &&
                    listHistoryChat?.map((item) => (
                      <div className={classes.container}>
                        <div className="header">
                          <div className="container_profile gap-4">
                            <div className="photo">
                              <img src={IconAvatarIkhawn} alt="icon_profile" />
                            </div>
                            <div className="title">{item?.penerima}</div>
                          </div>
                          {renderBadge(item.status)}
                        </div>
                        <div className="body">
                          <div className="title">{item?.isi_pesan}</div>
                        </div>
                        <div className="footer">
                          <div className="date">
                            <div className="container_profile">
                              <div className="photo">
                                <img src={IconCalender} alt="icon_calender" />
                              </div>
                              <div className="title">
                                {moment(
                                  item?.tanggal,
                                  "YYYY-MM-DD HH:mm:ss"
                                ).format("D MMMM YYYY, HH:mm")}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              )}
            </div>
          }
        />

        <CabangStyle>
          <Drawers open={byDetail?.detail}>
            <DetailCabang
              handleHistory={() => {
                setHistoryChat(true);

                const payload = {
                  id_cabang: byDetail?.data?.id_cabang,
                  time_choose: "month",
                  status: "all",
                };

                if (byDetail?.data?.id_cabang) {
                  dispatch(
                    getHistoryWA(payload, (message) => {
                      setHistoryChatList(message?.data?.wa_history);
                    })
                  );
                }
              }}
              handleWACabang={() => setIsRequestWA(!is_requestWA)}
              setConnectWA={() => setConnectWA(!connectWA)}
              connectWA={connectWA}
              is_requestWA={is_requestWA}
              handleQRCode={handleQRCode}
              handleLogoutWA={handleLogoutWA}
            />
          </Drawers>
          <ListCabang />
          <div id="area-modal"></div>
        </CabangStyle>
      </>
    );
  };

  return getContent();
};

export default CabangPage;
