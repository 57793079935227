/* eslint-disable import/no-anonymous-default-export */
// uploadFile Reducers
// --------------------------------------------------------

import {
  SET_LOADING,
  CLEAR_ERROR,
  SET_ERROR,
  INIT_DATA,
  SET_DOUBLE_SUBMIT,
  SET_UPLOAD,
  SET_UPLOAD_MULTIPLE,
  SET_CLEAR,
  SET_UPLOAD_IMAGE,
} from "../../actions/uploadFile";

const initialState = {
  isLoading: false,
  isDoubleSubmit: false,
  isSubmitting: false,
  error: {},
  dataUpload: [],
  dataMultiple: [],
  uploadImage: [],
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_DOUBLE_SUBMIT:
      return {
        ...state,
        isDoubleSubmit: payload,
      };
    case SET_ERROR:
      return {
        ...state,
        error: payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: {},
      };
    case INIT_DATA:
      return {
        ...initialState,
      };
    case SET_UPLOAD:
      return {
        ...state,
        dataUpload: payload,
      };
    case SET_UPLOAD_MULTIPLE:
      return {
        ...state,
        dataMultiple: payload,
      };
    case SET_UPLOAD_IMAGE:
      return {
        ...state,
        uploadImage: payload,
      };
    case SET_CLEAR:
      return {
        ...state,
        dataMultiple: [],
        dataUpload: [],
        uploadImage: [],
      };
    default:
      return state;
  }
};
