import React, { useState } from "react";

import Buttons from "components/buttons";
import Inputs from "components/form/inputs";
import Tab from "components/tab";
import AddJamaah from "./components/AddJamaah";
import Popup from "components/popup";
import BookingDetailDataJamaah from "../BookingDetailDataJamaah";
import BookingDetailRoomlist from "../BookingDetailRoomlist";
import BookingDetailBlockseat from "../BookingDetailBlockseat";

import IconSearch from "assets/icons/search-normal.svg";
import IconJamaah from "assets/icons/Jamaah.svg";

const BookingDetailContent = () => {
  const [openPopupAddJamaah, setOpenPopupAddJamaah] = useState(false);

  return (
    <>
      <Popup
        open={openPopupAddJamaah}
        handleClose={() => setOpenPopupAddJamaah(false)}
        title="Tambah Jamaah"
        width="752px"
        children={<AddJamaah setOpenPopupAddJamaah={setOpenPopupAddJamaah} />}
      />
      <div className="card-detail-booking-content px-4 py-6">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row gap-2 w-full items-center">
            <img src={IconJamaah} alt="jamaah" />
            <div className="text-[20px] font-semibold">Jumlah Jamaah:</div>
            <div className="card-detail-booking-content-total-jamaah">11</div>
          </div>
          <div className="flex flex-row gap-2">
            <Inputs
              id="test"
              placeholder="Search"
              customClass="!px-4 !py-[14px] !pl-[42px] !w-[267px]"
              iconPosition="left"
              icon={IconSearch}
            />
            <Buttons
              text="Tambah Additional"
              customClass="btn-primary py-4 px-[18px]"
            />
            <Buttons
              text="Tambah Jamaah"
              customClass="btn-primary py-4 px-[18px]"
              onClick={() => setOpenPopupAddJamaah(true)}
            />
          </div>
        </div>
        <div>
          <Tab
            defaultActiveKey="1"
            items={[
              {
                label: "Data Jamaah",
                key: "1",
                children: <BookingDetailDataJamaah />,
              },
              {
                label: "Roomlist",
                key: "2",
                children: <BookingDetailRoomlist />,
              },
              {
                label: "Blockseat",
                key: "3",
                children: <BookingDetailBlockseat />,
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};

export default React.memo(BookingDetailContent);
