import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";

const Badge = ({
  label,
  type,
  circle,
  customClass,
  style,
  color,
  backgroundColor,
}) => {
  const useStyle = makeStyles({
    container: {
      display: "flex",
      flexDirection: "row",
      position: "relative",
      padding: "6px 16px",
      borderRadius: "8px",
      alignItems: "flex-start",
      fontFamily: "GeneralSansSemibold",
      fontWeight: "600",
      gap: "8px",
      width: "max-content",
      fontSize: "10px",
      "&:hover": {},
      ...style,
      color: color,
      backgroundColor: backgroundColor,
    },
  });

  const classes = useStyle();

  return (
    <div className={`${classes.container} ${customClass}`}>
      {circle && (
        <div
          className="w-2 h-2 rounded-full"
          style={{ backgroundColor: circle }}
        />
      )}{" "}
      {label}
    </div>
  );
};

Badge.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  customClass: PropTypes.string.isRequired,
};
Badge.defaultProps = {
  label: "Super Admin",
  type: "red",
  color: "#FE0000",
  backgroundColor: "#F7A399",
  customClass: "",
};

export default React.memo(Badge);
