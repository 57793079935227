import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import parse from "html-react-parser";
import SettingAboutAction from "stores/actions/settings/about";

import Buttons from "../../../../components/buttons";
import Cards from "../../../../components/cards";
import PopupConfirm from "components/popup/error-handling";
import EditorToolbar, {
  formats,
  modules,
} from "components/richTextEditor/EditorToolbar";
import ReactQuill from "react-quill";
import { toBase64 } from "utils/helpers";

const SettingAbout = () => {
  const dispatch = useDispatch();
  const store = useSelector(({ settingAbout }) => settingAbout);
  const [input, setInput] = useState();
  const [edit, setEdit] = useState(false);
  const [openPopup, setOpenPopup] = useState({
    show: false,
    type: "",
    title: "",
    subtitle: "",
  });

  useEffect(() => {
    dispatch(SettingAboutAction.fetchData());
  }, []);

  useEffect(() => {
    if (store && store.data) {
      setInput(window?.atob(store?.data?.tentang_aplikasi));
    }
  }, [store, store.data]);

  const handleSaveAbout = async () => {
    const status = await dispatch(
      SettingAboutAction.postData({
        tentang_aplikasi: toBase64("encode", input),
      })
    );
    if (status === 200) {
      setEdit(false);
      setOpenPopup({
        show: true,
        type: "success",
        title: "Berhasil",
        subtitle: "Berhasil edit tentang aplikasi",
      });
    } else {
      setOpenPopup({
        show: true,
        type: "error",
        title: "Gagal",
        subtitle: "Gagal edit tentang aplikasi",
      });
    }
  };

  return (
    <>
      <PopupConfirm
        open={openPopup.show}
        handleClose={() =>
          setOpenPopup({
            show: false,
            type: "",
            title: "",
            subtitle: "",
          })
        }
        type={openPopup.type}
        title={openPopup.title}
        subtitle={openPopup.subtitle}
      />
      <Cards containerClass="!p-0">
        <div className="flex flex-row justify-between items-center p-4">
          <div className="text-[20px] font-semibold">Tentang Aplikasi</div>
          {edit ? (
            <div className="flex flex-row gap-4">
              <Buttons
                text="Batalkan"
                customClass="btn-outline btn-small"
                onClick={() => {
                  setEdit(false);
                }}
              />
              <Buttons
                text="Simpan"
                loading={store?.isLoading}
                customClass={`btn-primary btn-small ${
                  store?.isLoading && "loading"
                }`}
                onClick={() => {
                  handleSaveAbout();
                }}
              />
            </div>
          ) : (
            <Buttons
              text="Edit Data"
              customClass="btn-primary btn-small"
              onClick={() => {
                setEdit(true);
              }}
            />
          )}
        </div>
        <div className="divider"></div>
        <div className="p-4">
          {edit ? (
            <div className="text-editor mt-4 p-4">
              <EditorToolbar />
              <ReactQuill
                id="content"
                name="content"
                theme="snow"
                value={input}
                onChange={(e) => setInput(e)}
                modules={modules}
                formats={formats}
                className="bg-[#EDF2F4] w-full h-[68vh]"
              />
            </div>
          ) : (
            <div>
              {store?.data &&
                parse(window?.atob(store?.data?.tentang_aplikasi))}
            </div>
          )}
        </div>
      </Cards>
    </>
  );
};

export default React.memo(SettingAbout);
