import React, { useState } from "react";
import { Grid } from "@material-ui/core";

import Buttons from "components/buttons";
import Rates from "components/form/rate";
import Badge from "components/badge";
import MoslemeTable from "components/Table";
import Progressbars from "components/progressbars";
import Popup from "components/popup";
import Inputs from "components/form/inputs";
import AlertPopup from "components/popup/alert";

import Hotel from "assets/images/hotel-madinah.jpg";
import IconHotel from "assets/icons/Hotel Akomodasi1.svg";
import IconLocation from "assets/icons/icon-location-maroon.svg";
import AvatarRed from "assets/images/Avatar-ikhwan-red.svg";
import IconSingle from "assets/icons/Single.svg";
import IconDouble from "assets/icons/Double.svg";
import IconTriple from "assets/icons/Triple.svg";
import IconQuad from "assets/icons/Quad.svg";
import IconSearch from "assets/icons/search-normal.svg";

const PaketRoomlist = () => {
  const [grouping, setGrouping] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [openSaveGrouping, setOpenSaveGrouping] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listFilter, setListFilter] = useState([
    {
      id: 0,
      label: "Single",
      count: 1,
      active: true,
      icActive: IconSingle,
      icInactive: IconSingle,
    },
    {
      id: 1,
      label: "Double",
      count: 6,
      active: false,
      icActive: IconDouble,
      icInactive: IconDouble,
    },
    {
      id: 2,
      label: "Tripple",
      count: 2,
      active: false,
      icActive: IconTriple,
      icInactive: IconTriple,
    },
    {
      id: 3,
      label: "Quad",
      count: 1,
      active: false,
      icActive: IconQuad,
      icInactive: IconQuad,
    },
  ]);
  const [currentpage, setCurrentpage] = useState(1);
  const [size, setSize] = useState(10);
  const total = 100;

  const columns = [
    {
      title: "Foto",
      dataIndex: "photo",
      render: (img) => <img src={img} alt="img" />,
    },
    {
      title: "Nama Sesuai Passport",
      dataIndex: "passport_name",
    },
    {
      title: "Kamar",
      dataIndex: "room",
      render: () => (
        <div className="flex flex-row gap-2 px-2 py-1 bg-[#D8E1BE] w-fit rounded-lg items-center">
          <img src={IconDouble} alt="double" />
          <div className="font-medium">Double</div>
        </div>
      ),
    },
    {
      title: "Relasi",
      dataIndex: "relation",
      render: (relasi) => (
        <div className="bg-[#FFDCDC] rounded-lg px-4 py-2 w-fit">{relasi}</div>
      ),
    },
    {
      title: "Jamaah Cabang",
      dataIndex: "branch",
      render: (branch) => <Badge label={branch} />,
    },
  ];

  const data = [
    {
      key: "1",
      photo: AvatarRed,
      passport_name: "Dimas Andreyan Pradana Putra",
      room: "",
      relation: "Suami",
      branch: "Yogyakarta",
    },
    {
      key: "2",
      photo: AvatarRed,
      passport_name: "Dimas Andreyan Pradana Putra",
      room: "",
      relation: "Suami",
      branch: "Yogyakarta",
    },
    {
      key: "3",
      photo: AvatarRed,
      passport_name: "Dimas Andreyan Pradana Putra",
      room: "",
      relation: "Suami",
      branch: "Yogyakarta",
    },
  ];

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: total,
    current: currentpage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20"],
  };

  // func to set size pagination
  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setSize(pageSize);
  };

  const handleFilter = (id) => {
    let temp = [...listFilter];
    temp.forEach((v) => {
      if (v.id === id) {
        v.active = true;
      } else {
        v.active = false;
      }
    });

    setListFilter(temp);
  };

  return (
    <>
      <AlertPopup
        open={openSaveGrouping}
        title="Apakah anda yakin?"
        subtitle="Apakah kamu yakin ingin menukar “Dimas Andreyan Pradana Putra” dengan “Abdul Wahid Ngatono” ?"
        handleClose={() => setOpenSaveGrouping(false)}
        handleContinue={() => setOpenSaveGrouping(false)}
        loading={loading}
      />
      <Popup
        open={openPopup}
        handleClose={() => setOpenPopup(false)}
        title="Atur Anggota Kamar"
        width={"1080px"}
        containerClass="z-[1000]"
      >
        <div className="border border-[#E0E0E0] rounded-2xl">
          <div className="p-4">
            <div className="flex flex-row items-center gap-6 soft-shadow rounded-xl p-4">
              <div className="flex flex-row items-center gap-2">
                <div className="flex flex-row gap-2 px-2 py-1 bg-[#D8E1BE] w-fit rounded-lg items-center">
                  <img src={IconDouble} alt="double" />
                  <div className="font-bold">Double</div>
                  <div class="flex justify-center px-2 py-1 items-center rounded-full bg-white border-[1px] border-[#F90601] w-[20px] h-[20px]">
                    <div class="txt-maroon text-[12px] font-medium">1</div>
                  </div>
                </div>
              </div>
              <Progressbars
                containerClass="mt-4 w-[30%]"
                percent={80}
                label="Kamar Terisi"
                status="exception"
                info="1/2"
              />
            </div>

            <Inputs
              id="test"
              containerClass="mt-4"
              label="Jamaah Yang Akan Ditukar"
              placeholder="Cari jamaah"
              customClass="!px-3 !py-4 !pl-[42px]"
              iconPosition="left"
              icon={IconSearch}
            />

            <div className="flex flex-row justify-between soft-shadow rounded-xl mt-4 p-4 cursor-pointer hover:bg-[#FFDCDC]">
              <div className="flex flex-row items-center gap-4">
                <img src={AvatarRed} alt="img" />
                <div>Dimas Andreyan Pradana Putra</div>
              </div>
              <div className="flex flex-row gap-2 px-2 py-1 bg-[#D8E1BE] w-fit rounded-lg items-center">
                <img src={IconDouble} alt="double" />
                <div className="font-medium">Double</div>
              </div>
              <div className="bg-[#FFDCDC] rounded-lg px-4 py-2 w-fit">
                Anak Laki-laki
              </div>
              <Badge customClass="!items-center" label="Yogyakarta" />
            </div>

            <Inputs
              containerClass="mt-4"
              label="Tukar dengan"
              placeholder="Cari jamaah"
              customClass="!px-3 !py-4 !pl-[42px]"
              iconPosition="left"
              icon={IconSearch}
            />

            <Buttons
              text="Tukar Dengan Kamar Yang Kosong"
              customClass="btn-secondary btn-large mt-4"
            />
          </div>
          <div className="divider"></div>
          <div className="flex flex-row justify-end p-4">
            <Buttons
              text="Simpan"
              customClass="btn-primary btn-large"
              onClick={() => setOpenSaveGrouping(true)}
            />
          </div>
        </div>
      </Popup>

      <div className="flex flex-row justify-end p-4">
        <Buttons text="Save" customClass="btn-primary btn-small" />
      </div>
      <div className="divider"></div>
      <Grid container className="p-4" spacing={2}>
        <Grid item md={3}>
          <div className="bg-white soft-shadow rounded-2xl">
            <img
              className="rounded-tl-2xl rounded-tr-2xl w-full"
              src={Hotel}
              alt="hotel"
            />
            <div className="p-4">
              <div className="text-[16px] font-semibold">Madinah Hotel</div>
              <div className="flex flex-row items-center mt-3">
                <img src={IconHotel} alt="hotel" />
                <div className="ml-2">Madinah</div>
                <Rates containerClass="ml-4" value={5} />
              </div>
              <div className="flex flex-row gap-2 items-center mt-2">
                <img src={IconLocation} alt="locaiton" />
                <div className="w-fit">
                  Amr Bin Al Gmoh Street, Madina, PO Box 40233, Madinah, 41499,
                  Arab Saudi
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item md={3}>
          <div className="bg-white soft-shadow rounded-2xl">
            <img
              className="rounded-tl-2xl rounded-tr-2xl w-full"
              src={Hotel}
              alt="hotel"
            />
            <div className="p-4">
              <div className="text-[16px] font-semibold">Mekkah Hotel</div>
              <div className="flex flex-row items-center mt-3">
                <img src={IconHotel} alt="hotel" />
                <div className="ml-2">Mekkah</div>
                <Rates containerClass="ml-4" value={5} />
              </div>
              <div className="flex flex-row gap-2 items-center mt-2">
                <img src={IconLocation} alt="locaiton" />
                <div className="w-fit">
                  P O Box 17700 Abraj Al Bait, Makkah 21955, Near King Abdul
                  Aziz, Mekkah, 21955, Arab Saudi
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item md={3}>
          <div className="bg-white soft-shadow rounded-2xl">
            <img
              className="rounded-tl-2xl rounded-tr-2xl w-full"
              src={Hotel}
              alt="hotel"
            />
            <div className="p-4">
              <div className="text-[16px] font-semibold">Madinah Hotel</div>
              <div className="flex flex-row items-center mt-3">
                <img src={IconHotel} alt="hotel" />
                <div className="ml-2">Madinah</div>
                <Rates containerClass="ml-4" value={5} />
              </div>
              <div className="flex flex-row gap-2 items-center mt-2">
                <img src={IconLocation} alt="locaiton" />
                <div className="w-fit">
                  Amr Bin Al Gmoh Street, Madina, PO Box 40233, Madinah, 41499,
                  Arab Saudi
                </div>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      <div className="divider"></div>
      <div className="flex flex-row justify-between p-4 mt-4">
        <div>
          <div className="text-[20px] font-semibold">Daftar Jamaah</div>
          {!grouping && (
            <div className="flex flex-row gap-4 mt-4">
              <div className="flex">
                {listFilter.map((list) => {
                  return (
                    <div
                      onClick={() => handleFilter(list.id)}
                      className={`flex rounded-lg items-center cursor-pointer px-3 py-1 border mr-3 ${
                        list.active
                          ? "bg-[#FAAB36] bg-opacity-30 border-[#FAAB36]"
                          : "bg-white border-[#e6e6e6]"
                      }`}
                      key={list.id}
                    >
                      <img
                        src={list.active ? list.icActive : list.icInactive}
                        alt="filter"
                      />
                      <p className="ml-2">
                        {list.label} ({list.count} pax)
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        <div>
          {grouping ? (
            <Buttons
              text="Simpan"
              customClass="btn-primary"
              onClick={() => setGrouping(false)}
            />
          ) : (
            <Buttons
              text="Atur Pengelompokan"
              customClass="btn-outline"
              onClick={() => setGrouping(true)}
            />
          )}
        </div>
      </div>
      <Grid container>
        {grouping && (
          <Grid item md={2} className="overflow-y-scroll">
            <div className="p-4">
              <div className="text-[16px] font-semibold">Filter Kamar</div>
              <div className="flex flex-row gap-4 mt-4">
                <div className="flex">
                  {listFilter.map((list) => {
                    return (
                      <div
                        onClick={() => handleFilter(list.id)}
                        className={`flex rounded-lg items-center cursor-pointer px-3 py-1 border mr-3 ${
                          list.active
                            ? "bg-[#FAAB36] bg-opacity-30 border-[#FAAB36]"
                            : "bg-white border-[#e6e6e6]"
                        }`}
                        key={list.id}
                      >
                        <img
                          src={list.active ? list.icActive : list.icInactive}
                          alt="filter"
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div
              className="p-4 bg-[#FBF3F2]"
              onClick={() => setOpenPopup(true)}
            >
              <div className="flex flex-row items-center gap-2">
                <div className="flex flex-row gap-2 px-2 py-1 bg-[#D8E1BE] w-fit rounded-lg items-center">
                  <img src={IconSingle} alt="single" />
                  <div className="font-bold">Single</div>
                  <div class="flex justify-center px-2 py-1 items-center rounded-full bg-white border-[1px] border-[#F90601] w-[20px] h-[20px]">
                    <div class="txt-maroon text-[12px] font-medium">1</div>
                  </div>
                </div>
                <div>(1pax)</div>
              </div>
              <Progressbars
                containerClass="mt-4"
                percent={100}
                label="Kamar Terisi"
                info="1/1"
              />
            </div>
            <div className="p-4" onClick={() => setOpenPopup(true)}>
              <div className="flex flex-row items-center gap-2">
                <div className="flex flex-row gap-2 px-2 py-1 bg-[#D8E1BE] w-fit rounded-lg items-center">
                  <img src={IconTriple} alt="triple" />
                  <div className="font-bold">Triple</div>
                  <div class="flex justify-center px-2 py-1 items-center rounded-full bg-white border-[1px] border-[#F90601] w-[20px] h-[20px]">
                    <div class="txt-maroon text-[12px] font-medium">3</div>
                  </div>
                </div>
                <div>(2pax)</div>
              </div>
              <Progressbars
                containerClass="mt-4"
                percent={30}
                label="Kamar Terisi"
                status="active"
                info="1/3"
              />
            </div>
            <div
              className="p-4 bg-[#FBF3F2]"
              onClick={() => setOpenPopup(true)}
            >
              <div className="flex flex-row items-center gap-2">
                <div className="flex flex-row gap-2 px-2 py-1 bg-[#D8E1BE] w-fit rounded-lg items-center">
                  <img src={IconQuad} alt="quad" />
                  <div className="font-bold">Quad</div>
                  <div class="flex justify-center px-2 py-1 items-center rounded-full bg-white border-[1px] border-[#F90601] w-[20px] h-[20px]">
                    <div class="txt-maroon text-[12px] font-medium">1</div>
                  </div>
                </div>
                <div>(1pax)</div>
              </div>
              <Progressbars
                containerClass="mt-4"
                percent={40}
                label="Kamar Terisi"
                status="active"
                info="1/2"
              />
            </div>
          </Grid>
        )}

        <Grid item md={grouping ? 10 : 12}>
          <MoslemeTable
            columns={columns}
            data={data}
            withSelection={false}
            selectionType={false}
            pagination={pagination}
            customPaginationClass="pr-4"
            onPageChange={(page) => setCurrentpage(page)}
            onSizeChange={(currentpage, pageSize) =>
              onSizeChange(currentpage, pageSize)
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default React.memo(PaketRoomlist);
