// doa Actions
// --------------------------------------------------------
import {
    handleError,
    handleErrorBE,
    handleSuccess,
} from "stores/actions/errorGeneral";
import {
    getDoaService,
    getDoaCategoryService,
    addDoaService,
    deleteDoaService,
} from "utils/api/layananIslami/doa";

/* eslint-disable space-before-function-paren */
export const SET_LOADING = "doa/SET_LOADING";
export const CLEAR_ERROR = "doa/CLEAR_ERROR";
export const SET_ERROR = "doa/SET_ERROR";
export const INIT_DATA = "doa/INIT_DATA";
export const SET_DOUBLE_SUBMIT = "doa/SET_DOUBLE_SUBMIT";
export const SET_DOA = "layananIslami/doa/SET_DOA";
export const SET_DOA_CATEGORY = "layananIslami/doa/SET_DOA_CATEGORY";

export const setLoading = (payload) => ({
    type: SET_LOADING,
    payload,
});

export const setDoubleSubmit = (payload) => ({
    type: SET_DOUBLE_SUBMIT,
    payload,
});

export const setDoa = (payload) => ({
    type: SET_DOA,
    payload,
});

export const setDoaCategory = (payload) => ({
    type: SET_DOA_CATEGORY,
    payload,
});

export const getDoaAction = (payload) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const res = await getDoaService(payload);
        dispatch(setLoading(false));
        const { status, data } = res;
        if (status == 200) {
            // handleSuccess(data);
            dispatch(setDoa(data));
        } else {
            handleErrorBE(res);
        }
    } catch (error) {
        dispatch(handleError(error));
    }
};

export const getDoaCategoryAction = () => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const payload = {
            per_page: 10,
            page_number: 1,
            sort_order: "DESC",
            sort_column: "no_urut",
            keyword: "",
        };
        const res = await getDoaCategoryService(payload);
        dispatch(setLoading(false));
        const { status, data } = res;
        if (status == 200) {
            dispatch(setDoaCategory(data));
        } else {
            handleErrorBE(res);
        }
    } catch (error) {
        dispatch(handleError(error));
    }
};

export const addDoaAction = (payload) => async (dispatch) => {
    try {
        const res = await addDoaService(payload);
        if (res.status === 200) {
            dispatch(handleSuccess(res.data));
            return res.status;
        } else {
            dispatch(handleErrorBE(res));
        }
    } catch (error) {
        dispatch(handleError(error));
    }
};

export const deleteDoaAction = (payload) => async (dispatch) => {
    try {
        dispatch(setLoading(true));
        const res = await deleteDoaService(payload);
        if (res.status === 200) {
            dispatch(handleSuccess(res.data));
            return res.status;
        } else {
            dispatch(handleErrorBE(res));
        }
    } catch (error) {
        dispatch(handleError(error));
    } finally {
        dispatch(setLoading(false));
    }
};
