import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppsSlideAction from "stores/actions/apps/slide";

import Popup from "components/popup";
import Buttons from "components/buttons";
import Tab from "components/tab";
import SlideWeb from "./components/Web";
import AddSlide from "./components/AddSlide";

const AppsSlide = () => {
  const dispatch = useDispatch();
  const store = useSelector(({ slide }) => slide);
  const [openPopup, setOpenPopup] = useState({
    show: false,
    edit: null,
  });

  useEffect(() => {
    dispatch(AppsSlideAction.fetchData());
  }, [dispatch, store.data.length, store.successEdit]);

  return (
    <>
      <Popup
        open={openPopup.show}
        handleClose={() =>
          setOpenPopup({
            show: false,
            edit: null,
          })
        }
        title={`${openPopup.edit ? "Edit Slide" : "Tambah Slide"}`}
        width="982px"
        children={
          <AddSlide
            dispatch={dispatch}
            setOpenPopup={setOpenPopup}
            edit={openPopup?.edit}
          />
        }
      />
      <div className="bg-white soft-shadow rounded-[20px]">
        <div className="flex flex-row justify-between p-4">
          <div className="text-[20px] font-semibold">Slide</div>
          <Buttons
            text="Tambah"
            customClass="btn-primary btn-small"
            onClick={() =>
              setOpenPopup({
                show: true,
                edit: null,
              })
            }
          />
        </div>
        <div className="divider"></div>
        <div className="p-4">
          <Tab
            defaultActiveKey="1"
            items={[
              {
                label: "Web",
                key: "1",
                children: (
                  <SlideWeb
                    store={store}
                    dispatch={dispatch}
                    setOpenPopup={setOpenPopup}
                  />
                ),
              },
              {
                label: "Mobile",
                key: "2",
                children: "2",
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};

export default React.memo(AppsSlide);
