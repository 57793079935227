// login Reducers
// --------------------------------------------------------

import {
  SET_LOADING,
  CLEAR_ERROR,
  SET_ERROR,
  INIT_DATA,
  SET_DOUBLE_SUBMIT,
  SET_AUTHENTICATED,
  SET_USERLOGIN,
  SET_VALIDATED_EMAIL,
  SET_EMAIL_FORGOT_PASSWORD,
  SET_VERIFICATION_OTP,
  SET_SUBMIT_CHANGE_PASSWORD,
} from "../../actions/login";

const initialState = {
  isLoading: false,
  isDoubleSubmit: false,
  isSubmitting: false,
  isAuthenticated: false,
  emailValidated: false,
  dataForgotPassword: false,
  dataVerificationOTP: false,
  isChangePasswordSuccess: false,
  userLogin: {},
  error: false,
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case SET_DOUBLE_SUBMIT:
      return {
        ...state,
        isDoubleSubmit: payload,
      };
    case SET_ERROR:
      return {
        ...state,
        error: payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: false,
      };
    case INIT_DATA:
      return {
        ...initialState,
      };
    case SET_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: payload,
      };
    case SET_VALIDATED_EMAIL:
      return {
        ...state,
        emailValidated: payload,
      };
    case SET_EMAIL_FORGOT_PASSWORD:
      return {
        ...state,
        dataForgotPassword: payload,
      };
    case SET_VERIFICATION_OTP:
      return {
        ...state,
        dataVerificationOTP: payload,
      };
    case SET_SUBMIT_CHANGE_PASSWORD:
      return {
        ...state,
        isChangePasswordSuccess: payload,
      };
    case SET_USERLOGIN:
      return {
        ...state,
        userLogin: payload,
      };
    default:
      return state;
  }
};
