import { useState } from "react";

import Buttons from "components/buttons";
import Popup from "components/popup";

import AvatarMale from "assets/icons/male.svg";
import AvatarFemale from "assets/icons/female.svg";
import SeatIkhwan from "assets/icons/colored/seat-ikhwan.svg";
import SeatAkhwat from "assets/icons/colored/seat-akhwat.svg";
import SeatBlank from "assets/icons/seat-blank.svg";
import Switch from "components/form/switch";

const Penerbangan = () => {
  const [openPopup, setOpenPopup] = useState(false);

  const JamaahCard = () => {
    return (
      <div className="shadow-[0px_0px_4px_rgba(0,0,0,0.04),0px_4px_8px_rgba(0,0,0,0.06)] rounded-xl p-3">
        <div className="p-5 border rounded-lg flex gap-8">
          <div className="flex gap-6 w-full">
            <img
              src={AvatarMale}
              alt="avatar"
              className="w-14 h-14 self-center"
            />
            <div className="self-center">
              <h5 className="font-semibold">Dimas Putra Andrean</h5>
              <div className="flex flex-row items-center gap-3 mt-3">
                <div className="bg-red-100 text-red-600 text-xs text-uppercase font-semibold rounded-lg px-2 py-1">
                  YOGYAKARTA
                </div>
                <div className="bg-[#F2F2F2] w-[6px] h-[6px] rounded-full"></div>
                <div className="bg-red-100 text-red-600 text-xs text-uppercase font-semibold rounded-lg px-2 py-1">
                  YIA - CGK
                </div>
              </div>
            </div>
          </div>
          <div className="w-full">
            <small className="text-xs">Suami dari</small>
            <div className="flex gap-2 mt-1">
              <img
                src={AvatarFemale}
                alt="avatar"
                width={16}
                height={16}
                className="self-center"
              />
              <span className="font-semibold text-xs pt-1">Fulanah</span>
            </div>
            <div className="bg-purple-100 text-purple-600 text-xs text-uppercase font-semibold rounded-lg px-2 py-1 mt-2 w-fit">
              MEDAN
            </div>
          </div>
          <div className="w-1/2 flex justify-end">
            <div className="self-center">
              <img
                src={SeatIkhwan}
                alt="avatar"
                className="w-12 h-12 mx-auto mb-1"
              />
              <div className="px-2 py-1 border border-blue-500 text-blue-500 text-xs rounded font-semibold">
                Business Class
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end mt-3">
          <Buttons
            text="Tukar Seat"
            customClass="btn-primary btn-small !w-fit"
            onClick={() => setOpenPopup(true)}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      <Popup
        open={openPopup}
        handleClose={() => setOpenPopup(false)}
        title="Pilih Seat"
        width="800px"
      >
        <div className="flex flex-row items-center gap-4">
          <div className="flex flex-row gap-4 bg-[#FF9C9A] rounded-xl px-4 py-5 w-full">
            <img src={AvatarMale} alt="avatar" />
            <div className="flex flex-col gap-2">
              <div className="text-[#4F4F4F]">Tuan</div>
              <div className="font-semibold text-[16px]">
                Dimas Andreyan Pradana Putra
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-4 bg-white border border-[#E0E0E0] rounded-xl px-4 py-5 w-full">
            <img src={AvatarMale} alt="avatar" />
            <div className="flex flex-col gap-2">
              <div className="text-[#4F4F4F]">Nyonya</div>
              <div className="font-semibold text-[16px]">
                Aisyah Nabilah Andreyan
              </div>
            </div>
          </div>
        </div>
        <div className="border border-[#E0E0E0] rounded-2xl mt-4">
          <div className="flex flex-row justify-center items-center">
            <div className="bg-white px-8 overflow-hidden">
              <div className="bg-white w-full p-10 rounded-t-[30%] h-full shadow">
                <div className="py-5 flex flex-col items-center">
                  <div className="px-2 py-1 border border-blue-500 text-blue-500 text-sm rounded font-semibold w-fit">
                    Business Class
                  </div>
                  <div className="grid grid-cols-3 gap-12">
                    <div className="grid grid-cols-2 gap-5 mt-12">
                      <img
                        src={SeatIkhwan}
                        alt="avatar"
                        className="w-10 h-10 mx-auto mb-1"
                      />
                      <img
                        src={SeatAkhwat}
                        alt="avatar"
                        className="w-10 h-10 mx-auto mb-1"
                      />
                      <img
                        src={SeatIkhwan}
                        alt="avatar"
                        className="w-10 h-10 mx-auto mb-1"
                      />
                      <img
                        src={SeatBlank}
                        alt="avatar"
                        className="w-10 h-10 mx-auto mb-1"
                      />
                    </div>
                    <div className="grid grid-cols-2 gap-6 mt-12">
                      <img
                        src={SeatAkhwat}
                        alt="avatar"
                        className="w-10 h-10 mx-auto mb-1"
                      />
                      <img
                        src={SeatAkhwat}
                        alt="avatar"
                        className="w-10 h-10 mx-auto mb-1 opacity-50"
                      />
                      <img
                        src={SeatAkhwat}
                        alt="avatar"
                        className="w-10 h-10 mx-auto mb-1"
                      />
                      <img
                        src={SeatAkhwat}
                        alt="avatar"
                        className="w-10 h-10 mx-auto mb-1"
                      />
                    </div>
                    <div className="grid grid-cols-2 gap-6 mt-12">
                      <img
                        src={SeatIkhwan}
                        alt="avatar"
                        className="w-10 h-10 mx-auto mb-1"
                      />
                      <img
                        src={SeatIkhwan}
                        alt="avatar"
                        className="w-10 h-10 mx-auto mb-1"
                      />
                      <img
                        src={SeatIkhwan}
                        alt="avatar"
                        className="w-10 h-10 mx-auto mb-1"
                      />
                      <img
                        src={SeatIkhwan}
                        alt="avatar"
                        className="w-10 h-10 mx-auto mb-1"
                      />
                    </div>
                  </div>
                </div>
                <div className="py-5 mt-3 flex flex-col items-center">
                  <div className="px-2 py-1 border border-gray-500 text-gray-500 text-sm rounded font-semibold w-fit">
                    Economy Class
                  </div>
                  <div className="grid grid-cols-3 gap-12">
                    <div className="grid grid-cols-3 gap-5 mt-10">
                      <img
                        src={SeatIkhwan}
                        alt="avatar"
                        className="w-10 h-10 mx-auto mb-1"
                      />
                      <img
                        src={SeatIkhwan}
                        alt="avatar"
                        className="w-10 h-10 mx-auto mb-1"
                      />
                      <img
                        src={SeatIkhwan}
                        alt="avatar"
                        className="w-10 h-10 mx-auto mb-1"
                      />
                      <img
                        src={SeatIkhwan}
                        alt="avatar"
                        className="w-10 h-10 mx-auto mb-1"
                      />
                      <img
                        src={SeatIkhwan}
                        alt="avatar"
                        className="w-10 h-10 mx-auto mb-1 opacity-50"
                      />
                      <img
                        src={SeatIkhwan}
                        alt="avatar"
                        className="w-10 h-10 mx-auto mb-1"
                      />
                    </div>
                    <div className="grid grid-cols-3 gap-5 mt-10">
                      <img
                        src={SeatIkhwan}
                        alt="avatar"
                        className="w-10 h-10 mx-auto mb-1"
                      />
                      <img
                        src={SeatIkhwan}
                        alt="avatar"
                        className="w-10 h-10 mx-auto mb-1"
                      />
                      <img
                        src={SeatIkhwan}
                        alt="avatar"
                        className="w-10 h-10 mx-auto mb-1 opacity-50"
                      />
                      <img
                        src={SeatIkhwan}
                        alt="avatar"
                        className="w-10 h-10 mx-auto mb-1"
                      />
                      <img
                        src={SeatIkhwan}
                        alt="avatar"
                        className="w-10 h-10 mx-auto mb-1"
                      />
                      <img
                        src={SeatIkhwan}
                        alt="avatar"
                        className="w-10 h-10 mx-auto mb-1"
                      />
                    </div>
                    <div className="grid grid-cols-3 gap-5 mt-10">
                      <img
                        src={SeatIkhwan}
                        alt="avatar"
                        className="w-10 h-10 mx-auto mb-1"
                      />
                      <img
                        src={SeatIkhwan}
                        alt="avatar"
                        className="w-10 h-10 mx-auto mb-1"
                      />
                      <img
                        src={SeatIkhwan}
                        alt="avatar"
                        className="w-10 h-10 mx-auto mb-1"
                      />
                      <img
                        src={SeatIkhwan}
                        alt="avatar"
                        className="w-10 h-10 mx-auto mb-1"
                      />
                      <img
                        src={SeatIkhwan}
                        alt="avatar"
                        className="w-10 h-10 mx-auto mb-1"
                      />
                      <img
                        src={SeatIkhwan}
                        alt="avatar"
                        className="w-10 h-10 mx-auto mb-1"
                      />
                    </div>
                  </div>
                </div>
                <div className="flex justify-between w-3/4 border rounded-lg mx-auto mt-10 p-4 gap-5 text-sm">
                  <div className="flex gap-2">
                    <div className="h-6 w-6 rounded-md bg-[#3020E8]"></div>
                    <span>Seat Ikhwan</span>
                  </div>
                  <div className="flex gap-2">
                    <div className="h-6 w-6 rounded-md bg-[#E82068]"></div>
                    <span>Seat Akhwat</span>
                  </div>
                  <div className="flex gap-2">
                    <div className="h-6 w-6 rounded-md bg-[#CECECE]"></div>
                    <span>Seat Kosong</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="divider"></div>
          <div className="flex flex-row items-center justify-between p-4">
            <Switch label="Tukar Seat" />
            <Buttons text="Simpan" customClass="btn-primary btn-medium" />
          </div>
        </div>
      </Popup>
      <div className="border-t grid grid-cols-2 gap-5">
        <div className="py-6">
          <div className="border bg-red-50 border-red-700 text-red-600 p-5 rounded-lg text-sm">
            Keterangan: Seat ini tidak menunjukan posisi tempat duduk di
            lapangan. Hanya Menunjukan jamaah duduk bersama siapa
          </div>
          <div className="flex flex-row justify-between items-center soft-shadow px-4 py-3 rounded-xl mt-4">
            <div className="flex flex-col gap-2">
              <div className="text-[#4F4F4F]">Jamaah belum memilih seat</div>
              <div className="font-semibold">2</div>
            </div>
            <Buttons text="Atur Seat" customClass="btn-outline btn-small" />
          </div>
          <h2 className="font-semibold mt-6 mb-5">Hasil Pencarian</h2>
          <div className="flex flex-col gap-4">
            <JamaahCard />
            <JamaahCard />
          </div>
        </div>
        <div className="flex justify-center bg-gray-200 px-8 overflow-hidden">
          <div className="bg-white w-[450px] p-10 rounded-b-[24px] h-full shadow">
            <div className="py-5 flex flex-col items-center">
              <div className="grid grid-cols-2 gap-12">
                <div className="grid grid-cols-2 gap-5 mt-12">
                  <img
                    src={SeatIkhwan}
                    alt="avatar"
                    className="w-10 h-10 mx-auto mb-1"
                  />
                  <img
                    src={SeatIkhwan}
                    alt="avatar"
                    className="w-10 h-10 mx-auto mb-1"
                  />
                  <img
                    src={SeatIkhwan}
                    alt="avatar"
                    className="w-10 h-10 mx-auto mb-1"
                  />
                  <img
                    src={SeatIkhwan}
                    alt="avatar"
                    className="w-10 h-10 mx-auto mb-1"
                  />
                  <img
                    src={SeatIkhwan}
                    alt="avatar"
                    className="w-10 h-10 mx-auto mb-1"
                  />
                  <img
                    src={SeatIkhwan}
                    alt="avatar"
                    className="w-10 h-10 mx-auto mb-1"
                  />
                  <img
                    src={SeatIkhwan}
                    alt="avatar"
                    className="w-10 h-10 mx-auto mb-1"
                  />
                  <img
                    src={SeatIkhwan}
                    alt="avatar"
                    className="w-10 h-10 mx-auto mb-1"
                  />
                  <img
                    src={SeatIkhwan}
                    alt="avatar"
                    className="w-10 h-10 mx-auto mb-1"
                  />
                  <img
                    src={SeatIkhwan}
                    alt="avatar"
                    className="w-10 h-10 mx-auto mb-1"
                  />
                </div>
                <div className="grid grid-cols-2 gap-6 mt-12">
                  <img
                    src={SeatAkhwat}
                    alt="avatar"
                    className="w-10 h-10 mx-auto mb-1"
                  />
                  <img
                    src={SeatAkhwat}
                    alt="avatar"
                    className="w-10 h-10 mx-auto mb-1 opacity-50"
                  />
                  <img
                    src={SeatAkhwat}
                    alt="avatar"
                    className="w-10 h-10 mx-auto mb-1"
                  />
                  <img
                    src={SeatAkhwat}
                    alt="avatar"
                    className="w-10 h-10 mx-auto mb-1"
                  />
                  <img
                    src={SeatAkhwat}
                    alt="avatar"
                    className="w-10 h-10 mx-auto mb-1"
                  />
                  <img
                    src={SeatAkhwat}
                    alt="avatar"
                    className="w-10 h-10 mx-auto mb-1"
                  />
                  <img
                    src={SeatAkhwat}
                    alt="avatar"
                    className="w-10 h-10 mx-auto mb-1"
                  />
                  <img
                    src={SeatAkhwat}
                    alt="avatar"
                    className="w-10 h-10 mx-auto mb-1"
                  />
                  <img
                    src={SeatAkhwat}
                    alt="avatar"
                    className="w-10 h-10 mx-auto mb-1"
                  />
                  <img
                    src={SeatAkhwat}
                    alt="avatar"
                    className="w-10 h-10 mx-auto mb-1"
                  />
                </div>
              </div>
            </div>

            <div className="flex justify-between border rounded-lg w-full mt-10 p-4 gap-5 text-sm">
              <div className="flex gap-2">
                <div className="h-6 w-6 rounded-md bg-[#3020E8]"></div>
                <span>Seat Ikhwan</span>
              </div>
              <div className="flex gap-2">
                <div className="h-6 w-6 rounded-md bg-[#E82068]"></div>
                <span>Seat Akhwat</span>
              </div>
              <div className="flex gap-2">
                <div className="h-6 w-6 rounded-md bg-[#CECECE]"></div>
                <span>Seat Kosong</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Penerbangan;
