import axios from "axios";
import { BASE_URL_ENDPOINT } from "../../../constant/constanta";

const AdminMemberAPI = {
  getListPage: (payload) =>
    axios.post(`${BASE_URL_ENDPOINT}/admin/get-admin`, payload),
  postData: (payload) =>
    axios.post(`${BASE_URL_ENDPOINT}/admin/save-admin`, payload),
  deleteData: (id) =>
    axios.delete(`${BASE_URL_ENDPOINT}/admin/data-admin/${id}`),
  getDetail: (id) => axios.get(`${BASE_URL_ENDPOINT}/admin/data-admin/${id}`),
  updateData: (payload, id) =>
    axios.put(`${BASE_URL_ENDPOINT}/admin/${id}/update-admin`, payload),
};

const AgenMemberAPI = {
  getListPage: (payload) =>
    axios.post(`${BASE_URL_ENDPOINT}/admin/get-agen`, payload),
  getDetail: (id) => axios.get(`${BASE_URL_ENDPOINT}/admin/agen/${id}`),
  postData: (payload) =>
    axios.post(`${BASE_URL_ENDPOINT}/admin/save-agen`, payload),
  deleteData: (id) => axios.delete(`${BASE_URL_ENDPOINT}/admin/agen/${id}`),
};

const JamaahMemberAPI = {
  getListPage: (payload) =>
    axios.post(`${BASE_URL_ENDPOINT}/admin/get-jamaah`, payload),
  getDetail: (id) => axios.get(`${BASE_URL_ENDPOINT}/admin/jamaah/${id}`),
  postData: (payload) =>
    axios.post(`${BASE_URL_ENDPOINT}/admin/save-jamaah`, payload),
  deleteData: (id) => axios.delete(`${BASE_URL_ENDPOINT}/admin/jamaah/${id}`),
};

export { AdminMemberAPI, AgenMemberAPI, JamaahMemberAPI };
