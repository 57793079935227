// uploadFile Actions
// --------------------------------------------------------

import { uploadService } from "../../../utils/api/upload";
import { handleError, handleErrorBE } from "../errorGeneral";

/* eslint-disable space-before-function-paren */
export const SET_LOADING = "uploadFile/SET_LOADING";
export const CLEAR_ERROR = "uploadFile/CLEAR_ERROR";
export const SET_ERROR = "uploadFile/SET_ERROR";
export const INIT_DATA = "uploadFile/INIT_DATA";
export const SET_UPLOAD = "uploadFile/SET_UPLOAD ";
export const SET_UPLOAD_MULTIPLE = "uploadFile/SET_UPLOAD_MULTIPLE ";
export const SET_DOUBLE_SUBMIT = "uploadFile/SET_DOUBLE_SUBMIT";
export const SET_CLEAR = "uploadFile/SET_CLEAR";
export const SET_UPLOAD_IMAGE = "uploadFile/SET_UPLOAD_IMAGE";

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const setDoubleSubmit = (payload) => ({
  type: SET_DOUBLE_SUBMIT,
  payload,
});

export const setDataUpload = (payload) => ({
  type: SET_UPLOAD,
  payload,
});

export const setDataUploadMulti = (payload) => ({
  type: SET_UPLOAD_MULTIPLE,
  payload,
});

export const clearData = () => ({
  type: SET_CLEAR,
});

export const setErrorUpload = (value, fileName) => ({
  type: SET_ERROR,
  payload: { fileName, value },
});

export const setUploadImage = (payload) => ({
  type: SET_UPLOAD_IMAGE,
  payload,
});

export const uploadFileSubmite =
  (payload, name, id, type) => async (dispatch, getState) => {
    dispatch(setLoading(true));
    try {
      const storeDataUpload = getState().uploadFile.dataUpload;
      const storeDataUploadMulti = getState().uploadFile.dataMultiple;
      let formData = new FormData();
      if (type === "multiple") {
        formData.append("images", payload[0][0]);
      } else {
        formData.append("images", payload[0]);
      }
      formData.append("directory", "admin");

      const res = await uploadService(formData);

      dispatch(setLoading(false));
      if (res.status === 200) {
        let temporary = [...storeDataUpload];
        let temporary_multi = [...storeDataUploadMulti];

        if (type === "multiple") {
          temporary_multi.push({
            id: id,
            fileName: payload[0][0].name,
            path: res.data.data.link,
            name: name,
            type: "image",
          });
        } else {
          temporary.push({
            id: id,
            fileName: payload[0].name,
            path: res.data.data.link,
            name: name,
            type: "image",
          });
        }

        dispatch(setDataUpload(temporary));
        dispatch(setDataUploadMulti(temporary_multi));
      } else {
        dispatch(handleErrorBE(res));
      }
    } catch (error) {
      dispatch(setErrorUpload(error.message || "Upload image error"));
      dispatch(handleError(error));
    }
  };

export const actUploadImage = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await uploadService(payload);
    dispatch(setLoading(false));
    if (res.status === 200) {
      dispatch(setUploadImage(res.data));
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const resetDataUpload = () => {
  return async (dispatch) => {
    dispatch(clearData());
  };
};
