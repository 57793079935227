import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppsStaffAction from "stores/actions/apps/staff";

import Badge from "components/badge";
import Buttons from "components/buttons";
import MoslemeTable from "components/Table";
import Popup from "components/popup";
import AlertPopup from "components/popup/alert";
import AppsAddStaff from "./components/AddStaff";

import { Edit, Trash } from "iconsax-react";
import Selects from "components/form/selects";
import { getAllCabang } from "stores/actions/cabang";

const AppsStaff = () => {
  const dispatch = useDispatch();
  const store = useSelector(({ staff }) => staff);
  const storeCabang = useSelector(({ cabang }) => cabang);
  const [listStaff, setListStaff] = useState([]);
  const [openPopup, setOpenPopup] = useState({
    show: false,
    edit: null,
  });
  const [staffIdDelete, setStaffIdDelete] = useState();
  const [openDeleteStaff, setOpenDeleteStaff] = useState(false);
  const [selectedCabang, setSelectedCabang] = useState(0);
  const [loading, setLoading] = useState(false);
  // Selection & type state
  const [selectionType, setSelectionType] = useState("checkbox");
  const [withSelection, setWithSelection] = useState(true);

  const [options, setOptions] = useState([]);

  const columns = [
    {
      title: "Foto",
      dataIndex: "photo",
      render: (img) => (
        <img className="h-[32px] w-[32px]" src={img} alt="img" />
      ),
    },
    {
      title: "Nama",
      dataIndex: "name",
    },
    {
      title: "Jabatan",
      dataIndex: "position",
      render: (type) => <Badge label={type} />,
    },
    {
      title: "Cabang",
      dataIndex: "branch",
      renderender: (branch) => <Badge label={branch} />,
    },
    {
      title: "Deskripsi",
      dataIndex: "description",
      render: ({ id, deskripsi, data }) => (
        <>
          <div>{deskripsi}</div>
          <div className="action">
            <Edit
              className="cursor-pointer"
              onClick={() => {
                setOpenPopup({
                  show: true,
                  edit: data,
                });
              }}
              size="15"
              color="#141414"
            />
            <Trash
              className="cursor-pointer"
              onClick={() => {
                setOpenDeleteStaff(true);
                setStaffIdDelete(id);
              }}
              size="15"
              color="#141414"
            />
          </div>
        </>
      ),
    },
  ];

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: store.total,
    current: store.page_number,
    pageSize: store.per_page,
    defaultPageSize: store.per_page,
    pageSizeOptions: ["10", "20"],
  };

  useEffect(() => {
    dispatch(getAllCabang());
  }, []);

  useEffect(() => {
    let list = [];
    storeCabang?.dataAllCabang?.data?.map((val, idx) => {
      let value = {
        value: val.name,
        label: val.name,
        id: val.id_cabang,
      };
      return list.push(value);
    });
    setOptions(list);
  }, [storeCabang, storeCabang.length]);

  useEffect(() => {
    setListStaff(store.data);
  }, [store.data, store.data.length]);

  useEffect(() => {
    dispatch(
      AppsStaffAction.fetchData({
        page_number: store.page_number,
        per_page: store.per_page,
        keyword: store.keyword,
        sort_column: "",
        sort_order: "DESC",
        cabang_id: selectedCabang,
      })
    );
  }, [dispatch, store.keyword, store.per_page, store.page_number]);

  useEffect(() => {
    dispatch(
      AppsStaffAction.fetchData({
        page_number: store.page_number,
        per_page: store.per_page,
        keyword: store.keyword,
        sort_column: "",
        sort_order: "DESC",
        cabang_id: selectedCabang,
      })
    );
  }, [selectedCabang]);

  const confirmDeleteStaff = async () => {
    setLoading(true);
    const status = await dispatch(AppsStaffAction.deleteData(staffIdDelete));

    if (status === 200) {
      setListStaff(store.data);
      setOpenDeleteStaff(false);
      setLoading(false);
    }
  };

  return (
    <>
      <Popup
        open={openPopup.show}
        handleClose={() =>
          setOpenPopup({
            show: false,
            edit: null,
          })
        }
        title={`${openPopup.edit ? "Edit Staff" : "Tambah Staff"}`}
        width="982px"
        children={
          <AppsAddStaff
            setOpenPopup={setOpenPopup}
            edit={openPopup.edit}
            cabang={options}
          />
        }
      />

      <AlertPopup
        open={openDeleteStaff}
        subtitle="Apakah anda yakin untuk menghapus staff?"
        handleClose={() => setOpenDeleteStaff(false)}
        handleContinue={confirmDeleteStaff}
        loading={loading}
      />

      <div className="bg-white soft-shadow rounded-[20px]">
        <div className="flex flex-row justify-between p-4">
          <div className="text-[20px] font-semibold">Data Staff</div>
          <div className="flex flex-row gap-4">
            <Buttons
              text="Tambah"
              customClass="btn-primary btn-small"
              onClick={() =>
                setOpenPopup({
                  show: true,
                  edit: null,
                })
              }
            />
          </div>
        </div>
        <div className="divider"></div>
        <div className="p-4">
          <Selects
            id="cabang"
            label="Cabang"
            placeholder="Semua Cabang"
            containerClass="!w-[15%]"
            options={options}
            itemRenderer={({ state, item, methods }) => {
              return (
                <div
                  className={`react-dropdown-select-item ${
                    state.values.length !== 0 &&
                    state.values[0].value === item.value &&
                    "react-dropdown-select-item-selected"
                  }`}
                  onClick={() => {
                    methods.addItem(item);
                    setSelectedCabang(item.id);
                  }}
                >
                  {item.label}
                </div>
              );
            }}
          />
          <MoslemeTable
            customClass="mt-4"
            loading={store.status === "process"}
            columns={columns}
            data={listStaff.map((item, i) => {
              return {
                ...item,
                key: `${i + 1}`,
                id: item.id,
                photo: item.image,
                name: item.nama,
                position: item.jabatan,
                branch: item.cabang_name,
                description: {
                  id: item.id,
                  deskripsi: item.deskripsi,
                  data: item,
                },
              };
            })}
            withSelection={withSelection}
            selectionType={selectionType}
            pagination={pagination}
            onPageChange={(page) => dispatch(AppsStaffAction.setPage(page))}
            onSizeChange={(currentpage, pageSize) => {
              dispatch(AppsStaffAction.setPage(currentpage));
              dispatch(AppsStaffAction.setSize(pageSize));
            }}
          />
        </div>
      </div>
    </>
  );
};

export default React.memo(AppsStaff);
