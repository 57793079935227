// role Reducers
// --------------------------------------------------------

import {
  SET_LOADING,
  SET_LOADING_DATA,
  CLEAR_ERROR,
  SET_ERROR,
  INIT_DATA,
  SET_DOUBLE_SUBMIT,
  SET_DATA_ROLE,
  SET_DATA_ROLEV2,
  SET_DATA_ROLEV2_UTAMA,
  SET_DATA_PERMISSION,
  SET_DATA_DETAIL_ROLEV2,
  SET_DATA_ROLE_PERMISSION,
} from "../../actions/role";

const initialState = {
  isLoading: false,
  isLoadingData: false,
  isDoubleSubmit: false,
  isSubmitting: false,
  dataRole: {},
  error: {},
  dataRoleV2: {},
  dataRoleV2Utama: {},
  dataPermission: {},
  dataDetail: {},
  dataRolePermision: {},
};

export default (state = initialState, { payload, type }) => {
  switch (type) {
    case SET_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
      case SET_LOADING_DATA:
        return {
          ...state,
          isLoadingData: payload,
        };
    case SET_DOUBLE_SUBMIT:
      return {
        ...state,
        isDoubleSubmit: payload,
      };
    case SET_DATA_ROLE:
      return {
        ...state,
        dataRole: payload,
      };
    case SET_ERROR:
      return {
        ...state,
        error: payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: {},
      };
    case INIT_DATA:
      return {
        ...initialState,
      };
    case SET_DATA_ROLEV2:
      return {
        ...state,
        dataRoleV2: payload,
      };

    case SET_DATA_ROLEV2_UTAMA:
      return {
        ...state,
        dataRoleV2Utama: payload,
      };
    case SET_DATA_PERMISSION:
      return {
        ...state,
        dataPermission: payload,
      };
    case SET_DATA_DETAIL_ROLEV2:
      return {
        ...state,
        dataDetail: payload,
      };
    case SET_DATA_ROLE_PERMISSION:
      return {
        ...state,
        dataRolePermision: payload,
      };
    default:
      return state;
  }
};
