import axios from "axios";
import { BASE_URL_ENDPOINT } from "../../../../constant/constanta";

export const getAdditionalByPaket = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/paket/get-additional`, payload);

export const saveAdditional = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/paket/save-additional`, payload);

export const deleteAdditional = (id) =>
  axios.delete(`${BASE_URL_ENDPOINT}/admin/paket/additional/${id}`);

export const getAdditional = () =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/get-additional`, {
    keyword: "",
    sort_column: "",
    sort_order: "DESC",
  });
