import axios from "axios";
import { BASE_URL_ENDPOINT } from "constant/constanta";

export const getAdditionalApi = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/get-additional`, payload);

export const getIdAdditionalApi = (id) =>
  axios.get(`${BASE_URL_ENDPOINT}/admin/additional/${id}`);

export const deleteIdAdditionalApi = (id) =>
  axios.delete(`${BASE_URL_ENDPOINT}/admin/additional/${id}`);

export const createOrUpdateAdditionalApi = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/save-additional`, payload);

export const duplicateAdditionalApi = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/additional/duplicate`, payload);
