import React, { useState, useEffect } from "react";
import { ReactComponent as Location } from "../../../../../assets/icons/location.svg";
import PopupBandara from "../../popupdatamaster";
import Places from "../../../../../components/map";
import { ReactComponent as LocationBlack } from "../../../../../assets/icons/locationblack.svg";
import Selects from "../../../../../components/form/selects";
import TextAreas from "../../../../../components/form/textarea";
import { useSelector, useDispatch } from "react-redux";
import { getAllRegion } from "stores/actions/datamaster";
import "./index.css";

const style = {
  width: "auto",
  height: "318px",
  borderRadius: "40px",
};

function AlamatAkomodasiComponent({
  formikValue,
  formikOnChange,
  formikAddressDetails,
  setCity,
  setAddressDetail,
}) {
  const [isLocation, setIsLocation] = useState(false);
  const [selected, setSelected] = useState({
    lat: -7.7955798,
    lng: 110.3694896,
  });
  const [address, setAdress] = useState("Sleman");

  const dispatch = useDispatch();
  const { dataRegion } = useSelector((state) => state.datamaster);

  useEffect(() => {
    if (address) {
      dispatch(
        getAllRegion({
          keyword: address?.split(",")[0],
        })
      );
    }
  }, [address]);
  const handleSetAdress = (data) => {
    setAdress(data);
    formikValue("address_lat_long", data);
  };

  const handleSelected = (data) => {
    setSelected(data);
    formikValue("latitude", data.lat);
    formikValue("longitude", data.lng);
  };

  useEffect(() => {
    if (address) {
      dispatch(
        getAllRegion({
          keyword: address.split(",")[0],
        })
      );
    }
  }, [address]);

  return (
    <>
      <div className="flex w-full">
        <div className="w-1/2 mr-6">
          <Places
            style={style}
            selected={selected}
            setSelected={handleSelected}
            address={address}
            setAdress={handleSetAdress}
          />
        </div>
        <div className="w-1/2">
          <p className="p-4 bg-[#DDD3AE66] text-red-700 text-lg rounded-md border border-red-700">
            Pastikan Koordinat yang dipilih tidak berbeda jauh dengan alamat
            yang akan diisi di Alamat Lengkap
          </p>
          <div className="mt-8">
            <label className="text-lg font-semibold">Koordinat Lokasi</label>
            <div className="flex my-4 items-center ">
              <LocationBlack /> <span className="text-lg ml-4">{address}</span>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="w-full cursor-pointer rounded-lg h-16 border-dashed border-2 mt-6 border-gray-200 flex items-center justify-center">
        <Location />
        <span className="ml-2 text-red-700">{address} </span>
      </div>
      <div className="mt-4">
        <Selects
          searchable
          label="Pilih Kota"
          placeholder="Pilih Kota"
          iconPosition="left"
          options={dataRegion}
          onChange={(value) => value.map((data) => setCity(data.value))}
        />
      </div> */}
      <div className="w-full mt-4">
        <TextAreas
          id="address_details"
          name="address_details"
          // value={formikAddressDetails}
          placeholder="Cth. Jalan, No. Rumah, Blok"
          label="Alamat Lengkap"
          onChange={(e) => {
            setAddressDetail(e.target.value);
          }}
        />
      </div>
    </>
  );
}

export default AlamatAkomodasiComponent;
