import React from "react";
import fotoProfil from "assets/images/Avatar-ikhwan-1.svg";
import fotoProfilAkhwat from "assets/images/foto-profil-ibadahku.svg";
import Switch from "components/form/switch";
import IconLocation from "assets/icons/colored/icon-location-maroon.svg";
import IconWhatsapp from "assets/icons/colored/icon-whatsapp.svg";
import Badge from "components/badge";

const AlbumData = [
  {
    name: "Dimas Andreyan Prana Putra",
    location:
      "Jl. Monjali, No. XX, Kecamatan Mlati, Kabupaten Sleman, Daerah Istimewa Yogyakarta",
    phone: "0878 5354 3388",
    badgeLabel: "Yogyakarta",
    badgeColor: "#FE0000",
    badgeBackgroundColor: "#F7A399",
    fotoProfilImg: fotoProfil,
  },
  {
    name: "Abdul Wahid Ngatono",
    location:
      "Jl. Monjali, No. XX, Kecamatan Mlati, Kabupaten Sleman, Daerah Istimewa Yogyakarta",
    phone: "0878 5354 3388",
    badgeLabel: "Palembang",
    badgeColor: "#718355",
    badgeBackgroundColor: "#E9F5DB",
    fotoProfilImg: fotoProfil,
  },
  {
    name: "Agus Nugroho",
    location:
      "Jl. Monjali, No. XX, Kecamatan Mlati, Kabupaten Sleman, Daerah Istimewa Yogyakarta",
    phone: "0878 5354 3388",
    badgeLabel: "Surabaya",
    badgeColor: "#FFD400",
    badgeBackgroundColor: "#FFF2B2",
    fotoProfilImg: fotoProfil,
  },
  {
    name: "Aisyah Nabila Andreyan",
    location:
      "Jl. Monjali, No. XX, Kecamatan Mlati, Kabupaten Sleman, Daerah Istimewa Yogyakarta",
    phone: "0878 5354 3388",
    badgeLabel: "Lubuklinggau",
    badgeColor: "#F20089",
    badgeBackgroundColor: "#FEDCED",
    fotoProfilImg: fotoProfilAkhwat,
  },
  {
    name: "Dimas Andreyan Prana Putra",
    location:
      "Jl. Monjali, No. XX, Kecamatan Mlati, Kabupaten Sleman, Daerah Istimewa Yogyakarta",
    phone: "0878 5354 3388",
    badgeLabel: "Yogyakarta",
    badgeColor: "#FE0000",
    badgeBackgroundColor: "#F7A399",
    fotoProfilImg: fotoProfil,
  },
  {
    name: "Dimas Andreyan Prana Putra",
    location:
      "Jl. Monjali, No. XX, Kecamatan Mlati, Kabupaten Sleman, Daerah Istimewa Yogyakarta",
    phone: "0878 5354 3388",
    badgeLabel: "Yogyakarta",
    badgeColor: "#FE0000",
    badgeBackgroundColor: "#F7A399",
    fotoProfilImg: fotoProfil,
  },
];

const AlbumDataCard = ({
  name,
  location,
  phone,
  badgeLabel,
  badgeColor,
  badgeBackgroundColor,
  fotoProfilImg,
}) => {
  return (
    <div className="flex flex-row justify-between items-start p-[16px] gap-[16px] rounded-lg shadow-md">
      <div className="flex flex-row items-center gap-[16px]">
        <div className="flex flex-row justify-center items-center gap-[8px] w-[100px] h-[100px] rounded-lg">
          <img src={fotoProfilImg} alt="foto profil" className="rounded-lg" />
        </div>
        <div className="flex flex-col gap-[8px]">
          <h2 className="text-xs font-normal text-black font-semibold">
            {name}
          </h2>
          <div className="flex flex-row items-center gap-[8px]">
            <img src={IconLocation} alt="lokasi" />
            <p className="">{location}</p>
          </div>
          <div className="flex flex-row items-center gap-[8px]">
            <img src={IconWhatsapp} alt="lokasi" />
            <p className="">{phone}</p>
          </div>
          <div>
            <Badge
              label={badgeLabel}
              color={badgeColor}
              backgroundColor={badgeBackgroundColor}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-row items-center gap-[8px]">
        <Switch />
        <p className="text-xs font-normal text-slate-400">Sembunyikan Foto</p>
      </div>
    </div>
  );
};

const AlbumDataJamaah = () => {
  return (
    <div className="flex flex-col gap-[16px] p-[16px] overflow-auto">
      {AlbumData.map((item) => (
        <AlbumDataCard
          key={item.name}
          name={item.name}
          location={item.location}
          phone={item.phone}
          badgeLabel={item.badgeLabel}
          badgeColor={item.badgeColor}
          badgeBackgroundColor={item.badgeBackgroundColor}
          fotoProfilImg={item.fotoProfilImg}
        />
      ))}
    </div>
  );
};

export default AlbumDataJamaah;
