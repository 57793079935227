import { useState } from "react";
import Selects from ".";
import { Col, Row } from "antd";

import IconBrush from "../../../assets/icons/brush.svg";

const SelectsDocs = () => {
  const [options, setOptions] = useState([
    {
      value: "jack",
      label: "Jack",
    },
    {
      value: "lucy",
      label: "Lucy",
    },
    {
      value: "tom",
      label: "Tom",
    },
  ]);

  return (
    <Row>
      <Col span={12} className="px-3">
        <Selects
          label="Label"
          placeholder="Select Placeholder"
          options={options}
          searchable
          itemRenderer={({ state, item, methods }) => {
            return (
              <div
                className={`react-dropdown-select-item ${
                  state.values.length !== 0 &&
                  state.values[0].value === item.value &&
                  "react-dropdown-select-item-selected"
                }`}
                onClick={() => methods.addItem(item)}
              >
                {item.label}
              </div>
            );
          }}
        />
      </Col>
      <Col span={12} className="px-3">
        <Selects
          label="Label"
          placeholder="Text Input"
          icon={IconBrush}
          iconPosition="left"
          options={options}
        />
      </Col>
      <Col span={12} className="px-3 mt-4">
        <Selects
          label="Label"
          customClass="select-disabled"
          disabled
          placeholder="Text Input"
          iconPosition="left"
          options={options}
        />
      </Col>
      <Col span={12} className="px-3 mt-4">
        <Selects
          label="Label"
          customClass="select-error"
          error="Error Message"
          placeholder="Text Input"
          iconPosition="left"
          options={options}
        />
      </Col>
      <Col span={12} className="px-3 mt-4">
        <Selects
          label="Label"
          customClass="select-success"
          success="Field validation is successfull"
          placeholder="Text Input"
          iconPosition="left"
          options={options}
        />
      </Col>
    </Row>
  );
};

export default SelectsDocs;
