import {
  ERROR_FETCHING_ABOUT,
  SET_EDIT_ABOUT,
  START_FETCHING_ABOUT,
  SUCCESS_FETCHING_ABOUT,
  SET_LOADING,
} from "stores/actions/settings/about/actionTypes";

const statuslist = {
  idle: "idle",
  process: "process",
  success: "success",
  error: "error",
};

const initialState = {
  data: null,
  status: statuslist.idle,
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case START_FETCHING_ABOUT:
      return { ...state, status: statuslist.process };

    case ERROR_FETCHING_ABOUT:
      return { ...state, status: statuslist.error };

    case SUCCESS_FETCHING_ABOUT:
      return {
        ...state,
        data: action.payload.data,
        status: statuslist.success,
      };

    case SET_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_EDIT_ABOUT:
      return {
        ...state,
        data: action.payload.data,
      };
    default:
      return state;
  }
}
