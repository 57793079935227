import React, { useEffect, useState } from "react";
import PropType from "prop-types";
import { useDispatch } from "react-redux";
import ArticleCategoryAction from "stores/actions/apps/artikel/category";

import Buttons from "components/buttons";

import { Edit, Trash } from "iconsax-react";

const AppsKelolaKategori = ({
  setOpenPopupKelolaKategori,
  setOpenPopupAddKategori,
  setSuccessEditOrDeleteArtikel,
  listCategory,
}) => {
  const dispatch = useDispatch();
  const [dataListArticle, setDataListArticle] = useState([]);

  useEffect(() => {
    setDataListArticle(listCategory);
  }, [listCategory]);

  const getContent = () => {
    return dataListArticle?.map((val, idx) => {
      return (
        <div
          key={idx}
          className="bg-white soft-shadow rounded-xl p-4 border border-[#ffffff] hover:border hover:border-[#F90601] relative list-kelola-artikel"
        >
          <div className="text-[16px] font-semibold">{val?.nama_kategori}</div>
          <div className="absolute right-6 top-0 bottom-0 hidden gap-8 list-kelola-artikel-action">
            <Edit
              className="cursor-pointer"
              onClick={() => {
                setOpenPopupKelolaKategori(false);
                setOpenPopupAddKategori({
                  show: true,
                  edit: {
                    id: val.id_kategori_artikel,
                    nama_kategori: val.nama_kategori,
                  },
                });
              }}
            />
            <Trash
              className="cursor-pointer"
              onClick={() => {
                dispatch(
                  ArticleCategoryAction.deleteData(val.id_kategori_artikel)
                );
                const oldList = [...dataListArticle];

                setDataListArticle(
                  oldList.filter(
                    (item) =>
                      item.id_kategori_artikel !== val.id_kategori_artikel
                  )
                );

                return setSuccessEditOrDeleteArtikel(true);
              }}
            />
          </div>
        </div>
      );
    });
  };

  return (
    <div className="bg-white border-[1px] border-[#E0E0E0] rounded-2xl">
      <div className="p-4">
        <div className="flex flex-col gap-4">{getContent()}</div>
      </div>
      <div className="divider"></div>
      <div className="flex flex-row justify-end gap-2 p-4">
        <Buttons
          text="Kembali"
          customClass="btn-outline btn-medium !w-fit"
          onClick={() => setOpenPopupKelolaKategori(false)}
        />
        <Buttons
          text="Tambah Kategori Artikel"
          customClass="btn-primary btn-medium !w-fit"
          onClick={() => {
            setOpenPopupKelolaKategori(false);
            setOpenPopupAddKategori({
              show: true,
              edit: null,
            });
          }}
        />
      </div>
    </div>
  );
};

AppsKelolaKategori.propTypes = {
  setOpenPopupKelolaKategori: PropType.func,
  setOpenPopupAddKategori: PropType.func,
  setSuccessEditOrDeleteArtikel: PropType.func,
  listCategory: PropType.array,
};

AppsKelolaKategori.defaultProps = {
  setOpenPopupKelolaKategori: () => {},
  setOpenPopupAddKategori: () => {},
  setSuccessEditOrDeleteArtikel: () => {},
};

export default React.memo(AppsKelolaKategori);
