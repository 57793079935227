import React, { useState } from "react";
import { Grid } from "@material-ui/core";

import Cards from "components/cards";
import Dropdowns, { closeDropdown } from "components/dropdowns";
import Buttons from "components/buttons";
import Badge from "components/badge";
import MoslemeTable from "components/Table";
import Inputs from "components/form/inputs";
import TextAreas from "components/form/textarea";

import IconDelete from "assets/icons/trash.svg";
import IconChecklisted from "assets/icons/Checklisted.svg";
import IconAddRole from "assets/icons/Add-Role.svg";
import Photo from "assets/images/Photo.png";
import IconEdit from "assets/icons/edit.svg";
import DropdownArrow from "assets/icons/dropdown-arrow.svg";

const AddAdditional = ({
  dropdownItems,
  selectedItem,
  setSelectedItem,
  jamaahItems,
  selectedJamaahItem,
  setSelectedJamaahItem,
}) => {
  const [selectedAdditionalItem, setSelectedAdditionalItem] = useState("");

  const columns = [
    {
      title: "Jumlah",
      dataIndex: "total",
    },
    {
      title: "Catatan",
      dataIndex: "note",
    },
    {
      title: "Harga",
      dataIndex: "price",
    },
  ];

  const data = [
    {
      key: "1",
      total: "1",
      note: "-",
      price: "Rp 500.000",
    },
  ];

  return (
    <>
      <Cards containerClass="additional-header-container">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row items-center">
            <Dropdowns
              dropdownHeader={
                <>
                  <div className="flex flex-row gap-2 items-center font-semibold text-[18px]">
                    <div>
                      {selectedAdditionalItem !== ""
                        ? selectedAdditionalItem
                        : "Tambahan"}
                    </div>
                    <img src={DropdownArrow} alt="arrow" className="w-6 h-6" />
                  </div>
                </>
              }
            >
              <div className="orange-bg-shine !px-4 !py-3 !rounded-none !rounded-tl-2xl !rounded-tr-2xl">
                <div className="text-white font-semibold text-[16px]">
                  Select Additional
                </div>
              </div>
              <div className="bg-white soft-shadow rounded-bl-2xl rounded-br-2xl">
                {jamaahItems.map((val, idx) => {
                  return (
                    <div
                      key={idx}
                      className={`px-4 py-2 text-[#151515] cursor-pointer hover:bg-[#FFDCDC] ${
                        selectedAdditionalItem === val.name && "bg-[#FFDCDC]"
                      }`}
                      onClick={(e) => {
                        setSelectedAdditionalItem(val.name);
                        closeDropdown();
                      }}
                    >
                      <div className="flex flex-row">
                        <div className="text-[16px]">{val.name}</div>
                        {selectedAdditionalItem === val.name && (
                          <img
                            className="ml-2"
                            src={IconChecklisted}
                            alt="checklisted"
                          />
                        )}
                      </div>
                      <div className="txt-orange font-semibold">
                        {val.price}
                      </div>
                    </div>
                  );
                })}
              </div>
            </Dropdowns>
            <div className="px-3 py-1 border-[0.5px] border-[#E0E0E0] rounded-lg ml-3">
              Opsional
            </div>
          </div>
          <img src={IconDelete} alt="trash" />
        </div>
        <div className="additional-header-wrapper mt-4">
          <div className="additional-header-label">Harga :</div>
          <div className="additional-header-price">Rp 2.000.000</div>
        </div>
      </Cards>

      <Cards containerClass="additional-content-container">
        <div className="flex flex-row justify-between">
          <div className="font-semibold text-[20px]">Jamaah</div>
          <Buttons
            text="Tambah Jamaah"
            customClass="btn-outline"
            icon={IconAddRole}
          />
        </div>

        <Cards containerClass="mt-[22px] !p-4">
          <div className="flex flex-row justify-between">
            <div className="flex flex-row">
              <img className="w-[56px] h-[56px]" src={Photo} alt="avatar" />
              <div className="ml-3">
                <div className="font-semibold text-[20px]">
                  Tn. Abdul Wahid Ngatono
                </div>
                <div className="flex gap-2 mt-2">
                  <Badge label="Banjarmasin" />
                  <Badge label="Banjarmasin" />
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-6">
              <img
                className="w-[24px] h-[24px] cursor-pointer"
                src={IconEdit}
                alt="trash"
              />
              <img
                className="w-[24px] h-[24px] cursor-pointer"
                src={IconDelete}
                alt="trash"
              />
            </div>
          </div>
          <MoslemeTable
            bordered
            columns={columns}
            data={data}
            customClass="tbl-jamaah mt-4"
            withSelection={false}
            selectionType=""
            withPagination={false}
            customPaginationClass="pr-4"
          />
        </Cards>

        <Cards containerClass="mt-[22px] !p-4">
          <div className="flex flex-row justify-between">
            <div className="flex flex-row">
              <img className="w-[56px] h-[56px]" src={Photo} alt="avatar" />
              <div className="ml-3">
                <div className="font-semibold text-[20px]">
                  Tn. Abdul Wahid Ngatono
                </div>
                <div className="flex gap-2 mt-2">
                  <Badge label="Banjarmasin" />
                  <Badge label="Banjarmasin" />
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-6">
              <img
                className="w-[24px] h-[24px] cursor-pointer"
                src={IconEdit}
                alt="trash"
              />
              <img
                className="w-[24px] h-[24px] cursor-pointer"
                src={IconDelete}
                alt="trash"
              />
            </div>
          </div>
          <MoslemeTable
            bordered
            columns={columns}
            data={data}
            customClass="tbl-jamaah mt-4"
            withSelection={false}
            selectionType=""
            withPagination={false}
            customPaginationClass="pr-4"
          />
        </Cards>

        <Cards containerClass="!p-4 mt-4">
          <Dropdowns
            containerClass="mt-4"
            dropdownHeader={
              <>
                <div className="font-semibold min-h-[24px] mb-2 text-[16px] text-[#4F4F4F]">
                  Pilih Jamaah
                </div>
                <div className="bg-[#F2F2F2] rounded-xl p-4 flex flex-row justify-between items-center">
                  <div
                    className={`text-[14px] ${
                      selectedJamaahItem === "" && "text-[#828282]"
                    }`}
                  >
                    {selectedJamaahItem !== ""
                      ? selectedJamaahItem
                      : "Pilih jamaah"}
                  </div>
                  <img src={DropdownArrow} alt="arrow" className="w-6 h-6" />
                </div>
              </>
            }
          >
            <div className="orange-bg-shine !px-4 !py-3 !rounded-none !rounded-tl-2xl !rounded-tr-2xl">
              <div className="text-white font-semibold text-[16px]">
                Pilih Additional
              </div>
            </div>
            <div className="bg-white soft-shadow rounded-bl-2xl rounded-br-2xl">
              {jamaahItems.map((val, idx) => {
                return (
                  <div
                    key={idx}
                    className={`flex flex-row px-4 py-2 text-[#151515] cursor-pointer hover:bg-[#FFDCDC] ${
                      selectedJamaahItem === val.name && "bg-[#FFDCDC]"
                    }`}
                    onClick={() => {
                      setSelectedJamaahItem(val.name);
                      closeDropdown();
                    }}
                  >
                    <img
                      className="w-[40px] h-[40px]"
                      src={Photo}
                      alt="avatar"
                    />
                    <div className="ml-3">
                      <div className="font-semibold text-[16px]">
                        {val.name}
                      </div>
                      <div className="flex gap-2 mt-2">
                        <Badge label="Banjarmasin" />
                        <Badge label="Banjarmasin" />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Dropdowns>
          <Grid container spacing={2}>
            <Grid item md={3}>
              <Inputs id="total" label="Jumlah" placeholder="" />
            </Grid>
            <Grid item md={9}>
              <Inputs id="price" label="Total Harga" placeholder="" />
            </Grid>
          </Grid>
          <TextAreas
            id="note"
            placeholder=""
            label="Catatan"
            containerClass="mt-4"
          />
          <Buttons
            text="Tambah"
            containerClass="mt-6"
            customClass="btn-primary btn-large"
          />
        </Cards>
      </Cards>
      <Buttons
        text="Simpan"
        customClass="btn-primary btn-large disabled mt-4"
      />
    </>
  );
};

export default AddAdditional;
