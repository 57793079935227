import { makeStyles } from "@material-ui/core";
import React, { useCallback, useState } from "react";
import PopupConfirm from ".";
import Buttons from "../../buttons";

const useStyles = makeStyles({
  container: {
    width: "200px",
  },
});

const DocsPopupConfirm = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => setOpen(false), []);

  const handleOpen1 = useCallback(() => setOpen1(true), []);
  const handleClose1 = useCallback(() => setOpen1(false), []);

  const handleOpen2 = useCallback(() => setOpen2(true), []);
  const handleClose2 = useCallback(() => setOpen2(false), []);

  return (
    <div>
      <PopupConfirm
        open={open}
        handleClose={handleClose}
        type="success"
        title="Berhasil"
        subtitle="Berhasil menambahkan member baru."
      />
      <PopupConfirm
        open={open1}
        handleClose={handleClose1}
        type="error"
        title="Gagal"
        subtitle="Gagal menambahkan."
      />
      <PopupConfirm
        open={open2}
        handleClose={handleClose2}
        type="warning"
        title="Warning"
        subtitle="Harap periksa kembali data yang Anda tambahkan."
      />
      <div className={classes.container}>
        <Buttons
          text="Success"
          customClass="btn-primary btn-medium"
          onClick={handleOpen}
        />
        <Buttons
          text="error"
          customClass="btn-primary btn-medium mt-[5px]"
          onClick={handleOpen1}
        />
        <Buttons
          text="warning"
          customClass="btn-primary btn-medium mt-[5px]"
          onClick={handleOpen2}
        />
      </div>
    </div>
  );
};

export default DocsPopupConfirm;
