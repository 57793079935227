import React, { useState } from "react";
import { Pagination } from "antd";
import { makeStyles } from "@material-ui/core";
import { useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getAllPaket } from "stores/actions/paket/landing";
import useDebounce from "../../../../utils/helpers/useDebounce";

import Header from "./components/Header";
import PaketDetailHover from "./components/PaketDetailHover";
import PaketCard from "./components/PaketCard";

const useStyle = makeStyles({
  content: {
    background: "#fff",
    borderRadius: "16px",
    boxShadow:
      "0px 10px 16px -6px rgba(0, 0, 0, 0.04), 0px 4px 80px -4px rgba(0, 0, 0, 0.04)",
    "& .content_header": {
      padding: "9px 16px",
      borderBottom: "1px solid #E0E0E0",
    },
    "& .content_list": {
      display: "grid",
      gap: "10px",
      gridTemplateColumns: "repeat(3,minmax(0,1fr))",
      height: "calc(100vh - 260px)",
      overflowY: "auto",
      overflowX: "hidden",
      "&::-webkit-scrollbar": {
        width: "4px",
        // height: scrollHorizontal ? "5px" : "100px",
      },
      "&::-webkit-scrollbar-track": {
        width: "4px",
        borderRight: "10px solid #fff",
        borderLeft: "10px solid #fff",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#E0E0E0",
        height: "30px",
        width: "4px",
        borderRadius: "16px",
      },
      "&::-webkit-scrollbar-track-piece:start": {
        background: "#fff",
      },
      "&::-webkit-scrollbar-track-piece:end": {
        background: "#fff",
      },
      scrollBehavior: "smooth",
      "@media (max-width: 1400px)": {
        gridTemplateColumns: "repeat(2,minmax(0,1fr))",
      },

      "& .paket_cards_container": {},

      "& .paket_card": {
        borderRadius: "0px 40px 40px 0px",
        transition: "transform 0.3s ease-in-out",
        // position: "relative",
        margin: "0 auto",
        "&:hover ": {
          // position: "absolute",
          top: "0",
          right: "auto",
          // zIndex: '99',
        },
        // zIndex: '99',
        background: "#FBF3F2",
        borderRadius: "40px",
        cursor: "pointer",
        fontSize: "General Sans, sans-serif",
        padding: "51px 12px 12px 12px",
        // position: "relative",
        // zIndex: 1,
        "&:nth-child(-n+3)": {
          borderRadius: "0px 0px 40px 40px",
        },
        "& .card_content": {
          background: "#fff",
          borderRadius: "32px",
          boxShadow:
            "0px 10px 16px -6px rgba(0, 0, 0, 0.04), 0px 4px 80px -4px rgba(0, 0, 0, 0.04)",
          display: "flex",
          padding: "10px 10px 12px 12px",
        },
        "& .card_image_wrapper": {
          borderRadius: "24px",
          boxShadow: "0px 6px 64px -4px rgba(0, 0, 0, 0.04)",
          filter:
            "drop-shadow(0px 10px 16px rgba(0, 0, 0, 0.04)) drop-shadow(0px 6px 32px rgba(0, 0, 0, 0.04))",
          overflow: "hidden",
          position: "relative",
          marginTop: "-38px",
          "& .card_image": {
            height: "248px",
            objectFit: "cover",
            width: "200px !important",
          },
        },
        "& .card_text_wrapper": {
          paddingLeft: "12px",
          width: "100%",
          "& .card_text_title": {
            borderBottom: "1px dashed #BDBDBD",
            borderSpacing: "5px",
            paddingBottom: "8px",
            position: "relative",
            "& h2": {
              fontWeight: 600,
              fontSize: "14px",
              textTransform: "uppercase",
              lineHeight: "20px",
            },
            "& .card_badge": {
              background: "#2eb24333",
              borderRadius: "4px",
              color: "#333D29",
              fontSize: "8px",
              fontWeight: "600",
              padding: "4px 8px",
              position: "absolute",
              bottom: "10px",
              right: "0",
              width: "fit-content",
            },
          },
          "& .card_detail_item": {
            display: "flex",
            gap: "8px",
            alignItems: "center",
            marginTop: "12px",
            fontSize: "12px",
            "& .green_bold_text": {
              background:
                "linear-gradient(204.44deg, #01F91A -18.49%, #359B05 84.72%)",
              fontWeight: 700,
              "-webkit-background-clip": "text",
              "-webkit-text-fill-color": "transparent",
            },
          },
        },
      },
    },
    "& .content_footer": {
      display: "flex",
      justifyContent: "flex-end",
      padding: "14px",
    },
  },
});

const Umroh = () => {
  const classes = useStyle();
  const { allPaket, isLoading } = useSelector((state) => state.landingPaket);
  const dispatch = useDispatch();
  const [isLoadingAction, setIsLoadingAction] = useState(false);

  const [hoveredPaketIndex, setHoveredPaketIndex] = useState(null);

  // search state
  const [searchValue, setSearchValue] = useState("");
  const searchDebounce = useDebounce(searchValue, 1000);
  const [status, setStatus] = useState({
    publish: false,
    draft: false,
  });
  const [status_keberangkatan, setStatusKeberangkatan] = useState({
    sudah_berangkat: false,
    sedang_berangkat: false,
    belum_berangkat: false,
  });

  // pagination state
  const [currentPage, setCurrentPage] = useState(1);

  const handlePaketCardHover = (index) => {
    setHoveredPaketIndex(index);
  };

  const handlePaketCardUnhover = () => {
    setHoveredPaketIndex(null);
  };

  const renderStatus = () => {
    if (status.publish && status.draft) {
      return [1, 0];
    } else if (status.publish) {
      return [1];
    } else if (status.draft) {
      return [0];
    } else {
      return [];
    }
  };

  const renderStatusKebrangkatan = () => {
    if (
      status_keberangkatan.sudah_berangkat &&
      status_keberangkatan.sedang_berangkat &&
      status_keberangkatan.belum_berangkat
    ) {
      return [0, 1, 2];
    } else if (
      status_keberangkatan.sudah_berangkat &&
      status_keberangkatan.sedang_berangkat
    ) {
      return [2, 1];
    } else if (
      status_keberangkatan.sudah_berangkat &&
      status_keberangkatan.belum_berangkat
    ) {
      return [2, 0];
    } else if (
      status_keberangkatan.sedang_berangkat &&
      status_keberangkatan.belum_berangkat
    ) {
      return [1, 0];
    } else if (status_keberangkatan.sudah_berangkat) {
      return [2];
    } else if (status_keberangkatan.sedang_berangkat) {
      return [1];
    } else if (status_keberangkatan.belum_berangkat) {
      return [0];
    } else {
      return [];
    }
  };

  useLayoutEffect(() => {
    const payload = {
      tipe_paket: 2,
      keyword: searchValue,
      per_page: 10,
      page_number: currentPage,
      sort_column: "id_paket",
      sort_order: "DESC",
      status: renderStatus(),
      status_keberangkatan: renderStatusKebrangkatan(),
    };
    dispatch(getAllPaket(payload));
  }, [currentPage, searchDebounce]);

  const handleStatus = (data, key) => {
    setStatus({ ...status, [key]: data });
  };

  const handleStatusKeberangkatan = (data, key) => {
    setStatusKeberangkatan({ ...status_keberangkatan, [key]: data });
  };

  const handleClickTerapkan = () => {
    setIsLoadingAction(true);
    const payload = {
      tipe_paket: 2,
      keyword: searchValue,
      per_page: 10,
      page_number: currentPage,
      sort_column: "id_paket",
      sort_order: "DESC",
      status: renderStatus(),
      status_keberangkatan: renderStatusKebrangkatan(),
    };
    dispatch(
      getAllPaket(payload, (message) => {
        setIsLoadingAction(false);
      })
    );
  };

  return (
    <>
      <div className={classes.content}>
        <div className="content_header">
          <Header
            onSearch={(e) => setSearchValue(e.target.value)}
            onStatus={handleStatus}
            status={status}
            onStatusKeberangkatan={handleStatusKeberangkatan}
            status_keberangkatan={status_keberangkatan}
            handleClickTerapkan={handleClickTerapkan}
            isLoading={isLoadingAction}
          />
        </div>
        <div className="content_list">
          {allPaket.data &&
            allPaket.data.length > 0 &&
            allPaket?.data?.map((item) => (
              <div
                className="paket_card"
                key={item.id_paket}
                onMouseEnter={() => handlePaketCardHover(item.id_paket)}
                onMouseLeave={() => handlePaketCardUnhover()}
              >
                {hoveredPaketIndex === item.id_paket && (
                  <PaketDetailHover data={item} />
                )}
                <PaketCard data={item} />
              </div>
            ))}
        </div>
        <div className="content_footer mosleme-pagination">
          <Pagination
            defaultCurrent={1}
            total={allPaket?.total_data}
            onChange={(e) => setCurrentPage(e)}
          />
        </div>
      </div>
    </>
  );
};

export default React.memo(Umroh);
