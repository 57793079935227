import Maskapai from "../assets/icons/Maskapai.svg";
import MaskapaiActive from "../assets/icons/Maskapai1.svg";
import AppWeb from "../assets/icons/app-web.svg";
import Notification from "../assets/icons/notification.svg";
import FAQ from "../assets/icons/FAQ.svg";
import HelpCenter from "../assets/icons/Pusat-Bantuan.svg";
import PrivacyPolicy from "../assets/icons/Kebijakan-Privasi.svg";
import About from "../assets/icons/Tentang-Aplikasi.svg";

export const settingMenuList = [
  {
    title: "Travel",
    key: "travel",
    icon: Maskapai,
    iconActive: MaskapaiActive,
    order: 0,
  },
  {
    title: "Aplikasi & Website",
    key: "apps",
    icon: AppWeb,
    order: 1,
  },
  {
    title: "Notifikasi & Pengingat",
    key: "notification",
    icon: Notification,
    order: 2,
  },
  {
    title: "FAQ",
    key: "faq",
    icon: FAQ,
    order: 3,
  },
  {
    title: "Pusat Bantuan",
    key: "help-center",
    icon: HelpCenter,
    order: 4,
  },
  {
    title: "Kebijakan Privasi",
    key: "privacy-policy",
    icon: PrivacyPolicy,
    order: 5,
  },
  {
    title: "Tentang Aplikasi",
    key: "about",
    icon: About,
    order: 4,
  },
];
