import React from "react";
import PropTypes from "prop-types";
import Popup from "../../../popup";
import Inputs from "../../../form/inputs";
import Buttons from "../../../buttons";
import { makeStyles } from "@material-ui/core";
import IconChecklist from "../../../../assets/icons/ic_checklist.svg";
import ScrollCustom from "../../../scroll";
const useStyles = makeStyles({
  card_popup_profile: {
    width: "100%",
    height: "100%",
    padding: "20px",
    borderWidth: "1px 1px 1px 1px",
    borderStyle: "solid",
    borderColor: "rgba(64, 72, 82, 0.1)",
    borderRadius: "8px 8px 0px 0px",
    "& .card_inside": {
      marginTop: "20px",
      border: "1px solid rgba(64, 72, 82, 0.1)",
      height: "256px",
      width: "100%",
      padding: "16px",
      borderRadius: "8px",
      "& .container_button_outline": {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        marginTop: "10px",
      },
      "& .container_column": {
        display: "flex",
        flexDirection: "column",
        "& .title": {
          fontFamily: "GeneralSans",
          fontSize: "14px",
          fontWeight: 500,
          color: "#000",
        },
        "& .subtitle": {
          fontSize: "14px",
          fontFamily: "GenaralSans",
          color: "#4F4F4F",
        },
        "& .row_container": {
          marginTop: "16px",
          marginBottom: "8px",
          display: "flex",
          flexDirection: "row",
          "& .icon": {
            marginRight: "5px",
          },
          "& .title": {
            fontFamily: "GeneralSansSemiBold",
            fontSize: "14px",

            color: "#000",
          },
        },
      },
    },
    "& .container_button": {
      marginTop: "75px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center",
    },
  },
});

const PerbaruiKontak = ({
  openProfileKontak,
  handleClosePopupProfileKontak,
  dataProfileKontak,
}) => {
  const classes = useStyles();
  return (
    <Popup
      type="secondary"
      width="1000px"
      height="467px"
      open={openProfileKontak}
      handleClose={handleClosePopupProfileKontak}
      title="Perbarui Kontak"
    >
      <div className={classes.card_popup_profile}>
        {/* <ScrollCustom height={200}> */}
          <div className="card_inside">
            <div className="container_column">
              <div className="title">Ganti Email</div>
              <div className="row_container">
                <div className="icon">
                  <img src={IconChecklist} alt="checklist" />
                </div>
                <div className="title">Me@dimzdigital.media</div>
              </div>
              <div className="subtitle">Alamat email telah diverifikasi</div>
            </div>
            <Inputs
              id="email"
              placeholder="Masukan masukan email"
              customClass="mt-[10px]"
            />
            <div className="container_button_outline">
              <Buttons
                text="Perbarui Email"
                customClass="btn-outline btn-medium"
              />
            </div>
          </div>
          <div className="card_inside">
            <div className="container_column">
              <div className="title">Ganti Nomor Telepon</div>
              <div className="row_container">
                <div className="icon">
                  <img src={IconChecklist} alt="checklist" />
                </div>
                <div className="title">0878 5354 3388</div>
              </div>
              <div className="subtitle">Nomor telepon telah diverifikasi</div>
            </div>
            <Inputs
              id="telephon"
              placeholder="Masukan nomor baru"
              customClass="mt-[10px]"
            />
            <div className="container_button_outline">
              <Buttons
                text="Perbarui No telpon"
                customClass="btn-outline btn-medium"
              />
            </div>
          </div>
        {/* </ScrollCustom> */}
        <div className="container_button">
          <Buttons text="Simpan" customClass="btn-primary btn-medium" />
        </div>
      </div>
    </Popup>
  );
};

PerbaruiKontak.propTypes = {
  dataProfileKontak: PropTypes.object.isRequired,
  openProfileKontak: PropTypes.bool.isRequired,
  handleClosePopupProfileKontak: PropTypes.func.isRequired,
};
PerbaruiKontak.defaultProps = {
  dataProfileKontak: {},
  openProfileKontak: false,
  handleClosePopupProfileKontak: () => {},
};

export default React.memo(PerbaruiKontak);
