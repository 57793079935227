export const INIT_DATA = "paket/coupon/INIT_DATA";
export const SET_LOADING = "paket/coupon/SET_LOADING";
export const CLEAR_ERROR = "paket/coupon/CLEAR_ERROR";
export const SET_ERROR = "paket/coupon/SET_ERROR";
export const SET_PAGE_PAKET_COUPON = "paket/coupon/SET_PAGE_PAKET_COUPON";
export const SET_SIZE_PAKET_COUPON = "paket/coupon/SET_SIZE_PAKET_COUPON";
export const SET_SIZE_TOTAL_PAKET_COUPON =
  "paket/coupon/SET_SIZE_TOTAL_PAKET_COUPON";
export const SET_KEYWORD_PAKET_COUPON = "paket/coupon/SET_KEYWORD_PAKET_COUPON";
export const START_FETCHING_PAKET_COUPON =
  "paket/coupon/START_FETCHING_PAKET_COUPON";
export const ERROR_FETCHING_PAKET_COUPON =
  "paket/coupon/ERROR_FETCHING_PAKET_COUPON";
export const SUCCESS_FETCHING_PAKET_COUPON =
  "paket/coupon/SUCCESS_FETCHING_PAKET_COUPON";
export const SET_ADD_OR_EDIT_PAKET_COUPON =
  "paket/coupon/SET_ADD_OR_EDIT_PAKET_COUPON";
export const SET_DELETE_PAKET_COUPON = "paket/coupon/SET_DELETE_PAKET_COUPON";
