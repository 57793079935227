import React from "react";
import PropTypes from "prop-types";
import { Snackbar, IconButton } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { ReactComponent as TimeCircle } from "../../assets/icons/times-circle.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close_toast.svg";

const Toast = ({
  message,
  vertical,
  horizontal,
  handleClose,
  open,
  status = "error",
}) => {
  const Alert = React.forwardRef((props, ref) => (
    <MuiAlert
      icon={
        status === "error" && (
          <TimeCircle width={24} height={24} color="white" />
        )
      }
      elevation={6}
      action={
        <IconButton onClick={handleClose} sx={{ padding: "4px 0px 0px 0px" }}>
          <CloseIcon />
        </IconButton>
      }
      ref={ref}
      variant="filled"
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      {...props}
    />
  ));
  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={6000}
        open={open}
        onClose={handleClose}
        key={vertical + horizontal}
        sx={{
          ".MuiAlert-root": {
            backgroundColor: "#FFF2E3",
          },
        }}
      >
        <Alert onClose={handleClose} severity={status}>
          <div
            style={{
              fontWeight: 400,
              fontSize: 13,
            }}
          >
            {message || "something went wrong!"}
          </div>
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
};

Toast.propTypes = {
  message: PropTypes.string,
  vertical: PropTypes.string,
  horizontal: PropTypes.string,
  handleClose: PropTypes.func,
  open: PropTypes.bool,
};

Toast.defaultProps = {
  message: "something went wrong!",
  vertical: "bottom",
  horizontal: "center",
  handleClose: () => {},
  open: false,
};

export default React.memo(Toast);
