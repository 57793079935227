export const pathnameCONFIG = {
  ROOT: {
    BASE_URL: "/",
  },
  DASHBOARD: {
    BASE_URL: "/dashboard",
  },
  PAKET: {
    BASE_URL: "/paket",
    DETAIL: "/paket/detail/:id",
  },
  TAGIHAN: {
    BASE_URL: "/tagihan",
    DETAIL: "/tagihan/detail",
  },
  CABANG: {
    BASE_URL: "/cabang",
  },
  LOG_ACTIVITY_CABANG: {
    BASE_URL: "/cabang",
    DETAIL: "/cabang/log-activity-cabang",
  },
  DATAMASTER: {
    BASE_URL: "/data_master",
    LOG_ACTIVITY: "/data_master/logactivity/:id",
  },
  ROLE: {
    BASE_URL: "/role",
  },
  MEMEBER: {
    BASE_URL: "/member",
  },
  APPS: {
    BASE_URL: "/apps",
  },
  BOOKING: {
    BASE_URL: "/booking",
    DETAIL: "/booking/detail",
  },
  CALCULATOR: {
    BASE_URL: "/calculator",
  },
  SETTING: {
    BASE_URL: "/setting",
  },
  MARKETING: {
    BASE_URL: "/marketing",
  },
  FEE: {
    BASE_URL: "/fee",
    PENARIKAN: "/fee-penarikan",
  },
  LAYANANISLAMI: {
    BASE_URL: "/layanan-islami",
  },
  // TRANSPORTASI: {
  //   BASE_URL: "/transportasi",
  // },
  // ITINENARY: {
  //   BASE_URL: "/itinerary",
  // },
};
