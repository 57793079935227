import React, { useState } from "react";

import Buttons from "components/buttons";
import Badge from "components/badge";
import PendapatanCabangHistory from "./components/History";

const PendapatanCabang = () => {
  const [history, setHistory] = useState(false);

  return (
    <>
      {history ? (
        <PendapatanCabangHistory setHistory={setHistory} />
      ) : (
        <>
          <div className="flex flex-row justify-between items-center p-4 mx-[-1rem]">
            <div className="text-xl font-semibold">
              Daftar Pendapatan Cabang
            </div>
            <Buttons
              text="History"
              customClass="btn-outline btn-small"
              onClick={() => setHistory(true)}
            />
          </div>
          {/* <div className="divider"></div> */}
          <div className="pt-4 border-t border-[#e0e0e0]">
            <div className="grid grid-cols-4 gap-4">
              <div className="bg-white shadow-soft rounded-lg">
                <div className="bg-[#FBC8C8] p-4 rounded-tl-lg rounded-tr-lg">
                  <Badge label="Yogyakarta" />
                </div>
                <div className="p-4">
                  <div>Total Pendapatan</div>
                  <div className="font-semibold mt-2">Rp32.789.000</div>
                </div>
              </div>

              <div className="bg-white shadow-soft rounded-lg">
                <div className="bg-[#E7E7E6] p-4 rounded-tl-lg rounded-tr-lg">
                  <Badge label="Jakarta" />
                </div>
                <div className="p-4">
                  <div>Total Pendapatan</div>
                  <div className="font-semibold mt-2">Rp32.789.000</div>
                </div>
              </div>

              <div className="bg-white shadow-soft rounded-lg">
                <div className="bg-[#FBE7C8] p-4 rounded-tl-lg rounded-tr-lg">
                  <Badge label="Solo" />
                </div>
                <div className="p-4">
                  <div>Total Pendapatan</div>
                  <div className="font-semibold mt-2">Rp32.789.000</div>
                </div>
              </div>

              <div className="bg-white shadow-soft rounded-lg">
                <div className="bg-[#F3ECCA] p-4 rounded-tl-lg rounded-tr-lg">
                  <Badge label="Surabaya" />
                </div>
                <div className="p-4">
                  <div>Total Pendapatan</div>
                  <div className="font-semibold mt-2">Rp32.789.000</div>
                </div>
              </div>

              <div className="bg-white shadow-soft rounded-lg">
                <div className="bg-[#C8FBFB] p-4 rounded-tl-lg rounded-tr-lg">
                  <Badge label="Banjarmasin" />
                </div>
                <div className="p-4">
                  <div>Total Pendapatan</div>
                  <div className="font-semibold mt-2">Rp32.789.000</div>
                </div>
              </div>

              <div className="bg-white shadow-soft rounded-lg">
                <div className="bg-[#EEE5C8] p-4 rounded-tl-lg rounded-tr-lg">
                  <Badge label="Balikpapan" />
                </div>
                <div className="p-4">
                  <div>Total Pendapatan</div>
                  <div className="font-semibold mt-2">Rp32.789.000</div>
                </div>
              </div>

              <div className="bg-white shadow-soft rounded-lg">
                <div className="bg-[#E0E9EB] p-4 rounded-tl-lg rounded-tr-lg">
                  <Badge label="Tarakan" />
                </div>
                <div className="p-4">
                  <div>Total Pendapatan</div>
                  <div className="font-semibold mt-2">Rp32.789.000</div>
                </div>
              </div>

              <div className="bg-white shadow-soft rounded-lg">
                <div className="bg-[#D1ECD5] p-4 rounded-tl-lg rounded-tr-lg">
                  <Badge label="Jambi" />
                </div>
                <div className="p-4">
                  <div>Total Pendapatan</div>
                  <div className="font-semibold mt-2">Rp32.789.000</div>
                </div>
              </div>

              <div className="bg-white shadow-soft rounded-lg">
                <div className="bg-[#F9F1F1] p-4 rounded-tl-lg rounded-tr-lg">
                  <Badge label="Makassar" />
                </div>
                <div className="p-4">
                  <div>Total Pendapatan</div>
                  <div className="font-semibold mt-2">Rp32.789.000</div>
                </div>
              </div>

              <div className="bg-white shadow-soft rounded-lg">
                <div className="bg-[#C8C8FB] p-4 rounded-tl-lg rounded-tr-lg">
                  <Badge label="Gorontalo" />
                </div>
                <div className="p-4">
                  <div>Total Pendapatan</div>
                  <div className="font-semibold mt-2">Rp32.789.000</div>
                </div>
              </div>

              <div className="bg-white shadow-soft rounded-lg">
                <div className="bg-[#D9D6E5] p-4 rounded-tl-lg rounded-tr-lg">
                  <Badge label="Pekanbaru" />
                </div>
                <div className="p-4">
                  <div>Total Pendapatan</div>
                  <div className="font-semibold mt-2">Rp32.789.000</div>
                </div>
              </div>

              <div className="bg-white shadow-soft rounded-lg">
                <div className="bg-[#D4F9EE] p-4 rounded-tl-lg rounded-tr-lg">
                  <Badge label="Palembang" />
                </div>
                <div className="p-4">
                  <div>Total Pendapatan</div>
                  <div className="font-semibold mt-2">Rp32.789.000</div>
                </div>
              </div>

              <div className="bg-white minim-shadow rounded-lg">
                <div className="bg-[#FAD8E9] p-4 rounded-tl-lg rounded-tr-lg">
                  <Badge label="Lubuklinggau" />
                </div>
                <div className="p-4">
                  <div>Total Pendapatan</div>
                  <div className="font-semibold mt-2">Rp32.789.000</div>
                </div>
              </div>

              <div className="bg-white minim-shadow rounded-lg">
                <div className="bg-[#F8E1F9] p-4 rounded-tl-lg rounded-tr-lg">
                  <Badge label="Medan" />
                </div>
                <div className="p-4">
                  <div>Total Pendapatan</div>
                  <div className="font-semibold mt-2">Rp32.789.000</div>
                </div>
              </div>

              <div className="bg-white minim-shadow rounded-lg">
                <div className="bg-[#FAF0E7] p-4 rounded-tl-lg rounded-tr-lg">
                  <Badge label="Aceh" />
                </div>
                <div className="p-4">
                  <div>Total Pendapatan</div>
                  <div className="font-semibold mt-2">Rp32.789.000</div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default React.memo(PendapatanCabang);
