import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import ReactQuill from "react-quill";

import Inputs from "../../../../../../../components/form/inputs";
import Switch from "../../../../../../../components/form/switch";
import EditorToolbar, {
  modules,
  formats,
} from "../../../../../../../components/richTextEditor/EditorToolbar";
import Buttons from "../../../../../../../components/buttons";
import Selects from "../../../../../../../components/form/selects";

const AddReminder = () => {
  const [caption, setCaption] = useState({ value: null });
  const [email, setEmail] = useState({ value: null });
  const [whatsapp, setWhatsapp] = useState({ value: null });
  const [telegram, setTelegram] = useState({ value: null });
  const [reminderType, setReminderType] = useState([
    {
      value: "jack",
      label: "Jack",
    },
    {
      value: "lucy",
      label: "Lucy",
    },
    {
      value: "tom",
      label: "Tom",
    },
  ]);

  const handleChange = (type, value) => {
    switch (type) {
      case "caption":
        setCaption({ value });
        break;
      case "email":
        setEmail({ value });
        break;
      case "whatsapp":
        setWhatsapp({ value });
        break;
      case "telegram":
        setTelegram({ value });
        break;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="border-[1px] border-[#4048521a] rounded-[12px]">
        <div className="p-4">
          <Grid container spacing={2}>
            <Grid item md={8}>
              <Inputs label="Judul" placeholder="Masukkan judul" />
            </Grid>
            <Grid item md={4}>
              <Selects
                label="Jenis Pengingat"
                placeholder="Text Input"
                options={reminderType}
              />
            </Grid>
          </Grid>

          <div className="mt-4">
            <div className="font-semibold text-[#4F4F4F]">Caption</div>
            <div className="text-editor mt-2">
              <EditorToolbar />
              <ReactQuill
                theme="snow"
                value={caption.value}
                onChange={(e) => handleChange("caption", e)}
                modules={modules}
                formats={formats}
                className="bg-[#EDF2F4] w-full h-[222px]"
              />
            </div>
          </div>
          <Switch
            containerClass="mt-4"
            label="Dibuat Slide"
            customClass="switch-slide"
          />
          <div className="mt-4">
            <div className="font-semibold text-[#4F4F4F]">Email</div>
            <div className="text-editor mt-2">
              <EditorToolbar />
              <ReactQuill
                theme="snow"
                value={email.value}
                onChange={(e) => handleChange("email", e)}
                modules={modules}
                formats={formats}
                className="bg-[#EDF2F4] w-full h-[222px]"
              />
            </div>
          </div>
          <div className="mt-4">
            <Grid container spacing={2}>
              <Grid item md={6}>
                <div className="font-semibold text-[#4F4F4F]">Whatsapp</div>
                <div className="text-editor mt-2">
                  <EditorToolbar />
                  <ReactQuill
                    theme="snow"
                    value={whatsapp.value}
                    onChange={(e) => handleChange("whatsapp", e)}
                    modules={modules}
                    formats={formats}
                    className="bg-[#EDF2F4] w-full h-[222px]"
                  />
                </div>
              </Grid>
              <Grid item md={6}>
                <div className="font-semibold text-[#4F4F4F]">Telegram</div>
                <div className="text-editor mt-2">
                  <EditorToolbar />
                  <ReactQuill
                    theme="snow"
                    value={telegram.value}
                    onChange={(e) => handleChange("telegram", e)}
                    modules={modules}
                    formats={formats}
                    className="bg-[#EDF2F4] w-full h-[222px]"
                  />
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="mt-4">
            <Grid container spacing={2}>
              <Grid item md={4}>
                <Selects
                  label="Text Field Label"
                  placeholder="Text Input"
                  options={reminderType}
                />
              </Grid>
              <Grid item md={4}>
                <Inputs label="Waktu" placeholder="Masukkan Waktu" />
              </Grid>
              <Grid item md={4}>
                <Selects
                  label="Tipe Waktu"
                  placeholder="Text Input"
                  options={reminderType}
                />
              </Grid>
            </Grid>
          </div>
        </div>
        <div className="divider"></div>
        <div className="flex flex-row justify-end p-4">
          <Buttons text="Tambah" customClass="btn-primary btn-medium !w-fit" />
        </div>
      </div>
    </>
  );
};

export default React.memo(AddReminder);
