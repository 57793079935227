import React, { useState } from "react";
import Buttons from "../../components/buttons";
import Inputs from "../../components/form/inputs";
import search from "../../assets/icons/search-normal.svg";
import { ReactComponent as Activity } from "../../assets/icons/activity.svg";
import { ReactComponent as Receive } from "../../assets/icons/receive-square.svg";
import { ReactComponent as Send } from "../../assets/icons/send-square.svg";
import SliderTab from "./components/slidertab";
import ModalDatamaster from "./components/modalmaster";
import Maskapai from "./components/subpage/maskapai/index";
import Bandara from "./components/subpage/bandara/index";
import Transport from "./components/subpage/Transport";
import Akomodasi from "./components/subpage/Akomodasi";
import Cards from "../../components/cards";
import Rekening from "./components/subpage/rekening";
import Additional from "./components/subpage/additional";
import Starting from "./components/subpage/starting";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Musim from "./components/subpage/musim";
import Popup from "components/popup";
import { getMasterId } from "stores/actions/datamaster";
import { resetDataMusim } from "stores/actions/datamaster/musim";
import Perlengkapan from "./components/subpage/perlengkapan";
import Pembimbing from "./components/subpage/pembimbing";
import { getLocalStorage } from "utils/helpers";

function Master() {
  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState("");
  const dispatch = useDispatch();

  const { keyData, masterId } = useSelector((state) => state.datamaster);

  const userLogin =
    getLocalStorage("userLogin") && JSON.parse(getLocalStorage("userLogin"));
  const permissions = userLogin.data.account.permissions;

  const tableComponentRender = () => {
    switch (keyData) {
      case "maskapai":
        return <Maskapai filter={filter} openModal={setOpen} />;
      case "bandara":
        return <Bandara filter={filter} openModal={setOpen} />;
      case "transportasi":
        return <Transport filter={filter} openModal={setOpen} />;
      case "akomodasi":
        return <Akomodasi filter={filter} openModal={setOpen} />;
      case "musim":
        return <Musim filter={filter} openModal={setOpen} />;
      case "rekening":
        return <Rekening filter={filter} openModal={setOpen} />;
      case "additional":
        return <Additional filter={filter} openModal={setOpen} />;
      case "starting":
        return <Starting filter={filter} openModal={setOpen} />;
      case "perlengkapan":
        return <Perlengkapan filter={filter} openModal={setOpen} />;
      case "pembimbing & guide":
        return <Pembimbing filter={filter} openModal={setOpen} />;
      default:
        return null;
    }
  };

  const handleFilter = (e) => {
    setFilter(e.target.value);
  };

  const onHandleAddData = () => {
    keyData === "musim" ||
    keyData === "rekening" ||
    keyData === "additional" ||
    keyData === "starting"
      ? dispatch(getMasterId({ tambahData: true }))
      : setOpen(true);
    return console.log(keyData);
  };

  return (
    <Cards containerClass="!p-0">
      {masterId?.tambahData ? (
        <ModalDatamaster
          open={masterId.tambahData}
          setOpen={() => {
            dispatch(getMasterId({}));
            dispatch(resetDataMusim());
          }}
        />
      ) : (
        <ModalDatamaster open={open} setOpen={() => setOpen(false)} />
      )}

      <div className="flex w-full flex-row justify-start py-[14px] px-[10px]">
        <div className="mr-[10px] ">
        <Inputs
            id="test"
            placeholder="Search"
            // customClass=""
            iconPosition="left"
            icon={search}
            onChange={handleFilter}
          />
        </div>
        <div className="flex flex-row w-full justify-between">
          <div className="flex items-center w-full justify-center">
            <SliderTab />
          </div>
          <div className="flex items-center ml-7">
            {permissions.includes("create_master-data") && (
              <Buttons
                onClick={onHandleAddData}
                text="Tambah Data"
                customClass="btn-primary btn-small"
              />
            )}
            <Link
              className="block ml-4"
              to={`/data_master/logactivity/${keyData}`}
            >
              <Activity className=" w-4 h-4 mx-2" />
            </Link>
            <Send className=" w-4 h-4 mx-2" />
            <Receive className=" w-4 h-4 mx-2" />
          </div>
        </div>
      </div>
      <div className="">{tableComponentRender()}</div>
    </Cards>
  );
}

export default Master;
