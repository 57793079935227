import React from "react";

import Badge from "../../../../components/badge";
import MoslemeTable from "../../../../components/Table";

const TabTagihan = ({ avatar, columns, data }) => {
  const CardTagihanJamaah = () => {
    return (
      <div className="card-tagihan-jamaah-wrapper">
        <div className="card-tagihan-jamaah-header flex flex-row items-center">
          <img
            src={avatar}
            className="card-tagihan-jamaah-header-avatar"
            alt="avatar"
          />
          <div className="card-tagihan-jamaah-header-name">
            Tn. Abdul Wahid Ngatono
          </div>
        </div>
        <div className="card-tagihan-jamaah-content">
          <div className="flex flex-row justify-between p-4">
            <div className="card-tagihan-jamaah-content-wrapper">
              <div className="card-tagihan-jamaah-content-wrapper-label">
                Tipe Kamar
              </div>
              <div className="card-tagihan-jamaah-content-wrapper-value">
                Quad
              </div>
            </div>
            <div className="card-tagihan-jamaah-content-wrapper">
              <div className="card-tagihan-jamaah-content-wrapper-label">
                Harga Paket
              </div>
              <div className="card-tagihan-jamaah-content-wrapper-value">
                Rp 30.000.000
              </div>
            </div>
            <div>
              <Badge label="BPN-CGK" type="old brown" />
            </div>
          </div>

          <div className="px-4 mt-1 mb-4">
            <div>Additional</div>
            <MoslemeTable
              bordered
              columns={columns}
              data={data}
              customClass="tbl-jamaah"
              withSelection={false}
              selectionType=""
              withPagination={false}
              customPaginationClass="pr-4"
            />
          </div>
        </div>
        <div className="card-tagihan-jamaah-footer p-4">
          <div className="card-tagihan-jamaah-footer-wrapper">
            <div className="card-tagihan-jamaah-footer-wrapper-label">
              Total Tagihan:
            </div>
            <div className="card-tagihan-jamaah-footer-wrapper-name mt-2">
              Rp 33.500.000
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="grid grid-cols-2 gap-2 ">
        <CardTagihanJamaah />
        <CardTagihanJamaah />
        <CardTagihanJamaah />
        <CardTagihanJamaah />
        <CardTagihanJamaah />
        <CardTagihanJamaah />
      </div>
    </>
  );
};

export default TabTagihan;
