import React, { useEffect, useState } from "react";
import Card from "../../../../../../components/cards";
import Tab from "../../../../../../components/tab";
import { Spin } from "antd";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";

import "./tabs.css";
import EditInfo from "./info";
import EditGallery from "./galery";
import EditAddress from "./address";
import { editDataAkomodasi, editDataBandara } from "stores/actions/datamaster";
import { IMAGE } from "constant/constanta";

function EditAkomodasi({ setOpen }) {
  const state = useSelector((state) => state);
  console.log({ state });
  const { detailData } = useSelector((state) => state.datamaster);
  const { dataMultiple, isLoading } = useSelector((state) => state.uploadFile);
  const dispatch = useDispatch();
  const [dataVideo, setDataVideo] = useState();
  const [time, setTime] = useState(false);

  const formik = useFormik({
    initialValues: {
      id: "",
      image_logo: "",
      image_thumbnail: "",
      kategori: "",
      bintang: "",
      deskripsi: "",
      latitude: "",
      longitude: "",
      alamat_lengkap: "",
      address_lat_long: "",
      name: "",
    },
  });

  useEffect(() => {
    if (detailData) {
      formik.setFormikState({
        values: {
          id: detailData?.data?.id_akomodasi,
          image_logo: detailData?.data?.logo,
          image_thumbnail: detailData?.data?.thumbnail,
          deskripsi: detailData?.data?.deskripsi,
          kategori: detailData?.data?.kategori,
          bintang: detailData?.data?.bintang,
          latitude: detailData?.data?.latitude,
          name: detailData?.data?.name,
          longitude: detailData?.data?.longitude,
          alamat_lengkap: detailData?.data?.alamat_lengkap,
          address_lat_long: detailData?.data?.address_lat_long,
          images: dataMultiple
            .filter((x) => x.type === "image")
            .map((x) => x.path),
        },
      });
      if (detailData?.data?.gallery_akomodasi)
        setDataVideo(
          detailData?.data?.gallery_akomodasi
            .filter((x) => x.type === "video")
            .map((x) => x.link)
        );
    }
    setTimeout(() => {
      setTime(true);
    }, 1000);
  }, [detailData]);

  const handleSave = () => {
    // console.log({
    //   ...formik.values,
    //   latitude: formik.values.latitude.toString(),
    //   longitude: formik.values.longitude.toString(),
    //   name: formik.values.name.toString(),
    //   url_video: dataVideo,
    //   images: dataMultiple
    //     .filter((x) => x.type === "image" && x.path.split("/")[2] === IMAGE)
    //     .map((x) => x.path),
    // });
    dispatch(
      editDataAkomodasi({
        ...formik.values,
        latitude: formik.values.latitude.toString(),
        longitude: formik.values.longitude.toString(),
        name: formik.values.name.toString(),
        url_video: dataVideo,
        images: dataMultiple
          .filter((x) => x.type === "image" && x.path.split("/")[2] === IMAGE)
          .map((x) => x.path),
      })
    );
    setOpen(false);
  };

  return (
    <>
      {time === true ? (
        <Card containerClass=" !shadow-none !rounded-2xl !border !border-gray-200">
          <Tab
            defaultActiveKey="1"
            items={[
              {
                label: "Info Dasar",
                key: "1",
                children: (
                  <EditInfo
                    valueKategori={formik.values.kategori}
                    valueBintang={formik.values.bintang}
                    valueDescription={formik.values.deskripsi}
                    descriptionOnChange={(value) => {
                      formik.setFieldValue("deskripsi", value);
                    }}
                    valueName={formik.values.name}
                    formikOnChange={formik.handleChange}
                    formikValue={formik.setFieldValue}
                    handleSave={handleSave}
                    loading={isLoading}
                  />
                ),
              },

              {
                label: "Alamat & Koordinat",
                key: "2",
                children: (
                  <EditAddress
                    valueAddress={formik.values.address_lat_long}
                    valueAddressDetail={formik.values.alamat_lengkap}
                    valueLat={formik.values.latitude}
                    valueLong={formik.values.longitude}
                    formikOnChange={formik.handleChange}
                    handleSave={handleSave}
                    loading={isLoading}
                  />
                ),
              },
              {
                label: "Galeri & Deskripsi",
                key: "3",
                children: (
                  <EditGallery
                    valueImage={formik.values.images}
                    valueVideo={formik.values.url_video}
                    formikValue={formik.setFieldValue}
                    handleSave={handleSave}
                    setDataVideo={setDataVideo}
                    dataVideo={dataVideo}
                    loading={isLoading}
                  />
                ),
              },
            ]}
          />
        </Card>
      ) : (
        <div className="w-full h-[534px] flex justify-center items-center">
          <Spin size="large" />
        </div>
      )}
    </>
  );
}

export default EditAkomodasi;
