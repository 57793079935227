// adminStarting Actions
// --------------------------------------------------------

import {
  handleError,
  handleErrorBE,
  handleSuccess,
} from "stores/actions/errorGeneral";
import { setValidation, set_after_validation } from "stores/actions/general";
import { clearData } from "stores/actions/uploadFile";
import {
  createOrUpdateStartingApi,
  duplicateStartingApi,
} from "utils/api/datamaster/starting";
import {
  deleteIdStartingApi,
  getStartingApi,
} from "utils/api/datamaster/starting";
import { getAllDataMaster } from "..";

/* eslint-disable space-before-function-paren */
export const SET_LOADING = "adminStarting/SET_LOADING";
export const CLEAR_ERROR = "adminStarting/CLEAR_ERROR";
export const SET_ERROR = "adminStarting/SET_ERROR";
export const INIT_DATA = "adminStarting/INIT_DATA";
export const SET_DOUBLE_SUBMIT = "adminStarting/SET_DOUBLE_SUBMIT";
export const SET_DATA_STARTING = "adminStarting/SET_DATA_STARTING";
export const SET_DELETE_ID_STARTING = "adminStarting/SET_DELETE_ID_STARTING";

export const setLoading = (payload) => ({
  type: SET_LOADING,
  payload,
});

export const setDoubleSubmit = (payload) => ({
  type: SET_DOUBLE_SUBMIT,
  payload,
});

export const setDataStarting = (payload) => ({
  type: SET_DATA_STARTING,
  payload,
});

export const setDeleteIdStarting = (payload) => ({
  type: SET_DELETE_ID_STARTING,
  payload,
});

export const getMasterStarting = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await getStartingApi(payload);
    dispatch(setLoading(false));
    if (res.status === 200) {
      dispatch(setDataStarting(res.data));
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const deleteIdMasterStarting = (id) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await deleteIdStartingApi(id);
    dispatch(setLoading(false));
    if (res.status === 200) {
      dispatch(setDeleteIdStarting(res.data));
      dispatch(getAllDataMaster());
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const createOrUpdateStarting = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await createOrUpdateStartingApi(payload);
    dispatch(setLoading(false));
    if (res.status === 200) {
      dispatch(set_after_validation(res.data));
      dispatch(clearData());
      dispatch(getAllDataMaster());
      dispatch(handleSuccess(res.data));
    } else if (res.status === 400) {
      dispatch(setValidation(res.data));
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};

export const duplicateStarting = (payload) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    const res = await duplicateStartingApi(payload);
    dispatch(setLoading(false));
    if (res.status === 200) {
      dispatch(set_after_validation(res.data));
      dispatch(handleSuccess(res.data));
    } else if (res.status === 400) {
      dispatch(setValidation(res.data));
    } else {
      dispatch(handleErrorBE(res));
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(setLoading(false));
  }
};
