import React, { useState } from "react";

import Buttons from "../../../../../components/buttons";
import Radios from "../../../../../components/form/radio";
import InputPhone from "../../../../../components/form/inputPhone";

import Whatsapp from "../../../../../assets/icons/whatsapp-white.svg";
import Email from "../../../../../assets/icons/sms-white.svg";
import FlagIndonesia from "../../../../../assets/icons/flag-indonesia.svg";
import Inputs from "../../../../../components/form/inputs";
import { useDispatch, useSelector } from "react-redux";
import {
    getKontakAction,
    saveKontakAction,
} from "stores/actions/pusatBantuan/kontak";

const EditContact = ({ handleClose, activeKontakToEdit }) => {
    const { isLoading } = useSelector((state) => state.pusatBantuanKontak);

    const dispatch = useDispatch();

    const [type, setType] = useState(activeKontakToEdit.jenis);
    const [email, setEmail] = useState(
        activeKontakToEdit.jenis === "email" ? activeKontakToEdit.kontak : ""
    );
    const [whatsapp, setWhatsapp] = useState(
        activeKontakToEdit.jenis === "whatsapp" ? activeKontakToEdit.kontak : ""
    );
    const [owner, setOwner] = useState(activeKontakToEdit.pemilik);

    const [radio, setRadio] = useState([
        {
            label: "Whatsapp",
            value: "whatsapp",
            icon: Whatsapp,
            disabled: activeKontakToEdit.jenis === "email",
        },
        {
            label: "Email",
            value: "email",
            icon: Email,
            disabled: activeKontakToEdit.jenis === "whatsapp",
        },
    ]);

    const savePayload = {
        id: activeKontakToEdit.id,
        kontak: type === "email" ? email : whatsapp,
        jenis: type,
        pemilik: owner || "-",
    };

    const getPayload = {
        keyword: "",
        per_page: 10,
        page_number: 1,
        sort_column: "kontak",
        sort_order: "ASC",
    };

    const submitData = async () => {
        await dispatch(saveKontakAction(savePayload));
        await dispatch(getKontakAction(getPayload));
        handleClose();
    };
    return (
        <div>
            <div className="border-[1px] border-[#4048521a] rounded-[12px]">
                <div className="p-4">
                    <div className="font-semibold text-[#4F4F4F]">Cabang</div>
                    <Radios
                        defaultValue={type}
                        options={radio}
                        containerClass="mt-2"
                        onChange={(e) => setType(e.target.value)}
                    />
                    {type === "email" ? (
                        <>
                            <div className="font-semibold text-[#4F4F4F] mt-4">
                                Alamat Email
                            </div>
                            <Inputs
                                containerClass="mt-2"
                                placeholder="Masukkan alamat email"
                                onChange={(e) => setEmail(e.target.value)}
                                value={email}
                            />
                        </>
                    ) : (
                        <>
                            <div className="font-semibold text-[#4F4F4F] mt-4">
                                Nomor Whatsapp
                            </div>
                            <InputPhone
                                id="test"
                                containerClass="mt-2"
                                placeholder="Text Input"
                                icon={FlagIndonesia}
                                onChange={(val) => setWhatsapp(val)}
                                value={whatsapp}
                            />

                            <div className="font-semibold text-[#4F4F4F] mt-4">
                                Nama Pemilik
                            </div>
                            <Inputs
                                containerClass="mt-2"
                                placeholder="Masukkan nama pemilik"
                                onChange={(e) => setOwner(e.target.value)}
                                value={owner}
                            />
                        </>
                    )}
                </div>
                <div className="divider"></div>
                <div className="flex flex-row justify-end p-4">
                    <Buttons
                        text="Tambah"
                        customClass="btn-primary btn-medium !w-fit"
                        onClick={submitData}
                        loading={isLoading}
                    />
                </div>
            </div>
        </div>
    );
};

export default React.memo(EditContact);
