import React from "react";

import BookingDetailStyle from "./BookingDetail.Style";
import Cards from "components/cards";
import Buttons from "components/buttons";
import BookingDetailHeader from "./components/BookingDetailHeader";
import BookingDetailContent from "./components/BookingDetailContent";

import IconPrint from "assets/icons/Print.svg";
import IconArrowLeft from "assets/icons/arrow-left.svg";

const BookingDetailPage = () => {
  return (
    <BookingDetailStyle>
      <Cards containerClass="!p-0">
        <div className="flex flex-row gap-2 items-center p-4">
          <img src={IconArrowLeft} alt="arrow" />
          <div className="text-[28px] font-semibold">Booking UMR-123</div>
          <Buttons
            text="Print"
            customClass="btn-outline btn-small"
            icon={IconPrint}
          />
        </div>
        <div className="divider"></div>
        <div className="p-4">
          <BookingDetailHeader />
          <div className="mt-4">
            <BookingDetailContent />
          </div>
        </div>
      </Cards>
    </BookingDetailStyle>
  );
};

export default React.memo(BookingDetailPage);
