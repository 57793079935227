import React from "react";
import Badge from "components/badge";
import Switch from "components/form/switch";
import Inputs from "components/form/inputs";
import Buttons from "components/buttons";
import { toCurrencyFormat } from "utils/helpers";
import IconEdit from "assets/icons/edit.svg";
import InputsCurrency from "components/form/inputsCurrency";

const CardContent = ({
  data,
  handleEditFee,
  handleChangeRow,
  index,
  updatedPaketFee,
  isLoadingAction = false,
}) => {
  return (
    <div className="rounded-xl soft-shadow box-border !h-[236px] ">
      <div
        className={`center py-[20px] px-[16px] rounded-t-xl `}
        style={{ background: `${data?.bg_color}80` }}
      >
        <Badge
          label={data?.branch_name}
          backgroundColor={`${data?.bg_color}`}
          color={data?.text_color}
          customClass="w-[115px] h-[26px] !uppercase"
        />
      </div>
      <div className="flex flex-column justify-between items-center px-[20px] py-[16px] bg-[#fff] border-b-[1px]">
        {data?.is_edit_fee ? (
          <div className="w-full relative my-[8px]">
            <InputsCurrency
              id="test"
              placeholder="Masukkan Harga"
              name="fee"
              value={data?.fee}
              onChange={(e) => handleChangeRow(e, "fee", index)}
            />
            <Buttons
              text="Simpan"
              customClass="btn-primary btn-small !w-fit absolute top-2 right-2"
              loading={isLoadingAction}
              onClick={() => updatedPaketFee(data)}
            />
          </div>
        ) : (
          <>
            <div className="font-semibold text-[16px]">
              Rp {toCurrencyFormat(data?.fee)}
            </div>
            <img
              src={IconEdit}
              alt="edit"
              width={24}
              height={24}
              className="cursor-pointer"
              onClick={() => handleEditFee("is_edit_fee", index)}
            />
          </>
        )}
      </div>
      <div className=" p-[16px] bg-[#fafafa] rounded-b-xl ">
        <div className="p-[0px]">
          <div className="text-[#4F4F4F] font-semibold">Requiring Fee</div>
          <div className="flex flex-column justify-between items-center gap-[32px] mt-[16px]">
            {data?.is_edit_fee_requiring ? (
              <>
                <div className="w-full relative my-[8px]">
                  <InputsCurrency
                    placeholder="Masukkan harga..."
                    customClass="!py-3"
                    value={data?.requiring_fee}
                    onChange={(e) => handleChangeRow(e, "requiring_fee", index)}
                  />
                  <Buttons
                    text="Simpan"
                    loading={isLoadingAction}
                    customClass="btn-primary !w-fit !font-extrabold absolute top-2 right-2 btn-small"
                    onClick={() => {
                      updatedPaketFee(data);
                    }}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-row gap-[8px] mt-[8px]">
                  {data.requiring_fee ? (
                    <h2 className="txt-green font-semibold">
                      Rp {toCurrencyFormat(data.requiring_fee)}
                    </h2>
                  ) : (
                    "-"
                  )}
                  {data?.requiring_fee_active === 1 && (
                    <div
                      className="cursor-pointer"
                      onClick={() =>
                        handleEditFee("is_edit_fee_requiring", index)
                      }
                    >
                      <img
                        src={IconEdit}
                        alt="edit"
                        className="w-[24px] h-[24px]"
                      />
                    </div>
                  )}
                </div>
                <div>
                  <Switch
                    customClass="w-[52px] h-[20px]"
                    checked={data?.requiring_fee_active === 1 ? true : false}
                    onChange={(e) => {
                      updatedPaketFee({
                        ...data,
                        requiring_fee_active:
                          data?.requiring_fee_active === 1 ? 0 : 1,
                      });
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardContent;
