import React, { useState } from "react";

import Buttons from "components/buttons";
import MoslemeTable from "components/Table";
import Badge from "components/badge";

import IconCSV from "assets/icons/CSV.svg";
import AvatarRed from "assets/images/Avatar-ikhwan-red.svg";
import IconSingle from "assets/icons/Single.svg";
import IconDouble from "assets/icons/Double.svg";
import IconChecklist from "assets/icons/Checklist-Round.svg";
import IconCloseRed from "assets/icons/icon-close-maroon.svg";
import { DocumentCopy, Edit, Eye, Trash } from "iconsax-react";

const listCabang = [
  "all",
  "yogyakarta",
  "solo",
  "surabaya",
  "jakarta",
  "palembang",
  "jambi",
  "pekanbaru",
  "medan",
  "aceh",
  "lubuklinggau",
  "makassar",
  "gorontalo",
  "tarakan",
  "banjarmasin",
  "balikpapan",
];

const columns = [
  {
    title: "Foto",
    dataIndex: "photo",
    render: (img) => <img src={img} alt="user" />,
  },
  {
    title: "Nama Sesuai Passport",
    dataIndex: "name",
  },
  {
    title: "Jenis Kelamin",
    dataIndex: "gender",
  },
  {
    title: "Nomor HP",
    dataIndex: "phone",
  },
  {
    title: "Roomlist",
    dataIndex: "roomlist",
    render: (room) => (
      <div className="yellow-bg rounded-xl px-[10px] py-[6px] flex flex-row justify-between items-center gap-2">
        <img src={IconDouble} alt="Double" />
        <div>Double</div>
        <div className="flex justify-center px-2 py-1 items-center rounded-full bg-white border-[1px] border-[#F90601] w-[20px] h-[20px]">
          <div className="txt-maroon text-[12px] font-medium">3</div>
        </div>
      </div>
    ),
  },
  {
    title: "Starting",
    dataIndex: "starting",
    render: (starting) => <Badge label={starting} />,
  },
  {
    title: "Cabang",
    dataIndex: "branch",
    render: (branch) => (
      <div>
        <Badge label={branch} />
        <div className="action">
          <Eye onClick={() => alert(`show id:`)} size="15" color="#141414" />
          <Trash
            onClick={() => alert(`delete id:`)}
            size="15"
            color="#141414"
          />
        </div>
      </div>
    ),
  },

  {
    title: "Absensi Manasik",
    dataIndex: "attendance",
    render: () => (
      <div>
        <div className="flex flex-row items-center gap-2">
          <div className="bg-[#F3CFCE] rounded-lg px-4 py-1 relative">
            <div className="txt-maroon font-medium">1</div>
            <img
              className="absolute top-[-6px] right-[-6px]"
              src={IconChecklist}
              alt="checklist"
            />
          </div>
          <div className="bg-[#F3CFCE] rounded-lg px-4 py-1 relative">
            <div className="txt-maroon font-medium">2</div>
            <img
              className="absolute top-[-6px] right-[-6px]"
              src={IconCloseRed}
              alt="checklist"
            />
          </div>
        </div>
        <div className="action">
          <DocumentCopy
            onClick={() => alert(`copy id:`)}
            size="15"
            color="#141414"
          />
          <Edit onClick={() => alert(`edit id:`)} size="15" color="#141414" />
        </div>
      </div>
    ),
  },
];

const data = [
  {
    key: "1",
    photo: AvatarRed,
    name: "Dimas Andreyan Prana Putra",
    gender: "Laki-laki",
    phone: "0878 5354 3388",
    roomlist: "Double",
    starting: "YIA - CGK",
    branch: "Yogyakarta",
    attendance: "12",
  },
  {
    key: "2",
    photo: AvatarRed,
    name: "Dimas Andreyan Prana Putra",
    gender: "Laki-laki",
    phone: "0878 5354 3388",
    roomlist: "Double",
    starting: "YIA - CGK",
    branch: "Yogyakarta",
    attendance: "12",
  },
  {
    key: "3",
    photo: AvatarRed,
    name: "Dimas Andreyan Prana Putra",
    gender: "Laki-laki",
    phone: "0878 5354 3388",
    roomlist: "Double",
    starting: "YIA - CGK",
    branch: "Yogyakarta",
    attendance: "12",
  },
];

const Absensi = () => {
  const [activeFilter, setActiveFilter] = useState("all");
  // Loading
  const [isLoading, setIsLoading] = useState(false);
  // Expaneble
  const [isExpand, setIsExpand] = useState(false);
  // Selection & type state
  const [selectionType, setSelectionType] = useState("checkbox");
  const [withSelection, setWithSelection] = useState(false);

  // Pagination state
  const [currentpage, setCurrentpage] = useState(1);
  const [size, setSize] = useState(10);
  const total = 100;

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: total,
    current: currentpage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20"],
  };

  // func to set size pagination
  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setSize(pageSize);
  };

  // if have expaneble, this is the props example..
  const expandable = {
    expandedRowRender: (record) => <p style={{ margin: 0 }}>{record.name}</p>,
    rowExpandable: (record) => record.name !== "Not Expandable",
  };
  // manipulated data with action
  const sourceData = data;

  const getFilterCabang = () => {
    return listCabang.map((val, idx) => {
      return (
        <div
          key={idx}
          className={`border-[1px] border-[#4048521a] rounded-lg px-4 py-1 cursor-pointer uppercase font-semibold ${
            activeFilter === val && "bg-[#F3CFCE]"
          }`}
          onClick={() => setActiveFilter(val)}
        >
          <div className={`${activeFilter === val && "txt-maroon"}`}>{val}</div>
        </div>
      );
    });
  };

  return (
    <div className="p-4">
      <div className="p-4 border-[1px] border-[#4048521a] rounded-lg">
        <div className="font-medium">Filter Cabang</div>
        <div className="flex flex-row flex-wrap gap-4 mt-4">
          {getFilterCabang()}
        </div>
      </div>
      <div className="flex flex-row justify-between items-center mt-4">
        <div className="font-semibold text-[16px]">Daftar Jamaah</div>
        <Buttons
          text="Export CSV"
          customClass="btn-outline btn-small"
          icon={IconCSV}
        />
      </div>
      <div className="mt-6">
        <MoslemeTable
          loading={isLoading}
          expandable={isExpand && expandable}
          columns={columns}
          data={sourceData}
          withSelection={withSelection}
          selectionType={selectionType}
          pagination={pagination}
          onPageChange={(page) => setCurrentpage(page)}
          onSizeChange={(currentpage, pageSize) =>
            onSizeChange(currentpage, pageSize)
          }
        />
      </div>
    </div>
  );
};

export default React.memo(Absensi);
