import React, { useState } from "react";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import "react-quill/dist/quill.snow.css";
import "./styles.css";

const RichTextEditor = ({ className, data, onChange, label, placeholder }) => {
  const useStyles = makeStyles({
    textArea: {
      "& .ql-container.ql-snow": {
        backgroundColor: "#EDF2F4",
        width: "100%",
        height: "376px",
      },
    },
  });
  const classes = useStyles();

  console.log(data);

  const [state, setState] = useState({ value: null });
  const handleChange = (value) => {
    setState({ value });
  };
  return (
    <>
      <h1 className={`font-semibold text-[#000000] mb-2 ${className}`}>
        {label}
      </h1>
      <div className={`text-editor ${className}`}>
        <EditorToolbar />
        <ReactQuill
          theme="snow"
          value={data.value}
          onChange={onChange}
          modules={modules}
          formats={formats}
          placeholder={placeholder}
          className={`${classes.textArea}`}
        />
      </div>
    </>
  );
};

RichTextEditor.propTypes = {
  data: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};
RichTextEditor.defaultProps = {
  data: { value: null },
  onChange: (e) => {},
  label: "Deskripsi",
  placeholder: "Masukkan deskripsi",
};
export default RichTextEditor;
