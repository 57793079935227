import axios from "axios";
import { BASE_URL_ENDPOINT } from "constant/constanta";

export const getRekeningApi = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/get-rekening`, payload);

export const getIdRekeningApi = (id) =>
  axios.get(`${BASE_URL_ENDPOINT}/admin/rekening/${id}`);

export const deleteIdRekeningApi = (id) =>
  axios.delete(`${BASE_URL_ENDPOINT}/admin/rekening/${id}`);

export const createOrUpdateRekeningApi = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/save-rekening`, payload);

export const duplicateRekeningApi = (payload) =>
  axios.post(`${BASE_URL_ENDPOINT}/admin/rekening/duplicate`, payload);
