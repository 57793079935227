import axios from "axios";
import { BASE_URL_ENDPOINT } from "constant/constanta";

// get doa list
export const getDoaService = (payload) =>
    axios.post(`${BASE_URL_ENDPOINT}/admin/get-doa`, payload);

// get kategori doa list
export const getDoaCategoryService = (payload) =>
    axios.post(`${BASE_URL_ENDPOINT}/admin/get-kategori-doa`, payload);

// add doa
export const addDoaService = (payload) =>
    axios.post(`${BASE_URL_ENDPOINT}/admin/save-doa`, payload);

export const deleteDoaService = (payload) =>
    axios.delete(`${BASE_URL_ENDPOINT}/admin/doa/${payload}`);
