import React, { useState } from "react";
import PropTypes from "prop-types";

import Countdown from "react-countdown";
import Cards from "../../../components/cards";
import InputOTP from "../../../components/form/inputOTP";

import IconLeftArrow from "../../../assets/icons/Left-Arrow.svg";

const CardOTP = ({
  values,
  otp,
  setOtpPage,
  setForgotPage,
  setOtp,
  forgotPasswordByEmail,
  checkPhoneNumberOTPbyPhone,
  telephone,
}) => {
  const [getOTP, setGetOTP] = useState(false);

  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      setGetOTP(true);
    } else {
      // Render a countdown
      return (
        <div className="flex mt-6 text-[#828282]">
          Tunggu {seconds} detik untuk mengirim ulang kode OTP
        </div>
      );
    }
  };

  return (
    <Cards containerClass="w-full">
      <div className="flex flex-row items-center">
        <img
          className="cursor-pointer"
          src={IconLeftArrow}
          onClick={() => {
            setOtpPage(false);
            setForgotPage(true);
          }}
          alt="left-arrow"
        />
        <h3 className="card-login-title ml-1">Masukan Kode OTP</h3>
      </div>
      <h5 className="card-login-desc">
        Masukkan 4 digit kode OTP yang kami kirim ke No Telepon{" "}
        {values.forgotEmail}
      </h5>
      <InputOTP containerClass="mt-6" value={otp} onChange={(e) => setOtp(e)} />
      {getOTP ? (
        <div className="flex mt-3 text-[#828282]">
          Tidak menerima kode?
          <span
            className="text-[#E82320] cursor-pointer"
            onClick={() => {
              if (telephone !== "") {
                setOtp("");

                checkPhoneNumberOTPbyPhone({
                  phone_number: telephone,
                });

                setGetOTP(false);
              } else {
                setOtp("");

                forgotPasswordByEmail({
                  account: values.forgotEmail,
                });
                setGetOTP(false);
              }
            }}
          >
            &nbsp;Kirim Ulang
          </span>
        </div>
      ) : (
        <Countdown date={Date.now() + 59000} renderer={renderer} />
      )}
    </Cards>
  );
};

CardOTP.propTypes = {
  values: PropTypes.object,
  otp: PropTypes.string,
  setOtpPage: PropTypes.func,
  setForgotPage: PropTypes.func,
  setOtp: PropTypes.func,
};

export default React.memo(CardOTP);
