import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import GalleryAction from "stores/actions/apps/gallery";
import { resetDataUpload } from "stores/actions/uploadFile";

import Buttons from "components/buttons";
import Tab from "components/tab";
import AddGaleriUpload from "./components/Upload";
import AddGaleriIbadahku from "./components/Ibadahku";

const AppsAddGaleri = ({ setOpenPopup }) => {
  const dispatch = useDispatch();

  const formikAddAppsGalery = useFormik({
    initialValues: {
      image: "",
    },
    validationSchema: Yup.object().shape({}),
    onSubmit: async (values, actions) => {
      if (Object.keys(values).length !== 0) {
        const status = await dispatch(
          GalleryAction.postData({
            file: [values.image],
          })
        );

        if (status === 200) {
          setOpenPopup(false);
          actions.resetForm();
          dispatch(resetDataUpload());
        }
      } else {
        actions.setSubmitting(false);
        actions.setErrors({
          image: "Gambar Wajib dipilih.",
        });
      }
    },
  });

  const formikAddAppsGaleryIbadahku = useFormik({
    initialValues: {
      images: [],
    },
    validationSchema: Yup.object().shape({}),
    onSubmit: (val) => {},
  });

  return (
    <div className="bg-white border-[1px] border-[#E0E0E0] rounded-2xl">
      <div className="px-4 py-6">
        <Tab
          defaultActiveKey="1"
          items={[
            {
              label: "Upload",
              key: "1",
              children: (
                <AddGaleriUpload
                  setFieldValue={formikAddAppsGalery.setFieldValue}
                  value={formikAddAppsGalery.values}
                  errors={formikAddAppsGalery.errors}
                />
              ),
            },
            {
              label: "Pilih Dari Ibadahku",
              key: "2",
              children: (
                <AddGaleriIbadahku
                  setFieldValue={formikAddAppsGaleryIbadahku.setFieldValue}
                />
              ),
            },
          ]}
        />
      </div>
      <div className="divider"></div>
      <div className="flex flex-row justify-end gap-2 p-4">
        <Buttons
          text="Kembali"
          customClass="btn-outline btn-medium !w-fit"
          onClick={() => setOpenPopup(false)}
        />
        <Buttons
          text="Tambah"
          loading={formikAddAppsGalery.isSubmitting}
          customClass={`btn-primary btn-medium !w-fit ${
            formikAddAppsGalery.isSubmitting && "loading"
          }`}
          onClick={() => formikAddAppsGalery.handleSubmit()}
        />
      </div>
    </div>
  );
};

export default React.memo(AppsAddGaleri);
