import React, { useState, useCallback, useEffect } from "react";
import { Grid } from "@material-ui/core";
import useDebounce from "../../../utils/helpers/useDebounce";

import Cards from "../../../components/cards";
import Inputs from "../../../components/form/inputs";
import Buttons from "../../../components/buttons";
import MoslemeTable from "../../../components/Table";
import Badge from "../../../components/badge";
import AlertPopup from "../../../components/popup/alert/";
import SelectPermissionDropdown from "./SelectPermissionDropdown";
import AddRolePopup from "./AddRolePopup";
import AddEditRolePopup from "./AddEditRolePopup";

import { Eye, Trash, DocumentCopy, Edit } from "iconsax-react";
import IconSearch from "../../../assets/icons/search-normal.svg";
import IconSquare from "../../../assets/icons/send-square.svg";
import IconActivity from "../../../assets/icons/activity.svg";
import AvatarRed from "../../../assets/images/Avatar-ikhwan-red.svg";
import AddRoleIcon from "../../../assets/icons/add-role-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteDataRole,
  duplicatedDataRoleV2,
  getDataDetailRoleV2,
  getDataPermissionV2,
  getDataRole,
  getDataRoleV2,
  getDataRoleV2Utama,
  setDataDetailRoleV2,
} from "../../../stores/actions/role";
import PermissionDropdownOnSelect from "./PermissionDropdownOnSelect";
import { handleSuccess } from "stores/actions/errorGeneral";
import { getLocalStorage } from "utils/helpers";

const CardRoleTable = () => {
  const dispatch = useDispatch();
  const { dataRole, isLoading, isLoadingData, dataRoleV2, dataPermission } = useSelector(
    (state) => state.role
  );

  const [data, setData] = useState([
    {
      key: "1",
      role: {
        tags: "Admin Pusat",
        color: "#6C757D",
        backgroundColor: "#CED4DA",
      },
      permission: {
        total: 10,
      },
      deskripsi: "Description",
      action: "",
    },
  ]);

  const userLogin =
    getLocalStorage("userLogin") && JSON.parse(getLocalStorage("userLogin"));
  const permissions = userLogin?.data?.account?.permissions;

  // columns data configuration
  const columns = [
    {
      title: "Role",
      dataIndex: "role",
      render: (_, role) => {
        return (
          <>
            <div className="flex flex-row justify-start">
              <Badge
                label={role?.display_name}
                color={role?.text_color}
                backgroundColor={role?.bg_color}
                customClass="ml-1 font-bold py-[6px] px-[16px]"
              />
            </div>
          </>
        );
      },
    },
    {
      title: permissions?.includes("update_role") ? "Permission" : " ",
      dataIndex: "action",
      render: (_, data) => (
        <>
          {permissions?.includes("update_role") && (
            <div className="flex flex-row">
              <PermissionDropdownOnSelect
                selectedRole={dataPermission?.data}
                data={data}
              />
            </div>
          )}
        </>
      ),
    },
    {
      title: "Deskripsi",
      dataIndex: "description",
      className: "whitespace-nowrap",
      render: (s, data) => {
        return (
          <div className="!w-full relative">
            <div className="font-normal text-[12px] text-[#141414]">
              {" "}
              {s !== null && s?.length > 150 ? `${s?.substr(0, 150)}...` : s}
            </div>

            <div className="action absolute !w-full top-2 flex !flex-row !justify-end bg-red-500">
              <div className="flex flex-row justify-end pr-[16px] gap-x-[32px]">
                {permissions?.includes("delete_role") && !data?.is_main && (
                  <button className="hover:bg-red-100 duration-500 rounded-full h-[16px] w-[16px] flex justify-center">
                    <Trash
                      size="16"
                      className="self-center"
                      onClick={() => handleDeleteButton(data)}
                    />
                  </button>
                )}
                {permissions?.includes("update_role") &&
                permissions?.includes("create_role") &&
                !data?.is_main ? (
                  <button
                    className="hover:bg-red-100 duration-500 rounded-full h-[16px] w-[16px] flex justify-center"
                    onClick={() => {
                      setOpenModalDuplicated(true);
                      setSelectedRole(data);
                    }}
                  >
                    <DocumentCopy size="16" className="self-center" />
                  </button>
                ) : (
                  <></>
                )}
                {permissions?.includes("update_role") && !data?.is_main && (
                  <button
                    className="hover:bg-red-100 duration-500 rounded-full h-[16px] w-[16px] flex justify-center"
                    onClick={() => handleEditButton(data)}
                  >
                    <Edit size="16" className="self-center" />
                  </button>
                )}
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  // selected role state
  const [selectedRole, setSelectedRole] = useState(null);

  // search state
  const [searchValue, setSearchValue] = useState("");
  const searchDebounce = useDebounce(searchValue, 1000);

  // Selection & type state
  const [selectionType, setSelectionType] = useState("checkbox");

  // Pagination state
  const [currentPage, setCurrentpage] = useState(1);
  const [size, setSize] = useState(10);

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: dataRoleV2?.data?.total,
    current: currentPage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20", "50", "100"],
  };

  // modal delete confirmation state
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalDuplicated, setOpenModalDuplicated] = useState(false);

  const handleOpenModalDelete = useCallback(() => {
    setTimeout(() => {
      setOpenModalDelete(true);
    }, [100]);
  }, []);
  const handleCloseModalDelete = useCallback(() => {
    setTimeout(() => {
      setSelectedRole(null);
    }, [1000]);
    setOpenModalDelete(false);
  }, []);

  const handleCloseModalDuplicated = useCallback(() => {
    setSelectedRole(null);
    setOpenModalDuplicated(false);
  }, []);
  const handleDeleteButton = (role) => {
    setSelectedRole(role);
    handleOpenModalDelete();
  };
  const handleConfirmDelete = async () => {
    const payload = {
      name: selectedRole.name,
    };

    dispatch(
      deleteDataRole(payload, (message) => {
        handleCloseModalDelete();
        const payloadV2 = {
          main: null,
          page: currentPage,
          offset: 10,
          pagination: true,
          keyword: "",
        };

        dispatch(getDataRoleV2(payloadV2));
        dispatch(handleSuccess(message));
      })
    );
  };
  const handleDuplicatedConform = () => {
    const payload = {
      name: selectedRole.name,
    };
    dispatch(
      duplicatedDataRoleV2(
        payload,
        (message) => {
          dispatch(getDataRoleV2(payload));
          dispatch(handleSuccess(message));
          handleCloseModalDuplicated();
        },
        () => {
          handleCloseModalDuplicated();
        }
      )
    );
  };

  // add/edit role modal state
  const [openAddRolePopup, setOpenAddRolePopup] = useState(false);
  const [openAddEditRolePopup, setOpenAddEditRolePopup] = useState(false);

  const handleCloseAddRolePopup = useCallback(() => {
    setOpenAddRolePopup(false);
  }, []);
  const handleOpenAddRolePopup = useCallback(() => {
    setOpenAddRolePopup(true);
  }, []);
  const handleOpenAddEditRolePopup = useCallback(() => {
    handleCloseAddRolePopup();
    setOpenAddEditRolePopup(true);
  }, []);
  const handleCloseAddEditRolePopup = useCallback((state) => {
    setSelectedRole(null);
    setOpenAddEditRolePopup(false);
  }, []);

  // table action
  const handleEditButton = (role) => {
    handleOpenAddEditRolePopup();
    setSelectedRole(role);

    dispatch(getDataDetailRoleV2({ name: role.name }));
  };

  // func to set size pagination
  const onSizeChange = (currentPage, pageSize) => {
    setCurrentpage(currentPage);
    setSize(pageSize);
  };

  useEffect(() => {
    const payloadV2 = {
      main: null,
      page: currentPage,
      offset: size,
      pagination: true,
      keyword: searchValue,
    };
    dispatch(getDataPermissionV2());

    dispatch(getDataRoleV2(payloadV2));
  }, [currentPage, dispatch, searchDebounce, searchValue, size]);

  useEffect(() => {
    const payloadV2 = {
      main: 1,
      page: 1,
      offset: 10,
      pagination: false,
      keyword: "",
    };

    dispatch(getDataRoleV2Utama(payloadV2));
  }, [dispatch]);

  return (
    <Cards containerClass="card-table !w-full">
      {/* Table Header */}
      <div className="card-table-header border-b-[1px] border-[#E0E0E0]">
        <Grid container spacing={2}>
          <Grid item md={4}>
            <Inputs
              id="test"
              placeholder="Search"
              customClass="!w-[245px] !h-[44px]"
              iconPosition="left"
              icon={IconSearch}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </Grid>
          <Grid item md={8} className="flex justify-end items-center">
            {permissions.includes("create_role") && (
              <Buttons
                text="Tambah Role"
                customClass="btn-primary btn-small !w-fit"
                icon={AddRoleIcon}
                onClick={() => {
                  dispatch(setDataDetailRoleV2({}));
                  handleOpenAddEditRolePopup();
                }}
              />
            )}
            <img
              width={16}
              height={16}
              className="ml-4 cursor-pointer"
              src={IconSquare}
              alt="square"
            />
            <img
              width={16}
              height={16}
              className="ml-4 cursor-pointer"
              src={IconActivity}
              alt="activity"
            />
          </Grid>
        </Grid>
      </div>

      {/* Table */}
      <MoslemeTable
        columns={columns}
        data={dataRoleV2?.data?.data?.length > 0 ? dataRoleV2?.data?.data : []}
        rowKey={(record) => record.id}
        withSelection={true}
        selectionType={selectionType}
        pagination={pagination}
        customPaginationClass="pr-4"
        onPageChange={(page) => setCurrentpage(page)}
        onSizeChange={(currentPage, pageSize, offset) =>
          onSizeChange(currentPage, pageSize, offset)
        }
        loading={isLoadingData}
        customClass="table-role"
      />

      {/* AlertPopup */}
      <AlertPopup
        open={openModalDelete}
        handleClose={handleCloseModalDelete}
        handleContinue={handleConfirmDelete}
        title="Hapus Role?"
        subtitle={`Apakah kamu yakin ingin menghapus role “${selectedRole?.display_name}”?`}
        loading={isLoading}
      />

      {/* AlertPopup Duplicate */}
      <AlertPopup
        open={openModalDuplicated}
        handleClose={handleCloseModalDuplicated}
        handleContinue={handleDuplicatedConform}
        title="Duplicated Role?"
        subtitle={`Apakah kamu yakin ingin menduplicate role “${selectedRole?.display_name}”?`}
        loading={isLoading}
        text_accept="Duplicate"
      />

      {/* Role List & Add RolePopup */}
      <AddRolePopup
        open={openAddRolePopup}
        handleClosePopup={handleCloseAddRolePopup}
        handleOpenPopup={handleOpenAddRolePopup}
        handleCloseSecondPopup={handleCloseAddEditRolePopup}
        handleOpenSecondPopup={handleOpenAddEditRolePopup}
        roleList={data}
        selectedRole={selectedRole}
        setSelectedRole={setSelectedRole}
      />

      <AddEditRolePopup
        open={openAddEditRolePopup}
        handleClosePopup={handleCloseAddEditRolePopup}
        handleOpenPopup={handleOpenAddEditRolePopup}
        selectedRole={selectedRole}
      />
    </Cards>
  );
};

export default CardRoleTable;
