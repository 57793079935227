import React, { useCallback, useState } from "react";
import { Grid } from "@material-ui/core";

import SidebarChild from "components/template_layout/Siderbar/SidebarChild";

import IconGalery from "assets/icons/Galery.svg";
import IconRating from "assets/icons/Rating.svg";
import IconVisa from "assets/icons/Visa.svg";
import Album from "./components/Album";
import Buttons from "components/buttons";
import Rating from "./components/Rating";
import Visa from "./components/Visa";

export const menuSidebar = [
  {
    title: "Album",
    key: "album",
    icon: IconGalery,
    order: 0,
  },
  {
    title: "Rating",
    key: "rating",
    icon: IconRating,
    order: 1,
  },
  {
    title: "E-visa",
    key: "e-visa",
    icon: IconVisa,
    order: 2,
  },
];

const Ibadahku = () => {
  const [showContent, setShowContent] = useState("manasik");

  const getContent = useCallback(() => {
    switch (showContent) {
      case "album":
        return <Album />;
      case "rating":
        return <Rating />;
      case "e-visa":
        return <Visa />;
      default:
        return <Album />;
    }
  }, [showContent]);

  return (
    <>
      <div className="flex flex-row justify-end items-center p-[16px] gap-[8px] border-b">
        <Buttons
          text="Simpan"
          customClass="btn-primary btn-medium !w-[52px] !h-[42px]"
          containerClass=""
        />
      </div>
      <Grid container spacing={2}>
        <Grid item md={2}>
          <SidebarChild
            containerClass="!rounded-none border-[1px] border-[#4048521a] !relative !w-full !left-0"
            menuList={menuSidebar}
            handleClick={setShowContent}
            path="/paket"
            showContent={showContent}
          />
        </Grid>
        <Grid item md={10}>
          {getContent()}
        </Grid>
      </Grid>
    </>
  );
};

export default React.memo(Ibadahku);
