import { handleError, handleErrorBE } from "stores/actions/errorGeneral";
import debounce from "debounce-promise";
import {
  CLEAR_ERROR,
  ERROR_FETCHING_MITRA,
  SET_ADD_OR_EDIT_MITRA,
  SET_DELETE_MITRA,
  SET_KEYWORD_MITRA,
  SET_LOADING,
  SET_PAGE_MITRA,
  SET_SIZE_MITRA,
  SET_TOTAL_MITRA,
  START_FETCHING_MITRA,
  SUCCESS_FETCHING_MITRA,
} from "./actionTypes";
import { AppsMitraAPI } from "utils/api/apps/mitra";
let debounceGetAdmin = debounce(AppsMitraAPI.getList, 1500);

const startFetchingMitra = () => ({
  type: START_FETCHING_MITRA,
});

const errorFetchingMitra = () => ({
  type: ERROR_FETCHING_MITRA,
});

const successFetchingMitra = (payload) => ({
  type: SUCCESS_FETCHING_MITRA,
  payload,
});

export const setLoading = () => ({
  type: SET_LOADING,
});

export const clearError = () => ({
  type: CLEAR_ERROR,
});

const setPage = (page) => ({
  type: SET_PAGE_MITRA,
  page,
});

const setSize = (size) => ({
  type: SET_SIZE_MITRA,
  size,
});

const setTotal = (total) => ({
  type: SET_TOTAL_MITRA,
  total,
});

const setKeyword = (keyword) => ({
  type: SET_KEYWORD_MITRA,
  keyword,
});

export const setAddOrEditMitra = (payload, edit) => ({
  type: SET_ADD_OR_EDIT_MITRA,
  payload,
  edit,
});

export const setDeleteMitra = (payload, id) => ({
  type: SET_DELETE_MITRA,
  payload,
  id,
});

const handleFetchListMitra = (payload) => {
  return async (dispatch, getState) => {
    dispatch(startFetchingMitra());
    try {
      let per_page = getState().mitra.per_page;
      let page_number = getState().mitra.page_number;
      let sort_column = getState().mitra.sort_column;
      let sort_order = getState().mitra.sort_order;
      let keyword = getState().mitra.keyword;
      const container = {
        per_page,
        page_number,
        sort_column,
        sort_order,
        keyword,
      };

      let res = await debounceGetAdmin(container);
      if (res.data.code === 401) {
        window.location.href = "/login";
      }
      let data = res.data.data === "" ? [] : res.data.data;
      dispatch(successFetchingMitra(data));
      dispatch(setTotal(res.data.total_data));
      dispatch(setPage(res.data.page));
    } catch (error) {
      console.log("error: ", error.response);
      dispatch(errorFetchingMitra());
    }
  };
};

const handleAddOrEditMitra = (payload) => {
  return async (dispatch, getState) => {
    let edit = payload.edit ? true : false;
    try {
      dispatch(clearError());
      dispatch(setLoading(true));
      const response = await AppsMitraAPI.postData(payload);
      dispatch(setLoading(false));
      if (response.status === 200) {
        dispatch(setAddOrEditMitra(response.data, edit));
        return response.status;
      } else {
        dispatch(handleErrorBE(response));
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

const handleDeleteMitra = (payload) => {
  return async (dispatch, getState) => {
    try {
      dispatch(clearError());
      dispatch(setLoading(true));
      const response = await AppsMitraAPI.deleteData(payload);
      dispatch(setLoading(false));
      if (response.status === 200) {
        dispatch(setDeleteMitra(response.data, payload));
        return response.status;
      } else {
        dispatch(handleErrorBE(response));
      }
    } catch (error) {
      dispatch(handleError(error));
    }
  };
};

const AppsMitraAction = {
  fetchData: handleFetchListMitra,
  postData: handleAddOrEditMitra,
  deleteData: handleDeleteMitra,
  setPage: setPage,
  setSize: setSize,
};

export default AppsMitraAction;
