import React, { useEffect, useState } from "react";
import MoslemeTable from "../../../../../components/Table";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteDataBandara,
  duplicateDataBandara,
  getAllDataBandara,
} from "../../../../../stores/actions/datamaster";
import { columns } from "./column";
import useDebounce from "../../../../../utils/helpers/useDebounce";
import AlertPopup from "../../../../../components/popup/alert";
import Grid from "@mui/material/Grid";
import Buttons from "../../../../../components/buttons";
import Popup from "../../../../../components/popup";
import Inputs from "../../../../../components/form/inputs";
import CheckboxMaster from "../../checkbox";
import { useFormik } from "formik";

function Bandara({ filter, openModal }) {
  // Selection & type state
  const [selectionType, setSelectionType] = useState("checkbox");
  const [open, setOpen] = useState(false);
  const [actionHandle, setActionHandle] = useState();
  const [isModal, setIsModal] = useState(false);
  const [checkName, setCheckName] = useState("");

  const formik = useFormik({
    initialValues: {
      id: "",
      name: "",
      logo: true,
      domestic_code: true,
      map: true,
      description: true,
    },
  });

  // data table
  const { dataBandara, status } = useSelector((state) => state.datamaster);
  let dataTable = dataBandara.data;
  const dispatch = useDispatch();

  // Handle action table
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleContinue = () => {
    dispatch(deleteDataBandara(actionHandle));
    setOpen(false);
  };

  // Pagination state
  const [currentpage, setCurrentpage] = useState(1);
  const [size, setSize] = useState(10);
  const total = dataBandara.total_data;

  // filter data table
  const emailDebounce = useDebounce(filter, 1000);

  useEffect(() => {
    dispatch(
      getAllDataBandara({
        page_number: currentpage,
        per_page: size,
        keyword: filter,
        sort_column: "",
        sort_order: "DESC",
      })
    );
  }, [emailDebounce, size, currentpage, dispatch, filter]);

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: total,
    current: currentpage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20", "50", "100"],
  };

  // func to set size pagination
  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setSize(pageSize);
  };

  // submit form
  const handleSave = () => {
    dispatch(
      duplicateDataBandara({
        ...formik.values,
        logo: formik.values.logo === true ? 1 : 0,
        domestic_code: formik.values.domestic_code === true ? 1 : 0,
        map: formik.values.map === true ? 1 : 0,
        description: formik.values.description === true ? 1 : 0,
      })
    );
    setIsModal(false);
  };

  return (
    <div className="mt-10">
      <AlertPopup
        open={open}
        handleClose={handleClose}
        handleContinue={handleContinue}
      />

      <Popup
        type="primary"
        title="Duplicate Maskapai"
        open={isModal}
        handleClose={() => setIsModal(false)}
      >
        <Inputs
          id="name"
          name="name"
          placeholder="Text Input"
          defaultValue={formik.values.name}
          onChange={formik.handleChange}
        />
        {formik.values.name === checkName && (
          <p className="my-1 text-red-500"> Masukan nama yang berbeda</p>
        )}

        <div className="my-4">
          <CheckboxMaster
            title="Logo"
            checked={formik.values.logo === true}
            onChange={(e) => {
              formik.setFieldValue("logo", !formik.values.logo);
            }}
          />
        </div>
        <div className="my-4">
          <CheckboxMaster
            title="Map"
            checked={formik.values.map === true}
            onChange={(e) => {
              formik.setFieldValue("map", !formik.values.map);
            }}
          />
        </div>
        <div className="my-4">
          <CheckboxMaster
            title="Kode Domestik"
            checked={formik.values.domestic_code === true}
            onChange={(e) => {
              formik.setFieldValue(
                "domestic_code",
                !formik.values.domestic_code
              );
            }}
          />
        </div>
        <div className="my-4">
          <CheckboxMaster
            title="Deskripsi"
            checked={formik.values.description === true}
            onChange={(e) => {
              formik.setFieldValue("description", !formik.values.description);
            }}
          />
        </div>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item sm={12} md={6}>
            <Buttons
              onClick={() => setIsModal(false)}
              text="Batal"
              customClass="btn-secondary btn-large"
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <Buttons
              onClick={handleSave}
              disabled={formik.values.name === checkName ? true : false}
              text="Simpan"
              customClass={
                formik.values.name === checkName
                  ? "btn-primary disabled btn-large"
                  : "btn-primary  btn-large"
              }
            />
          </Grid>
        </Grid>
      </Popup>

      <MoslemeTable
        columns={columns(
          handleOpen,
          setActionHandle,
          setIsModal,
          openModal,
          dispatch,
          formik.setFieldValue,
          setCheckName
        )}
        loading={status === "process"}
        data={dataTable}
        rowKey={(record) => record.id_bandara}
        withSelection={true}
        selectionType={selectionType}
        customPaginationClass="pr-4"
        pagination={pagination}
        onPageChange={(page) => setCurrentpage(page)}
        onSizeChange={(currentpage, pageSize) =>
          onSizeChange(currentpage, pageSize)
        }
      />
    </div>
  );
}

export default Bandara;
