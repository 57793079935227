import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";

import Sliders from "components/slider";
import GeneralInfo from "./components/Generalinfo";
import TipeKamar from "./pages/tipe_kamar";
import PaketPenerbangan from "./components/Penerbangan";
import Badge from "components/badge";
import PaketTagihan from "./components/Tagihan";
import Fee from "./pages/Fee";
import Coupon from "./components/Coupon";
import Akomodasi from "./components/Akomodasi";
import Additional from "./components/Additional";
import Laporan from "./components/Laporan";
import Perlengkapan from "./pages/Perlengkapan";
import Dokumen from "./pages/Dokumen";
import Manifest from "./components/Manifest";
import Blockseat from "./pages/Blockseat";
import Ibadahku from "./pages/Ibadahku";
import PaketRoomlist from "./pages/Roomlist";

import IconBack from "assets/icons/arrow-left-dark-icon.svg";
import GeneralIcon from "assets/icons/colored/General.svg";
import IconPenerbanganAktif from "assets/icons/Penerbangan-aktif.svg";
import PaketDetailStyle from "./PaketDetail.Style";
import Transportasi from "./pages/transportasi";
import Itinenary from "./pages/Itinenary";
import { IcFee } from "assets/icons/dashboard";
import IconTagihan from "assets/icons/Tagihan.svg";
import IconLaporan from "assets/icons/Laporan.svg";
import IconPerlengkapan from "assets/icons/colored/Perlengkapan.svg";
import IconDokumen from "assets/icons/Dokumen.svg";
import IconManifest from "assets/icons/Manifest.svg";
import IconBlockseat from "../../../assets/icons/Blockseat.svg";
import IconIbadahku from "../../../assets/icons/Ibadahku.svg";
import IconDelete from "assets/icons/icon-delete-maroon.svg";
import IconDuplicate from "assets/icons/icon-duplicated.svg";
import Buttons from "components/buttons";
import { useDispatch, useSelector } from "react-redux";
import { deleteAllPaket } from "stores/actions/paket/generalInfo";
import { handleSuccess } from "stores/actions/errorGeneral";
import AlertPopup from "components/popup/alert/";
import moment from "moment";

const useStyle = makeStyles({
  content: {
    boxShadow:
      "0px 1px 2px rgba(0, 0, 0, 0.08), 0px 2px 8px rgba(0, 0, 0, 0.04)",
    background: "#fff",
  },

  activeTitle: {
    background: "linear-gradient(204.44deg, #F90601 -18.49%, #8A1212 84.72%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    textFillColor: "transparent",
  },
  header: {
    background: "#FFFFFF",
    padding: "18px 16px 18px 16px",
    boxShadow:
      "0px 1px 2px rgba(0, 0, 0, 0.08), 0px 2px 8px rgba(0, 0, 0, 0.04)",
    borderRadius: "20px 20px 0px 0px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  title: {
    marginLeft: "13px",
    marginRight: "8px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "32px",
    background:
      "linear-gradient(34.99deg, #151515 17.46%, #3D3D3D 58.62%, #595959 87.32%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    backgroundClip: "text",
    textFillColor: "transparent",
  },
});

const PaketDetail = () => {
  const classes = useStyle();
  const router = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();
  const store = useSelector(({ generalInfo }) => generalInfo);
  const [clickedButton, setClickedButton] = useState("GeneralInfo");
  const [isDeletePaket, setIsDeletePaket] = useState(false);
  const [isLoadingDel, setIsloadingDel] = useState(false);

  const handleButtonClick = (buttonText) => {
    setClickedButton(buttonText);
  };

  const getContent = () => {
    switch (clickedButton) {
      case "GeneralInfo":
        return <GeneralInfo />;
      case "TipeKamar":
        return <TipeKamar />;
      case "Penerbangan":
        return <PaketPenerbangan />;
      case "Akomodasi":
        return <Akomodasi />;
      case "Transportasi":
        return <Transportasi />;
      case "Itenerary":
        return <Itinenary />;
      case "InfoTambahan":
        return <div>InfoTambahan</div>;
      case "Additional":
        return <Additional />;
      case "Coupon":
        return <Coupon />;
      case "Fee":
        return <Fee />;
      case "Tagihan":
        return <PaketTagihan />;
      case "Perlengkapan":
        return <Perlengkapan />;
      case "Dokumen":
        return <Dokumen />;
      case "Manifest":
        return <Manifest />;
      case "Roomlist":
        return <PaketRoomlist />;
      case "Laporan":
        return <Laporan />;
      case "Blockseat":
        return <Blockseat />;
      case "Ibadahku":
        return <Ibadahku />;
      default:
        return null;
    }
  };

  const handleDeleteAllPaket = () => {
    setIsloadingDel(true);
    dispatch(
      deleteAllPaket(id, (message) => {
        setIsloadingDel(false);
        setIsDeletePaket(false);
        setTimeout(() => {
          dispatch(handleSuccess(message));
        }, 1000);

        router.push("/paket");
      })
    );
  };

  const handleCloseModalDelete = () => {
    setIsDeletePaket(false);
  };

  return (
    <PaketDetailStyle>
      <div className={classes.header}>
        <div className="flex flex-row items-center">
          <img
            src={IconBack}
            alt="back button"
            className="w-[16px] h-[16px] ml-[20px] cursor-pointer"
            onClick={() => router.push("/paket")}
          />
          <h1 className={classes.title}>
            {`${store.data?.judul_paket} ${moment(
              store.data?.jadwal_keberangkatan
            ).format("DD MMMM YYYY")}`}
          </h1>
          {store.data?.status === 0 ? (
            <Badge
              style={{}}
              label={"Draft"}
              color="#564592"
              backgroundColor="#56459233"
            />
          ) : (
            <Badge
              style={{}}
              label={"Publish"}
              color="#333D29"
              backgroundColor="rgba(46, 178, 67, 0.2)"
            />
          )}
        </div>

        <div className=" flex flex-row justify-end w-[45%] py-[4px]   items-center">
          <div className="mr-[16px] cursor-pointer">
            <img src={IconDuplicate} alt="icon_duplicated" />
          </div>
          <div
            className="mr-[16px] cursor-pointer"
            onClick={() => setIsDeletePaket(true)}
          >
            <img src={IconDelete} alt="icon_delete" />
          </div>
          <Buttons text="Publish" customClass="btn-primary btn-small" />
        </div>
      </div>
      <div className="bg-white h-[full] normal-shadow">
        <div className={classes.content}>
          <div className="ml-4 p-2" style={{ width: "calc(100% - 40px)" }}>
            <Sliders
              customClass="!flex !flex-row !items-center !gap-1"
              slidesToShow={8}
            >
              <div
                className={`!flex !flex-row gap-3 cursor-pointer ${
                  clickedButton === "GeneralInfo" ? classes.activeTitle : ""
                }`}
                onClick={() => handleButtonClick("GeneralInfo")}
              >
                <img src={GeneralIcon} alt="general Info" />
                <h1>General Info</h1>
              </div>
              <div
                className={`!flex !flex-row gap-3 cursor-pointer ${
                  clickedButton === "TipeKamar" ? classes.activeTitle : ""
                }`}
                onClick={() => handleButtonClick("TipeKamar")}
              >
                <img src={GeneralIcon} alt="TipeKamar" />
                <h1>Tipe Kamar</h1>
              </div>
              <div
                className={`!flex !flex-row gap-3 cursor-pointer ${
                  clickedButton === "Penerbangan" ? classes.activeTitle : ""
                }`}
                onClick={() => handleButtonClick("Penerbangan")}
              >
                <img src={IconPenerbanganAktif} alt="general Info" />
                <h1>Penerbangan</h1>
              </div>
              <div
                className={`!flex !flex-row gap-3 cursor-pointer ${
                  clickedButton === "Akomodasi" ? classes.activeTitle : ""
                }`}
                onClick={() => handleButtonClick("Akomodasi")}
              >
                <img src={GeneralIcon} alt="general Info" />
                <h1>Akomodasi</h1>
              </div>
              <div
                className={`!flex !flex-row gap-3 cursor-pointer ${
                  clickedButton === "Transportasi" ? classes.activeTitle : ""
                }`}
                onClick={() => handleButtonClick("Transportasi")}
              >
                <img src={GeneralIcon} alt="general Info" />
                <h1>Transportasi</h1>
              </div>
              <div
                className={`!flex !flex-row gap-3 cursor-pointer ${
                  clickedButton === "Itenerary" ? classes.activeTitle : ""
                }`}
                onClick={() => handleButtonClick("Itenerary")}
              >
                <img src={GeneralIcon} alt="general Info" />
                <h1>Itenerary</h1>
              </div>

              <div
                className={`!flex !flex-row gap-3 cursor-pointer ${
                  clickedButton === "Additional" ? classes.activeTitle : ""
                }`}
                onClick={() => handleButtonClick("Additional")}
              >
                <img src={GeneralIcon} alt="general Info" />
                <h1>Additional</h1>
              </div>
              <div
                className={`!flex !flex-row gap-3 cursor-pointer ${
                  clickedButton === "Coupon" ? classes.activeTitle : ""
                }`}
                onClick={() => handleButtonClick("Coupon")}
              >
                <img src={GeneralIcon} alt="general Info" />
                <h1>Coupon</h1>
              </div>
              <div
                className={`!flex !flex-row gap-3 cursor-pointer ${
                  clickedButton === "Fee" ? classes.activeTitle : ""
                }`}
                onClick={() => handleButtonClick("Fee")}
              >
                <img src={IcFee} alt="general Info" />
                <h1>Fee</h1>
              </div>
              <div
                className={`!flex !flex-row gap-3 cursor-pointer ${
                  clickedButton === "Fee" ? classes.activeTitle : ""
                }`}
                onClick={() => handleButtonClick("Tagihan")}
              >
                <img src={IconTagihan} alt="tagihan" />
                <h1>Tagihan</h1>
              </div>
              <div
                className={`!flex !flex-row gap-3 cursor-pointer ${
                  clickedButton === "Perlengkapan" ? classes.activeTitle : ""
                }`}
                onClick={() => handleButtonClick("Perlengkapan")}
              >
                <img src={IconPerlengkapan} alt="perlengkapan" />
                <h1>Perlengkapan</h1>
              </div>
              <div
                className={`!flex !flex-row gap-3 cursor-pointer ${
                  clickedButton === "Dokumen" ? classes.activeTitle : ""
                }`}
                onClick={() => handleButtonClick("Dokumen")}
              >
                <img src={IconDokumen} alt="perlengkapan" />
                <h1>Dokumen</h1>
              </div>
              <div
                className={`!flex !flex-row gap-3 cursor-pointer ${
                  clickedButton === "Manifest" ? classes.activeTitle : ""
                }`}
                onClick={() => handleButtonClick("Manifest")}
              >
                <img src={IconManifest} alt="manifest" />
                <h1>Manifest</h1>
              </div>
              <div
                className={`!flex !flex-row gap-3 cursor-pointer ${
                  clickedButton === "Roomlist" ? classes.activeTitle : ""
                }`}
                onClick={() => handleButtonClick("Roomlist")}
              >
                <img src={IconManifest} alt="roomlist" />
                <h1>Roomlist</h1>
              </div>
              <div
                className={`!flex !flex-row gap-3 cursor-pointer ${
                  clickedButton === "Laporan" ? classes.activeTitle : ""
                }`}
                onClick={() => handleButtonClick("Laporan")}
              >
                <img src={IconLaporan} alt="laporan" />
                <h1>Laporan</h1>
              </div>
              <div
                className={`!flex !flex-row gap-3 cursor-pointer ${
                  clickedButton === "Blockseat" ? classes.activeTitle : ""
                }`}
                onClick={() => handleButtonClick("Blockseat")}
              >
                <img src={IconBlockseat} alt="blockseat" />
                <h1>Blockseat</h1>
              </div>
              <div
                className={`!flex !flex-row gap-3 cursor-pointer ${
                  clickedButton === "Ibadahku" ? classes.activeTitle : ""
                }`}
                onClick={() => handleButtonClick("Ibadahku")}
              >
                <img src={IconIbadahku} alt="ibadahku" />
                <h1>Ibadahku</h1>
              </div>
            </Sliders>
          </div>
        </div>
        {getContent()}
      </div>
      {/* AlertPopup */}
      <AlertPopup
        open={isDeletePaket}
        handleClose={handleCloseModalDelete}
        handleContinue={handleDeleteAllPaket}
        title="Hapus Paket?"
        subtitle={`Apakah kamu yakin ingin menghapus paket ?`}
        loading={isLoadingDel}
      />
    </PaketDetailStyle>
  );
};

export default PaketDetail;
