import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PaketCouponAction from "stores/actions/paket/coupon";
import { toCurrencyFormat } from "utils/helpers";

import MoslemeTable from "components/Table";
import AlertPopup from "components/popup/alert";
import Buttons from "components/buttons";
import Create from "./Create";
import Duplicate from "./Duplicate";

import { DocumentCopy, Edit, Eye, Trash } from "iconsax-react";
import IllusJamPasir from "assets/images/Jam-Pasir.svg";

function Coupon() {
  const dispatch = useDispatch();
  const store = useSelector(({ coupon }) => coupon);
  const { id } = useParams();
  const [isDuplicate, setIsDuplicate] = React.useState({
    show: false,
    data: null,
  });
  const [dataCoupon, setDataCoupon] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isCreateOrEdit, setIsCreateOrEdit] = React.useState({
    show: false,
    edit: null,
  });
  const [openDeleteCoupon, setOpenDeleteCoupon] = useState(false);
  const [couponIdDelete, setCouponIdDelete] = useState();

  const columns = [
    {
      title: "Kode Coupon",
      dataIndex: "code_coupon",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Diskon (Rp/%)",
      dataIndex: "jenis_diskon",
      render: (s, item) => {
        return item.jenis_diskon === 2 ? (
          <div>{item.nominal_diskon} %</div>
        ) : (
          <div>Rp {toCurrencyFormat(item.nominal_diskon)}</div>
        );
      },
    },
    {
      title: "Jumlah Order",
      dataIndex: "jumlah_jamaah",

      render: (s, data) => {
        return (
          <div className="py-[3px] px-[6px] flex flex-row justify-center items-center bg-transparent border-[#E0E0E0] border-[1px] rounded-lg w-[80px] ">
            <div className="flex flex-row justify-start">
              <Eye size={16} />
              <div className="ml-[8px]">{data?.amount_coupon_order} Pax</div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Quad",
      dataIndex: "quad",
      render: (s, data) => {
        return (
          <div className="flex flex-col justify-start items-start gap-y-[4px]">
            <div className="text-[12px] font-normal text-[#141414] line-through">
              Rp {toCurrencyFormat(data?.basic_price_package?.quad_price)}
            </div>
            <div className="text-[12px] font-semibold text-[#141414]">
              Rp{" "}
              {toCurrencyFormat(data?.basic_price_package?.quad_price_diskon)}
            </div>
          </div>
        );
      },
    },
    {
      title: "Triple",
      dataIndex: "triple",
      render: (s, data) => {
        return (
          <div className="flex flex-col justify-start items-start gap-y-[4px]">
            <div className="text-[12px] font-normal text-[#141414] line-through">
              Rp {toCurrencyFormat(data?.basic_price_package?.triple_price)}
            </div>
            <div className="text-[12px] font-semibold text-[#141414]">
              Rp{" "}
              {toCurrencyFormat(data?.basic_price_package?.triple_price_diskon)}
            </div>
          </div>
        );
      },
    },
    {
      title: "Double",
      dataIndex: "double",
      render: (s, data) => {
        return (
          <div className="flex flex-col justify-start items-start gap-y-[4px]">
            <div className="text-[12px] font-normal text-[#141414] line-through">
              Rp {toCurrencyFormat(data?.basic_price_package?.double_price)}
            </div>
            <div className="text-[12px] font-semibold text-[#141414]">
              Rp{" "}
              {toCurrencyFormat(data?.basic_price_package?.double_price_diskon)}
            </div>
          </div>
        );
      },
    },
    {
      title: "Single",
      dataIndex: "single",
      render: (s, data) => {
        return (
          <div className="flex flex-col justify-start items-start gap-y-[4px]">
            <div className="text-[12px] font-normal text-[#141414] line-through">
              Rp {toCurrencyFormat(data?.basic_price_package?.single_price)}
            </div>
            <div className="text-[12px] font-semibold text-[#141414]">
              Rp{" "}
              {toCurrencyFormat(data?.basic_price_package?.single_price_diskon)}
            </div>
          </div>
        );
      },
    },
    {
      title: "Periode",
      dataIndex: "tanggal_berlaku",
      className: "whitespace-nowrap relative",
      render: (s, item) => (
        <div>
          <div className="w-full overflow-hidden">
            <p className="whitespace-nowrap text-ellipsis">
              {item.tanggal_berlaku} - {item.tanggal_berakhir}
            </p>
          </div>
        </div>
      ),
    },
    {
      title: "Tipe Coupon",
      dataIndex: "tipe_coupon",
      className: "whitespace-nowrap",
      render: (s, item) => (
        <div>
          {item.tipe_coupon === 2 ? (
            <div className="bg-[#56459233] px-4 py-1 rounded-lg w-fit font-semibold">
              Private
            </div>
          ) : (
            <div className="bg-[#2eb24333] px-4 py-1 rounded-lg w-fit font-semibold">
              Public
            </div>
          )}
          <div className="action absolute !w-full top-2 flex !flex-row !justify-end">
            <div className="flex flex-row justify-end pr-10 gap-x-16">
              <DocumentCopy
                className="cursor-pointer"
                onClick={() =>
                  setIsDuplicate({
                    show: true,
                    data: item,
                  })
                }
                size="15"
                color="#141414"
              />
              <Edit
                className="cursor-pointer"
                onClick={() => {
                  setIsCreateOrEdit({
                    show: true,
                    edit: item,
                  });
                }}
                size="15"
                color="#141414"
              />
              <Trash
                className="cursor-pointer"
                onClick={() => {
                  setOpenDeleteCoupon(true);
                  setCouponIdDelete({
                    id: item.id_coupon,
                    name: item.code_coupon,
                  });
                }}
                size="15"
                color="#141414"
              />
            </div>
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    dispatch(
      PaketCouponAction.fetchData({
        paket_id: id,
      })
    );
  }, [
    dispatch,
    store.keyword,
    store.per_page,
    store.page_number,
    store.id_role,
  ]);

  const getAllPaketCoupon = () => {
    dispatch(
      PaketCouponAction.fetchData({
        paket_id: id,
      })
    );
  };

  useEffect(() => {
    setDataCoupon(store.data);
  }, [store.data, store.data?.length]);

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: store.total,
    current: store.page_number,
    pageSize: store.per_page,
    defaultPageSize: store.per_page,
    pageSizeOptions: ["10", "20"],
  };

  const handleConfirmDeleteCoupon = async () => {
    setLoading(true);
    const status = await dispatch(
      PaketCouponAction.deleteData(couponIdDelete?.id)
    );
    if (status === 200) {
      setDataCoupon(store.data);
      setOpenDeleteCoupon(false);
      setLoading(false);
    }
  };

  return (
    <>
      <AlertPopup
        open={openDeleteCoupon}
        handleClose={() => setOpenDeleteCoupon(false)}
        handleContinue={handleConfirmDeleteCoupon}
        loading={loading}
        title="Hapus Cabang"
        subtitle={`Apakah Anda sudah yakin ingin menghapus Coupon ${couponIdDelete?.name}?`}
      />

      <div className="">
        {dataCoupon?.length > 0 && (
          <div className="flex justify-end py-[16px] pr-[16px]">
            <Buttons
              onClick={() =>
                setIsCreateOrEdit({
                  show: true,
                  edit: null,
                })
              }
              text="Tambah Data"
              customClass="btn-primary btn-small"
            />
          </div>
        )}

        {dataCoupon === null || dataCoupon?.length === 0 ? (
          <div className="flex flex-col justify-center items-center gap-2 py-10">
            <img
              className="h-[260px] w-[260px]"
              src={IllusJamPasir}
              alt="empty"
            />
            <div className="text-[16px] font-normal text-[#141414]">
              Belum ada Coupon di kategori ini
            </div>
            <div className="mt-[16px] ">
              <Buttons
                onClick={() =>
                  setIsCreateOrEdit({
                    show: true,
                    edit: null,
                  })
                }
                text="Tambah Coupun"
                customClass="btn-primary btn-small"
              />
            </div>
          </div>
        ) : (
          <div>
            <MoslemeTable
              loading={store.status === "process"}
              columns={columns}
              data={dataCoupon.map((item, i) => {
                return {
                  ...item,
                  key: `${i + 1}`,
                };
              })}
              withSelection
              selectionType="checkbox"
              pagination={pagination}
              onPageChange={(page) => dispatch(PaketCouponAction.setPage(page))}
              onSizeChange={(currentpage, pageSize) => {
                dispatch(PaketCouponAction.setPage(currentpage));
                dispatch(PaketCouponAction.setSize(pageSize));
              }}
            />
            <Duplicate
              show={isDuplicate.show}
              data={isDuplicate.data}
              close={() =>
                setIsDuplicate({
                  show: false,
                  data: null,
                })
              }
            />
          </div>
        )}

        <Create
          show={isCreateOrEdit.show}
          close={setIsCreateOrEdit}
          edit={isCreateOrEdit.edit}
          getAllPaketCoupon={getAllPaketCoupon}
        />
      </div>
    </>
  );
}

export default Coupon;
