import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import moment from "moment";

import Cards from "../../../components/cards";
import Inputs from "../../../components/form/inputs";
import Buttons from "../../../components/buttons";
import MoslemeTable from "../../../components/Table";
import Badge from "../../../components/badge";

import IconSearch from "../../../assets/icons/search-normal.svg";
import IconSquare from "../../../assets/icons/send-square.svg";
import IconActivity from "../../../assets/icons/activity.svg";
import AvatarRed from "../../../assets/images/Avatar-ikhwan-red.svg";
import IconWhatsapp from "../../../assets/icons/whatsapp.svg";
import { DocumentCopy, Edit, Eye, Trash } from "iconsax-react";
import { getAllDataUmroh, getDetailDataUmroh } from "stores/actions/tagihan";
import { useSelector, useDispatch } from "react-redux";

const CardUmrohTable = () => {
  const router = useHistory();
  // Selection & type state
  const [selectionType, setSelectionType] = useState("checkbox");

  // Pagination state
  const [currentpage, setCurrentpage] = useState(1);
  const [size, setSize] = useState(10);
  const [isExpand, setIsExpand] = useState(true);
  const total = 100;

  // data table
  const { dataUmroh, status } = useSelector((state) => state.tagihan);
  let dataTable = dataUmroh.data;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getAllDataUmroh({
        page_number: 1,
        per_page: 10,
        sort_column: "order_number",
        sort_order: "ASC",
        tipe_paket: 1,
      })
    );
  }, []);

  const columns = [
    {
      title: "Foto",
      dataIndex: "user_jamaah",
      render: (record) => (
        <>
          <img
            className="rounded-full w-10 h-10 object-cover"
            alt="logo maskapai"
            src={record && record?.profile_photo}
          />
        </>
      ),
    },
    {
      title: "Title",
      dataIndex: "user_jamaah",
      render: (record) => (
        <>
          <div className="w-max">
            {(record && record?.title === null) || undefined
              ? " - "
              : record?.title}
          </div>
        </>
      ),
    },
    {
      title: "Nama",
      dataIndex: "user_jamaah",
      render: (record) => (
        <>
          <div className="w-max">{record && record?.name}</div>
          {/* <div className="flex flex-row mt-1">
            <Badge label={tags[0]} type="blue light" />
            <Badge label={tags[1]} type="orange old" customClass="ml-1" />
          </div> */}
        </>
      ),
    },
    {
      title: "Kode Tagihan",
      // dataIndex: "bill",
    },
    {
      title: "Tanggal Booking",
      dataIndex: "created_at",
      render: (record) => (
        <>
          <div className="">
            {record && moment(record.created_at).format("LLL")}
          </div>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (record) => (
        <Badge
          label={record === 1 ? "lunas" : "belum lunas"}
          type={record === 1 ? "orange dark" : record === 3 ? "green" : ""}
          customClass="uppercase"
        />
      ),
    },
    {
      title: "Follow Up",
      render: (record) => (
        <>
          <div className="flex flex-row gap-1">
            <Buttons
              text="1"
              customClass="btn-brown-light btn-small !w-fit"
              icon={IconWhatsapp}
            />
            <Buttons
              text="2"
              customClass="btn-brown-light btn-small !w-fit"
              icon={IconWhatsapp}
            />
          </div>
          <div className="action">
            <Eye
              className="cursor-pointer"
              onClick={() => {
                dispatch(getDetailDataUmroh(record.id));
                router.push("/tagihan/detail");
              }}
              size="15"
              color="#141414"
            />
            <Trash
              className="cursor-pointer"
              // onClick={() => setOpenPopupDeleteCabang(true)}
              size="15"
              color="#141414"
            />
          </div>
        </>
      ),
    },
    {
      title: "Paket",
      dataIndex: "paket",
      render: (paket) => (
        <>
          <div>{paket}</div>
          <div className="action">
            <DocumentCopy
              className="cursor-pointer"
              onClick={() => alert(`copy id:`)}
              size="15"
              color="#141414"
            />
            <Edit
              className="cursor-pointer"
              onClick={() => alert(`edit id:`)}
              size="15"
              color="#141414"
            />
          </div>
        </>
      ),
    },
  ];

  // const data = [
  //   {
  //     key: "1",
  //     photo: AvatarRed,
  //     title: "Tuan",
  //     name: {
  //       name: "Dimas Andreyean Pradana Putra",
  //       tags: ["Admin Cabang Daerah", "ACEH"],
  //     },

  //     bill: "UMR-987",
  //     bookdate: "21/08/2022 14.07 WIB",
  //     status: {
  //       code: 1,
  //       value: "DP",
  //     },
  //     followup: "whatsapp",
  //     paket: "Umroh Bersama Ustadz Muhammad Abduh Tuasikal 12 September 2022",
  //   },
  //   {
  //     key: "2",
  //     photo: AvatarRed,
  //     title: "Tuan",
  //     name: {
  //       name: "Dimas Andreyean Pradana Putra",
  //       tags: ["Admin Cabang Daerah", "ACEH"],
  //     },

  //     bill: "UMR-987",
  //     bookdate: "21/08/2022 14.07 WIB",
  //     status: {
  //       code: 2,
  //       value: "Dibayar Sebagian",
  //     },
  //     followup: "whatsapp",
  //     paket: "Umroh Bersama Ustadz Muhammad Abduh Tuasikal 12 September 2022",
  //   },
  //   {
  //     key: "3",
  //     photo: AvatarRed,
  //     title: "Tuan",
  //     name: {
  //       name: "Dimas Andreyean Pradana Putra",
  //       tags: ["Admin Cabang Daerah", "ACEH"],
  //     },

  //     bill: "UMR-987",
  //     bookdate: "21/08/2022 14.07 WIB",
  //     status: {
  //       code: 3,
  //       value: "Lunas",
  //     },
  //     followup: "whatsapp",
  //     paket: "Umroh Bersama Ustadz Muhammad Abduh Tuasikal 12 September 2022",
  //   },
  // ];

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: total,
    current: currentpage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20"],
  };

  // func to set size pagination
  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setSize(pageSize);
  };

  const expandable = {
    expandedRowRender: (record) => <p style={{ margin: 0 }}>{record.name}</p>,
    rowExpandable: (record) => record.name !== "Not Expandable",
  };

  // // action button
  // const ButtonAction = ({ value }) => {
  //   return (
  //     <div className="cursor-pointer flex justify-around">
  //       <Eye
  //         onClick={() => router.push("/tagihan/detail")}
  //         size="15"
  //         color="#141414"
  //       />
  //       <Trash
  //         onClick={() => alert(`delete id:`, value)}
  //         size="15"
  //         color="#141414"
  //       />
  //       <DocumentCopy
  //         onClick={() => alert(`copy id:`, value)}
  //         size="15"
  //         color="#141414"
  //       />
  //       <Edit
  //         onClick={() => alert(`edit id:`, value)}
  //         size="15"
  //         color="#141414"
  //       />
  //     </div>
  //   );
  // };

  // // manipulated data with action
  // const sourceData = data.map((v) => {
  //   v.actions = <ButtonAction value={v} />;
  //   return v;
  // });

  return (
    <Cards containerClass="card-table w-auto !rounded-2xl !shadow-none !m-0 !p-0">
      <div className="card-table-header !py-2 !px-4">
        <Grid container spacing={2}>
          <Grid item md={4}>
            <Inputs
              id="test"
              customClass="!w-[245px]"
              placeholder="Search"
              iconPosition="left"
              icon={IconSearch}
            />
          </Grid>
          <Grid item md={8} className="flex justify-end">
            <img
              className="ml-4 cursor-pointer w-4"
              src={IconSquare}
              alt="square"
            />
            <img
              className="ml-4 cursor-pointer w-4"
              src={IconActivity}
              alt="activity"
            />
          </Grid>
        </Grid>
      </div>

      {/* Table */}
      <MoslemeTable
        expandable={isExpand && expandable}
        columns={columns}
        data={dataTable}
        withSelection={true}
        selectionType={selectionType}
        pagination={pagination}
        customPaginationClass="pr-4"
        customClass="mt-4"
        onPageChange={(page) => setCurrentpage(page)}
        onSizeChange={(currentpage, pageSize) =>
          onSizeChange(currentpage, pageSize)
        }
      />
    </Cards>
  );
};

export default CardUmrohTable;
