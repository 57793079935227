import { useState } from "react";
import ColorPicker from ".";

const ColorPickerDocs = () => {
    const [selectedColor, setSelectedColor] = useState("");

    return (
        <div className="w-72">
            <h2 className="font-semibold mt-5 mb-2">Color Picker</h2>
            <ColorPicker
                placeholder="Pilih Warna"
                label="Pilih Warna"
                onChange={(color) => setSelectedColor(color.hex)}
                color={selectedColor}
            />
            <p>Selected Color : {selectedColor}</p>
        </div>
    );
};

export default ColorPickerDocs;
