import {
  START_FETCHING_SETTING_ARTICLE,
  ERROR_FETCHING_SETTING_ARTICLE,
  SUCCESS_FETCHING_SETTING_ARTICLE,
  SET_ID_ARTICLE,
} from '../../actions/article/actionType';

const statuslist = {
  idle: 'idle',
  process: 'process',
  success: 'success',
  error: 'error',
};

const initialState = {
  data: [],
  kategori_id: '0',
  status: statuslist.idle,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case START_FETCHING_SETTING_ARTICLE:
      return { ...state, status: statuslist.process };

    case ERROR_FETCHING_SETTING_ARTICLE:
      return { ...state, status: statuslist.error };

    case SUCCESS_FETCHING_SETTING_ARTICLE:
      return {
        ...state,
        data: action.data,
        status: statuslist.success,
      };
    case SET_ID_ARTICLE:
      return { ...state, kategori_id: action.kategori_id };

    default:
      return state;
  }
}
