import React, { useState } from "react";

import Buttons from "../../../../components/buttons";
import MoslemeTable from "../../../../components/Table";
import Badge from "../../../../components/badge";

import IconSingle from "../../../../assets/icons/Single.svg";
import IconDouble from "../../../../assets/icons/Double.svg";
import IconTriple from "../../../../assets/icons/Triple.svg";
import IconQuad from "../../../../assets/icons/Quad.svg";
import AvatarRed from "../../../../assets/images/Avatar-ikhwan-red.svg";

const BookingDetailRoomlist = () => {
  const [listFilter, setListFilter] = useState([
    {
      id: 0,
      label: "Single",
      count: 1,
      active: true,
      icActive: IconSingle,
      icInactive: IconSingle,
    },
    {
      id: 1,
      label: "Double",
      count: 6,
      active: false,
      icActive: IconDouble,
      icInactive: IconDouble,
    },
    {
      id: 2,
      label: "Tripple",
      count: 2,
      active: false,
      icActive: IconTriple,
      icInactive: IconTriple,
    },
    {
      id: 3,
      label: "Quad",
      count: 1,
      active: false,
      icActive: IconQuad,
      icInactive: IconQuad,
    },
  ]);
  const [currentpage, setCurrentpage] = useState(1);
  const [size, setSize] = useState(10);
  const total = 100;

  const columns = [
    {
      title: "Foto",
      dataIndex: "photo",
      render: (img) => <img src={img} alt="img" />,
    },
    {
      title: "Nama Sesuai Passport",
      dataIndex: "passport_name",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      render: () => (
        <div className="flex flex-row gap-2 px-2 py-1 bg-white w-fit rounded-lg border-[1px] border-[#4590F4] items-center">
          <img src={AvatarRed} alt="user" />
          <div className="font-medium">Laki Laki</div>
        </div>
      ),
    },
    {
      title: "Kamar",
      dataIndex: "room",
      render: () => (
        <div className="flex flex-row gap-2 px-2 py-1 bg-white w-fit yellow-dark rounded-lg items-center">
          <img src={IconDouble} alt="double" />
          <div className="font-medium">Double</div>
          <div className="flex justify-center px-2 py-1 items-center rounded-full bg-white border-[1px] border-[#F90601] w-[20px] h-[20px]">
            <div className="txt-maroon font-medium">1</div>
          </div>
        </div>
      ),
    },
    {
      title: "Relasi",
      dataIndex: "relation",
    },
    {
      title: "Jamaah Cabang",
      dataIndex: "branch",
      render: (branch) => <Badge label={branch} />,
    },
  ];

  const data = [
    {
      key: "1",
      photo: AvatarRed,
      passport_name: "Dimas Andreyan Pradana Putra",
      gender: "",
      room: "",
      relation: "Suami",
      branch: "Yogyakarta",
    },
    {
      key: "2",
      photo: AvatarRed,
      passport_name: "Dimas Andreyan Pradana Putra",
      gender: "",
      room: "",
      relation: "Suami",
      branch: "Yogyakarta",
    },
    {
      key: "3",
      photo: AvatarRed,
      passport_name: "Dimas Andreyan Pradana Putra",
      gender: "",
      room: "",
      relation: "Suami",
      branch: "Yogyakarta",
    },
  ];

  // Pagination config
  const pagination = {
    showSizeChanger: true,
    total: total,
    current: currentpage,
    pageSize: size,
    defaultPageSize: 10,
    pageSizeOptions: ["10", "20"],
  };

  // func to set size pagination
  const onSizeChange = (currentpage, pageSize) => {
    setCurrentpage(currentpage);
    setSize(pageSize);
  };

  const handleFilter = (id) => {
    let temp = [...listFilter];
    temp.forEach((v) => {
      if (v.id === id) {
        v.active = true;
      } else {
        v.active = false;
      }
    });

    setListFilter(temp);
  };

  return (
    <div>
      <div className="p-4 flex flex-row justify-between">
        <div>
          <div className="text-[20px] font-semibold">Daftar Jamaah</div>
          <div className="flex flex-row gap-4 mt-4">
            <div className="flex">
              {listFilter.map((list) => {
                return (
                  <div
                    onClick={() => handleFilter(list.id)}
                    className={`flex rounded-lg items-center cursor-pointer px-3 py-1 border mr-3 ${
                      list.active
                        ? "bg-[#FAAB36] bg-opacity-30 border-[#FAAB36]"
                        : "bg-white border-[#e6e6e6]"
                    }`}
                    key={list.id}
                  >
                    <img
                      src={list.active ? list.icActive : list.icInactive}
                      alt="filter"
                    />
                    <p className="ml-2">
                      {list.label} ({list.count} pax)
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div>
          <Buttons text="Atur Pengelompokan" customClass="btn-outline" />
        </div>
      </div>
      <div className="divider"></div>
      <MoslemeTable
        columns={columns}
        data={data}
        withSelection={false}
        selectionType={false}
        pagination={pagination}
        customPaginationClass="pr-4"
        customClass="mt-4"
        onPageChange={(page) => setCurrentpage(page)}
        onSizeChange={(currentpage, pageSize) =>
          onSizeChange(currentpage, pageSize)
        }
      />
    </div>
  );
};

export default React.memo(BookingDetailRoomlist);
