import axios from "axios";
import { BASE_URL_ENDPOINT } from "constant/constanta";

const guideApi = {
  list: (payload) =>
    axios.post(`${BASE_URL_ENDPOINT}/admin/get-pembimbing`, payload),
  post: (payload) =>
    axios.post(`${BASE_URL_ENDPOINT}/admin/save-pembimbing`, payload),
  delete: (id) => axios.delete(`${BASE_URL_ENDPOINT}/admin/pembimbing/${id}`),
  duplicate: (payload) =>
    axios.post(`${BASE_URL_ENDPOINT}/admin/pembimbing/duplicate`, payload),
};

export { guideApi };
