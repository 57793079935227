import Buttons from "components/buttons";
import ImageField from "components/image_field";
import React, { useState } from "react";

const UploadFoto = () => {
  const [isMulti, setIsMulti] = useState(true);
  const [isError, setIsError] = useState(false);

  return (
    <div className="flex flex-col gap-[16px] w-full h-fit border rounded-lg">
      <div className="p-[16px] m-auto">
        <ImageField
          customContainer="!w-[570px] !h-[162px]"
          setFile={(file) => console.log("file1", file)}
          label="Foto"
          name="Foto"
        />
      </div>
      <div className="flex flex-row justify-end items-center p-[16px] gap-[8px] border-t">
        <Buttons text="Simpan" customClass="btn-primary btn-medium" />
      </div>
    </div>
  );
};

export default UploadFoto;
