import React from "react";
import PropTypes from "prop-types";

import CardsStyle from "./Cards.style";

const Cards = ({ containerClass, children }) => {
  return <CardsStyle className={`${containerClass}`}>{children}</CardsStyle>;
};

Cards.propTypes = {
  containerClass: PropTypes.string,
};

Cards.defaultProps = {
  containerClass: "",
};

export default React.memo(Cards);
