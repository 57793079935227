import Drawer from "../../../../../components/drawer";
import Input from "../../../../../components/form/inputs";
import IconCloseDrawer from "../../../../../assets/icons/Close-Drawer.svg";
import IconSearch from "../../../../../assets/icons/search-normal.svg";

import Badge from "components/badge";

const JamaahDrawer = ({
  isOpenDrawer = false,
  setIsOpenDrawer = (message) => {},
  listDataJamaah = {},
  dataHeaderDrawer = {},
  input,
  setInput,
}) => {
  return (
    <Drawer open={isOpenDrawer}>
      <div className="flex flex-1 justify-between rounded-xl p-4 mb-5 bg-white shadow-lg">
        <div>
          <h2 className="font-bold text-lg">List Jamaah Starting</h2>
          <div className="flex items-center gap-1">
            <Badge
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              customClass={
                "!py-[4px] !px-[16px] !text-[12px] !font-semibold rounded-lg"
              }
              label={`${dataHeaderDrawer?.starting?.name}`}
              color={dataHeaderDrawer?.starting?.color_text}
              backgroundColor={dataHeaderDrawer?.starting?.color_bg}
            />
          </div>
        </div>
        <img
          className="cursor-pointer"
          src={IconCloseDrawer}
          alt="close"
          onClick={() => {
            setIsOpenDrawer(false);
            setInput("");
          }}
        />
      </div>
      <Input
        customClass="!py-3"
        placeholder="Search"
        iconPosition="left"
        icon={IconSearch}
        value={input}
        onChange={(e) => setInput(e.target.value)}
      />
      {listDataJamaah?.data?.jamaah?.map((item) => (
        <div className="flex gap-3 py-3">
          <div className="w-[36px] h-[36px] rounded-full">
            <img src={item?.photo} alt="avatar" width={36} height={36} />
          </div>

          <div>
            <div className="my-1 pt-3">{item?.name}</div>

            <Badge
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              customClass={
                "!py-[4px] !px-[16px] !text-[12px] !font-semibold rounded-lg"
              }
              label={`${item?.cabang?.name}`}
              color={item?.cabang?.color_text}
              backgroundColor={item?.cabang?.color_bg}
            />
          </div>
        </div>
      ))}
    </Drawer>
  );
};
export default JamaahDrawer;
