import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import HeaderLayout from "./index";
import FotoProfile from "../../../assets/images/profile_new.png";
import IconContact from "../../../assets/icons/ic_contact.svg";
import AvatarIkhwan from "../../../assets/icons/ava-ikhwan.svg";
import Buttons from "../../buttons";
import EditProfile from "./components/EditProfile";
import GantiKataSandi from "./components/GantiKataSandi";
import PerbaruiKontak from "./components/PerbaruiKontak";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { logout } from "../../../stores/actions/login";

import { getLocalStorage } from "utils/helpers";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  dropdown: {
    position: "absolute",
    right: "16px",
    top: "109px",
    zIndex: "999",
    width: "400px",
    // zIndex: 1,
    // bottom: "-303px",
    overflow: "hidden",
    boxShadow:
      "0px 6px 64px -4px rgba(0, 0, 0, 0.04), 0px 10px 16px rgba(0, 0, 0, 0.04), 0px 6px 32px rgba(0, 0, 0, 0.04)",
    backgroundColor: "#fff",
    borderRadius: "16px",
    paddingTop: "16px",
    paddingBottom: "16px",
    "& .row_container": {
      display: "flex",
      flexDirection: "row",
      "& .icon_profile": {
        width: "60px",
        height: "60px",
        borderRadius: "30px",
        cursor: "pointer",
        position: "relative",
        "& .icon_active": {
          width: "10px",
          height: "10px",
          borderRadius: "5px",
          background: "#A7C957",
          border: "1px solid #ffff",
          position: "absolute",
          left: "2px",
          bottom: "4px",
        },
      },
      "& .column_container": {
        display: "flex",
        flexDirection: "column",
        marginLeft: "16px",

        "& .title_profile": {
          fontFamily: "GeneralSansSemibold",
          fontWeight: 600,
          color: "#151515",
          fontSize: "16px",
        },
        "& .container_row": {
          marginTop: "4px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          "& .subtitle": {
            color: "#BDBDBD",
            marginLeft: "6px",
            marginRight: "4px",
          },
        },
      },
    },
    "& .card_container": {
      border: "1px solid #E0E0E0",
      marginLeft: "-18px",
      marginRight: "-18px",
      marginTop: "20px",
    },
    "& .title_card": {
      fontSize: "16px",
      color: "#000",
      fontFamily: "GeneralSans",
      padding: "10px 16px 10px 16px",
      cursor: "pointer",
    },
  },
  navbarSpace: {
    width: "100%",
    position: "fixed",
    height: "30px",
    background: "#f5f5f5",
    zIndex: "997",
  },
}));
const Dropdown = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [openProfileSandi, setOpenProfileSandi] = useState(false);
  const [openProfileKontak, setOpenProfileKontak] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const userLogin = getLocalStorage("userLogin");
  const userLoginJsonParse = userLogin && JSON.parse(userLogin);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };
  const handleClosePopupProfile = useCallback(() => {
    setOpenProfile(false);
  }, []);
  const handleOpenPopProfile = useCallback(() => {
    setOpenProfile(true);
  }, []);
  const handleClosePopupProfileSandi = useCallback(() => {
    setOpenProfileSandi(false);
  }, []);
  const handleOpenPopUpSandi = useCallback(() => {
    setOpenProfileSandi(true);
  }, []);
  const handleClosePopupProfileKontak = useCallback(() => {
    setOpenProfileKontak(false);
  }, []);
  const handleOpenPopUpKontak = useCallback(() => {
    setOpenProfileKontak(true);
  }, []);
  const handleLogout = useCallback(() => {
    dispatch(logout(history));
  }, []);
  return (
    <React.Fragment>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={classes.root}>
          <div className={classes.navbarSpace}></div>
          <HeaderLayout handleClick={handleClick} />
          {open ? (
            <div className={classes.dropdown}>
              <div className="row_container px-4">
                <div className="icon_profile">
                  <img
                    src={AvatarIkhwan}
                    alt="fotoProfile"
                    width={56}
                    height={56}
                  />
                  <div className="icon_active"></div>
                </div>
                <div className="column_container">
                  <div className="title_profile">
                    {userLoginJsonParse?.data?.account?.name}
                  </div>
                  <div className="container_row">
                    <img src={IconContact} alt="icon_contact" />
                    <div className="subtitle">Nickname</div>
                    <div>
                      <b> {userLoginJsonParse?.data?.account?.nickname}</b>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-4">
                <Buttons
                  text="Edit Profile"
                  customClass="btn-outline btn-medium mt-[20px]"
                  onClick={handleOpenPopProfile}
                />
              </div>
              <div className="card_container"></div>
              <div
                className="title_card hover:bg-[#F5F5F5]"
                onClick={handleOpenPopUpKontak}
              >
                Perbarui Kontak
              </div>
              <div
                className="title_card hover:bg-[#F5F5F5]"
                onClick={handleOpenPopUpSandi}
              >
                Ganti Kata sandi
              </div>
              <div className="card_container"></div>
              <div
                className="title_card hover:bg-[#F5F5F5]"
                onClick={handleLogout}
              >
                Logout
              </div>
            </div>
          ) : null}
        </div>
      </ClickAwayListener>

      <EditProfile
        openProfile={openProfile}
        handleClosePopupProfile={handleClosePopupProfile}
      />
      <GantiKataSandi
        openProfileSandi={openProfileSandi}
        handleClosePopupProfileSandi={handleClosePopupProfileSandi}
      />
      <PerbaruiKontak
        openProfileKontak={openProfileKontak}
        handleClosePopupProfileKontak={handleClosePopupProfileKontak}
      />
    </React.Fragment>
  );
};

Dropdown.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClickAway: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
};

Dropdown.defaultProps = {
  open: false,
  handleClick: () => {},
  handleClickAway: () => {},
};

export default Dropdown;
