import React, { useCallback, useEffect, useRef, useState } from "react";
import TaskIcon from "../../../../../assets/icons/task-table.svg";
import PerlengkapanJamaah from "./PerlengkapanJamaah";
import Dropdowns from "components/dropdowns";
import { Radio, Space, Switch } from "antd";
import useClickOutside from "utils/helpers/useClickOutside";
import { Task } from "iconsax-react";
import { updatePermissionRoleAction } from "stores/actions/role";

const PerlengkapanContent = ({ value, selectedRole }) => {
  const [isClicked, setIsClicked] = useState(false);
  const [maxPerlengkapan, setMaxPerlengkapan] = useState(10);
  const popover = useRef();
  const [openDropdown, setOpenDropdown] = useState(false);
  const close = useCallback(() => setOpenDropdown(false), []);
  useClickOutside(popover, close);

  return (
    <div>
      <div className="relative">
        <button
          className={`border ${
            value > 0 ? "bg-[#D8E1BE]" : ""
          } flex flex-row justify-center gap-1 rounded-xl py-2 px-4  duration-200`}
          onClick={() => setOpenDropdown(!openDropdown)}
        >
          <Task size="18" color="#141414" className="mt-1" />
          Perlengkapan ({value}/{maxPerlengkapan})
        </button>
        {openDropdown && value > 0 && (
          <div className="absolute z-50 top-[60px] bottom-0">
            <PerlengkapanJamaah />
          </div>
        )}
      </div>
    </div>
  );
};

export default PerlengkapanContent;
