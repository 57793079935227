import { DocumentCopy, Edit, Eye, Trash } from "iconsax-react";
import { getMasterId } from "stores/actions/datamaster";
import { getLocalStorage } from "utils/helpers";

const userLogin =
  getLocalStorage("userLogin") && JSON.parse(getLocalStorage("userLogin"));
const permissions = userLogin?.data?.account?.permissions;

export const columns = (dispatch) => [
  {
    title: "Musim",
    dataIndex: "thn_hijriyah",
    render: (thn_hijriyah, data) => (
      <div className="text-sm">{`${data?.thn_masehi} M/${thn_hijriyah} H`}</div>
    ),
  },
  {
    title: "Deskripsi",
    dataIndex: "description",
    className: "whitespace-nowrap relative",
    render: (s, data) => (
      <div className="w-full relative flex flex-row justify-between">
        {s !== null && s?.length > 150 ? `${s?.substr(0, 150)}...` : s}
        <div className="action absolute !w-full top-2 flex !flex-row !justify-end">
          <div className="flex flex-row justify-end pr-[16px] gap-x-16">
            {permissions?.includes("delete_master-data") && (
              <Trash
                className="cursor-pointer"
                onClick={() =>
                  dispatch(getMasterId({ modal: true, data: data }))
                }
                size="16"
                color="#141414"
              />
            )}

            {permissions?.includes("update_master-data") && (
              <Edit
                className="cursor-pointer"
                onClick={() =>
                  dispatch(getMasterId({ modalEdit: true, data: data }))
                }
                size="16"
                color="#141414"
              />
            )}
          </div>
        </div>
      </div>
    ),
  },
];
